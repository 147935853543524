import { APIError, APIResource, APIResponse } from "../utils/APIResource";
import { quoteActions } from "../actions";
import Enums from "../utils/Enums";

export default class QuoteService {
  static get(id) {
    return APIResource.get(`/v1/quote/${id}`)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getPdf(id) {
    return APIResource.get(`/v1/quote/${id}.pdf`, { responseType: "blob" })
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static previewPdf(id) {
    return APIResource.get(`/v1/quote/${id}/generate`, { responseType: "blob" })
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static save(params) {
    return APIResource.post(`/v1/quote`, params)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static deleteQuote(id) {
    return APIResource.delete(`/v1/quote/${id}`)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static update(id, params) {
    return APIResource.put(`/v1/quote/${id}`, params)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static updateStage(id, params) {
    return APIResource.put(`/v1/quote/${id}/stage`, params)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static generateQuote(id, params) {
    return APIResource.put(`/v1/quote/${id}/send`, params)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        quoteActions.save(Enums.STAGES.EMPLOYEE);
        return Promise.reject(new APIError(error));
      });
  }

  static list(params) {
    return APIResource.get(`/v1/quote`)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getExistingPolicyDetails(policyNo) {
    return APIResource.get(`/v1/policy/${policyNo}`)
      .then(resp => {
        return Promise.resolve(
          new APIResponse({
            resp,
            data: resp.data
          })
        );
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getExistingPolicyMemberDetails(policyNo) {
    return APIResource.get(`/v1/policy/member/${policyNo}`)
      .then(resp => {
        return Promise.resolve(
          new APIResponse({
            resp,
            data: resp.data
          })
        );
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }
}
