import { APIResource, APIError, APIResponse } from "../utils/APIResource";

export default class CompanyService {
  static getCompanyDetail(quoteId) {
    return APIResource.get(`/v1/quote/${quoteId}/company`)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static putCompanyDetail({ quoteId, details }) {
    return APIResource.put(`/v1/quote/${quoteId}/company`, details)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static postCompanyDetail({ quoteId, details }) {
    return APIResource.post(`/v1/quote/${quoteId}/company`, details)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }
}
