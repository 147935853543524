import bowser from "bowser";

const Extended = {
  isIE: () => {
    return bowser.msie;
  },
  minHeight: value => {
    return {
      minHeight: value,
      height: value
    };
  }
};

export default Extended;
