import React, { Component } from "react";
import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";

import Quote from "../components/Quote";

class CurrentQuote extends Component {
  render() {
    const { quote, navDrawerOpen } = this.props;
    return <Quote key={quote.resetTime} quote={quote} navDrawerOpen={navDrawerOpen} />;
  }
}

const styles = theme => ({
  root: {},
  loadingWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

function mapStateToProps(state) {
  return { quote: state.quote };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(CurrentQuote));
