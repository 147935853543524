import * as actionTypes from "../actions/types";

const initialState = {
  notification: {
    showLoading: false,
    showPopup: false,
    popupType: "success",
    popupMessage: ""
  },
  currentPolicyNo: null,
  filter: {
    memberType: "ALL",
    claimType: "ALL",
    fromDate: "",
    toDate: ""
  },
  claimExpenseList: []
};

export const policyClaimExpense = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.POLICY_DETAILS_SET_CURRENT_POLICY_NO:
      let policyNo = action.payload;
      return {
        ...state,
        currentPolicyNo: policyNo
      };
    case actionTypes.POLICY_CLAIM_EXPENSE_SHOW_LOADING:
      return { ...state, notification: { ...state.notification, showLoading: true } };
    case actionTypes.POLICY_CLAIM_EXPENSE_CLOSE_LOADING:
      return { ...state, notification: { ...state.notification, showLoading: false } };
    case actionTypes.POLICY_CLAIM_EXPENSE_SHOW_POPUP_SUCCESS:
      return {
        ...state,
        notification: {
          ...state.notification,
          showPopup: true,
          popupType: "success",
          popupMessage: action.message
        }
      };
    case actionTypes.POLICY_CLAIM_EXPENSE_SHOW_POPUP_ERROR:
      return {
        ...state,
        notification: {
          ...state.notification,
          showPopup: true,
          popupType: "error",
          popupMessage: action.message
        },
        filter: initialState.filter,
        claimExpenseList: initialState.claimExpenseList
      };
    case actionTypes.POLICY_CLAIM_EXPENSE_CLOSE_POPUP:
      return {
        ...state,
        notification: {
          ...state.notification,
          showPopup: false
        }
      };
    case actionTypes.POLICY_CLAIM_EXPENSE_SUCCESS:
      return {
        ...state,
        filter: action.filter,
        claimExpenseList: action.payload
      };
    case actionTypes.POLICY_CLAIM_EXPENSE_CLEAR:
      return initialState;
    default: {
      return state;
    }
  }
};
