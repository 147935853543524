import Enums from "../../../../utils/Enums";

export default [
  {
    access: Enums.SCOPE_TYPE.EMP_HR_R,
    logo: require("../../../img/dashboard-employees-img.png"),
    id: "myEmployees",
    title: "My Employees"
  },
  {
    access: Enums.SCOPE_TYPE.CLAIM_EXP_HR_R,
    logo: require("../../../img/dashboard-claim-expenses-img.png"),
    id: "claimExpenses",
    title: "Claim Expenses"
  },
  {
    access: Enums.SCOPE_TYPE.EMP_BENEFIT_HR_R,
    logo: require("../../../img/dashboard-benefits-img.png"),
    id: "employeeBenefits",
    title: "Benefits"
  },
  {
    access: Enums.SCOPE_TYPE.DOCUMENT_HR_R,
    logo: require("../../../img/dashboard-document-img.png"),
    id: "documents",
    title: "Documents"
  }
];
