import CorePlans from "./PTF/core.data";
import SupplementaryPlans from "./PTF/supplementary.data";

import CorePlans3 from "./PF3/core.data";
import SupplementaryPlans3 from "./PF3/supplementary.data";

import PEP_CorePlans from "./PEP/core.data";
import PEP_SupplementaryPlans from "./PEP/supplementary.data";

import GCP_CorePlans from "./GCP/core.data";
import GCP_SupplementaryPlans from "./GCP/supplementary.data";

const plans = {
  version: 2,
  PTF: [
    {
      title: `Core Plans`,
      plans: CorePlans
    },
    {
      title: `Supplementary Plans`,
      plans: SupplementaryPlans
    }
  ],
  PEP: [
    {
      title: `Core Plans`,
      plans: PEP_CorePlans
    },
    {
      title: `Supplementary Plans`,
      plans: PEP_SupplementaryPlans
    }
  ],
  GCP: [
    {
      title: `Core Plans`,
      plans: GCP_CorePlans
    },
    {
      title: `Supplementary Plans`,
      plans: GCP_SupplementaryPlans
    }
  ],
  PF3: [
    {
      title: `Core Plans`,
      plans: CorePlans3
    },
    {
      title: `Supplementary Plans`,
      plans: SupplementaryPlans3
    }
  ]
};

export default plans;
