import Enums from "../../../../utils/Enums";

const GEMMBenefits = {
  headers: [
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "30%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Daily Room and Board<strong>`,
          info: `<em>(including ICU & HDW)</em>`
        },
        {
          data: `(As per Basis Group Hospital & Surgical Plan)`,
          attributes: {
            colspan: 5,
            style: {
              width: "100%",
              textAlign: "center"
            }
          }
        }
      ]
    },
    {
      id: 1,
      columns: [
        {
          data: `<strong>Provides reimbursement of eligible expenses in excess of Basic GHS</strong>`,
          info: `if:<br><ol type="a">
          <li><em>Hospitalisation is at least 20 days; or</em></li>
          <li><em>Surgical Percentage is at least 75% per incision</em></li>
          </ol>`
        },
        {
          data: `S$ 80,000`,
          info: `per disability`,
          attributes: {
            style: {
              whiteSpace: "nowrap"
            }
          }
        },
        {
          data: `S$ 60,000`,
          info: `per disability`,
          attributes: {
            style: {
              whiteSpace: "nowrap"
            }
          }
        },
        {
          data: `S$ 40,000`,
          info: `per disability`,
          attributes: {
            style: {
              whiteSpace: "nowrap"
            }
          }
        },
        {
          data: `S$ 60,000`,
          info: `per disability`,
          attributes: {
            style: {
              whiteSpace: "nowrap"
            }
          }
        },
        {
          data: `S$ 20,000`,
          info: `per disability`,
          attributes: {
            style: {
              whiteSpace: "nowrap"
            }
          }
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>Daily Home Nursing Benefit</strong>`,
          info: `<em>(max. per day, up to 30 days per disability)</em>`
        },
        {
          data: `S$ 80 per day for all plans`,
          info: `(subject to respective benefit limit)`,
          attributes: {
            colspan: 5,
            style: {
              width: "57%",
              textAlign: "right"
            }
          }
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>HIV Due to Blood Transfusion and Occupationally Acquired HIV</strong>`
        },
        {
          data: `S$ 5,000 per policy year`,
          info: `(subject to respective benefit limit)`,
          attributes: {
            colspan: 5,
            style: {
              width: "57%",
              textAlign: "right"
            }
          }
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>Parent Accommodation</strong>`,
          info: `<em>(up to 60 days for accompanying child age 12 and below)</em>`
        },
        {
          data: `S$ 100 per day for all plans`,
          info: `(subject to respective benefit limit)`,
          attributes: {
            colspan: 5,
            style: {
              width: "57%",
              textAlign: "right"
            }
          }
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>Deductible</strong>`
        },
        {
          data: `As per Basic GHS`,
          attributes: {
            colspan: 5,
            style: {
              width: "100%",
              textAlign: "center"
            }
          }
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>Co-Insurance</strong>`
        },
        {
          data: `20%`,
          attributes: {
            colspan: 5,
            style: {
              width: "100%",
              textAlign: "center"
            }
          }
        }
      ]
    }
  ],
  footnotes: []
};

let GEMMPremiumEmployee = {
  title: `<strong>For Employee Only</strong> <i>(inclusive of 7% GST)</i>`,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 33.00`
        },
        {
          data: `S$ 26.00`
        },
        {
          data: `S$ 17.00`
        },
        {
          data: `S$ 12.00`
        },
        {
          data: `S$ 9.00`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 33.00`
        },
        {
          data: `S$ 26.00`
        },
        {
          data: `S$ 17.00`
        },
        {
          data: `S$ 12.00`
        },
        {
          data: `S$ 9.00`
        }
      ]
    }
  ],
  footnotes: []
};

let GEMMPremiumSpouseOrChildren = {
  title: `<strong>For Employee and Spouse or Children Coverage Only</strong> <i>(inclusive of 7% GST)</i>`,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 74.25`
        },
        {
          data: `S$ 58.50`
        },
        {
          data: `S$ 38.25`
        },
        {
          data: `S$ 27.00`
        },
        {
          data: `S$ 20.25`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 74.25`
        },
        {
          data: `S$ 58.50`
        },
        {
          data: `S$ 38.25`
        },
        {
          data: `S$ 27.00`
        },
        {
          data: `S$ 20.25`
        }
      ]
    }
  ],
  footnotes: []
};

let GEMMPremiumSpouseAndChildren = {
  title: `<strong>For Employees and Spouse and Children Coverage</strong> <i>(inclusive of 7% GST)</i>`,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 115.50`
        },
        {
          data: `S$ 91.00`
        },
        {
          data: `S$ 59.50`
        },
        {
          data: `S$ 42.00`
        },
        {
          data: `S$ 31.50`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 115.50`
        },
        {
          data: `S$ 91.00`
        },
        {
          data: `S$ 59.50`
        },
        {
          data: `S$ 42.00`
        },
        {
          data: `S$ 31.50`
        }
      ]
    }
  ],
  footnotes: []
};
//   ----------------------------------------------------------------------
const GEMM = {
  id: 1,
  code: "GEMM",
  label: "Group Extended Major Medical (GEMM)",
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GEMMBenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium (inclusive of 7% GST)",
      data: [GEMMPremiumEmployee, GEMMPremiumSpouseOrChildren, GEMMPremiumSpouseAndChildren]
    }
  ]
};

export default GEMM;
