import { APIResource, APIError, APIResponse } from "../utils/APIResource";

export default class ValidationService {
  static emailValidation(emails) {
    const removeNullFromEmailList = emails.filter(e => e !== undefined);
    return APIResource.post("/v1/person/validateEmail", {
      emails: removeNullFromEmailList
    })
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }
}
