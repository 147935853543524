import * as React from "react";
import { SvgIcon, Box } from "@mui/material";

export default function IconBenefit(props) {
  return (
    <SvgIcon {...props}>
      <Box
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11.552 10.409a.5.5 0 0 1 .896 0l1.493 3.024a.5.5 0 0 0 .376.273l3.338.485a.5.5 0 0 1 .277.853l-2.415 2.354a.5.5 0 0 0-.144.443l.57 3.324a.5.5 0 0 1-.725.527l-2.985-1.57a.5.5 0 0 0-.466 0l-2.985 1.57a.5.5 0 0 1-.725-.527l.57-3.324a.5.5 0 0 0-.144-.443l-2.415-2.354a.5.5 0 0 1 .277-.853l3.338-.485a.5.5 0 0 0 .376-.273l1.493-3.024ZM18 2H6v5l6 2.5L18 7V2Z"
        component="path"
      />
    </SvgIcon>
  );
}
