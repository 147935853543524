import Enums from "../../../../../utils/Enums";

const GPCGSumAssuredEmployee = {
  title: `<br><strong>Employee<strong>`,
  headers: [
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "30%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Group Pre Crisis Guard</strong>`
        },
        {
          data: `S$ 75,000`
        },
        {
          data: `S$ 30,000`
        },
        {
          data: `S$ 22,500`
        },
        {
          data: `S$ 12,000`
        },
        {
          data: `S$ 7,500`
        }
      ]
    }
  ],
  footnotes: []
};

const GPCGSumAssuredDependant = {
  title: `<br><strong>Dependant<strong>`,
  headers: [
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "30%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Group Pre Crisis Guard</strong>`
        },
        {
          data: `S$ 37,500`
        },
        {
          data: `S$ 15,000`
        },
        {
          data: `S$ 11,250`
        },
        {
          data: `S$ 6,000`
        },
        {
          data: `S$ 3,750`
        }
      ]
    }
  ],
  footnotes: []
};

let GPCGPremiumEmployee = {
  title: `<strong> * For Employee Only </strong>`,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 19</strong>`
        },
        {
          data: `S$ 35.00`
        },
        {
          data: `S$ 14.00`
        },
        {
          data: `S$ 11.00`
        },
        {
          data: `S$ 6.00`
        },
        {
          data: `S$ 4.00`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>20 - 24</strong>`
        },
        {
          data: `S$ 39.00`
        },
        {
          data: `S$ 16.00`
        },
        {
          data: `S$ 12.00`
        },
        {
          data: `S$ 7.00`
        },
        {
          data: `S$ 4.00`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>25 - 29</strong>`
        },
        {
          data: `S$ 54.00`
        },
        {
          data: `S$ 22.00`
        },
        {
          data: `S$ 17.00`
        },
        {
          data: `S$ 9.00`
        },
        {
          data: `S$ 6.00`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>30 - 34</strong>`
        },
        {
          data: `S$ 78.00`
        },
        {
          data: `S$ 32.00`
        },
        {
          data: `S$ 24.00`
        },
        {
          data: `S$ 13.00`
        },
        {
          data: `S$ 8.00`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>35 - 39</strong>`
        },
        {
          data: `S$ 90.00`
        },
        {
          data: `S$ 36.00`
        },
        {
          data: `S$ 27.00`
        },
        {
          data: `S$ 15.00`
        },
        {
          data: `S$ 9.00`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>40 - 44</strong>`
        },
        {
          data: `S$ 113.00`
        },
        {
          data: `S$ 46.00`
        },
        {
          data: `S$ 34.00`
        },
        {
          data: `S$ 19.00`
        },
        {
          data: `S$ 12.00`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>45 - 49</strong>`
        },
        {
          data: `S$ 164.00`
        },
        {
          data: `S$ 66.00`
        },
        {
          data: `S$ 50.00`
        },
        {
          data: `S$ 27.00`
        },
        {
          data: `S$ 17.00`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>50 - 54</strong>`
        },
        {
          data: `S$ 189.00`
        },
        {
          data: `S$ 76.00`
        },
        {
          data: `S$ 57.00`
        },
        {
          data: `S$ 31.00`
        },
        {
          data: `S$ 19.00`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>55 - 59</strong>`
        },
        {
          data: `S$ 258.00`
        },
        {
          data: `S$ 104.00`
        },
        {
          data: `S$ 78.00`
        },
        {
          data: `S$ 42.00`
        },
        {
          data: `S$ 26.00`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>60 - 64</strong>`
        },
        {
          data: `S$ 391.00`
        },
        {
          data: `S$ 157.00`
        },
        {
          data: `S$ 118.00`
        },
        {
          data: `S$ 63.00`
        },
        {
          data: `S$ 40.00`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>65 - 69</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 612.00`
        },
        {
          data: `S$ 245.00`
        },
        {
          data: `S$ 184.00`
        },
        {
          data: `S$ 98.00`
        },
        {
          data: `S$ 62.00`
        }
      ]
    }
  ],
  footnotes: [
    {
      data: `Note: Coverage for Group Crisis Cover Accelerated benefit ceases at age 70 last birthday.`
    }
  ]
};

let GPCGPremiumDependent = {
  title: `<br><strong> * For Dependant Only </strong>`,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 19</strong>`
        },
        {
          data: `S$ 19.00`
        },
        {
          data: `S$ 8.00`
        },
        {
          data: `S$ 6.00`
        },
        {
          data: `S$ 3.00`
        },
        {
          data: `S$ 2.00`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>20 - 24</strong>`
        },
        {
          data: `S$ 21.00`
        },
        {
          data: `S$ 9.00`
        },
        {
          data: `S$ 6.00`
        },
        {
          data: `S$ 4.00`
        },
        {
          data: `S$ 2.00`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>25 - 29</strong>`
        },
        {
          data: `S$ 29.00`
        },
        {
          data: `S$ 12.00`
        },
        {
          data: `S$ 9.00`
        },
        {
          data: `S$ 5.00`
        },
        {
          data: `S$ 3.00`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>30 - 34</strong>`
        },
        {
          data: `S$ 42.00`
        },
        {
          data: `S$ 17.00`
        },
        {
          data: `S$ 13.00`
        },
        {
          data: `S$ 7.00`
        },
        {
          data: `S$ 4.00`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>35 - 39</strong>`
        },
        {
          data: `S$ 49.00`
        },
        {
          data: `S$ 19.00`
        },
        {
          data: `S$ 15.00`
        },
        {
          data: `S$ 8.00`
        },
        {
          data: `S$ 5.00`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>40 - 44</strong>`
        },
        {
          data: `S$ 61.00`
        },
        {
          data: `S$ 25.00`
        },
        {
          data: `S$ 18.00`
        },
        {
          data: `S$ 10.00`
        },
        {
          data: `S$ 6.00`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>45 - 49</strong>`
        },
        {
          data: `S$ 89.00`
        },
        {
          data: `S$ 36.00`
        },
        {
          data: `S$ 27.00`
        },
        {
          data: `S$ 15.00`
        },
        {
          data: `S$ 9.00`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>50 - 54</strong>`
        },
        {
          data: `S$ 102.00`
        },
        {
          data: `S$ 41.00`
        },
        {
          data: `S$ 31.00`
        },
        {
          data: `S$ 17.00`
        },
        {
          data: `S$ 10.00`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>55 - 59</strong>`
        },
        {
          data: `S$ 139.00`
        },
        {
          data: `S$ 56.00`
        },
        {
          data: `S$ 42.00`
        },
        {
          data: `S$ 23.00`
        },
        {
          data: `S$ 14.00`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>60 - 64</strong>`
        },
        {
          data: `S$ 211.00`
        },
        {
          data: `S$ 85.00`
        },
        {
          data: `S$ 64.00`
        },
        {
          data: `S$ 34.00`
        },
        {
          data: `S$ 22.00`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>65 - 69</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 330.00`
        },
        {
          data: `S$ 132.00`
        },
        {
          data: `S$ 99.00`
        },
        {
          data: `S$ 53.00`
        },
        {
          data: `S$ 33.00`
        }
      ]
    }
  ],
  footnotes: [
    {
      data: `Note: Coverage for Group Crisis Cover Accelerated benefit ceases at age 70 last birthday.`
    }
  ]
};

//   ----------------------------------------------------------------------
const GEAC = {
  id: 11,
  code: "GEAC",
  label: "Group Pre Crisis Guard (GPCG)",
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GPCGSumAssuredEmployee, GPCGSumAssuredDependant]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium",
      data: [GPCGPremiumEmployee, GPCGPremiumDependent]
    }
  ]
};

export default GEAC;
