import { addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import zh from "react-intl/locale-data/zh";
import localeData from "./locales";

addLocaleData([...en, ...zh]);

// const defaultLocale = "en";

const systemLocale = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;

const languageWithoutRegionCode = systemLocale.toLowerCase().split(/[_-]+/)[0];

const defaultLocale = localeData[systemLocale]
  ? systemLocale
  : localeData[languageWithoutRegionCode]
  ? languageWithoutRegionCode
  : "en";

// const messages = defaultLocale
//   ? localeData[defaultLocale]
//   : localeData[languageWithoutRegionCode] ||
//     localeData[language] ||
//     localeData["en"];

const getMessages = locale => {
  return localeData[locale] || localeData[languageWithoutRegionCode] || localeData[defaultLocale];
};

export { defaultLocale, getMessages };
