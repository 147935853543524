import { bindActionCreators } from "redux";
import { companyActions, quoteActions, documentActions } from "../../../../../../actions";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import React from "react";
import TextFieldWithValidator from "../../../../../../form/presentational/TextFieldWithValidator";
import SelectFieldWithValidator from "../../../../../../form/presentational/SelectFieldWithValidator";
import ReduxFormValidate from "../../../../../../utils/ReduxFormValidate";
import Util from "../../../../../../utils/Util";
import Enums from "../../../../../../utils/Enums";

const CompanyDetailForm = props => {
  let { readOnly, quote, document } = props;

  readOnly = !readOnly && document.hasSignedProposal ? true : readOnly;

  const agentCodeValidationMessage = value =>
    value && !(value === props.user.username) ? "Please ensure your agent number is valid." : undefined;

  if (props.quote.stages !== "SUBMITTED" && props.quote.stages !== "INFORCE") {
    if (props.initialValues.agent_code !== props.user.username) {
      readOnly = false;
      props.documentActions.resetFiles();
      props.document.files.map(file => {
        if (file && file.file_type === Enums.FILE_TYPE.ACCEPTANCE_FILE_TYPE) {
          props.documentActions.deleteFile(props.quote.id, file.encrypted_id).then(() => {});
        }
      });
    }
  }

  const fieldValidations = !document.hasSignedProposal
    ? [ReduxFormValidate.maxLength100, ReduxFormValidate.nbstpValidationCompanyName]
    : [];

  const fieldValidationsCompanyContactName = !document.hasSignedProposal
    ? [ReduxFormValidate.maxLength100, ReduxFormValidate.nbstpValidationCompanyContactName]
    : [];

  const fieldValidationsPhoneNumber = !document.hasSignedProposal
    ? [
        ReduxFormValidate.nbstpValidationLimitCompanyNumber,
        ReduxFormValidate.nbstpValidation,
        ReduxFormValidate.contactNumber
      ]
    : [];

  const fieldValidationsAddress1 = !document.hasSignedProposal
    ? [ReduxFormValidate.nbstpValidationLimitCompanyAddress1, ReduxFormValidate.nbstpValidation]
    : [];

  return (
    <div>
      <form onSubmit={props.handleSubmit}>
        <Grid container spacing={"24px"}>
          <Grid item xs={12} sm={6}>
            <Field
              name="name"
              validate={fieldValidations}
              disabled={readOnly}
              component={TextFieldWithValidator}
              autoComplete="[off]"
              label="Company Name"
              type="text"
              inputProps={{
                maxLength: 100
              }}
              onChange={(event, newValue, previousValue, name) => {
                const fieldConfig = {
                  key: name,
                  value: newValue === "" ? " " : newValue
                };
                props.companyActions.setCompanyFormDetails(fieldConfig);
                props.quoteActions.setName(newValue);
                props.handleEdits();
              }}
            />
            <Field
              name="contact_name"
              validate={fieldValidationsCompanyContactName}
              disabled={readOnly}
              component={TextFieldWithValidator}
              autoComplete="[off]"
              label="HR Contact Name"
              type="text"
              inputProps={{
                maxLength: 100
              }}
              onChange={(event, newValue, previousValue, name) => {
                const fieldConfig = { key: name, value: newValue };
                props.companyActions.setCompanyFormDetails(fieldConfig);
                props.handleEdits();
              }}
            />
            <Field
              name="contact_email"
              disabled={readOnly}
              validate={[
                ReduxFormValidate.hrEmail,
                ReduxFormValidate.isEmailForbiddenDomainPattern,
                ReduxFormValidate.isEmailForbiddenUsernamePatterns
              ]}
              component={TextFieldWithValidator}
              autoComplete="[off]"
              label="HR Contact Email"
              type="email"
              onChange={(event, newValue, previousValue, name) => {
                const fieldConfig = { key: name, value: newValue };
                props.companyActions.setCompanyFormDetails(fieldConfig);
                props.handleEdits();
              }}
            />
            <Field
              name="contact_no"
              disabled={readOnly}
              validate={fieldValidationsPhoneNumber}
              component={TextFieldWithValidator}
              autoComplete="[off]"
              label="HR Contact Phone Number"
              type="text"
              inputProps={{
                maxLength: 16
              }}
              onChange={(event, newValue, previousValue, name) => {
                const fieldConfig = { key: name, value: newValue };
                props.companyActions.setCompanyFormDetails(fieldConfig);
                props.handleEdits();
              }}
            />
            <Field
              name="address"
              disabled={readOnly}
              validate={fieldValidationsAddress1}
              component={TextFieldWithValidator}
              autoComplete="[off]"
              label="Enter Company Block/House No and Street/Road Name"
              inputProps={{
                maxLength: 30
              }}
              type="text"
              onChange={(event, newValue, previousValue, name) => {
                const fieldConfig = { key: name, value: newValue };
                props.companyActions.setCompanyFormDetails(fieldConfig);
                props.handleEdits();
              }}
            />
            <Field
              name="address2"
              validate={[ReduxFormValidate.nbstpValidationOptional]}
              disabled={readOnly}
              component={TextFieldWithValidator}
              autoComplete="[off]"
              label="Enter Company Floor and Unit No (Optional)"
              type="text"
              inputProps={{
                maxLength: 30
              }}
              onChange={(event, newValue, previousValue, name) => {
                const fieldConfig = { key: name, value: newValue };
                props.companyActions.setCompanyFormDetails(fieldConfig);
              }}
            />
            <Field
              name="address3"
              disabled={readOnly}
              component={TextFieldWithValidator}
              validate={[ReduxFormValidate.nbstpValidationOptional]}
              autoComplete="[off]"
              label="Enter Company Building/Estate Name (Optional)"
              type="text"
              inputProps={{
                maxLength: 30
              }}
              onChange={(event, newValue, previousValue, name) => {
                const fieldConfig = { key: name, value: newValue };
                props.companyActions.setCompanyFormDetails(fieldConfig);
                props.handleEdits();
              }}
            />
            <Field
              name="address4"
              disabled={readOnly}
              component={TextFieldWithValidator}
              validate={[ReduxFormValidate.nbstpValidationOptional]}
              autoComplete="[off]"
              label="Enter City (Optional)"
              type="text"
              inputProps={{
                maxLength: 30
              }}
              onChange={(event, newValue, previousValue, name) => {
                const fieldConfig = { key: name, value: newValue };
                props.companyActions.setCompanyFormDetails(fieldConfig);
                props.handleEdits();
              }}
            />
            <Field
              name="address5"
              disabled={readOnly}
              component={TextFieldWithValidator}
              validate={[ReduxFormValidate.nbstpValidationOptional]}
              autoComplete="[off]"
              label="Enter State (Optional)"
              type="text"
              inputProps={{
                maxLength: 30
              }}
              onChange={(event, newValue, previousValue, name) => {
                const fieldConfig = { key: name, value: newValue };
                props.companyActions.setCompanyFormDetails(fieldConfig);
                props.handleEdits();
              }}
            />
            <Field
              name="postal_code"
              validate={ReduxFormValidate.postalCode}
              disabled={readOnly}
              component={TextFieldWithValidator}
              autoComplete="[off]"
              label="Enter Company Postal Code"
              type="text"
              inputProps={{
                maxLength: 6
              }}
              onChange={(event, newValue, previousValue, name) => {
                const fieldConfig = { key: name, value: newValue };
                props.companyActions.setCompanyFormDetails(fieldConfig);
              }}
            />
            <Field
              fullWidth
              disabled={readOnly}
              name="nature_of_business"
              autoComplete="[off]"
              isSelect={true}
              component={SelectFieldWithValidator}
              label="Nature Of Business"
              onChange={(event, newValue, previousValue, name) => {
                const fieldConfig = { key: name, value: newValue };
                props.companyActions.setCompanyFormDetails(fieldConfig);
                props.handleEdits();
              }}
              onBlur={e => {
                e.preventDefault();
              }}
            >
              {props.natureOfBusinessList.map(nob => (
                <MenuItem value={nob} key={nob}>
                  {nob}
                </MenuItem>
              ))}
            </Field>
            <Field
              name="auth_signatory_name"
              validate={ReduxFormValidate.signatory}
              disabled={readOnly}
              component={TextFieldWithValidator}
              autoComplete="[off]"
              label="Authorised Signatory Name"
              type="text"
              inputProps={{
                maxLength: 100
              }}
              onChange={(event, newValue, previousValue, name) => {
                const fieldConfig = { key: name, value: newValue };
                props.companyActions.setCompanyFormDetails(fieldConfig);
                props.handleEdits();
              }}
            />
            <Field
              name="auth_signatory_designation"
              validate={ReduxFormValidate.signatory}
              disabled={readOnly}
              component={TextFieldWithValidator}
              autoComplete="[off]"
              label="Authorised Signatory Designation"
              type="text"
              onChange={(event, newValue, previousValue, name) => {
                const fieldConfig = { key: name, value: newValue };
                props.companyActions.setCompanyFormDetails(fieldConfig);
                props.handleEdits();
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="encrypted_password"
              validate={ReduxFormValidate.encryptedPassword}
              disabled={readOnly}
              component={TextFieldWithValidator}
              autoComplete="[off]"
              label="Set password to protect this quote"
              type="password"
              helperText="Password is up to 20 characters"
              inputProps={{
                maxLength: 20
              }}
              onChange={(event, newValue, previousValue, name) => {
                const fieldConfig = { key: name, value: newValue };
                props.companyActions.setCompanyFormDetails(fieldConfig);
                props.handleEdits();
              }}
            />
            <Field
              name="notes"
              disabled={readOnly}
              component={TextFieldWithValidator}
              autoComplete="[off]"
              label="Notes (Optional)"
              type="text"
              multiline
              rows={7}
              onChange={(event, newValue, previousValue, name) => {
                const fieldConfig = { key: name, value: newValue };
                props.companyActions.setCompanyFormDetails(fieldConfig);
                props.handleEdits();
              }}
            />
            {props.agentType === Enums.P_CODE.SCB_ASSISTED && (
              <Field
                name="referral_id_1"
                disabled={readOnly}
                validate={ReduxFormValidate.referralCode}
                component={TextFieldWithValidator}
                autoComplete="[off]"
                label="Referral ID 1"
                type="text"
                helperText="Referral ID is up to 7 characters"
                inputProps={{
                  maxLength: 7
                }}
                onChange={(event, newValue, previousValue, name) => {
                  const fieldConfig = { key: name, value: newValue };
                  props.companyActions.setCompanyFormDetails(fieldConfig);
                  props.handleEdits();
                }}
              />
            )}
            {props.agentType === Enums.P_CODE.SCB_ASSISTED && (
              <Field
                name="referral_id_2"
                disabled={readOnly}
                validate={ReduxFormValidate.referralCode}
                component={TextFieldWithValidator}
                autoComplete="[off]"
                label="Referral ID 2"
                type="text"
                helperText="Referral ID is up to 7 characters"
                inputProps={{
                  maxLength: 7
                }}
                onChange={(event, newValue, previousValue, name) => {
                  const fieldConfig = { key: name, value: newValue };
                  props.companyActions.setCompanyFormDetails(fieldConfig);
                  props.handleEdits();
                }}
              />
            )}
            <Field
              name="agent_first_name"
              disabled={readOnly}
              component={TextFieldWithValidator}
              autoComplete="[off]"
              label="Agent First Name"
              type="text"
              inputProps={{
                maxLength: 100
              }}
              onChange={(event, newValue, previousValue, name) => {
                const fieldConfig = { key: name, value: newValue };
                props.companyActions.setCompanyFormDetails(fieldConfig);
                props.handleEdits();
              }}
            />
            <Field
              name="agent_last_name"
              disabled={readOnly}
              component={TextFieldWithValidator}
              autoComplete="[off]"
              label="Agent Surname"
              type="text"
              inputProps={{
                maxLength: 100
              }}
              onChange={(event, newValue, previousValue, name) => {
                const fieldConfig = { key: name, value: newValue };
                props.companyActions.setCompanyFormDetails(fieldConfig);
                props.handleEdits();
              }}
            />
            <Field
              name="agent_code"
              disabled={readOnly}
              component={TextFieldWithValidator}
              autoComplete="[off]"
              label="Agent Code"
              type="text"
              inputProps={{
                maxLength: 100
              }}
              onChange={(event, newValue, previousValue, name) => {
                const fieldConfig = { key: name, value: newValue };
                props.companyActions.setCompanyFormDetails(fieldConfig);
                props.handleEdits();
              }}
              validate={
                props.quote.stages !== "SUBMITTED" && props.quote.stages !== "INFORCE"
                  ? [agentCodeValidationMessage]
                  : undefined
              }
            />
            <Field
              name="agent_email"
              disabled={readOnly}
              validate={ReduxFormValidate.email}
              component={TextFieldWithValidator}
              autoComplete="[off]"
              label="Agent Email"
              type="text"
              helperText="Mandatory to enter pruadviser or prufa email"
              FormHelperTextProps={{ style: { fontWeight: "bold" } }}
              inputProps={{
                maxLength: 100
              }}
              onChange={(event, newValue, previousValue, name) => {
                const fieldConfig = { key: name, value: newValue };
                props.companyActions.setCompanyFormDetails(fieldConfig);
                props.handleEdits();
              }}
            />
            <Field
              name="bank_account_name"
              disabled={readOnly}
              component={TextFieldWithValidator}
              autoComplete="[off]"
              label="Corporate Bank Account Name"
              type="text"
              inputProps={{
                maxLength: 100
              }}
              onChange={(event, newValue, previousValue, name) => {
                const fieldConfig = { key: name, value: newValue };
                props.companyActions.setCompanyFormDetails(fieldConfig);
                props.handleEdits();
              }}
            />
            <Field
              name="bank_code"
              disabled={readOnly}
              component={TextFieldWithValidator}
              autoComplete="[off]"
              label="Bank Code"
              type="text"
              inputProps={{
                maxLength: 100
              }}
              onChange={(event, newValue, previousValue, name) => {
                const fieldConfig = { key: name, value: newValue };
                props.companyActions.setCompanyFormDetails(fieldConfig);
                props.handleEdits();
              }}
            />
            <Field
              name="bank_name"
              disabled={readOnly}
              component={TextFieldWithValidator}
              autoComplete="[off]"
              label="Bank Name"
              type="text"
              inputProps={{
                maxLength: 100
              }}
              onChange={(event, newValue, previousValue, name) => {
                const fieldConfig = { key: name, value: newValue };
                props.companyActions.setCompanyFormDetails(fieldConfig);
                props.handleEdits();
              }}
            />
            <Field
              name="bank_account_number"
              disabled={readOnly}
              component={TextFieldWithValidator}
              autoComplete="[off]"
              label="Bank Account Number"
              type="text"
              inputProps={{
                maxLength: 100
              }}
              onChange={(event, newValue, previousValue, name) => {
                const fieldConfig = { key: name, value: newValue };
                props.companyActions.setCompanyFormDetails(fieldConfig);
                props.handleEdits();
              }}
            />
            <Field
              name="branch_code"
              disabled={readOnly}
              component={TextFieldWithValidator}
              autoComplete="[off]"
              label="Branch Code"
              type="text"
              inputProps={{
                maxLength: 100
              }}
              onChange={(event, newValue, previousValue, name) => {
                const fieldConfig = { key: name, value: newValue };
                props.companyActions.setCompanyFormDetails(fieldConfig);
                props.handleEdits();
              }}
            />
            <Field
              name="bank_swift_code"
              disabled={readOnly}
              component={TextFieldWithValidator}
              autoComplete="[off]"
              label="Bank Swift Code"
              type="text"
              inputProps={{
                maxLength: 100
              }}
              onChange={(event, newValue, previousValue, name) => {
                const fieldConfig = { key: name, value: newValue };
                props.companyActions.setCompanyFormDetails(fieldConfig);
                props.handleEdits();
              }}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    initialValues: state.company && state.company.details && Util.trimObj(state.company.details),
    quote: state.quote,
    readOnly: state.quote.readOnly,
    natureOfBusinessList: state.document.natureOfBusiness,
    user: state.user,
    agentType: state.user.auth.pcode,
    document: state.document
  };
}

function mapDispatchToProps(dispatch) {
  return {
    companyActions: bindActionCreators(companyActions, dispatch),
    quoteActions: bindActionCreators(quoteActions, dispatch),
    documentActions: bindActionCreators(documentActions, dispatch)
  };
}

function submit(values) {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "company-detail",
    enableReinitialize: true,
    destroyOnUnmount: false,
    onSubmit: submit
  })(CompanyDetailForm)
);
