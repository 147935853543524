import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Enums from "../utils/Enums";
import TextField from "@mui/material/TextField";
import config from "../config/config";
import { quoteActions } from "../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Util from "../utils/Util";

const existing_policy_no_mandatory_text = "Please enter existing policy number";

class QuoteTypeDialogue extends React.Component {
  // constructor(props) {
  //   super(props);

  state = {
    open: false,
    errorOpen: false,
    openQuoteDialogue: false,
    business_type: Enums.BUSINESS_TYPE.NEW,
    visible: true,
    policyNo: "",
    existing_policy_no: "",
    existing_policy_no_error_text: ""
  };
  businessType = [
    {
      id: Enums.BUSINESS_TYPE.NEW,
      name: Enums.BUSINESS_TYPE.NEW_BUSINESS
    },
    {
      id: Enums.BUSINESS_TYPE.CONVERSION,
      name: Enums.BUSINESS_TYPE.CONVERSION_BUSINESS
    }
  ];

  handleChange = (event, value) => {
    if (event.target.value === Enums.BUSINESS_TYPE.CONVERSION) {
      this.setState({
        visible: false,
        business_type: Enums.BUSINESS_TYPE.CONVERSION
      });
    } else if (event.target.value === Enums.BUSINESS_TYPE.NEW) {
      this.setState({
        visible: true,
        policyNo: "",
        business_type: Enums.BUSINESS_TYPE.NEW
      });
    }
    // this.setState({ business_type: value }, () => {
    //   this.props.setBusinessType(this.state.business_type);
    // });
  };

  handleInput = event => {
    let value = event.target.value;
    this.setState({
      policyNo: value,
      existing_policy_no: value
    });
  };

  validateExistingPolicyNo = () => {
    if (this.state.business_type === Enums.BUSINESS_TYPE.NEW) {
      this.props.confirmHandler(this.state.policyNo, Enums.BUSINESS_TYPE.NEW);
    } else if (this.state.business_type === Enums.BUSINESS_TYPE.CONVERSION) {
      if (Util.isEmpty(this.state.policyNo)) {
        this.setState({ existing_policy_no_error_text: existing_policy_no_mandatory_text });
        return;
      } else {
        if (!config.disable_conversion) {
          this.props.getExistingPolicyDetails(this.state.policyNo, this.props.user.username).then(
            resp => {
              this.setState({
                existing_policy_no_error_text: "",
                policyNo: "",
                visible: true,
                business_type: Enums.BUSINESS_TYPE.NEW
              });
              this.props.confirmHandler(this.state.policyNo, Enums.BUSINESS_TYPE.CONVERSION);
              this.props.setExistingPolicyNo(this.state.existing_policy_no);
            },
            error => {
              this.setState({
                existing_policy_no_error_text: error.message
              });
            }
          );
        } else {
          this.props.confirmHandler(this.state.policyNo, Enums.BUSINESS_TYPE.CONVERSION);
        }
      }
    }
  };

  handleCloseButton = () => {
    this.setState({
      existing_policy_no_error_text: "",
      policyNo: "",
      visible: true,
      business_type: Enums.BUSINESS_TYPE.NEW
    });
    // this.props.setBusinessType(Enums.BUSINESS_TYPE.NEW);
    this.props.closeHandler(this.state.business_type);
  };

  render() {
    const {
      open,
      closeHandler,
      closeButtonText,
      errorMessage,
      confirmHandler,
      confirmButtonText,
      icon,
      classes,
      name,
      title,
      description
    } = this.props;
    return (
      <Dialog
        open={open}
        onClose={closeHandler}
        aria-labelledby={`${name}-title`}
        aria-describedby={`${name}-description`}
        //maxWidth="xs"
        classes={{
          paper: classes.paper
        }}
      >
        {icon && <div className={classes.dialogIcon}>{icon}</div>}
        <DialogContent classes={{ root: classes.dialogContent }}>
          {/* <DialogContentText>Quote For</DialogContentText> */}
          <DialogTitle classes={{ root: classes.dialogTitle }} disableTypography={true}>
            {title}
          </DialogTitle>
          <Typography variant="body1" color="textSecondary">
            {description}
          </Typography>
          <Typography variant="body1" color="textSecondary" className={classes.newQuoteMessage}>
            To proceed, please also select the type of business for your new quote
          </Typography>
        </DialogContent>
        <FormControl style={{ paddingLeft: "5%" }} component="fieldset">
          <FormLabel component="legend" />
          <RadioGroup
            className={classes.businessTypeRadioGroup}
            value={this.state.business_type}
            onChange={this.handleChange}
          >
            {this.businessType.map((type, idx) => {
              return (
                <FormControlLabel
                  key={idx}
                  value={type.id}
                  control={<Radio classes={{ default: classes.businessTypeLabel }} />}
                  label={type.name}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
        <div style={{ paddingLeft: "5%", width: "90%", paddingBottom: "5%" }} hidden={this.state.visible}>
          <TextField
            error={this.state.existing_policy_no_error_text.length === 0 ? false : true}
            helperText={this.state.existing_policy_no_error_text}
            autoFocus
            type="text"
            id="name"
            label="Existing Policy Number"
            value={this.state.policyNo}
            onChange={this.handleInput}
            fullWidth
          />
        </div>
        <DialogActions style={{ paddingLeft: "2%" }} className={classes.dialogAction}>
          <Button
            onClick={this.handleCloseButton}
            variant="raised"
            className={classNames(classes.button, classes.buttonCancel)}
            autoFocus
            style={{ color: "#000" }}
          >
            {closeButtonText}
          </Button>
          <Button
            style={{ marginLeft: "30%" }}
            onClick={this.validateExistingPolicyNo}
            className={classes.button}
            color="primary"
            variant="contained"
            autoFocus
          >
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = theme => ({
  paper: {
    padding: 30
  },
  dialogIcon: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20
  },
  dialogTitle: {
    paddingLeft: 0,
    fontSize: "1.1em",
    fontFamily: theme.typography.fontFamily
  },
  dialogContent: {
    maxWidth: 320,
    "&:first-child": {
      padding: "0 15px 15px 15px"
    }
  },
  button: {
    width: 130
  },
  buttonCancel: {
    backgroundColor: "transparent",
    border: `2px solid ${theme.colors.blackScale.black60}`
  },
  dialogAction: {
    marginBottom: 20,
    // alignItems: "start",
    justifyContent: "flex-start"
  },
  businessTypeRadioGroup: {
    display: "inline-block"
  },
  businessTypeLabel: {
    color: "rgba(0, 0, 0, 0.54)"
  },
  newQuoteMessage: {
    marginTop: 10
  }
});

function mapStateToProps(state) {
  return {
    company: state.company,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(quoteActions, dispatch),
    setBusinessType: (...payload) => dispatch(quoteActions.setBusinessType(...payload)),
    setExistingPolicyNo: (...payload) => dispatch(quoteActions.setExistingPolicyNo(...payload))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(QuoteTypeDialogue));
