import Enums from "../../../../../utils/Enums";

const GDBenefits = {
  headers: [
    {
      title: ``,
      mandatory: true,
      attributes: {
        style: {
          width: 10
        }
      }
    },
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: 200
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>1</strong>`
        },
        {
          data: `<strong>Medication Fee</strong>`
        },
        {
          data: `As charged for item 1 to 9`,
          attributes: {
            rowspan: 9
          }
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>2</strong>`
        },
        {
          data: `<strong>X-Ray</strong>`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>3</strong>`
        },
        {
          data: `<strong>Prophylaxis Treatment</strong>`,
          info: `(General Scaling and Polishing)`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>4</strong>`
        },
        {
          data: `<strong>Fillings</strong>`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>5</strong>`
        },
        {
          data: `<strong>	Simple and Surgical Extraction</strong>`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>6</strong>`
        },
        {
          data: `<strong>Root Canal Treatment</strong>`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>7</strong>`
        },
        {
          data: `<strong>Gum Treatment</strong>`,
          info: `(including Curettage)`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>8</strong>`
        },
        {
          data: `<strong>Periodontal Surgery</strong>`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>9</strong>`
        },
        {
          data: `<strong>Repairs of Dentures, Crowns and Bridges Due to Accident</strong>`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>10</strong>`
        },
        {
          data: `<strong>Overall Dental's Limit</strong>`,
          info: `(per policy year)`
        },
        {
          data: `S$500`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>11</strong>`
        },
        {
          data: `<strong>Co-insurance</strong>`
        },
        {
          data: `20%`
        }
      ]
    }
  ],
  footnotes: []
};

let GDPremium = {
  headers: [
    {
      title: `Plan Type`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Annual Premium</strong>`
        },
        {
          data: `S$ 295.00`
        }
      ]
    }
  ],
  footnotes: []
};

let Occupational = {
  title: `Occupational`,
  headers: [
    {
      title: `Class`,
      mandatory: true
    },
    {
      title: `Occupations`,
      mandatory: true
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Class 1</strong>`
        },
        {
          data: `Clerical, administrative or other similar non-hazardous occupations`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>Class 2</strong>`
        },
        {
          data: `Occupations where some degree of risk is involved, e.g. supervision of manual workers, totally administrative job in an industrial environment`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>Class 3</strong>`
        },
        {
          data: `Occupations involving regular light to medium manual work but no substantial hazard which may increase the risk of sickness or accident`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>Class 4</strong>`
        },
        {
          data: `<strong>Not covered</strong>`
        }
      ]
    }
  ],
  footnotes: []
};

//   ----------------------------------------------------------------------
const GP = {
  id: 1,
  code: "GDEN",
  label: `Group Dental (GDEN)`,
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GDBenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium (inclusive of 7% GST)",
      data: [GDPremium, Occupational]
    }
  ]
};

export default GP;
