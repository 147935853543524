import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import { Typography, Icon, Grid, Button } from "@mui/material";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { policyEmployeeActions } from "../../../../actions";
import MyEmployeeTable from "./PolicyMyEmployeeTable";
import CustomBreadcrumb from "../../../../components/CustomBreadcrumb";
import CustomTooltip from "../../../../components/CustomTooltip";

class MyEmployees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPageNo: 0,
      openDependentDialog: false
    };

    this.props.getMyEmployeesInformation();
    this.props.getCategories();
  }

  handleDownload = () => () => {
    this.props.exportFCPolicyEmployee();
  };

  handlePageChange = (event, pageIndex) => {
    this.setState({
      currentPageNo: pageIndex
    });
  };

  handleResetPage = () => {
    this.setState({
      currentPageNo: 0
    });
  };

  render() {
    const { classes, policyEmployees, policy } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.breadcrumb}>
          <CustomBreadcrumb currentPage="MyEmployees" companyName={policy.companyName} />
        </div>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Typography variant="h5" color="inherit" className={classes.title}>
              {policy.companyName && `${policy.companyName}'s`} Member Listing
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <div className={classes.downloadLink}>
              <CustomTooltip title="Download Excel Report" placement="right" arrow>
                <Button className="tooltip" size="small" onClick={this.handleDownload()}>
                  <Icon>{"file_download"}</Icon>
                </Button>
              </CustomTooltip>
            </div>
          </Grid>
        </Grid>
        <br />
        <MyEmployeeTable
          data={policyEmployees.data}
          currentPageNo={this.state.currentPageNo}
          handleResetPage={this.handleResetPage}
          handlePageChange={this.handlePageChange}
        />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    overflowX: "auto",
    paddingLeft: "10px"
  },
  title: {
    fontSize: "20px",
    fontWeight: "600 !important"
  },
  breadcrumb: {
    padding: "30px 0px 30px 0px"
  },
  content: {
    padding: theme.spacing.unit * 2.5,
    paddingTop: 10,
    marginBottom: 65
  },
  downloadLink: {
    "&:hover": {
      cursor: "pointer"
    },
    textAlign: "right"
  }
});

function mapStateToProps(state) {
  return {
    policy: state.policyDetails.policy,
    policyEmployees: state.policyMyEmployees
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(policyEmployeeActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(MyEmployees)));
