const class_1 = [
  "Abbot",
  "Abbot Assistant",
  "Account Executive",
  "Account Manager",
  "Accountant",
  "Accounts Assistant",
  "Accounts Executive",
  "Actuary",
  "Acupuncturist",
  "Adjuster",
  "Admin Assistant",
  "Administration Assistant",
  "Administrative Exec / Manager",
  "Administrative Officer",
  "Administrative Personnel",
  "Administrator",
  "Advertising Exec / Manager",
  "Advertising Personnel (admin)",
  "Airlines personnel (admin)",
  "Anaesthetist",
  "Analyst (financial)",
  "Analyst (systems)",
  "Analyst Programmer",
  "Announcer",
  "Applications Consultant",
  "Architect",
  "Assessor",
  "Auditor",
  "Author",
  "Baby",
  "Bank Officer",
  "Bank Staff",
  "Banker",
  "Barrister",
  "Broker (stock)",
  "Business Analyst",
  "Business Consultant",
  "Business Devt Exec/Manager",
  "Businessman (admin only)",
  "Buyer",
  "Calligrapher",
  "Cameraman (indoor)",
  "Cashier",
  "Chairman",
  "Chief Officer",
  "Child",
  "Church Worker",
  "Civil Servant (admin)",
  "Clerk",
  "Clinic Assistant",
  "Communications Officer (admin)",
  "Compere",
  "Computer Professional",
  "Conference Manager",
  "Consultant (admin only)",
  "Consultant (medical)",
  "Copywriter",
  "Costing Business",
  "Counsellor",
  "Courier Services (admin)",
  "Court Assistant",
  "Credit Analyst",
  "Credit Controller",
  "Currency Dealer",
  "Cushioner",
  "Custom Officer (admin only)",
  "Customer Service Officer",
  "Customer Svs Officer/Exec/Mgr",
  "Dealer",
  "Dental Practitioner",
  "Dentist",
  "Dentist Assistant",
  "Deputy",
  "Designer",
  "Designer (fashion)",
  "Developer",
  "Dietician",
  "Director (admin only)",
  "Doctor",
  "Draftsman",
  "Drama Director/Teacher",
  "Draughtsman",
  "E-Commerce Exec/Manager",
  "Economist",
  "Editor",
  "Education Officer",
  "Engineer (computer)",
  "Engineer (software)",
  "Estimator",
  "Examiner",
  "Executive (admin)",
  "Expeditor",
  "Fashion Designer",
  "Film Critic",
  "Financial Analyst",
  "Financial Controller / Manager",
  "Fortune Teller",
  "Fund Manager",
  "General Affairs",
  "General Manager (admin only)",
  "Graduate",
  "Graphic Artist / Designer",
  "Guest Relation",
  "Guitar Instructor",
  "Gynaecologist",
  "Human Resource Personnel",
  "Illustrator",
  "Immigration Officer",
  "Information System Officer",
  "Inland Revenue Officer",
  "Insurance Agent (Prudential)",
  "Insurance Assistant",
  "Interpreter",
  "Investment Executive",
  "IT Analyst",
  "IT Manager/Director",
  "IT Specialist",
  "Judicial Officer",
  "Lawyer",
  "Lecturer",
  "Legal Advisor",
  "Legal Assistant",
  "Librarian",
  "Loans Officer",
  "Logistic Personnel (admin)",
  "Management Consultant",
  "Management Trainee",
  "Manager",
  "Manager (admin only)",
  "Managing Director",
  "Manpower Specialist",
  "Marker",
  "Marketing Exec/Manager",
  "Mass communication",
  "Medic",
  "Merchandiser",
  "Monk",
  "MRT personel",
  "Music Instructor",
  "Napkin folder",
  "Network Controller",
  "Network Specialist",
  "Neurosurgeon",
  "Newscaster",
  "Nun",
  "Nutritionist",
  "Obstetrician",
  "Office Messenger",
  "Operation Assistant",
  "Operations Exec/Manager",
  "Optician",
  "Optometrist",
  "Order processing",
  "Paediatrician",
  "Page Boy",
  "Passenger Service Officer",
  "Pastor",
  "Payroll Duties",
  "Personal Assistant",
  "Pharmacist",
  "Photographer (indoor)",
  "Planner",
  "Politician",
  "President (admin only)",
  "Principal",
  "Professor",
  "Project Analyst/Manager(admin)",
  "Psychiatrist",
  "Psychologist",
  "Publisher",
  "Purchaser",
  "Receptionist",
  "Recruitment Officer",
  "Registrar",
  "Remisier",
  "Researcher",
  "Researcher (indoor)",
  "Sales Manager",
  "Sales person/Executive(indoor)",
  "SCB analysis",
  "Search Exec/Consultant",
  "Secretary",
  "Self Employed (admin only)",
  "Service Officer",
  "Shipping Assistant (admin)",
  "Shipping Clerk (admin)",
  "Shipping Coordinator(admin)",
  "Shipping Officer",
  "Shop Assistant (indoor)",
  "Sole Proprietor",
  "Sole Proprietor (admin only)",
  "Solicitor",
  "Stenographer",
  "Stock Broker",
  "Student",
  "Surgeon",
  "System Security Officer",
  "Systems Analyst",
  "Tax",
  "Tax Consultant",
  "Teacher",
  "Ticketer",
  "Ticketing Coordinator",
  "Toddler",
  "Tour Coordinator/Consul(admin)",
  "Trader",
  "Translator",
  "Tutor",
  "Typist",
  "Underwriter",
  "Vice-President",
  "Web Administrator/Webmaster"
];

const class_2 = [
  "Advertising Personnel(non-adm)",
  "Aesthetician",
  "Agent",
  "Air Force Officer",
  "Aircraft/Aerospace Industry",
  "Aircrew (commercial airline)",
  "Airlines personnel (non-admin)",
  "Army Officer",
  "Artist",
  "Attendant (door)",
  "Attendant (lift)",
  "Baker",
  "Beautician",
  "Biochemist",
  "Biologist",
  "Bowling Alley Worker",
  "Box Worker",
  "Broker (property)",
  "Cabin Crew(commercial airline)",
  "Cameraman (outdoor)",
  "Captain (hotel)",
  "Car Park Attendant",
  "Checker",
  "Chemist",
  "Chiropractor",
  "Choreographer",
  "Civil Engineer",
  "Cleaner (indoor)",
  "Clerk of Works",
  "Cobbler (in shop)",
  "Commando",
  "Communication Officer(non-adm)",
  "Communications Officer",
  "Computer Servicing",
  "Computer Technician",
  "Computer Worker",
  "Computer Worker (Hardware)",
  "Concierge",
  "Confectionery (baker)",
  "Conference Coordinator",
  "Controller",
  "Conveyor",
  "Cosmetic Industry",
  "Dance Instructor",
  "Darkroom Lab personnel",
  "Designer (interior)",
  "Domestic Helper",
  "Doorman",
  "Drama Director",
  "Drama Producer",
  "Drama Teacher",
  "Dressmaker",
  "Embalmer",
  "Engineer (civil)",
  "Engineer (electrical)",
  "Engineer (electronic)",
  "Film Producer",
  "Fish Auctioneer",
  "Fish-Ball Maker",
  "Flight Steward",
  "Flight Stewardess",
  "Floor Manager (fast food)",
  "Florist",
  "Food & Beverage Manager",
  "Food Manufacturer",
  "Footwear Industry",
  "Foreign Service",
  "Fruit Seller",
  "Garment Industry",
  "Geomancer",
  "Goldsmith",
  "Grocer",
  "Group Leader",
  "Group Merchandise",
  "Guest Relation Officer",
  "Guest Relation Officer (hotel)",
  "Hairstylist",
  "Handbag Industry",
  "Home-maker",
  "Horticulturist",
  "Hotel Captain",
  "Housewife",
  "Housing Agency",
  "Housing Agent",
  "Inspector",
  "Instructor",
  "Insurance Agent(oth companies)",
  "Interior Decorator",
  "Interior Designer",
  "Jeweller",
  "Journalist (in Singapore)",
  "Key Punch Operator",
  "Laboratory Assistant",
  "Laboratory Technician",
  "Lead Girl",
  "Lead Stewardess",
  "Lift Attendent",
  "Line Leader",
  "Liquor Promotor",
  "Lithographer",
  "Loss Adjuster (motor)",
  "Maid",
  "Maintenance Personnel",
  "Make-up Artist",
  "Manicurist",
  "Marine Superintendent",
  "Merchant",
  "Midwife",
  "Missionary",
  "Mixer",
  "Model (fashion)",
  "Money Changer",
  "Musician(excl performing arts)",
  "National Serviceman",
  "Navy Officer",
  "Newspaper Vendor",
  "NSman (bomb disposal)",
  "Nurse",
  "Nursing Officer",
  "Oil trader",
  "Operator",
  "Owner",
  "P'grapher(O/D,no aerial shoot)",
  "Paperboard Maker",
  "Parking Warden",
  "Partner",
  "Pawnbroker",
  "Pensioner",
  "Pet Therapist",
  "Physiotherapist",
  "Pilot (commercial airline)",
  "Postman",
  "Processor",
  "Producer",
  "Project Analyst/Mgr(manual)",
  "Promoter",
  "Property Agent",
  "Public Relations Officer",
  "Quality Controller",
  "Radiographer",
  "Radiologist",
  "Real Estate Agent",
  "Reflexologist",
  "Reporter (in Singapore)",
  "Representative",
  "Researcher (outdoor)",
  "Retiree",
  "RSAF - Army Officer",
  "RSAF - Navy Officer",
  "SAF Officer (Air force)",
  "SAF Officer (Army)",
  "SAF Officer (Navy)",
  "Sales person (outdoor)",
  "Salesman",
  "Seamstress",
  "Setter",
  "Shop Assistant",
  "Shop Assistant (outdoor)",
  "Social Worker",
  "Storeman(light material)",
  "Superintendent (marine)",
  "Supervisor (light industries)",
  "Supplier",
  "Surveyor",
  "Tailor (not in factory)",
  "Technical Officer",
  "Technical Support Officer",
  "Tester",
  "Tool-Maker",
  "Tool-Setter",
  "Tour Coordinator (lead tours)",
  "Tour Guide",
  "Trainee Pilot",
  "Typesetter",
  "Valuer",
  "Waiter (hotel/restaurant)",
  "Waitress (hotel/restaurant)"
];

const class_3 = [
  "Actor",
  "Actress",
  "Aircon Installer (indoor)",
  "Aircon Technician (indoor)",
  "Airforce Personnel",
  "Ambulanceman (Driver)",
  "Army Medic",
  "Army Personnel",
  "Assembler (electronic)",
  "Bar Steward/Maid",
  "Bartender",
  "Bell Hop",
  "Building Maintenance Worker",
  "Building Technician",
  "Bus Driver",
  "Businessman(admin&manual work)",
  "Butcher (retail shop / stall)",
  "Captain (ship)",
  "Caretaker",
  "Carpenter",
  "Cashier (bar/lounge/disco/pub)",
  "Caterer",
  "Chambermaid",
  "Chauffeur",
  "Chef",
  "Childcare Assistant",
  "Childcare Teacher",
  "Cleaner",
  "Cleaner (outdoor)",
  "Cobbler (roadside)",
  "Coffin Maker",
  "Colour Separator",
  "Combat Specialist",
  "Concrete Industry",
  "Contractor",
  "Contractor(building&construct)",
  "Cook",
  "Courier Services",
  "Courier Services (van)",
  "Craftsman",
  "Custom Officer (others)",
  "Cutter (paper industry)",
  "Dancer",
  "Debt Collector",
  "Deliveryman (van)",
  "Disc Jockey",
  "Dish Washer",
  "Driver (bus)",
  "Driver (taxi)",
  "Driver (van)",
  "Driving Instructor",
  "Dry Cleaner",
  "Electrician (domestic)",
  "Electrician (factory)",
  "Electrician(construction site)",
  "Employee(bar/lounge/disco/pub)",
  "Engineer (aeronautical)",
  "Engineer (marine)",
  "Engineer (mechanical)",
  "Engraver",
  "Estates Officer",
  "Factory Worker",
  "Film Artiste",
  "Fish Breeder / Farming",
  "Fishmonger",
  "Fitness Instructor",
  "Fitter (no welding)",
  "Foreman (construction site)",
  "Frame Installer",
  "Gardener",
  "Gas Dealer (delivery)",
  "GRO (bar/lounge/disco/pub)",
  "Handler",
  "Hawker",
  "Hostess",
  "Hostess (bar/lounge/disco/pub)",
  "Housekeeper",
  "Instructor (driving)",
  "Instructor (fitness)",
  "Janitor",
  "Karaoke Jockey",
  "Keysmith",
  "Laundry Business",
  "Life-Guard",
  "Lightingman",
  "Loading Assistant",
  "Locksmith",
  "Loss Adjuster",
  "Loss Adjuster (marine)",
  "Maker",
  "Manufacturer",
  "Packer",
  "Painter (indoor)",
  "Pet Groomer",
  "Petrol Station Attendant",
  "Plumber",
  "Porter",
  "Printer (books)",
  "Printer (newspaper)",
  "Production Staff",
  "Production Worker",
  "Repairman (air-con)",
  "Repairman (TV & radio)",
  "Restaurant Crew (fast-food)",
  "Restaurant Helper",
  "Security Guard (unarmed)",
  "Self Employed(adm+manual work)",
  "Sewing Operator",
  "Singer",
  "SoleProprietor(adm+manual wrk)",
  "Storeman",
  "Supervisor",
  "Supervisor (construction)",
  "Supervisor (marine)",
  "Supervisor (power house)",
  "Supervisor (ship-building)",
  "Supervisor (sub-contractor)",
  "Tailor (in factory)",
  "Tattooist",
  "Taxi Driver",
  "Technician",
  "Veterinary Surgeon",
  "Waiter",
  "Waiter (bar/lounge/disco/pub)",
  "Waitress",
  "Waitress(bar/lounge/disco/pub)",
  "Window Cleaner (indoor)"
];
/*
const class_4 = [
  "Agricultural Contractor",
  "Aircon Installer (outdoor)",
  "Aircon Technician (outdoor)",
  "Aircraft Maintenance Worker",
  "Aircraft Mechanic",
  "Animal Keeper / Trainer",
  "Assembler (automobile)",
  "Athletes",
  "Automobile Industry",
  "Blacksmith",
  "Blasting Engineer",
  "Boatman",
  "Boatswain",
  "Body Guard",
  "Boiler Attendant",
  "Bomb Disposal (professional)",
  "Bomb Disposal (SAF)",
  "Bouncer",
  "Bulldozer Operator",
  "Butcher (packing house)",
  "Butcher (slaughter house)",
  "Cable Layer",
  "Cement Mixer Driver",
  "Cement Worker",
  "Coast Guard",
  "Construction Worker",
  "Courier Services (motor cycle)",
  "Crane Operator (mobile crane)",
  "Crane Operator (tower crane)",
  "Cultural Affairs",
  "Cutter (Tree-cutter)",
  "Deck Hand",
  "Deck Officer",
  "Deck Trainee",
  "Deliveryman",
  "Deliveryman (bicycle)",
  "Deliveryman (furniture)",
  "Deliveryman (lorry)",
  "Deliveryman (motor-cycle)",
  "Demolition Worker",
  "Despatch Rider",
  "Detective",
  "Die Caster",
  "Diver",
  "Diver (navy regulars)",
  "Diver(prof w/o bomb disposal)",
  "Diver(prof with bomb disposal)",
  "Diving Instructor",
  "Dock (cargo handler)",
  "Dog Handler",
  "Dog Trainer",
  "Driller (oil rig)",
  "Driller(building&construction)",
  "Driver (lorry)",
  "Electrician",
  "Electrician (Use gondola)",
  "Electro Armament",
  "Electro Plating",
  "Engine Pump Operator",
  "Entertainer",
  "Excavator Driver",
  "Farmer",
  "Ferryman",
  "Fireman",
  "Fisherman (deep sea)",
  "Fisherman (inshore)",
  "Fitter (welding)",
  "Forklift Driver",
  "Forklift Operator",
  "Freight Fwder(handling of gds)",
  "Furniture Industry",
  "Garbage Collector",
  "Gas Brazer",
  "Gas Dealer",
  "Gem Garder",
  "General Worker",
  "Glazier",
  "Grinder",
  "Helicopter Crew",
  "Helicopter Crew (RSAF)",
  "Jockey",
  "Journalist (outside Singapore)",
  "Labourer",
  "Machinist",
  "Martial Arts Instructor",
  "Masseuse",
  "Mechanic (motor)",
  "Messenger (motor cycle)",
  "Metal Work Worker",
  "Military personnel",
  "Miscellaneous",
  "Moulder",
  "Narcotics Officer",
  "Naval Diver(inc bomb disposal)",
  "NSman (naval diving)",
  "NSman (parachuting)",
  "NSman(W/O hazardous duties)",
  "NSman(with commando duties)",
  "Occupation unknown",
  "Odd-Job Labourer",
  "Oil Rig Pesonnel",
  "Oil Rig Pesonnel (Oth Prof)",
  "Oil Rigger",
  "Oilfield Operator",
  "Others",
  "Painter (automobile)",
  "Painter (outdoor)- Use gondola",
  "Painter (ship)",
  "Pest Control Worker",
  "Pilot",
  "Pilot (private flying)",
  "Pilot (RSAF)",
  "Police",
  "Polisher (car)",
  "Polisher (renovation)",
  "Prison Warden",
  "Private Investigator",
  "Professional Footballer",
  "Reporter (outside Singapore)",
  "SAF (with parachuting only)",
  "Sailor",
  "Scaffolder-Use scaffold > 20ft",
  "Seaman",
  "Security Guard (armed)",
  "Ship Building Erector",
  "Ship Building Welder",
  "Ship Building Worker",
  "Ski Flying",
  "Sports man",
  "Sports man  - Others",
  "Stuntman",
  "Theatre - stage hands",
  "Trainee Pilot (RSAF)",
  "Transport Aircrew (RSAF)",
  "Transport Pilot (RSAF)",
  "Tugmaster",
  "Type Caster",
  "Unemployed",
  "Vehicle Industry",
  "Wall Plasterer",
  "Water sports instructor",
  "Welder (automobile)",
  "Welder (general)",
  "Welder (ship building)",
  "Welder (shipyard)",
  "Window Cleaner (outdoor)",
  "Zookeeper"
];*/

const occupationsClass = {
  "F1": {name: 'Class 1 - Professional / White collar / Office worker', description: 'For those engaged mainly in executive, administrative or clerical duties. You spend a substantial amount of time indoors (e.g. the office). Examples include administrators, accountants, C-level management, doctors, professionals, school teachers, and students.', data: class_1},
    "F2": {name: 'Class 2 - Sales / Light manual worker', description: 'For those engaged mainly in sales or light manual duties, service industry and may be required to be on the move frequently. However, your job does not expose you to hazardous conditions. Examples include sales persons, employees of hospitality industry, beauticians, homemakers, commercial airlines crew, engineer (civil/electrical), healthcare personnel and National Servicemen Full-time (NSFs)', data: class_2},
    "F3": {name: 'Class 3 - Skilled / Semi-skilled worker', description: 'For those engaged mainly in skilled or semi-skilled work and may be required to perform manual labour (not involving height above 6 meters from ground level). Your job may also expose you to certain hazardous conditions. Examples include childcare services, technicians, engineers (aeronautical/mechanical), light vehicle drivers, housekeepers and manufacturers.', data: class_3}
};
export default occupationsClass;
