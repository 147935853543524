import Enums from "../../../../utils/Enums";

const GADDBenefits = {
  headers: [
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "30%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Group Accidental Death & Dismemberment</strong>`
        },
        {
          data: `S$ 500,000`
        },
        {
          data: `S$ 200,000`
        },
        {
          data: `S$ 150,000`
        },
        {
          data: `S$ 80,000`
        },
        {
          data: `S$ 50,000`
        }
      ]
    }
  ],
  footnotes: []
};

let GADDPremium = {
  title: `Annual Premium Rate (inclusive of 7% GST) – Group Accidental Death & Dismemberment`,
  headers: [
    {
      title: `Occupational Class`,
      mandatory: true
    },
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Class 1</strong>`,
          attributes: {
            rowspan: 2
          }
        },
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 154.00`
        },
        {
          data: `S$ 66.00`
        },
        {
          data: `S$ 50.00`
        },
        {
          data: `S$ 27.00`
        },
        {
          data: `S$ 17.00`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 631.00`
        },
        {
          data: `S$ 274.00`
        },
        {
          data: `S$ 205.00`
        },
        {
          data: `S$ 110.00`
        },
        {
          data: `S$ 69.00`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>Class 2</strong>`,
          attributes: {
            rowspan: 2
          }
        },
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 183.00`
        },
        {
          data: `S$ 80.00`
        },
        {
          data: `S$ 60.00`
        },
        {
          data: `S$ 32.00`
        },
        {
          data: `S$ 20.00`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 752.00`
        },
        {
          data: `S$ 332.00`
        },
        {
          data: `S$ 249.00`
        },
        {
          data: `S$ 133.00`
        },
        {
          data: `S$ 83.00`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>Class 3</strong>`,
          attributes: {
            rowspan: 2
          }
        },
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 241.00`
        },
        {
          data: `S$ 107.00`
        },
        {
          data: `S$ 81.00`
        },
        {
          data: `S$ 43.00`
        },
        {
          data: `S$ 27.00`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 993.00`
        },
        {
          data: `S$ 447.00`
        },
        {
          data: `S$ 335.00`
        },
        {
          data: `S$ 179.00`
        },
        {
          data: `S$ 112.00`
        }
      ]
    }
  ],
  footnotes: []
};
//   ----------------------------------------------------------------------
const GADD = {
  id: 1,
  code: "GADD",
  label: "Group Accidental Death & Dismemberment (GADD)",
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GADDBenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium (inclusive of 7% GST)",
      data: [GADDPremium]
    }
  ]
};

export default GADD;
