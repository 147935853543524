import ValidationService from "../services/validation.service";
import * as actionTypes from "./types";

export const validationsActions = {
  emailValidations
};

function emailValidations(emails) {
  return (dispatch, getState) => {
    const appType = getState().app.type;
    return ValidationService.emailValidation(emails, appType)
      .then(response => {
        dispatch({
          type: actionTypes.GET_EMAIL_VALIDATION,
          payload: response.data
        });
        return Promise.resolve(response);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  };
}
