import ExternalScript from "../utils/ExternalScript";
import { APIResource, APIError, APIResponse } from "../utils/APIResource";

export default class ProductService {
  static loadConfig(code, version) {
    let url = `/v1/product/${code}/${version}/config`;
    return APIResource.get(url)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static loadEngine(code, version) {
    let url = `/v1/product/${code}/${version}/engine`;
    return APIResource.get(url)
      .then(resp => {
        return new Promise(function(resolve, reject) {
          //TODO: remove mocked
          // let url = `/_mock/PTF.1.js`;
          // ExternalScript.load(window, url, () => {

          ExternalScript.inject(window, resp.data, () => {
            let obj = window.MMPProducts;
            return obj
              ? resolve({ success: true, data: obj })
              : reject({ code: "404", message: "Config script not found" });
          });
        });
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static loadLatestProductVersion(code) {
    let url = `/v1/product/version/${code}`;
    return APIResource.get(url)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static isEngineLoaded() {
    return window.MMPProducts;
  }
}
