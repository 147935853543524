import * as actionTypes from "./types";
import config from "../config/config";

export const appActions = {
  init,
  setLocale,
  setError,
  set500Error,
  setType,
  reset,
  scrollToTop,
  scrollToTopFin,
  setFirstPageDialogVisibility,
  setRenewalNotificationVisibility
};

function init() {
  return (dispatch, getState) => {
    let state = getState();
    if (state.app.build !== config.app.build || state.app.type !== config.app.type) {
      dispatch({ type: actionTypes.APP_RESET });
    }
  };
}

function setLocale(locale) {
  return {
    type: actionTypes.APP_SET_LOCALE,
    payload: locale
  };
}

function setError(error) {
  return {
    type: actionTypes.APP_SET_ERROR,
    payload: error
  };
}

function set500Error(error) {
  return {
    type: actionTypes.APP_500_ERROR,
    payload: error
  };
}

function setType(type) {
  return {
    type: actionTypes.APP_SET_TYPE,
    payload: type
  };
}

function reset() {
  return { type: actionTypes.APP_RESET };
}

function scrollToTop() {
  return { type: actionTypes.APP_SCROLL };
}

function scrollToTopFin() {
  return { type: actionTypes.APP_SCROLL_FIN };
}

function setFirstPageDialogVisibility(visible) {
  return {
    type: actionTypes.APP_FIRSTPAGEDIALOG_SET_VISIBILITY,
    payload: visible
  };
}

function setRenewalNotificationVisibility(visible) {
  return {
    type: actionTypes.APP_RENEWALNOTIFICATION_SET_VISIBILITY,
    payload: visible
  };
}
