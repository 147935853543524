import React, { Component } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import classNames from "classnames";
import { withStyles } from "@mui/styles";
import { common } from "@mui/material/colors";
import Enums from "../utils/Enums";
import assets from "../assets";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

class HeaderPlain extends Component {
  constructor(props) {
    super(props);

    this.logoInfo = {
      DEFAULT: {
        leftLogo: assets.images.pru_work_logo,
        leftClassName: props.classes.pruworkImgLogo,
        rightLogo: assets.images.pru_menu_logo,
        rightClassName: props.classes.pruLogo
      },
      PRU_AGENCY: {
        leftLogo: assets.images.pru_work_logo,
        leftClassName: props.classes.pruworkImgLogo,
        rightLogo: assets.images.pru_menu_logo,
        rightClassName: props.classes.pruLogo
      },
      SCB_ASSISTED: {
        leftLogo: assets.images.pru_work_logo,
        leftClassName: props.classes.pruworkImgLogo,
        rightLogo: assets.images.pru_menu_logo,
        rightClassName: props.classes.pruLogo
      },
      UOB_ASSISTED: {
        leftLogo: assets.images.pru_menu_logo,
        leftClassName: props.classes.pruLogo,
        rightLogo: assets.images.brand.UOB,
        rightClassName: props.classes.branchImgLogo
      }
    };
  }

  getLogoInfo = brand => {
    return (this.props.app.type === Enums.APP_TYPE.SALES && brand && this.logoInfo[brand]) || this.logoInfo["DEFAULT"];
  };

  render() {
    const { classes, brand } = this.props;
    const logoInfo = this.getLogoInfo(brand);

    return (
      <AppBar color="default" className={classNames(classes.appBar)}>
        <Toolbar className={classes.toolbar}>
          <div>
            <img src={logoInfo.leftLogo} alt="logo" className={classNames(logoInfo.leftClassName)} />
          </div>
          <div className={classes.brandLogoContainer}>
            <img src={logoInfo.rightLogo} alt="brand_logo" className={logoInfo.rightClassName} />
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    zIndex: 1
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between"
  },
  pruLogo: {
    maxWidth: 157
  },
  pruworkImgLogo: {
    maxWidth: 177
  },
  branchImgLogo: {
    maxWidth: 103
  },
  brandLogoContainer: {
    alignItems: "flex-end",
    textAlign: "right"
  },
  flex: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  appBar: {
    position: "fixed",
    backgroundColor: common.white
  }
});

function mapStateToProps(state) {
  return { user: state.user, app: state.app };
}

export default injectIntl(connect(mapStateToProps, {})(withStyles(styles, { withTheme: true })(HeaderPlain)));
