import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import { bindActionCreators } from "redux";
import { myEmployeeActions } from "../../../../actions";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import SimpleMessageDialog from "../../../../components/SimpleMessageDialog";
import SimpleConfirmDialog from "../../../../components/SimpleConfirmDialog";
import MyEmployeeDialog from "./MyEmployeeDialog";
import TerminationEmployeeDialog from "./TerminationEmployeeDialog";
import LoadingPopup from "../../../../components/LoadingPopup";
import Util from "../../../../utils/Util";
import SnackBar from "../../../../components/SnackBar";

class MyEmployeeFooter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inviteDialog: false,
      addEmployeeDialog: false,
      terminateEmployeeDialog: false,
      showSnackBarError: false,
      selectedRecord: null,
      selectedIndex: null,
      mode: "",
      employeeDialogTitle: ""
    };
  }

  handleIconClick = name => event => {
    if (name === "add") {
      this.setState({
        addEmployeeDialog: true,
        mode: "ADD",
        employeeDialogTitle: "Add new employee",
        selectedRecord: null
      });
    } else if (name === "send") {
      this.setState({ inviteDialog: true });
    } else if (name === "delete") {
      this.setState({
        terminateEmployeeDialog: true,
        selectedRecord: this.getSelectedRecord()
      });
    }
  };

  handleCloseDialog = name => event => {
    if (name === "add") {
    } else if (name === "edit") {
    } else if (name === "send") {
      this.setState({ inviteDialog: false });
    } else if (name === "invite_success" || name === "invite_error" || name === "add_employee") {
      this.props.closePopup();
    } else if (name === "employee") {
      this.setState({ addEmployeeDialog: false });
    } else if (name === "termination") {
      this.setState({ terminateEmployeeDialog: false });
    }
  };

  handleCloseSnackbarError = () => {
    this.setState({ showSnackBarError: false });
  };

  handleSnackbarErrorEvent = event => {
    if (event.code === 400) {
      this.setState({ showSnackBarError: true });
    }
  };

  sendInvite = () => {
    this.props.showLoading(true);
    this.props.sendInvite().then(
      response => {
        this.props.showLoading(false);
      },
      error => {
        this.props.showLoading(false);
      }
    );
    this.handleCloseDialog("send")();
  };

  getSelectedRecord() {
    let selectedId = this.props.selectedIds[0];
    if (selectedId) {
      return this.props.myEmployee.data.filter(d => d.person_id === selectedId)[0];
    }
    return null;
  }

  componentDidMount() {
    this.props.onRef(this);
  }
  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  onCellClickHandler = personId => {
    let selectedIndex = null;
    const selectedRecord = this.props.myEmployee.data.filter((d, id) => {
      if (d.person_id === personId) {
        selectedIndex = id;
      }
      return d.person_id === personId;
    })[0];

    this.setState({
      addEmployeeDialog: true,
      mode: "EDIT",
      employeeDialogTitle: "Edit Employee",
      selectedRecord: selectedRecord,
      selectedIndex: selectedIndex
    });
  };

  confirmTerminateEmployee = date => {
    this.props.showLoading(true);
    //call api with this termination date
    const { updateEmployee } = this.props;
    let selectedRecord = Util.clone(this.state.selectedRecord);
    selectedRecord["coverage_end_date"] = date;

    //Set dependents coverage_end_date as well
    selectedRecord.dependents.forEach(d => {
      d["coverage_end_date"] = date;
    });

    updateEmployee({
      member: selectedRecord,
      dependents: selectedRecord.dependents
    }).then(
      response => {
        this.props.showLoading(false);
      },
      error => {
        this.props.showLoading(false);
      }
    );
    this.handleCloseDialog("termination")();
  };

  render() {
    const { classes, navDrawerOpen, selectedIds, policyDetails } = this.props;
    const { inviteDialog, addEmployeeDialog, selectedRecord, selectedIndex, mode, employeeDialogTitle } = this.state;
    return (
      <div className={classNames(classes.root, navDrawerOpen && classes.menuShow)}>
        <IconButton disabled={policyDetails?.policy?.renewal_submitted} onClick={this.handleIconClick("add")}>
          <Icon>person_add</Icon>
        </IconButton>
        <IconButton disabled={selectedIds.length === 0} onClick={this.handleIconClick("send")}>
          <Icon>send</Icon>
        </IconButton>
        <IconButton
          disabled={selectedIds.length === 0 || selectedIds.length > 1 || policyDetails?.policy?.renewal_submitted}
          onClick={this.handleIconClick("delete")}
        >
          <Icon>delete</Icon>
        </IconButton>
        {/*for invitation employee*/}
        <SimpleConfirmDialog
          open={inviteDialog}
          name="email-detail-dialog"
          title="Send Invite To Selected Employees?"
          description="Selected employees will receive an email to login and activate
                their insurance account."
          closeButtonText="Cancel"
          closeHandler={this.handleCloseDialog("send")}
          okButtonText="Send invite"
          okHandler={this.sendInvite}
        />

        <SimpleMessageDialog
          type="success"
          name="send-invite-success"
          title="Invites Sent!"
          description="Your employees can now activate their Employee portal via the link sent in the email invite."
          closeButtonText="OK"
          closeHandler={this.handleCloseDialog("invite_success")}
          open={this.props.myEmployee.showInviteSuccess}
        />

        <SimpleMessageDialog
          type="error"
          name="send-invite-error"
          title="There was a problem"
          description="We are unable to complete the invites as this moment, please try again later"
          closeButtonText="OK"
          closeHandler={this.handleCloseDialog("invite_error")}
          open={this.props.myEmployee.showInviteError}
        />

        {addEmployeeDialog && (
          <MyEmployeeDialog
            open={addEmployeeDialog}
            selectedRecord={selectedRecord}
            selectedIndex={selectedIndex}
            title={employeeDialogTitle}
            mode={mode}
            readOnly={mode === "VIEW"}
            handleClose={this.handleCloseDialog("employee")}
            handleEmployeeError={this.handleSnackbarErrorEvent}
            policyDetails={policyDetails}
          />
        )}

        <SimpleMessageDialog
          isHtml={true}
          type="success"
          name="employee-success"
          title="Submitted"
          description="Thank you for your submission on PRUWorks. We will be in touch with you if we have any queries. Otherwise, we will proceed to update the member data within 3 to 5 working days.<br/><br/>If any medical underwriting is required, we will be sending a separate email to HR."
          closeButtonText="OK"
          closeHandler={this.handleCloseDialog("add_employee")}
          open={this.props.myEmployee.showEmployeeSuccess}
        />

        <LoadingPopup name="my-employee-footer-loading" open={this.props.myEmployee.showLoading} />

        {this.state.showSnackBarError && (
          <SnackBar
            type="error"
            open={this.state.showSnackBarError}
            onClose={this.handleCloseSnackbarError}
            message="An error has occurred when processing the Employee's information, please try again."
          />
        )}

        {this.state.terminateEmployeeDialog && (
          <TerminationEmployeeDialog
            open={this.state.terminateEmployeeDialog}
            selectedRecord={selectedRecord}
            name={"EmployeeSuccess"}
            icon={<Icon style={{ fontSize: 50, color: "red" }}>delete</Icon>}
            closeButtonText={"OK"}
            setTerminationDate={this.setTerminationDate}
            closeHandler={this.handleCloseDialog("termination")}
            confirmHandler={this.confirmTerminateEmployee}
          />
        )}
        {policyDetails?.policy?.renewal_submitted && (
          <p style={{ padding: "5px", margin: "0px" }}>
            {" "}
            <strong>Note:</strong> Please submit or upload member changes after policy had been renewed successfully.
            Please reach out to your financial consultant for assistance.
          </p>
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    backgroundColor: "white",
    position: "fixed",
    right: 0,
    bottom: 0,
    left: theme.miniLeftDrawer.widthClosed,
    borderTop: "1px solid rgba(0, 0, 0, 0.12)"
  },
  menuShow: {
    left: theme.miniLeftDrawer.width
  }
});

function mapStateToProps(state) {
  return { myEmployee: state.HR.myemployee };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(myEmployeeActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(MyEmployeeFooter)));
