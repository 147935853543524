import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
//plans display typography not in backend but in json
import Typography from "@mui/material/Typography";
import { Accordion as ExpansionPanel } from "@mui/material";
import { AccordionSummary as ExpansionPanelSummary } from "@mui/material";
import { AccordionDetails as ExpansionPanelDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { productActions } from "../../../../actions";
import PlanContent from "./PlanContent";
import pruPlans from "../../../../assets/data/plans/index";
import pruPlansV2 from "../../../../assets/data/plans/Version2/index";
import { bindActionCreators } from "redux";
import config from "../../../../config/config";
import moment from "moment";
import { cloneDeep } from "lodash";

class Plans extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getPlans() {
    const { tiers = null, productCode = quote.product_code } = this.props;
    const enrolledPlans = Object.keys(tiers);

    const formattedPlan = [];
    pruPlans[productCode].forEach(type => {
      const formattedType = type.plans.map(plan => {
        if (enrolledPlans.includes(plan.code)) return plan;
        return null;
      });

      if (formattedType && !formattedType.every(p => p === null))
        formattedPlan.push({ title: type.title, plans: formattedType });
    });
    return formattedPlan || pruPlans[productCode];
  }

  getPlanTitle = (plan, selectedTiers) => {
    return (
      plan.types.find(t => t.type === this.props.types[0]).data[0].headers.find(h => h.tier === selectedTiers[0])
        .title || "Unknown Plan"
    );
  };

  getPlansShow(portalType, resetTime, basePlansClone) {
    let basePlans = basePlansClone;
    if (portalType == "HR" || portalType == "PI") {
      basePlans = this.getHRPlans(basePlans);
    } else {
      basePlans = this.getSalesPlans(basePlans, resetTime);
    }
    return basePlans;
  }

  getSalesPlans(basePlans, resetTime) {
    let creationTime = resetTime;
    if (this.props.quote.created !== undefined) {
      if (moment(this.props.quote.created).isBefore(resetTime)) {
        creationTime = this.props.quote.created;
      }
    }
    // removing the GHS and GTL Plans Rates which belongs to HR
    // id 5 signifies GTL_HR, id 6 signifies GHS_HR, id 7 signifies GTL_HR_PF3, id 8 signifies GHS_HR_PF3
    let hrPlanIdx = basePlans[0].plans.findIndex(x => x.id === 5);
    if (hrPlanIdx !== -1) {
      basePlans[0].plans.splice(hrPlanIdx, 1);
    }

    let hrPlanIdx2 = basePlans[0].plans.findIndex(x => x.id === 6);
    if (hrPlanIdx2 !== -1) {
      basePlans[0].plans.splice(hrPlanIdx2, 1);
    }

    let hrPlanIdx3 = basePlans[0].plans.findIndex(x => x.id === 7);
    if (hrPlanIdx3 !== -1) {
      basePlans[0].plans.splice(hrPlanIdx3, 1);
    }

    let hrPlanIdx4 = basePlans[0].plans.findIndex(x => x.id === 8);
    if (hrPlanIdx4 !== -1) {
      basePlans[0].plans.splice(hrPlanIdx4, 1);
    }

    let hrPlanIdx5 = basePlans[0].plans.findIndex(x => x.id === 20);
    if (hrPlanIdx5 !== -1) {
      basePlans[0].plans.splice(hrPlanIdx5, 1);
    }

    let hrPlanIdx6 = basePlans[0].plans.findIndex(x => x.id === 9);
    if (hrPlanIdx6 !== -1) {
      basePlans[0].plans.splice(hrPlanIdx6, 1);
    }

    //SupplyMentary Plan
    //GCCA
    let supPlanIdx1 = basePlans[1].plans.findIndex(x => x.id === 10);
    if (supPlanIdx1 !== -1) {
      basePlans[1].plans.splice(supPlanIdx1, 1);
    }

    //GPCG
    let supPlanIdx2 = basePlans[1].plans.findIndex(x => x.id === 11);
    if (supPlanIdx2 !== -1) {
      basePlans[1].plans.splice(supPlanIdx2, 1);
    }

    //GEMM
    let supPlanIdx3 = basePlans[1].plans.findIndex(x => x.id === 12);
    if (supPlanIdx3 !== -1) {
      basePlans[1].plans.splice(supPlanIdx3, 1);
    }

    //PGIH
    let supPlanIdx4 = basePlans[1].plans.findIndex(x => x.id === 13);
    if (supPlanIdx4 !== -1) {
      basePlans[1].plans.splice(supPlanIdx4, 1);
    }

    //PSIH
    let supPlanIdx5 = basePlans[1].plans.findIndex(x => x.id === 14);
    if (supPlanIdx5 !== -1) {
      basePlans[1].plans.splice(supPlanIdx5, 1);
    }

    //GADD
    let supPlanIdx6 = basePlans[1].plans.findIndex(x => x.id === 15);
    if (supPlanIdx6 !== -1) {
      basePlans[1].plans.splice(supPlanIdx6, 1);
    }

    //GAMR
    let supPlanIdx7 = basePlans[1].plans.findIndex(x => x.id === 16);
    if (supPlanIdx7 !== -1) {
      basePlans[1].plans.splice(supPlanIdx7, 1);
    }

    //GDEN
    let supPlanIdx8 = basePlans[1].plans.findIndex(x => x.id === 17);
    if (supPlanIdx8 !== -1) {
      basePlans[1].plans.splice(supPlanIdx8, 1);
    }

    //PDIH
    let supPlanIdx9 = basePlans[1].plans.findIndex(x => x.id === 18);
    if (supPlanIdx9 !== -1) {
      basePlans[1].plans.splice(supPlanIdx9, 1);
    }

    if (moment(creationTime).isSameOrAfter(config.cut_off_date)) {
      // removing the old rates of GHS and GTL
      // id 1 signifies GTL old rates and id 2 signifies GHS old rates
      let oldPlanIdx1 = basePlans[0].plans.findIndex(x => x.id === 1);
      if (oldPlanIdx1 !== -1) {
        basePlans[0].plans.splice(oldPlanIdx1, 1);
      }

      let oldPlanIdx2 = basePlans[0].plans.findIndex(x => x.id === 2);
      if (oldPlanIdx2 !== -1) {
        basePlans[0].plans.splice(oldPlanIdx2, 1);
      }
    } else {
      // original rates
      // removing the new rates of GHS and GTL
      // id 3 signifies GTL new rates and id 4 signifies GHS new rates
      let newPlanIdx1 = basePlans[0].plans.findIndex(x => x.id === 3);
      if (newPlanIdx1 !== -1) {
        basePlans[0].plans.splice(newPlanIdx1, 1);
      }

      let newPlanIdx2 = basePlans[0].plans.findIndex(x => x.id === 4);
      if (newPlanIdx2 !== -1) {
        basePlans[0].plans.splice(newPlanIdx2, 1);
      }
    }
    return basePlans;
  }

  getHRPlans(basePlans) {
    // getting policy effective date
    let policyEffectiveDate = this.props.policyEffectiveDate;
    // policyEffectiveDate = this.props.quote.req_effective_date;

    // removing the old rates of GHS and GTL
    // id 1 signifies GTL old rates and id 2 signifies GHS old rates
    let oldPlanIdx1 = basePlans[0].plans.findIndex(x => x.id === 1);
    if (oldPlanIdx1 !== -1) {
      basePlans[0].plans.splice(oldPlanIdx1, 1);
    }

    let oldPlanIdx2 = basePlans[0].plans.findIndex(x => x.id === 2);
    if (oldPlanIdx2 !== -1) {
      basePlans[0].plans.splice(oldPlanIdx2, 1);
    }

    if (moment(policyEffectiveDate).isSameOrAfter(config.cut_off_date)) {
      // removing the GHS and GTL Old Plans Rates with info notes
      // id 5 signifies GTL_HR and id 6 signifies GHS_HR
      let hrPlanIdx = basePlans[0].plans.findIndex(x => x.id === 5);
      if (hrPlanIdx !== -1) {
        basePlans[0].plans.splice(hrPlanIdx, 1);
      }

      let hrPlanIdx2 = basePlans[0].plans.findIndex(x => x.id === 6);
      if (hrPlanIdx2 !== -1) {
        basePlans[0].plans.splice(hrPlanIdx2, 1);
      }
    } else {
      // original rates with information notes
      // removing the new rates of GHS and GTL
      // id 3 signifies GTL new rates and id 4 signifies GHS new rates
      let newPlanIdx1 = basePlans[0].plans.findIndex(x => x.id === 3);
      if (newPlanIdx1 !== -1) {
        basePlans[0].plans.splice(newPlanIdx1, 1);
      }

      let newPlanIdx2 = basePlans[0].plans.findIndex(x => x.id === 4);
      if (newPlanIdx2 !== -1) {
        basePlans[0].plans.splice(newPlanIdx2, 1);
      }
    }
    return basePlans;
  }

  render() {
    const {
      classes,
      types,
      tiers = null,
      selectionDisplayMode,
      displayAll = true,
      productCode = quote.product_code,
      resetTime = quote.resetTime,
      portalType
    } = this.props;

    var planVersions = [];
    planVersions.push(pruPlans);
    planVersions.push(pruPlansV2);

    //get version for screen plan display
    const productVersion = this.props.productVersion || this.props.quote.product_version;
    var planCurrentVersion = planVersions.find(plan => plan.version === productVersion);

    const basePlansDefault = displayAll ? planCurrentVersion[productCode] : this.getPlans();
    const basePlansClone = cloneDeep(basePlansDefault);

    let basePlans = this.getPlansShow(portalType, resetTime, basePlansClone);

    return (
      <div className={classes.root}>
        {basePlans.map((basePlan, _idxBasePlans) => {
          return (
            basePlan && (
              <div key={_idxBasePlans}>
                {_idxBasePlans > 0 && <br />}
                <Typography variant="headline" component="h2" className={classes.headline}>
                  {basePlan.title}
                </Typography>

                {basePlan.plans.map((plan, _idxPlan) => {
                  let selectedTiers = tiers && plan ? tiers[plan.code] : [];

                  return (
                    plan && (
                      <ExpansionPanel key={_idxPlan} className={classes.expansionPanel}>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          className={classes.expansionPanelSummary}
                        >
                          <Typography className={classes.expansionPanelSummaryLabel}>
                            {!displayAll && (
                              <span className={classes.titleContainer}>
                                <span className={classes.titleLabel}>{plan.label}</span>
                                <span className={classes.titleSelected}>{this.getPlanTitle(plan, selectedTiers)}</span>
                              </span>
                            )}

                            {displayAll && <span>{plan.label}</span>}
                          </Typography>
                        </ExpansionPanelSummary>

                        <ExpansionPanelDetails>
                          <PlanContent
                            data={plan.types}
                            types={types}
                            tiers={selectedTiers}
                            selectionDisplayMode={selectionDisplayMode}
                          />
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    )
                  );
                })}
              </div>
            )
          );
        })}
      </div>
    );
  }
}

Plans.defaultProps = {
  tiers: null,
  selectionDisplayMode: null
};

const styles = theme => ({
  root: {
    width: "100%"
  },
  flex: {
    flex: 1
  },
  expansionPanel: {
    margin: "16px 0"
  },
  expansionPanelSummary: {
    backgroundColor: theme.palette.default.main
  },
  expansionPanelSummaryLabel: {
    color: theme.palette.default.fontColor,
    width: "100%"
  },
  headline: {
    fontSize: "1em",
    fontWeight: 700
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  titleLabel: {
    width: "80%"
  },
  titleSelected: {
    flex: 1,
    fontWeight: "bold",
    color: "#C4184A"
  }
});

function mapStateToProps(state) {
  return { quote: state.quote };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(productActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Plans));
