export default [
    "Administrative and support service activities",
    "Advertising/public relations/marketing",
    "Agriculture and fishing",
    "Architecture/construction",
    "Arts, entertainment and recreation",
    "Automotive/transportation/aviation",
    "Banking/finance/insurance/securities",
    "Consulting/education/training/research and development",
    "Electronics/information technology",
    "Food and beverages service",
    "Government/statutory boards/uniformed groups",
    "Healthcare/medical/dental",
    "Hospitality/tourism",
    "Law/politics",
    "Manufacturing/Distribution",
    "Media/information and communications",
    "Oil & gas/mining and quarrying",
    "Others",
    "Professional, scientific and technical activities",
    "Real estate",
    "Retail/wholesale/general trading"
]