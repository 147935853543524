import Enums from "../../../../../utils/Enums";

// GHS ----------------------
let GHSBenefits = {
  headers: [
    {
      title: ``,
      mandatory: true,
      attributes: {
        style: {
          width: 10
        }
      }
    },
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "40%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `1`
        },
        {
          data: `<strong>Daily Room and Board Benefit</strong>`,
          info: `<em>(Per day, up to 120 days per disability)</em>`
        },
        {
          data: `1-Bed Ward (Private)`
        },
        {
          data: `1-Bed Ward (Private)`
        },
        {
          data: `1-Bed Ward (Private)`
        },
        {
          data: `1-Bed Ward (Private)`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `2`
        },
        {
          data: `<strong>Intensive Care Unit (ICU)</strong>`,
          info: `<em>(Max. per day, up to 30 days per disability)</em>`
        },
        {
          data: `3 x 1-Bedded (Private)`
        },
        {
          data: `3 x 1-Bedded (Private)`
        },
        {
          data: `3 x 1-Bedded (Private)`
        },
        {
          data: `3 x 1-Bedded (Private)`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `3`
        },
        {
          data: `<strong>High Dependency Ward (HDW)</strong>`,
          info: `<em>(Max. per day, up to 30 days per disability)</em>`
        },
        {
          data: `2 x 1-Bedded (Private)`
        },
        {
          data: `2 x 1-Bedded (Private)`
        },
        {
          data: `2 x 1-Bedded (Private)`
        },
        {
          data: `2 x 1-Bedded (Private)`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `4`
        },
        {
          data: `<strong>Other Hospital Services Benefits</strong>`,
          info: `<em>(Max. per disability)</em>`
        },
        {
          data: `S$25,000`,
          info: `per disability<br> limit for items<br> 4 to 6`,
          attributes: {
            rowspan: 3
          }
        },
        {
          data: `S$25,000`,
          info: `per disability<br> limit for items<br> 4 to 6`,
          attributes: {
            rowspan: 3
          }
        },
        {
          data: `S$30,000`,
          info: `per disability<br> limit for items<br> 4 to 6`,
          attributes: {
            rowspan: 3
          }
        },
        {
          data: `S$25,000`,
          info: `per disability<br> limit for items<br> 4 to 6`,
          attributes: {
            rowspan: 3
          }
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `5`
        },
        {
          data: `<p style="padding-left: 12px"><strong>Surgical Fees</strong></p>`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `6`
        },
        {
          data: `<p style="padding-left: 12px"><strong>In-Hospital Doctor's Consultation Benefit</strong></p>`,
          info: `<em>(One visit per day, max. 120 days)</em>`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `7`
        },
        {
          data: `<strong>Pre (90 days) & Post (90 days) Hospitalisation/ Surgery,  Specialist Consultation, Diagnostic X-Ray and Lab Test, Traditional Chinese Medicine</strong>`
        },
        {
          data: `S$5,000`,
          attributes: {
            rowspan: 2
          }
        },
        {
          data: `S$5,000`,
          attributes: {
            rowspan: 2
          }
        },
        {
          data: `S$6,000`,
          attributes: {
            rowspan: 2
          }
        },
        {
          data: `S$5,000`,
          attributes: {
            rowspan: 2
          }
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `8`
        },
        {
          data: `<p style="padding-left: 12px"><strong>Emergency Accidental Outpatient<br> Treatment Benefit</strong></p>`,
          info: `<em>(Including Accidental Dental Treatment)</em>`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `9`
        },
        {
          data: `<strong>Miscarriage Benefit</strong>`
        },
        {
          data: `As per disability`
        },
        {
          data: `As per disability`
        },
        {
          data: `As per disability`
        },
        {
          data: `As per disability`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `10`
        },
        {
          data: `<strong>Outpatient Kidney Dialysis & Outpatient Cancer Treatment</strong>`,
          info: `<em>(Max. per policy year)</em>`
        },
        {
          data: `S$ 20,000`
        },
        {
          data: `S$ 20,000`
        },
        {
          data: `S$ 25,000`
        },
        {
          data: `S$ 20,000`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `11`
        },
        {
          data: `<strong>Overseas Hospitalisation for Accident Benefit</strong>`
        },
        {
          data: `150% of GHS benefit (<em>for item 4 to 8</em>)`
        },
        {
          data: `150% of GHS benefit (<em>for item 4 to 8</em>)`
        },
        {
          data: `150% of GHS benefit (<em>for item 4 to 8</em>)`
        },
        {
          data: `150% of GHS benefit (<em>for item 4 to 8</em>)`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `12`
        },
        {
          data: `<strong>Rehabilitation Benefit</strong>`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        }
      ]
    },
    {
      id: 13,
      columns: [
        {
          data: `13`
        },
        {
          data: `<strong>Death Benefit</strong>`,
          info: `<em>(Double Death Benefits if due to accident)</em>`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 10,000`
        },
        {
          data: `S$ 5,000`
        }
      ]
    },
    {
      id: 14,
      columns: [
        {
          data: `14`
        },
        {
          data: `<strong>Co-Insurance</strong>`
        },
        {
          data: `NIL`
        },
        {
          data: `20% (for item 1 to 12)`
        },
        {
          data: `NIL`
        },
        {
          data: `20% (for item 1 to 12)`
        }
      ]
    }
  ],
  footnotes: []
};

let GHSPremiumEmployee = {
  title: `For Employee Only (inclusive of 7% GST)`,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 19</strong>`
        },
        {
          data: `S$ 378.00`
        },
        {
          data: `S$ 255.00`
        },
        {
          data: `S$ 204.00`
        },
        {
          data: `S$ 193.00`
        },
        {
          data: `S$ 136.00`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>20 - 24</strong>`
        },
        {
          data: `S$ 378.00`
        },
        {
          data: `S$ 255.00`
        },
        {
          data: `S$ 204.00`
        },
        {
          data: `S$ 197.00`
        },
        {
          data: `S$ 141.00`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>25 - 29</strong>`
        },
        {
          data: `S$ 378.00`
        },
        {
          data: `S$ 260.00`
        },
        {
          data: `S$ 207.00`
        },
        {
          data: `S$ 204.00`
        },
        {
          data: `S$ 146.00`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>30 - 34</strong>`
        },
        {
          data: `S$ 408.00`
        },
        {
          data: `S$ 292.00`
        },
        {
          data: `S$ 234.00`
        },
        {
          data: `S$ 217.00`
        },
        {
          data: `S$ 156.00`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>35 - 39</strong>`
        },
        {
          data: `S$ 408.00`
        },
        {
          data: `S$ 299.00`
        },
        {
          data: `S$ 239.00`
        },
        {
          data: `S$ 249.00`
        },
        {
          data: `S$ 179.00`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>40 - 44</strong>`
        },
        {
          data: `S$ 439.00`
        },
        {
          data: `S$ 363.00`
        },
        {
          data: `S$ 286.00`
        },
        {
          data: `S$ 322.00`
        },
        {
          data: `S$ 230.00`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>45 - 49</strong>`
        },
        {
          data: `S$ 633.00`
        },
        {
          data: `S$ 470.00`
        },
        {
          data: `S$ 388.00`
        },
        {
          data: `S$ 392.00`
        },
        {
          data: `S$ 272.00`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>50 - 54</strong>`
        },
        {
          data: `S$ 766.00`
        },
        {
          data: `S$ 604.00`
        },
        {
          data: `S$ 510.00`
        },
        {
          data: `S$ 532.00`
        },
        {
          data: `S$ 381.00`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>55 - 59</strong>`
        },
        {
          data: `S$ 939.00`
        },
        {
          data: `S$ 833.00`
        },
        {
          data: `S$ 663.00`
        },
        {
          data: `S$ 695.00`
        },
        {
          data: `S$ 497.00`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>60 - 64</strong>`
        },
        {
          data: `S$ 1,327.00`
        },
        {
          data: `S$ 1,042.00`
        },
        {
          data: `S$ 919.00`
        },
        {
          data: `S$ 921.00`
        },
        {
          data: `S$ 659.00`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>65 - 69</strong>`
        },
        {
          data: `S$ 1,820.00`
        },
        {
          data: `S$ 1,480.00`
        },
        {
          data: `S$ 1,218.00`
        },
        {
          data: `S$ 1,209.00`
        },
        {
          data: `S$ 865.00`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only.</em>`
        },
        {
          data: `S$ 3,400.00`
        },
        {
          data: `S$ 2,490.00`
        },
        {
          data: `S$ 2,005.00`
        },
        {
          data: `S$ 1,654.00`
        },
        {
          data: `S$ 1,183.00`
        }
      ]
    }
  ],
  footnotes: []
};

let GHSPremiumSpouseOrChild = {
  title: `For Employee and Spouse or Children Coverage Only (inclusive of 7% GST)`,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 19</strong>`
        },
        {
          data: `S$ 850.50`
        },
        {
          data: `S$ 573.75`
        },
        {
          data: `S$ 459.00`
        },
        {
          data: `S$ 434.25`
        },
        {
          data: `S$ 306.00`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>20 - 24</strong>`
        },
        {
          data: `S$ 850.50`
        },
        {
          data: `S$ 573.75`
        },
        {
          data: `S$ 459.00`
        },
        {
          data: `S$ 443.25`
        },
        {
          data: `S$ 317.25`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>25 - 29</strong>`
        },
        {
          data: `S$ 850.50`
        },
        {
          data: `S$ 585.00`
        },
        {
          data: `S$ 465.75`
        },
        {
          data: `S$ 459.00`
        },
        {
          data: `S$ 328.50`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>30 - 34</strong>`
        },
        {
          data: `S$ 918.00`
        },
        {
          data: `S$ 657.00`
        },
        {
          data: `S$ 526.50`
        },
        {
          data: `S$ 488.25`
        },
        {
          data: `S$ 351.00`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>35 - 39</strong>`
        },
        {
          data: `S$ 918.00`
        },
        {
          data: `S$ 672.75`
        },
        {
          data: `S$ 537.75`
        },
        {
          data: `S$ 560.25`
        },
        {
          data: `S$ 402.75`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>40 - 44</strong>`
        },
        {
          data: `S$ 987.75`
        },
        {
          data: `S$ 816.75`
        },
        {
          data: `S$ 643.50`
        },
        {
          data: `S$ 724.50`
        },
        {
          data: `S$ 517.50`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>45 - 49</strong>`
        },
        {
          data: `S$ 1,424.25`
        },
        {
          data: `S$ 1,057.50`
        },
        {
          data: `S$ 873.00`
        },
        {
          data: `S$ 882.00`
        },
        {
          data: `S$ 612.00`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>50 - 54</strong>`
        },
        {
          data: `S$ 1,723.50`
        },
        {
          data: `S$ 1,359.00`
        },
        {
          data: `S$ 1,147.50`
        },
        {
          data: `S$ 1,197.00`
        },
        {
          data: `S$ 857.25`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>55 - 59</strong>`
        },
        {
          data: `S$ 2,112.75`
        },
        {
          data: `S$ 1,874.25`
        },
        {
          data: `S$ 1,491.75`
        },
        {
          data: `S$ 1,563.75`
        },
        {
          data: `S$ 1,118.25`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>60 - 64</strong>`
        },
        {
          data: `S$ 2,985.75`
        },
        {
          data: `S$ 2,344.50`
        },
        {
          data: `S$ 2,067.75`
        },
        {
          data: `S$ 2,072.25`
        },
        {
          data: `S$ 1,482.75`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>65 - 69</strong>`
        },
        {
          data: `S$ 4,095.00`
        },
        {
          data: `S$ 3,330.00`
        },
        {
          data: `S$ 2,740.50`
        },
        {
          data: `S$ 2,720.25`
        },
        {
          data: `S$ 1,946.25`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 7,650.00`
        },
        {
          data: `S$ 5,602.50`
        },
        {
          data: `S$ 4,511.25`
        },
        {
          data: `S$ 3,721.50`
        },
        {
          data: `S$ 2,661.75`
        }
      ]
    }
  ],
  footnotes: []
};

let GHSPremiumSpouseAndChild = {
  title: `For Employee and Spouse and Children Coverage Only (inclusive of 7% GST)`,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 19</strong>`
        },
        {
          data: `S$ 1,323.00`
        },
        {
          data: `S$ 892.50`
        },
        {
          data: `S$ 714.00`
        },
        {
          data: `S$ 675.50`
        },
        {
          data: `S$ 476.00`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>20 - 24</strong>`
        },
        {
          data: `S$ 1,323.00`
        },
        {
          data: `S$ 892.50`
        },
        {
          data: `S$ 714.00`
        },
        {
          data: `S$ 689.50`
        },
        {
          data: `S$ 493.50`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>25 - 29</strong>`
        },
        {
          data: `S$ 1,323.00`
        },
        {
          data: `S$ 910.00`
        },
        {
          data: `S$ 724.50`
        },
        {
          data: `S$ 714.00`
        },
        {
          data: `S$ 511.00`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>30 - 34</strong>`
        },
        {
          data: `S$ 1,428.00`
        },
        {
          data: `S$ 1,022.00`
        },
        {
          data: `S$ 819.00`
        },
        {
          data: `S$ 759.50`
        },
        {
          data: `S$ 546.00`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>35 - 39</strong>`
        },
        {
          data: `S$ 1,428.00`
        },
        {
          data: `S$ 1,046.50`
        },
        {
          data: `S$ 836.50`
        },
        {
          data: `S$ 871.50`
        },
        {
          data: `S$ 626.50`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>40 - 44</strong>`
        },
        {
          data: `S$ 1,536.50`
        },
        {
          data: `S$ 1,270.50`
        },
        {
          data: `S$ 1,001.00`
        },
        {
          data: `S$ 1,127.00`
        },
        {
          data: `S$ 805.00`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>45 - 49</strong>`
        },
        {
          data: `S$ 2,215.50`
        },
        {
          data: `S$ 1,645.00`
        },
        {
          data: `S$ 1,358.00`
        },
        {
          data: `S$ 1,372.00`
        },
        {
          data: `S$ 952.00`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>50 - 54</strong>`
        },
        {
          data: `S$ 2,681.00`
        },
        {
          data: `S$ 2,114.00`
        },
        {
          data: `S$ 1,785.00`
        },
        {
          data: `S$ 1,862.00`
        },
        {
          data: `S$ 1,333.50`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>55 - 59</strong>`
        },
        {
          data: `S$ 3,286.50`
        },
        {
          data: `S$ 2,915.50`
        },
        {
          data: `S$ 2,320.50`
        },
        {
          data: `S$ 2,432.50`
        },
        {
          data: `S$ 1,739.50`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>60 - 64</strong>`
        },
        {
          data: `S$ 4,644.50`
        },
        {
          data: `S$ 3,647.00`
        },
        {
          data: `S$ 3,216.50`
        },
        {
          data: `S$ 3,223.50`
        },
        {
          data: `S$ 2,306.50`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>65 - 69</strong>`
        },
        {
          data: `S$ 6,370.00`
        },
        {
          data: `S$ 5,180.00`
        },
        {
          data: `S$ 4,263.00`
        },
        {
          data: `S$ 4,231.50`
        },
        {
          data: `S$ 3,027.50`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 11,900.00`
        },
        {
          data: `S$ 8,715.00`
        },
        {
          data: `S$ 7,017.50`
        },
        {
          data: `S$ 5,789.00`
        },
        {
          data: `S$ 4,140.50`
        }
      ]
    }
  ],
  footnotes: []
};

/*let GHSGroupDiscount = {
  headers: [
    {
      title: `Group Size <i>Insured Employee<i>`
    },
    {
      title: `Discount`
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>11 - 15</strong>`
        },
        {
          data: `5%`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>16 and above</strong>`
        },
        {
          data: `10%`
        }
      ]
    }
  ],
  footnotes: []
};*/

//   ----------------------------------------------------------------------

const GHS = {
  id: 2,
  code: "GHSB",
  label: "Group Hospital & Surgical (GHS)",
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GHSBenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium",
      data: [GHSPremiumEmployee, GHSPremiumSpouseOrChild, GHSPremiumSpouseAndChild]
    }
    /*{
      id: 4,
      order: 4,
      type: Enums.PLANS_TYPE.GROUP_DISCOUNT,
      label: "Group Size Discount",
      data: [GHSGroupDiscount]
    }*/
  ]
};

export default GHS;
