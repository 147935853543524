import GTL from "./GTL";
import GHS from "./GHS";
import GTL_2021 from "./GTL_2021";
import GHS_2021 from "./GHS_2021";
import GTL_HR from "./GTL_HR";
import GHS_HR from "./GHS_HR";

// Plans ----------------------
const CorePlans = [GTL, GHS, GTL_2021, GHS_2021, GTL_HR, GHS_HR];

export default CorePlans;
