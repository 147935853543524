import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Interweave } from "interweave";
import path from "path-browserify";
import React, { Component } from "react";
import SimpleConfirmDialog from "../../../../../components/SimpleConfirmDialog";
import WatchVideo from "../../../../../containers/Auth/components/watchVideo";

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openConfirmDialog: false,
      freeTrialClicked: false
    };
  }

  renderButton = type => {
    const { classes, banner } = this.props;
    switch (type) {
      case "validus":
        return (
          <div>
            <Button
              onClick={this.confirmBeforePreformingAction}
              variant="contained"
              color="primary"
              className={classes.buttonFirst}
            >
              {banner.button.first}
            </Button>
            <Button
              onClick={this.props.buttonClickControl.validus.requestACallback}
              variant="contained"
              color="secondary"
              className={classes.buttonSecond}
            >
              {banner.button.second}
            </Button>
          </div>
        );
      // case "hreasily":
      //   return (
      //     <div>
      //       <Button
      //         onClick={this.confirmBeforePreformingAction}
      //         variant="raised"
      //         color="primary"
      //         className={classes.buttonFirst}
      //       >
      //         {banner.button.first}
      //       </Button>
      //       <Button
      //         onClick={this.confirmBeforePreformingActionFreeTrial}
      //         variant="raised"
      //         color="primary"
      //         className={classes.buttonFirst}
      //       >
      //         {banner.button.second}
      //       </Button>
      //       <Button
      //         onClick={this.props.buttonClickControl.hreasily.downloadBrochure}
      //         variant="raised"
      //         color="primary"
      //         className={classes.buttonFirst}
      //       >
      //         {banner.button.third}
      //       </Button>
      //     </div>
      //   );
      case "wellteq":
        return (
          <div>
            <Button
              onClick={this.props.buttonClickControl.wellteq.requestACallback}
              variant="contained"
              color="primary"
              className={classes.buttonFirst}
            >
              {banner.button.first}
            </Button>
          </div>
        );
      case "smehealth":
        return (
          <div>
            <Button
              onClick={this.props.buttonClickControl.smehealth.requestACallback}
              variant="contained"
              color="primary"
              className={classes.buttonFirst}
            >
              {banner.button.first}
            </Button>
          </div>
        );
      // case "stash":
      //   return (
      //     <div>
      //       <Button
      //         onClick={this.confirmBeforePreformingAction}
      //         variant="raised"
      //         color="primary"
      //         className={classes.buttonFirst}
      //       >
      //         {banner.button.first}
      //       </Button>
      //     </div>
      //   );
      default:
        return;
    }
  };

  confirmBeforePreformingActionFreeTrial = () => {
    this.setState({
      openConfirmDialog: true,
      freeTrialClicked: true
    });
  };

  confirmBeforePreformingAction = () => {
    this.setState({ openConfirmDialog: true });
  };

  closeCofirmDialog = () => {
    this.setState({
      openConfirmDialog: false,
      freeTrialClicked: false
    });
  };

  responseConfirmDialog = () => {
    this.closeCofirmDialog();
    switch (this.props.banner.key) {
      case "validus": {
        return this.props.buttonClickControl.validus.getStarted();
      }
      case "hreasily": {
        if (this.state.freeTrialClicked) {
          return this.props.buttonClickControl.hreasily.freeTrial();
        } else {
          return this.props.buttonClickControl.hreasily.getStarted();
        }
      }
      case "stash": {
        return this.props.buttonClickControl.stash.getStarted();
      }
    }
  };

  calculateMaxHeight = (isSingleLogo, img) => {
    if (path.basename(img).includes("wellteq")) {
      return "80px";
    } else {
      if (isSingleLogo) {
        return "50px";
      } else {
        return "25px";
      }
    }
  };

  render() {
    const { classes, banner } = this.props;
    let singleLogo = banner.logo.length === 1;
    return (
      <div>
        <Grid
          container
          className={classes.banner}
          spacing={"0px"}
          style={{
            backgroundImage: "url(/static/media/" + path.basename(banner.background) + ")"
          }}
        >
          <Grid item sm={12} md={6}>
            <span className={classes.bannerHeader}>
              <Interweave content={banner.header} />
            </span>
            <p className={classes.bannerDesc}>
              <Interweave tagName="span" content={banner.subHeader} />
              {banner.subHeaderExplain && <div className={classes.subDesc}>{banner.subHeaderExplain}</div>}
            </p>
            {banner.youTubeID && <WatchVideo textAlign="left" youTubeID={banner.youTubeID} />}
            {this.renderButton(banner.key)}
          </Grid>
          <Grid className={classes.space} item sm={12} md={1} />
          <Grid item sm={12} md={5}>
            <p className={classes.logoHolder}>
              {banner.logo.map((img, index) => {
                return (
                  <img
                    key={index}
                    src={img}
                    alt="Logo"
                    className={index !== 0 ? classes.logo : null}
                    style={{
                      maxHeight: this.calculateMaxHeight(singleLogo, img)
                    }}
                  />
                );
              })}
            </p>
          </Grid>
        </Grid>
        {banner.subInformation.title !== "" ? (
          <div className={classes.subInformation}>
            <div className={classes.subTitle}>
              <Interweave tagName={"span"} content={banner.subInformation.title} />
            </div>
            {banner.subInformation.titleExplain && (
              <div className={classes.subDesc}>{banner.subInformation.titleExplain}</div>
            )}
            <div className={classes.subDesc}>{banner.subInformation.desc}</div>
          </div>
        ) : (
          ""
        )}
        <SimpleConfirmDialog
          name="leave-confirm-dialog"
          title="You’re leaving the HR Portal."
          description="We will redirect you to our partner’s website. Click ok to continue."
          closeButtonText="Cancel"
          okButtonText="OK"
          closeHandler={this.closeCofirmDialog}
          okHandler={this.responseConfirmDialog.bind(this)}
          open={this.state.openConfirmDialog}
        />
      </div>
    );
  }
}

const styles = theme => ({
  logo: {
    paddingLeft: "20px"
  },
  logoHolder: {
    textAlign: "right",
    marginTop: 0
  },
  buttonFirst: {
    minWidth: 150,
    marginRight: 10,
    padding: "10px 25px",
    color: "white"
  },
  buttonSecond: {
    minWidth: 150,
    backgroundColor: "transparent",
    border: "1px solid " + `${theme.colors.primary.red}`,
    color: `${theme.colors.primary.red}`,
    boxShadow: "none",
    padding: "10px 25px"
  },
  space: {
    padding: "20px"
  },
  banner: {
    padding: 40,
    minHeight: 240,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat"
  },
  bannerHeader: {
    fontSize: 37
  },
  bannerDesc: {
    lineHeight: 1.5,
    fontSize: 21
  },
  subInformation: {
    padding: 40,
    paddingTop: 20,
    whiteSpace: "pre",
    backgroundColor: "rgba(216, 216, 216, 0.31)"
  },
  subTitle: {
    fontSize: 24,
    marginTop: 0,
    marginBottom: 10
  },
  subDesc: {
    color: "#6B6A6D",
    paddingTop: 5,
    fontSize: 12
  }
});

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Banner));
