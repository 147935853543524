export const typographyRootStyle = {
  color: "red",
  whiteSpace: "pre-line",
  textAlign: "left",
  margin: 0,
  fontFamily: "Pru Sans,Roboto,Helvetica,Arial,sans-serif",
  fontWeight: 400,
  fontSize: "0.75rem",
  lineHeight: "1.66",
  maxWidth: "300px"
};
