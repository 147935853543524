import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import QuoteTypeDialogue from "./QuoteTypeDialogue";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";

class ConfirmMessageDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      agreeTnc: false
    };
  }
  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.open === true && prevState.agreeTnc === true) {
      this.state.agreeTnc = false;
    }
  }
  render() {
    const {
      name,
      title,
      description,
      open,
      closeHandler,
      closeButtonText,
      confirmHandler,
      confirmButtonText,
      icon,
      isCheckbox,
      checkboxLabel,
      classes
    } = this.props;
    const { agreeTnc } = this.state;

    const splitDescription = description ? description.split("~") : [description];

    return (
      <Dialog
        open={open}
        onClose={closeHandler}
        aria-labelledby={`${name}-title`}
        aria-describedby={`${name}-description`}
        maxWidth={splitDescription.length < 2 ? false : "md"}
        fullWidth={false}
      >
        {icon && <div className={classes.dialogIcon}>{icon}</div>}
        <DialogContent classes={{ root: splitDescription.length < 2 ? classes.dialogContent : null }}>
          <DialogTitle classes={{ root: classes.dialogTitle }} disableTypography={true}>
            {title}
          </DialogTitle>
          {splitDescription.map((item, index) => (
            <>
              <Typography variant="body1" color="textSecondary" key={index}>
                {item ? item.trim() : item}
              </Typography>
              {index !== splitDescription - 1 && <br />}
            </>
          ))}
          {isCheckbox && (
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  className={classes.tncCheckBox}
                  control={
                    <Checkbox
                      className={classes.tncCheckBox}
                      color="primary"
                      checked={agreeTnc}
                      name="agreeTnc"
                      onChange={this.handleChange("agreeTnc")}
                    />
                  }
                  label={checkboxLabel}
                />
              </FormGroup>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button
            onClick={closeHandler}
            variant="raised"
            className={classNames(classes.button, classes.buttonCancel)}
            autoFocus
          >
            {closeButtonText}
          </Button>
          <Button
            onClick={confirmHandler}
            className={classes.button}
            color="primary"
            variant="contained"
            disabled={isCheckbox ? (agreeTnc ? false : true) : false}
            autoFocus
          >
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = theme => ({
  dialogIcon: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20
  },
  dialogTitle: {
    paddingLeft: 0,
    fontSize: "1.1em",
    fontFamily: theme.typography.fontFamily
  },
  dialogContent: {
    maxWidth: 320,
    "&:first-child": {
      padding: "0 15px 15px 15px"
    }
  },
  button: {
    width: 130
  },
  buttonCancel: {
    backgroundColor: "transparent",
    border: `2px solid ${theme.colors.blackScale.black60}`,
    color: "#000"
  },
  dialogAction: {
    marginBottom: 20
  },
  tncCheckBox: {
    alignItems: "center"
  }
});

export default withStyles(styles, { withTheme: true })(ConfirmMessageDialog);
