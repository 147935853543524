import * as actionTypes from "../types";
import MyEmployeeService from "../../services/HR/myemployee.service";
import Util from "../../utils/Util";

export const myEmployeeActions = {
  getMyEmployees,
  getEmployeeDependants,
  emulateEmployee,
  sort,
  setSelectedEmployeeIds,
  sendInvite,
  showLoading,
  closePopup,
  getCategories,
  addEmployee,
  updateEmployee
};

function getMyEmployees(policy_no, company_id, data) {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.HR_MY_EMPLOYEE_SHOW_LOADING
    });
    MyEmployeeService.getMyEmployees(policy_no, company_id, data).then(
      response => {
        setTimeout(
          dispatch({
            type: actionTypes.HR_MY_EMPLOYEE_CLOSE_LOADING
          }),
          1500
        );
        if (response.success) {
          dispatch({
            type: actionTypes.HR_MY_EMPLOYEE_LOAD,
            payload: response.data
          });
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch({
          type: actionTypes.HR_MY_EMPLOYEE_CLOSE_LOADING
        });
        return Promise.reject(error);
      }
    );
  };
}

function getEmployeeDependants(emp_person_id) {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: actionTypes.HR_MY_EMPLOYEE_DEPENDANT_SHOW_LOADING
    });
    MyEmployeeService.getMyEmployeeDependants(emp_person_id, state.HR.coverage.policy.policy_no).then(
      response => {
        dispatch({
          type: actionTypes.HR_MY_EMPLOYEE_DEPENDANT_CLOSE_LOADING
        });
        if (response.success) {
          dispatch({
            type: actionTypes.HR_MY_EMPLOYEE_DEPENDANT_LOAD,
            payload: response.data
          });
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch({
          type: actionTypes.HR_MY_EMPLOYEE_DEPENDANT_CLOSE_LOADING
        });
        return Promise.reject(error);
      }
    );
  };
}

function emulateEmployee(employeeId, policy_no, company_id) {
  return dispatch => {
    dispatch({ type: actionTypes.HR_MY_EMPLOYEE_SHOW_LOADING });
    return MyEmployeeService.emulateEmployee(policy_no, employeeId, company_id)
      .then(response => {
        if (response.success) {
          dispatch({ type: actionTypes.HR_MY_EMPLOYEE_CLOSE_LOADING });
          return response.data;
        } else {
          throw new Error();
        }
      })
      .catch(error => {
        dispatch({ type: actionTypes.HR_MY_EMPLOYEE_CLOSE_LOADING });
        dispatch({ type: actionTypes.APP_500_ERROR, payload: { fail: true } });
        throw error;
      });
  };
}

function sort(payload) {
  return dispatch => {
    dispatch({ type: actionTypes.HR_MY_EMPLOYEE_SORT, payload });
  };
}

function setSelectedEmployeeIds(selectedIds) {
  return dispatch => {
    dispatch({
      type: actionTypes.HR_MY_EMPLOYEE_SET_SELECTED_IDS,
      payload: selectedIds
    });
  };
}

function sendInvite() {
  return (dispatch, getState) => {
    const state = getState();
    const selectedIds = state.HR.myemployee.selectedIds;
    return MyEmployeeService.sendInvite(selectedIds).then(
      response => {
        if (response.success) {
          dispatch({ type: actionTypes.HR_MY_EMPLOYEE_SEND_INVITE_SUCCESS });
          //refresh list to show new status
          dispatch(getMyEmployees());
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch({ type: actionTypes.HR_MY_EMPLOYEE_SEND_INVITE_ERROR });
        return Promise.reject(error);
      }
    );
  };
}

function closePopup() {
  return dispatch => {
    dispatch({ type: actionTypes.HR_MY_EMPLOYEE_CLOSE_POPUP });
  };
}

function getCategories() {
  return dispatch => {
    MyEmployeeService.getCategories().then(
      response => {
        if (response.success) {
          dispatch({
            type: actionTypes.HR_MY_EMPLOYEE_LOAD_CATEGORY_SUCCESS,
            payload: response.data
          });
        }
        return Promise.resolve(response);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function addEmployee(employee) {
  return (dispatch, getState) => {
    const policy_no = getState().HR.coverage.policy.policy_no;

    let _employee = {
      member: _mapMember(employee.member),
      dependents: employee.dependents.map(d => _mapDependent(d))
    };
    return MyEmployeeService.addEmployee(policy_no, _employee).then(
      response => {
        if (response.success) {
          dispatch({
            type: actionTypes.HR_MY_EMPLOYEE_ADD_SUCCESS,
            payload: response.data
          });
        }
        return Promise.resolve(response);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function updateEmployee(employee, originalCategory) {
  const category = employee.member.category;
  return (dispatch, getState) => {
    const policy_no = getState().HR.coverage.policy.policy_no;
    let _employee = {
      member: _mapMember(employee.member),
      dependents: employee.dependents.map(d => _mapDependent(d))
    };

    // Set original category so that the API will only see data changes
    if (originalCategory) {
      _employee.member.category = originalCategory;
    }
    const updatePromises = MyEmployeeService.updateEmployee(policy_no, _employee).then(response => {
      if (response.success) {
        // If the category also changed then also update that to API
        if (originalCategory && category !== originalCategory) {
          _employee.member.category = category;
          return MyEmployeeService.updateEmployee(policy_no, _employee);
        } else {
          return response;
        }
      } else {
        return Promise.reject();
      }
    });

    return updatePromises.then(
      response => {
        if (response.success) {
          dispatch({
            type: actionTypes.HR_MY_EMPLOYEE_ADD_SUCCESS,
            payload: response.data
          });
        }
        return Promise.resolve(response);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function showLoading(isShow) {
  return dispatch => {
    if (isShow) {
      dispatch({
        type: actionTypes.HR_MY_EMPLOYEE_SHOW_LOADING
      });
    } else {
      dispatch({
        type: actionTypes.HR_MY_EMPLOYEE_CLOSE_LOADING
      });
    }
  };
}

function _mapMember(person) {
  //Populate person values
  return {
    id: person.person_id,
    id_no: person.id_no,
    full_name: person.full_name,
    gender: Util.isEmpty(person.gender) ? null : person.gender,
    dob: Util.isEmpty(person.dob) ? null : person.dob,
    marital_status: Util.isEmpty(person.marital_status) ? null : person.marital_status,
    occupation_class: person.occupation_class,
    bank_account_name: person.bank_account_name,
    bank_account_number: person.bank_account_number,
    bank_name: person.bank_name,
    no_of_dependent: person.no_of_dependent,
    nationality: person.nationality,
    country_of_residence: person.country_of_residence,
    date_of_employment: Util.isEmpty(person.date_of_employment) ? null : person.date_of_employment,
    email: person.email,
    category: person.category,
    type: Util.isEmpty(person.type) ? null : person.type,
    dependency_type: Util.isEmpty(person.dependency_type) ? null : person.dependency_type,
    dependency_id_no: person.dependency_id_no,
    invalid_fields: person.invalid_fields,
    isValid: person.isValid,
    foreign_worker: person.foreign_worker,
    coverage_start_date: person.coverage_start_date,
    coverage_end_date: person.coverage_end_date,
    bank_code: person.bank_code,
    branch_code: person.branch_code
  };
}

function _mapDependent(person) {
  //Populate person values
  return {
    id: person.person_id,
    id_no: person.id_no,
    full_name: person.full_name,
    category: person.category,
    gender: Util.isEmpty(person.gender) ? null : person.gender,
    dob: Util.isEmpty(person.dob) ? null : person.dob,
    nationality: person.nationality,
    type: Util.isEmpty(person.type) ? null : person.type,
    invalid_fields: person.invalid_fields,
    isValid: person.isValid,
    coverage_start_date: person.coverage_start_date,
    coverage_end_date: person.coverage_end_date
  };
}
