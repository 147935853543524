import Enums from "../../../../utils/Enums";

const GFWMSumAssuredEmployee = {
  headers: [
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "20%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    },
    {
      title: `Plan 6`,
      tier: 6
    },
    {
      title: `Plan 7`,
      tier: 7
    },
    {
      title: `Plan 8`,
      tier: 8
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Limit Type<br></strong>`
        },
        {
          data: `Per Policy Year Limit`
        },
        {
          data: `Per Policy Year Limit`
        },
        {
          data: `Per Policy Year Limit`
        },
        {
          data: `Per Policy Year Limit`
        },
        {
          data: `Per Disability Limit`
        },
        {
          data: `Per Disability Limit`
        },
        {
          data: `Per Disability Limit`
        },
        {
          data: `Per Disability Limit`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>Benefit Limit</strong>`
        },
        {
          data: `S$ 80,000`
        },
        {
          data: `S$ 60,000`
        },
        {
          data: `S$ 80,000`
        },
        {
          data: `S$ 60,000`
        },
        {
          data: `S$ 80,000`
        },
        {
          data: `S$ 60,000`
        },
        {
          data: `S$ 80,000`
        },
        {
          data: `S$ 60,000`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>First Dollar Cover by Insurer</strong>`
        },
        {
          data: `Up to S$80,000`
        },
        {
          data: `Up to S$60,000`
        },
        {
          data: `First S$15,000`
        },
        {
          data: `First S$15,000`
        },
        {
          data: `Up to S$80,000`
        },
        {
          data: `Up to S$60,000`
        },
        {
          data: `First S$15,000`
        },
        {
          data: `First S$15,000`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>Co-payment</strong>`,
          info: `<i>after first S$15,000</i>`
        },
        {
          data: `N.A.`
        },
        {
          data: `N.A.`
        },
        {
          data: `25%`
        },
        {
          data: `25%`
        },
        {
          data: `N.A.`
        },
        {
          data: `N.A.`
        },
        {
          data: `25%`
        },
        {
          data: `25%`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>Hospital / Ward Type</strong>`
        },
        {
          data: `4-Bedded Government Restructured Hospital`
        },
        {
          data: `4-Bedded Government Restructured Hospital`
        },
        {
          data: `4-Bedded Government Restructured Hospital`
        },
        {
          data: `4-Bedded Government Restructured Hospital`
        },
        {
          data: `4-Bedded Government Restructured Hospital`
        },
        {
          data: `4-Bedded Government Restructured Hospital`
        },
        {
          data: `4-Bedded Government Restructured Hospital`
        },
        {
          data: `4-Bedded Government Restructured Hospital`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong> (1) Daily Room and Board</strong>`,
          info: `<i>Maximum of 120 days</i>`
        },
        {
          data: `As Charged up to Per Policy Year limit of S$80,000 for item 1 to 7`,
          attributes: {
            rowspan: "7"
          }
        },
        {
          data: `As Charged up to Per Policy Year limit of S$60,000 for item 1 to 7`,
          attributes: {
            rowspan: "7"
          }
        },
        {
          data: `As Charged up to Per Policy Year limit of S$80,000 for item 1 to 7`,
          attributes: {
            rowspan: "7"
          }
        },
        {
          data: `As Charged up to Per Policy Year limit of S$60,000 for item 1 to 7`,
          attributes: {
            rowspan: "7"
          }
        },
        {
          data: `As Charged up to Per Disability limit of S$80,000 for item 1 to 7`,
          attributes: {
            rowspan: "7"
          }
        },
        {
          data: `As Charged up to Per Disability limit of S$60,000 for item 1 to 7`,
          attributes: {
            rowspan: "7"
          }
        },
        {
          data: `As Charged up to Per Disability limit of S$80,000 for item 1 to 7`,
          attributes: {
            rowspan: "7"
          }
        },
        {
          data: `As Charged up to Per Disability limit of S$60,000 for item 1 to 7`,
          attributes: {
            rowspan: "7"
          }
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong> (2) Intensive Care Unit/ <br> High Dependency Ward</strong>`,
          info: ` <i>Maximum of 30 days</i>`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong> (3) Other Hospital Service</strong>`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong> (4) Surgical Fees</strong>`,
          info: `<i>< S$1,500 will not be subject to Surgical Schedule</i>`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong> (5) In-Hospital Doctor's Consultation</strong>`,
          info: `<i>Maximum of 120 days</i>`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong> (6) Pre (90 days) & Post (90 days) Hospitalisation / Surgery<strong>`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `<strong>(7) Emergency Accidental Outpatient Treatment</strong>`,
          info: `<i>Including Accidental Dental Treatment</i>`
        }
      ]
    },
    {
      id: 13,
      columns: [
        {
          data: `<strong>Optional Benefits</strong>`,
          info: `<i> Each Optional Benefit will add on additional loading on the total premium payable.</i>`
        },
        {
          data: ` `
        },
        {
          data: ` `
        },
        {
          data: ` `
        },
        {
          data: ` `
        },
        {
          data: ` `
        },
        {
          data: ` `
        },
        {
          data: ` `
        },
        {
          data: ` `
        }
      ]
    },
    {
      id: 14,
      columns: [
        {
          data: `<strong>(A) Worldwide Coverage</strong><br>
          Extend benefits under items 1 to 7 to insured Member without geographical limitation<br>
                <i>(Only Reasonable and Customary Charges incurred will be paid)</i>`,
          info: `<i>Additional 5% loading on selected base plan premium<i>`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        }
      ]
    },
    {
      id: 15,
      columns: [
        {
          data: `<strong>(B) Waiver of Waiting Period for Pre-Existing Condition</strong><br>
                Provide Pre-existing Conditions Waiting Period Waiver<br>
                <i>(Waiver of 12 months waiting period such that benefits under items 1 to 7 is/are payable
                even if sickness or injury that is due to Pre-existing Conditions, occurs within first 12 months of coverage)</i>`,
          info: `<i>Additional 10% loading on selected base plan premium<i>`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        }
      ]
    },
    {
      id: 16,
      columns: [
        {
          data: `<strong>(C) Worldwide Coverage with Waiver of Waiting Period for Pre-Existing Condition</strong><br>
          Coverage under (A) and (B) applies`,
          info: `<i> Additional 15% loading on selected base plan Premium</i>`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        }
      ]
    },
    {
      id: 17,
      columns: [
        {
          data: `<strong>Pro-ration Factors</strong>`,
          info: `<i>Pro-ration factor of 75% will apply for Plan 1 to 8 if Insured Member stays in a ward higher than his Room & Board Entitlement 
          (i.e. private Hospital or 1 -bedded / 2 bedded Government Restructured Hospital) as specified in the Benefit Schedule.</i>`
        },
        {
          data: `75%`
        },
        {
          data: `75%`
        },
        {
          data: `75%`
        },
        {
          data: `75%`
        },
        {
          data: `75%`
        },
        {
          data: `75%`
        },
        {
          data: `75%`
        },
        {
          data: `75%`
        }
      ]
    }
  ],
  footnotes: []
};

let GFWMPremiumOnly = {
  title: `<br><strong>* For Employee Only (inclusive of 8% GST) - Group Foreign Worker Medical Insurance (GFWM)<strong>`,
  headers: [
    {
      title: `Age Last`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    },
    {
      title: `Plan 6`,
      tier: 6
    },
    {
      title: `Plan 7`,
      tier: 7
    },
    {
      title: `Plan 8`,
      tier: 8
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16-50<br></strong>`
        },
        {
          data: `S$ 199.80`
        },
        {
          data: `S$ 187.92`
        },
        {
          data: `S$ 162.00`
        },
        {
          data: `S$ 154.44`
        },
        {
          data: `S$ 209.52`
        },
        {
          data: `S$ 197.64`
        },
        {
          data: `S$ 170.64`
        },
        {
          data: `S$ 162.00`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>51-69<br></strong>`
        },
        {
          data: `S$ 477.36`
        },
        {
          data: `S$ 449.28`
        },
        {
          data: `S$ 386.64`
        },
        {
          data: `S$ 368.28`
        },
        {
          data: `S$ 501.12`
        },
        {
          data: `S$ 471.96`
        },
        {
          data: `S$ 406.08`
        },
        {
          data: `S$ 386.64`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>70-74<br></strong>`
        },
        {
          data: `S$ 477.36`
        },
        {
          data: `S$ 449.28`
        },
        {
          data: `S$ 386.64`
        },
        {
          data: `S$ 368.28`
        },
        {
          data: `S$ 501.12`
        },
        {
          data: `S$ 471.96`
        },
        {
          data: `S$ 406.08`
        },
        {
          data: `S$ 386.64`
        }
      ]
    }
  ],
  footnotes: []
};

let GFWMOptionAPremium = {
  title: `<br><strong><u>Options for extension for Group Foreign Worker Medical Insurance (GFWM)</u>
          <br>
          <br>* For Employee Only (inclusive of 8% GST) for Option (A) - Worldwide Cover<strong>`,
  headers: [
    {
      title: `Age Last`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    },
    {
      title: `Plan 6`,
      tier: 6
    },
    {
      title: `Plan 7`,
      tier: 7
    },
    {
      title: `Plan 8`,
      tier: 8
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 50</strong>`
        },
        {
          data: `S$ 199.80`
        },
        {
          data: `S$ 187.92`
        },
        {
          data: `S$ 162.00`
        },
        {
          data: `S$ 154.44`
        },
        {
          data: `S$ 209.52`
        },
        {
          data: `S$ 197.64`
        },
        {
          data: `S$ 170.64`
        },
        {
          data: `S$ 162.00`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>51 - 69</strong>`
        },
        {
          data: `S$ 501.12`
        },
        {
          data: `S$ 471.96`
        },
        {
          data: `S$ 406.08`
        },
        {
          data: `S$ 386.64`
        },
        {
          data: `S$ 525.96`
        },
        {
          data: `S$ 495.72`
        },
        {
          data: `S$ 426.60`
        },
        {
          data: `S$ 406.08`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>70 - 74</strong>`
        },
        {
          data: `S$ 501.12`
        },
        {
          data: `S$ 471.96`
        },
        {
          data: `S$ 406.08`
        },
        {
          data: `S$ 386.64`
        },
        {
          data: `S$ 525.96`
        },
        {
          data: `S$ 495.72`
        },
        {
          data: `S$ 426.60`
        },
        {
          data: `S$ 406.08`
        }
      ]
    }
  ],
  footnotes: []
};

let GFWMOptionBPremium = {
  title: `<br><strong>* For Employee Only (inclusive of 8% GST) for Option (B) - Wavier of Waiting Period for Pre-Existing Condition <strong>`,
  headers: [
    {
      title: `Age Last`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    },
    {
      title: `Plan 6`,
      tier: 6
    },
    {
      title: `Plan 7`,
      tier: 7
    },
    {
      title: `Plan 8`,
      tier: 8
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 50</strong>`
        },
        {
          data: `S$ 209.52`
        },
        {
          data: `S$ 197.64`
        },
        {
          data: `S$ 169.56`
        },
        {
          data: `S$ 162.00`
        },
        {
          data: `S$ 220.32`
        },
        {
          data: `S$ 206.28`
        },
        {
          data: `S$ 178.20`
        },
        {
          data: `S$ 169.56`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>51 - 69</strong>`
        },
        {
          data: `S$ 524.88`
        },
        {
          data: `S$ 494.64`
        },
        {
          data: `S$ 425.52`
        },
        {
          data: `S$ 405.00`
        },
        {
          data: `S$ 550.80`
        },
        {
          data: `S$ 519.48`
        },
        {
          data: `S$ 447.12`
        },
        {
          data: `S$ 425.52`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>70 - 74</strong>`
        },
        {
          data: `S$ 524.88`
        },
        {
          data: `S$ 494.64`
        },
        {
          data: `S$ 425.52`
        },
        {
          data: `S$ 405.00`
        },
        {
          data: `S$ 550.80`
        },
        {
          data: `S$ 519.48`
        },
        {
          data: `S$ 447.12`
        },
        {
          data: `S$ 425.52`
        }
      ]
    }
  ],
  footnotes: []
};

let GFWMOptionCPremium = {
  title: `<br><strong>* For Employee Only (inclusive of 8% GST) for Option (C) -  Worldwide Coverage with Waiver of Waiting Period for Pre-Existing Condition<strong>`,
  headers: [
    {
      title: `Age Last`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    },
    {
      title: `Plan 6`,
      tier: 6
    },
    {
      title: `Plan 7`,
      tier: 7
    },
    {
      title: `Plan 8`,
      tier: 8
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 50</strong>`
        },
        {
          data: `S$ 218.16`
        },
        {
          data: `S$ 206.28`
        },
        {
          data: `S$ 177.12`
        },
        {
          data: `S$ 168.48`
        },
        {
          data: `S$ 230.04`
        },
        {
          data: `S$ 216.00`
        },
        {
          data: `S$ 186.84`
        },
        {
          data: `S$ 177.12`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>51 - 69</strong>`
        },
        {
          data: `S$ 548.64`
        },
        {
          data: `S$ 516.24`
        },
        {
          data: `S$ 444.96`
        },
        {
          data: `S$ 423.36`
        },
        {
          data: `S$ 576.72`
        },
        {
          data: `S$ 543.24`
        },
        {
          data: `S$ 466.56`
        },
        {
          data: `S$ 444.96`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>70 - 74</strong>`
        },
        {
          data: `S$ 548.64`
        },
        {
          data: `S$ 516.24`
        },
        {
          data: `S$ 444.96`
        },
        {
          data: `S$ 423.36`
        },
        {
          data: `S$ 576.72`
        },
        {
          data: `S$ 543.24`
        },
        {
          data: `S$ 466.56`
        },
        {
          data: `S$ 444.96`
        }
      ]
    }
  ],
  footnotes: []
};

const GFWM = {
  id: 20,
  code: "GFWM",
  label: "Group Foreign Worker Medical Insurance (GFWM)",
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GFWMSumAssuredEmployee]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium",
      data: [GFWMPremiumOnly, GFWMOptionAPremium, GFWMOptionBPremium, GFWMOptionCPremium]
    }
  ]
};

export default GFWM;
