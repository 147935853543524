import * as actionTypes from "../actions/types";

const detailsObject = {
  address: " ",
  address2: " ",
  address3: " ",
  address4: " ",
  address5: " ",
  agent_first_name: " ",
  agent_last_name: " ",
  agent_code: " ",
  agent_email: " ",
  auth_signatory_designation: " ",
  auth_signatory_name: " ",
  contact_email: "",
  contact_name: " ",
  contact_no: " ",
  name: " ",
  nature_of_business: " ",
  notes: " ",
  uen_no: "",
  encrypted_password: "",
  postal_code: " ",
  referral_id_1: " ",
  referral_id_2: " ",
  bank_account_name: " ",
  bank_code: " ",
  bank_name: " ",
  bank_account_number: " ",
  branch_code: " ",
  bank_swift_code: " "
};

const initialState = {
  details: { ...detailsObject },
  data: {
    categories: [],
    estimates: [],
    persons: []
  }
};

export const company = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.COMPANY_DETAIL_RESET:
      return initialState;
    case actionTypes.COMPANY_SET_FORM_DETAILS:
      return {
        ...state,
        details: {
          ...state.details,
          [action.payload.key]: action.payload.value
        }
      };
    case actionTypes.COMPANY_DETAIL_SAVE_REQUEST:
      return {
        ...state,
        isSaving: true,
        saveSuccess: false,
        saveError: false
      };
    case actionTypes.COMPANY_DETAIL_SAVE_SUCCESS:
      return {
        ...state,
        isSaving: false,
        saveSuccess: true,
        saveError: false
      };
    case actionTypes.COMPANY_DETAIL_SAVE_ERROR:
      return {
        ...state,
        isSaving: false,
        saveSuccess: false,
        saveError: true
      };
    case actionTypes.COMPANY_DETAIL_GET_PENDING:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.COMPANY_DETAIL_GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        loadedSuccessTime: new Date(),
        details: action.payload.data === "" ? initialState.details : action.payload.data
      };
    case actionTypes.COMPANY_DETAIL_GET_REJECTED: {
      let _state = Object.assign({}, initialState);
      return {
        ..._state,
        isLoading: false,
        isLoaded: false,
        isLoadedError: true,
        loadedErrorTime: new Date()
      };
    }
    case actionTypes.COMPANY_DETAIL_PUT_PENDING:
      return {
        ...state,
        isPutting: true
      };
    case actionTypes.COMPANY_DETAIL_PUT_FULFILLED:
      return {
        ...state,
        isPutting: false,
        isPutted: true,
        isPuttedError: false,
        puttedSuccessTime: new Date(),
        details: action.payload.data
      };
    case actionTypes.COMPANY_DETAIL_PUT_REJECTED:
      return {
        ...state,
        isPutting: false,
        isPutted: false,
        isPuttedError: true,
        puttedErrorTime: new Date()
      };
    case actionTypes.COMPANY_DETAIL_POST_PENDING:
      return {
        ...state,
        isPosting: true
      };
    case actionTypes.COMPANY_DETAIL_POST_FULFILLED:
      return {
        ...state,
        isPosting: false,
        isPosted: true,
        postedSuccessTime: new Date(),
        details: action.payload.data
      };
    case actionTypes.COMPANY_DETAIL_POST_REJECTED:
      return {
        ...state,
        isPosting: false,
        isPosted: false,
        isPuttedError: true,
        postedErrorTime: new Date()
      };
    case actionTypes.SET_CACHE_TO_COMPANY_DETAIL:
      return {
        ...state,
        ...action.payload.data,
        details: action.payload.company,
        existingPolicyNo: action.payload.policy_no
      };

    default:
      return state;
  }
};
