import Util from "./Util";

const Regex = {
  email: value =>
    /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(
      value
    ),
  encryptedPassword: value => /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\S+$).{8,}$/g.test(value),
  onlyNumber: value => /^\d+$/.test(value),
  postalCode: value => /^\d{6}$/.test(value),
  referralCode: value => /^[a-zA-Z0-9]{0,7}$/.test(value),
  nricValidate: value => /[^\w]|_/g.test(value),
  nbstpFieldValidateCompanyName: value => {
    const reg = new RegExp(Util.nbstpFieldRegexCompanyname);
    return reg.test(value);
  },
  nbstpFieldValidateCompanyContactName: value => {
    const reg = new RegExp(Util.nbstpFieldRegexCompanyContactname);
    return reg.test(value);
  },
  nbstpFieldValidate: value => {
    const reg = new RegExp(Util.nbstpFieldRegex);
    return reg.test(value);
  },
  percentage: (value, name) => {
    console.log(name, value);
  }
};
/**
 * Check if field length more than max value
 * @param max The maximum length
 */
const maxLength = max => value => (value && value.length > max ? `Must be ${max} characters or less` : undefined);

const nbstpValidationLimit = max => value =>
  value && value.length > max ? `Input exceeded ${max} characters limit` : undefined;

const ReduxFormValidate = {
  required: value => (value ? undefined : "This field is required"),
  signatory: value =>
    value && Util.isEmpty(value) ? "Please provide an authorised signatory for the company." : undefined,
  postalCode: value => (value && !Regex.postalCode(value) ? "Please enter a valid 6 digit postal code." : undefined),
  referralCode: value =>
    value && !Regex.referralCode(value) ? "Please enter a valid 7 characters referral code." : undefined,
  contactNumber: value =>
    value && !Regex.onlyNumber(value) ? "Please provide a valid contact number for the company." : undefined,
  email: value => (value && !Regex.email(value) ? "The email address provided is invalid." : undefined),
  hrEmail: value =>
    value && (!Regex.email(value) || !Util.isValidDomain(value))
      ? "Please provide a valid email address for the company's HR contact person."
      : undefined,
  encryptedPassword: value =>
    value && !Regex.encryptedPassword(value)
      ? "The password you selected MUST " +
        "\n - be at least 8 characters in length" +
        "\n - contain at least 1 upper case letter and 1 lower case letter" +
        "\n - contain at least 1 numeral" +
        "\n - contain NO whitespaces"
      : undefined,
  maxLength100: maxLength(100),
  alphaNumeric: value => (value && Regex.nricValidate(value) ? "Please enter only Aplhabet and Number" : undefined),
  allowOnlyNumber: value => (value && !Regex.onlyNumber(value) ? "Please enter only number" : undefined),
  nbstpValidation: value =>
    value && !Regex.nbstpFieldValidate(value) ? "Invalid characters in name field" : undefined,
  nbstpValidationCompanyName: value =>
    value && !Regex.nbstpFieldValidateCompanyName(value) ? "Invalid characters in name field" : undefined,
  nbstpValidationCompanyContactName: value =>
    value && !Regex.nbstpFieldValidateCompanyContactName(value) ? "Invalid character in the name field" : undefined,
  nbstpValidationLimitCompanyNumber: nbstpValidationLimit(16),
  nbstpValidationLimitCompanyAddress1: nbstpValidationLimit(30),
  nbstpValidationOptional: value => {
    if (!value) {
      return undefined; // If no value provided, treat as valid
    }
    return !Regex.nbstpFieldValidate(value) ? "Invalid characters in name field" : undefined;
  },
  percentage: (value, name) =>
    value && !Regex.percentage(value, name) ? "Please enter only number less 100" : undefined,
  isEmailForbiddenDomainPattern: value =>
    Util.isEmailForbiddenDomainPatterns(value)
      ? "This email domain is not allowed, please provide another email address."
      : undefined,
  isEmailForbiddenUsernamePatterns: value =>
    Util.isEmailForbiddenUsernamePatterns(value)
      ? "Please provide a valid email address for the company's HR contact person."
      : undefined
};

export default ReduxFormValidate;
