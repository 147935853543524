import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import _ from "lodash";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InfoButton from "../../../../components/InfoButton";
import InfoPopover from "../../../../components/InfoPopover";
import codeplan from "../../../../assets/data/codeplan";

class CategoryPlansTablePF3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      infoPopover: {
        open: false,
        element: null,
        data: ""
      }
    };
  }

  handleClickInfoButton = (data, id) => event => {
    let infoPopover = {
      ...this.state.infoPopover
    };
    infoPopover.open = !infoPopover.open;
    if (infoPopover.open) {
      infoPopover.element = this.refs[id];
      infoPopover.data = data;
    }
    this.setState({ infoPopover });
  };

  handleCloseDialog() {
    let infoPopover = {
      ...this.state.infoPopover,
      open: false
    };
    this.setState({ infoPopover });
  }

  renderInfoPopover = () => {
    const { infoPopover } = this.state;

    return (
      <InfoPopover
        open={infoPopover.open}
        data={infoPopover.data}
        element={infoPopover.element}
        handleClickInfoButton={this.handleClickInfoButton}
      />
    );
  };

  getPlansConfiguration() {
    const { data } = this.props;
    let allConfiguration = [];

    //form array with all configuration
    data.forEach(d => {
      allConfiguration.push(d.plansConfiguration);
    });

    //return uniq plan configuration
    return _.uniqBy(_.flatten(allConfiguration), "code");
  }

  getBorderStyling(idx, coreCounter, classes) {
    let borderStyling = "";
    if (coreCounter == 1 || coreCounter - 1 == idx || coreCounter == idx) {
      borderStyling = classes.rightBorder;
    }
    return borderStyling;
  }

  render() {
    const { classes, data, config, extendedBenefits } = this.props;
    const allConfigurationCode = this.getPlansConfiguration();

    let suppCounter = 0;
    let coreCounter = 0;

    codeplan[config.code].map((p, idx) => {
      if (p.type == "Supplementary") {
        if (_.find(allConfigurationCode, ["code", p.value])) suppCounter++;
      }
      if (p.type == "Core") {
        if (_.find(allConfigurationCode, ["code", p.value])) coreCounter++;
      }
    });

    if (!data) {
      return (
        <div className={classes.root}>
          <div className={classes.content}>
            <Typography color="inherit">No insurance coverage available.</Typography>
          </div>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        {/*Employee Listing*/}
        <Paper className={classes.root}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.employeeTableTitle}>Employee Category</TableCell>
                <TableCell className={classes.employeeTableTitle + " " + classes.rightBorder}>
                  No. Of Person(s)
                </TableCell>
                <TableCell className={classes.employeeTableTitle + " " + classes.rightBorder} colSpan={coreCounter}>
                  Core Plan(s)
                </TableCell>
                <TableCell
                  className={suppCounter == 0 ? classes.hidden : classes.employeeTableTitle}
                  colSpan={suppCounter}
                >
                  Supplementary Plan(s)
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell className={classes.planTableTitleInside + " " + classes.rightBorder} colSpan={2} />
                {codeplan[config.code].map((p, idx) => {
                  if (p.type === "Core") {
                    const borderStyling = this.getBorderStyling(idx, coreCounter, classes);
                    if (_.find(allConfigurationCode, ["code", p.value]))
                      // render only if exist in planConfiguration
                      return (
                        <TableCell key={idx} className={classes.planTableTitleInside + " " + borderStyling}>
                          <div>
                            {p.sCode}
                            <InfoButton
                              variant="outlined"
                              ref={`btnInfoBody${idx}`}
                              id={`btnInfoBody${idx}`}
                              data={p.label}
                              handleClickInfoButton={this.handleClickInfoButton}
                            />
                          </div>
                        </TableCell>
                      );
                  }
                })}

                {codeplan[config.code].map((p, idx) => {
                  if (_.find(allConfigurationCode, ["code", p.value]))
                    if (p.type === "Supplementary") {
                      // render only if exist in planConfiguration
                      return (
                        p.sCode !== "GAMR" && (
                          <TableCell key={idx} className={classes.planTableTitleInside}>
                            <div>
                              {p.sCode}
                              <InfoButton
                                variant="outlined"
                                ref={`btnInfoBody${idx}`}
                                id={`btnInfoBody${idx}`}
                                data={p.label}
                                handleClickInfoButton={this.handleClickInfoButton}
                              />
                            </div>
                          </TableCell>
                        )
                      );
                    }
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map((d, id) => {
                let noOfDependents = d.noOfDependents || 0;
                let noOfEmployees = d.noOfEmployees || 0;
                let empRow = (
                  <TableRow key={id}>
                    <TableCell>{d.name}</TableCell>
                    <TableCell className={classes.rightBorder}>{d.noOfEmployees}</TableCell>

                    {codeplan[config.code].map((p, idx) => {
                      let plan = d.plansConfiguration.find(_p => _p.code === p.value);
                      let hasGAMR = d.plansConfiguration.find(_p => _p.code === "GAMR") ? true : false;
                      let selectedTier = plan && plan.tier ? `Plan ${plan.tier}` : "-";
                      const borderStyling = this.getBorderStyling(idx, coreCounter, classes);
                      if (plan) {
                        if (p.type === "Core") {
                          if (plan.code === "GFWM") {
                            let benefitName = "";

                            extendedBenefits.map(benefit => {
                              if (d.name === benefit.categoryName) {
                                if (benefit.benefitCode === "B") {
                                  benefitName = "(Worldwide Coverage)";
                                } else if (benefit.benefitCode === "C") {
                                  benefitName = "(Waiver of Waiting Period for Pre-Existing Condition)";
                                } else if (benefit.benefitCode === "D") {
                                  benefitName =
                                    "(Worldwide Coverage with Waiver of Waiting Period for Pre-Existing Condition)";
                                }
                              }
                            });

                            return (
                              <TableCell key={idx} className={borderStyling}>
                                {selectedTier}
                                {benefitName !== "" && (
                                  <InfoButton
                                    variant="outlined"
                                    ref={`btnInfoBody-${d.name}-${idx}`}
                                    id={`btnInfoBody-${d.name}-${idx}`}
                                    data={benefitName}
                                    handleClickInfoButton={this.handleClickInfoButton}
                                  />
                                )}
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell key={idx} className={borderStyling}>
                                {selectedTier}
                              </TableCell>
                            );
                          }
                        } else {
                          return (
                            plan.code !== "GAMR" && (
                              <TableCell key={idx}>
                                {selectedTier}
                                {plan.code === "GADD" && hasGAMR && (
                                  <InfoButton
                                    variant="outlined"
                                    ref={`btnInfoBody-${d.name}-${idx}`}
                                    id={`btnInfoBody-${d.name}-${idx}`}
                                    data={"(With Group Accidental Medical Reimbursement)"}
                                    handleClickInfoButton={this.handleClickInfoButton}
                                  />
                                )}
                              </TableCell>
                            )
                          );
                        }
                      } else {
                        if (_.find(allConfigurationCode, ["code", p.value])) {
                          if (p.type === "Core") {
                            return (
                              <TableCell key={idx} className={borderStyling}>
                                {" "}
                                -{" "}
                              </TableCell>
                            );
                          } else {
                            return p.value !== "GAMR" && <TableCell key={idx}> - </TableCell>;
                          }
                        }
                      }
                    })}
                  </TableRow>
                );

                let dependantRow = (
                  <TableRow
                    key={"dep" + id}
                    className={classNames(noOfDependents === 0 && classes.trCellDataDim, {
                      hidden: !config.enableDependent
                    })}
                  >
                    <TableCell>Dependants ({d.name})</TableCell>
                    <TableCell className={classes.rightBorder}>{noOfDependents}</TableCell>

                    {codeplan[config.code].map((p, idx) => {
                      let plan = d.plansConfiguration.find(_p => _p.code === p.value);
                      let selectedTier =
                        plan && plan.enableDependent && plan.tier && noOfDependents !== 0 ? `Plan ${plan.tier}` : "-";
                      const borderStyling = this.getBorderStyling(idx, coreCounter, classes);

                      if (plan) {
                        if (p.type === "Core") {
                          return (
                            <TableCell key={idx} className={borderStyling}>
                              {selectedTier}
                            </TableCell>
                          );
                        } else {
                          return plan.code !== "GAMR" && <TableCell key={idx}>{selectedTier}</TableCell>;
                        }
                      } else {
                        if (_.find(allConfigurationCode, ["code", p.value])) {
                          return (
                            p.value !== "GAMR" && (
                              <TableCell key={idx} className={borderStyling}>
                                {" "}
                                -{" "}
                              </TableCell>
                            )
                          );
                        }
                      }
                    })}
                  </TableRow>
                );

                let grabDependantRow = (
                  <TableRow
                    key={"dep" + id}
                    className={classNames(noOfDependents === 0 && classes.trCellDataDim, {
                      hidden: !config.enableDependent
                    })}
                  >
                    <TableCell>{d.name}</TableCell>
                    <TableCell className={classes.rightBorder}>{noOfDependents}</TableCell>

                    {codeplan[config.code].map((p, idx) => {
                      let plan = d.plansConfiguration.find(_p => _p.code === p.value);
                      let selectedTier =
                        plan && plan.enableDependent && plan.tier && noOfDependents !== 0 ? `Plan ${plan.tier}` : "-";
                      const borderStyling = this.getBorderStyling(idx, coreCounter, classes);
                      if (plan) {
                        if (p.type === "Core") {
                          return (
                            <TableCell key={idx} className={borderStyling}>
                              {selectedTier}
                            </TableCell>
                          );
                        } else {
                          return <TableCell key={idx}>{selectedTier}</TableCell>;
                        }
                      } else {
                        if (_.find(allConfigurationCode, ["code", p.value])) {
                          return (
                            <TableCell key={idx} className={borderStyling}>
                              {" "}
                              -{" "}
                            </TableCell>
                          );
                        }
                      }
                    })}
                  </TableRow>
                );

                if (config && config.code && config.code === "GCP") {
                  if (noOfEmployees != 0 && noOfDependents != 0) {
                    return [empRow, grabDependantRow];
                  } else if (noOfDependents == 0) {
                    return empRow;
                  } else if (noOfEmployees == 0) {
                    return grabDependantRow;
                  }
                } else {
                  return [empRow, dependantRow];
                }
              })}
            </TableBody>
          </Table>
          {this.renderInfoPopover()}
        </Paper>
      </div>
    );
  }
  renderInfoDialog = () => {
    const { infoPopover } = this.state;

    return (
      <SimpleMessageDialog
        type="none"
        name="info"
        title="Info"
        description={infoPopover.data}
        alignCenter={false}
        isHtml={true}
        open={infoPopover.open}
        closeHandler={this.handleCloseDialog.bind(this)}
      />
    );
  };
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto"
  },
  trCellDataDim: {
    opacity: 0.5
  },
  cellInfo: {
    minWidth: 65
  },
  employeeTableTitle: {
    fontSize: 15,
    fontWeight: "bold",
    color: "black",
    backgroundColor: "#F8F8F8",
    borderBottom: "none"
  },
  planTable: {
    backgroundColor: "#F8F8F8"
  },
  planTableTitleInside: {
    fontSize: 13,
    fontWeight: "bold",
    color: "black",
    backgroundColor: "#F8F8F8",
    borderBottom: "none",
    paddingRight: "0px",
    paddingTop: "0px",
    paddingBottom: "0px"
  },
  rightBorder: {
    borderRight: "1px solid rgba(224, 224, 224, 1)"
  },
  hidden: {
    display: "none"
  },
  removeBorder: {
    borderRight: "none"
  }
});

function mapStateToProps(state) {
  return {
    hr_coverage: state.HR.coverage,
    products: state.products
  };
}

function mapDispatchToProps(dispatch) {
  return {
    //...bindActionCreators(quotesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(CategoryPlansTablePF3)));
