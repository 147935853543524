import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { withStyles } from "@mui/styles";
import { isEmpty } from "lodash";
import React, { Component } from "react";
import assets from "../../../../assets";
import Util from "../../../../utils/Util";
import PdfViewerBase64 from "../../../../components/pdfViewerBase64";
import DocumentService from "../../../../services/document.service";
import Enums from "../../../../utils/Enums";
import { connect } from "react-redux";

class UsefulDocTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pdf: undefined,
      pdfContentBase64: "",
      fileName: undefined,
      open: false,
      isPDF: false
    };
  }

  handleClick = async (fileNameOriginal, fileName) => {
    let result = await DocumentService.getDocumentByName(fileNameOriginal);

    let isPDF = result.data.file_mime_type === "application/pdf" ? true : false;

    let pdfContentBase64 = result.data.file_content;
    let file = `data:${result.data.file_mime_type};base64,${result.data.file_content}`;

    this.setState({ pdf: file, pdfContentBase64, fileName: fileName, open: true, isPDF: isPDF });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  renderDataCell(data, classes, policy, appType) {
    let policyDisplay = policy.displayType;
    let policyCoverage = policy.policyCoverage;
    let contractType = policyDisplay === "customised" ? "Customized" : policy.contractType;

    const displayTypeMatch =
      (data.displayTypes && data.displayTypes.includes(policyDisplay)) || data.displayType === policyDisplay;
    const appTypeMatch = appType === Enums.APP_TYPE.SALES && data.type === Enums.P_CODE.PRU_AGENCY;

    const codeTypeMatch =
      data.code &&
      !Util.isEmpty(data.code[contractType]) &&
      data.code[contractType].flat().some(code => {
        if (Array.isArray(code)) {
          return code.some(innerCode => policyCoverage.includes(innerCode));
        } else {
          return policyCoverage.includes(code);
        }
      });

    const showDoc =
      data.code && !Util.isEmpty(data.code[contractType])
        ? displayTypeMatch && appTypeMatch && codeTypeMatch
        : displayTypeMatch && appTypeMatch;

    if (showDoc) {
      return (
        <TableRow className={classes.dataRow} onClick={() => this.handleClick(data.fileNameOriginal, data.fileName)}>
          <TableCell>{isEmpty(data.desc) ? "-" : data.desc}</TableCell>
        </TableRow>
      );
    }
  }

  render() {
    const { header, classes, policy, appType } = this.props;
    return (
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.header}>
                Useful Documents
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{assets.pcDoc.map(d => this.renderDataCell(d, classes, policy, appType))}</TableBody>
        </Table>
        <PdfViewerBase64
          open={this.state.open}
          handleClose={this.handleClose}
          pdf={this.state.pdf}
          pdfContentBase64={this.state.pdfContentBase64}
          isPDF={this.state.isPDF}
          fileName={this.state.fileName}
        />
      </TableContainer>
    );
  }
}

const styles = theme => ({
  header: {
    backgroundColor: "black",
    color: "white !important",
    fontWeight: "bold"
  },
  table: {
    "& th, & td": {
      border: "1px solid rgba(224, 224, 224, 1)"
    }
  },
  dataRow: {
    backgroundColor: "white",
    cursor: "pointer",
    textDecoration: "underline"
  }
});

function mapStateToProps(state) {
  return {
    policy: state.policyDetails.policy,
    appType: state.app.type
  };
}

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(UsefulDocTable));
