const ENUMS = {
  ACCESSIBLE_ROLES: {
    AGENT: "AGENT",
    HR: "HR"
  },
  SCOPE_TYPE: {
    TC_W: "tc:w",
    OTP_W: "otp:w",
    PRODUCT_INFO_R: "product_info:r",
    QUOTE_W: "quote:w",
    QUOTE_R: "quote:r",
    DOCUMENT_SALE_R: "document[sales]:r",
    TUTORIAL_R: "tutorial:r",
    CLIENT_AND_POLICY_LISTING_HR_R: "client_and_policy_listing[hr]:r",
    DASHBOARD_HR_R: "dashboard[hr]:r",
    INS_COV_HR_R: "ins_cov[hr]:r",
    EMP_HR_R: "employee:r",
    EMP_BENEFIT_HR_R: "emp_benefit:r",
    COMPANY_HR_R: "company:r",
    DOCUMENT_HR_R: "dashboard[hr]:r",
    TRAINING_R: "trg:r",
    CLAIM_EXP_HR_R: "claim_expense[hr]:r"
  },
  P_CODE: {
    UOB_ASSISTED: "UOB_ASSISTED",
    PRU_AGENCY: "PRU_AGENCY",
    CUSTOMIZED: "CUSTOMIZED",
    SCB_ASSISTED: "SCB_ASSISTED",
    UOB_TEAM_2: "UOB_TEAM_2",
    PRU_CE: "PRU_CE",
    PFA: "PFA",
    BRO: "BRO"
  },
  CHN_CODE: {
    PD: "PD",
    AD: "AD",
    PFA: "PFA",
    BRO: "BRO"
  },
  APP_TYPE: {
    SALES: "SALES",
    HR: "HR"
  },
  STAGES: {
    ESTIMATE: "ESTIMATE",
    EMPLOYEE: "EMPLOYEE",
    PROPOSAL: "PROPOSAL",
    SUBMISSION: "SUBMISSION",
    SUBMITTED: "SUBMITTED",
    INFORCE: "INFORCE" //PROCESSED (on local)
  },
  PERSON_TYPE: {
    EMPLOYEE: "E",
    SPOUSE: "S",
    CHILD: "C"
  },
  DEPENDENCY_TYPE: {
    NONE: "None",
    SPOUSE: "Spouse",
    CHILDREN: "Children",
    FAMILY: "Family"
  },
  MARTIAL_STATUS: {
    MARRIED: "Married",
    DIVORCED: "Divorced",
    SINGLE: "Single"
  },
  PLANS_TYPE: {
    BENEFITS: "BENEFITS",
    PREMIUM: "PREMIUM",
    GROUP_DISCOUNT: "GROUP_DISCOUNT"
  },
  PLANS_CODE: {
    HDF: "HDF",
    GOSP: "GOSP",
    GCCA: "GCCA",
    GAMR: "GAMR",
    GEMM: "GEMM",
    GTLB: "GTLB",
    GPRU: "GPRU",
    GDEN: "GDEN",
    GHSB: "GHSB",
    GADD: "GADD",
    PGIH: "PGIH",
    PSIH: "PSIH",
    GEAC: "GEAC",
    GPPD: "GPPD",
    GEAC: "GEAC",
    PDIH: "PDIH",
    GFWM: "GFWM",
    GCCE: "GCCE",
    GDIB: "GDIB",
    DI08: "DI08",
    DI18: "DI18",
    DI19: "DI19",
    DI39: "DI39",
    DI48: "DI48",
    GEEC: "GEEC",
    GHPS: "GHPS",
    GMAT: "GMAT",
    GOPB: "GOPB",
    GSMM: "GSMM",
    P3GP: "P3GP",
    P3HS: "P3HS",
    P3SP: "P3SP",
    PGAT: "PGAT",
    PSAT: "PSAT",
    PEGP: "PEGP",
    PEHS: "PEHS",
    PESP: "PESP",
    PGAH: "PGAH",
    PGFH: "PGFH",
    PGPW: "PGPW",
    PPIH: "PPIH",
    PSAH: "PSAH",
    PSFH: "PSFH",
    PSMH: "PSMH",
    PSPW: "PSPW",
    VADD: "VADD",
    VCCA: "VCCA",
    VCCE: "VCCE",
    VDIP: "VDIP",
    VEAC: "VEAC",
    VEEC: "VEEC",
    VTLB: "VTLB",
    VTPD: "VTPD",
    AMR: "AMR" //need to double check
  },
  CLAIM_TYPE: {
    INPATIENT: "INPATIENT",
    OUTPATIENT_GP: "OUTPATIENT_GP",
    OUTPATIENT_SP: "OUTPATIENT_SP",
    OUTPATIENT_DENTAL: "OUTPATIENT_DENTAL",
    OUTPATIENT_HS_VAC: "OUTPATIENT_HS_VAC",
    MATERNITY: "MATERNITY",
    OUTPATIENT: "OUTPATIENT"
  },
  SORT: {
    ASC: "asc",
    DESC: "desc"
  },
  PAYMENT_METHOD: {
    CREDIT_CARD: "CREDIT_CARD",
    BANK_TRANSFER: "BANK_TRANSFER",
    CHEQUE: "CHEQUE"
  },
  PAYMENT_STATUS: {
    PENDING: "PENDING",
    DONE: "DONE"
  },
  FILE_TYPE: {
    ACCEPTANCE_FILE_TYPE: "ACCEPTANCE",
    ACRA_FILE_TYPE: "ACRA",
    MAS314_FILE_TYPE: "MAS314",
    NRIC_IND_FILE_TYPE: "NRIC_IND",
    CORP_BANK_DETAILS: "CORP_BANK_DETAILS",
    DECLARATION_CLAIM_ENTITLEMENT_FILE_TYPE: "DECLARATION",
    LETTER_FILE_TYPE: "LETTER",
    HR_DEC_FILE_TYPE: "HR_DEC",
    DEC_SPECIFIC_FILE_TYPE: "DEC_SPECIFIC",
    DEC_ENTITLEMENT_FILE_TYPE: "DEC_ENTITLEMENT",
    MEMBER_UPDATE_FILE_TYPE: "MEMBER_UPDATE",
    SIGNED_QUO_FILE_TYPE: "SIGNED_QUO",
    AUTHO_SIGNATORY_FILE_TYPE: "AUTHO_SIGNATORY",
    HEADCOUNT_FILE_TYPE: "HEADCOUNT"
  },
  FILE_EXTENSION: {
    XLSX: "xlsx",
    OTHERS: "others"
  },
  ERROR_PREFIX: {
    IBMCM: "IBM-CM:"
  },
  PAYMENT_CREDITCARDS: ["MASTERCARD"],
  COMPONENT: {
    FIND_DOC_GP_STAB: "FIND_DOC_GP_TAB",
    FIND_DOC_GP_TAB: "FIND_DOC_GP_TAB",
    MINI_LEFT_DRAWER_BENEFITS: "BENEFITS"
  },
  BUSINESS_TYPE: {
    NEW_BUSINESS: "New Business",
    CONVERSION_BUSINESS: "Conversion",
    NEW: "NEW",
    CONVERSION: "CONVERSION"
  },
  // HREASILY: {
  //   LEARN_MORE: "Learn More",
  //   FREE_TRIAL: "Free Trial",
  //   DOWNLOAD_BROCHURE: "Download Brochure"
  // },
  ESIGN: {
    COMPLETE: "COMPLETE",
    REJECTED: "REJECTED",
    RESUME_LATER: "RESUME_LATER"
  },
  // STASH: {
  //   CODE: "STASH"
  // },
  DATE_FILTER: {
    CONSULTATION_DATE: "CONSULTATION_DATE",
    PROCESSED_DATE: "PROCESSED_DATE"
  },
  PACKAGED_PLANS: ["PF3", "PTF", "PEP"],
  PRODUCT_CODE_PF3: "PF3",
  PLAN_CODE_GFWM: "GFWM",
  ROUTE: {
    CLIENT_AND_POLICY_LISTING: 0,
    INSURANCE_COVERAGE: 1,
    MY_EMPLOYEES: 2,
    CLAIM_EXPENSES: 3,
    BENEFITS: 4,
    DOCUMENTS: 5
  },
  DISPLAY_TYPE: {
    CUSTOMIZED_PLAN: "customised",
    PACKAGE_PLAN: "packaged"
  }
};

export default Object.freeze(ENUMS);
