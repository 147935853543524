export default [
  {
    value: "F",
    label: "Female",
    title: "Ms"
  },
  {
    value: "M",
    label: "Male",
    title: "Mr"
  }
];
