import { APIResource, APIError, APIResponse } from "../utils/APIResource";

export default class PolicyService {
  static getFCPolicyList() {
    return APIResource.get(`/v1/fc/policy`)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getFCPolicyDetails(policyNo) {
    return APIResource.get(`/v1/fc/policy/${policyNo}`)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static exportFCPolicyEmployee(policyNo) {
    return APIResource.get(`/v1/fc/policy/employee/export/${policyNo}`, { responseType: "blob" })
      .then(resp => {
        const response = new APIResponse(resp);
        return Promise.resolve({ ...response, filename: resp.headers.filename });
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getPolicyRelatedDocumentList(policyNo) {
    return APIResource.get(`/v1/fc/policy/${policyNo}/document`)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getPolicyRelatedDocument(policyNo, documentId) {
    return APIResource.get(`/v1/fc/policy/${policyNo}/document/${documentId}`)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getFCClaimExpenses(policyNo, filter) {
    let url = `/v1/fc/policy/claim/${policyNo}?`;
    for (var key in filter) {
      if (filter.hasOwnProperty(key)) {
        url += `&${key}=${filter[key]}`;
      }
    }
    return APIResource.get(url)
      .then(resp => {
        const response = new APIResponse(resp);
        return Promise.resolve({ ...response, filter });
      })
      .catch(error => {
        const { response } = error;
        return Promise.reject(
          error && error.message
            ? "System error, please try again."
            : (response && response.data) || "System error, please try again."
        );
      });
  }

  static exportFCClaimExpenses(policyNo, filter) {
    let url = `/v1/fc/policy/claim/export/${policyNo}?`;
    for (var key in filter) {
      if (filter.hasOwnProperty(key)) {
        url += `&${key}=${filter[key]}`;
      }
    }
    return APIResource.get(url, { responseType: "blob" })
      .then(resp => {
        const response = new APIResponse(resp);
        return Promise.resolve({ ...response, filename: resp.headers.filename });
      })
      .catch(error => {
        const { response } = error;
        return Promise.reject((response && response.data) || "System error, please try again.");
      });
  }
}
