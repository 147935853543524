import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import InfoButton from "../../../../../components/InfoButton";
import InfoPopover from "../../../../../components/InfoPopover";
import _ from "lodash";
import React, { Component } from "react";
import { injectIntl } from "react-intl";

class CategoryPackageTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      infoPopover: {
        open: false,
        element: null,
        data: ""
      }
    };
  }

  handleClickInfoButton = (data, id) => event => {
    let infoPopover = {
      ...this.state.infoPopover
    };
    infoPopover.open = !infoPopover.open;
    if (infoPopover.open) {
      infoPopover.element = this.refs[id];
      infoPopover.data = data;
    }
    this.setState({ infoPopover });
  };

  handleCloseDialog() {
    let infoPopover = {
      ...this.state.infoPopover,
      open: false
    };
    this.setState({ infoPopover });
  }

  renderInfoPopover = () => {
    const { infoPopover } = this.state;

    return (
      <InfoPopover
        open={infoPopover.open}
        data={infoPopover.data}
        element={infoPopover.element}
        handleClickInfoButton={this.handleClickInfoButton}
      />
    );
  };

  getPlansData() {
    const { data } = this.props;
    let allConfiguration = [];

    data.forEach(d => {
      allConfiguration.push(d.plans);
    });

    const flattenedPlans = _.flatten(allConfiguration);

    const sortedPlans = _.sortBy(flattenedPlans, plan => {
      if (plan.type === "core") {
        return 0;
      } else {
        return 1;
      }
    });

    return _.uniqBy(sortedPlans, "productCode");
  }

  getBorderStyling(idx, corePlansCount, suppPlansCount, classes) {
    let borderStyling = "";
    if (idx === corePlansCount - 1 && suppPlansCount > 0) {
      borderStyling = classes.rightBorder;
    }
    if (idx === corePlansCount + suppPlansCount - 1 && corePlansCount === 0) {
      borderStyling = classes.rightBorder;
    }
    return borderStyling;
  }

  render() {
    const { classes, data, config } = this.props;
    const sortedPlans = this.getPlansData();
    const corePlansCount = sortedPlans.filter(config => config.type === "core").length;
    const suppPlansCount = sortedPlans.filter(config => config.type === "supp").length;

    if (!data || !data.length) {
      return (
        <div className={classes.root}>
          <div className={classes.content}>
            <Typography color="inherit">No insurance coverage available.</Typography>
          </div>
        </div>
      );
    }
    return (
      <div className={classes.root}>
        <Paper className={classes.root}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.employeeTableTitle}>Company Name</TableCell>
                <TableCell className={classes.employeeTableTitle}>Employee Category</TableCell>
                <TableCell
                  className={
                    classes.employeeTableTitle +
                    " " +
                    (corePlansCount > 0 || suppPlansCount > 0 ? classes.rightBorder : "")
                  }
                >
                  No. Of Active Member(s)
                </TableCell>
                <TableCell
                  className={
                    corePlansCount === 0
                      ? classes.hidden
                      : classes.employeeTableTitle + " " + (suppPlansCount > 0 ? classes.rightBorder : "")
                  }
                  colSpan={corePlansCount}
                >
                  Core Plan(s)
                </TableCell>
                <TableCell
                  className={suppPlansCount == 0 ? classes.hidden : classes.employeeTableTitle}
                  colSpan={suppPlansCount}
                >
                  Supplementary Plan(s)
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell className={classes.planTableTitleInside + " " + classes.rightBorder} colSpan={3} />
              </TableRow>

              <TableRow>
                <TableCell className={classes.planTableTitleInside + " " + classes.rightBorder} colSpan={3} />

                {sortedPlans.map((config, idx) => {
                  if (config.type === "core") {
                    const borderStyling = this.getBorderStyling(idx, corePlansCount, suppPlansCount, classes);
                    return (
                      <TableCell key={idx} className={classes.planTableTitleInside + " " + borderStyling}>
                        <div>
                          {config.productCode}
                          <InfoButton
                            variant="outlined"
                            ref={`btnInfoBody${idx}`}
                            id={`btnInfoBody${idx}`}
                            data={config.description}
                            handleClickInfoButton={this.handleClickInfoButton}
                          />
                        </div>
                      </TableCell>
                    );
                  }
                })}

                {sortedPlans.map((config, idx) => {
                  if (config.type === "supp") {
                    return (
                      <TableCell key={idx} className={classes.planTableTitleInside}>
                        <div>
                          {config.productCode}
                          <InfoButton
                            variant="outlined"
                            ref={`btnInfoBody${idx}`}
                            id={`btnInfoBody${idx}`}
                            data={config.description}
                            handleClickInfoButton={this.handleClickInfoButton}
                          />
                        </div>
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map((d, id) => {
                let noOfDependents = d.noOfDependents || 0;
                let noOfEmployees = d.noOfEmployees || 0;
                let empRow = (
                  <TableRow key={id}>
                    <TableCell>{d.companyName}</TableCell>
                    <TableCell>{d.employeeCategory}</TableCell>
                    <TableCell className={classes.rightBorder}>{noOfEmployees}</TableCell>

                    {sortedPlans.map((config, idx) => {
                      let planConfiguration = d.plans.find(
                        _p => _p.productCode === config.productCode && _p.type === config.type
                      );
                      const borderStyling = this.getBorderStyling(idx, corePlansCount, suppPlansCount, classes);
                      if (config.type === "core") {
                        if (planConfiguration) {
                          const selectedTier = planConfiguration.tier ? `Plan ${planConfiguration.tier}` : "-";
                          return (
                            <TableCell key={idx} className={borderStyling}>
                              {selectedTier}
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell key={idx} className={borderStyling}>
                              -
                            </TableCell>
                          );
                        }
                      }

                      if (config.type === "supp") {
                        if (planConfiguration) {
                          const selectedTier = planConfiguration.tier ? `Plan ${planConfiguration.tier}` : "-";
                          return <TableCell key={idx}>{selectedTier}</TableCell>;
                        } else {
                          return <TableCell key={idx}>-</TableCell>;
                        }
                      }
                    })}
                  </TableRow>
                );

                let dependantRow = (
                  <TableRow
                    key={"dep" + id}
                    className={classNames(noOfDependents === 0 && classes.trCellDataDim, {
                      hidden: !config.enableDependent
                    })}
                  >
                    <TableCell>{d.companyName}</TableCell>
                    <TableCell>Dependants ({d.employeeCategory})</TableCell>
                    <TableCell className={classes.rightBorder}>{noOfDependents}</TableCell>
                    {sortedPlans.map((config, idx) => {
                      let planConfiguration = d.plans.find(
                        _p => _p.productCode === config.productCode && _p.type === config.type
                      );
                      const borderStyling = this.getBorderStyling(idx, corePlansCount, suppPlansCount, classes);
                      if (config.type === "core") {
                        if (planConfiguration) {
                          let selectedTier =
                            planConfiguration &&
                            planConfiguration.enableDependent &&
                            planConfiguration.tier &&
                            noOfDependents !== 0
                              ? `Plan ${planConfiguration.tier}`
                              : "-";
                          return (
                            <TableCell key={idx} className={borderStyling}>
                              {selectedTier}
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell key={idx} className={borderStyling}>
                              -
                            </TableCell>
                          );
                        }
                      }

                      if (config.type === "supp") {
                        if (planConfiguration) {
                          let selectedTier =
                            planConfiguration &&
                            planConfiguration.enableDependent &&
                            planConfiguration.tier &&
                            noOfDependents !== 0
                              ? `Plan ${planConfiguration.tier}`
                              : "-";
                          return <TableCell key={idx}>{selectedTier}</TableCell>;
                        } else {
                          return <TableCell key={idx}>-</TableCell>;
                        }
                      }
                    })}
                  </TableRow>
                );

                return [empRow, dependantRow];
              })}
            </TableBody>
          </Table>
          {this.renderInfoPopover()}
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto"
  },
  trCellDataDim: {
    opacity: 0.5
  },
  cellInfo: {
    minWidth: 65
  },
  employeeTableTitle: {
    fontSize: 15,
    fontWeight: "bold",
    color: "black",
    backgroundColor: "#F8F8F8",
    borderBottom: "none"
  },
  planTable: {
    backgroundColor: "#F8F8F8"
  },
  planTableTitleInside: {
    fontSize: 13,
    fontWeight: "bold",
    color: "black",
    backgroundColor: "#F8F8F8",
    borderBottom: "none",
    paddingRight: "0px",
    paddingTop: "0px",
    paddingBottom: "0px"
  },
  rightBorder: {
    borderRight: "1px solid rgba(224, 224, 224, 1)"
  },
  hidden: {
    display: "none"
  }
});

export default injectIntl(withStyles(styles, { withTheme: true })(CategoryPackageTable));
