import { APIResource, APIError, APIResponse } from "../../utils/APIResource";

export default class MyEmployeeService {
  static getMyEmployees(policyNumber, company_id, params) {
    let url = `/v1/policy/employees/${policyNumber}/${company_id}?`;
    for (var key in params) {
      if (params.hasOwnProperty(key)) {
        url += `&${key}=${params[key]}`;
      }
    }
    return APIResource.get(url)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getMyEmployeeDependants(emp_person_id, policy_no) {
    let url = `/v1/policy/${policy_no}/employee/${emp_person_id}/dependants`;
    return APIResource.get(url)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static emulateEmployee(policyNo, employeeId, companyId) {
    return APIResource.post("/v1/emulateEmployee", { policyNo, employeeId, companyId })
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static sendInvite(ids) {
    return APIResource.post(`/v1/person/invitation`, { ids })
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getCategories() {
    let id = "12345"; //TODO: Hardcoded for now
    return APIResource.get(`/v1/policy/categories/${id}`)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static addEmployee(policy_no, data) {
    return APIResource.post(`/v1/policy/member/${policy_no}`, data)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static updateEmployee(policy_no, data) {
    return APIResource.put(`/v1/policy/member/${policy_no}`, data)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }
}
