import React from "react";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const ClaimDateInput = ({ name, value, onChange, onError, maxDateMessage, className }) => {
  return (
    <DatePicker
      format="DD/MM/YYYY"
      name={name}
      value={value}
      onChange={newValue => {
        onChange(newValue);
      }}
      renderInput={params => (
        <TextField
          {...params}
          onBlur={() => onError("")}
          error={Boolean(onError)}
          helperText={onError}
          className={className}
        />
      )}
      autoComplete="[off]"
      animateYearScrolling
      clearable
      keyboard
      disableFuture
      maxDateMessage={maxDateMessage}
    />
  );
};

export default ClaimDateInput;
