import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { withStyles } from "@mui/styles";
import ConfirmMessageDialog from "./ConfirmMessageDialog";
import SimpleMessageDialog from "./SimpleMessageDialog";
import { appActions, productActions, quoteActions, companyActions, employeeActions } from "../actions";
import ENUMS from "../utils/Enums";
import Util from "../utils/Util";
import QuoteTypeDialogue from "./QuoteTypeDialogue";
import config from "../config/config";
import { ListItemButton } from "@mui/material";

class NewQuote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      errorOpen: false,
      openQuoteDialogue: false
    };
  }

  handleClickOpen = () => {
    const { form } = this.props;

    if (form["company-detail"] && form["company-detail"].syncErrors !== undefined) {
      this.setState({
        errorOpen: true,
        openQuoteDialogue: false
      });
    } else {
      const { name } = this.props.quote;
      let description, title;
      if (Util.isEmpty(name)) {
        description = "Since you haven't provided a company name the current quote will be discarded";
        title = "Discard current quote and create new";
      } else {
        description =
          "Are you sure you want to create a new quote?\n" +
          "Your current quote will be automatically listed under Saved Quotes.";
        title = "Create New Quote";
      }
      this.setState({ open: true, description, title });
    }
  };

  handleClose = value => this.setState({ open: false, openQuoteDialogue: false });

  handleErrorClose = () => this.setState({ errorOpen: false });

  handleResetAndRetriveFromCache = businessType => {
    this.props.resetQuote();
    this.props.setCompanyDetailsFromCache(this.props.company_cache);
    // reset first and to retrieve everything as accordingly
    this.setState({ business_type: businessType }, () => {
      this.props.setBusinessType(this.state.business_type);
      this.props.setExistingPolicyNo(this.props.company.existingPolicyNo);
      this.props.setName(this.props.company.details.name);
      this.props.setCompanyFormDetails({ key: "name", value: this.props.company.details.name });
    });
  };

  handleBusinessType = (existingPolicyNo, businessType) => {
    const pathName = "/auth/sp/currentQuote";
    const editingQuote = pathName === this.props.history.location.pathname;
    const { name, stages } = this.props.quote;
    const missingName = Util.isEmpty(name);
    const brand = this.props.user.auth.pcode;

    if (brand === ENUMS.P_CODE.PRU_AGENCY || brand === ENUMS.P_CODE.PFA || brand === ENUMS.P_CODE.BRO) {
      if (businessType === ENUMS.BUSINESS_TYPE.NEW) {
        if (Util.isEmpty(stages) && missingName) {
          if (!editingQuote) {
            this.props.resetQuote();
            this.props.history.push(pathName);
          } else {
            this.props.resetQuote();
          }
        } else {
          if (missingName) {
            this.props.resetQuote();
          } else if (this.props.quote.stages !== undefined) {
            this.props.newQuote(this.props.quote.stages).then(resp => {
              if (pathName !== this.props.history.location.pathname) this.props.history.push(pathName);
              this.props.resetQuote();
            });
          }
        }
      } else if (businessType === ENUMS.BUSINESS_TYPE.CONVERSION && !config.disable_conversion) {
        this.handleResetAndRetriveFromCache(businessType);
        // as it will be prefilled eitherways so missing name would never be true
        if (Util.isEmpty(stages) && !missingName) {
          if (!editingQuote) {
            this.handleResetAndRetriveFromCache(businessType);
            this.props.history.push(pathName);
          }
        } else {
          if (this.props.quote.stages !== undefined) {
            this.handleResetAndRetriveFromCache(businessType);
            if (pathName !== this.props.history.location.pathname) this.props.history.push(pathName);
          } else {
            if (pathName !== this.props.history.location.pathname) this.props.history.push(pathName);
          }
        }
      } else if (businessType === ENUMS.BUSINESS_TYPE.CONVERSION && config.disable_conversion) {
        if (Util.isEmpty(stages) && missingName) {
          if (!editingQuote) {
            this.props.resetQuote();
            this.props.history.push(pathName);
            this.props.setBusinessType(businessType);
            this.props.setExistingPolicyNo(existingPolicyNo);
          } else {
            this.props.resetQuote();
            this.props.setBusinessType(businessType);
            this.props.setExistingPolicyNo(existingPolicyNo);
          }
        } else {
          if (missingName) {
            this.props.resetQuote();
            this.props.setBusinessType(businessType);
            this.props.setExistingPolicyNo(existingPolicyNo);
          } else if (this.props.quote.stages !== undefined) {
            this.props.newQuote(this.props.quote.stages).then(resp => {
              if (pathName !== this.props.history.location.pathname) this.props.history.push(pathName);
            });
            this.props.setBusinessType(businessType);
            this.props.setExistingPolicyNo(existingPolicyNo);
          }
          this.props.resetQuote();
          this.props.setBusinessType(businessType);
          this.props.setExistingPolicyNo(existingPolicyNo);
        }
      }
    }

    this.handleClose();
    this.props.loadConfig();

    const dialogNbstpDisabled = Util.isDialogNbstpEnabled(config.disableBefore, config.dialogAutoDisable);

    if (
      config.firstPageDialogEnabled &&
      dialogNbstpDisabled &&
      (businessType === ENUMS.BUSINESS_TYPE.NEW || businessType === ENUMS.BUSINESS_TYPE.CONVERSION)
    ) {
      this.props.setFirstPageDialogVisibility(true);
    }
  };

  handleNewQuote = () => {
    const pathName = "/auth/sp/currentQuote";
    const editingQuote = pathName === this.props.history.location.pathname;
    const { name, stages } = this.props.quote;
    const missingName = Util.isEmpty(name);
    const brand = this.props.user.auth.pcode;
    // const brand = ENUMS.P_CODE.UOB_ASSISTED;

    if (brand === ENUMS.P_CODE.PRU_AGENCY) {
      this.setState({
        openQuoteDialogue: true,
        open: false
      });
    } else {
      this.setState({
        // openQuoteDialogue: true,
        open: false
      });
    }
    if (Util.isEmpty(stages) && missingName) {
      if (!editingQuote) {
        this.props.resetQuote();
        this.props.history.push(pathName);
      } else {
        this.props.resetQuote();
      }
    } else {
      if (missingName) {
        this.props.resetQuote();
      } else if (this.props.quote.stages !== undefined) {
        this.props.newQuote(this.props.quote.stages).then(resp => {
          if (pathName !== this.props.history.location.pathname) this.props.history.push(pathName);
        });
      }
      this.props.resetQuote();
    }

    this.props.loadConfig();
  };

  render() {
    const { classes, agentType } = this.props;
    // let manualAgentType = agentType;
    // manualAgentType = ENUMS.P_CODE.UOB_ASSISTED;
    return (
      <div>
        <ListItemButton onClick={this.handleClickOpen}>
          <ListItemIcon className={classes.icon} />
          <ListItemText primary="New Quote" classes={{ primary: classes.itemText }} />
        </ListItemButton>

        {agentType != ENUMS.P_CODE.PRU_AGENCY && (
          <ConfirmMessageDialog
            name="create-new-quote"
            title={this.state.title}
            description={this.state.description}
            open={this.state.open}
            closeHandler={this.handleClose}
            confirmHandler={this.handleNewQuote}
            confirmButtonText="Confirm"
            closeButtonText="Cancel"
          />
        )}
        <SimpleMessageDialog
          type="error"
          name="create-new-quote-error"
          title="Error Create New Quote"
          description="The current quote contains an error. Please fix the error before creating a new quote."
          closeButtonText="OK"
          closeHandler={this.handleErrorClose}
          open={this.state.errorOpen}
        />
        {agentType === ENUMS.P_CODE.PRU_AGENCY || agentType === ENUMS.P_CODE.PFA || agentType === ENUMS.P_CODE.BRO ? (
          <QuoteTypeDialogue
            name="create-new-quote"
            title={this.state.title}
            description={this.state.description}
            // open={this.state.openQuoteDialogue}
            open={this.state.open}
            closeHandler={this.handleClose}
            confirmHandler={this.handleBusinessType}
            confirmButtonText="Confirm"
            closeButtonText="Cancel"
            business_type={ENUMS.BUSINESS_TYPE.NEW}
            errorMessage={this.state.errorMessage}
          />
        ) : (
          <QuoteTypeDialogue
            name="create-new-quote"
            title={this.state.title}
            description={this.state.description}
            open={this.state.openQuoteDialogue}
            //open={this.state.open}
            closeHandler={this.handleClose}
            confirmHandler={this.handleBusinessType}
            confirmButtonText="Confirm"
            closeButtonText="Cancel"
            // pathHandler={this.handlePath}
            business_type={ENUMS.BUSINESS_TYPE.NEW}
            errorMessage={this.state.errorMessage}
          />
        )}
      </div>
    );
  }
}

const styles = theme => ({
  iconError: {
    color: `${theme.colors.error}`,
    fontSize: "2rem"
  },
  itemText: {
    fontSize: 22
  }
});

function mapStateToProps(state) {
  return {
    app: state.app,
    form: state.form,
    quote: state.quote,
    company: state.company,
    company_cache: state.company_cache,
    user: state.user,
    agentType: state.user.auth.pcode
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(appActions, dispatch),
    ...bindActionCreators(quoteActions, dispatch),
    ...bindActionCreators(productActions, dispatch),
    ...bindActionCreators(companyActions, dispatch),
    ...bindActionCreators(employeeActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(NewQuote));
