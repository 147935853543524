import _ from "lodash";

const productKeys = {
  DOCDOC: "docdoc",
  SMEHEALTH: "smehealth",
  WELLTEQ: "wellteq",
  VALIDUS: "validus",
  // HREASILY: "hreasily",
  // STASH: "stash",
  MORE_SERVICES: "more_services"
};

const { DOCDOC, WELLTEQ, VALIDUS, HREASILY, MORE_SERVICES, STASH } = productKeys;

const availableProducts = {
  // Agent Distribution Channel
  // PRU_AGENCY: [DOCDOC, WELLTEQ, VALIDUS],
  // AD: [DOCDOC, WELLTEQ, VALIDUS],
  PRU_AGENCY: [],
  AD: [],

  // Partner Distribution Channel
  PD: [],
  //UOB Assisted (include UOB team 1)
  UOB_ASSISTED: [],
  UOB_TEAM_1: [],
  //UOB Direct (include UOB team 2)
  UOB_DIRECTED: [],
  UOB_TEAM_2: [],
  SCB_ASSISTED: []

  //Freya(PFA)
  // PFA: [HREASILY, STASH]

  // PD: [DOCDOC, WELLTEQ],
  // UOB_ASSISTED: [],
  // UOB_TEAM_1: [DOCDOC, WELLTEQ],
  // UOB_DIRECTED: [DOCDOC, WELLTEQ],
  // UOB_TEAM_2: [DOCDOC, WELLTEQ]
};

const getProductInfo = agentType => {
  const allProducts = {
    EMPLOYEE: [
      {
        id: DOCDOC,
        className: "imgHorizontal",
        image: "medImg",
        icons: { active: "medIconActive", inactive: "medIcon" },
        header: "Easy Access to Medical Specialists",
        iconHeader: "Easy Access to Medical Specialists",
        youTubeID: "6ktJS-A9F9M",
        desc:
          "Empower your employees by enabling them to find the right doctor for their unique medical condition or procedure.\n\n" +
          "DocDoc’s doctor discovery system gives your employees access to a doctor who will provide them with a personalised set of recommended specialists based on their preferences and needs.\n\n" +
          "With the largest provider network in Asia, DocDoc ensures each of your employees will receive efficiently priced treatments from physicians that are well qualified for the specific condition or procedure – resulting in increased productivity.",
        logo: [require("../img/docdoc-logo.png")],
        isVideo: true
      },
      {
        id: WELLTEQ,
        className: "imgVertical",
        imageHeight: index => (index === 1 ? "25px" : "50px"),
        image: "healthImg",
        icons: { active: "healthIconActive", inactive: "healthIcon" },
        iconHeader: "Employee Digital Wellness Platform",
        header: "Access to wellness benefits and rewards",
        youTubeID: "6ktJS-A9F9M",
        desc:
          "Employee Wellness is key to productivity. WellteQ’s digital health solutions provides employees access to a suite of wellness & engagement programmes; helping employees improve their health and well-being via a gamified platform offering powerful activity challenges, mental and financial programmes, rewards and virtual coaching. It’s a great way to motivate employees, challenging them to stay fit.\n\n" +
          "In addition, via real time engagement and HR risk analytics, WellteQ enables you to understand your organisation’s health risks, culture and engagement statistics, improving your company’s productivity and efficiency.",
        logo: [require("../img/wellteq-logo.png")],
        isVideo: false
      },
      {
        id: MORE_SERVICES,
        header: "Coming Soon",
        desc:
          "As part of our continuous efforts to serve you better, new value added services will be coming your way. Please watch this space for exciting services."
      }
    ],
    BUSINESS: [
      {
        id: VALIDUS,
        className: "imgHorizontal",
        image: "loanImg",
        icons: { active: "loanIconActive", inactive: "loanIcon" },
        iconHeader: "Smart Financing",
        header: "Financing made easy",
        youTubeID: "6ktJS-A9F9M",
        desc:
          "Having the right financing in place goes a long way in helping to manage cash flow, purchase inventory, or to simply fund your business growth.\n\n" +
          "Validus’ online peer-to-peer lending platform gives you access to invoice financing in a fast, affordable and reliable way, making it easier and quicker for businesses to obtain low-interest, zero collateral financing at a lower cost.\n\n" +
          "Access up to $500,000, invoice, PO and working capital financing within 48 hours of approval.",
        logo: [require("../img/validus-logo.png")],
        isVideo: false
      }
    ]
  };

  const available = availableProducts[agentType];

  const products = _.reduce(
    allProducts,
    (coll, v, k) => {
      const categoryProducts = _.filter(v, ({ id }) => _.includes(available, id));
      return _.isEmpty(categoryProducts) ? coll : _.set(coll, k, categoryProducts);
    },
    {}
  );

  return {
    products,
    productCategories: _.keys(products),
    categoryData: {
      EMPLOYEE: { menuTitle: "For Employees" },
      BUSINESS: { menuTitle: "For your Business" }
    }
  };
};

export default { getProductInfo, productKeys, availableProducts };
