import { useDispatch, useSelector } from "react-redux";
import { otpActions } from "../../../../actions";

let hasSentOtp = false;

const useOtp = () => {
  const dispatch = useDispatch();

  const refreshNotificationMethods = () => {
    dispatch(otpActions.getNotificationMethods());
  };

  const setOtpSelectedMethod = otpMethod => {
    dispatch(otpActions.setOtpSelectedMethod(otpMethod));
  };

  const sendOtp = (otpMethod, txId) => {
    console.log("txId", txId);
    const regenerateOtp = hasSentOtp;

    dispatch(otpActions.sendOtp(otpMethod, txId, regenerateOtp)).then(response => {
      if (response?.success) {
        hasSentOtp = true;
      }
    });
  };

  const otpState = useSelector(state => state.otp);

  const selectedMethod = otpState?.selectedMethod;

  const previousOtpMethod = otpState?.previousOtpMethod;

  const anotherWayHandler = () => {
    console.log("invokeeedd");
    setPreviousOtpMethodHandler(selectedMethod);
    setOtpSelectedMethod(null);
  };

  const setPreviousOtpMethodHandler = otpMethod => {
    dispatch(otpActions.setPreviousOtpMethod(otpMethod));
  };

  const backHandlerOtpSelector = () => {
    setOtpSelectedMethod(previousOtpMethod);
  };

  return {
    otpState,
    selectedMethod,
    previousOtpMethod,
    setOtpSelectedMethod,
    setPreviousOtpMethodHandler,
    refreshNotificationMethods,
    anotherWayHandler,
    backHandlerOtpSelector,
    sendOtp
  };
};

export default useOtp;
