import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { bindActionCreators } from "redux";

import { withStyles } from "@mui/styles";
import classNames from "classnames";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import SnackBar from "../../../../../components/SnackBar";
import Grid from "@mui/material/Grid";
import { quoteActions, productActions } from "../../../../../actions";
import PremiumAmount from "../components/PremiumAmount";
import ENUMS from "../../../../../utils/Enums";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import ProductEngineService from "../../../../../services/product.engine.service";
import Util from "../../../../../utils/Util";

class ActionToolbarStep0 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackBarErrorShow: false,
      open: false,
      isOldQuoteWarningOpen: false,
      validation: null
    };
  }

  componentWillMount() {
    const { quote } = this.props;
    if (!Util.isProductPF3(quote.product_code)) {
      this.setState({ isOldQuoteWarningOpen: true });
    }
  }

  handleClickSave = () => {
    this.props.save(ENUMS.STAGES.ESTIMATE).then(response => {
      this.setState({ snackBarErrorShow: true });
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  renderError = validation => {
    const { classes } = this.props;
    if (!validation || (validation && validation.valid)) return null;
    return (
      <Modal open={this.state.open} onClose={this.handleClose}>
        <Grid container direction="row">
          <Grid item xs={1} />
          <Grid item xs={10}>
            <div className={classes.popup} style={{ padding: "10px" }}>
              <div className={classes.containerPopup}>
                <span style={{ fontWeight: "bold" }}>Please resolve the error(s) or click Next to proceed.</span>
                <br />
                <br />
                <Button variant="contained" color="inherit" className={classes.button} onClick={this.handleClose}>
                  Close
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.props.handlerNextStep(1)}
                >
                  {" "}
                  Next{" "}
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={1} style={{ paddingTop: "2%" }}>
            <Button variant="fab" mini onClick={this.handleClose}>
              <Icon className={classes.leftIcon}>close</Icon>
            </Button>
          </Grid>
        </Grid>
      </Modal>
    );
  };

  checkIfAllPlanValid = handlerNextStep => {
    const { quote } = this.props;
    const { data } = quote;
    const { categories, estimates } = data;

    let _categories = [];
    categories.forEach((category, idx) => {
      //Populate categories values
      _categories.push({
        name: category.name,
        plansConfiguration: category.plansConfiguration.filter(c => {
          return !Util.isEmpty(c.tier);
        })
      });
    });

    let _estimates = estimates.map((e, idc) => {
      return {
        averageAge: Util.parseInteger(e.averageAge),
        name: e.name,
        noOfEmployee: e.noOfEmployee ? Util.parseInteger(e.noOfEmployee) : 0
      };
    });

    let validates = ProductEngineService.validateCategoryAndEstimate(
      this.props.document.hasSignedProposal,
      _categories,
      _estimates
    );

    const reg = new RegExp(Util.nbstpFieldRegexCompanyname);
    const regexResult = reg.test(quote.name);

    if (!regexResult && !this.props.document.hasSignedProposal) {
      this.setState({ validation: "invalid name", open: true });
    } else {
      Promise.resolve(validates).then(
        resolve => {
          this.setState({ validation: null });
          handlerNextStep(1);
        },
        err => {
          err.error = Util.mapPlansCodeLabel(err.error);
          this.setState({ validation: err, open: true });
        }
      );
    }
  };

  render() {
    const { classes, handlerNextStep, intl, document, quote } = this.props;
    const { activeStep } = quote;
    let readonly = this.props.quote.readOnly;

    return (
      <Toolbar className={classes.container}>
        {this.renderError(this.state.validation)}
        <div className={classes.leftActions}>
          <Button
            variant="raised"
            color="inherit"
            disabled={readonly || document.hasSignedProposal}
            onClick={this.handleClickSave}
            className={classNames(classes.button, classes.buttonSave)}
          >
            {intl.formatMessage({ id: "actiontoolbar.button.save" })}
          </Button>
        </div>
        <div style={{ flexGrow: 1 }} />
        <div className={classes.actions}>
          <PremiumAmount showEstimate={true} />
          <Button
            variant="raised"
            color="primary"
            className={classes.button}
            onClick={() => this.checkIfAllPlanValid(handlerNextStep(1))}
          >
            {intl.formatMessage({ id: "actiontoolbar.button.continue" })}
          </Button>
        </div>
        <SnackBar
          type="success"
          open={this.state.snackBarErrorShow}
          onClose={() => this.setState({ snackBarErrorShow: false })}
          message="The quote has been saved"
        />
      </Toolbar>
    );
  }
}

const styles = theme => ({
  container: {
    justifyContent: "flex-start",
    borderTop: "solid 1px #ccc",
    ...theme.extended.minHeight(55)
  },
  button: {
    marginLeft: 20,
    minWidth: 150,
    fontWeight: 500
  },
  buttonSave: {
    backgroundColor: "#fff"
  },
  popupTitle: {
    display: "block",
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: "400"
  },
  popup: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    alignSelf: "center",
    padding: "12",
    backgroundColor: theme.background.color,
    // backgroundColor: theme.palette.default.main,
    color: theme.palette.default.fontColor,
    p: 4,
    overflowY: "auto",
    textAlign: "center",
    borderRadius: "3px",
    boxShadow: "4px 8px 12px 0 rgba(0,0,0,0.4)",
    animation: "show-modal .7s ease-in-out"
  },
  containerPopup: {
    marginLeft: theme.spacing.unit * 5.0,
    marginRight: theme.spacing.unit * 5.0,
    paddingTop: theme.spacing.unit * 5.0,
    paddingBottom: theme.spacing.unit * 5.0
    // display: "inline-block"
  },
  actions: {
    display: "flex",
    flexDirection: "row"
  },
  leftActions: {
    display: "flex",
    flexDirection: "row",
    marginLeft: -20
  }
});

function mapStateToProps(state) {
  return {
    quote: state.quote,
    document: state.document
  };
}

function mapDispatchToProps(dispatch) {
  return {
    save: (...payload) => dispatch(quoteActions.save(...payload)),
    ...bindActionCreators(productActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(ActionToolbarStep0)));
