import Enums from "../../../../utils/Enums";

// GHS ----------------------
let GHSBenefits = {
  headers: [
    {
      title: ``,
      mandatory: true,
      attributes: {
        style: {
          width: "5%"
        }
      }
    },
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "20%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    },
    {
      title: `Plan 6`,
      tier: 6
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `1`
        },
        {
          data: `<strong> Daily Room and Board Benefit</strong>`,
          info: `<em>(Per day, up to 120 days per disability)</em>`
        },
        {
          data: `1-Bed Ward Private`
        },
        {
          data: `2-Bed Ward Private`
        },
        {
          data: `4-Bed Ward Private`
        },
        {
          data: `1-Bed Ward Government Restructured`
        },
        {
          data: `4-Bed Ward Government Restructured`
        },
        {
          data: `1-Bed Ward Private `
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `2`
        },
        {
          data: `<strong>Intensive Care Unit (ICU)</strong>`,
          info: `<em>(Max. per day, up to 30 days per disability)</em>`
        },
        {
          data: `3 x 1-Bedded`
        },
        {
          data: `3 x 2-Bedded	`
        },
        {
          data: `3 x 4-Bedded`
        },
        {
          data: `S$20,000`,
          info: `per disability limit for items 2 to 8`,
          attributes: {
            rowspan: 7
          }
        },
        {
          data: `S$15,000`,
          info: `per disability limit for items 2 to 8`,
          attributes: {
            rowspan: 7
          }
        },
        {
          data: `As Charged up to S$250,000 per policy year`,
          attributes: {
            rowspan: 8
          }
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `3`
        },
        {
          data: `<strong>High Dependency Ward (HDW)</strong>`,
          info: `<em>(Max. per day, up to 30 days per disability)</em>`
        },
        {
          data: `2 x 1-Bedded`
        },
        {
          data: `2 x 2-Bedded	`
        },
        {
          data: `2 x 4-Bedded`,
          attributes: {
            notLast: true
          }
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `4`
        },
        {
          data: `<strong>Other Hospital Services Benefits</strong>`,
          info: `<em>(Max. per disability)</em>`
        },
        {
          data: `S$25,000`,
          info: `per disability<br> limit for items<br> 4 to 8`,
          attributes: {
            rowspan: "5"
          }
        },
        {
          data: `S$20,000`,
          info: `per disability<br> limit for items<br> 4 to 8`,
          attributes: {
            rowspan: "5"
          }
        },
        {
          data: `S$15,000`,
          info: `per disability<br> limit for items<br> 4 to 8`,
          attributes: {
            rowspan: "5"
          }
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `5`
        },
        {
          data: `<strong>Surgical Fees (< S$ 1,500 will not be subject to Surgical Schedule)</strong>`,
          info: `<em>(subject to surgical schedule)</em><br>
          i. Waiver of surgical schedule if insured member is admitted to government restructured hospital.<br>
          ii. Surgical fee more than S$1,500 is subject to surgical schedule if insured member is admitted to a private hospital.`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `6`
        },
        {
          data: `<strong>In-Hospital Doctor's Consultation Benefit</strong>`,
          info: `<em>(Max. 120 days)</em>`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `7`
        },
        {
          data: `<strong>Pre (90 days) & Post (90 days) Hospitalisation/ Surgery,  Specialist Consultation, Diagnostic X-Ray and Lab Test, Traditional Chinese Medicine</strong>`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `8`
        },
        {
          data: `<strong>Emergency Accidental Outpatient<br> Treatment Benefit</strong>`,
          info: `<em>(Including Accidental Dental Treatment)</em>`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `9`
        },
        {
          data: `<strong>Miscarriage Benefit</strong>`
        },
        {
          data: `Covered under benefits (items 1 to 8) , as per the respective benefit limits`
        },
        {
          data: `Covered under benefits (items 1 to 8) , as per the respective benefit limits`
        },
        {
          data: `Covered under benefits (items 1 to 8) , as per the respective benefit limits`
        },
        {
          data: `Covered under benefits (items 1 to 8) , as per the respective benefit limits`
        },
        {
          data: `Covered under benefits (items 1 to 8) , as per the respective benefit limits`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `10`
        },
        {
          data: `<strong>Outpatient Cancer Treatment</strong>`,
          info: `<em>(Max. per policy year)</em>`
        },
        {
          data: `S$ 12,000`
        },
        {
          data: `S$ 12,000`
        },
        {
          data: `S$ 12,000`
        },
        {
          data: `S$ 6,000`
        },
        {
          data: `S$ 6,000`
        },
        {
          data: `S$25,000 <br>
          per policy year`,
          info: `(<em>part of policy year limit</em>)`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `11`
        },
        {
          data: `<strong>Outpatient Kidney Dialysis</strong>`,
          info: `<em>(Max. per policy year)</em>`
        },
        {
          data: `S$ 12,000`
        },
        {
          data: `S$ 12,000`
        },
        {
          data: `S$ 12,000`
        },
        {
          data: `S$ 6,000`
        },
        {
          data: `S$ 6,000`
        },
        {
          data: `S$25,000 <br>
          per policy year`,
          info: `(<em>part of policy year limit</em>)`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `12`
        },
        {
          data: `<strong>Overseas Hospitalisation for Accident Benefit</strong>`
        },
        {
          data: `150% of GHS benefit (<em>for item 1 to 7</em>)`
        },
        {
          data: `150% of GHS benefit (<em>for item 1 to 7</em>)`
        },
        {
          data: `150% of GHS benefit (<em>for item 1 to 7</em>)`
        },
        {
          data: `150% of GHS benefit (<em>for item 1 to 7</em>)`
        },
        {
          data: `150% of GHS benefit (<em>for item 1 to 7</em>)`
        },
        {
          data: `150% of GHS benefit (<em>for item 1 to 7</em>)`
        }
      ]
    },
    {
      id: 13,
      columns: [
        {
          data: `13`
        },
        {
          data: `<strong>Rehabilitation Benefit</strong>`,
          info: `<em>(Max. per disability, up to 31 days)</em>`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$10,000 <br> per policy year `,
          info: `(part of policy year limit)`
        }
      ]
    },
    {
      id: 14,
      columns: [
        {
          data: `14`
        },
        {
          data: `<strong>Hospital Cash Benefit</strong>`,
          info: `<em>(Max. per day, up to 90 days per disability)</em>`
        },
        {
          data: ``
        },
        {
          data: ``
        },
        {
          data: ``
        },
        {
          data: ``
        },
        {
          data: ``
        },
        {
          data: ``
        }
      ]
    },
    {
      id: "",
      columns: [
        {
          data: ``
        },
        {
          data: `<strong>- Singapore Government Restructured B1 Ward</strong>`
        },
        {
          data: `S$ 50`
        },
        {
          data: `S$ 40`
        },
        {
          data: `Nil`
        },
        {
          data: `Nil`
        },
        {
          data: `Nil`
        },
        {
          data: `S$ 80`
        }
      ]
    },
    {
      id: "",
      columns: [
        {
          data: ``
        },
        {
          data: `<strong>- Singapore Government Restructured B2 Ward</strong>`
        },
        {
          data: `S$ 100`
        },
        {
          data: `S$ 80`
        },
        {
          data: `S$ 40`
        },
        {
          data: `Nil`
        },
        {
          data: `Nil`
        },
        {
          data: `S$ 150`
        }
      ]
    },
    {
      id: "",
      columns: [
        {
          data: ``
        },
        {
          data: `<strong>- Singapore Government Restructured C Ward</strong>`
        },
        {
          data: `S$ 150`
        },
        {
          data: `S$ 120`
        },
        {
          data: `S$ 80`
        },
        {
          data: `Nil`
        },
        {
          data: `Nil`
        },
        {
          data: `S$ 200`
        }
      ]
    },
    {
      id: 18,
      columns: [
        {
          data: `15`
        },
        {
          data: `<strong>In-Hospital Psychiatric Treatment</strong>`,
          info: `<em>(Max. per policy year)</em>`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 2,500`
        },
        {
          data: `S$ 2,500`
        },
        {
          data: `S$10,000 <br> per policy year `,
          info: `(part of policy year limit)`
        }
      ]
    },
    {
      id: 19,
      columns: [
        {
          data: `16`
        },
        {
          data: `<strong>Death Benefit</strong>`,
          info: `<em>(Double Death Benefits payable in the event of death due to accidental causes)</em>`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        }
      ]
    },
    {
      id: 20,
      columns: [
        {
          data: `17`
        },
        {
          data: `<strong>Pro-ration factor for:<br> 
                  Plan 2 &amp; 3<br>
                  Plan 4 &amp; 5</strong>`,
          info: `<strong>Plan 2 &amp; 3</strong><br> <em>Applicable to items 4 – 7</em></p>
              <p><strong>Plan 4 &amp; 5</strong><br> <em>Applicable to items 2 – 7</em></p>`
        },
        {
          data: `Nil`
        },
        {
          data: `75%`,
          info: `applies if Insured Member stays in 1 Bedded ward (Private or Government Restructured)`
        },
        {
          data: `75%`,
          info: `applies if Insured Member stays in 2 Bedded or higher ward (Private or Government Restructured)`
        },
        {
          data: `75%`,
          info: `applies if Insured Member stays in 1 Bedded ward (Private)`
        },
        {
          data: `75% `,
          info: `applies if Insured Member stays in 4 Bedded or higher ward (Private) or 2 Bedded or higher ward (Government Restructured)`
        },
        {
          data: `Nil`
        }
      ]
    },
    {
      id: 21,
      columns: [
        {
          data: `18`
        },
        {
          data: `<strong>Co-insurance</strong>`
        },
        {
          data: `Not Applicable`
        },
        {
          data: `Not Applicable`
        },
        {
          data: `Not Applicable`
        },
        {
          data: `Not Applicable`
        },
        {
          data: `Not Applicable`
        },
        {
          data: `20%`,
          info: `(Private Hospital)`
        }
      ]
    }
  ],
  footnotes: []
};

let GHSPremiumEmployee = {
  title: `<strong>* For Employee Only <i>(inclusive of 8% GST)</i></strong> `,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    },
    {
      title: `Plan 6`,
      tier: 6
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 19</strong>`
        },
        {
          data: `S$ 391.25`
        },
        {
          data: `S$ 267.11`
        },
        {
          data: `S$ 215.62`
        },
        {
          data: `S$ 217.48`
        },
        {
          data: `S$ 159.95`
        },
        {
          data: `S$ 792.18`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>20 - 24</strong>`
        },
        {
          data: `S$ 391.25`
        },
        {
          data: `S$ 267.11`
        },
        {
          data: `S$ 215.62`
        },
        {
          data: `S$ 221.52`
        },
        {
          data: `S$ 165.00`
        },
        {
          data: `S$ 792.18`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>25 - 29</strong>`
        },
        {
          data: `S$ 391.25`
        },
        {
          data: `S$ 272.15`
        },
        {
          data: `S$ 218.66`
        },
        {
          data: `S$ 228.58`
        },
        {
          data: `S$ 170.05`
        },
        {
          data: `S$ 792.18`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>30 - 34</strong>`
        },
        {
          data: `S$ 421.53`
        },
        {
          data: `S$ 304.45`
        },
        {
          data: `S$ 245.91`
        },
        {
          data: `S$ 241.70`
        },
        {
          data: `S$ 180.13`
        },
        {
          data: `S$ 1,022.64`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>35 - 39</strong>`
        },
        {
          data: `S$ 421.53`
        },
        {
          data: `S$ 311.52`
        },
        {
          data: `S$ 250.95`
        },
        {
          data: `S$ 274.01`
        },
        {
          data: `S$ 203.35`
        },
        {
          data: `S$ 1,022.64`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>40 - 44</strong>`
        },
        {
          data: `S$ 452.82`
        },
        {
          data: `S$ 376.11`
        },
        {
          data: `S$ 298.39`
        },
        {
          data: `S$ 347.70`
        },
        {
          data: `S$ 254.83`
        },
        {
          data: `S$ 1,145.49`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>45 - 49</strong>`
        },
        {
          data: `S$ 648.64`
        },
        {
          data: `S$ 484.11`
        },
        {
          data: `S$ 401.35`
        },
        {
          data: `S$ 418.35`
        },
        {
          data: `S$ 297.23`
        },
        {
          data: `S$ 1,429.27`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>50 - 54</strong>`
        },
        {
          data: `S$ 782.88`
        },
        {
          data: `S$ 619.37`
        },
        {
          data: `S$ 524.49`
        },
        {
          data: `S$ 559.66`
        },
        {
          data: `S$ 407.25`
        },
        {
          data: `S$ 1,733.05`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>55 - 59</strong>`
        },
        {
          data: `S$ 957.50`
        },
        {
          data: `S$ 850.51`
        },
        {
          data: `S$ 678.92`
        },
        {
          data: `S$ 724.17`
        },
        {
          data: `S$ 524.33`
        },
        {
          data: `S$ 2,240.62`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>60 - 64</strong>`
        },
        {
          data: `S$ 1,349.13`
        },
        {
          data: `S$ 1,061.46`
        },
        {
          data: `S$ 937.31`
        },
        {
          data: `S$ 952.29`
        },
        {
          data: `S$ 687.84`
        },
        {
          data: `S$ 3,051.03`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>65 - 69</strong>`
        },
        {
          data: `S$ 1,846.74`
        },
        {
          data: `S$ 1,503.55`
        },
        {
          data: `S$ 1,239.11`
        },
        {
          data: `S$ 1,242.98`
        },
        {
          data: `S$ 895.76`
        },
        {
          data: `S$ 4,571.86`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only.</em>`
        },
        {
          data: `S$ 3,441.50`
        },
        {
          data: `S$ 2,522.99`
        },
        {
          data: `S$ 2,033.46`
        },
        {
          data: `S$ 1,692.13`
        },
        {
          data: `S$ 1,216.74`
        },
        {
          data: `S$ 5,586.05`
        }
      ]
    }
  ],
  footnotes: []
};

let GHSPremiumSpouseOrChild = {
  title: `<br><strong>* For Employee and Spouse or Children Coverage Only <i>(inclusive of 8% GST)</i></strong>`,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    },
    {
      title: `Plan 6`,
      tier: 6
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 19</strong>`
        },
        {
          data: `S$ 880.31`
        },
        {
          data: `S$ 601.00`
        },
        {
          data: `S$ 485.15`
        },
        {
          data: `S$ 489.33`
        },
        {
          data: `S$ 359.89`
        },
        {
          data: `S$ 1,782.41`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>20 - 24</strong>`
        },
        {
          data: `S$ 880.31`
        },
        {
          data: `S$ 601.00`
        },
        {
          data: `S$ 485.15`
        },
        {
          data: `S$ 498.42`
        },
        {
          data: `S$ 371.25`
        },
        {
          data: `S$ 1,782.41`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>25 - 29</strong>`
        },
        {
          data: `S$ 880.31`
        },
        {
          data: `S$ 612.34`
        },
        {
          data: `S$ 491.99`
        },
        {
          data: `S$ 514.31`
        },
        {
          data: `S$ 382.61`
        },
        {
          data: `S$ 1,782.41`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>30 - 34</strong>`
        },
        {
          data: `S$ 948.44`
        },
        {
          data: `S$ 685.01`
        },
        {
          data: `S$ 553.30`
        },
        {
          data: `S$ 543.83`
        },
        {
          data: `S$ 405.29`
        },
        {
          data: `S$ 2,300.94`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>35 - 39</strong>`
        },
        {
          data: `S$ 948.44`
        },
        {
          data: `S$ 700.92`
        },
        {
          data: `S$ 564.64`
        },
        {
          data: `S$ 616.52`
        },
        {
          data: `S$ 457.54`
        },
        {
          data: `S$ 2,300.94`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>40 - 44</strong>`
        },
        {
          data: `S$ 1,018.85`
        },
        {
          data: `S$ 846.25`
        },
        {
          data: `S$ 671.38`
        },
        {
          data: `S$ 782.33`
        },
        {
          data: `S$ 573.37`
        },
        {
          data: `S$ 2,577.35`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>45 - 49</strong>`
        },
        {
          data: `S$ 1,459.44`
        },
        {
          data: `S$ 1,089.25`
        },
        {
          data: `S$ 903.04`
        },
        {
          data: `S$ 941.29`
        },
        {
          data: `S$ 668.77`
        },
        {
          data: `S$ 3,215.86`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>50 - 54</strong>`
        },
        {
          data: `S$ 1,761.48`
        },
        {
          data: `S$ 1,393.58`
        },
        {
          data: `S$ 1,180.10`
        },
        {
          data: `S$ 1,259.24`
        },
        {
          data: `S$ 916.31`
        },
        {
          data: `S$ 3,899.36`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>55 - 59</strong>`
        },
        {
          data: `S$ 2,154.38`
        },
        {
          data: `S$ 1,913.65`
        },
        {
          data: `S$ 1,527.57`
        },
        {
          data: `S$ 1,629.38`
        },
        {
          data: `S$ 1,179.74`
        },
        {
          data: `S$ 5,041.40`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>60 - 64</strong>`
        },
        {
          data: `S$ 3,035.54`
        },
        {
          data: `S$ 2,388.29`
        },
        {
          data: `S$ 2,108.95`
        },
        {
          data: `S$ 2,142.65`
        },
        {
          data: `S$ 1,547.64`
        },
        {
          data: `S$ 6,864.82`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>65 - 69</strong>`
        },
        {
          data: `S$ 4,155.17`
        },
        {
          data: `S$ 3,382.99`
        },
        {
          data: `S$ 2,788.00`
        },
        {
          data: `S$ 2,796.71`
        },
        {
          data: `S$ 2,015.46`
        },
        {
          data: `S$ 10,286.69`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 7,743.38`
        },
        {
          data: `S$ 5,676.73`
        },
        {
          data: `S$ 4,575.29`
        },
        {
          data: `S$ 3,807.29`
        },
        {
          data: `S$ 2,737.67`
        },
        {
          data: `S$ 12,568.61`
        }
      ]
    }
  ],
  footnotes: []
};

let GHSPremiumSpouseAndChild = {
  title: `<br><strong>* For Employee and Spouse and Children Coverage Only <i>(inclusive of 8% GST)</i></strong>`,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    },
    {
      title: `Plan 6`,
      tier: 6
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 19</strong>`
        },
        {
          data: `S$ 1,369.38`
        },
        {
          data: `S$ 934.89`
        },
        {
          data: `S$ 754.67`
        },
        {
          data: `S$ 761.18`
        },
        {
          data: `S$ 559.83`
        },
        {
          data: `S$ 2,772.63`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>20 - 24</strong>`
        },
        {
          data: `S$ 1,369.38`
        },
        {
          data: `S$ 934.89`
        },
        {
          data: `S$ 754.67`
        },
        {
          data: `S$ 775.32`
        },
        {
          data: `S$ 577.50`
        },
        {
          data: `S$ 2,772.63`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>25 - 29</strong>`
        },
        {
          data: `S$ 1,369.38`
        },
        {
          data: `S$ 952.53`
        },
        {
          data: `S$ 765.31`
        },
        {
          data: `S$ 800.03`
        },
        {
          data: `S$ 595.18`
        },
        {
          data: `S$ 2,772.63`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>30 - 34</strong>`
        },
        {
          data: `S$ 1,475.36`
        },
        {
          data: `S$ 1,065.58`
        },
        {
          data: `S$ 860.69`
        },
        {
          data: `S$ 845.95`
        },
        {
          data: `S$ 630.46`
        },
        {
          data: `S$ 3,579.24`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>35 - 39</strong>`
        },
        {
          data: `S$ 1,475.36`
        },
        {
          data: `S$ 1,090.32`
        },
        {
          data: `S$ 878.33`
        },
        {
          data: `S$ 959.04`
        },
        {
          data: `S$ 711.73`
        },
        {
          data: `S$ 3,579.24`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>40 - 44</strong>`
        },
        {
          data: `S$ 1,584.87`
        },
        {
          data: `S$ 1,316.39`
        },
        {
          data: `S$ 1,044.37`
        },
        {
          data: `S$ 1,216.95`
        },
        {
          data: `S$ 891.91`
        },
        {
          data: `S$ 4,009.22`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>45 - 49</strong>`
        },
        {
          data: `S$ 2,270.24`
        },
        {
          data: `S$ 1,694.39`
        },
        {
          data: `S$ 1,404.73`
        },
        {
          data: `S$ 1,464.23`
        },
        {
          data: `S$ 1,040.31`
        },
        {
          data: `S$ 5,002.45`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>50 - 54</strong>`
        },
        {
          data: `S$ 2,740.08`
        },
        {
          data: `S$ 2,167.80`
        },
        {
          data: `S$ 1,835.72`
        },
        {
          data: `S$ 1,958.81`
        },
        {
          data: `S$ 1,425.38`
        },
        {
          data: `S$ 6,065.68`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>55 - 59</strong>`
        },
        {
          data: `S$ 3,351.25`
        },
        {
          data: `S$ 2,976.79`
        },
        {
          data: `S$ 2,376.22`
        },
        {
          data: `S$ 2,534.60`
        },
        {
          data: `S$ 1,835.16`
        },
        {
          data: `S$ 7,842.17`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>60 - 64</strong>`
        },
        {
          data: `S$ 4,721.96`
        },
        {
          data: `S$ 3,715.11`
        },
        {
          data: `S$ 3,280.59`
        },
        {
          data: `S$ 3,333.02`
        },
        {
          data: `S$ 2,407.44`
        },
        {
          data: `S$ 10,678.61`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>65 - 69</strong>`
        },
        {
          data: `S$ 6,463.59`
        },
        {
          data: `S$ 5,262.43`
        },
        {
          data: `S$ 4,336.89`
        },
        {
          data: `S$ 4,350.43`
        },
        {
          data: `S$ 3,135.16`
        },
        {
          data: `S$ 16,001.51`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 12,045.25`
        },
        {
          data: `S$ 8,830.47`
        },
        {
          data: `S$ 7,117.11`
        },
        {
          data: `S$ 5,922.46`
        },
        {
          data: `S$ 4,258.59`
        },
        {
          data: `S$ 19,551.18`
        }
      ]
    }
  ],
  footnotes: []
};

/*let GHSGroupDiscount = {
  headers: [
    {
      title: `Group Size <i>Insured Employee<i>`
    },
    {
      title: `Discount`
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>11 - 15</strong>`
        },
        {
          data: `5%`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>16 and above</strong>`
        },
        {
          data: `10%`
        }
      ]
    }
  ],
  footnotes: []
};*/

//   ----------------------------------------------------------------------

const GHS = {
  id: 9,
  code: "GHSB",
  label: "Group Hospital & Surgical (GHS)",
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GHSBenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium",
      data: [GHSPremiumEmployee, GHSPremiumSpouseOrChild, GHSPremiumSpouseAndChild]
    }
    /*{
      id: 4,
      order: 4,
      type: Enums.PLANS_TYPE.GROUP_DISCOUNT,
      label: "Group Size Discount",
      data: [GHSGroupDiscount]
    }*/
  ]
};

export default GHS;
