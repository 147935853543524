import React, { useState } from "react";
import { Box, Button, Grid, Typography, Radio } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

const RenewalSelectRequest = ({ handleRadioChange, renewalType, renewalTypeOptions }) => {
  const policyRenew = useSelector(state => state.policyRenew);
  const dispatch = useDispatch();

  return (
    <>
      <Box
        sx={{
          overflow: "hidden",
          backgroundColor: "white",
          padding: "30px 30px 35px 30px",
          boxShadow: "-2px -2px 20px 0 rgba(0,0,0,0.1)"
        }}
      >
        <Grid
          sx={{ padding: "0px 0px 20px 0px", display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>Policy chosen for renewal</Typography>
        </Grid>

        <Grid container rowSpacing={1} columnSpacing={3} item xs={5}>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>Company:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>{policyRenew.company_name}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>Reference / Policy No.:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>{policyRenew.policy_no}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>Policy Expiry Date:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>{policyRenew.expiry_date}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          overflow: "hidden",
          backgroundColor: "white",
          padding: "30px 30px 35px 30px",
          boxShadow: "-2px -2px 20px 0 rgba(0,0,0,0.1)",
          marginTop: "20px",
          marginBottom: "80px"
        }}
      >
        <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ padding: "0px 0px 20px 0px", fontSize: "18px", fontWeight: 500 }}>
            Select your request
          </Typography>
        </Grid>

        <Grid container rowSpacing={1} columnSpacing={3} xs={4} item>
          {renewalTypeOptions.map((item, index) => (
            <Grid item style={{ display: "flex", alignItems: "center" }} key={index}>
              <Radio
                checked={renewalType === item.value}
                onChange={event => {
                  handleRadioChange(event, {});
                }}
                value={item.value}
                name="renewal-type"
                color="primary"
              />
              <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>{item.name}</Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default RenewalSelectRequest;
