import * as actionTypes from "../actions/types";
import Util from "../utils/Util";
import Enums from "../utils/Enums";

const initialState = {};

export const user = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_UPDATE_TOKEN: {
      return {
        ...state,
        isTokenRefreshing: false,
        loggedIn: true,
        auth: Object.assign(
          {
            ...state.auth,
            access_token: action.payload.access_token,
            refresh_token: action.payload.refresh_token
          },
          Util.decodeAccessToken(action.payload.access_token)
        )
      };
    }
    case actionTypes.USER_REFRESH_TOKEN_PENDING:
      return {
        ...state,
        isTokenRefreshing: true,
        isTokenRefreshed: false,
        isTokenRefreshError: false
      };
    case actionTypes.USER_REFRESH_TOKEN_FULFILLED:
      return {
        ...state,
        isTokenRefreshing: false,
        isTokenRefreshed: true,
        tokenRefreshedTime: new Date(),
        auth: Object.assign(
          {
            ...action.payload.data,
            access_token: action.payload.data.access_token,
            refresh_token: action.payload.data.refresh_token
          },
          Util.decodeAccessToken(action.payload.data.access_token)
        )
      };
    case actionTypes.USER_REFRESH_TOKEN_REJECTED:
      return {
        ...state,
        isTokenRefreshing: false,
        isTokenRefreshed: false,
        isTokenRefreshError: true
      };
    case actionTypes.USER_LOGIN_REQUEST:
      return {
        loggingIn: true
      };
    case actionTypes.USER_LOGIN_SUCCESS:
      return {
        isTokenRefreshing: false,
        loggedIn:
          action.user.scope.includes(Enums.SCOPE_TYPE.TC_W) || action.user.scope.includes(Enums.SCOPE_TYPE.OTP_W)
            ? false
            : true, //false agree tnc required.
        firstTimeLogin: action.user.scope.includes(Enums.SCOPE_TYPE.TC_W),
        username: action.username,
        auth: Object.assign({ ...action.user }, Util.decodeAccessToken(action.user.access_token))
      };
    case actionTypes.USER_LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false,
        error: action.error
      };
    case actionTypes.USER_LOGOUT:
      return {
        loggedIn: false
      };
    case actionTypes.HR_GET_INFO_SUCCESS:
      return {
        ...state,
        hrInformation: action.hrInformation
      };
    case actionTypes.HR_GET_USER_INFO_SUCCESS:
      return {
        ...state,
        fullName: action.payload.full_name,
        hrInformation: {
          contact_name: action.payload.full_name,
          contact_email: action.payload.email,
          contact_no: ""
        }
      };

    case actionTypes.HR_GET_BANNER_SUCCESS:
      return {
        ...state,
        banner: action.payload
      };

    case actionTypes.HR_GET_BANNER_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
