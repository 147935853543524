import * as actionTypes from "../actions/types";

const initialState = {};

export const otp = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_OTP_NOTIFICATION_METHODS_SUCCESS:
      console.log("action.payload", action.payload.default_method);
      return {
        notificationMethods: action.payload,
        previousOtpMethod: "",
        selectedMethod: action.payload.default_method,
        availableMethods: [action.payload.default_method, ...(action.payload.alternate_methods || [])]
      };
    case actionTypes.GET_OTP_NOTIFICATION_METHODS_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case actionTypes.SEND_OTP_FAIL:
      console.log("SEND_OTP_FAIL", action.payload);
      return {
        ...state,
        error: action.payload
      };
    case actionTypes.SEND_OTP_FAIL_SMS:
      console.log("SEND_OTP_FAIL_SMS", action.payload);
      return {
        ...state,
        errorSms: action.payload
      };

    case actionTypes.SEND_OTP_FAIL_EMAIL:
      console.log("SEND_OTP_FAIL_EMAIL", action.payload);
      return {
        ...state,
        errorEmail: action.payload
      };
    case actionTypes.SEND_OTP_SUCCESS:
      return {
        ...state,
        error: "",
        errorSms: "",
        errorEmail: "",
        sentChannel: action.payload
      };
    case actionTypes.SELECT_OTP_METHOD:
      return {
        ...state,
        selectedMethod: action.payload
      };
    case actionTypes.SELECT_PREVIOUS_OTP_METHOD:
      return {
        ...state,
        previousOtpMethod: action.payload
      };
    default:
      return state;
  }
};
