import * as actionTypes from "./types";
import PolicyService from "../services/policy.service";

export const policyListActions = {
  getPolicyList
};

function getPolicyList() {
  return dispatch => {
    dispatch({ type: actionTypes.APP_SET_LOADING_STATE, payload: true });

    PolicyService.getFCPolicyList().then(
      response => {
        dispatch({ type: actionTypes.APP_SET_LOADING_STATE, payload: false });
        if (response.success) {
          dispatch({
            type: actionTypes.POLICY_GET_ALL_POLICIES,
            payload: response.data.policies
          });
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch({ type: actionTypes.APP_SET_LOADING_STATE, payload: false });
        return Promise.reject(error);
      }
    );
  };
}
