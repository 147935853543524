import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";

class PhoneNumberLink extends Component {
  render() {
    const { number, description, classes } = this.props;
    return (
      <a href={`tel:${number}`} style={{ textDecoration: "none" }}>
        <Typography color="primary" className={classes.callNumber}>
          {number}
        </Typography>
        <Typography className={classes.callText}>{description}</Typography>
      </a>
    );
  }
}

const styles = theme => ({
  callText: {
    color: "#6A6B6D",
    textAlign: "center",
    fontSize: 14
  },
  callNumber: {
    color: "#ED1B24",
    textAlign: "center",
    fontSize: 18,
    lineHeight: 1.56,
    letterSpacing: 0.5,
    fontWeight: 600
  }
});

export default withStyles(styles, { withTheme: true })(PhoneNumberLink);
