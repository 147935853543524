import React, { Component } from "react";
import classNames from "classnames";
import { withStyles } from "@mui/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import {
  ModeEdit as ModeEditIcon,
  Clear as ClearIcon,
  Folder as FolderIcon,
  Warning as WarningIcon
} from "@mui/icons-material";
import SimpleConfirmDialog from "../../../../../../components/SimpleConfirmDialog";
import Slide from "@mui/material/Slide";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class Toolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      deleteDialogOpen: false
    };
  }

  handleClickdeleteDialogOpen = () => {
    this.setState({ deleteDialogOpen: true });
  };

  handleMoveCategoryClose = value => () => {
    this.handleCatgoryMenuClose();
    this.props.moveCategoryHandler(value);
  };

  handleClose = () => {
    this.setState({ deleteDialogOpen: false });
  };

  handleCategoryMenuClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCatgoryMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleRemoveEmployee = () => {
    this.props.removeEmployeeHandler();
    this.handleClose();
  };

  render() {
    const { classes, categoriesSource, employeeSelected, editEmployeeHandler } = this.props;
    const { anchorEl } = this.state;
    const ITEM_HEIGHT = 38;

    return (
      <div className={classes.root}>
        <div className={classNames({ hidden: employeeSelected.length === 0 }, classes.toolbarButton)}>
          {employeeSelected.length <= 1 && (
            <IconButton onClick={editEmployeeHandler}>
              <ModeEditIcon />
            </IconButton>
          )}
          {/*Edit Categories Menu*/}
          <IconButton
            aria-label="More"
            disabled={this.props.disabled}
            aria-owns={anchorEl ? "long-menu" : null}
            aria-haspopup="true"
            onClick={this.handleCategoryMenuClick}
          >
            <FolderIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={this.state.anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleCatgoryMenuClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 200
              }
            }}
          >
            {categoriesSource.map((option, id) => (
              <MenuItem key={id} onClick={this.handleMoveCategoryClose(option.value)}>
                {option.label}
              </MenuItem>
            ))}
          </Menu>

          {/*Delete Dialog*/}
          <IconButton onClick={this.handleClickdeleteDialogOpen} disabled={this.props.disabled}>
            <ClearIcon />
          </IconButton>

          <SimpleConfirmDialog
            open={this.state.deleteDialogOpen}
            name="remove-employee"
            title="Remove selected employee(s)?"
            description="Removed employee(s) will not be covered under the plan."
            closeButtonText="Cancel"
            closeHandler={this.handleClose}
            okButtonText="Remove"
            okHandler={this.handleRemoveEmployee}
          />
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex"
  },
  toolbarButton: {
    flex: 1,
    height: 40,
    display: "flex",
    alignItems: "center"
  },
  employeeListInfo: {
    alignSelf: "flex-end",
    flex: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    textAlign: "right",
    marginBottom: theme.spacing.unit
  },
  dioalogTitleLogo: {},
  dialogTitleWrapper: {
    textAlign: "center"
  },
  dialogContentWrapper: {
    textAlign: "center"
  },
  dialogActionsAlign: {
    justifyContent: "center",
    marginBottom: 30
  },
  button: {
    width: 200
  }
});

export default withStyles(styles, { withTheme: true })(Toolbar);
