import React from "react";

import { DatePicker } from "@mui/x-date-pickers";

const DateInput = ({ name, value, onChange, onError, maxDateMessage, className }) => {
  return (
    <DatePicker
      onError={onError}
      name={name}
      value={value}
      onChange={onChange}
      format="DD/MM/YYYY"
      autoComplete="[off]"
      animateYearScrolling
      clearable
      keyboard
      disableFuture
      maxDateMessage={maxDateMessage}
      className={className}
    />
  );
};

export default DateInput;
