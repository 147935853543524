import React, { useState } from "react";
import { Box, Button, Grid, Typography, Radio } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const RenewalSubmission = ({}) => {
  return (
    <>
      <Box
        sx={{
          overflow: "hidden",
          backgroundColor: "white",
          padding: "30px 30px 35px 30px",
          boxShadow: "-2px -2px 20px 0 rgba(0,0,0,0.1)"
        }}
      >
        <Grid
          sx={{ padding: "0px 0px 20px 0px", display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
            Thank you for your submission. An acknowledgment email has been sent to you.
          </Typography>
        </Grid>

        <Grid container rowSpacing={1} columnSpacing={3} xs={12}>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>You may also explore other pages:</Typography>
          </Grid>
          <Grid item xs={12}>
            <ul>
              <li>
                <Link style={{ color: "#ee353b", textDecoration: "underline" }} to={{ pathname: "/auth/sp/Documents" }}>
                  Documents
                </Link>
              </li>
              <li>
                <Link
                  style={{ color: "#ee353b", textDecoration: "underline" }}
                  to={{ pathname: "/auth/Documents/uploadHistory" }}
                >
                  Upload History
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default RenewalSubmission;
