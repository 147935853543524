import * as actionTypes from "../actions/types";

const detailsObject = {
  address: " ",
  agent_first_name: " ",
  agent_last_name: " ",
  agent_code: " ",
  agent_email: " ",
  auth_signatory_designation: " ",
  auth_signatory_name: " ",
  contact_email: "",
  contact_name: " ",
  contact_no: " ",
  name: " ",
  nature_of_business: " ",
  notes: " ",
  uen_no: "",
  encrypted_password: "",
  postal_code: " ",
  referral_id_1: " ",
  referral_id_2: " ",
  bank_account_name: " ",
  bank_code: " ",
  bank_name: " ",
  bank_account_number: " ",
  branch_code: " ",
  bank_swift_code: " "
};

const initialState = {
  details: { ...detailsObject },
  data: {
    categories: [],
    estimates: [],
    persons: []
  }
};

export const company_cache = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.COMPANY_CACHE_DETAIL_RESET:
      return initialState;
    case actionTypes.QUOTE_EXISTING_POLICY_FULFILLED:
      return {
        ...state,
        ...action.payload.data,
        details: action.payload.data.company
      };

    default:
      return state;
  }
};
