import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableFooter,
  TablePagination,
  Button,
  Icon
} from "@mui/material";
import classNames from "classnames";
import TablePaginationActions from "../../_HR/MyEmployees/TablePaginationActions";
import moment from "moment";
import CustomTooltip from "../../../../components/CustomTooltip";

const TABLE_ROWS_PER_PAGE = 20;
const TABLE_COLUMNS = [
  { label: "Claim Reference", type: "string", field: "claimReference" },
  { label: "Claim Number", type: "string", field: "claimNo" },
  { label: "Company", type: "string", field: "companyName" },
  { label: "Employee Name", type: "string", field: "employeeName" },
  { label: "Employee NRIC", type: "string", field: "employeeNric" },
  { label: "Claimant Name", type: "string", field: "claimantName" },
  { label: "Claimant NRIC", type: "string", field: "claimantNric" },
  { label: "Relation", type: "string", field: "relation" },
  { label: "Type of Claim", type: "string", field: "claimType" },
  { label: "Date of Consultation", type: "date", field: "dateConsultation" },
  { label: "Claim Received Date", type: "date", field: "dateReceived" },
  { label: "Claim Processed Date", type: "date", field: "claimProcessDate" },
  { label: "Amount Submitted", type: "string", field: "amountSubmitted" },
  { label: "Amount Approved", type: "string", field: "amountPaid" },
  { label: "Status", type: "string", field: "claimStatus" }
];

class PolicyClaimExpenseTable extends Component {
  dataProcess = (value, type) => {
    if (type === "date") {
      return value && moment(value).format("YYYY") !== "9999" ? moment(value).format("DD/MM/YYYY") : "-";
    }
    return value;
  };

  render() {
    const { classes, claimExpense, isProcessing, currentPageNo, handlePageChange, handleExport } = this.props;
    const offsetStartIdx = Math.floor(currentPageNo * TABLE_ROWS_PER_PAGE);
    const offsetEndIdx = Math.floor(offsetStartIdx + TABLE_ROWS_PER_PAGE - 1);
    const currentPageList = (claimExpense || []).filter((data, idx) => offsetStartIdx <= idx && idx <= offsetEndIdx);

    return (
      <div className={classes.container}>
        <div className={classes.tooltipContent}>
          <CustomTooltip title="Download Excel Report" placement="right" arrow>
            <Button
              size="small"
              disabled={isProcessing || !(claimExpense && claimExpense.length)}
              onClick={handleExport}
            >
              <Icon>{"get_app"}</Icon>
            </Button>
          </CustomTooltip>
        </div>
        <div className={classes.tableContent}>
          <Table>
            <TableHead className={classes.tableHeaderRow}>
              <TableRow>
                {TABLE_COLUMNS.map(({ label }, idx) => (
                  <TableCell key={idx} className={classes.tableHeaderCell}>
                    {label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPageList.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={TABLE_COLUMNS.length} className={classes.greyedText}>
                    No record found
                  </TableCell>
                </TableRow>
              ) : (
                currentPageList.map((claimExpense, row) => (
                  <TableRow key={row}>
                    {TABLE_COLUMNS.map(({ field, type }, idx) => (
                      <TableCell
                        key={idx}
                        className={classNames(classes.tableBodyCell, { [classes.tableBodyOddCell]: row % 2 !== 0 })}
                      >
                        {this.dataProcess(claimExpense[field], type)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={15}
                  count={(claimExpense && claimExpense.length) || 0}
                  rowsPerPage={TABLE_ROWS_PER_PAGE}
                  page={currentPageNo}
                  onPageChange={handlePageChange}
                  ActionsComponent={TablePaginationActions}
                  rowsPerPageOptions={[]}
                  labelDisplayedRows={({ count, page }) => {
                    const totalPages = count === 0 ? 1 : Math.ceil(count / TABLE_ROWS_PER_PAGE);
                    return `Page ${page + 1} of ${totalPages}`;
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    backgroundColor: "white",
    marginLeft: "10px",
    marginBottom: "5px",
    marginTop: "25px",
    borderRadius: "5px"
  },
  tableContent: {
    marginTop: 5,
    overflowX: "auto",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px"
  },
  tableHeaderRow: {
    backgroundColor: "#ED1B24",
    padding: "10px 20px",
    marginBottom: 10,
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px"
  },
  tableHeaderCell: {
    paddingTop: 10,
    paddingBottom: 10,
    color: "white",
    border: "1px solid #cccccc",
    fontSize: 15
  },
  tableBodyCell: {
    padding: "0px 8px",
    color: "black",
    border: "1px solid #cccccc"
  },
  tableBodyOddCell: {
    backgroundColor: "rgba(134, 133, 133, 0.1)"
  },
  greyedText: {
    color: "rgba(0, 0, 0, 0.5)"
  },
  tooltipContent: {
    paddingTop: 10
  }
});

export default withStyles(styles, { withTheme: true })(PolicyClaimExpenseTable);
