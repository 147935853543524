import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withStyles } from "@mui/styles";
import CoveragePackage from "./PackagePlans/CoveragePackage";
import CoverageCustomised from "./CustomisedPlans/CoverageCustomised";
import CustomBreadcrumb from "../../../../components/CustomBreadcrumb";

class PolicyInsuranceCoverage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, policy } = this.props;
    return (
      <div>
        <div className={classes.breadcrumb}>
          <CustomBreadcrumb currentPage="InsuranceCoverage" companyName={policy.companyName} />
        </div>
        {policy.displayType === "customised" ? <CoverageCustomised /> : <CoveragePackage />}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    backgroundColor: theme.background.color,
    marginBottom: theme.actionFooter.height,
    fontSize: "0.75em"
  },
  waitingPeriodWrapper: {
    padding: theme.spacing.unit * 2.5,
    marginTop: 10
  },
  waitingPeriodContent: {
    display: "flex",
    justifyContent: "space-between"
  },
  breadcrumb: {
    margin: "30px 10px"
  }
});

function mapStateToProps(state) {
  return {
    policy: state.policyDetails.policy
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(PolicyInsuranceCoverage)));
