import * as actionTypes from "./types";
import Util from "../utils/Util";
import Enums from "../utils/Enums";
import moment from "moment";
import ProductEngineService from "../services/product.engine.service";
import quoteService from "../services/quote.service";
import productService from "../services/product.service";
import companyService from "../services/company.service";
import paymentService from "../services/payment.service";
import { productActions } from "./product.action";

export const quoteActions = {
  setEstimatedPremium,
  setActualPremium,
  computeEstimatedPremium,
  computeActualPremium,
  setCategories,
  setEstimates,
  setName,
  setActiveStep,
  setPDPA,
  save,
  newQuote,
  get,
  init,
  setActivationDate,
  setConfirmActivationDate,
  submitPolicy,
  closeSubmitPolicyPopup,
  closeSubmitPolicySnackbar,
  submitGenerateQuote,
  setWaitingPeriod,
  resetActualPremium,
  setStageValidation,
  setPaymentMethod,
  makeCreditCardPayment,
  employeeRevalidate,
  employeeFinishRevalidate,
  dependantSnackbarToggle,
  validateStep2,
  isValid,
  resetQuote,
  setProductCodeAndVersion,
  setExistingPolicyNo,
  setBusinessType,
  deleteQuote,
  getExistingPolicyDetails,
  getExistingPolicyMemberDetails
};

function init(productId, productCode) {
  return dispatch => {
    dispatch({
      type: actionTypes.QUOTE_INIT,
      payload: {
        id: productId,
        code: productCode
      }
    });
  };
}

// Luis & Kunal Code 17 Jul
/*function deleteQuote(quoteId){
  return dispatch =>{
    dispatch({
      type: actionTypes.QUOTE_DELETE,
      payload:{
        quoteId: quoteId
      }
    });
  };
}*/

function deleteQuote(quoteId) {
  return dispatch => {
    return quoteService.deleteQuote(quoteId).then(
      resp => {
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

//End Code

function setProductCodeAndVersion(code, version) {
  return dispatch => {
    dispatch({
      type: actionTypes.QUOTE_SET_PRODUCT_CODE_VERSION,
      payload: { code, version }
    });
  };
}

function isValid(quote) {
  if (quote && quote.premiums && quote.premiums.actual) {
    if (quote.premiums.actual.final === 0) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function validateStep2(state) {
  let activationDate = state.quote.req_effective_date
    ? moment(state.quote.req_effective_date, "DD/MM/YYYY").toDate()
    : null;
  let today = new Date().setHours(0, 0, 0, 0);
  if (activationDate === null || activationDate < today || Util.isEmpty(state.quote.waiting_period)) {
    return false;
  }
  return true;
}

function dependantSnackbarToggle(toggle) {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.QUOTE_SET_DEPENDANT_SNACKBAR_FLAG,
      payload: toggle
    });
  };
}

function employeeRevalidate() {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.QUOTE_SET_REVALIDATE_FLAG
    });
  };
}

function employeeFinishRevalidate() {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.QUOTE_UNSET_REVALIDATE_FLAG
    });
  };
}

function computeEstimatedPremium() {
  return (dispatch, getState) => {
    const state = getState();
    const { categories, estimates } = state.quote.data;

    let _opts = _mapOpts(state.quote);

    let _estimates = estimates.map((e, idc) => {
      return {
        averageAge: Util.parseInteger(e.averageAge),
        name: e.name,
        noOfEmployee: e.noOfEmployee ? Util.parseInteger(e.noOfEmployee) : 0
      };
    });

    let _categories = [];
    categories.forEach((category, idx) => {
      //Populate categories values
      _categories.push({
        name: category.name,
        plansConfiguration: category.plansConfiguration.filter(c => {
          return !Util.isEmpty(c.tier);
        })
      });
    });

    return ProductEngineService.computeEstimatedPremium(_categories, _estimates, _opts).then(
      resp => {
        let premiums = {
          discount: resp.discount,
          final: resp.final,
          subTotal: resp.subTotal,
          total: resp.total
        };
        dispatch(setEstimatedPremium(premiums));
        return Promise.resolve(premiums);
      },
      error => {
        let premiums = {
          discount: 0,
          final: 0,
          subTotal: 0,
          total: 0
        };
        dispatch(setEstimatedPremium(premiums));
        return Promise.reject({
          valid: error.success,
          error: Util.mapPlansCodeLabel(error.msg)
        });
      }
    );
  };
}

function computeActualPremium() {
  return (dispatch, getState) => {
    const state = getState();
    const { categories, persons } = state.quote.data;

    let _opts = _mapOpts(state.quote);

    let _categories = [];
    categories.forEach((category, idx) => {
      //Populate categories values
      _categories.push({
        name: category.name,
        plansConfiguration: category.plansConfiguration.filter(c => {
          return !Util.isEmpty(c.tier);
        })
      });
    });

    if (persons.length > 0 && _opts.activateDate && _categories[0].plansConfiguration) {
      return ProductEngineService.computeActualPremium(_categories, persons, _opts).then(
        resp => {
          let premiums = {
            discount: resp.discount,
            final: resp.final,
            subTotal: resp.subTotal,
            total: resp.total
          };
          dispatch(setActualPremium(premiums));
          return Promise.resolve(premiums);
        },
        error => {
          let premiums = {
            discount: 0,
            final: 0,
            subTotal: 0,
            total: 0
          };
          dispatch(setActualPremium(premiums));
          return Promise.reject({
            valid: error.success,
            error: Util.mapPlansCodeLabel(error.msg)
          });
        }
      );
    }
  };
}

function resetActualPremium() {
  return dispatch => {
    let premiums = {
      discount: 0,
      final: 0,
      subTotal: 0,
      total: 0
    };
    dispatch(setActualPremium(premiums));
    return Promise.resolve(premiums);
  };
}

function setCategories(payload) {
  return dispatch => {
    return dispatch({
      type: actionTypes.QUOTE_SET_CATEGORIES,
      payload: payload
    });
  };
}

function setEstimates(payload) {
  return dispatch => {
    return dispatch({
      type: actionTypes.QUOTE_SET_ESTIMATES,
      payload: payload
    });
  };
}

function setActiveStep(payload) {
  return dispatch => {
    dispatch({
      type: actionTypes.QUOTE_SET_ACTIVE_STEP,
      payload: payload
    });
  };
}

function setEstimatedPremium(payload) {
  return dispatch => {
    dispatch({
      type: actionTypes.QUOTE_SET_ESTIMATED_PREMIUM,
      payload: payload
    });
  };
}

function setActualPremium(payload) {
  return dispatch => {
    dispatch({ type: actionTypes.QUOTE_SET_ACTUAL_PREMIUM, payload: payload });
  };
}

function setName(payload) {
  return dispatch => {
    dispatch({ type: actionTypes.QUOTE_SET_NAME, payload: payload });
  };
}

function setExistingPolicyNo(payload) {
  return dispatch => {
    dispatch({ type: actionTypes.QUOTE_SET_EXISTING_POLICY_NO, payload: payload });
  };
}

function setBusinessType(payload) {
  return dispatch => {
    dispatch({ type: actionTypes.QUOTE_SET_BUSINESS_TYPE, payload: payload });
  };
}

function get(id) {
  return dispatch => {
    dispatch(resetQuote());
    return dispatch({
      type: actionTypes.QUOTE_GET,
      payload: quoteService.get(id)
    });
  };
}

function setPDPA() {
  return (dispatch, getState) => {
    const state = getState();
    const quote = state.quote;

    return dispatch({
      type: actionTypes.QUOTE_SET_PDPA,
      payload: !quote.pdpa_consent
    });
  };
}

function updateQuote(quote, dispatch, company, name, reset, payload) {
  if (!quote.readOnly) {
    return dispatch({
      type: actionTypes.QUOTE_SAVE,
      payload: quoteService.update(quote.id, payload)
    }).then(
      quoteServiceResponse => {
        let result = quoteServiceResponse.action.payload;
        return _saveCompany(dispatch, company, result.data.id, name, reset);
      },
      error => {
        return Promise.reject(error);
      }
    );
  } else {
    if (reset) dispatch(resetQuote());
    return Promise.resolve();
  }
}

function save(stage, reset = false) {
  return (dispatch, getState) => {
    const state = getState();
    const quote = state.quote;
    const company = state.company;

    if (quote.isSaving) {
      return Promise.reject({
        success: false,
        msg: "Quote is saving in progress"
      });
    }

    if (reset) {
      dispatch({ type: actionTypes.QUOTE_RESET_TRIGGERED });
    }

    let payload = {
      product_id: quote.product_id,
      name: quote.name,
      stages: stage || Enums.STAGES.ESTIMATE,
      req_effective_date: quote.req_effective_date,
      pdpa_consent: quote.pdpa_consent,
      quotation_no: 0, //TODO: remove in future as this will be generated from the backend
      waiting_period: quote.waiting_period,
      data: {
        persons: _mapPersons(quote.data.persons),
        categories: quote.data.categories,
        specificIndividuals: quote.data.specificIndividuals,
        estimates: quote.data.estimates,
        amlClauseAction: quote.data.amlClauseAction
      },
      business_type: quote.business_type,
      existing_policy_no: quote.existing_policy_no
    };

    if (reset) {
      dispatch({ type: actionTypes.QUOTE_RESET_TRIGGERED });
    }

    if (!Util.isEmpty(quote.id)) {
      return quoteService.get(quote.id).then(
        response => {
          let currentQuoteInfo = response.data;

          return productService.loadLatestProductVersion(Enums.PRODUCT_CODE_PF3).then(
            latestProductResponse => {
              if (latestProductResponse.success) {
                let latestProductResponseData = latestProductResponse.data.config;
                if (
                  currentQuoteInfo.stages == Enums.STAGES.ESTIMATE ||
                  currentQuoteInfo.stages == Enums.STAGES.EMPLOYEE
                ) {
                  dispatch(
                    productActions.loadEngine(latestProductResponseData.code, latestProductResponseData.version)
                  );
                  dispatch(productActions.setConfig(latestProductResponseData));
                  payload.product_id = latestProductResponseData.productId;
                  return updateQuote(quote, dispatch, company, name, reset, payload);
                } else {
                  dispatch(productActions.loadEngine(currentQuoteInfo.product_code, currentQuoteInfo.product_version));
                  dispatch(productActions.setConfig(latestProductResponseData));
                  return updateQuote(quote, dispatch, company, name, reset, payload);
                }
              }
            },
            error => {
              return Promise.reject(error);
            }
          );
        },
        error => {
          return Promise.reject(error);
        }
      );
    } else {
      //Create new quote
      return dispatch({
        type: actionTypes.QUOTE_SAVE,
        payload: quoteService.save(payload)
      }).then(
        response => {
          let result = response.action.payload;
          return _saveCompany(dispatch, company, result.data.id, name, reset);
        },
        error => {
          return Promise.reject(error);
        }
      );
    }
  };
}

//TODO: to refactor to shift this to company.action
function _saveCompany(dispatch, company, quoteId, name, reset) {
  if (!Util.isEmpty(company.details.id)) {
    //replace null value to empty
    for (const [key, value] of Object.entries(company.details)) {
      if (value == null) {
        company.details[key] = " ";
      }
    }
    return dispatch({
      type: actionTypes.COMPANY_DETAIL_PUT,
      payload: companyService.putCompanyDetail({
        quoteId: quoteId,
        details: {
          name: name,
          ...company.details
        }
      })
    }).then(() => {
      if (reset) dispatch(resetQuote());
      return Promise.resolve();
    });
  } else {
    return dispatch({
      type: actionTypes.COMPANY_DETAIL_POST,
      payload: companyService.postCompanyDetail({
        quoteId: quoteId,
        details: {
          name: name,
          ...company.details
        }
      })
    }).then(() => {
      if (reset) dispatch(resetQuote());
      return Promise.resolve();
    });
  }
}

function newQuote(stage) {
  return save(stage, true);
}

function resetQuote() {
  return dispatch => {
    dispatch({ type: actionTypes.QUOTE_RESET });
    dispatch({ type: actionTypes.COMPANY_DETAIL_RESET });
    dispatch({ type: actionTypes.DOCUMENT_RESET });
  };
}

function _mapPersons(persons) {
  //Populate persons values
  return persons.map((person, idx) => {
    if (
      !Util.isEmpty(person.type) &&
      (person.type === Enums.PERSON_TYPE.SPOUSE || person.type === Enums.PERSON_TYPE.CHILD)
    ) {
      //Dependent
      return {
        id: null,
        id_no: person.id_no,
        full_name: person.full_name,
        gender: Util.isEmpty(person.gender) ? null : person.gender,
        dob: Util.isEmpty(person.dob) ? null : person.dob,
        nationality: person.nationality,
        category: person.category,
        type: Util.isEmpty(person.type) ? null : person.type,
        dependency_id_no: person.dependency_id_no,
        invalid_fields: person.invalid_fields,
        isValid: person.isValid
      };
    } else {
      return {
        id: null,
        id_no: person.id_no,
        full_name: person.full_name,
        gender: Util.isEmpty(person.gender) ? null : person.gender,
        dob: Util.isEmpty(person.dob) ? null : person.dob,
        marital_status: Util.isEmpty(person.marital_status) ? null : person.marital_status,
        occupation_class: person.occupation_class,
        bank_account_name: person.bank_account_name,
        bank_account_number: person.bank_account_number,
        bank_name: person.bank_name,
        no_of_dependent: person.no_of_dependent,
        nationality: person.nationality,
        country_of_residence: person.country_of_residence,
        date_of_employment: Util.isEmpty(person.date_of_employment) ? null : person.date_of_employment,
        email: person.email,
        category: person.category,
        type: Util.isEmpty(person.type) ? null : person.type,
        dependency_type: Util.isEmpty(person.dependency_type) ? null : person.dependency_type,
        dependency_id_no: person.dependency_id_no,
        invalid_fields: person.invalid_fields,
        isValid: person.isValid,
        foreign_worker:
          person.foreign_worker == true ? "Y" : person.foreign_worker == false ? "N" : person.foreign_worker
      };
    }
  });
}

function _mapOpts(quote) {
  return {
    activateDate: quote.req_effective_date
  };
}

function setActivationDate(payload) {
  return dispatch => {
    dispatch({
      type: actionTypes.QUOTE_SET_ACTIVATION_DATE,
      payload: payload
    });
  };
}

function setWaitingPeriod(payload) {
  return dispatch => {
    dispatch({
      type: actionTypes.QUOTE_SET_WAITING_PERIOD,
      payload: payload
    });
  };
}

function setConfirmActivationDate(payload) {
  return dispatch => {
    dispatch({
      type: actionTypes.QUOTE_SET_CONFIRM_ACTIVATION_DATE,
      payload: payload
    });
  };
}

function submitPolicy() {
  return (dispatch, getState) => {
    const quote = getState().quote;
    dispatch({
      type: actionTypes.QUOTE_SUBMIT_POLICY
    });
    quoteService.updateStage(quote.id, { stages: Enums.STAGES.SUBMITTED }).then(
      response => {
        if (response.success) {
          dispatch({
            type: actionTypes.QUOTE_SUBMIT_POLICY_SUCCESS
          });
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch({
          type: actionTypes.QUOTE_SUBMIT_POLICY_ERROR,
          payload: error.message
        });
        return Promise.reject(error);
      }
    );
  };
}

function closeSubmitPolicyPopup(payload) {
  return dispatch => {
    dispatch({
      type: actionTypes.QUOTE_SUBMIT_POLICY_CLOSE_SUCCESS_POPUP,
      payload: payload
    });
  };
}

function closeSubmitPolicySnackbar(payload) {
  return dispatch => {
    dispatch({
      type: actionTypes.QUOTE_SUBMIT_POLICY_CLOSE_SNACKBAR,
      payload: payload
    });
  };
}

function submitGenerateQuote(payload) {
  return (dispatch, getState) => {
    const quote = getState().quote;
    dispatch({
      type: actionTypes.QUOTE_SUBMIT_QUOTE
    });
    return quoteService.generateQuote(quote.id, { ...payload, stages: Enums.STAGES.PROPOSAL }).then(
      response => {
        if (response.success) {
          dispatch({
            type: actionTypes.QUOTE_SUBMIT_QUOTE_SUCCESS
          });
          dispatch({
            type: actionTypes.APP_FIRSTPAGEDIALOG_SET_VISIBILITY,
            payload: true
          });
          dispatch({
            type: actionTypes.APP_RENEWALNOTIFICATION_SET_VISIBILITY,
            payload: true
          });
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch({
          type: actionTypes.QUOTE_SUBMIT_QUOTE_ERROR,
          payload: error.message
        });
        return Promise.reject(error);
      }
    );
  };
}

function setStageValidation(stage, status) {
  return dispatch => {
    dispatch({
      type: actionTypes.QUOTE_SET_STAGE_VALIDATION,
      payload: { stage, status }
    });
  };
}

function setPaymentMethod(method) {
  return (dispatch, getState) => {
    const quote = getState().quote;

    return paymentService.setPayment(quote.id, method).then(
      resp => {
        dispatch({
          type: actionTypes.QUOTE_SET_PAYMENT_METHOD,
          payload: method
        });
        return Promise.resolve(resp);
      },
      error => {
        return Promise.resolve(error);
      }
    );
  };
}

function makeCreditCardPayment(hostedFieldsInstance, data) {
  return (dispatch, getState) => {
    const quote = getState().quote;

    return new Promise((resolve, reject) => {
      dispatch({ type: actionTypes.QUOTE_MAKE_PAYMENT_PENDING });
      hostedFieldsInstance.tokenize((err, resp) => {
        if (err) {
          dispatch({
            type: actionTypes.QUOTE_MAKE_PAYMENT_REJECTED,
            payload: err
          });
          reject(err);
          return;
        }

        //Check that only Mastercard goes through
        if (resp.details && resp.details.cardType) {
          if (Enums.PAYMENT_CREDITCARDS.includes(resp.details.cardType.toUpperCase())) {
            let payload = {
              nonce: resp.nonce,
              card_holder_name: data.card_holder_name
            };

            return paymentService.makePayment(quote.id, payload).then(
              resp => {
                dispatch({ type: actionTypes.QUOTE_MAKE_PAYMENT_FULFILLED });
                resolve(resp);
              },
              error => {
                dispatch({
                  type: actionTypes.QUOTE_MAKE_PAYMENT_REJECTED,
                  payload: error
                });
                reject(error);
              }
            );
          } else {
            dispatch({
              type: actionTypes.QUOTE_MAKE_PAYMENT_REJECTED,
              payload: err
            });
            reject({
              message: "Your card is not supported. We only accept Mastercard at the moment."
            });
          }
        } else {
          dispatch({
            type: actionTypes.QUOTE_MAKE_PAYMENT_REJECTED,
            payload: err
          });
          reject();
        }
      });
    });
  };
}

function getExistingPolicyDetails(policyNo, agentNo) {
  return dispatch => {
    // dispatch(resetQuote());
    return dispatch({
      type: actionTypes.QUOTE_EXISTING_POLICY,
      payload: quoteService.getExistingPolicyDetails(policyNo, agentNo)
    });
  };
}

function getExistingPolicyMemberDetails(policyNo, agentNo) {
  return dispatch => {
    return dispatch({
      type: actionTypes.QUOTE_EXISTING_POLICY_MEMBER_DETAILS,
      payload: quoteService.getExistingPolicyMemberDetails(policyNo, agentNo)
    });
  };
}
