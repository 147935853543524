import React, { useState } from "react";
import { Box, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, Radio, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
const RenewalListingTable = ({ renewals, handleRadioChange, selectedPolicy }) => {
  return (
    <Box
      sx={{
        overflow: "hidden",
        backgroundColor: "white",
        padding: "40px 30px 35px 30px",
        boxShadow: "-2px -2px 20px 0 rgba(0,0,0,0.1)"
      }}
    >
      <Typography sx={{ fontSize: "18px", padding: "0px 0px 20px 0px", fontWeight: 500 }}>
        Select a policy for your renewal request
      </Typography>
      {renewals.length === 0 && (
        <Typography sx={{ fontSize: "14px", padding: "0px 0px 20px 0px", fontWeight: 500 }}>
          There are no records at the moment
        </Typography>
      )}
      {renewals.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell style={{ fontSize: "1rem", color: "#7f7f7f" }}>Policy Number</TableCell>
              <TableCell style={{ fontSize: "1rem", color: "#7f7f7f" }}>Company Name</TableCell>
              <TableCell style={{ fontSize: "1rem", color: "#7f7f7f" }}>Policy Expiry Date</TableCell>
              <TableCell style={{ fontSize: "1rem", color: "#7f7f7f", display: "flex", alignItems: "center" }}>
                <span>Renewal Status</span>
                <Tooltip
                  title={
                    "The status displayed below reflects the progress of renewal case submission. For actual policy status, kindly refer to Client & Policy Information page."
                  }
                >
                  <InfoOutlinedIcon style={{ width: "20px", margin: "0px 0px 0px 5px" }} />
                </Tooltip>
              </TableCell>
              <TableCell style={{ fontSize: "1rem", color: "#7f7f7f" }}>Latest Updated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renewals.map((renewal, index) => (
              <TableRow key={index}>
                <TableCell style={{ fontSize: "1rem" }}>
                  <Radio
                    checked={selectedPolicy === renewal.policy_no}
                    onChange={event => {
                      handleRadioChange(event, {
                        company_name: renewal.company_name,
                        expiry_date: renewal.expiry_date,
                        contract_type: renewal.contract_type,
                        status: renewal.status,
                        id: renewal.id,
                        policy_no: renewal.policy_no,
                        renewal_type: renewal.renewal_type,
                        has_hr_dec_changed: renewal.has_hr_dec_changed || false
                      });
                    }}
                    value={renewal.policy_no}
                    name="policy-selection"
                    color="primary"
                  />
                </TableCell>
                <TableCell style={{ fontSize: "1rem" }}>{renewal.policy_no}</TableCell>
                <TableCell style={{ fontSize: "1rem" }}>{renewal.company_name}</TableCell>
                <TableCell style={{ fontSize: "1rem" }}>{renewal.expiry_date}</TableCell>
                <TableCell style={{ fontSize: "1rem" }}>
                  {renewal.status.toLowerCase() === "lapsed" ||
                  renewal.status.toLowerCase() === "expired" ||
                  renewal.status.toLowerCase() === "renewal_status" ? (
                    <Tooltip
                      title={
                        <>
                          {renewal.status.toLowerCase() === "lapsed" ? (
                            <ul>
                              <li>
                                You may reinstate the policy within a 14-days grace period. After this period, the
                                system will automatically remove the policy.
                              </li>
                              <li>Expired or non-renewed policies will be labeled as "Lapsed".</li>
                            </ul>
                          ) : renewal.status.toLowerCase() === "expired" ? (
                            <ul>
                              <li>
                                You may reinstate the policy within a 14-days grace period. After this period, the
                                system will automatically remove the policy.
                              </li>
                            </ul>
                          ) : renewal.status.toLowerCase() === "renewal_status" ? (
                            <ul>
                              <li>
                                The status displayed below reflects the progress of renewal case submission. for actual
                                policy status, kindly refer to client & policy information page.
                              </li>
                            </ul>
                          ) : (
                            ""
                          )}
                        </>
                      }
                    >
                      <span style={{ color: "red", display: "flex", alignItems: "center" }}>
                        <InfoOutlinedIcon style={{ color: "red", margin: "0px 5px 0px 0px" }} /> {renewal.status}
                      </span>
                    </Tooltip>
                  ) : (
                    <>{renewal.status.toLowerCase() === "pending" ? <> PENDING </> : renewal.status}</>
                  )}
                </TableCell>
                <TableCell style={{ fontSize: "1rem" }}>{renewal.updated ? renewal.updated : ""}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};

export default RenewalListingTable;
