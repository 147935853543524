export default [
  { value: "SGP", label: "Singapore" },
  { value: "AFG", label: "Afghan" },
  { value: "AL", label: "Albania" },
  { value: "GBA", label: "Alderney" },
  { value: "DZ", label: "Algeria" },
  { value: "AND", label: "Andorra" },
  { value: "ANG", label: "Anguilla" },
  { value: "ANT", label: "Antigua" },
  { value: "RA", label: "Argentina" },
  { value: "ARU", label: "Aruba" },
  { value: "AUS", label: "Australia" },
  { value: "A", label: "Austria" },
  { value: "BS", label: "Bahamas" },
  { value: "BRN", label: "Bahrain" },
  { value: "BD", label: "Bangladesh" },
  { value: "BDS", label: "Barbados" },
  { value: "BAR", label: "Barbuda" },
  { value: "B", label: "Belgium" },
  { value: "BH", label: "Belize" },
  { value: "BY", label: "Belorussia" },
  { value: "DY", label: "Benin" },
  { value: "BOL", label: "Bolivia" },
  { value: "RB", label: "Botswana" },
  { value: "BR", label: "Brazil" },
  { value: "BVI", label: "Bristish Virgin Islands" },
  { value: "BRU", label: "Brunei" },
  { value: "BG", label: "Bulgaria" },
  { value: "RCA", label: "C. Afr Rep" },
  { value: "K", label: "Cambodia" },
  { value: "CDN", label: "Canada" },
  { value: "CVI", label: "Cape Verde Islands" },
  { value: "CAY", label: "Cayman Islands" },
  { value: "RCH", label: "Chile" },
  { value: "CHN", label: "China" },
  { value: "CO", label: "Colombia" },
  { value: "RCB", label: "Congo" },
  { value: "COK", label: "Cook Islands" },
  { value: "CR", label: "Costa Rica" },
  { value: "HR", label: "Croatia" },
  { value: "C", label: "Cuba" },
  { value: "CY", label: "Cyprus" },
  { value: "CZ", label: "Czech" },
  { value: "DK", label: "Denmark" },
  { value: "WD", label: "Dominica" },
  { value: "DOM", label: "DominicRep" },
  { value: "EC", label: "Ecuador" },
  { value: "ET", label: "Egypt" },
  { value: "ES", label: "ElSalvador" },
  { value: "EW", label: "Estonia" },
  { value: "ETH", label: "Ethiopia" },
  { value: "FR", label: "Faroes" },
  { value: "FJI", label: "Fiji" },
  { value: "FIN", label: "Finland" },
  { value: "F", label: "France" },
  { value: "POL", label: "French Polynesia" },
  { value: "WAG", label: "Gambia" },
  { value: "D", label: "Germany" },
  { value: "GH", label: "Ghana" },
  { value: "GBZ", label: "Gibraltar" },
  { value: "GR", label: "Greece" },
  { value: "WG", label: "Grenada" },
  { value: "GB", label: "Gt Britain" },
  { value: "GUD", label: "Guadeloupe" },
  { value: "GCA", label: "Guatemala" },
  { value: "GBG", label: "Guernsey" },
  { value: "GUY", label: "Guyana" },
  { value: "RH", label: "Haiti" },
  { value: "HKG", label: "HK G" },
  { value: "NL", label: "Holland" },
  { value: "HON", label: "Honduras" },
  { value: "HK", label: "Hong Kong" },
  { value: "H", label: "Hungary" },
  { value: "IS", label: "Iceland" },
  { value: "IND", label: "India" },
  { value: "IDN", label: "Indonesia" },
  { value: "IR", label: "Iran" },
  { value: "IRQ", label: "Iraq" },
  { value: "IRL", label: "Ireland" },
  { value: "GBM", label: "Isle o Man" },
  { value: "IL", label: "Israel" },
  { value: "I", label: "Italy" },
  { value: "CI", label: "IvoryCoast" },
  { value: "JA", label: "Jamaica" },
  { value: "JPN", label: "Japan" },
  { value: "GBJ", label: "Jersey" },
  { value: "JOR", label: "Jordan" },
  { value: "724", label: "Kampuchea" },
  { value: "EAK", label: "Kenya" },
  { value: "KOR", label: "Korea" },
  { value: "KWT", label: "Kuwait" },
  { value: "LAO", label: "Laos" },
  { value: "LV", label: "Latvia" },
  { value: "RL", label: "Lebanon" },
  { value: "LS", label: "Lesotho" },
  { value: "LAR", label: "Libya" },
  { value: "FL", label: "Liechstein" },
  { value: "LT", label: "Lithuania" },
  { value: "L", label: "Luxembourg" },
  { value: "MCU", label: "Macau" },
  { value: "MK", label: "Macedonia" },
  { value: "RM", label: "Madagascar" },
  { value: "MW", label: "Malawi" },
  { value: "MAL", label: "Malaysia" },
  { value: "MLD", label: "Maldives" },
  { value: "RMM", label: "Mali" },
  { value: "M", label: "Malta" },
  { value: "MAR", label: "Marshall Island" },
  { value: "MRT", label: "Martinique" },
  { value: "RIM", label: "Mauritania" },
  { value: "MS", label: "Mauritius" },
  { value: "MEX", label: "Mexico" },
  { value: "MC", label: "Monaco" },
  { value: "712", label: "Mongolia" },
  { value: "MNT", label: "Montserrat" },
  { value: "MA", label: "Morocco" },
  { value: "BUR", label: "Myanmar" },
  { value: "NA", label: "N Antilles" },
  { value: "NAU", label: "Nauru" },
  { value: "NZ", label: "NewZealand" },
  { value: "NIC", label: "Nicaragua" },
  { value: "RN", label: "Niger" },
  { value: "WAN", label: "Nigeria" },
  { value: "MRN", label: "Northern Mariana Islands" },
  { value: "N", label: "Norway" },
  { value: "OMN", label: "Oman" },
  { value: "OTH", label: "Others" },
  { value: "PK", label: "Pakistan" },
  { value: "PA", label: "Panama" },
  { value: "PY", label: "Paraguay" },
  { value: "PE", label: "Peru" },
  { value: "PHL", label: "Philippine" },
  { value: "PL", label: "Poland" },
  { value: "P", label: "Portugal" },
  { value: "QTR", label: "Qatar" },
  { value: "RO", label: "Romania" },
  { value: "RUS", label: "Russia" },
  { value: "RWA", label: "Rwanda" },
  { value: "ZA", label: "S Africa" },
  { value: "WS", label: "Samoa" },
  { value: "RSM", label: "San Marino" },
  { value: "SA", label: "Saudi Arabia" },
  { value: "SN", label: "Senegal" },
  { value: "SY", label: "Seychelles" },
  { value: "WAL", label: "SierraLeon" },
  { value: "SK", label: "Slovakia" },
  { value: "SLN", label: "Slovenia" },
  { value: "SP", label: "Somalia" },
  { value: "E", label: "Spain" },
  { value: "CL", label: "Sri Lanka" },
  { value: "SKN", label: "St. Kitts & Nevis" },
  { value: "SLU", label: "St. Lucia" },
  { value: "SMA", label: "St. Maarten" },
  { value: "SVN", label: "St. Vincent & The Grenadines" },
  { value: "SME", label: "Surinam" },
  { value: "SD", label: "Swaziland" },
  { value: "S", label: "Sweden" },
  { value: "CH", label: "Switz'land" },
  { value: "SYR", label: "Syria" },
  { value: "TT", label: "T & Tobago" },
  { value: "TAI", label: "Taiwan" },
  { value: "EAT", label: "Tanzania" },
  { value: "THA", label: "Thailand" },
  { value: "TG", label: "Togo" },
  { value: "TN", label: "Tunisia" },
  { value: "TMN", label: "Turk'stan" },
  { value: "TR", label: "Turkey" },
  { value: "CAI", label: "Turks & Caicos Islands" },
  { value: "VAE", label: "UAEmirates" },
  { value: "EAU", label: "Uganda" },
  { value: "UA", label: "Ukraine" },
  { value: "UK", label: "United Kingdom" },
  { value: "ROU", label: "Uruguay" },
  { value: "USA", label: "USA" },
  { value: "VNT", label: "Vanuatu" },
  { value: "V", label: "Vatican" },
  { value: "YV", label: "Venezuela" },
  { value: "VN", label: "Vietnam" },
  { value: "001", label: "Worldwide" },
  { value: "ADN", label: "Yemen" },
  { value: "YU", label: "Yugoslavia" },
  { value: "ZRE", label: "Za‹re" },
  { value: "Z", label: "Zambia" },
  { value: "ZW", label: "Zimbabwe" }
];
