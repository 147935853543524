import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import { PopupboxManager, PopupboxContainer } from "react-popupbox";
import YouTube from "react-youtube";
import css from "react-popupbox/dist/react-popupbox.css";
import assets from "../../../assets";

const { introImages, ProductInfo } = assets;

class WatchVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      youTubeSession: null
    };
  }

  pauseVideo = () => {
    const { youTubeSession } = this.state;
    if (youTubeSession) {
      youTubeSession.pauseVideo();
    }
  };

  openLightBox = () => {
    const opts = {
      playerVars: {
        modestbranding: 1,
        rel: 0
        // autoplay: 1
      }
    };
    const content = <YouTube videoId={this.props.youTubeID} opts={opts} onReady={this.onReady} />;
    PopupboxManager.open({ content });
  };

  onReady = ({ target }) => {
    this.setState({ youTubeSession: target });
  };

  render() {
    const { classes, textAlign } = this.props;
    return (
      <div>
        <div
          className={classes.videoHolder}
          onClick={this.openLightBox}
          style={{ textAlign: textAlign === undefined ? "center" : textAlign }}
        >
          <img alt="Intro icon" className={classes.videoIcon} src={introImages.videoIcon} />
          <span className={classes.red}>&nbsp;Watch Video</span>
        </div>
        <PopupboxContainer className={classes.videoWrapper} onClosed={this.pauseVideo} />
      </div>
    );
  }
}

const styles = theme => ({
  videoHolder: {
    fontSize: "12px",
    cursor: "pointer"
  },
  videoWrapper: {
    backgroundColor: "rgba(0,0,0,0)",
    padding: 0
  },
  videoIcon: {
    verticalAlign: "middle",
    marginRight: "7px"
  },
  red: {
    color: `${theme.colors.primary.red}`
  }
});

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(WatchVideo));
