import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import Typography from "@mui/material/Typography";
import PDFResource from "../utils/PDFResource";
import MobileDetect from "mobile-detect";
import classNames from "classnames";
import bowser from "bowser";
import Enums from "../utils/Enums";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.mjs`;
const mobileDetect = new MobileDetect(window.navigator.userAgent);

class PDFViewerBase64 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pages: undefined,
      zoom: 1
    };
    this.route = this.props.route;
  }

  onDocumentComplete = ({ numPages }) => {
    this.setState({ page: 1, pages: numPages, zoom: 1 });
  };

  onPageComplete = page => {
    this.setState({ page: page });
  };

  handlePreviousPage = () => {
    if (this.state.page > 1) this.setState({ page: this.state.page - 1 });
  };

  handleNextPage = () => {
    if (this.state.page < this.state.pages) this.setState({ page: this.state.page + 1 });
  };

  handleZoomIn = () => {
    if (this.state.zoom < 1.5) this.setState({ zoom: this.state.zoom + 0.1 });
  };

  handleZoomOut = () => {
    if (this.state.zoom > 1) this.setState({ zoom: this.state.zoom - 0.1 });
  };

  downloadPDF = async () => {
    if ((window.navigator && window.navigator.msSaveOrOpenBlob) || bowser.msie) {
      // We are running on IE
      let getPDFBlob = await PDFResource.downloadPDFAsBlob(this.props.pdf);
      if (this.props.fileName) {
        window.navigator.msSaveOrOpenBlob(getPDFBlob.data, this.props.fileName);
      } else {
        window.navigator.msSaveOrOpenBlob(getPDFBlob.data);
      }
    } else {
      this.downloadPDFRef.click();
    }
  };

  render() {
    const { classes, pdf, pdfContentBase64, isPDF = false } = this.props;
    let type;
    if (pdf)
      if (isPDF) {
        type = Enums.FILE_EXTENSION.PDF;
      } else {
        type = pdf.includes(Enums.FILE_EXTENSION.PDF) ? Enums.FILE_EXTENSION.PDF : Enums.FILE_EXTENSION.OTHERS;
      }
    return (
      <Modal className={classes.pdfViewModal} open={this.props.open} onClose={this.props.handleClose}>
        <div className={classNames(classes.maxWidth, classes.maxHeight)}>
          <Grid className={classes.pdfHolder} container justifyContent="center" spacing={"0px"} tabIndex={-1}>
            {mobileDetect.mobile() === null && <Grid item xs onClick={this.props.handleClose} />}
            <Grid className={classNames(classes.pdfViewHolder)} item xs>
              {this.props.pdf !== undefined &&
                (type === Enums.FILE_EXTENSION.PDF ? (
                  <Document
                    file={`data:application/pdf;base64,${pdfContentBase64}`}
                    onLoadSuccess={this.onDocumentComplete}
                    style={{
                      padding: "50px 0"
                    }}
                  >
                    <Page pageNumber={this.state.page} scale={this.state.zoom} />
                  </Document>
                ) : (
                  <Typography className={classes.temp}>
                    {/* {pdf.split("/").slice(-1)[0]} */}
                    {this.props.fileName}
                    <br />
                    <span className={classes.subTemp}>Preview Unavailable</span>
                  </Typography>
                ))}
              <a onClick={this.props.handleClose} className={classes.closeAction}>
                <Fab>
                  <i className="material-icons">close</i>
                </Fab>
              </a>
              <Fab className={classes.dlAction} onClick={this.downloadPDF}>
                <i className="material-icons">file_download</i>
              </Fab>

              {this.props.fileName ? (
                <a
                  download={this.props.fileName}
                  href={this.props.pdf}
                  className={classes.hide}
                  ref={ref => {
                    this.downloadPDFRef = ref;
                  }}
                />
              ) : (
                <a
                  download
                  href={this.props.pdf}
                  className={classes.hide}
                  ref={ref => {
                    this.downloadPDFRef = ref;
                  }}
                />
              )}
            </Grid>
            {mobileDetect.mobile() === null && <Grid item xs onClick={this.props.handleClose} />}
          </Grid>
          {type === Enums.FILE_EXTENSION.PDF && (
            <Grid className={classes.pdfActionsHolder} container justifyContent="center" spacing={"16px"}>
              <Grid item xs={2}>
                <Fab onClick={this.handlePreviousPage} disabled={this.state.page === 1} className={classes.prevAction}>
                  <i className="material-icons">chevron_left</i>
                </Fab>
              </Grid>
              <Grid item xs={2}>
                <Fab
                  onClick={this.handleNextPage}
                  disabled={this.state.page === this.state.pages}
                  className={classes.nextAction}
                >
                  <i className="material-icons">chevron_right</i>
                </Fab>
              </Grid>
              <Grid className={classes.pdfZoomHolder} direction="column" container spacing={"24px"}>
                <Grid item>
                  <Fab className={classes.zoomIn} onClick={this.handleZoomIn} disabled={this.state.zoom >= 1.5}>
                    <i className="material-icons">zoom_in</i>
                  </Fab>
                </Grid>
                <Grid item>
                  <Fab className={classes.zoomOut} onClick={this.handleZoomOut} disabled={this.state.zoom <= 1}>
                    <i className="material-icons">zoom_out</i>
                  </Fab>
                </Grid>
              </Grid>
            </Grid>
          )}
        </div>
      </Modal>
    );
  }
}

const styles = theme => ({
  root: {},
  maxWidth: {
    width: "100%"
  },
  maxHeight: {
    height: "100%"
  },
  heading: {
    paddingTop: 30,
    fontSize: 28,
    color: theme.colors.blackScale.black100
  },
  content: {
    padding: "10px 40px"
  },
  tableHead: {
    backgroundColor: theme.colors.blackScale.black100,
    fontSize: 14,
    padding: 20,
    color: theme.palette.common.white
  },
  tableRowDoc: {
    color: theme.colors.blackScale.black70,
    backgroundColor: "white",
    height: "30px"
  },
  tableCellDoc: {
    padding: 0,
    paddingLeft: 25
  },
  clearCellStyle: {
    padding: 0,
    paddingRight: "0!important",
    border: 0
  },
  pdfViewModal: {
    overflowY: "scroll"
  },
  dlHolder: {
    textAlign: "right"
  },
  dlIcon: {
    "&:hover": {
      color: theme.colors.blackScale.black80,
      cursor: "pointer"
    },
    color: theme.colors.blackScale.black70
  },
  dlCell: {
    padding: 0
  },
  pdfHolder: {
    height: "100%"
  },
  pdfViewHolder: {
    margin: "20px",
    position: "relative",
    display: "flex",
    flexBasis: "100%",
    "& div": {
      display: "flex",
      justifyContent: "center"
    },
    justifyContent: "center"
  },
  pdfActionsHolder: {
    zIndex: "1000",
    position: "fixed",
    bottom: 20
  },
  prevAction: {
    float: "right",
    opacity: 0.3,
    "&:hover": {
      opacity: 1
    }
  },
  nextAction: {
    float: "left",
    opacity: 0.3,
    "&:hover": {
      opacity: 1
    }
  },
  closeAction: {
    textDecoration: "none",
    position: "fixed",
    top: 20,
    left: 20,
    [theme.breakpoints.down("sm")]: {
      opacity: 0.3,
      "&:hover": {
        opacity: 1
      }
    }
  },
  dlAction: {
    position: "fixed",
    top: 20,
    right: 20,
    [theme.breakpoints.down("sm")]: {
      opacity: 0.3,
      "&:hover": {
        opacity: 1
      }
    }
  },
  pdfZoomHolder: {
    position: "fixed",
    bottom: 20,
    right: 0,
    maxWidth: 100
  },
  zoomIn: {
    float: "right",
    marginRight: 20
  },
  zoomOut: {
    float: "right",
    marginRight: 20
  },
  temp: {
    color: "white",
    textAlign: "center"
  },
  subTemp: {
    color: theme.colors.blackScale.black50,
    fontSize: "1.1em"
  },
  hide: {
    display: "none"
  }
});

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(PDFViewerBase64));
