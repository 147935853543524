import React from "react";
import { Box, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, Radio, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const RenewalSubmissionListingTable = ({ renewalSubmissions }) => {
  return (
    <>
      <Box
        sx={{
          overflow: "hidden",
          backgroundColor: "white",
          padding: "40px 30px 35px 30px",
          boxShadow: "-2px -2px 20px 0 rgba(0,0,0,0.1)",
          marginBottom: "80px",
          marginTop: "20px"
        }}
      >
        <Typography sx={{ fontSize: "18px", padding: "0px 0px 20px 0px", fontWeight: 500 }}>
          Submission History
        </Typography>
        {renewalSubmissions.length === 0 && (
          <Typography sx={{ fontSize: "14px", padding: "0px 0px 20px 0px", fontWeight: 500 }}>
            There are no records at the moment
          </Typography>
        )}
        {renewalSubmissions.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontSize: "1rem", color: "#7f7f7f" }}>Policy Number</TableCell>
                <TableCell style={{ fontSize: "1rem", color: "#7f7f7f" }}>Company Name</TableCell>
                <TableCell style={{ fontSize: "1rem", color: "#7f7f7f" }}>Policy Expiry Date</TableCell>
                <TableCell style={{ fontSize: "1rem", color: "#7f7f7f", display: "flex", alignItems: "center" }}>
                  Renewal Status
                  <Tooltip
                    title={
                      "The status displayed below reflects the progress of renewal case submission. For actual policy status, kindly refer to Client & Policy Information page."
                    }
                  >
                    <InfoOutlinedIcon style={{ width: "20px", margin: "0px 0px 0px 5px" }} />
                  </Tooltip>
                </TableCell>
                <TableCell style={{ fontSize: "1rem", color: "#7f7f7f" }}>Latest Updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renewalSubmissions.map((renewal, index) => (
                <TableRow key={index}>
                  <TableCell style={{ fontSize: "1rem" }}>{renewal.policy_no}</TableCell>
                  <TableCell style={{ fontSize: "1rem" }}>{renewal.company_name}</TableCell>
                  <TableCell style={{ fontSize: "1rem" }}>{renewal.expiry_date}</TableCell>
                  <TableCell style={{ fontSize: "1rem" }}>{renewal.status}</TableCell>
                  <TableCell style={{ fontSize: "1rem" }}>{renewal.updated}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>
    </>
  );
};

export default RenewalSubmissionListingTable;
