import { quoteActions } from "./quote.action";
import { productActions } from "./product.action";
import { employeeActions } from "./employee.action";
import { companyActions } from "./company.action";
import { documentActions } from "./document.action";

export const combinedActions = {
  loadQuoteDetail
};

function loadQuoteDetail(quoteId) {
  return async dispatch => {
    try {
      const response = await dispatch(quoteActions.get(quoteId));
      const { data } = response.value;
      await dispatch(productActions.loadConfigByCodeAndVersion(data.product_code, data.product_version));

      await Promise.all([
        dispatch(employeeActions.validateEmployeeData(data.stage)),
        dispatch(companyActions.getCompanyDetail(quoteId)),
        dispatch(documentActions.getFiles(quoteId))
      ]);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}
