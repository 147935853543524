import * as actionTypes from "./types";
import PolicyService from "../services/policy.service";

export const policyDetailsActions = {
  setCurrentPolicyNo,
  getPolicyDetails,
  removePolicyDetails,
  getPolicyRelatedDocumentList
};

function setCurrentPolicyNo(payload) {
  return dispatch => {
    dispatch({ type: actionTypes.POLICY_DETAILS_SET_CURRENT_POLICY_NO, payload: payload });
  };
}

function getPolicyDetails(policyNo) {
  return dispatch => {
    dispatch({ type: actionTypes.APP_SET_LOADING_STATE, payload: true });

    PolicyService.getFCPolicyDetails(policyNo).then(
      response => {
        dispatch({ type: actionTypes.APP_SET_LOADING_STATE, payload: false });
        if (response.success) {
          dispatch({
            type: actionTypes.POLICY_DETAILS_GET_POLICY,
            payload: response.data
          });
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch({ type: actionTypes.APP_SET_LOADING_STATE, payload: false });
        return Promise.reject(error);
      }
    );
  };
}

function removePolicyDetails() {
  return dispatch => {
    dispatch({ type: actionTypes.POLICY_DETAILS_REMOVE_POLICY });
  };
}

function getPolicyRelatedDocumentList(policyNo) {
  return dispatch => {
    dispatch({ type: actionTypes.APP_SET_LOADING_STATE, payload: true });

    PolicyService.getPolicyRelatedDocumentList(policyNo).then(
      response => {
        dispatch({ type: actionTypes.APP_SET_LOADING_STATE, payload: false });
        if (response.success) {
          dispatch({
            type: actionTypes.POLICY_GET_ALL_RELATED_DOCUMENTS,
            payload: response.data
          });
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch({ type: actionTypes.APP_SET_LOADING_STATE, payload: false });
        return Promise.reject(error);
      }
    );
  };
}
