import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import { injectIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import { bindActionCreators } from "redux";
import Categories from "./Categories.1";
import { quoteActions } from "../../../../../../actions";
import { companyActions } from "../../../../../../actions";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Enums from "../../../../../../utils/Enums";
import Util from "../../../../../../utils/Util";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";

const existing_policy_no_mandatory_text = "Please enter existing policy number";

class SelectPlans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      readOnly: false,
      business_type: Enums.BUSINESS_TYPE.NEW,
      existing_policy_no: "",
      existing_policy_no_error_text: "",
      init: true,
      errorCompanyName: false,
      errorCompanyNameText: ""
    };

    this.businessType = [
      {
        id: Enums.BUSINESS_TYPE.NEW,
        name: Enums.BUSINESS_TYPE.NEW_BUSINESS
      },
      {
        id: Enums.BUSINESS_TYPE.CONVERSION,
        name: Enums.BUSINESS_TYPE.CONVERSION_BUSINESS
      }
    ];

    this.emptyEstimate = {
      name: null,
      noOfEmployee: null,
      averageAge: null
    };

    this.emptyCategory = {
      name: null,
      enableDependent: true,
      expandPanel: true,
      plansConfiguration: props.productPlans.map(p => {
        if (Util.isGFWM(p.code)) {
          return {
            code: p.code,
            tier: null,
            enableDependent: false,
            wwc: false,
            wpec: false,
            wwcNwpec: false
          };
        }
        return {
          code: p.code,
          tier: null,
          enableDependent: false
        };
      })
    };
  }

  setStateFromProps = props => {
    this.setState(
      {
        name: props.quote.name || "",
        readOnly: this.props.quote.readOnly || props.document.hasSignedProposal,
        business_type: props.quote.business_type || Enums.BUSINESS_TYPE.NEW,
        existing_policy_no: props.quote.existing_policy_no || ""
      },
      () => {
        if (this.state.business_type === Enums.BUSINESS_TYPE.CONVERSION) {
          this.validateExistingPolicyNo(this.state.existing_policy_no);
        }
      }
    );
  };

  componentWillMount() {
    this.setStateFromProps(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.quote.id === null) {
      this.handleEdits();
    }

    if (this.state.init) {
      this.setState({ init: false }, () => {
        if (Util.isEmpty(nextProps.estimates)) {
          this.handleAddCategory();
        }
      });
    }

    this.setStateFromProps(nextProps);
  }

  validateName(value) {
    if (!this.props.document.hasSignedProposal) {
      const reg = new RegExp(Util.nbstpFieldRegexCompanyname);
      const regexResult = reg.test(value);
      this.setState({
        errorCompanyName: !regexResult || value.trim() === "",
        errorCompanyNameText:
          !regexResult && value ? "Invalid characters in name field" : value.trim() === "" ? "Field is required" : ""
      });
    }
  }

  componentDidMount() {
    if (this.state?.name) {
      const value = this.state?.name;
      this.validateName(value);
    }
  }

  handleInputChange = event => {
    let target = event.target;
    let value = target.value;

    this.validateName(value);

    if (this.state.business_type === Enums.BUSINESS_TYPE.CONVERSION) {
      this.props.company.details.name = value;
    }
    this.setState({ name: value }, () => {
      this.props.setName(value);
      this.props.setCompanyName({ key: "name", value: value });
      this.handleEdits();
    });
  };

  handleExistingPolicyNoChange = event => {
    let target = event.target;
    let value = target.value;
    this.setState({ existing_policy_no: value }, () => {
      this.props.setExistingPolicyNo(value);
      this.handleEdits();
      this.validateExistingPolicyNo(value);
    });
  };

  handleEdits = () => {
    this.props.handleHasEdits();
  };

  handleBusinessTypeChange = (event, value) => {
    this.setState({ business_type: value }, () => {
      this.props.setBusinessType(value);
      this.handleEdits();
      // this.resetExistingPolicyNo();

      // if (value === Enums.BUSINESS_TYPE.CONVERSION) {
      //   this.validateExistingPolicyNo();
      // }
    });
  };

  resetExistingPolicyNo = () => {
    this.setState({ existing_policy_no: "", existing_policy_no_error_text: "" }, () => {
      this.props.setExistingPolicyNo("");
    });
  };

  validateExistingPolicyNo = value => {
    if (Util.isEmpty(value)) {
      this.setState({ existing_policy_no_error_text: existing_policy_no_mandatory_text });
    } else {
      this.setState({
        existing_policy_no_error_text: ""
        // existing_policy_no : value
      });
    }
  };

  handleAddCategory = () => {
    const { estimates, categories } = this.props;
    this.props.setEstimates(estimates.concat(Util.clone(this.emptyEstimate)));

    categories.map(category => {
      category.expandPanel = false;
    });

    this.props.setCategories(categories.concat(Util.clone(this.emptyCategory)));
    this.handleEdits();
  };

  render() {
    const { classes, quote, user, company, intl, document } = this.props;
    const { business_type, existing_policy_no } = this.state;
    let { name } = this.state;
    const brand = user.auth.pcode;
    business_type === Enums.BUSINESS_TYPE.CONVERSION && company.company !== undefined
      ? (name = company.company.name)
      : (name = name);
    return (
      <div className={classes.root}>
        <div>
          <Paper>
            <Typography className={classes.pruTitle}>
              <span className={classes.pruTitle1}>PRU</span>
              <span className={classes.pruTitle2}>Treasure Flexi II</span>
            </Typography>
            <Typography className={classes.financeInfo}>
              Your Financial Consultant will go through and explain the features you have selected using the Product
              Summaries. The Product Summaries will contain all the available features for the Product but only those
              features that you have selected will apply to your insurance application.
            </Typography>
            <br />
            <Typography className={classes.financeInfo}>
              {`Medical underwriting is required for GTL/GCCA Plan 1 and all Plans from GPCG.\nEmployee/Dependent Health Declaration Form (HDF) is required for submission to Medical Underwriter for above features.`}
            </Typography>

            <Grid container spacing={"16px"}>
              <Grid item xs>
                <Typography className={classes.companyLabel}>Company Name</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="name"
                  name="name"
                  className={classes.companyNameField}
                  disabled={this.state.readOnly}
                  value={name}
                  onChange={this.handleInputChange}
                  autoComplete="[off]"
                  placeholder="Fill in here"
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    maxLength: 100
                  }}
                  helperText={this.state.errorCompanyNameText}
                  error={this.state.errorCompanyName}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  disabled={quote.readOnly || document.hasSignedProposal || this.props.categories?.length >= 15}
                  variant="flat"
                  color="primary"
                  onClick={this.handleAddCategory}
                  className={classes.buttonAddCategory}
                >
                  <b>Add Employee Category</b>
                  <Icon className={classes.leftIcon}>add</Icon>
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </div>

        <Paper className={classes.hintTextRoot}>
          <Typography className={classes.defineText}>
            Classify the employees into categories and select the benefits that suit each group.
          </Typography>
        </Paper>

        <Categories handleEdits={this.handleEdits} />

        <br />
        <br />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%"
  },
  paper: {
    position: "relative"
  },
  companyLabel: {
    backgroundColor: "white",
    paddingLeft: 15,
    paddingTop: 15,
    fontWeight: "bold"
  },
  financeInfo: {
    backgroundColor: "white",
    whiteSpace: "pre-line",
    paddingLeft: 15,
    paddingRight: 15
  },
  companyNameField: {
    width: "90%",
    paddingLeft: 15,
    paddingTop: 10,
    paddingBottom: 10
    // [theme.breakpoints.up("sm")]: {
    //   width: "35%"
    // },
    // [theme.breakpoints.down("sm")]: {
    //   width: "70%"
    // }
  },
  dividerSm: {
    marginTop: 15,
    marginBottom: 15
  },
  hintTextRoot: {
    paddingLeft: 5,
    marginTop: 25,
    marginBottom: 20,
    backgroundColor: "#3BB1D5"
  },
  defineText: {
    backgroundColor: "white",
    padding: 15
  },
  businessTypeParentDiv: {
    marginTop: 12,
    lineHeight: 1,
    display: "flex"
    //color: "rgba(0, 0, 0, 0.54)"
  },
  businessTypeDiv: {
    marginRight: 25,
    color: "rgba(0, 0, 0, 0.54)"
  },
  businessTypeRadioGroup: {
    display: "inline-block"
  },
  businessTypeLabel: {
    color: "rgba(0, 0, 0, 0.54)"
  },
  pruTitle: {
    fontSize: "20px",
    fontWeight: 500,
    paddingBottom: 15,
    paddingTop: 20
  },
  pruTitle1: {
    paddingLeft: 15,
    paddingTop: 15,
    color: theme.colors.primary.red
  },
  pruTitle2: {
    color: theme.colors.primary.darkGrey
  }
});

function mapStateToProps(state) {
  let product = state.products[state.quote.product_code];
  return {
    quote: state.quote,
    document: state.document,
    user: state.user,
    company: state.company,
    categories: state.quote.data.categories,
    estimates: state.quote.data.estimates,
    productPlans: product && product.config.plans
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setName: (...payload) => dispatch(quoteActions.setName(...payload)),
    setCompanyName: (...payload) => dispatch(companyActions.setCompanyFormDetails(...payload)),
    setExistingPolicyNo: (...payload) => dispatch(quoteActions.setExistingPolicyNo(...payload)),
    setBusinessType: (...payload) => dispatch(quoteActions.setBusinessType(...payload)),
    ...bindActionCreators(quoteActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(SelectPlans)));
