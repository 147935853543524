import { APIResource, APIResponse } from "../../utils/APIResource";

export default class ClaimExpenseService {
  // below are PCE claim expenses
  static getClaimTypes(policyNo) {
    const url = `/v1/claim/expense/types/${policyNo}`;
    return APIResource.get(url)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        const { response } = error;
        return Promise.reject((response && response.data) || "System error, please try again.");
      });
  }

  static getClaimExpenses(policyNo, companyId, filter) {
    let url = `/v1/claim/expense/${policyNo}/${companyId}?`;
    for (var key in filter) {
      if (filter.hasOwnProperty(key)) {
        url += `&${key}=${filter[key]}`;
      }
    }
    return APIResource.get(url)
      .then(resp => {
        const response = new APIResponse(resp);
        return Promise.resolve({ ...response, filter });
      })
      .catch(error => {
        const { response } = error;
        return Promise.reject((response && response.data) || "System error, please try again.");
      });
  }

  static exportClaimExpenses(policyNo, companyId, filter) {
    let url = `/v1/claim/expense/export/${policyNo}/${companyId}?`;
    for (var key in filter) {
      if (filter.hasOwnProperty(key)) {
        url += `&${key}=${filter[key]}`;
      }
    }
    return APIResource.get(url, { responseType: "blob" })
      .then(resp => {
        const response = new APIResponse(resp);
        return Promise.resolve({ ...response, filename: resp.headers.filename });
      })
      .catch(error => {
        const { response } = error;
        return Promise.reject((response && response.data) || "System error, please try again.");
      });
  }
}
