import React, { Component } from "react";
import { withRouter } from "react-router";
import { withStyles } from "@mui/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";

const MAIN_PAGE = "/auth/claimAndPolicyListing";

class BreadCrumbs extends Component {
  constructor(props) {
    super(props);
  }
  getVisitedRoute = () => {
    const visitedRoute = [];
    const currentRoute = this.props.location.pathname;
    if (this.props.currentRoutes && this.props.currentRoutes.routes.length > 0) {
      for (const route of this.props.currentRoutes.routes) {
        visitedRoute.push(route);
        if (route.path === currentRoute) {
          break;
        }
      }
      return visitedRoute;
    }
  };

  render() {
    const routes = this.getVisitedRoute();
    const { classes, pageLabel } = this.props;
    const { company } = this.props.location.state || {};
    return (
      <div role="presentation" className={classes.container}>
        <Breadcrumbs aria-label="breadcrumb" separator=">">
          <Link to={MAIN_PAGE} className={classes.link}>
            PRUworks
          </Link>
          {routes &&
            routes.map(route => {
              const isActive = route.path === this.props.location.pathname;
              return route.name.toLowerCase() === pageLabel ? (
                <Link to={route.path} className={isActive ? classes.linkActive : classes.link} key={route.path}>
                  {company}'s {route.name}
                </Link>
              ) : (
                <Link to={route.path} className={isActive ? classes.linkActive : classes.link} key={route.path}>
                  {route.name}
                </Link>
              );
            })}
        </Breadcrumbs>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    paddingLeft: "40px"
  },
  link: {
    textDecoration: "none",
    color: theme.colors.primary.darkGrey
  },
  linkActive: {
    textDecoration: "none",
    color: theme.colors.primary.grey,
    fontWeight: 600
  }
});

export default withRouter(withStyles(styles, { withTheme: true })(BreadCrumbs));
