import React from "react";
import { withStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const TncPage = ({ classes }) => {
  return (
    <div>
      <Paper className={classes.root} elevation={4}>
        <Typography variant="headline" component="h3">
          Terms of Use and Privacy Notice
        </Typography>
        <Typography variant="caption" component="h6" className={classes.spacing}>
          Updated as at 18 June 2018
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          Please read the following information carefully.
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          Prudential Assurance Company Singapore (Pte) Ltd (“Prudential”) is an indirect subsidiary of Prudential plc, a
          company incorporated in the United Kingdom. Neither Prudential nor Prudential plc is affiliated with
          Prudential Financial Inc, a company whose principal place of business is in the United States of America.
        </Typography>
        <hr />
        <Typography variant="title" component="h4" className={classes.spacing}>
          Terms of Use
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          Access to this website and its pages, and the products, information, services, information, data, graphics,
          links or other material or items described or contained herein (“Website”) is granted by Prudential subject to
          the following Terms and Conditions. Use of this Website constitutes your agreement to be bound by all such
          Terms and Conditions without limitation or qualification. Prudential may at any time revise these Terms and
          Conditions by updating this posting. You are bound by any such revisions and should therefore periodically
          visit this page to review the then current Terms and Conditions to which you are bound. IF YOU DO NOT AGREE TO
          THESE TERMS & CONDITIONS, PLEASE DO NOT USE OR ACCESS THIS WEBSITE.
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          This Website offers Prudential’s “PRUworks” solution for enterprises (“PRUworks”), which features links to the
          products and/or services of external third party service providers or vendors which are separate and
          independent from Prudential and its affiliates (the “Third Parties”). Prudential and its affiliates are not
          responsible for and are not in control of (i) the products and/or services of the Third Parties; (ii) the
          contents of the Third Parties’ respective websites; or (iii) the terms and conditions for use of the
          respective Third Parties’ websites and/or online services. For the avoidance of doubt, Prudential, its
          affiliates and its agents are not agents of any of the Third Parties.
        </Typography>
        <Typography variant="title" component="h4" className={classes.spacing}>
          Disclaimer
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          The Website, PRUworks and its contents are provided on a "as is" and "as available" basis. Prudential
          disclaims all warranties, express or implied, as to any matter whatsoever in relation to this Website,
          Pruworks and its contents, including but not limited to, all warranties of merchantability, fitness for a
          particular purpose or use, title and non-infringement of third party rights.
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          The contents of the Website and PRUworks may not reflect current or accurate information since it is subject
          to periodic updates. Prudential does not warrant the accuracy, adequacy or completeness of this Website,
          PRUworks and its contents and expressly disclaims liability for any errors or omissions. In addition,
          Prudential may, from time to time, at its absolute discretion change, amend, delete or replace the Website,
          PRUworks and its contents or any part thereof. You agree to independently ascertain the accuracy, adequacy and
          completeness of the information on the Website and PRUworks before utilising it.
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          For the avoidance of doubt, nothing in this Website or PRUworks is intended to supercede or amend the terms of
          a policy contract in effect between Prudential and any policyholder. Any information on this Website and
          PRUworks is subject to the terms of the applicable policy contract. No communication or transaction on this
          Website shall be construed as an admission of liability by Prudential.
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          No warranty is given that access to this Website and PRUworks will be uninterrupted or free from errors or
          that any identified defect will be corrected. Further, no warranty is given that this Website and PRUworks is
          free from any viruses or other malicious, destructive or corrupting code, agent, program or macros or that
          viruses or other harmful components will not be transmitted in connection with or arising from the use of this
          Website or PRUworks.
        </Typography>

        <Typography variant="title" component="h4" className={classes.spacing}>
          Specific Disclaimers for Third Parties
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          This Website may be linked to other websites which are not maintained by Prudential. Such links are provided
          for your convenience and reference only and the inclusion of any hyperlink to any other website is not an
          endorsement or verification by Prudential of such website, its contents or its sponsoring organization, and
          such website shall be accessed at your own risk.
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          For the avoidance of doubt, Prudential and its affiliates –
        </Typography>
        <Typography component="div" className={classes.pSpacing}>
          <ul>
            <li>
              shall not, in any way, be deemed to have endorsed any of the products and/or services provided by the
              Third Parties through their respective websites;
            </li>
            <li>
              shall not assume any liability or responsibility for the acts or omissions of the Third Parties,
              including, without limitation, any non-performance and/or defects of the Third Parties’ products or
              services;
            </li>
            <li>
              make no representations or warranties in relation to the completeness, accuracy, use, purchase,
              subscription or any such use of the products and/or services provided by the Third Parties through their
              respective websites; and
            </li>
            <li>
              make no representations or warranty in relation to the quality, defects, merchantability, performance,
              fitness for purpose or any other such aspects of the products and/or services provided by the Third
              Parties through their respective websites
            </li>
          </ul>
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          Before deciding to subscribe, purchase, or in any way use any of the applications, products or services
          offered by such Third Parties, please consider carefully whether such applications, products or services are
          suitable for you. If in doubt, please seek advice from your relevant advisers on the suitability of such
          applications, products or services.
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          If you subscribe, purchase or in any way use any of the applications, products or services offered by the
          Third Parties, the relevant Third Parties may impose terms and conditions for such subscription, purchase or
          use. Such terms and conditions may include terms relating to the protection of your personal data (please see
          the Section below on Your Personal Data).
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          Prudential, its affiliates and its agents shall not be liable for any loss or damage howsoever arising as a
          result of any person subscribing, purchasing or in any way using the applications, products or services
          offered by such Third Parties. Any dispute arising out of your use of the Third Parties’ applications,
          products and/or services shall be resolved directly between you and the relevant Third Party.
        </Typography>
        <Typography variant="title" component="h4" className={classes.spacing}>
          Electronic Signature
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          By using your User ID and log-in password to access and use this Website or PRUworks:
        </Typography>
        <Typography component="div" className={classes.pSpacing}>
          <ul>
            <li>
              You confirm that such usage constitutes your legally binding electronic signature for purposes of all your
              communications and transactions with us on this Website and PRUworks;
            </li>
            <li>
              You agree that Prudential may maintain electronic records of your communications and transactions on this
              Website or PRUworks and that such records shall be legally binding and may be used as electronic evidence
              in any dispute, to the fullest extent permitted by law; and
            </li>
            <li>
              You represent and warrant that you are a person duly authorized by either the policyholder or insured
              member to communicate and transact with Prudential on this Website and PRUworks.
            </li>
          </ul>
        </Typography>
        <Typography variant="title" component="h4" className={classes.spacing}>
          Exclusion of Liability
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          Prudential shall in no event or circumstances be liable for any damages of any character or any claim, demand,
          losses or expenses including without limitation, direct, indirect, special, consequential or punitive damages
          or economic loss arising from or in connection with the use of this Website, PRUworks and its contents,
          including without limitation:
        </Typography>
        <Typography component="div" className={classes.pSpacing}>
          <ul>
            <li>
              any access, use or the inability to access or use this Website or PRUworks, or reliance on the contents of
              this Website or PRUworks;
            </li>
            <li>
              any system, server or connection failure, error, omission, interruption, delay in transmission or computer
              virus;
            </li>
            <li>any use of or access to any other website linked to this Website or PRUworks; and/or</li>
            <li>
              any services, products, information, data, software or other material obtained from this Website or
              PRUworks or from any other website linked to this Website or PRUworks,
            </li>
          </ul>
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          howsoever caused, including negligence, even if Prudential or its employees or authorized representatives have
          been advised of the possibility of such damages, losses and/or expenses. This exclusion clause shall take
          effect to the fullest extent permitted by applicable law.
        </Typography>
        <Typography variant="title" component="h4" className={classes.spacing}>
          Governing Law & Jurisdiction
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          Any products or services offered through this Website and PRUworks by Prudential are to Singapore citizens,
          Singapore Permanent Residents or foreign nationals residing in Singapore. Nothing in this Website or PRUworks
          shall be construed as an offer to sell or as the solicitation to buy or the provision of any product or
          service outside of Singapore. In the event that the jurisdiction you are in imposes restrictions on or does
          not permit access to the contents of this Website and PRUworks, you shall forthwith comply with such
          restrictions or discontinue access (as the case may be) hereto.
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          By accessing this Website and PRUworks and/or using the services offered through this Website and PRUworks,
          you agree that the laws of Singapore shall govern such access to this Website and PRUworks and the provision
          of such services, and you agree to submit irrevocably to the exclusive jurisdiction of the courts of
          Singapore.
        </Typography>
        <Typography variant="title" component="h4" className={classes.spacing}>
          Copyright and Trademarks
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          You should assume that everything you see or read on this Website is under the copyright of Prudential and may
          not be copied, used or distributed in any way without the written permission of Prudential, except as provided
          in this Website. Prudential neither warrants nor represents that your use of the contents of this website will
          not infringe any rights of third parties not owned by or affiliated with Prudential.
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          Images of people or places displayed on this Website are either the property of Prudential, or used by
          Prudential with permission. The use of these images by you, or anyone else authorized by you, is prohibited
          unless specifically permitted elsewhere on this Website. Any unauthorized use of the images may violate
          copyright laws, trademark laws, the laws of privacy and publicity, and communications regulations and
          statutes.
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          All trademarks, service marks, trade names, logos and icons (collectively "Trademarks") displayed on this
          Website are registered and unregistered Trademarks of Prudential and others. Nothing contained in this Website
          should be construed as granting, by implication, estoppel, or otherwise, any licence or right to use any
          Trademark displayed on this Website without the written permission of Prudential or such third party that may
          own the Trademarks displayed on this Website. Your use of the Trademarks displayed on this Website, is
          strictly prohibited unless specifically elsewhere on this Website.
        </Typography>

        <Typography variant="headline" component="h3">
          Your Personal Data
        </Typography>
        <hr />
        <Typography component="p" className={classes.pSpacing}>
          Prudential and Prudential representatives each have a legal obligation to comply with the Personal Data
          Protection Act 2012 (“PDPA”) in relation to personal data which we hold about you. By continuing to use the
          website, you expressly authorize and consent to Prudential, its officers, employees, representatives and
          distribution partners, as well as third party service providers to collect, use, retain and/or disclose your
          personal data in accordance to the manner and for the purposes (where relevant) set out in our{" "}
          <a target="_blank" href="https://www.prudential.com.sg/privacy-notice">
            Privacy Notice
          </a>{" "}
          which includes, but is not limited to, arranging for call backs from Third Parties.
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          The personal data you provide to Prudential through PRUworks will not be disclosed to the Third Parties,
          unless you expressly give us your consent to do so. Once re-directed to the relevant Third Party’s website,
          the collection, use and disclosure of your personal data will be governed by the relevant Third Party’s data
          privacy terms and conditions. As a result, the relevant Third Party may need to collect a fresh set of
          personal data from you (which is separate from the set you had provided to Prudential through PRUworks).
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          If you have clarifications, corrections, issues, access or information requests about your data which
          Prudential holds, you may wish to contact us at:
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          Prudential Assurance Company
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          Enterprise Business
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          7 Straits View, #06-01 Marina One East Tower, Singapore 018936
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          If you wish to contact Prudential about a data protection concern, please write to The Data Privacy Officer at
          the address shown above or email to Data.Privacy@prudential.com.sg.
        </Typography>

        <Typography variant="title" component="h4" className={classes.spacing}>
          Confidentiality
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          Both Prudential and your Prudential representative treat all information about you securely and with care
          guided by our policies and practices.
        </Typography>
      </Paper>
    </div>
  );
};

const styles = theme => ({
  pSpacing: {
    margin: `${theme.spacing.unit}px 0`
  },
  root: {
    padding: 16,
    margin: theme.spacing.unit * 3
  },
  spacing: {
    padding: "5px 0",
    margin: `${theme.spacing.unit}px 0`
  }
});

export default withStyles(styles, { withTheme: true })(TncPage);
