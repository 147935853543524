import { useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";
import SimpleControlDialog from "../../../../../components/SimpleControlDialog";
import { renderTextField } from "../../../../../components/CustomReduxFormComponent";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core/styles";
import useOtp from "../../hooks/useOtp";
import { typographyRootStyle } from "../../types/typographyRootStyle";

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: "1rem",
    fontWeight: "500"
  }
}));

const OtpEmail = ({
  txId,
  show,
  description,
  closeHandler,
  handleSubmit,
  reset,
  submitHandler,
  submitError,
  submitErrorMessage,
  anotherWayHandler,
  closeButtonDisabled
}) => {
  const classes = useStyles();
  useEffect(() => {
    reset();
  }, [show, reset]);

  const submit = values => {
    console.log("values", values);
    submitHandler(values.otp);
  };

  const { otpState, sendOtp } = useOtp();

  console.log("otpState", otpState);

  useEffect(() => {
    if (show) {
      if (otpState?.previousOtpMethod !== "EMAIL") {
        console.log("SENDING EMAIL@@@");
        sendOtp("EMAIL", txId);
      }
    }
  }, [show]);

  const [isOtpValid, setIsOtpValid] = useState(false);

  const customInputHandler = event => {
    const otpValue = event.target.value;
    if (!otpValue) {
      setIsOtpValid(false);
    } else {
      setIsOtpValid(true);
    }
  };

  return (
    <SimpleControlDialog
      show={show}
      title="Enter OTP"
      description={description}
      onClose={closeHandler}
      closeHandler={anotherWayHandler}
      valid={isOtpValid}
      onSubmit={handleSubmit(submit)}
      okButtonText="Submit"
      closeButtonText="Try Another Way"
      showCloseButton={true}
      closeButtonDisabled={closeButtonDisabled}
    >
      {otpState?.errorEmail && (
        <div variant="caption" style={typographyRootStyle}>
          {otpState?.errorEmail}
        </div>
      )}
      {!otpState?.errorEmail && otpState?.sentChannel === "EMAIL" && (
        <Typography variant="body2" className={classes.title}>
          A OTP was just sent to your email address.
        </Typography>
      )}
      <Field
        name="otp"
        autoFocus
        margin="dense"
        component={renderTextField}
        autoComplete="off"
        label="OTP"
        type="text"
        onChange={customInputHandler}
      />
      {!isOtpValid && (
        <div variant="caption" style={typographyRootStyle}>
          {"Required"}
        </div>
      )}
      {submitError && (
        <div variant="caption" style={typographyRootStyle}>
          {submitErrorMessage}
        </div>
      )}
    </SimpleControlDialog>
  );
};

export default reduxForm({
  form: "otpForm" // give your form a unique name
})(OtpEmail);
