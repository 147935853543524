import React, { Component } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import classNames from "classnames";
import { DateRange as DateRangeIcon } from "@mui/icons-material";
import { ValidatorForm } from "react-material-ui-form-validator";
import DatePickerValidatorElement from "../../../../components/validation/DatePickerValidatorElement";
import moment from "moment/moment";

class TerminationEmployeeDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      terminationDate: null,
      errorMessage: ""
    };
  }

  componentWillMount() {
    const FORMAT_DATE = "DD/MM/YYYY";

    ValidatorForm.addValidationRule(`terminationDateValid`, value => {
      // termination date validation revoked
      let coverageStartDate = moment(this.props.selectedRecord.coverage_start_date, FORMAT_DATE);
      let dateHrSendInstruction = moment(moment(), FORMAT_DATE);
      let lastDateOfEmployment = moment(value, FORMAT_DATE);
      let diffInDays = moment.duration(dateHrSendInstruction.diff(lastDateOfEmployment)).asDays();
      if (lastDateOfEmployment.isSameOrAfter(coverageStartDate)) {
        if (diffInDays <= 31) {
          return true;
        } else {
          if (lastDateOfEmployment.isSameOrAfter(dateHrSendInstruction, "day")) {
            return true;
          } else {
            this.setState({
              errorMessage:
                "Late notification of last date of employment. Thus, last date of employment should be on " +
                dateHrSendInstruction.format(FORMAT_DATE) +
                " or later"
            });
            return false;
          }
        }
      } else {
        this.setState({ errorMessage: "Last date of employment cannot be earlier than coverage start date" });
        return false;
      }
    });
  }

  setTerminationDate = date => {
    this.setState({ terminationDate: date });
  };

  handleCompletedSubmit = () => {
    this.props.confirmHandler(moment(this.state.terminationDate).format("DD/MM/YYYY"));
  };

  handleButtonSubmit = () => {
    this.refs.form.submit();
  };

  render() {
    const { name, selectedRecord, open, closeHandler, icon, classes } = this.props;

    return (
      <Dialog
        open={open}
        onClose={closeHandler}
        aria-labelledby={`${name}-title`}
        aria-describedby={`${name}-description`}
      >
        <div className={classes.dialogTitleIcon}>{icon}</div>
        <DialogContent className={classes.dialogContent}>
          <ValidatorForm ref="form" onSubmit={this.handleCompletedSubmit}>
            <div>
              <DialogTitle>Remove Employee From Policy?</DialogTitle>
              <Typography variant="subheading" className={classes.padding}>
                Employee selected:
                <span className={classes.textHighlight}>{selectedRecord ? `${selectedRecord.full_name}` : ""}</span>
              </Typography>
            </div>
            <div className={classes.padding}>
              <DatePickerValidatorElement
                // style={{ width: "100%" }}
                fullWidth
                label={!this.state.terminationDate && "Please enter last date of employment"}
                name={"coverage_end_date"}
                autoComplete="[off]"
                clearable
                value={this.state.terminationDate}
                onChange={this.setTerminationDate}
                format="DD/MM/YYYY"
                animateYearScrolling={true}
                InputProps={{
                  endAdornment: <DateRangeIcon />
                }}
                validators={["required", `terminationDateValid`]}
                errorMessages={["", this.state.errorMessage]}
              />
            </div>
            <div className={classes.dialogNote}>
              <Typography variant="caption">
                <strong>Please note: </strong> The Employee and dependant(s) will be covered till last day of
                employment.
              </Typography>
            </div>
          </ValidatorForm>
        </DialogContent>
        <DialogActions className={classNames(classes.dialogAction, classes.centerContent)}>
          <Button onClick={closeHandler} className={classes.button} color="primary" autoFocus>
            Cancel
          </Button>
          <Button
            onClick={this.handleButtonSubmit}
            className={classes.button}
            color="primary"
            variant="raised"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = theme => ({
  dialogContent: {
    maxWidth: 420
  },
  centerContent: {
    display: "flex",
    justifyContent: "center"
  },
  button: {
    width: 130
  },
  dialogTitleIcon: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20
  },
  padding: {
    padding: "0px 20px"
  },
  dialogAction: {
    marginBottom: 20
  },
  dialogNote: {
    padding: 20
  },
  textHighlight: {
    color: "red"
  }
});

function mapStateToProps(state, prop) {
  return { policy: state.HR.coverage.policy };
}

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(TerminationEmployeeDialog));
