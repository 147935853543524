import { Field, reduxForm } from "redux-form";
import { renderTextField } from "../../components/CustomReduxFormComponent";
import React from "react";
import SimpleControlDialog from "../../components/SimpleControlDialog";

const typographyRootStyle = {
  color: "red",
  whiteSpace: "pre-line",
  textAlign: "center",
  margin: 0,
  fontFamily: "Pru Sans,Roboto,Helvetica,Arial,sans-serif",
  fontWeight: 400,
  fontSize: "0.75rem",
  lineHeight: "1.66"
};
const required = value => (value ? undefined : "Required");

class OtpDialog extends React.Component {
  componentWillReceiveProps(nextProps) {
    //reset form when open
    if (this.props.show !== nextProps.show) this.props.reset();
  }
  submit = values => {
    this.props.submitHandler(values.otp);
  };
  render() {
    return (
      <SimpleControlDialog
        show={this.props.show}
        title="Enter OTP"
        description={this.props.description}
        onClose={this.props.closeHandler}
        closeHandler={this.props.closeHandler}
        valid={this.props.valid}
        onSubmit={this.props.handleSubmit(this.submit)}
        okButtonText="Submit"
        closeButtonText="Cancel"
      >
        <Field
          name="otp"
          autoFocus
          margin="dense"
          component={renderTextField}
          autoComplete="[off]"
          label="OTP"
          type="text"
          onChange={this.props.inputHandler}
          validate={required}
        />
        {this.props.submitError && (
          <div variant="caption" style={typographyRootStyle}>
            {this.props.submitErrorMessage}
          </div>
        )}
      </SimpleControlDialog>
    );
  }
}
export default reduxForm({
  form: "otp-form"
})(OtpDialog);
