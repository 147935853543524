import Enums from "../../../../../utils/Enums";

const GFWMSumAssuredEmployee = {
  headers: [
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "20%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    },
    {
      title: `Plan 6`,
      tier: 6
    },
    {
      title: `Plan 7`,
      tier: 7
    },
    {
      title: `Plan 8`,
      tier: 8
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Limit Type<br></strong>`
        },
        {
          data: `Per Policy Year Limit`
        },
        {
          data: `Per Policy Year Limit`
        },
        {
          data: `Per Policy Year Limit`
        },
        {
          data: `Per Policy Year Limit`
        },
        {
          data: `Per Disability Limit`
        },
        {
          data: `Per Disability Limit`
        },
        {
          data: `Per Disability Limit`
        },
        {
          data: `Per Disability Limit`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>Benefit Limit</strong>`
        },
        {
          data: `S$ 80,000`
        },
        {
          data: `S$ 60,000`
        },
        {
          data: `S$ 80,000`
        },
        {
          data: `S$ 60,000`
        },
        {
          data: `S$ 80,000`
        },
        {
          data: `S$ 60,000`
        },
        {
          data: `S$ 80,000`
        },
        {
          data: `S$ 60,000`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>First Dollar Cover by Insurer</strong>`
        },
        {
          data: `Up to S$80,000`
        },
        {
          data: `Up to S$60,000`
        },
        {
          data: `First S$15,000`
        },
        {
          data: `First S$15,000`
        },
        {
          data: `Up to S$80,000`
        },
        {
          data: `Up to S$60,000`
        },
        {
          data: `First S$15,000`
        },
        {
          data: `First S$15,000`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>Co-payment</strong>`,
          info: `<i>after first S$15,000</i>`
        },
        {
          data: `N.A.`
        },
        {
          data: `N.A.`
        },
        {
          data: `25%`
        },
        {
          data: `25%`
        },
        {
          data: `N.A.`
        },
        {
          data: `N.A.`
        },
        {
          data: `25%`
        },
        {
          data: `25%`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>Hospital / Ward Type</strong>`
        },
        {
          data: `4-Bedded Government Restructured Hospital`
        },
        {
          data: `4-Bedded Government Restructured Hospital`
        },
        {
          data: `4-Bedded Government Restructured Hospital`
        },
        {
          data: `4-Bedded Government Restructured Hospital`
        },
        {
          data: `4-Bedded Government Restructured Hospital`
        },
        {
          data: `4-Bedded Government Restructured Hospital`
        },
        {
          data: `4-Bedded Government Restructured Hospital`
        },
        {
          data: `4-Bedded Government Restructured Hospital`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong> (1) Daily Room and Board</strong>`,
          info: `<i>Maximum of 120 days</i>`
        },
        {
          data: `As Charged up to Per Policy Year limit of S$80,000 for item 1 to 7`,
          attributes: {
            rowspan: "7"
          }
        },
        {
          data: `As Charged up to Per Policy Year limit of S$60,000 for item 1 to 7`,
          attributes: {
            rowspan: "7"
          }
        },
        {
          data: `As Charged up to Per Policy Year limit of S$80,000 for item 1 to 7`,
          attributes: {
            rowspan: "7"
          }
        },
        {
          data: `As Charged up to Per Policy Year limit of S$60,000 for item 1 to 7`,
          attributes: {
            rowspan: "7"
          }
        },
        {
          data: `As Charged up to Per Disability limit of S$80,000 for item 1 to 7`,
          attributes: {
            rowspan: "7"
          }
        },
        {
          data: `As Charged up to Per Disability limit of S$60,000 for item 1 to 7`,
          attributes: {
            rowspan: "7"
          }
        },
        {
          data: `As Charged up to Per Disability limit of S$80,000 for item 1 to 7`,
          attributes: {
            rowspan: "7"
          }
        },
        {
          data: `As Charged up to Per Disability limit of S$60,000 for item 1 to 7`,
          attributes: {
            rowspan: "7"
          }
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong> (2) Intensive Care Unit/ <br> High Dependency Ward</strong>`,
          info: ` <i>Maximum of 30 days</i>`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong> (3) Other Hospital Service</strong>`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong> (4) Surgical Fees</strong>`,
          info: `<i>< S$1,500 will not be subject to Surgical Schedule</i>`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong> (5) In-Hospital Doctor's Consultation</strong>`,
          info: `<i>Maximum of 120 days</i>`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong> (6) Pre (90 days) & Post (90 days) Hospitalisation / Surgery<strong>`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `<strong>(7) Emergency Accidental Outpatient Treatment</strong>`,
          info: `<i>Including Accidental Dental Treatment</i>`
        }
      ]
    },
    {
      id: 13,
      columns: [
        {
          data: `<strong>Optional Benefits</strong>`,
          info: `<i> Each Optional Benefit will add on additional loading on the total premium payable.</i>`
        },
        {
          data: ` `
        },
        {
          data: ` `
        },
        {
          data: ` `
        },
        {
          data: ` `
        },
        {
          data: ` `
        },
        {
          data: ` `
        },
        {
          data: ` `
        },
        {
          data: ` `
        }
      ]
    },
    {
      id: 14,
      columns: [
        {
          data: `<strong>(A) Worldwide Coverage</strong><br>
          Extend benefits under items 1 to 7 to insured Member without geographical limitation<br>
                <i>(Only Reasonable and Customary Charges incurred will be paid)</i>`,
          info: `<i>Additional 5% loading on selected base plan premium<i>`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        }
      ]
    },
    {
      id: 15,
      columns: [
        {
          data: `<strong>(B) Waiver of Waiting Period for Pre-Existing Condition</strong><br>
                Provide Pre-existing Conditions Waiting Period Waiver<br>
                <i>(Waiver of 12 months waiting period such that benefits under items 1 to 7 is/are payable
                even if sickness or injury that is due to Pre-existing Conditions, occurs within first 12 months of coverage)</i>`,
          info: `<i>Additional 10% loading on selected base plan premium<i>`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        }
      ]
    },
    {
      id: 16,
      columns: [
        {
          data: `<strong>(C) Worldwide Coverage with Waiver of Waiting Period for Pre-Existing Condition</strong><br>
          Coverage under (A) and (B) applies`,
          info: `<i> Additional 15% loading on selected base plan Premium</i>`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        },
        {
          data: `N/A`
        }
      ]
    },
    {
      id: 17,
      columns: [
        {
          data: `<strong>Pro-ration Factors</strong>`,
          info: `<i>Pro-ration factor of 75% will apply for Plan 1 to 8 if Insured Member stays in a ward higher than his Room & Board Entitlement 
          (i.e. private Hospital or 1 -bedded / 2 bedded Government Restructured Hospital) as specified in the Benefit Schedule.</i>`
        },
        {
          data: `75%`
        },
        {
          data: `75%`
        },
        {
          data: `75%`
        },
        {
          data: `75%`
        },
        {
          data: `75%`
        },
        {
          data: `75%`
        },
        {
          data: `75%`
        },
        {
          data: `75%`
        }
      ]
    }
  ],
  footnotes: []
};

let GFWMPremiumOnly = {
  title: `<br><strong>* For Employee Only (inclusive of 9% GST) - Group Foreign Worker Medical Insurance (GFWM)<strong>`,
  headers: [
    {
      title: `Age Last`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    },
    {
      title: `Plan 6`,
      tier: 6
    },
    {
      title: `Plan 7`,
      tier: 7
    },
    {
      title: `Plan 8`,
      tier: 8
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 50</strong>`
        },
        {
          data: `S$ 191.84`
        },
        {
          data: `S$ 180.94`
        },
        {
          data: `S$ 154.87`
        },
        {
          data: `S$ 148.24`
        },
        {
          data: `S$ 201.65`
        },
        {
          data: `S$ 189.66`
        },
        {
          data: `S$ 163.50`
        },
        {
          data: `S$ 155.87`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>51 - 69</strong>`
        },
        {
          data: `S$ 481.78`
        },
        {
          data: `S$ 453.44`
        },
        {
          data: `S$ 390.22`
        },
        {
          data: `S$ 371.69`
        },
        {
          data: `S$ 505.76`
        },
        {
          data: `S$ 476.33`
        },
        {
          data: `S$ 409.84`
        },
        {
          data: `S$ 390.22`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>70 - 74</strong>`
        },
        {
          data: `S$ 481.78`
        },
        {
          data: `S$ 453.44`
        },
        {
          data: `S$ 390.22`
        },
        {
          data: `S$ 371.69`
        },
        {
          data: `S$ 505.76`
        },
        {
          data: `S$ 476.33`
        },
        {
          data: `S$ 409.84`
        },
        {
          data: `S$ 390.22`
        }
      ]
    }
  ],
  footnotes: []
};

let GFWMOptionAPremium = {
  title: `<br><strong><u>Options for extension for Group Foreign Worker Medical Insurance (GFWM)</u>
          <br>
          <br>* For Employee Only (inclusive of 9% GST) for Option (A) - Worldwide Cover<strong>`,
  headers: [
    {
      title: `Age Last`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    },
    {
      title: `Plan 6`,
      tier: 6
    },
    {
      title: `Plan 7`,
      tier: 7
    },
    {
      title: `Plan 8`,
      tier: 8
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 50</strong>`
        },
        {
          data: `S$ 201.65`
        },
        {
          data: `S$ 189.66`
        },
        {
          data: `S$ 163.50`
        },
        {
          data: `S$ 155.87`
        },
        {
          data: `S$ 211.46`
        },
        {
          data: `S$ 199.47`
        },
        {
          data: `S$ 172.22`
        },
        {
          data: `S$ 163.50`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>51 - 69</strong>`
        },
        {
          data: `S$ 505.76`
        },
        {
          data: `S$ 476.33`
        },
        {
          data: `S$ 409.84`
        },
        {
          data: `S$ 390.22`
        },
        {
          data: `S$ 530.83`
        },
        {
          data: `S$ 500.31`
        },
        {
          data: `S$ 430.55`
        },
        {
          data: `S$ 409.84`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>70 - 74</strong>`
        },
        {
          data: `S$ 505.76`
        },
        {
          data: `S$ 476.33`
        },
        {
          data: `S$ 409.84`
        },
        {
          data: `S$ 390.22`
        },
        {
          data: `S$ 530.83`
        },
        {
          data: `S$ 500.31`
        },
        {
          data: `S$ 430.55`
        },
        {
          data: `S$ 409.84`
        }
      ]
    }
  ],
  footnotes: []
};

let GFWMOptionBPremium = {
  title: `<br><strong>* For Employee Only (inclusive of 9% GST) for Option (B) - Wavier of Waiting Period for Pre-Existing Condition <strong>`,
  headers: [
    {
      title: `Age Last`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    },
    {
      title: `Plan 6`,
      tier: 6
    },
    {
      title: `Plan 7`,
      tier: 7
    },
    {
      title: `Plan 8`,
      tier: 8
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 50</strong>`
        },
        {
          data: `S$ 211.46`
        },
        {
          data: `S$ 199.47`
        },
        {
          data: `S$ 171.13`
        },
        {
          data: `S$ 163.50`
        },
        {
          data: `S$ 222.36`
        },
        {
          data: `S$ 208.19`
        },
        {
          data: `S$ 179.85`
        },
        {
          data: `S$ 171.13`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>51 - 69</strong>`
        },
        {
          data: `S$ 529.74`
        },
        {
          data: `S$ 499.22`
        },
        {
          data: `S$ 429.46`
        },
        {
          data: `S$ 408.75`
        },
        {
          data: `S$ 555.90`
        },
        {
          data: `S$ 524.29`
        },
        {
          data: `S$ 451.26`
        },
        {
          data: `S$ 429.46`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>70 - 74</strong>`
        },
        {
          data: `S$ 529.74`
        },
        {
          data: `S$ 499.22`
        },
        {
          data: `S$ 429.46`
        },
        {
          data: `S$ 408.75`
        },
        {
          data: `S$ 555.90`
        },
        {
          data: `S$ 524.29`
        },
        {
          data: `S$ 451.26`
        },
        {
          data: `S$ 429.46`
        }
      ]
    }
  ],
  footnotes: []
};

let GFWMOptionCPremium = {
  title: `<br><strong>* For Employee Only (inclusive of 9% GST) for Option (C) -  Worldwide Coverage with Waiver of Waiting Period for Pre-Existing Condition<strong>`,
  headers: [
    {
      title: `Age Last`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    },
    {
      title: `Plan 6`,
      tier: 6
    },
    {
      title: `Plan 7`,
      tier: 7
    },
    {
      title: `Plan 8`,
      tier: 8
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 50</strong>`
        },
        {
          data: `S$ 220.18`
        },
        {
          data: `S$ 208.19`
        },
        {
          data: `S$ 178.76`
        },
        {
          data: `S$ 170.04`
        },
        {
          data: `S$ 232.17`
        },
        {
          data: `S$ 218.00`
        },
        {
          data: `S$ 188.57`
        },
        {
          data: `S$ 178.76`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>51 - 69</strong>`
        },
        {
          data: `S$ 553.72`
        },
        {
          data: `S$ 521.02`
        },
        {
          data: `S$ 449.08`
        },
        {
          data: `S$ 427.28`
        },
        {
          data: `S$ 582.06`
        },
        {
          data: `S$ 548.27`
        },
        {
          data: `S$ 470.88`
        },
        {
          data: `S$ 449.08`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>70 - 74</strong>`
        },
        {
          data: `S$ 553.72`
        },
        {
          data: `S$ 521.02`
        },
        {
          data: `S$ 449.08`
        },
        {
          data: `S$ 427.28`
        },
        {
          data: `S$ 582.06`
        },
        {
          data: `S$ 548.27`
        },
        {
          data: `S$ 470.88`
        },
        {
          data: `S$ 449.08`
        }
      ]
    }
  ],
  footnotes: []
};

const GFWM = {
  id: 20,
  code: "GFWM",
  label: "Group Foreign Worker Medical Insurance (GFWM)",
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GFWMSumAssuredEmployee]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium",
      data: [GFWMPremiumOnly, GFWMOptionAPremium, GFWMOptionBPremium, GFWMOptionCPremium]
    }
  ]
};

export default GFWM;
