import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";

class EmailLink extends Component {
  render() {
    const { email, description, classes } = this.props;
    return (
      <a href={`mailto:${email}`} style={{ textDecoration: "none" }}>
        <Typography color="primary" className={classes.emailUs}>
          {email}
        </Typography>
        <Typography className={classes.emailText}>{description}</Typography>
      </a>
    );
  }
}

const styles = theme => ({
  emailText: {
    color: "#6A6B6D",
    textAlign: "center",
    fontSize: 14
  },
  emailUs: {
    color: "#ED1B24",
    textAlign: "center",
    fontSize: 13,
    lineHeight: 1.56,
    letterSpacing: 0.5,
    fontWeight: 600
  }
});

export default withStyles(styles, { withTheme: true })(EmailLink);
