import { withStyles } from "@mui/styles";
import classNames from "classnames";
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import React from "react";
import Snackbar from "@mui/material/Snackbar";

class SnackBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }

  componentWillReceiveProps(newProps) {
    this.setState({ open: newProps.open });
  }

  componentDidMount() {
    this.setState({ open: this.props.open });
  }

  closeSnackBar = (event, reason) => {
    this.setState({ open: false });
    if (this.props.onClose) this.props.onClose(event, reason);
  };

  openSnackBar = event => {
    if (this.props.onOpen) this.props.onOpen(event);
  };

  snackBarType = classes => {
    let type = {
      success: {
        icon: (
          <Icon
            classes={{
              root: classes.icon
            }}
          >
            check_circle
          </Icon>
        )
      },
      error: {
        icon: (
          <Icon
            classes={{
              root: classes.icon
            }}
          >
            warning
          </Icon>
        )
      }
    };
    return { TYPE: type };
  };

  render() {
    const { classes } = this.props;
    const { TYPE } = this.snackBarType(classes);
    /**
     * Set config for Snackbar
     */
    let anchorOrigin = this.props.anchorOrigin || {
      vertical: "bottom",
      horizontal: "right"
    };
    let autoHideDuration = this.props.autoHideDuration || 6000;
    let snackBarType = this.props.type || "success";
    let snackBarStyle =
      anchorOrigin.vertical === "bottom"
        ? classes.snackBarBottom
        : anchorOrigin.vertical === "top"
        ? classes.snackBarTop
        : classes.snackBar;
    let snackBarIcon = snackBarType === "success" ? classes.iconSuccess : classes.iconError;
    return (
      <Snackbar
        className={snackBarStyle}
        anchorOrigin={anchorOrigin}
        autoHideDuration={autoHideDuration}
        open={this.state.open}
        onClose={this.closeSnackBar}
        onEntered={this.openSnackBar}
        SnackbarContentProps={{
          "aria-describedby": this.props.id || "snackbar-id",
          classes: {
            root: classes.snackBarContent,
            message: classes.snackMessage,
            action: classes.snackBarAction
          }
        }}
        message={
          <div id={this.props.id || "snackbar-id"} className={classes.messageContainer}>
            <div className={classNames(classes.icon, snackBarIcon)}>{TYPE[snackBarType].icon}</div>
            <div className={classes.message} style={{ color: "#fff" }}>
              {this.props.message}
            </div>
          </div>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={this.closeSnackBar}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    );
  }
}
const styles = theme => ({
  iconSuccess: {
    backgroundColor: theme.colors.success,
    outline: `1px solid ${theme.colors.success}`
  },
  iconError: {
    backgroundColor: theme.colors.error,
    outline: `1px solid ${theme.colors.error}`
  },
  icon: {
    color: `${theme.colors.secondary.white}`,
    padding: 2,
    width: "2rem",
    height: "2rem"
  },
  snackBarContent: {
    maxWidth: "100%",
    border: `1px solid ${theme.colors.blackScale.black70}`,
    backgroundColor: theme.colors.secondary.white,
    boxShadow: "none",
    padding: 0,
    flexGrow: 0
  },
  message: {
    color: theme.colors.blackScale.black100,
    padding: 14,
    borderLeft: 0,
    borderRight: 0
  },
  snackMessage: {
    padding: 0
  },
  snackBarBottom: {
    bottom: 60
  },
  snackBarTop: {
    top: 135
  },
  snackBarAction: {
    color: theme.colors.blackScale.black70,
    justifyContent: "flex-end",
    flex: 1,
    marginLeft: 0,
    paddingLeft: 0,
    marginRight: 0
  },
  snackBarContentError: {
    backgroundColor: theme.colors.error,
    display: "flex",
    alignItems: "center"
  },
  snackBarContentSuccess: {
    backgroundColor: theme.colors.success,
    display: "flex",
    alignItems: "center"
  },
  messageContainer: {
    display: "flex",
    alignItems: "center"
  }
});

export default withStyles(styles, { withTheme: true })(SnackBar);
