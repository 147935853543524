import * as actionTypes from "../actions/types";

const initialState = {};

export const products = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRODUCT_CONFIG_LOAD_SUCCESS: {
      let _state = {
        ...state
      };
      let _payload = action.payload;
      _payload.updatedTime = new Date();

      if (!_state[_payload.code]) _state[_payload.code] = {};
      _state[_payload.code].config = _payload;
      return {
        ..._state
      };
    }
    case actionTypes.PRODUCT_CONFIG_LOAD_FAILURE:
      return {
        ...state,
        failedTime: new Date()
      };
    case actionTypes.PRODUCT_CONFIG_SET_PLANS:
      return {
        ...state,
        plans: action.payload
      };
    case actionTypes.PRODUCT_CONFIG_SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      };
    case actionTypes.PRODUCT_ENGINE_LOAD_SUCCESS: {
      let _payload = action.payload;
      return {
        ...state,
        engine: {
          code: _payload.code,
          version: _payload.version,
          isLoaded: true,
          updatedTime: new Date()
        }
      };
    }
    case actionTypes.PRODUCT_ENGINE_LOAD_FAILURE:
      return {
        ...state,
        engine: {
          isLoaded: false,
          failedTime: new Date()
        }
      };
    case actionTypes.PRODUCT_CONFIG_LATEST_VERSION:
      let _payload = action.payload;
      return {
        ...state,
        latest_config: {
          code: _payload.code,
          version: _payload.version
        }
      };
    default:
      return state;
  }
};
