export default [
  {
    value: "M",
    label: "Married"
  },
  {
    value: "D",
    label: "Divorced"
  },
  {
    value: "S",
    label: "Single"
  },
  {
    value: "W",
    label: "Widowed"
  }
];
