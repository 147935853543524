import * as actionTypes from "../actions/types";

const initialState = {
  esignContent: null
};

export const esign = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.QUOTE_E_SIGN_CONTENT_ERROR:
      return {
        ...state,
        error: action.error
      };
    case actionTypes.QUOTE_GET_DOCUMENT_SIGN_STATUS:
      return {
        ...state,
        error: action.error
      };
    case actionTypes.QUOTE_GET_DOCUMENT_CALLBACK_STATUS:
      return {
        ...state,
        error: action.error
      };
    case actionTypes.QUOTE_GET_SIGNED_DOCUMENT_PENDING:
      return {
        ...state,
        isValidResponse: false
      };
    case actionTypes.QUOTE_GET_SIGNED_DOCUMENT_FULFILLED:
      return {
        ...state,
        isValidResponse: true,
        esignContent: action.payload,
        error: action.error
      };

    default:
      return state;
  }
};
