import Enums from "../../../../utils/Enums";

let doc = [
  {
    desc: "PRUWorks Portal Guide for FR",
    fileNameOriginal: "PRUWorks Portal Guide for FR - updated 18 Jul 2024 (Final Revised).pdf",
    fileName: "PRUWorks Portal Guide for FR.pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: [Enums.DISPLAY_TYPE.PACKAGE_PLAN, Enums.DISPLAY_TYPE.CUSTOMIZED_PLAN]
  },
  {
    desc: "GHS Claim form (including Physician statement)",
    fileNameOriginal: "Form - Group GHS Claim. V08.2023.pdf",
    fileName: "GHS Claim form (including Physician statement).pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: [Enums.DISPLAY_TYPE.PACKAGE_PLAN, Enums.DISPLAY_TYPE.CUSTOMIZED_PLAN],
    code: {
      PF2: [[Enums.PLANS_CODE.GHSB], [Enums.PLANS_CODE.GEMM]],
      PF3: [[Enums.PLANS_CODE.GHSB], [Enums.PLANS_CODE.GFWM], [Enums.PLANS_CODE.GEMM]],
      Customized: [
        Enums.PLANS_CODE.GHSB,
        Enums.PLANS_CODE.GFWM,
        Enums.PLANS_CODE.P3HS,
        Enums.PLANS_CODE.GSMM,
        Enums.PLANS_CODE.GEMM
      ]
    }
  },
  {
    desc: "Health Declaration Form",
    fileNameOriginal: "Form - Group Health Declaration (Jun 2019) EO.pdf",
    fileName: "Health Declaration Form",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: Enums.DISPLAY_TYPE.CUSTOMIZED_PLAN
  },
  {
    desc: "FME Panel Clinics",
    fileNameOriginal: "Panel Drs - Medical Exam Only (25102021) (1).pdf",
    fileName: "FME Panel Clinics.pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: Enums.DISPLAY_TYPE.CUSTOMIZED_PLAN
  },
  {
    desc: "Member Movement Form (editable pdf)",
    fileNameOriginal: "Member movement Form V140823 with email bank account.xls",
    fileName: "Member Movement Form (editable pdf).xls",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: Enums.DISPLAY_TYPE.CUSTOMIZED_PLAN
  },
  {
    desc: "Pruworks HR User Guide (GCP)",
    fileNameOriginal: "Pruworks-HR-PCE-User-Guide.pdf",
    fileName: "Pruworks HR User Guide (GCP).pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: Enums.DISPLAY_TYPE.CUSTOMIZED_PLAN
  },
  {
    desc: "Pruworks Employee User Guide (GCP)",
    fileNameOriginal: "Customised policy_Employee PRUworks Claims Portal Guide (2).pdf",
    fileName: "Pruworks Employee User Guide (GCP).pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: Enums.DISPLAY_TYPE.CUSTOMIZED_PLAN
  },
  {
    desc: "Health Declaration Form",
    fileNameOriginal: "Form - Group Health Declaration (Jun 2019) EO.pdf",
    fileName: "Health Declaration Form.pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: Enums.DISPLAY_TYPE.PACKAGE_PLAN
  },
  {
    desc: "Pruworks HR User Guide (PTRF2)",
    fileNameOriginal: "PRUworks HR Portal Guide 2023.pdf",
    fileName: "Pruworks HR User Guide (PTRF2).pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: Enums.DISPLAY_TYPE.PACKAGE_PLAN
  },
  {
    desc: "Pruworks Employee User Guide (PTRF2)",
    fileNameOriginal: "PTRF II PRUworks Employee Portal Guide - (August 2023).pdf",
    fileName: "Pruworks Employee User Guide (PTRF2).pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: Enums.DISPLAY_TYPE.PACKAGE_PLAN
  },
  {
    desc: "PruTreasure Flexi 2 Brochure",
    fileNameOriginal: "PTRF2 Brochure 25 July 2024 - Agency (New).pdf",
    fileName: "PruTreasure Flexi 2 Brochure.pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: Enums.DISPLAY_TYPE.PACKAGE_PLAN
  },
  {
    desc: "IHP Mobile Guide",
    fileNameOriginal: "IHP Mobile App Guide_Pruworks_1605023.pdf",
    fileName: "IHP Mobile Guide.pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: Enums.DISPLAY_TYPE.PACKAGE_PLAN
  },
  {
    desc: "Form - Group Term Life (Death)",
    fileNameOriginal: "Form - Group Term Life (Death) V03.2018.pdf",
    fileName: "Form - Group Term Life (Death).pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: [Enums.DISPLAY_TYPE.PACKAGE_PLAN, Enums.DISPLAY_TYPE.CUSTOMIZED_PLAN],
    code: { PF2: [Enums.PLANS_CODE.GTLB], PF3: [Enums.PLANS_CODE.GTLB], Customized: [Enums.PLANS_CODE.GTLB] }
  },
  {
    desc: "Form - Group Terminal Illness Claim",
    fileNameOriginal: "Form - Group Terminal Illness Claim V04.2020.pdf",
    fileName: "Form - Group Terminal Illness Claim.pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: [Enums.DISPLAY_TYPE.PACKAGE_PLAN, Enums.DISPLAY_TYPE.CUSTOMIZED_PLAN],
    code: { PF2: [Enums.PLANS_CODE.GTLB], PF3: [Enums.PLANS_CODE.GTLB], Customized: [Enums.PLANS_CODE.GTLB] }
  },
  {
    desc: "Form - Group TPD claim form with Medical Specialist Report",
    fileNameOriginal: "Form - Group TPD claim form with Medical Specialist Report.V03.2018.pdf",
    fileName: "Form - Group TPD claim form with Medical Specialist Report.pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: [Enums.DISPLAY_TYPE.PACKAGE_PLAN, Enums.DISPLAY_TYPE.CUSTOMIZED_PLAN],
    code: { PF2: [Enums.PLANS_CODE.GTLB], PF3: [Enums.PLANS_CODE.GTLB], Customized: [Enums.PLANS_CODE.GTLB] }
  },
  {
    desc: "Form - Group CC claim",
    fileNameOriginal: "Form - Group CC Claim V03.2018.pdf",
    fileName: "Form - Group CC claim.pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: [Enums.DISPLAY_TYPE.PACKAGE_PLAN, Enums.DISPLAY_TYPE.CUSTOMIZED_PLAN],
    code: {
      PF2: [Enums.PLANS_CODE.GCCA],
      PF3: [Enums.PLANS_CODE.GCCA],
      Customized: [Enums.PLANS_CODE.GCCE, Enums.PLANS_CODE.GCCA]
    }
  },
  {
    desc: "Benign brain tumor - Group Crisis Cover_Pre-Crisis Cover",
    fileNameOriginal: "Benign brain tumor -Group Crisis Cover_Pre-Crisis Cover.pdf",
    fileName: "Benign brain tumor -Group Crisis Cover_Pre-Crisis Cover.pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: [Enums.DISPLAY_TYPE.PACKAGE_PLAN, Enums.DISPLAY_TYPE.CUSTOMIZED_PLAN],
    code: { PF2: [""], PF3: [Enums.PLANS_CODE.GEAC], Customized: [Enums.PLANS_CODE.GEAC, Enums.PLANS_CODE.GEEC] }
  },
  {
    desc: "General APS - Group Crisis Cover_Pre Crisis Cover",
    fileNameOriginal: "General APS -Group Crisis Cover_Pre-Crisis Cover.pdf",
    fileName: "General APS - Group Crisis Cover_Pre Crisis Cover.pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: [Enums.DISPLAY_TYPE.PACKAGE_PLAN, Enums.DISPLAY_TYPE.CUSTOMIZED_PLAN],
    code: { PF2: [""], PF3: [Enums.PLANS_CODE.GEAC], Customized: [Enums.PLANS_CODE.GEAC, Enums.PLANS_CODE.GEEC] }
  },
  {
    desc: "Heart CC - Group Crisis Cover_Pre Crisis Cover.pdf",
    fileNameOriginal: "Heart CC -Group Crisis Cover_Pre-Crisis Cover.pdf",
    fileName: "Heart CC - Group Crisis Cover_Pre Crisis Cover.pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: [Enums.DISPLAY_TYPE.PACKAGE_PLAN, Enums.DISPLAY_TYPE.CUSTOMIZED_PLAN],
    code: { PF2: [""], PF3: [Enums.PLANS_CODE.GEAC], Customized: [Enums.PLANS_CODE.GEAC, Enums.PLANS_CODE.GEEC] }
  },
  {
    desc: "Kidney Failure - Group Crisis Cover_Pre Crisis Cover",
    fileNameOriginal: "Kidney Failure -Group Crisis Cover_Pre-Crisis Cover.pdf",
    fileName: "Kidney Failure - Group Crisis Cover_Pre Crisis Cover.pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: [Enums.DISPLAY_TYPE.PACKAGE_PLAN, Enums.DISPLAY_TYPE.CUSTOMIZED_PLAN],
    code: { PF2: [""], PF3: [Enums.PLANS_CODE.GEAC], Customized: [Enums.PLANS_CODE.GEAC, Enums.PLANS_CODE.GEEC] }
  },
  {
    desc: "Major Cancers - Group Crisis Cover_Pre Crisis Cover",
    fileNameOriginal: "Major Cancers -Group Crisis Cover_Pre-Crisis Cover.pdf",
    fileName: "Major Cancers - Group Crisis Cover_Pre Crisis Cover.pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: [Enums.DISPLAY_TYPE.PACKAGE_PLAN, Enums.DISPLAY_TYPE.CUSTOMIZED_PLAN],
    code: { PF2: [""], PF3: [Enums.PLANS_CODE.GEAC], Customized: [Enums.PLANS_CODE.GEAC, Enums.PLANS_CODE.GEEC] }
  },
  {
    desc: "Storke ES - Group Crisis Cover_Pre Crisis Cover",
    fileNameOriginal: "Stroke ES- Group Crisis Cover_Pre-Crisis Cover.pdf",
    fileName: "Stroke ES - Group Crisis Cover_Pre Crisis Cover.pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: [Enums.DISPLAY_TYPE.PACKAGE_PLAN, Enums.DISPLAY_TYPE.CUSTOMIZED_PLAN],
    code: { PF2: [""], PF3: [Enums.PLANS_CODE.GEAC], Customized: [Enums.PLANS_CODE.GEAC, Enums.PLANS_CODE.GEEC] }
  },
  {
    desc: "Form - Group GADD claim",
    fileNameOriginal: "Form - Group ADD Claim V03.2018(1).pdf",
    fileName: "Form - Group GADD claim.pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: [Enums.DISPLAY_TYPE.PACKAGE_PLAN, Enums.DISPLAY_TYPE.CUSTOMIZED_PLAN],
    code: { PF2: [Enums.PLANS_CODE.GADD], PF3: [Enums.PLANS_CODE.GADD], Customized: [Enums.PLANS_CODE.GADD] }
  },
  {
    desc: "Form - Group Outpatient Claim",
    fileNameOriginal: "Form-Group Outpatient Claim.V01 2024.pdf",
    fileName: "Form - Group Outpatient Claim.pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: [Enums.DISPLAY_TYPE.PACKAGE_PLAN, Enums.DISPLAY_TYPE.CUSTOMIZED_PLAN],
    code: {
      PF2: [Enums.PLANS_CODE.PGIH, Enums.PLANS_CODE.GPRU, Enums.PLANS_CODE.PSIH, Enums.PLANS_CODE.GOSP],
      PF3: [Enums.PLANS_CODE.PGIH, Enums.PLANS_CODE.GPRU, Enums.PLANS_CODE.PSIH, Enums.PLANS_CODE.GOSP],
      Customized: [
        Enums.PLANS_CODE.GOSP,
        Enums.PLANS_CODE.GPRU,
        Enums.PLANS_CODE.PGIH,
        Enums.PLANS_CODE.PSIH,
        Enums.PLANS_CODE.P3SP,
        Enums.PLANS_CODE.P3GP,
        Enums.PLANS_CODE.GOPB,
        Enums.PLANS_CODE.PSMH
      ]
    }
  },
  {
    desc: "Form - Group Dental Claim",
    fileNameOriginal: "Form-Group Dental Claim.V08 2023.pdf",
    fileName: "Form - Group Dental Claim.pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: [Enums.DISPLAY_TYPE.PACKAGE_PLAN, Enums.DISPLAY_TYPE.CUSTOMIZED_PLAN],
    code: { PF2: [Enums.PLANS_CODE.GDEN], PF3: [Enums.PLANS_CODE.GDEN], Customized: [Enums.PLANS_CODE.GDEN] }
  },
  {
    desc: "Form - Group Dental Clm(PPD)",
    fileNameOriginal: "Form - Group Dental Clm (PPD) V08.2023.pdf",
    fileName: "Form - Group Dental Clm(PPD).pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: [Enums.DISPLAY_TYPE.PACKAGE_PLAN, Enums.DISPLAY_TYPE.CUSTOMIZED_PLAN],
    code: { PF2: [""], PF3: [Enums.PLANS_CODE.PDIH], Customized: [Enums.PLANS_CODE.PDIH] }
  },
  {
    desc: "Form - GROUP GHS CLAIM WITH GAMR BOX",
    fileNameOriginal: "Form - Group GHS Claim with GAMR box. V08.2023.pdf",
    fileName: "Form - GROUP GHS CLAIM WITH GAMR BOX.pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    displayTypes: [Enums.DISPLAY_TYPE.PACKAGE_PLAN, Enums.DISPLAY_TYPE.CUSTOMIZED_PLAN],
    code: { PF2: [Enums.PLANS_CODE.GAMR], PF3: [Enums.PLANS_CODE.GAMR], Customized: [Enums.PLANS_CODE.GAMR] }
  }
];

export default doc;
