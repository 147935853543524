import SearchIcon from "@mui/icons-material/Search";
import {
  FormControl,
  Input,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { policyEmployeeActions } from "../../../../actions";
import classNames from "classnames";
import moment from "moment";
import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Enums from "../../../../utils/Enums";
import TablePaginationActions from "../../_HR/MyEmployees/TablePaginationActions";

const EMPTY_SELECT_VALUE = "-";

class MyEmployeeTable extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      { name: "companyName", type: "string", label: "Company Name" },
      { name: "fullName", type: "string", label: "Full Name", filter: this.getTextFilter("fullName") },
      { name: "nric", type: "string", label: "NRIC/FIN/Passport", filter: this.getTextFilter("nric") },
      { name: "dob", type: "date", label: "Date of Birth" },
      {
        name: "employeeCategory",
        type: "string",
        label: "Employee Category",
        filter: this.getSelectFilter("employeeCategory", this.getCategories())
      },
      { name: "email", type: "string", label: "Email Address" },
      { name: "dependentToName", type: "string", label: "Dependent To (Name)" },
      { name: "dependentToNRIC", type: "string", label: "Dependent To (NRIC/FIN/Passport)" },
      { name: "relationshipType", type: "string", label: "Relationship Type" },
      { name: "coverageStartDate", type: "date", label: "Coverage Start Date" },
      { name: "coverageEndDate", type: "date", label: "Coverage End Date" }
    ];

    this.state = {
      order: Enums.SORT.DESC,
      orderBy: "fullName",
      pageIndex: 0,
      filterValues: {
        fullName: "",
        nric: "",
        employeeCategory: EMPTY_SELECT_VALUE
      },
      processedData: null
    };
  }

  handleSubmitFilter = (columnName, value) => {
    //reset all other value
    let filterValues = this.state.filterValues;
    Object.entries(filterValues).forEach(([key, value]) => {
      if (columnName !== key) {
        filterValues[key] = "";
        if (key === "employeeCategory") {
          filterValues[key] = EMPTY_SELECT_VALUE;
        }
      }
    });
    this.setState({ filterValues });

    const filteredData = this.props.data.filter(employee => {
      if (employee[columnName] && columnName === "employeeCategory") {
        const filterValue =
          filterValues[columnName] === EMPTY_SELECT_VALUE ? "" : filterValues[columnName].toLowerCase();
        return employee[columnName].some(employee => employee.toLowerCase().includes(filterValue));
      }

      if (employee[columnName]) {
        const filterValue =
          filterValues[columnName] === EMPTY_SELECT_VALUE ? "" : filterValues[columnName].toLowerCase();
        return employee[columnName].toLowerCase().includes(filterValue);
      }
    });
    this.setState({ processedData: filteredData });
    this.props.handleResetPage();
  };

  sortSubHeaderHandler = subheader => () => {
    const order = this.state.order === Enums.SORT.DESC ? Enums.SORT.ASC : Enums.SORT.DESC;

    const selectedTable = this.state.processedData ? this.state.processedData : this.props.data;
    let sortedTable;

    sortedTable = [...selectedTable].sort((a, b) => {
      const aProduct = a.products.find(product => product.code === subheader.productCode) || "";
      const bProduct = b.products.find(product => product.code === subheader.productCode) || "";
      const aList = aProduct[subheader.subName] || [];
      const bList = bProduct[subheader.subName] || [];
      const aValue = aList[0] || "";
      const bValue = bList[0] || "";
      return order === Enums.SORT.ASC ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    });

    this.setState({ processedData: sortedTable, order, orderBy: subheader.name });
    this.props.handleResetPage();
  };

  sortHandler = columnName => () => {
    const order = this.state.order === Enums.SORT.DESC ? Enums.SORT.ASC : Enums.SORT.DESC;

    const selectedTable = this.state.processedData ? this.state.processedData : this.props.data;
    let sortedTable;
    if (columnName === "coverageStartDate" || columnName === "coverageEndDate") {
      sortedTable = [...selectedTable].sort((a, b) => {
        return order === Enums.SORT.ASC
          ? new Date(a[columnName]) - new Date(b[columnName])
          : new Date(b[columnName]) - new Date(a[columnName]);
      });
    } else if (columnName === "employeeCategory") {
      sortedTable = [...selectedTable].sort((a, b) => {
        return order === Enums.SORT.ASC
          ? a[columnName][0].localeCompare(b[columnName][0])
          : b[columnName][0].localeCompare(a[columnName][0]);
      });
    } else {
      sortedTable = [...selectedTable].sort((a, b) => {
        const aValue = a[columnName] || "";
        const bValue = b[columnName] || "";
        return order === Enums.SORT.ASC ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      });
    }

    this.setState({ processedData: sortedTable, order, orderBy: columnName });
    this.props.handleResetPage();
  };

  handleFilterChange = (type, name) => event => {
    this.state.filterValues[name] = event.target.value;
    this.setState({ filterValues: this.state.filterValues }, () => {
      if (type === "select") {
        this.handleSubmitFilter(name, this.state.filterValues[name]);
      }
    });
  };

  getNoneFilter = classes => {
    return (
      <FormControl className={classes.margin}>
        <Input disabled />
      </FormControl>
    );
  };

  getTextFilter = name => classes => {
    return (
      <FormControl className={classes.margin}>
        <Input
          value={this.state.filterValues[name]}
          onChange={this.handleFilterChange("text", name)}
          classes={{
            root: classes.textRoot,
            underline: classes.cssUnderline
          }}
          onKeyPress={ev => {
            if (ev.key === "Enter") {
              this.handleSubmitFilter(name, this.state.filterValues[name]);
              ev.preventDefault();
            }
          }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon className={classes.filterIcon} />
            </InputAdornment>
          }
        />
      </FormControl>
    );
  };

  getSelectFilter = (name, data) => classes => {
    return (
      <FormControl className={classes.formControl} fullWidth>
        <Select
          value={this.state.filterValues[name]}
          onChange={this.handleFilterChange("select", name)}
          input={
            <Input
              id="select-multiple"
              classes={{
                root: classes.textRoot,
                underline: classes.cssUnderline
              }}
            />
          }
          classes={{
            root: classes.selectRoot,
            icon: classes.selectIcon
          }}
        >
          <MenuItem value={EMPTY_SELECT_VALUE}>
            <em>Select</em>
          </MenuItem>
          {data.map(d => {
            return (
              <MenuItem key={d.value} value={d.value}>
                <em>{d.label}</em>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  getCategories = () => {
    const dropdownValue = this.props.policyEmployees.categories ? this.props.policyEmployees.categories : [];
    return dropdownValue.map(category => {
      return { label: category, value: category };
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.sortHandler(this.state.orderBy)();
    }
  }

  dataProcess = (value, type, name) => {
    if (type === "date") {
      return value && moment(value).format("YYYY") !== "9999" ? moment(value).format("DD/MM/YYYY") : "-";
    } else if (value && name === "employeeCategory") {
      return value.join(", ");
    }
    return value;
  };

  handleProductDataProcess = (value, name, subheader) => {
    if (!value || value.length === 0) {
      return;
    }

    const product = value.find(product => product.code === name);

    let result;
    if (product && subheader === "plans") {
      result = product.plans.join(", ");
    }
    return result;
  };

  handleCustomizedHeader = customizedProduct => {
    // remove employeeCategory for customized
    let result = this.columns.filter(column => column.name !== "employeeCategory");

    // add products from customized data
    customizedProduct.forEach(product => {
      const productData = {
        name: product.productCode,
        type: "String",
        label: product.description,
        subheaders: [
          {
            name: product.productCode + " planNo",
            label: "Plan No",
            productCode: product.productCode,
            subName: "plans"
          }
        ]
      };
      result = [...result, productData];
    });

    return result;
  };

  render() {
    const { classes, currentPageNo, data, handlePageChange, policy } = this.props;
    const tableRowPerPage = 20;
    const totalPageList = this.state.processedData ? this.state.processedData : data ? data : [];
    const offsetStartIdx = Math.floor(currentPageNo * tableRowPerPage);
    const offsetEndIdx = Math.floor(offsetStartIdx + tableRowPerPage - 1);
    const currentPageList = (totalPageList || []).filter((data, idx) => offsetStartIdx <= idx && idx <= offsetEndIdx);

    const employeeHeader =
      policy.displayType === "packaged"
        ? this.columns
        : this.handleCustomizedHeader(policy.customisedPlans ? policy.customisedPlans : []);

    return (
      <div className={classes.content}>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead className={classes.tableHeaderRow}>
              <TableRow>
                {employeeHeader.map((p, idx) =>
                  p.subheaders ? (
                    <TableCell
                      key={idx}
                      colSpan={p.subheaders.length}
                      className={classes.tableHeaderCell}
                      style={{ minWidth: "200px", maxWidth: "200px" }}
                    >
                      {p.label}
                    </TableCell>
                  ) : (
                    <TableCell key={idx} className={classes.tableHeaderCell} rowSpan={2}>
                      <TableSortLabel
                        className={classes.sortLabel}
                        active={this.state.orderBy === p.name}
                        direction={this.state.order}
                        onClick={this.sortHandler(p.name)}
                      >
                        <div>{p.label}</div>
                      </TableSortLabel>
                      <br />
                      {p.filter ? p.filter(classes) : ""}
                    </TableCell>
                  )
                )}
              </TableRow>
              <TableRow>
                {employeeHeader.map(
                  column =>
                    column.subheaders &&
                    column.subheaders.map((subheader, index) => (
                      <TableCell key={index} className={classes.tableHeaderCell}>
                        <TableSortLabel
                          className={classes.sortLabel}
                          active={this.state.orderBy === subheader.name}
                          direction={this.state.order}
                          onClick={this.sortSubHeaderHandler(subheader)}
                        >
                          {subheader.label}
                        </TableSortLabel>
                      </TableCell>
                    ))
                )}
              </TableRow>
            </TableHead>

            {totalPageList.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan="7">No record found</TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {currentPageList.map((d, id) => {
                  return (
                    <TableRow key={id}>
                      {employeeHeader.map((column, idx) =>
                        column.subheaders ? (
                          column.subheaders.map((subheader, index) => (
                            <TableCell
                              key={idx + index}
                              className={classNames(classes.tableBodyCell, { [classes.tableOldCell]: id % 2 !== 0 })}
                            >
                              {this.handleProductDataProcess(d.products, column.name, subheader.subName)}
                            </TableCell>
                          ))
                        ) : (
                          <TableCell
                            key={idx}
                            className={classNames(classes.tableBodyCell, { [classes.tableOldCell]: id % 2 !== 0 })}
                          >
                            {this.dataProcess(d[column.name], column.type, column.name)}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
            {totalPageList.length !== 0 && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={(totalPageList && totalPageList.length) || 0}
                    rowsPerPage={tableRowPerPage}
                    page={currentPageNo}
                    onPageChange={handlePageChange}
                    rowsPerPageOptions={[]}
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={({ from, to, count }) => {
                      return `Showing ${from} to ${to} of ${count} results`;
                    }}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </div>
    );
  }
}

const styles = theme => ({
  content: {
    width: "100%",
    overflowX: "auto"
  },
  table: {
    backgroundColor: theme.palette.background.paper
  },
  tableHeaderRow: {
    backgroundColor: "black",
    border: "0.1rem solid #cccccc"
  },
  tableHeaderCell: {
    fontSize: "14px",
    color: "white",
    fontWeight: "bold",
    border: "0.1rem solid #cccccc",
    paddingLeft: "15px"
  },
  tableBodyCell: {
    color: "black",
    border: "0.1rem solid #cccccc",
    paddingLeft: "15px"
  },
  tableOldCell: {
    backgroundColor: "rgba(134, 133, 133, 0.1)"
  },
  sortLabel: {
    "&.Mui-active": {
      color: "white"
    },
    "& .MuiTableSortLabel-icon": {
      color: "inherit !important"
    }
  },
  cssUnderline: {
    "&:after": {
      backgroundColor: "white"
    }
  },
  textRoot: {
    fontSize: "0.75rem",
    fontWeight: 400,
    color: "black",
    backgroundColor: "white"
  },
  filterIcon: {
    paddingLeft: "5px",
    color: "black"
  },
  selectIcon: {
    color: "black"
  },
  selectRoot: {
    paddingLeft: 5,
    borderBottomColor: "white",
    backgroundColor: "white"
  }
});

function mapStateToProps(state) {
  return {
    policyEmployees: state.policyMyEmployees,
    policy: state.policyDetails.policy
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(policyEmployeeActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(MyEmployeeTable)));
