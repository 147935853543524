import React, { Component, Fragment } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import classNames from "classnames";

import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import FilesUploader from "./FilesUploader";
import PaymentMethods from "./Payments/PaymentMethods";
import ENUMS from "../../../../../../utils/Enums";

class Submit extends Component {
  render() {
    const { classes, quote } = this.props;
    let readOnly =
      (!quote.readOnly && quote.stages !== ENUMS.STAGES.PROPOSAL) || !quote.quoteGenerated ? true : quote.readOnly;
    return (
      <Grid container className={classes.root}>
        <Grid item md={12}>
          <FilesUploader proposalDisabled={readOnly} />
        </Grid>

        <Grid item md={12}>
          <PaymentMethods disabled={readOnly} />
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    paddingRight: 30
  }
});

function mapStateToProps(state, prop) {
  return { quote: state.quote };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(Submit)));
