import Enums from "../../../../utils/Enums";

const GOGPBenefits = {
  headers: [
    {
      title: ``,
      mandatory: true,
      attributes: {
        style: {
          width: 10
        }
      }
    },
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "30%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    },
    {
      title: `Plan 6`,
      tier: 6
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>a</strong>`
        },
        {
          data: `<strong>Panel Registered Medical Practitioner</strong>`
        },
        {
          data: `Cashless`,
          attributes: {
            colspan: 6,
            style: {
              width: "100%",
              textAlign: "center"
            }
          }
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>b</strong>`
        },
        {
          data: `<strong>Non-Panel Registered Medical Practitioner</strong>`,
          info: `<em>(covers treatment in Singapore only)</em>`
        },
        {
          data: `Reimbursement up to S$30 per visit`,
          attributes: {
            colspan: 6,
            style: {
              width: "100%",
              textAlign: "center"
            }
          }
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>c</strong>`
        },
        {
          data: `<strong>Singapore Government Polyclinic</strong>`
        },
        {
          data: `Full Reimbursement`,
          attributes: {
            colspan: 6,
            style: {
              width: "100%",
              textAlign: "center"
            }
          }
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>d</strong>`
        },
        {
          data: `<strong>Accident & Emergency
          Department in Singapore Hospitals</strong>`
        },
        {
          data: `Reimbursement up to $120 per visit <br> <i>(capped at 3 visits per policy year)</i>`,
          attributes: {
            colspan: 6,
            style: {
              width: "100%",
              textAlign: "center"
            }
          }
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>e</strong>`
        },
        {
          data: `<strong>Paediatrician Direct Access</strong>`
        },
        {
          data: `Reimbursement up to S$30 per visit`,
          attributes: {
            colspan: 6,
            style: {
              width: "100%",
              textAlign: "center"
            }
          }
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>f</strong>`
        },
        {
          data: `<strong>Overseas Registered Medical Practitioner</strong>`
        },
        {
          data: `Reimbursement up to S$100 per visit`,
          attributes: {
            colspan: 6,
            style: {
              width: "100%",
              textAlign: "center"
            }
          }
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>g</strong>`
        },
        {
          data: `<strong>Registered Traditional Chinese Medicine (TCM) </br>
                  Practitioner</strong>`,
          info: `<em>(Consultation and Medicine)</em>`
        },
        {
          data: `Reimbursement of up to S$30 per visit`,
          info: `(capped at 6 visits per policy year)`,
          attributes: {
            colspan: 3,
            style: {
              marginLeft: "15%",
              width: "100%",
              textAlign: "center"
            }
          }
        },
        {
          data: `Not Applicable`,
          attributes: {
            colspan: 3,
            style: {
              width: "100%",
              textAlign: "center"
            }
          }
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>h</strong>`
        },
        {
          data: `<strong>Tele-consultation with Panel Provider</strong>`,
          info: `<em>(Tele-consultation and Medicine)</em>`
        },
        {
          data: `As Charged`,
          attributes: {
            colspan: 6,
            style: {
              width: "100%",
              textAlign: "center"
            }
          }
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>i</strong>`
        },
        {
          data: `<strong>Co-Payment per visit</strong>`,
          info: `<em>(applicable for all benefits)</em>`
        },
        {
          data: `Nil`
        },
        {
          data: `S$ 5`
        },
        {
          data: `S$ 10`
        },
        {
          data: `Nil`
        },
        {
          data: `S$ 5`
        },
        {
          data: `S$ 10`
        }
      ]
    }
  ],
  footnotes: []
};

let GOGPPremium = {
  headers: [
    {
      title: `Plan Type`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    },
    {
      title: `Plan 6`,
      tier: 6
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Annual Premium</strong>`
        },
        {
          data: `S$ 306.84`
        },
        {
          data: `S$ 269.49`
        },
        {
          data: `S$ 236.19`
        },
        {
          data: `S$ 258.39`
        },
        {
          data: `S$ 221.04`
        },
        {
          data: `S$ 187.74`
        }
      ]
    }
  ],
  footnotes: []
};

//   ----------------------------------------------------------------------
const PGIH = {
  id: 4,
  code: "PGIH",
  label: "Group Outpatient General Practitioner (GPRU)",
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GOGPBenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium (inclusive of 8% GST)",
      data: [GOGPPremium]
    }
  ]
};

export default PGIH;
