import * as actionTypes from "./types";

import CompanyService from "../services/company.service";

export const amlClauseActions = {
  getAmlClauseDetails,
  putAmlClauseDetails,
  postAmlClauseDetails,
  setAmlClauseFormDetails,
  setAmlClauseFromCache,
  setAmlClauseAction
};

function getAmlClauseDetails(quoteId) {
  return (dispatch, getState) => {
    return dispatch({
      type: actionTypes.COMPANY_DETAIL_GET,
      payload: CompanyService.getCompanyDetail(quoteId)
    });
  };
}

function putAmlClauseDetails(payload) {
  return {
    type: actionTypes.COMPANY_DETAIL_PUT,
    payload: CompanyService.putCompanyDetail(payload)
  };
}

function postAmlClauseDetails(payload) {
  return {
    type: actionTypes.COMPANY_DETAIL_POST,
    payload: CompanyService.postCompanyDetail(payload)
  };
}

function setAmlClauseFormDetails(payload) {
  return dispatch => {
    dispatch({ type: actionTypes.QUOTE_SET_SPECIFIC_INDIVIDUALS, payload: payload });
  };
}

function setAmlClauseAction(payload) {
  return dispatch => {
    dispatch({ type: actionTypes.QUOTE_SET_AML_CLAUSE_ACTION, payload: payload });
  };
}

function setAmlClauseFromCache(payload) {
  return dispatch => {
    dispatch({ type: actionTypes.SET_CACHE_TO_COMPANY_DETAIL, payload: payload });
  };
}
