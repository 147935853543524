import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import { injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import SimpleMessageDialog from "../../../../../../components/SimpleMessageDialog";

import EmployeeList from "./EmployeeList";
import EmployeeAdd from "./EmployeeAdd";
import { quoteActions, companyActions, employeeActions } from "../../../../../../actions";
import Enums from "../../../../../../utils/Enums";
import Util from "../../../../../../utils/Util";
import config from "../../../../../../config/config";

class EnrollEmployee extends Component {
  constructor(props) {
    super(props);

    this.previousState = {};

    this.state = {
      errors: null,
      employeeData: [],
      buttonClicked: false,
      prefillErrorMessage: "",
      prefillError: false
    };

    this.handlers = {
      computeActualPremium: Util.debounce(200, this.computeActualPremium)
    };
  }

  handleAddOneByOne = () => {
    this.props.gotoListEmployees({
      showEmployeeList: true,
      shouldShowPopup: true
    });
  };

  prefillMembers = () => {
    this.setState({ buttonClicked: true });
    this.props.getExistingPolicyMemberDetails(this.props.quote.existing_policy_no).then(
      resp => {
        this.props.validateEmployeeData(1);
      },
      error => {
        this.setState({
          prefillError: true,
          prefillErrorMessage: error.message
        });
      }
    );
  };

  handleCloseError = () => {
    this.setState({ prefillError: false });
  };

  componentWillMount() {
    if (this.props.quote.pdpa_consent === true) {
      this.setState({
        buttonClicked: true
      });
    }
    this.handlers.computeActualPremium(this.props);
    this.setPreviousState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      !(nextProps.quote.readOnly || nextProps.document.hasSignedProposal) &&
      (nextProps.quote.data.persons !== this.props.quote.data.persons ||
        nextProps.quote.req_effective_date !== this.previousState.req_effective_date ||
        nextProps.quote.stageValidation[Enums.STAGES.EMPLOYEE] !==
          this.previousState.stageValidation[Enums.STAGES.EMPLOYEE])
    ) {
      this.handlers.computeActualPremium(nextProps);
    }
    this.setPreviousState(nextProps);
  }

  setPreviousState = props => {
    let quoteData = Util.clone({
      req_effective_date: props.quote.req_effective_date,
      stageValidation: props.quote.stageValidation
    });
    this.previousState = {
      ...quoteData
    };
  };

  computeActualPremium = props => {
    const { computeActualPremium, resetActualPremium, quote, intl, document } = props;
    let persons = quote.data.persons;

    if (
      !(props.readOnly || document.hasSignedProposal) &&
      (quote.stageValidation[Enums.STAGES.EMPLOYEE] === false || Util.isEmpty(props.quote.req_effective_date))
    ) {
      //Amount should be reset to 0
      resetActualPremium();
      this.setState({ errors: null });
    } else {
      computeActualPremium().then(
        resp => {
          this.setState({ errors: null });
        },
        error => {
          if (persons.length === 0) {
            this.setState({ errors: null });
          } else {
            this.setState({
              errors: [
                {
                  ...error,
                  title: intl.formatMessage({ id: "validator.premiums.error" })
                }
              ]
            });
          }
        }
      );
    }
  };

  handleEdits = () => {
    this.props.handleHasEdits();
  };

  handlePDPA = () => {
    const { quote, setName, setPDPA, save } = this.props;

    if (this.props.business_type === "CONVERSION" && !config.disable_conversion) {
      setPDPA();
    } else if (
      (!quote.id && !quote.name && this.props.business_type === "NEW") ||
      (this.props.business_type === "CONVERSION" && config.disable_conversion)
    ) {
      setName("Quote 1");
      save().then(() => {
        setPDPA();
      });
    } else setPDPA();
  };

  render() {
    const { quote, employee, pdpa_consent } = this.props;
    const { errors, prefillErrorMessage, prefillError } = this.state;
    let persons = quote.data.persons;

    let contentSection = (
      <EmployeeAdd
        handlerAddOneByOne={this.handleAddOneByOne}
        pdpa_consent={pdpa_consent}
        handlerPDPA={this.handlePDPA}
        prefill={this.prefillMembers}
      />
    );
    if (
      this.props.business_type === Enums.BUSINESS_TYPE.NEW ||
      (this.props.business_type === Enums.BUSINESS_TYPE.CONVERSION && config.disable_conversion)
    ) {
      if ((persons && persons.length > 0) || employee.showEmployeeList)
        contentSection = <EmployeeList employeeData={persons} errors={errors} handleEdits={this.handleEdits} />;
      return (
        <div
          style={{
            width: "100%"
          }}
        >
          {contentSection}
        </div>
      );
    } else if (
      this.props.business_type === Enums.BUSINESS_TYPE.CONVERSION &&
      this.state.buttonClicked === true &&
      !config.disable_conversion
    ) {
      if ((persons && persons.length > 0) || employee.showEmployeeList)
        contentSection = <EmployeeList employeeData={persons} errors={errors} handleEdits={this.handleEdits} />;
      return (
        <div
          style={{
            width: "100%"
          }}
        >
          {contentSection}
          <SimpleMessageDialog
            type="error"
            name="prefill-fail"
            title="Prefill Member Fail!"
            description={`${prefillErrorMessage}`}
            closeButtonText="Close"
            closeHandler={this.handleCloseError.bind(this)}
            open={prefillError}
          />
        </div>
      );
    } else {
      return (
        <div
          style={{
            width: "100%"
          }}
        >
          {contentSection}
        </div>
      );
    }
  }
}

const styles = theme => ({});

function mapStateToProps(state) {
  return {
    quote: state.quote,
    employee: state.quote.employee,
    user: state.user,
    pdpa_consent: state.quote.pdpa_consent,
    readOnly: state.quote.readOnly,
    document: state.document,
    business_type: state.quote.business_type,
    company: state.company
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(quoteActions, dispatch),
    ...bindActionCreators(employeeActions, dispatch),
    ...bindActionCreators(companyActions, dispatch)
  };
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(EnrollEmployee))
);
