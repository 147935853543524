import * as actionTypes from "../actions/types";

const initialState = {
  company_name: "",
  policy_no: "",
  expiry_date: "",
  renewal_type: "",
  contract_type: "",
  status: "",
  id: null,
  has_hr_dec_changed: false
};

export const policyRenew = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_RENEW_POLICY:
      return {
        ...state,
        company_name: action.payload.company_name,
        expiry_date: action.payload.expiry_date,
        policy_no: action.payload.policy_no,
        contract_type: action.payload.contract_type,
        status: action.payload.status,
        id: action.payload.id,
        has_hr_dec_changed: action.payload.has_hr_dec_changed
      };
    case actionTypes.SET_RENEW_POLICY_TYPE:
      return {
        ...state,
        renewal_type: action.payload.renewal_type
      };
    case actionTypes.SET_RENEW_HAS_HR_DEC_CHANGED:
      return {
        ...state,
        has_hr_dec_changed: action.payload
      };
    case actionTypes.SET_RENEW_ID:
      return {
        ...state,
        id: action.payload
      };
    case actionTypes.SET_RENEW_STATUS:
      return {
        ...state,
        status: action.payload
      };
    default:
      return state;
  }
};
