import React, { Component } from "react";
import { injectIntl } from "react-intl";
import classNames from "classnames";
import AlertTopBar from "../components/AlertTopBar";
import { withStyles } from "@mui/styles";
import FormHelperText from "@mui/material/FormHelperText";
import Util from "../utils/Util";

class ErrorSummary extends Component {
  render() {
    const { classes, errors, title } = this.props;
    let finalTitle = title || "Please resolve the following errors";
    if (Util.isEmpty(errors)) return null;
    return (
      <div className={classes.root}>
        <AlertTopBar type="error" title={finalTitle} listDescription={errors} />
      </div>
    );
  }
}
const styles = theme => ({
  root: {
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: "0.75rem"
  }
});
export default injectIntl(withStyles(styles, { withTheme: true })(ErrorSummary));
