import Enums from "../../../../../utils/Enums";

const GLTBenefits = {
  headers: [
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "30%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1,
      info: `Health declaration required.`
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Group Term Life<br></strong>`,
          info: `<em>(Double Death Benefits payable in the event of </em><em>death due to accident in a public land conveyance)</em><br><ul>
              <li>Death</li>
              <li>Total and Permanent Disability (TPD)</li>
              <li>Terminal Illness (TI)</li>
              <li>Double Indemnity</li>
              </ul>`
        },
        {
          data: `S$ 500,000`
        },
        {
          data: `S$ 200,000`
        },
        {
          data: `S$ 150,000`
        },
        {
          data: `S$ 80,000`
        },
        {
          data: `S$ 50,000`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>Repatriation of Mortal Remains</strong>`,
          info: `<em>(per member)</em>`
        },
        {
          data: `S$ 50,000`
        },
        {
          data: `S$ 50,000`
        },
        {
          data: `S$ 50,000`
        },
        {
          data: `S$ 50,000`
        },
        {
          data: `S$ 50,000`
        }
      ]
    },
    {
      id: "",
      columns: [
        {
          data: ``
        },
        {
          data: `<div style=" color:red;opacity: 1.0 !important;">Repatriation of Mortal Remains is enhanced from $50,000 
          to $75,000 for policies that commences cover on/after 1 Jan 2021 and for renewed policies with policy anniversary date on/after 1 Jan 2021.</div>`,
          attributes: {
            colspan: "5",
            overwrite: true,
            style: {
              opacity: 1.0
            }
          }
        }
      ]
    }
  ],
  footnotes: []
};

let GLTPremium = {
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 19</strong>`
        },
        {
          data: `S$ 388.00`
        },
        {
          data: `S$ 156.00`
        },
        {
          data: `S$ 117.00`
        },
        {
          data: `S$ 73.00`
        },
        {
          data: `S$ 39.00`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>20 - 24</strong>`
        },
        {
          data: `S$ 379.00`
        },
        {
          data: `S$ 156.00`
        },
        {
          data: `S$ 117.00`
        },
        {
          data: `S$ 69.00`
        },
        {
          data: `S$ 39.00`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>25 - 29</strong>`
        },
        {
          data: `S$ 333.00`
        },
        {
          data: `S$ 156.00`
        },
        {
          data: `S$ 117.00`
        },
        {
          data: `S$ 61.00`
        },
        {
          data: `S$ 39.00`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>30 - 34</strong>`
        },
        {
          data: `S$ 372.00`
        },
        {
          data: `S$ 156.00`
        },
        {
          data: `S$ 117.00`
        },
        {
          data: `S$ 68.00`
        },
        {
          data: `S$ 39.00`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>35 - 39</strong>`
        },
        {
          data: `S$ 417.00`
        },
        {
          data: `S$ 185.00`
        },
        {
          data: `S$ 139.00`
        },
        {
          data: `S$ 77.00`
        },
        {
          data: `S$ 47.00`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>40 - 44</strong>`
        },
        {
          data: `S$ 574.00`
        },
        {
          data: `S$ 277.00`
        },
        {
          data: `S$ 215.00`
        },
        {
          data: `S$ 108.00`
        },
        {
          data: `S$ 73.00`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>45 - 49</strong>`
        },
        {
          data: `S$ 944.00`
        },
        {
          data: `S$ 431.00`
        },
        {
          data: `S$ 324.00`
        },
        {
          data: `S$ 174.00`
        },
        {
          data: `S$ 108.00`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>50 - 54</strong>`
        },
        {
          data: `S$ 1,611.00`
        },
        {
          data: `S$ 730.00`
        },
        {
          data: `S$ 548.00`
        },
        {
          data: `S$ 295.00`
        },
        {
          data: `S$ 183.00`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>55 - 59</strong>`
        },
        {
          data: `S$ 2,582.00`
        },
        {
          data: `S$ 1,246.00`
        },
        {
          data: `S$ 964.00`
        },
        {
          data: `S$ 479.00`
        },
        {
          data: `S$ 323.00`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>60 - 64</strong>`
        },
        {
          data: `S$ 4,320.00`
        },
        {
          data: `S$ 2,084.00`
        },
        {
          data: `S$ 1,612.00`
        },
        {
          data: `S$ 801.00`
        },
        {
          data: `S$ 539.00`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>65 - 69</strong>`
        },
        {
          data: `S$ 7,207.00`
        },
        {
          data: `S$ 3,480.00`
        },
        {
          data: `S$ 2,692.00`
        },
        {
          data: `S$ 1,337.00`
        },
        {
          data: `S$ 899.00`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only.</em>`
        },
        {
          data: `S$ 13,856.00`
        },
        {
          data: `S$ 6,688.00`
        },
        {
          data: `S$ 5,171.00`
        },
        {
          data: `S$ 2,566.00`
        },
        {
          data: `S$ 1,726.00`
        }
      ]
    }
  ],
  footnotes: []
};

let GLTGroupDiscount = {
  headers: [
    {
      title: `Group Size <i>Insured Employee<i>`,
      mandatory: true
    },
    {
      title: `Discount`,
      mandatory: true
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>11 - 15</strong>`
        },
        {
          data: `5%`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>16 and above</strong>`
        },
        {
          data: `10%`
        }
      ]
    }
  ],
  footnotes: []
};

//   ----------------------------------------------------------------------

const GTL = {
  id: 5,
  code: "GTLB",
  label: "Group Term Life (GTL)",
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GLTBenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium",
      data: [GLTPremium]
    }
    /*{
      id: 3,
      order: 3,
      type: Enums.PLANS_TYPE.GROUP_DISCOUNT,
      label: "Group Size Discount",
      data: [GLTGroupDiscount]
    }*/
  ]
};

export default GTL;
