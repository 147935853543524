import * as actionTypes from "../actions/types";

import Enums from "../utils/Enums";
import Util from "../utils/Util";

let defaultSchema = {
  id: null,
  id_no: "",
  full_name: "",
  gender: "",
  dob: "",
  marital_status: "",
  occupation_class: "",
  bank_account_name: " ",
  bank_account_number: " ",
  bank_name: " ",
  bank_code: " ",
  branch_code: " ",
  no_of_dependent: 0,
  nationality: "",
  country_of_residence: "",
  date_of_employment: "",
  email: "",
  tx_id: null,
  audit_id: null,
  category: "",
  foreign_worker: null,

  type: Enums.PERSON_TYPE.EMPLOYEE,
  dependency_type: Enums.DEPENDENCY_TYPE.NONE,
  dependency_id_no: null,

  deleted: null,
  created_by: null,
  created: null,
  updated_by: null,
  updated: null,
  isValid: false,
  invalid_fields: []
};

let initialState = {
  addEmployee: false,
  showEmployeeList: false,
  shouldShowPopup: false,
  dataIsInvalid: false,
  uploadFileName: "",
  selectedIds: [],
  uploadErrorCode: -1,
  uploadErrorMsg: "",
  errors: null
};

const employee = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EMPLOYEES_LOAD_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          persons: Util.cloneOrigin(action.payload)
        },
        employee: {
          ...state.employee,
          updatedTime: new Date()
        }
      };
    case actionTypes.EMPLOYEES_LOAD_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          persons: []
        },
        employee: {
          ...state.employee,
          failedTime: new Date()
        }
      };
    case actionTypes.EMPLOYEES_SET:
      return {
        ...state,
        employee: {
          ...state.employee,
          employees: action.payload
        }
      };
    case actionTypes.EMPLOYEE_UPLOAD_REQUEST:
      return {
        ...state,
        employee: {
          ...state.employee,
          uploading: true,
          uploadErrorCode: -1,
          uploadErrorMsg: ""
        }
      };
    case actionTypes.EMPLOYEE_UPLOAD_SUCCESS: {
      let _persons = action.payload.data;
      const dataIsInvalid = _persons.filter(d => d.isValid === false).length > 0;
      _persons = _persons.map((person, idx) => {
        return {
          ...defaultSchema,
          ...person
        };
      });

      return {
        ...state,
        data: {
          ...state.data,
          persons: Util.cloneOrigin(_persons)
        },
        employee: {
          ...state.employee,
          uploading: false,
          addEmployee: true,
          uploadFileName: action.payload.fileName,
          success: true,
          dataIsInvalid: dataIsInvalid,
          uploadErrorCode: -1,
          uploadErrorMsg: ""
        }
      };
    }
    case actionTypes.EMPLOYEE_VALIDATE_DATA:
      let _persons = action.payload.persons;
      return {
        ...state,
        data: {
          ...state.data,
          persons: Util.cloneOrigin(action.payload.persons)
        },
        employee: {
          ...state.employee,
          dataIsInvalid: action.payload.dataIsInvalid
        }
      };
    case actionTypes.EMPLOYEE_REVALIDATED_FLAG:
      return {
        ...state,
        employee: {
          ...state.employee,
          revalidated: false
        }
      };
    case actionTypes.EMPLOYEE_UPLOAD_ERROR:
      return {
        ...state,
        employee: {
          ...state.employee,
          uploading: false,
          addEmployee: true,
          success: false,
          uploadErrorCode: action.error.code,
          uploadErrorMsg: action.error.message
        }
      };
    case actionTypes.EMPLOYEES_SET_SELECTED:
      return {
        ...state,
        employee: {
          ...state.employee,
          selectedIds: action.payload
        }
      };
    case actionTypes.EMPLOYEE_LIST_SCREEN:
      return {
        ...state,
        employee: {
          ...state.employee,
          showEmployeeList: action.payload.showEmployeeList,
          shouldShowPopup: action.payload.shouldShowPopup
        }
      };
    case actionTypes.EMPLOYEE_DELETE_SUCCESS:
      return {
        ...state,
        employee: {
          ...state.employee,
          selectedIds: []
        }
      };
    case actionTypes.EMPLOYEE_SAVE_REQUEST: {
      const index = action.payload.index;
      const record = action.payload.record;

      let records = state.data.persons.slice(0);
      records[index] = {
        ...defaultSchema,
        ...record
      };
      return {
        ...state,
        data: {
          ...state.data,
          persons: Util.cloneOrigin(records)
        },
        employee: {
          ...state.employee,
          selectedIds: []
        }
      };
    }
    case actionTypes.EMPLOYEE_SAVE_SUCCESS:
      return {
        ...state,
        employee: {
          ...state.employee,
          selectedIds: []
        }
      };

    case actionTypes.EMPLOYEE_SAVE_ERROR:
      return {
        ...state,
        employee: {
          ...state.employee,
          selectedIds: []
        }
      };

    case actionTypes.EMPLOYEE_ADD_REQUEST: {
      const record = action.payload.record;
      let records = state.data.persons.slice(0);

      let newRecord = {
        ...defaultSchema,
        ...record
      };

      records.push(newRecord);
      return {
        ...state,
        data: {
          ...state.data,
          persons: Util.cloneOrigin(records)
        },
        employee: {
          ...state.employee,
          selectedIds: []
        }
      };
    }

    case actionTypes.EMPLOYEE_MOVE_SUCCESS:
      return {
        ...state,
        selectedIds: []
      };
    case actionTypes.EMPLOYEE_DELETE_REQUEST: {
      const ids = action.payload.ids;

      //need to sort before go to delete
      ids.sort(function(a, b) {
        return a - b;
      });
      let records = state.data.persons.slice(0);
      for (var i = ids.length - 1; i >= 0; i--) {
        records.splice(ids[i], 1);
      }

      return {
        ...state,
        data: {
          ...state.data,
          persons: Util.cloneOrigin(records)
        },
        employee: {
          ...state.employee,
          selectedIds: []
        }
      };
    }

    case actionTypes.EMPLOYEE_MOVE_REQUEST: {
      let records = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          persons: Util.cloneOrigin(records)
        },
        employee: {
          ...state.employee,
          selectedIds: []
        }
      };
    }

    case actionTypes.EMPLOYEE_SET_DEPENDENTS: {
      return {
        ...state,
        data: {
          ...state.data,
          persons: Util.cloneOrigin(action.payload)
        }
      };
    }

    case actionTypes.EMPLOYEE_SET_ERRORS: {
      return {
        ...state,
        employee: {
          ...state.employee,
          errors: action.payload
        }
      };
    }
    default:
      return state;
  }
};

export { initialState, employee };
