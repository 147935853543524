import GTL from "./GTL";
import GHS from "./GHS";
import GFWM from "./GFWM";
import GTL_2021 from "./GTL_2021";
import GHS_2021 from "./GHS_2021";
import GTL_HR from "./GTL_HR";
import GHS_HR from "./GHS_HR";
import GHS_HR_PF3 from "./GHS_HR_PF3";
import GTL_HR_PF3 from "./GTL_HR_PF3";
import GFWM_HR_PF3 from "./GFWM_HR_PF3";
import GHS_2021_HR from "./GHS_2021_HR";
// Plans ----------------------
const CorePlans = [
  GTL,
  GHS,
  GFWM,
  GTL_2021,
  GHS_2021,
  GTL_HR,
  GHS_HR,
  GTL_HR_PF3,
  GHS_HR_PF3,
  GFWM_HR_PF3,
  GHS_2021_HR
];

export default CorePlans;
