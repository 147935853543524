export default [
  "Singapore",
  "Brunei",
  "Indonesia (Jakarta Only)",
  "Japan",
  "Malaysia",
  "Macau",
  "Philippines (Manila Only)",
  "People’s Republic of China (except Xinjiang and Tibet)",
  "South Korea",
  "Taiwan",
  "Thailand"
];
