import React from "react";
import { connect } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { injectIntl } from "react-intl";
import { withStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

class LoadingPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    };
  }

  render() {
    const { classes, name, intl } = this.props;

    return (
      <div>
        <Dialog
          open={this.props.open}
          classes={{ paper: classes.paper }}
          maxWidth="xs"
          aria-labelledby={`dialog-${name}-loading`}
        >
          <DialogTitle id={`dialog-${name}-loading`} disableTypography={true} classes={{ root: classes.dialogTitle }}>
            <CircularProgress color="primary" className={classes.circularProgress} />
            <Typography variant="title">
              {intl.formatMessage({
                id: this.props.title || "popup.loading.default.title"
              })}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="subheading" color="textSecondary" gutterBottom>
              {intl.formatMessage({
                id: this.props.description || "popup.loading.default.description"
              })}
            </Typography>
            <Typography variant="subheading" color="textSecondary" gutterBottom>
              {intl.formatMessage({
                id: this.props.subDescription || "popup.loading.default.subDescription"
              })}
            </Typography>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const styles = theme => ({
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 15
  },
  paper: {
    minWidth: 360
  },
  circularProgress: {
    marginRight: 15
  }
});
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(LoadingPopup)));
