import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withStyles } from "@mui/styles";
import { injectIntl } from "react-intl";
import Typography from "@mui/material/Typography";
import TableCompanyHR from "../../../../components/hr/company/TableCompanyHR";

class InsuranceCoverageListCompanyHR extends Component {
  constructor(props) {
    super(props);
  }

  goToPage = (company, policyNumber, companyId, productCode, productVersion) => {
    if (productCode === "PCE" || productCode === "GCP") return;
    this.props.history.push(`/auth/insuranceCoverage/detail`, {
      company,
      policyNumber,
      companyId,
      productCode,
      productVersion
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography variant="h5" fontWeight={700}>
          Insurance Coverage
        </Typography>
        <Typography mt={3}>
          View all your clients insurance coverage via the <span className={classes.subText}>Policy Number</span> or{" "}
          <span className={classes.subText}>Company Name</span>
        </Typography>
        <TableCompanyHR goToPage={this.goToPage} clients={this.props.clients} />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    height: "100%",
    paddingTop: "40px",
    paddingLeft: "40px",
    paddingRight: "40px"
  },
  subText: {
    fontWeight: "bold"
  }
});

function mapStateToProps(state) {
  return {
    clients: state.HR.clientsAndPolicyListing.clients
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectIntl(withStyles(styles, { withTheme: true })(InsuranceCoverageListCompanyHR)))
);
