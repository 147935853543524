import React, { Component } from "react";
import { employeeActions, myEmployeeActions } from "../../../../../../actions";
import { bindActionCreators } from "redux";
import { ValidatorForm } from "react-material-ui-form-validator";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import LoadingPopup from "../../../../../../components/LoadingPopup";
import DependentEditForm from "./DependentEditForm";
import Util from "../../../../../../utils/Util";
import Enums from "../../../../../../utils/Enums";
import MyEmployeeService from "../../../../../../services/HR/myemployee.service";

const FORMAT_DATE = "DD/MM/YYYY";
const DEPENDANT_PLAN_ERROR = "Dependant Plan Error:";

class DependentEditContainer extends Component {
  constructor(props) {
    super(props);

    this.emptyData = {
      isNewRecord: true,
      full_name: "",
      category: "",
      id_no: "",
      dob: null,
      coverage_start_date: null,
      coverage_end_date: null,
      nationality: "",
      type: "",
      gender: "",
      errors: {}
    };

    this.handlers = {
      validation: Util.debounce(200, this.validation)
    };

    this.state = {
      validationOnly: false,
      dependents: [],
      formErrors: null,
      dirtyField: "",
      validationErrors: [],
      errorMessages: { coverageStartDateError: [], maxAgeCoverError: [], coverageEndDateError: [] }
    };
    this.oldValues = {};

    props.innerRef?.(this);
  }

  componentWillMount() {
    const { employeeData, dependentData, coverage } = this.props;
    const noOfDependants = (employeeData && employeeData.no_of_dependent) || 0;
    if (noOfDependants && noOfDependants > 0 && (!dependentData || dependentData.length === 0)) {
      // Synchronously retrieve dependants details
      MyEmployeeService.getMyEmployeeDependants(employeeData.person_id, coverage.policy.policy_no).then(resp => {
        if (resp) {
          this.setDependent(resp.data);
        }
      });
    } else {
      this.setDependent(dependentData);
    }
  }

  componentDidMount() {
    if (!this.props.readOnly) {
      setTimeout(() => {
        this.validateAndSubmit(true);
        this.oldValues = JSON.parse(JSON.stringify(this.state.dependents));
      }, 500);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.employeeData !== this.props.employeeData) {
      let _dependents = this.state.dependents.map((dependent, _idx) => {
        dependent.category = nextProps.employeeData.category;
        return dependent;
      });
      this.setDependent(_dependents);
    }
  }

  validateAndSubmit = (validationOnly = false) => {
    this.setState({ validationOnly: validationOnly }, () => {
      this.handlers.validation();
      this.refs.form.submit();
    });
  };

  handleSubmit = () => {
    let dependents = this.state.dependents.map((d, idx) => {
      return {
        ...d,
        invalid_fields: [],
        isValid: true
      };
    });

    // PACSEB368 Enable Dependant Validation Fix
    let validationError = false;
    if (this.props.app.type == Enums.APP_TYPE.HR) {
      if (this.state.validationErrors.length > 0) {
        this.state.validationErrors.find(x => {
          if (x.title == DEPENDANT_PLAN_ERROR) {
            validationError = true;
          }
        });
      }
    } else if (this.props.app.type == Enums.APP_TYPE.SALES) {
      validationError = false;
    }
    if (!this.state.validationOnly && !validationError) {
      setTimeout(() => {
        this.props.onSubmitCompleted("dependents", dependents);
      }, 50);
    }
  };

  handleChange = (idx, event) => {
    let target = event.target;
    let dependents = this.state.dependents.map((dependent, _idx) => {
      let d = dependent;
      if (idx === _idx) {
        let name = target.name.replace(`[${idx}]`, "");
        d[name] = target.value;
      }
      return d;
    });
    this.setDependent(dependents, true);
  };

  handleDateChange = (idx, name) => date => {
    let dependents = this.state.dependents.map((dependent, _idx) => {
      if (idx === _idx) {
        dependent[name] = (date && moment(date).format(FORMAT_DATE)) || null;
        this.setState({ dirtyField: name + _idx });
      }
      return dependent;
    });
    this.setDependent(dependents, true);
  };

  handleResetValue = (idx, name) => () => {
    let dependents = this.state.dependents.map((dependent, _idx) => {
      if (idx === _idx) {
        dependent[name] = "";
      }
      return dependent;
    });
    this.setDependent(dependents);
  };

  handleDelete = idx => {
    let dependents = this.state.dependents.filter((c, _idx) => _idx !== idx);
    this.setDependent(dependents, true);
  };

  handleTerminate = idx => {
    this.props.handleDependentTermination();
  };

  handleAdd = () => {
    let dependents = Object.assign({}, this.emptyData);
    if (this.props.employeeData) {
      dependents.category = this.props.employeeData.category;
    }
    this.setDependent([...this.state.dependents, dependents], true);
  };

  setDependent(dependents, hasFormChanged = false) {
    this.setState({ dependents }, () => {
      this.handlers.validation();
      if (hasFormChanged) {
        this.props.handleDirtyForm(JSON.stringify(this.state.dependents) === JSON.stringify(this.oldValues));
      }
    });
  }

  validation = () => {
    const { intl, categories, employeeData } = this.props;
    const { dependents } = this.state;
    let errors = [];

    if (dependents && dependents.length > 0) {
      //Check if dependent plan enabled
      let isDependentPlanEnabled = this.isDependentPlanEnabled(categories, employeeData);
      if (!isDependentPlanEnabled) {
        errors.push({
          title: "Dependant Plan Error:",
          msg: intl.formatMessage({ id: "validator.enableDependent" })
        });
      }

      //Check multiple spouse
      if (dependents.filter(d => d.type === Enums.PERSON_TYPE.SPOUSE).length > 1) {
        errors.push({
          title: "Multiple Spouse Error:",
          msg: intl.formatMessage({ id: "validator.multipleSpouse" })
        });
      }
    }

    this.setState({ validationErrors: errors }, () => {
      this.props.handleFormErrors("dependent", errors);
    });
  };

  isDependentPlanEnabled = (categories, employeeData) => {
    //Check if dependent plan enabled
    let isDependentPlanEnabled = false;
    categories.every(category => {
      if (employeeData && category.name === employeeData.category) {
        let isEnabled = !Util.isEmpty(
          category.plansConfiguration.find(planConfig => planConfig.enableDependent === true)
        );
        if (isEnabled && category.enableDependent) {
          isDependentPlanEnabled = true;
        }
        return false;
      } else {
        return true;
      }
    });
    return isDependentPlanEnabled;
  };

  render() {
    const { classes, readOnly, categories, show } = this.props;
    const { dependents, validationErrors } = this.state;
    const noOfDependents = dependents && dependents.length;

    return (
      <div>
        <LoadingPopup
          title="popup.loading.submitEmployee.title"
          name="send-employee-loading"
          open={this.state.showLoadingPopup}
        />
        <div className={classes.toolbar}>
          <Typography className={classes.toolbarInfo}>Number of Dependants {noOfDependents}</Typography>
          <Button
            className={classes.toolbarBtn}
            variant="contained"
            color="primary"
            onClick={this.handleAdd.bind(this)}
            disabled={readOnly}
          >
            <Icon className={classes.leftIcon}>add</Icon>
            Add Dependants
          </Button>
        </div>

        <ValidatorForm
          ref="form"
          onSubmit={this.handleSubmit}
          onError={errors => this.props.handleFormErrors("dependent", errors)}
          className={show ? null : classes.hidden}
        >
          {dependents.map((dependent, idx) => {
            return (
              <DependentEditForm
                key={idx}
                handleChange={this.handleChange}
                handleChangeWithoutEvent={this.handleChangeWithoutEvent}
                handleDateChange={this.handleDateChange.bind(this)}
                handleDelete={this.handleDelete.bind(this)}
                handleResetValue={this.handleResetValue.bind(this)}
                handleTerminate={this.handleTerminate.bind(this)}
                validationRules={this.props.validationRules}
                data={dependent}
                errorMessages={this.state.errorMessages}
                categories={categories}
                readOnly={readOnly}
                isFormDirty={this.props.isFormDirty}
                dirtyField={this.state.dirtyField}
                index={idx}
              />
            );
          })}
        </ValidatorForm>
      </div>
    );
  }
}

const styles = theme => ({
  root: {},
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: `${theme.spacing.unit * 2}px`
  },
  toolbarBtn: {
    alignSelf: "flex-end"
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  hidden: {
    display: "none"
  }
});

function mapStateToProps(state, prop) {
  if (state.app.type === Enums.APP_TYPE.SALES) {
    return {
      app: state.app,
      quote: state.quote,
      products: state.products
    };
  } else if (state.app.type === Enums.APP_TYPE.HR) {
    return {
      app: state.app,
      quote: state.quote,
      products: state.products,
      myEmployee: state.HR.myemployee,
      coverage: state.HR.coverage
    };
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(employeeActions, dispatch),
    ...bindActionCreators(myEmployeeActions, dispatch)
  };
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(DependentEditContainer))
);
