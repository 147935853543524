import SearchIcon from "@mui/icons-material/Search";
import {
  FormControl,
  Input,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import Enums from "../../../../utils/Enums";
import PdfViewerBase64 from "../../../../components/pdfViewerBase64";
import PolicyService from "../../../../services/policy.service";

class PolicyRelatedTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pdf: undefined,
      pdfContentBase64: "",
      fileName: undefined,
      open: false,
      isPDF: false,
      order: Enums.SORT.DESC,
      orderBy: "documentName",
      filteredData: null,
      filterValues: {
        documentName: "",
        dateOfScanning: null
      }
    };

    this.tableHeaders = [
      {
        label: "Document Name",
        field: "documentName",
        filter: this.getTextFilter("documentName")
      },
      {
        label: "Document Upload Date",
        field: "dateOfScanning",
        filter: this.getDateFilter("dateOfScanning")
      }
    ];
  }

  componentDidUpdate(prevProps) {
    if (this.props.documents !== prevProps.documents) {
      this.handleDocumentSort(this.state.orderBy)();
    }
  }

  getTextFilter = name => classes => {
    return (
      <FormControl>
        <Input
          value={this.state.filterValues[name]}
          onChange={this.handleFilterChange(name)}
          classes={{
            root: classes.textRoot
          }}
          onKeyPress={event => {
            if (event.key === "Enter") {
              event.preventDefault();
              this.handleDocumentFilter(name);
            }
          }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon className={classes.filterIcon} />
            </InputAdornment>
          }
        />
      </FormControl>
    );
  };

  getDateFilter = name => classes => {
    return (
      <DatePicker
        views={["year"]}
        format="YYYY"
        placeholder="YYYY"
        value={this.state.filterValues[name]}
        onChange={selectedYear => this.handleDateChange(selectedYear, name)}
        className={classes.datePicker}
        slotProps={{
          textField: {
            sx: {
              width: 180,
              backgroundColor: "white",
              borderRadius: "5px",
              "& .MuiInputBase-input": {
                height: 12
              }
            }
          },
          openPickerIcon: { fontSize: "small" }
        }}
      />
    );
  };

  handleFilterChange = name => event => {
    this.state.filterValues[name] = event.target.value;
    this.setState({ filterValues: this.state.filterValues });
  };

  handleDateChange = (selectedYear, name) => {
    this.state.filterValues[name] = selectedYear;
    this.setState({ filterValues: this.state.filterValues });
    this.handleDocumentFilter(name);
  };

  handleDocumentSort = columnName => () => {
    const order = this.state.order === Enums.SORT.DESC ? Enums.SORT.ASC : Enums.SORT.DESC;

    const data = this.state.filteredData ? this.state.filteredData : this.props.documents;
    let sortedData;
    if (columnName === "dateOfScanning") {
      sortedData = [...data].sort((a, b) => {
        return order === Enums.SORT.ASC
          ? new Date(a[columnName]) - new Date(b[columnName])
          : new Date(b[columnName]) - new Date(a[columnName]);
      });
    } else {
      sortedData = [...data].sort((a, b) => {
        const aValue = a[columnName] || "";
        const bValue = b[columnName] || "";
        return order === Enums.SORT.ASC ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      });
    }
    this.setState({ filteredData: sortedData, order, orderBy: columnName });
  };

  // filter function
  handleDocumentFilter = columnName => {
    // Only allow one column to filter
    let filterValues = this.state.filterValues;
    Object.entries(filterValues).forEach(([key, value]) => {
      if (columnName !== key) {
        key === "dateOfScanning" ? (filterValues[key] = null) : (filterValues[key] = "");
      }
    });
    this.setState({ filterValues });

    // to show all the policy data if both are empty value
    if (!filterValues.documentName && !filterValues.dateOfScanning) {
      this.setState({ filteredData: this.props.documents });
      return;
    }

    // filter logic
    const filteredData = this.props.documents.filter(document => {
      if (document[columnName] && columnName === "documentName") {
        return document[columnName].toLowerCase().includes(filterValues.documentName);
      }

      if (document[columnName] && columnName === "dateOfScanning") {
        return moment(document[columnName])
          .format("YYYY")
          .includes(moment(filterValues.dateOfScanning).format("YYYY"));
      }
    });
    this.setState({ filteredData: filteredData });
    this.handleDocumentSort(columnName);
  };

  handleClick = async (policyNo, documentId) => {
    let result = await PolicyService.getPolicyRelatedDocument(policyNo, documentId);

    let isPDF = result.data.file_mime_type === "application/pdf" ? true : false;

    let pdfContentBase64 = result.data.file_content;
    let file = `data:${result.data.file_mime_type};base64,${result.data.file_content}`;

    this.setState({ pdf: file, pdfContentBase64, fileName: result.data.file_name, open: true, isPDF: isPDF });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  renderDataCell(data, classes, index) {
    return (
      <TableRow
        key={index}
        onClick={() => {
          this.handleClick(this.props.policyNo, data.itemId);
        }}
      >
        <TableCell className={classes.dataRowName}>{isEmpty(data.documentName) ? "-" : data.documentName}</TableCell>
        <TableCell align="center" className={classes.dataRow}>
          {isEmpty(data.dateOfScanning) ? "-" : moment(data.dateOfScanning).format("DD/MM/YYYY")}
        </TableCell>
      </TableRow>
    );
  }

  render() {
    const { classes, documents } = this.props;
    const tableData = this.state.filteredData ? this.state.filteredData : documents;
    return (
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {this.tableHeaders.map((header, index) => (
                <TableCell className={classes.header} key={index}>
                  <TableSortLabel
                    className={classes.tableSortLabel}
                    active={this.state.orderBy === header.field}
                    direction={this.state.order}
                    onClick={this.handleDocumentSort(header.field)}
                  >
                    <div>{header.label}</div>
                  </TableSortLabel>
                  <br />
                  {header.filter(classes)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!tableData || tableData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={this.tableHeaders.length} className={classes.noDocumentsMessage}>
                  No documents available
                </TableCell>
              </TableRow>
            ) : (
              tableData.map((d, index) => this.renderDataCell(d, classes, index))
            )}
          </TableBody>
        </Table>
        <PdfViewerBase64
          open={this.state.open}
          handleClose={this.handleClose}
          pdf={this.state.pdf}
          pdfContentBase64={this.state.pdfContentBase64}
          isPDF={this.state.isPDF}
          fileName={this.state.fileName}
        />
      </TableContainer>
    );
  }
}

const styles = theme => ({
  header: {
    backgroundColor: "black",
    color: "white !important",
    fontWeight: "bold",
    width: "50%"
  },
  table: {
    "& th, & td": {
      border: "1px solid rgba(224, 224, 224, 1)"
    }
  },
  dataRow: {
    backgroundColor: "white",
    cursor: "pointer"
  },
  dataRowName: {
    backgroundColor: "white",
    cursor: "pointer",
    textDecoration: "underline"
  },
  noDocumentsMessage: {
    backgroundColor: "white",
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.5)"
  },
  textRoot: {
    fontSize: "0.75rem",
    fontWeight: 400,
    color: "black",
    backgroundColor: "white"
  },
  filterIcon: {
    paddingLeft: "5px",
    color: "black"
  },
  tableSortLabel: {
    "&.Mui-active": {
      color: "white"
    },
    "& .MuiTableSortLabel-icon": {
      color: "inherit !important"
    }
  }
});

export default withStyles(styles, { withTheme: true })(PolicyRelatedTable);
