import { Tab } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { Component } from "react";

class PolicyDocumentTabItem extends Component {
  render() {
    const { label, value, currentTab, activeClass, ...otherProps } = this.props;
    return <Tab label={label} value={value} className={currentTab === value ? activeClass : ""} {...otherProps} />;
  }
}

const styles = theme => ({});

export default withStyles(styles, { withTheme: true })(PolicyDocumentTabItem);
