import React from "react";
import Dropzone from "react-dropzone";
import { withStyles } from "@mui/styles";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import classNames from "classnames";

import { FileUpload as FileUploadIcon } from "@mui/icons-material";
import FilesUploader from "./FilesUploader";

const DropZone = ({
  classes,
  disabled,
  label,
  subTitle,
  accept,
  maxSize,
  descr,
  isUploading,
  openConfirmDialog,
  errorMessage,
  onDropRejected,
  onDropHandler,
  multiple
}) => (
  <div className={classes.dropZoneContainer}>
    <Typography variant="subheading" className={classes.title}>
      {label}
    </Typography>
    <Typography variant="subheading" gutterBottom className={classes.subTitle}>
      {subTitle}
    </Typography>
    {errorMessage && (
      <Typography variant="subheading" gutterBottom className={classes.errorMessage}>
        {errorMessage.dialogDescription}
      </Typography>
    )}
    <Dropzone
      disabled={disabled}
      accept={accept}
      maxSize={maxSize}
      onDrop={onDropHandler}
      onDropRejected={onDropRejected}
      className={classNames(classes.dropZone, disabled ? classes.disabled : null)}
      multiple={multiple === true}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          className={classNames(classes.dropZoneInfoWrapper, disabled ? classes.disabled : null)}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <FileUploadIcon className={classes.uploadIcon} />
          <Typography variant="caption" gutterBottom>
            {descr}
          </Typography>
          {isUploading && <LinearProgress />}
        </div>
      )}
    </Dropzone>
  </div>
);

const styles = theme => ({
  dropZoneContainer: {
    margin: "10px 0px 20px 0px"
  },
  title: {
    paddingBottom: 5
  },
  subTitle: {
    fontSize: 13
  },
  dropZone: {
    borderWidth: 2,
    borderColor: theme.colors.blackScale.black100,
    borderStyle: "dashed",
    borderRadius: 5,
    padding: 10
  },
  dropZoneInfoWrapper: {
    display: "flex",
    alignItems: "center",
    padding: "50px",
    textAlign: "center",
    border: "5px dotted",
    padding: "10px",
    borderColor: "#333333",
    borderStyle: "dashed",
    borderWidth: "2px",
    borderRadius: "5px",
    cursor: "pointer"
  },
  uploadIcon: {
    height: 25,
    width: 25,
    margin: "0 10px"
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13
  },
  disabled: {
    borderColor: theme.colors.blackScale.black70,
    color: theme.colors.blackScale.black70
  }
});

export default withStyles(styles, { withTheme: true })(DropZone);
