export default [
  {
    logo: require("../img/policy-details-employees-img.png"),
    id: "myEmployees",
    title: "Member Listing"
  },
  {
    logo: require("../img/policy-details-claims-img.png"),
    id: "claimExpenses",
    title: "Claims"
  },
  {
    logo: require("..//img/policy-details-documents-img.png"),
    id: "documents",
    title: "Documents"
  }
];
