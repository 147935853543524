import React, { Component } from "react";
import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";

import { withStyles } from "@mui/styles";
// import classNames from "classnames";

import Typography from "@mui/material/Typography";
// import { quotesActions } from "actions";

class Tutorial extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Typography variant="title" color="inherit">
            Tutorial
          </Typography>
          <br />
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.actionFooter.height
  },
  content: {
    padding: theme.spacing.unit * 2.5,
    backgroundColor: "#f8f8f8",
    paddingTop: 10
  }
});

function mapStateToProps(state) {
  return { quote: state.quote };
}

function mapDispatchToProps(dispatch) {
  return {
    //...bindActionCreators(quotesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(Tutorial)));
