import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TablePaginationActions from "../MyEmployees/TablePaginationActions";
import "./tooltip.css";
import classNames from "classnames";

const TABLE_ROWS_PER_PAGE = 20;
const TABLE_COLUMNS = [
  {
    label: "Claim Reference",
    field: "claim_reference"
  },
  {
    label: "Claim Number",
    field: "claim_no"
  },
  {
    label: "Company",
    field: "company_name"
  },
  {
    label: "Employee Name",
    field: "employee_name"
  },
  {
    label: "Employee NRIC",
    field: "employee_nric"
  },
  {
    label: "Claimant Name",
    field: "claimant_name"
  },
  {
    label: "Claimant NRIC",
    field: "claimant_nric"
  },
  {
    label: "Relation",
    field: "relation_to_employee"
  },
  {
    label: "Type of Claim",
    field: "claim_type"
  },
  {
    label: "Date of Consultation",
    field: "date_of_consultation"
  },
  {
    label: "Claim Received Date",
    field: "date_received"
  },
  {
    label: "Claim Processed Date",
    field: "claim_process_date"
  },
  {
    label: "Amount Submitted",
    field: "amount_submitted"
  },
  {
    label: "Amount Approved",
    field: "amount_approved"
  },
  {
    label: "Status",
    field: "claim_status"
  }
];

class ClaimExpenseTable extends Component {
  render() {
    const {
      classes,
      isProcessing,
      currentPageNo,
      claimExpenseList: totalPageList,
      handlePageChange,
      handleExport
    } = this.props;
    const offsetStartIdx = Math.floor(currentPageNo * TABLE_ROWS_PER_PAGE);
    const offsetEndIdx = Math.floor(offsetStartIdx + TABLE_ROWS_PER_PAGE - 1);
    const currentPageList = (totalPageList || []).filter((data, idx) => offsetStartIdx <= idx && idx <= offsetEndIdx);

    return (
      <div className={classes.content}>
        <div className={classes.tooltipContent}>
          <Button
            className="tooltip"
            size="small"
            disabled={isProcessing || !(totalPageList && totalPageList.length)}
            onClick={handleExport}
          >
            <Icon>{"get_app"}</Icon>
            <span className="tooltiptext">Export to excel</span>
          </Button>
        </div>

        <div className={classes.tableContent}>
          <Table>
            <TableHead className={classes.tableHeaderRow}>
              <TableRow>
                {TABLE_COLUMNS.map(({ label }, idx) => (
                  <TableCell key={idx} className={classes.tableHeaderCell}>
                    {label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPageList.map((claimExpense, row) => (
                <TableRow key={row}>
                  {TABLE_COLUMNS.map(({ field }, idx) => (
                    <TableCell
                      key={idx}
                      className={classNames(classes.tableBodyCell, { [classes.tableBodyOddCell]: row % 2 !== 0 })}
                    >
                      {claimExpense[field]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={11}
                  count={(totalPageList && totalPageList.length) || 0}
                  rowsPerPage={TABLE_ROWS_PER_PAGE}
                  page={currentPageNo}
                  onPageChange={handlePageChange}
                  ActionsComponent={TablePaginationActions}
                  rowsPerPageOptions={[]}
                  labelDisplayedRows={({ count, page }) => {
                    return `Page ${page + 1} - Total ${count === 0 ? 1 : Math.ceil(count / TABLE_ROWS_PER_PAGE)}`;
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  content: {
    marginTop: 15,
    border: "1px solid #cccccc",
    width: "100.5%"
  },
  tooltipContent: {
    marginTop: 5
  },
  tableContent: {
    marginTop: 5,
    overflowX: "auto"
  },
  tableHeaderRow: {
    backgroundColor: "#ED1B24"
  },
  tableHeaderCell: {
    padding: "0px 8px",
    color: "white",
    border: "1px solid #cccccc",
    fontSize: 14
  },
  tableBodyCell: {
    padding: "0px 8px",
    color: "black",
    border: "1px solid #cccccc"
  },
  tableBodyOddCell: {
    backgroundColor: "rgba(134, 133, 133, 0.1)"
  }
});

export default withStyles(styles, { withTheme: true })(ClaimExpenseTable);
