import * as actionTypes from "../actions/types";
import natureOfBusiness from "../assets/data/natureOfBusiness";
import countries from "../assets/data/countries";
import ENUMS from "../utils/Enums";

const initialState = {
  natureOfBusiness,
  countries,
  files: [],
  renewalFiles: [],
  hasSignedProposal: false
};

export const document = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DOCUMENT_RESET: {
      return {
        ...state,
        files: [],
        hasSignedProposal: false
      };
    }
    case actionTypes.DOCUMENT_ALL_GET_PENDING:
      return {
        ...state
      };
    case actionTypes.DOCUMENT_ALL_GET_FULFILLED:
      return {
        ...state,
        filesLoadedTime: new Date(),
        files: action.payload.data,
        hasSignedProposal:
          action.payload.data.filter(file => file.file_type === ENUMS.FILE_TYPE.ACCEPTANCE_FILE_TYPE).length > 0
      };
    case actionTypes.RENEWAL_DOCUMENT_ALL_GET_PENDING:
      return {
        ...state
      };
    case actionTypes.RENEWAL_DOCUMENT_ALL_GET_FULFILLED:
      return {
        ...state,
        renewalFiles: action.payload.data.data
      };
    case actionTypes.DOCUMENT_ACCEPTANCE_LETTER_UPLOAD_PENDING:
      return {
        ...state,
        acceptanceLetterIsUploading: true,
        acceptanceLetterUploadTime: new Date(),
        acceptanceLetterUploadErrorMessage: ""
      };
    case actionTypes.DOCUMENT_ACCEPTANCE_LETTER_UPLOAD_FULFILLED:
      return {
        ...state,
        acceptanceLetterIsUploading: false,
        acceptanceLetterUploadSuccess: true,
        acceptanceLetterUploadedTime: new Date(),
        acceptanceLetterFile: action.payload.data.name,
        acceptanceLetterUploadErrorMessage: ""
      };
    case actionTypes.DOCUMENT_ACCEPTANCE_LETTER_UPLOAD_REJECTED:
      return {
        ...state,
        acceptanceLetterIsUploading: false,
        acceptanceLetterUploadSuccess: false,
        acceptanceLetterUploadError: true,
        acceptanceLetterUploadErrorMessage: action.payload.message,
        acceptanceLetterFailedTime: new Date()
      };
    case actionTypes.DOCUMENT_ACRA_UPLOAD_PENDING:
      return {
        ...state,
        acraIsUploading: true,
        acraUploadErrorMessage: "",
        acraUploadTime: new Date()
      };
    case actionTypes.DOCUMENT_ACRA_UPLOAD_FULFILLED:
      return {
        ...state,
        acraIsUploading: false,
        acraUploadSuccess: true,
        acraUploadedTime: new Date(),
        acraUploadErrorMessage: "",
        acraFile: action.payload.data.name
      };
    case actionTypes.DOCUMENT_ACRA_UPLOAD_REJECTED:
      return {
        ...state,
        acraIsUploading: false,
        acraUploadSuccess: false,
        acraUploadError: true,
        acraUploadErrorMessage: action.payload.message,
        acraFailedTime: new Date()
      };
    case actionTypes.DOCUMENT_MAS314_UPLOAD_PENDING:
      return {
        ...state,
        mas314IsUploading: true,
        mas314UploadErrorMessage: "",
        mas314UploadTime: new Date()
      };
    case actionTypes.DOCUMENT_MAS314_UPLOAD_FULFILLED:
      return {
        ...state,
        mas314IsUploading: false,
        mas314UploadSuccess: true,
        mas314UploadedTime: new Date(),
        mas314UploadErrorMessage: "",
        mas314File: action.payload.data.name
      };
    case actionTypes.DOCUMENT_MAS314_UPLOAD_REJECTED:
      return {
        ...state,
        mas314IsUploading: false,
        mas314UploadSuccess: false,
        mas314UploadError: true,
        mas314UploadErrorMessage: action.payload.message,
        mas314FailedTime: new Date()
      };
    case actionTypes.DOCUMENT_CORP_BANK_UPLOAD_PENDING:
      return {
        ...state,
        corpBankIsUploading: true,
        corpBankUploadErrorMessage: "",
        corpBankUploadTime: new Date()
      };
    case actionTypes.DOCUMENT_CORP_BANK_UPLOAD_FULFILLED:
      return {
        ...state,
        corpBankIsUploading: false,
        corpBankUploadSuccess: true,
        corpBankUploadTime: new Date(),
        corpBankUploadErrorMessage: "",
        corpBankFile: action.payload.data.name
      };
    case actionTypes.DOCUMENT_CORP_BANK_UPLOAD_REJECTED:
      return {
        ...state,
        corpBankIsUploading: false,
        corpBankUploadSuccess: false,
        corpBankUploadError: true,
        corpBankUploadErrorMessage: action.payload.message,
        corpBankFailedTime: new Date()
      };
    case actionTypes.DOCUMENT_NRIC_IND_UPLOAD_PENDING:
      return {
        ...state,
        NRIC_INDIsUploading: true,
        NRIC_INDUploadErrorMessage: "",
        NRIC_INDUploadTime: new Date()
      };
    case actionTypes.DOCUMENT_NRIC_IND_UPLOAD_FULFILLED:
      return {
        ...state,
        NRIC_INDIsUploading: false,
        NRIC_INDUploadSuccess: true,
        NRIC_INDUploadedTime: new Date(),
        NRIC_INDUploadErrorMessage: "",
        NRIC_INDFile: action.payload.data.name
      };
    case actionTypes.DOCUMENT_NRIC_IND_UPLOAD_REJECTED:
      return {
        ...state,
        NRIC_INDIsUploading: false,
        NRIC_INDUploadSuccess: false,
        NRIC_INDUploadError: true,
        NRIC_INDUploadErrorMessage: action.payload.message,
        NRIC_INDFailedTime: new Date()
      };
    case actionTypes.DOCUMENT_DELETE_PENDING:
      return {
        ...state,
        isDeleting: true
      };
    case actionTypes.DOCUMENT_DELETE_FULFILLED:
      return {
        ...state,
        deletedTime: new Date()
      };
    case actionTypes.DOCUMENT_DELETE_REJECTED:
      return {
        ...state,
        deletedFailedTime: new Date()
      };
    case actionTypes.DOCUMENT_DECLARATION_CLAIM_ENTITLEMENT_UPLOAD:
      return {
        ...state,
        claimDeclarationIsUploading: false
      };
    case actionTypes.DOCUMENT_DECLARATION_CLAIM_ENTITLEMENT_UPLOAD_PENDING:
      return {
        ...state,
        claimDeclarationIsUploading: false
      };
    case actionTypes.DOCUMENT_DECLARATION_CLAIM_ENTITLEMENT_UPLOAD_FULFILLED:
      return {
        ...state,
        claimDeclarationIsUploading: false
      };
    case actionTypes.DOCUMENT_DECLARATION_CLAIM_ENTITLEMENT_UPLOAD_REJECTED:
      return {
        ...state,
        claimDeclarationIsUploading: false
      };
    case actionTypes.LETTER_UPLOAD_PENDING:
      return {
        ...state,
        LetterIsUploading: true,
        LetterUploadErrorMessage: ""
      };
    case actionTypes.LETTER_UPLOAD_FULFILLED:
      return {
        ...state,
        LetterIsUploading: false,
        LetterUploadSuccess: true,
        LetterUploadErrorMessage: ""
      };
    case actionTypes.LETTER_UPLOAD_REJECTED:
      return {
        ...state,
        LetterIsUploading: false,
        LetterUploadSuccess: false,
        LetterUploadError: true,
        LetterUploadErrorMessage: action.payload.message
      };
    case actionTypes.HR_DEC_UPLOAD_PENDING:
      return {
        ...state,
        hrDecIsUploading: true,
        hrDecUploadErrorMessage: ""
      };
    case actionTypes.HR_DEC_UPLOAD_FULFILLED:
      return {
        ...state,
        hrDecIsUploading: false,
        hrDecUploadSuccess: true,
        hrDecUploadErrorMessage: ""
      };
    case actionTypes.HR_DEC_UPLOAD_REJECTED:
      return {
        ...state,
        hrDecIsUploading: false,
        hrDecUploadSuccess: false,
        hrDecUploadError: true,
        hrDecUploadErrorMessage: action.payload.message
      };
    case actionTypes.DEC_ENTITLEMENT_UPLOAD_PENDING:
      return {
        ...state,
        decEntitlementIsUploading: true,
        decEntitlementUploadErrorMessage: ""
      };
    case actionTypes.DEC_ENTITLEMENT_UPLOAD_FULFILLED:
      return {
        ...state,
        decEntitlementIsUploading: false,
        decEntitlementUploadSuccess: true,
        decEntitlementUploadErrorMessage: ""
      };
    case actionTypes.DEC_ENTITLEMENT_UPLOAD_REJECTED:
      return {
        ...state,
        decEntitlementIsUploading: false,
        decEntitlementUploadSuccess: false,
        decEntitlementUploadError: true,
        decEntitlementUploadErrorMessage: action.payload.message
      };
    case actionTypes.DEC_SPECIFIC_UPLOAD_PENDING:
      return {
        ...state,
        decSpecificIsUploading: true,
        decSpecificUploadErrorMessage: ""
      };
    case actionTypes.DEC_SPECIFIC_UPLOAD_FULFILLED:
      return {
        ...state,
        decSpecificIsUploading: false,
        decSpecificUploadSuccess: true,
        decSpecificUploadErrorMessage: ""
      };
    case actionTypes.DEC_SPECIFIC_UPLOAD_REJECTED:
      return {
        ...state,
        decSpecificIsUploading: false,
        decSpecificUploadSuccess: false,
        decSpecificUploadError: true,
        decSpecificUploadErrorMessage: action.payload.message
      };
    case actionTypes.RENEWAL_DOCUMENT_DELETE_PENDING:
      return {
        ...state,
        isDeletingRenewal: true
      };
    case actionTypes.RENEWAL_DOCUMENT_DELETE_FULFILLED:
      return {
        ...state
      };
    case actionTypes.RENEWAL_DOCUMENT_DELETE_REJECTED:
      return {
        ...state
      };
    case actionTypes.MEMBER_UPDATE_UPLOAD_PENDING:
      return {
        ...state,
        memberUpdateIsUploading: true,
        memberUpdateUploadErrorMessage: ""
      };
    case actionTypes.MEMBER_UPDATE_UPLOAD_FULFILLED:
      return {
        ...state,
        memberUpdateIsUploading: false,
        memberUpdateUploadSuccess: true,
        memberUpdateUploadErrorMessage: ""
      };
    case actionTypes.MEMBER_UPDATE_UPLOAD_REJECTED:
      return {
        ...state,
        memberUpdateIsUploading: false,
        memberUpdateUploadSuccess: false,
        memberUpdateUploadError: true,
        memberUpdateUploadErrorMessage: action.payload.message
      };
    case actionTypes.SIGNED_QUO_UPLOAD_PENDING:
      return {
        ...state,
        signedQuoIsUploading: true,
        signedQuoUploadErrorMessage: ""
      };
    case actionTypes.SIGNED_QUO_UPLOAD_FULFILLED:
      return {
        ...state,
        signedQuoIsUploading: false,
        signedQuoUploadSuccess: true,
        signedQuoUploadErrorMessage: ""
      };
    case actionTypes.SIGNED_QUO_UPLOAD_REJECTED:
      return {
        ...state,
        signedQuoIsUploading: false,
        signedQuoUploadSuccess: false,
        signedQuoUploadError: true,
        signedQuoUploadErrorMessage: action.payload.message
      };
    case actionTypes.AUTHO_SIGNATORY_UPLOAD_PENDING:
      return {
        ...state,
        authoSignatoryIsUploading: true,
        authoSignatoryUploadErrorMessage: ""
      };
    case actionTypes.AUTHO_SIGNATORY_UPLOAD_FULFILLED:
      return {
        ...state,
        authoSignatoryIsUploading: false,
        authoSignatoryUploadSuccess: true,
        authoSignatoryUploadErrorMessage: ""
      };
    case actionTypes.AUTHO_SIGNATORY_UPLOAD_REJECTED:
      return {
        ...state,
        authoSignatoryIsUploading: false,
        authoSignatoryUploadSuccess: false,
        authoSignatoryUploadError: true,
        authoSignatoryUploadErrorMessage: action.payload.message
      };
    case actionTypes.ACRA_UPLOAD_PENDING:
      return {
        ...state,
        acraIsUploading: true,
        acraUploadErrorMessage: ""
      };
    case actionTypes.ACRA_UPLOAD_FULFILLED:
      return {
        ...state,
        acraIsUploading: false,
        acraUploadSuccess: true,
        acraUploadErrorMessage: ""
      };
    case actionTypes.ACRA_UPLOAD_REJECTED:
      return {
        ...state,
        acraIsUploading: false,
        acraUploadSuccess: false,
        acraUploadError: true,
        acraUploadErrorMessage: action.payload.message
      };
    case actionTypes.MAS314_UPLOAD_PENDING:
      return {
        ...state,
        mas314IsUploading: true,
        mas314UploadErrorMessage: ""
      };
    case actionTypes.MAS314_UPLOAD_FULFILLED:
      return {
        ...state,
        mas314IsUploading: false,
        mas314UploadSuccess: true,
        mas314UploadErrorMessage: ""
      };
    case actionTypes.MAS314_UPLOAD_REJECTED:
      return {
        ...state,
        mas314IsUploading: false,
        mas314UploadSuccess: false,
        mas314UploadError: true,
        mas314UploadErrorMessage: action.payload.message
      };
    default:
      return state;
  }
};
