import { withRouter } from "react-router";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import muiTheme from "../themes";
import React, { Component } from "react";
import PhoneNumberLink from "./PhoneNumberLink";
import EmailLink from "./EmailLink";
import Typography from "@mui/material/Typography";

class FCCallHelperBottom extends Component {
  render() {
    const { drawerOpen, classes } = this.props;
    return (
      <div className={classNames(classes.menuBottomWrapper, !drawerOpen && classes.menuBottomWrapperClose)}>
        <div className={classes.callContent}>
          <div className={classes.callIcon}>
            <i className="material-icons">call</i>
          </div>
          <div className={classes.callInfo}>
            <Typography className={classes.callText}>Call PruCustomer Line</Typography>
            <PhoneNumberLink number="1800 835 9733" description="(Mon-Fri, 8:30am to 5:30pm)" />
            <PhoneNumberLink number="+65 6233 3299" description="(if you're dialling from overseas)" />
          </div>
        </div>
        <div className={classes.emailContent}>
          <div className={classes.callInfo}>
            <hr />
            <Typography className={classes.emailText}>Email us at</Typography>
            <EmailLink email="SGP.EB.Enquiry@prudential.com.sg" description="" />
          </div>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  menuBottomWrapper: {
    opacity: 1,
    transition: "all 0.2s linear",
    marginTop: "auto"
  },
  menuBottomWrapperClose: {
    position: "relative",
    opacity: 0,
    maxHeight: 0
  },
  callContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: theme.extended.isIE() ? "space-around" : "space-evenly",
    padding: "14px 0px",
    backgroundColor: muiTheme.colors.blackScale.black50
  },
  callIcon: {
    width: 45,
    height: 45,
    borderRadius: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: muiTheme.colors.secondary.white,
    backgroundColor: muiTheme.colors.primary.red
  },
  emailText: {
    color: "#6A6B6D",
    textAlign: "center",
    fontSize: 14
  },
  callText: {
    color: "#6A6B6D",
    textAlign: "center",
    fontSize: 14
  },
  emailContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: theme.extended.isIE() ? "space-around" : "space-evenly",
    padding: "14px 0px",
    backgroundColor: muiTheme.colors.blackScale.black50
  }
});

export default withRouter(withStyles(styles, { withTheme: true })(FCCallHelperBottom));
