import { ValidatorForm } from "react-material-ui-form-validator";
import React, { Component } from "react";

var injectValidator = ComposedComponent => {
  return class Validator extends Component {
    componentWillMount() {
      ValidatorForm.addValidationRule("isTrim", value => {
        return value.trim() === "" ? false : true;
      });
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  };
};

export default injectValidator;
