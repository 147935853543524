import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, Radio } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import DropZone from "../../components/Quote/containers/SubmitDoc/DropZone";
import RenderFileOrUploader from "../../components/Quote/containers/SubmitDoc/RenderFileOrUploader";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import config from "../../../../config/config";
import { CheckBox } from "@mui/icons-material";
import { injectIntl } from "react-intl";
import ENUMS from "../../../../utils/Enums";
import Util from "../../../../utils/Util";
import RenderMultipleFileUploader from "../../components/Quote/containers/SubmitDoc/RenderMultipleFileUploader";

import {
  LETTER_UPLOAD,
  HR_DEC_UPLOAD,
  DEC_SPECIFIC_UPLOAD,
  DEC_ENTITLEMENT_UPLOAD,
  MEMBER_UPDATE_UPLOAD,
  SIGNED_QUO_UPLOAD,
  AUTHO_SIGNATORY_UPLOAD,
  HEADCOUNT_UPLOAD,
  ACRA_UPLOAD,
  MAS314_UPLOAD
} from "../../../../actions/types";

const RenewalUploadForms = props => {
  const {
    classes,
    intl,
    documentActions,
    files,
    prefix,
    renewalType,
    setHasHrDecChanged,
    errors,
    setUploadFormErrors,
    setIsSubmitBtnDisabled,
    onRemoveFile
  } = props;
  const policyRenew = useSelector(state => state.policyRenew);
  const document = useSelector(state => state.document);
  const dispatch = useDispatch();
  const [letterFiles, setletterFiles] = useState([]);
  const [hrDec, setHrDec] = useState([]);
  const [decEntitlement, setDecEntitlement] = useState([]);
  const [decSpecific, setDecSpecific] = useState([]);
  const [memberUpdate, setMemberUpdate] = useState([]);
  const [signedQuo, setSignedQuo] = useState([]);
  const [authoSignatory, setAuthoSignatory] = useState([]);
  const [headcount, setHeadcount] = useState([]);
  const [acra, setACRA] = useState([]);
  const [mas314, setMAS314] = useState([]);
  const LETTER_FILE_TYPE = prefix + ENUMS.FILE_TYPE.LETTER_FILE_TYPE;
  const HR_DEC_FILE_TYPE = prefix + ENUMS.FILE_TYPE.HR_DEC_FILE_TYPE;
  const DEC_ENTITLEMENT_FILE_TYPE = prefix + ENUMS.FILE_TYPE.DEC_ENTITLEMENT_FILE_TYPE;
  const DEC_SPECIFIC_FILE_TYPE = prefix + ENUMS.FILE_TYPE.DEC_SPECIFIC_FILE_TYPE;
  const MEMBER_UPDATE_FILE_TYPE = prefix + ENUMS.FILE_TYPE.MEMBER_UPDATE_FILE_TYPE;
  const SIGNED_QUO_FILE_TYPE = prefix + ENUMS.FILE_TYPE.SIGNED_QUO_FILE_TYPE;
  const AUTHO_SIGNATORY_FILE_TYPE = prefix + ENUMS.FILE_TYPE.AUTHO_SIGNATORY_FILE_TYPE;
  const HEADCOUNT_FILE_TYPE = prefix + ENUMS.FILE_TYPE.HEADCOUNT_FILE_TYPE;
  const ACRA_FILE_TYPE = prefix + ENUMS.FILE_TYPE.ACRA_FILE_TYPE;
  const MAS314_FILE_TYPE = prefix + ENUMS.FILE_TYPE.MAS314_FILE_TYPE;

  useEffect(() => {
    if (files.filter(file => file.file_type === LETTER_FILE_TYPE)) {
      setletterFiles(files.filter(file => file.file_type === LETTER_FILE_TYPE));
    }
    if (files.filter(file => file.file_type === HR_DEC_FILE_TYPE)) {
      setHrDec(files.filter(file => file.file_type === HR_DEC_FILE_TYPE));
    }
    if (files.filter(file => file.file_type === DEC_SPECIFIC_FILE_TYPE)) {
      setDecSpecific(files.filter(file => file.file_type === DEC_SPECIFIC_FILE_TYPE));
    }
    if (files.filter(file => file.file_type === DEC_ENTITLEMENT_FILE_TYPE)) {
      setDecEntitlement(files.filter(file => file.file_type === DEC_ENTITLEMENT_FILE_TYPE));
    }
    if (files.filter(file => file.file_type === MEMBER_UPDATE_FILE_TYPE)) {
      setMemberUpdate(files.filter(file => file.file_type === MEMBER_UPDATE_FILE_TYPE));
    }
    if (files.filter(file => file.file_type === SIGNED_QUO_FILE_TYPE)) {
      setSignedQuo(files.filter(file => file.file_type === SIGNED_QUO_FILE_TYPE));
    }
    if (files.filter(file => file.file_type === AUTHO_SIGNATORY_FILE_TYPE)) {
      setAuthoSignatory(files.filter(file => file.file_type === AUTHO_SIGNATORY_FILE_TYPE));
    }
    if (files.filter(file => file.file_type === HEADCOUNT_FILE_TYPE)) {
      setHeadcount(files.filter(file => file.file_type === HEADCOUNT_FILE_TYPE));
    }
    if (files.filter(file => file.file_type === ACRA_FILE_TYPE)) {
      setACRA(files.filter(file => file.file_type === ACRA_FILE_TYPE));
    }
    if (files.filter(file => file.file_type === MAS314_FILE_TYPE)) {
      setMAS314(files.filter(file => file.file_type === MAS314_FILE_TYPE));
    }
  }, [files]);

  const onDropRejected = (files, type) => {
    let firstFile = _.first(files);
    let errorData = getError(firstFile);

    if (errorData && errorData.inValid === true) {
      setUploadFormErrors({
        [type]: {
          invalid: errorData.inValid,
          dialogTitle: errorData.title,
          dialogDescription: errorData.description
        }
      });
    }
  };

  const getError = file => {
    const { intl } = this.props;

    if (file.size > config.fileUploadOptions.maxSize) {
      return {
        inValid: true,
        title: intl.formatMessage({ id: "popup.file.sizeExceeds.title" }),
        description: intl.formatMessage({
          id: "popup.file.sizeExceeds.description"
        })
      };
    }
  };

  const handleUploadFile = (uploadAction, fileType, files) => {
    Util.forEachAsync(files, file => {
      documentActions
        .uploadFileForRenewal(policyRenew.id, uploadAction, fileType, file)
        .then(() => {
          setUploadFormErrors({
            [fileType]: null
          });

          setIsSubmitBtnDisabled();
        })
        .catch(error => {
          setUploadFormErrors({
            [fileType]: {
              dialogDescription: error.data.error_description
            }
          });
        });
    });
  };

  const handleHrChange = isChecked => {
    setHasHrDecChanged(isChecked);
    if (!isChecked) {
      setACRA([]);
      setMAS314([]);
      if (acra?.length) {
        let fileId = acra[0]?.encrypted_id;
        props.documentActions
          .deleteRenewalFile(policyRenew.id, fileId)
          .then(res => {
            onRemoveFile();
          })
          .catch(error => {});
      }
      if (mas314?.length) {
        let fileId = mas314[0]?.encrypted_id;
        props.documentActions
          .deleteRenewalFile(policyRenew.id, fileId)
          .then(res => {
            onRemoveFile();
          })
          .catch(error => {});
      }
    }
    setIsSubmitBtnDisabled(isChecked);
  };

  return (
    <>
      <Box
        sx={{
          overflow: "hidden",
          backgroundColor: "white",
          padding: "30px 30px 35px 30px",
          boxShadow: "-2px -2px 20px 0 rgba(0,0,0,0.1)"
        }}
      >
        <Grid
          sx={{ padding: "0px 0px 20px 0px", display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>Policy chosen for renewal</Typography>
        </Grid>

        <Grid container rowSpacing={1} columnSpacing={3} xs={5} item>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>Company:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>{policyRenew.company_name}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>Reference / Policy No.:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>{policyRenew.policy_no}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>Policy Expiry Date:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>{policyRenew.expiry_date}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          overflow: "hidden",
          backgroundColor: "white",
          padding: "30px 30px 35px 30px",
          boxShadow: "-2px -2px 20px 0 rgba(0,0,0,0.1)",
          marginTop: "20px"
        }}
      >
        <Grid>
          <Typography sx={{ padding: "0px 0px 20px 0px", fontSize: "18px", fontWeight: 500 }}>
            Upload Renewal Invitation Letter
          </Typography>
          <Typography sx={{ padding: "0px 0px 30px 0px", fontSize: "16px", fontWeight: 500 }}>
            Drop or upload your scanned signed renewal invitation letter
          </Typography>
          <div className={classNames(root, false && disabledClass)}>
            <RenderFileOrUploader
              files={letterFiles}
              type="renewal"
              onRemoveFile={onRemoveFile}
              Uploader={
                <DropZone
                  disabled={false}
                  // accept={config.fileUploadOptions.accept}
                  maxSize={config.fileUploadOptions.maxSize}
                  descr={"Drop or upload your Renewal Invitation Letter"}
                  errorMessage={errors[LETTER_FILE_TYPE]}
                  onDropRejected={files => {
                    onDropRejected(files, LETTER_FILE_TYPE);
                  }}
                  onDropHandler={files => {
                    handleUploadFile(LETTER_UPLOAD, LETTER_FILE_TYPE, files);
                  }}
                  isUploading={document.LetterIsUploading}
                />
              }
            />
          </div>
        </Grid>
      </Box>
      <Box
        sx={{
          overflow: "hidden",
          backgroundColor: "white",
          padding: "30px 30px 35px 30px",
          boxShadow: "-2px -2px 20px 0 rgba(0,0,0,0.1)",
          marginTop: "20px",
          marginBottom: "80px"
        }}
      >
        <Grid>
          <Typography sx={{ padding: "0px 0px 20px 0px", fontSize: "18px", fontWeight: 500 }}>
            Upload All Relevant Documents
          </Typography>
          <Typography sx={{ padding: "0px 0px 20px 0px", fontSize: "12px", fontWeight: 200 }}>
            File type must be JPG, PDF or PNG and file size must not exceed 10MB
          </Typography>
          {(renewalType === "RENEW_WITH_CHG" || renewalType === "RENEW") && (
            <>
              <Typography sx={{ padding: "0px 0px 30px 0px", fontSize: "16px", fontWeight: 500 }}>
                <input
                  type="checkbox"
                  checked={policyRenew.has_hr_dec_changed}
                  onChange={event => {
                    handleHrChange(event?.target?.checked);
                  }}
                  disabled={hrDec?.length !== 0}
                />{" "}
                {`There has been change(s) on HR Declaration on Shareholders and Directorship.`}
              </Typography>
              {policyRenew.has_hr_dec_changed ? (
                <>
                  <Typography sx={{ padding: "0px 0px 30px 0px", fontSize: "16px", fontWeight: 500 }}>
                    {`Upload ACRA Document (Please ensure your ACRA form is dated to no more than 6 months before date of submission on PRUworks)`}
                  </Typography>
                  <div className={classNames(root, false && disabledClass)}>
                    <RenderMultipleFileUploader
                      files={acra}
                      type="renewal"
                      onRemoveFile={onRemoveFile}
                      Uploader={
                        <DropZone
                          disabled={false}
                          // accept={config.fileUploadOptions.accept}
                          maxSize={config.fileUploadOptions.maxSize}
                          descr={"Drop or upload ACRA Document"}
                          errorMessage={acra?.length == 0 && errors[ACRA_FILE_TYPE]}
                          onDropRejected={files => {
                            onDropRejected(files, ACRA_FILE_TYPE);
                          }}
                          onDropHandler={files => {
                            handleUploadFile(ACRA_UPLOAD, ACRA_FILE_TYPE, files);
                          }}
                          isUploading={document.acraIsUploading}
                          multiple={true}
                        />
                      }
                    />
                  </div>
                  <Typography sx={{ padding: "30px 0px 30px 0px", fontSize: "16px", fontWeight: 500 }}>
                    Upload MAS314
                  </Typography>
                  <div className={classNames(root, false && disabledClass)}>
                    <RenderFileOrUploader
                      files={mas314}
                      type="renewal"
                      onRemoveFile={onRemoveFile}
                      Uploader={
                        <DropZone
                          disabled={false}
                          // accept={config.fileUploadOptions.accept}
                          maxSize={config.fileUploadOptions.maxSize}
                          descr={"Drop or upload MAS314 Document"}
                          errorMessage={errors[MAS314_FILE_TYPE]}
                          onDropRejected={files => {
                            onDropRejected(files, MAS314_FILE_TYPE);
                          }}
                          onDropHandler={files => {
                            handleUploadFile(MAS314_UPLOAD, MAS314_FILE_TYPE, files);
                          }}
                          isUploading={document.mas314IsUploading}
                        />
                      }
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          )}
          {!policyRenew.has_hr_dec_changed && (
            <>
              <Typography sx={{ padding: "30px 0px 30px 0px", fontSize: "16px", fontWeight: 500 }}>
                HR Declaration on Shareholders and Directorship
              </Typography>
              <div className={classNames(root, false && disabledClass)}>
                <RenderFileOrUploader
                  files={hrDec}
                  type="renewal"
                  onRemoveFile={onRemoveFile}
                  Uploader={
                    <DropZone
                      disabled={false}
                      // accept={config.fileUploadOptions.accept}
                      maxSize={config.fileUploadOptions.maxSize}
                      descr={"Drop or upload HR Declaration on Shareholders and Directorship"}
                      errorMessage={errors[HR_DEC_FILE_TYPE]}
                      onDropRejected={files => {
                        onDropRejected(files, HR_DEC_FILE_TYPE);
                      }}
                      onDropHandler={files => {
                        handleUploadFile(HR_DEC_UPLOAD, HR_DEC_FILE_TYPE, files);
                      }}
                      isUploading={document.hrDecIsUploading}
                    />
                  }
                />
              </div>
            </>
          )}
          <Typography sx={{ padding: "30px 0px 30px 0px", fontSize: "16px", fontWeight: 500 }}>
            Declaration of Entitlement to Claim Input Tax on Insurance Policy
          </Typography>
          <div className={classNames(root, false && disabledClass)}>
            <RenderMultipleFileUploader
              files={decEntitlement}
              type="renewal"
              onRemoveFile={onRemoveFile}
              Uploader={
                <DropZone
                  disabled={false}
                  // accept={config.fileUploadOptions.accept}
                  maxSize={config.fileUploadOptions.maxSize}
                  descr={"Drop or upload Declaration of Entitlement to Claim Input Tax on Insurance Policy"}
                  errorMessage={decEntitlement?.length == 0 && errors[DEC_ENTITLEMENT_FILE_TYPE]}
                  onDropRejected={files => {
                    onDropRejected(files, DEC_ENTITLEMENT_FILE_TYPE);
                  }}
                  onDropHandler={files => {
                    handleUploadFile(DEC_ENTITLEMENT_UPLOAD, DEC_ENTITLEMENT_FILE_TYPE, files);
                  }}
                  isUploading={document.decEntitlementIsUploading}
                  multiple={true}
                />
              }
            />
          </div>

          <Typography sx={{ padding: "30px 0px 30px 0px", fontSize: "16px", fontWeight: 500 }}>
            Declaration of Specific Individual
          </Typography>
          <div className={classNames(root, false && disabledClass)}>
            <RenderFileOrUploader
              files={decSpecific}
              type="renewal"
              onRemoveFile={onRemoveFile}
              Uploader={
                <DropZone
                  disabled={false}
                  // accept={config.fileUploadOptions.accept}
                  maxSize={config.fileUploadOptions.maxSize}
                  descr={"Drop or upload Declaration of Specific Individual"}
                  errorMessage={errors[DEC_SPECIFIC_FILE_TYPE]}
                  onDropRejected={files => {
                    onDropRejected(files, DEC_SPECIFIC_FILE_TYPE);
                  }}
                  onDropHandler={files => {
                    handleUploadFile(DEC_SPECIFIC_UPLOAD, DEC_SPECIFIC_FILE_TYPE, files);
                  }}
                  isUploading={document.decSpecificIsUploading}
                />
              }
            />
          </div>

          {(renewalType === "RENEW_WITH_CHG" || renewalType === "RENEW") && (
            <>
              <Typography sx={{ padding: "30px 0px 30px 0px", fontSize: "16px", fontWeight: 500 }}>
                Member Update
              </Typography>
              <div className={classNames(root, false && disabledClass)}>
                <RenderFileOrUploader
                  files={memberUpdate}
                  type="renewal"
                  onRemoveFile={onRemoveFile}
                  Uploader={
                    <DropZone
                      disabled={false}
                      // accept={config.fileUploadOptions.accept}
                      maxSize={config.fileUploadOptions.maxSize}
                      descr={"Drop or upload Member Update"}
                      errorMessage={errors[MEMBER_UPDATE_FILE_TYPE]}
                      onDropRejected={files => {
                        onDropRejected(files, MEMBER_UPDATE_FILE_TYPE);
                      }}
                      onDropHandler={files => {
                        handleUploadFile(MEMBER_UPDATE_UPLOAD, MEMBER_UPDATE_FILE_TYPE, files);
                      }}
                      isUploading={document.memberUpdateIsUploading}
                    />
                  }
                />
              </div>
              <Typography sx={{ padding: "30px 0px 30px 0px", fontSize: "16px", fontWeight: 500 }}>
                Signed Quotation for New Product
              </Typography>
              <div className={classNames(root, false && disabledClass)}>
                <RenderFileOrUploader
                  files={signedQuo}
                  type="renewal"
                  onRemoveFile={onRemoveFile}
                  Uploader={
                    <DropZone
                      disabled={false}
                      // accept={config.fileUploadOptions.accept}
                      maxSize={config.fileUploadOptions.maxSize}
                      descr={"Drop or upload Signed Quotation for New Product"}
                      errorMessage={errors[SIGNED_QUO_FILE_TYPE]}
                      onDropRejected={files => {
                        onDropRejected(files, SIGNED_QUO_FILE_TYPE);
                      }}
                      onDropHandler={files => {
                        handleUploadFile(SIGNED_QUO_UPLOAD, SIGNED_QUO_FILE_TYPE, files);
                      }}
                      isUploading={document.signedQuoIsUploading}
                    />
                  }
                />
              </div>
              <Typography sx={{ padding: "30px 0px 30px 0px", fontSize: "16px", fontWeight: 500 }}>
                Authorised Signatory ID (front and back)
              </Typography>
              <div className={classNames(root, false && disabledClass)}>
                <RenderMultipleFileUploader
                  files={authoSignatory}
                  type="renewal"
                  onRemoveFile={onRemoveFile}
                  Uploader={
                    <DropZone
                      disabled={false}
                      // accept={config.fileUploadOptions.accept}
                      maxSize={config.fileUploadOptions.maxSize}
                      descr={"Drop or upload Authorised Signatory ID"}
                      errorMessage={authoSignatory?.length == 0 && errors[AUTHO_SIGNATORY_FILE_TYPE]}
                      onDropRejected={files => {
                        onDropRejected(files, AUTHO_SIGNATORY_FILE_TYPE);
                      }}
                      onDropHandler={files => {
                        handleUploadFile(AUTHO_SIGNATORY_UPLOAD, AUTHO_SIGNATORY_FILE_TYPE, files);
                      }}
                      isUploading={document.authoSignatoryIsUploading}
                      multiple={true}
                    />
                  }
                />
              </div>
            </>
          )}

          {renewalType === "RENEW" && (
            <>
              <Typography sx={{ padding: "30px 0px 30px 0px", fontSize: "16px", fontWeight: 500 }}>
                Upload Name / Headcount
              </Typography>
              <div className={classNames(root, false && disabledClass)}>
                <RenderFileOrUploader
                  files={headcount}
                  type="renewal"
                  onRemoveFile={onRemoveFile}
                  Uploader={
                    <DropZone
                      disabled={false}
                      // accept={config.fileUploadOptions.accept}
                      maxSize={config.fileUploadOptions.maxSize}
                      descr={"Drop or upload Name / Headcount"}
                      errorMessage={errors[HEADCOUNT_FILE_TYPE]}
                      onDropRejected={files => {
                        onDropRejected(files, HEADCOUNT_FILE_TYPE);
                      }}
                      onDropHandler={files => {
                        handleUploadFile(HEADCOUNT_UPLOAD, HEADCOUNT_FILE_TYPE, files);
                      }}
                      isUploading={document.headcountIsUploading}
                    />
                  }
                />
              </div>
            </>
          )}
        </Grid>
      </Box>
      {/* <Box
        sx={{
          overflow: "hidden",
          backgroundColor: "white",
          padding: "30px 30px 35px 30px",
          boxShadow: "-2px -2px 20px 0 rgba(0,0,0,0.1)",
          marginTop: "20px",
          marginBottom: "80px"
        }}
      >
        <Grid>
          <Typography sx={{ padding: "0px 0px 20px 0px", fontSize: "18px", fontWeight: 500 }}>
            Select Payment Method
          </Typography>
          <Typography sx={{ padding: "0px 0px 30px 0px", fontSize: "16px", fontWeight: 500 }}>
            Payer should be either a Director, Managing Director, Partner, Managing Partner, or Shareholder of the
            company / policyholder as listed in the ACRA.
          </Typography>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={3} xs={3} item>
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <Radio
              // checked={selectedPolicy === renewal.policy_no}
              onChange={event => {
                handleRadioChange(event, {});
              }}
              // value={renewal.policy_no}
              // name="policy-selection"
              // color="primary"
            />
            <Typography sx={{ fontSize: "16px", fontWeight: 500, display: "flex", alignItems: "center" }}>
              <ImportExportIcon style={{ marginRight: "5px", transform: "rotate(90deg)" }} />
              Bank Transfer
            </Typography>
          </Grid>
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <Radio
            // checked={selectedPolicy === renewal.policy_no}
            // onChange={(event)=>{
            //     handleRadioChange(event, renewal.company_name, renewal.expiry_date)
            // }}
            // value={renewal.policy_no}
            // name="policy-selection"
            // color="primary"
            />
            <Typography sx={{ fontSize: "16px", fontWeight: 500, display: "flex", alignItems: "center" }}>
              <BorderColorIcon style={{ marginRight: "5px", width: "20px" }} />
              Cheque
            </Typography>
          </Grid>
        </Grid>
      </Box> */}
    </>
  );
};

const styles = theme => ({
  root: {
    padding: "30px"
  },
  error: {
    fontSize: "0.75rem"
  },
  errorWrapper: {
    margin: " 0 20%",
    paddingBottom: 10
  },

  disabledClass: {
    color: `${theme.palette.text.disabled} !important`,
    borderColor: `${theme.palette.text.disabled} !important`,
    cursor: "not-allowed"
  },
  activeClass: {
    cursor: "pointer",
    borderColor: "#333333"
  },
  dropZoneInfoWrapper: {
    padding: "50px",
    textAlign: "center",
    border: "5px dotted #ccc",
    padding: "10px",
    borderStyle: "dashed",
    borderWidth: "2px",
    borderRadius: "5px"
  },
  uploadIcon: {
    height: "25px",
    width: "25px",
    color: "#ccc",
    margin: "0px 15px"
  },
  button: {},
  uploadInfo: {
    color: "#ccc"
  }
});

export default injectIntl(withStyles(styles, { withTheme: true })(RenewalUploadForms));
