import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";

import { injectIntl } from "react-intl";

class NoticeGST extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, intl, product_code, product_version } = this.props;
    return (
      <div>
        <span className={classes.notice}>
          {intl.formatMessage({ id: "notice.gst.2023.firstline" })}
          <br></br>
          {intl.formatMessage({ id: "notice.gst.2023.secondline" })}
          <br></br>
          {intl.formatMessage({ id: "notice.gst.2023.thirdline" })}
        </span>
      </div>
    );
  }
}

const styles = theme => ({
  notice: {
    fontSize: "12px",
    lineHeight: "80%"
  }
});

function mapStateToProps(state) {
  return {
    product_code: state.quote.product_code,
    product_version: state.quote.product_version
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(NoticeGST))
);
