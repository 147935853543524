import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router";
import { withStyles } from "@mui/styles";
import muiTheme from "../../../../themes";
import assets from "../../../../assets";
import BoxItem from "../../../../components/hr/dashboard/BoxItem";
import BoxItemValues from "../../../../assets/data/hr/dashboard/boxItemValues";
import Grid from "@mui/material/Grid";
import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import { coverageActions } from "../../../../actions";
import { bindActionCreators } from "redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import BenefitService from "../../../../services/HR/benefit.service";
import SimpleConfirmDialog from "../../../../components/SimpleConfirmDialog";
import LoadingPopup from "../../../../components/LoadingPopup";
import PolicyCard from "../../../../components/PolicyCard";

class HRDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      triggerLoadingPopup: false,
      openConfirmDialog: false
    };
  }

  componentDidMount() {
    const { policyNumber, companyId } = this.props.location.state;
    if (policyNumber) {
      this.props.getGaPolicyInfo(policyNumber);
      this.props.getPolicy(policyNumber, companyId);
    }
  }

  renderBoxItemBottom = () => {
    const { user } = this.props;

    let boxItems = BoxItemValues.filter(value => {
      // ignore if user has no access to scope
      return user.auth.scope.includes(value.access);
    });

    this.listBoxItemComponents = boxItems.map((value, index) => {
      return (
        <Grid xs={12} sm={6} md={12 / boxItems.length} key={index} item>
          <BoxItem data={value} onClick={this.goToPage} />
        </Grid>
      );
    });
    return this.listBoxItemComponents;
  };
  goToPage = id => {
    const { company, policyNumber, companyId, productCode } = this.props.location.state || {};
    switch (id) {
      case "insuranceCoverage":
        return this.props.history.push("/auth/insuranceCoverage/detail", {
          company,
          policyNumber,
          companyId,
          productCode
        });
      case "myEmployees":
        return this.props.history.push("/auth/myEmployees/detail", { company, policyNumber, companyId, productCode });
      case "claimExpenses":
        return this.props.history.push("/auth/claimExpenses/detail", { company, policyNumber, companyId, productCode });
      case "employeeBenefits":
        return this.props.history.push("/auth/employeeBenefit");
      case "documents":
        return this.props.history.push("/auth/documents");
      default:
        return;
    }
  };
  closeCofirmDialog = () => {
    this.setState({ openConfirmDialog: false });
  };

  responseConfirmDialog = () => {
    this.closeCofirmDialog();
    this.callBanner(this.state.partnerName, this.state.bannerUrl);
  };

  callBannerRedirect = banner => {
    this.callBanner(banner.name, banner.link);
  };

  callBanner = (partnerName, bannerUrl) => {
    const data = {
      name: partnerName,
      email: "",
      phone: "",
      promo_code: { code: "DUMMY_HR" },
      redirect: true
    };
    BenefitService.sendEmailRequestCallBack(data, partnerName).then(
      response => {
        if (response.success) {
          this.setState({ triggerLoadingPopup: false });
          window.open(bannerUrl, "_blank", "noopener noreferrer");
        }
      },
      error => {
        this.setState({ triggerLoadingPopup: false });
      }
    );
  };

  isShowCoverageLink = () => {
    const productCode = this.props.location.state.productCode;
    return productCode === "PF3" || productCode === "PTF" || productCode === "PEP";
  };

  render() {
    const { classes, user, coverage } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Typography variant="h5" fontWeight={700} mb={2}>
            {this.props.location.state.company}'s Dashboard
          </Typography>
          <PolicyCard isShowViewCoverageLink={this.isShowCoverageLink()} gaInfo={coverage.gaInfo} />
        </div>
        <Grid className={classes.listBoxItem} container spacing={"8px"}>
          {this.renderBoxItemBottom()}
        </Grid>

        <SimpleConfirmDialog
          name="leave-confirm-dialog"
          title="You’re leaving the PruWorks Portal."
          description="We will redirect you to our partner’s website. Click ok to continue."
          closeButtonText="Cancel"
          okButtonText="OK"
          closeHandler={this.closeCofirmDialog}
          okHandler={this.responseConfirmDialog.bind(this)}
          open={this.state.openConfirmDialog}
        />
        <LoadingPopup name={this.props.type} open={this.state.triggerLoadingPopup} />
      </div>
    );
  }
}
const styles = theme => ({
  root: {
    height: "100%",
    paddingTop: "40px",
    paddingLeft: "40px",
    paddingRight: "40px"
  },
  listBoxItem: {
    width: "100%",
    margin: 0,
    padding: 20,
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#ffffff"
  },
  content: {
    backgroundImage: "url(" + assets.dashBoardImages.background + ")",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    padding: "20px 20px 20px"
  },
  welcomeTitle: {
    fontSize: 26
  },
  title: {
    color: muiTheme.colors.blackScale.black100,
    fontSize: 24.5
  },
  insuranceOverView: {
    marginTop: 20
  },
  insuranceInfo: {
    flexDirection: "column",
    marginBottom: 10
  },
  socialMedia: {
    width: 112
  },
  insuranceTitle: {
    fontSize: 18,
    width: 168,
    color: "#000000"
  },
  insuranceOverViewContentWrapper: {
    marginTop: 10,
    alignItems: "center",
    flexDirection: "row"
  },
  wrapper: {
    padding: "20px",
    display: "flex",
    flexDirection: "column"
  },
  leftContent: {
    paddingLeft: 15
  },
  cardContainer: {
    padding: 20
  },
  button: {
    // marginTop: 40,
    fontSize: 18,
    height: 51,
    width: 227,
    borderRadius: 25.5,
    color: "#FFFFFF",
    backgroundColor: `${theme.colors.primary.red}`,
    boxShadow: "none",
    alignSelf: "flex-end"
  },

  customDot: {
    "& .control-dots": {
      margin: "10px 0 0 0"
    },
    "& .dot": {
      height: "15px !important",
      width: "15px !important",
      opacity: "1 !important"
    },
    "& .selected": {
      backgroundColor: "red !important"
    }
  },
  webBanner: {
    [theme.breakpoints.down("xs")]: {
      display: "none !important"
    }
  },
  mobileBanner: {
    [theme.breakpoints.up("sm")]: {
      display: "none !important"
    }
  }
});
function mapStateToProps(state) {
  return {
    quote: state.quote,
    user: state.user,
    coverage: state.HR.coverage
  };
}
function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(coverageActions, dispatch),
    getHRInfo: (...res) => dispatch(userActions.getHRInfo(...res))
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles, { withTheme: true })(HRDashboard)))
);
