import * as actionTypes from "./types";

import ESignService from "../services/esign.service";

export const esignActions = {
  getKofaxSign,
  getPackageCallBackResponse,
  getPackageStatus,
  getSignedDocument
};

function getKofaxSign(quoteId) {
  return dispatch => {
    return ESignService.getKofaxSign(quoteId).then(
      response => {
        if (response.success) {
          dispatch({
            type: actionTypes.QUOTE_GET_KOFAX,
            payload: response.data
          });
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch({
          type: actionTypes.QUOTE_E_SIGN_CONTENT_ERROR,
          error: error
        });
        return Promise.reject(error);
      }
    );
  };
}

function getPackageCallBackResponse(packageId) {
  return dispatch => {
    return ESignService.getCallbackStatus(packageId).then(
      response => {
        if (response.success) {
          dispatch({
            type: actionTypes.QUOTE_GET_DOCUMENT_CALLBACK_STATUS,
            payload: response.data
          });
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch({
          type: actionTypes.QUOTE_GET_DOCUMENT_CALLBACK_STATUS_ERROR,
          error: error
        });
        return Promise.reject(error);
      }
    );
  };
}

function getPackageStatus(packageId) {
  return dispatch => {
    return ESignService.getPackageStatus(packageId).then(
      response => {
        if (response.success) {
          dispatch({
            type: actionTypes.QUOTE_GET_DOCUMENT_SIGN_STATUS,
            payload: response.data
          });
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch({
          type: actionTypes.QUOTE_GET_DOCUMENT_SIGN_STATUS_ERROR,
          error: error
        });
        return Promise.reject(error);
      }
    );
  };
}

function getSignedDocument(quote_id, packageId) {
  return dispatch => {
    return ESignService.getSignedDocument(quote_id, packageId).then(
      response => {
        if (response.success) {
          dispatch({
            type: actionTypes.QUOTE_GET_SIGNED_DOCUMENT_FULFILLED,
            payload: response.data
          });
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch({
          type: actionTypes.QUOTE_GET_SIGNED_DOCUMENT_ERROR,
          error: error
        });
        return Promise.reject(error);
      }
    );
  };
}
