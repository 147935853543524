import * as actionTypes from "../actions/types";

const initialState = {
  data: []
};

export const policyList = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.POLICY_GET_ALL_POLICIES:
      return {
        ...state,
        data: action.payload
      };
    default: {
      return state;
    }
  }
};
