import { APIError, APIResource, APIResponse } from "../utils/APIResource";

export default class RenewalService {
  static getRenewPolicyList(params) {
    return APIResource.get(`/v1/fc/renewPolicy/list`) //placeholder
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getRenewSubmissionList(params) {
    return APIResource.get(`/v1/fc/renewSubmission/list`) //placeholder
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getRenewPolicies(params) {
    return APIResource.get(`/v1/fc/renewal-policies`) //placeholder
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static putRenewalPolicy(policyId, details) {
    return APIResource.put(`/v1/fc/renewal-policy/${policyId}`, details)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static postCreateRenewal(details) {
    return APIResource.post(`/v1/fc/renewal-policy`, details)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }
}
