import pi from "../../productInfo";

const { DOCDOC, VALIDUS, WELLTEQ, SMEHEALTH, HREASILY, STASH, MORE_SERVICES } = pi.productKeys;

export default [
  {
    key: VALIDUS,
    backgroundImg: require("../../../img/validus-list.png"),
    icon: require("../../../img/validus-icon.png"),
    title: "Smart Financing",
    description: "Access to P2P lending and invoice financing."
  },
  {
    key: SMEHEALTH,
    backgroundImg: require("../../../img/SME_Health+_Image.png"),

    title: "SME Health+",
    description: "A scheme that aims to support Small and Medium Enterprises (SMEs) in their health promotion journey"
  },
  {
    key: WELLTEQ,
    backgroundImg: require("../../../img/wellteq-list.png"),
    icon: require("../../../img/wellteq-icon.png"),
    title: "Employee Digital Wellness Platform",
    description: "Assess, engage and enhance your employees’ health."
  },
  {
    key: DOCDOC,
    backgroundImg: require("../../../img/docdoc-list.png"),
    icon: require("../../../img/docdoc-icon.png"),
    title: "Easy Access to Medical Specialists",
    description: "Empower your employees to receive the right care at the right time."
  },
  {
    key: HREASILY,
    backgroundImg: require("../../../img/HRE_image.jpg"),
    icon: require("../../../img/HReasily-icon.png"),
    title: "HReasily",
    description: "An HR management platform for all your business needs."
  },
  // {
  //   key: STASH,
  //   // instantRedirect: true,
  //   redirectUrl: "https://prustasheb-msite.sg.stashnextgen.io",
  //   backgroundImg: require("../../../img/stash-icon.png"),
  //   icon: "",
  //   title: "STASH NextGen Employee Benefits ",
  //   description: "An Employee Benefits management tool for you to inventivize your employees"
  // },
  {
    key: MORE_SERVICES,
    backgroundImg: "",
    icon: "",
    title: "Coming Soon",
    description:
      "As part of our continuous efforts to serve you better, new value added services will be coming your way. Please watch this space for exciting services."
  } /*,
  {
    key: "ergo",
    backgroundImg:
      require("../../img/ergo-list.png"),
    icon: require("../../img/ergo-icon.png"),
    title: "Corporate Travel Insurance ",
    description: "Buy a single policy for all your travelling employees"
  }*/
];
