import { APIResource, APIError, APIResponse } from "../utils/APIResource";

export default class PaymentService {
  static getToken() {
    return APIResource.get(`/v1/auth/braintree/token`)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static loadClientEngine(token) {
    return new Promise((resolve, reject) => {
      try {
        braintree.client.create(
          {
            authorization: token
          },
          (err, clientInstance) => {
            if (err) {
              console.error(err);
              reject(err);
            }
            resolve(clientInstance);
          }
        );
      } catch (err) {
        reject(err);
      }
    });
  }

  static setPayment(quoteId, mode) {
    return APIResource.post(
      `/v1/quote/${quoteId}/payment`,
      {
        method: mode
      },
      {}
    )
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static makePayment(quoteId, payload) {
    return APIResource.put(`/v1/quote/${quoteId}/payment`, payload, {})
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }
}
