import { UploadDocumentService } from "../../services/HR/uploadDocument.service";
import fileDownload from "js-file-download";

export const uploadDocumentActions = {
  downloadFile,
  downloadTemplate
};

function downloadFile(policy_no, doc_id, fileName) {
  UploadDocumentService.downloadFile(policy_no, doc_id).then(
    response => {
      if (response.success && response.data) {
        fileDownload(response.data, fileName || `Claim_Expenses_${policy_no}.xlsx`);
      }
      return response;
    },
    error => {
      return Promise.reject(error);
    }
  );
}

function downloadTemplate() {
  const fileName = "GCP member movement template.xls";
  UploadDocumentService.downloadTemplate(fileName).then(
    response => {
      if (response.success && response.data) {
        let temp = "data:application/vnd.ms-excel;base64," + encodeURIComponent(response.data.file_content);
        let download = document.createElement("a");
        download.href = temp;
        download.download = fileName;
        document.body.appendChild(download);
        download.click();
        document.body.removeChild(download);
      }
      return response;
    },
    error => {
      return Promise.reject(error);
    }
  );
}
