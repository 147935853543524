import Enums from "../../../../utils/Enums";

const GLTBenefits = {
  headers: [
    {
      title: ``,
      mandatory: true,
      attributes: {
        style: {
          width: 10
        }
      }
    },
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "40%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: ``
        },
        {
          data: `<strong>Group Term Life<br></strong>`,
          info: `<ul>
              <li>Death</li>
              <li>Total and Permanent Disability</li>
              <li>Extended Death & TPD</li>
              <li>Terminal Illness</li>
              <li>Family Income Benefit (Refer to the Summary for details)</li>
              </ul>`
        },
        {
          data: `S$ 200,000`
        },
        {
          data: `-`
        },
        {
          data: `-`
        },
        {
          data: `-`
        }
      ]
    }
  ],
  footnotes: []
};

let GLTPremium = {
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 19</strong>`
        },
        {
          data: `S$ 388.00`
        }
      ]
    }
  ],
  footnotes: []
};

let GLTGroupDiscount = {
  headers: [
    {
      title: `Group Size <i>Insured Employee<i>`,
      mandatory: true
    },
    {
      title: `Discount`,
      mandatory: true
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>11 - 15</strong>`
        },
        {
          data: `5%`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>16 and above</strong>`
        },
        {
          data: `10%`
        }
      ]
    }
  ],
  footnotes: []
};

//   ----------------------------------------------------------------------

const GLT = {
  id: 1,
  code: "GTLB",
  label: "Group Term Life (GTL)",
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GLTBenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium",
      data: [GLTPremium]
    }
    /*{
      id: 3,
      order: 3,
      type: Enums.PLANS_TYPE.GROUP_DISCOUNT,
      label: "Group Size Discount",
      data: [GLTGroupDiscount]
    }*/
  ]
};

export default GLT;
