import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { DateRange as DateRangeIcon } from "@mui/icons-material";
import { injectIntl } from "react-intl";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import DatePickerValidatorElement from "../../../../../../components/validation/DatePickerValidatorElement";
import EventIcon from "@mui/icons-material/Event";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import React, { Fragment, Component } from "react";

class HREmployeeAdditionalFields extends Component {
  getAccountStatus = value => {
    let text = "-";
    if (value === "Invited") {
      text = "invited";
    } else if (value === "Uninvited") {
      text = "to invite";
    } else if (value === "Accepted") {
      text = "Accepted";
    }
    return text;
  };

  getUnderWritingStatus = value => {
    let text = "-";
    if (value === "PENDING") {
      text = "Pending";
    } else if (value === "APPROVED") {
      text = "Approved";
    } else if (value === "REJECTED") {
      text = "Rejected";
    } else if (value === "LIMITED") {
      text = "Limited";
    }
    return text;
  };

  render() {
    const {
      intl,
      classes,
      formatDate,
      handleDateChange,
      handleChange,
      handleResetValue,
      getAccountStatus,
      readOnly,
      coverage_start_date,
      underwriting_status,
      coverage_end_date,
      employee_status,
      errorMessages,
      setRef
    } = this.props;
    return (
      <Fragment>
        <Grid item className={classes.gridItem} xs={12} sm={6} style={{ marginBottom: "4px" }}>
          <DatePickerValidatorElement
            name={"coverage_start_date"}
            innerRef={r => setRef(r, "coverage_start_date")}
            className={classes.marginTop}
            fullWidth
            clearable
            keyboard
            disabled={readOnly}
            label="Coverage Start Date"
            autoComplete="[off]"
            variant="standard"
            value={formatDate(coverage_start_date)}
            onChange={handleDateChange("coverage_start_date")}
            format="DD/MM/YYYY"
            animateYearScrolling={true}
            validators={["required", "isCoverageStartDateValid"]}
            errorMessages={[intl.formatMessage({ id: "validator.required" }), this.props.errorMessages]}
          />
        </Grid>

        <Grid item className={classes.gridItem} xs={12} sm={6}>
          <FormControl className={classes.marginTop} disabled fullWidth>
            <InputLabel style={{ left: "-15px" }} htmlFor="coverage_end_date">
              Coverage End Date
            </InputLabel>
            <Input autoComplete="[off]" id="coverage_end_date" value={coverage_end_date || "-"} />
            <FormHelperText />
          </FormControl>
        </Grid>

        <Grid
          item
          className={classes.gridItem}
          xs={12}
          sm={6}
          style={{
            borderBottom: "1px solid gray"
          }}
        >
          <FormControl disabled fullWidth>
            <InputLabel style={{ left: "-15px" }} htmlFor="underwriting_status">
              Underwriting Status
            </InputLabel>
            <Input
              autoComplete="[off]"
              id="underwriting_status"
              disableUnderline
              value={this.getUnderWritingStatus(underwriting_status) || "-"}
            />
          </FormControl>
        </Grid>

        <Grid
          item
          className={classes.gridItem}
          xs={12}
          sm={6}
          style={{
            borderBottom: "1px solid gray"
          }}
        >
          <FormControl disabled fullWidth>
            <InputLabel style={{ left: "-15px" }} htmlFor="account_status">
              Account Status
            </InputLabel>
            <Input
              autoComplete="[off]"
              id="account_status"
              disableUnderline
              value={this.getAccountStatus(employee_status)}
            />
          </FormControl>
        </Grid>
      </Fragment>
    );
  }
}

const styles = theme => ({
  gridItem: {
    paddingBottom: "7px !important",
    paddingTop: "7px !important"
  },
  marginTop: {
    marginTop: "10px !important"
  }
});

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    //...bindActionCreators(quotesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(HREmployeeAdditionalFields)));
