import GCCA from "./GCCA";
import GADD from "./GADD";
import GAMR from "./GAMR";
import GEMM from "./GEMM";
import GPRU from "./GPRU";
import GOSP from "./GOSP";
import GDEN from "./GDEN";

// Plans ----------------------
// Separate GADD GAMR
const plans = [GCCA, GEMM, GPRU, GOSP, GADD, GAMR, GDEN];

export default plans;
