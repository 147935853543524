import Enums from "../../../../../utils/Enums";

const GAMRBenefits = {
  headers: [
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "30%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Accidental Medical Reimbursement</strong>`,
          info: `<i>(Optional Benefit to GADD)</i>`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        }
      ]
    }
  ],
  footnotes: []
};

let GAMRPremium = {
  title: `Annual Premium Rate (inclusive of 9% GST) – Group Accidental Medical Reimbursement`,
  headers: [
    {
      title: `Occupational Class`,
      mandatory: true
    },
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Class 1</strong>`,
          attributes: {
            rowspan: 2
          }
        },
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 37.69`
        },
        {
          data: `S$ 37.69`
        },
        {
          data: `S$ 37.69`
        },
        {
          data: `S$ 37.69`
        },
        {
          data: `S$ 37.69`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 157.90`
        },
        {
          data: `S$ 157.90`
        },
        {
          data: `S$ 157.90`
        },
        {
          data: `S$ 157.90`
        },
        {
          data: `S$ 157.90`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>Class 2</strong>`,
          attributes: {
            rowspan: 2
          }
        },
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 46.86`
        },
        {
          data: `S$ 46.86`
        },
        {
          data: `S$ 46.86`
        },
        {
          data: `S$ 46.86`
        },
        {
          data: `S$ 46.86`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 196.60`
        },
        {
          data: `S$ 196.60`
        },
        {
          data: `S$ 196.60`
        },
        {
          data: `S$ 196.60`
        },
        {
          data: `S$ 196.60`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>Class 3</strong>`,
          attributes: {
            rowspan: 2
          }
        },
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 65.19`
        },
        {
          data: `S$ 65.19`
        },
        {
          data: `S$ 65.19`
        },
        {
          data: `S$ 65.19`
        },
        {
          data: `S$ 65.19`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 275.05`
        },
        {
          data: `S$ 275.05`
        },
        {
          data: `S$ 275.05`
        },
        {
          data: `S$ 275.05`
        },
        {
          data: `S$ 275.05`
        }
      ]
    }
  ],
  footnotes: []
};

//   ----------------------------------------------------------------------
const GAMR = {
  id: 18,
  code: "GAMR",
  label: "Group Accidental Medical Reimbursement (GAMR)(for Employee only)",
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GAMRBenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium",
      data: [GAMRPremium]
    }
  ]
};

export default GAMR;
