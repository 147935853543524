import Enums from "../../../../utils/Enums";

let doc = [
  {
    desc: "MAS 314 Declaration of Relevant Party Form",
    fileNameOriginal: "MAS 314 Declaration of Relevant Party Form (Ver. Oct 2017).pdf",
    fileName: "MAS 314 Declaration of Relevant Party Form (Ver. Oct 2017).pdf",
    type: Enums.P_CODE.PRU_AGENCY
  },
  {
    desc: "MAS 314 Declaration on Beneficial Owners",
    fileNameOriginal: "MAS 314 DECLARATION ON BENEFICIAL OWNER(S).pdf",
    fileName: "MAS 314 DECLARATION ON BENEFICIAL OWNER(S).pdf",
    type: Enums.P_CODE.PRU_AGENCY
  },
  {
    desc: "Health Declaration Form",
    fileNameOriginal: "Form - Group Health Declaration (Jun 2019) PTRFII.pdf",
    fileName: "Health Declaration Form.pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    code: { PTF: [Enums.PLANS_CODE.HDF], PF3: [Enums.PLANS_CODE.HDF] }
  },
  {
    desc: "GCP member movement template",
    fileNameOriginal: "GCP member movement template.xls",
    fileName: "GCP member movement template.xls",
    type: Enums.P_CODE.PRU_AGENCY,
    code: { GCP: [Enums.PLANS_CODE.HDF], PCE: [Enums.PLANS_CODE.HDF] }
    // code: { PTF: [Enums.PLANS_CODE.HDF], PF3: [Enums.PLANS_CODE.HDF] }
  },
  {
    desc: "PRUtreasures flexi II Product Summary – Group Outpatient Specialist (June 2023)",
    fileNameOriginal:
      "PRUTreasures Flexi II Product Summary - Group Outpatient Specialist (June 2023) v1.2 (Final).pdf",
    fileName: "PruTreasures Flexi II - Group SP - (June 2023).pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    code: { PTF: [Enums.PLANS_CODE.GOSP, Enums.PLANS_CODE.PSIH], PF3: [Enums.PLANS_CODE.GOSP, Enums.PLANS_CODE.PSIH] }
  },
  {
    desc: "PRUTreasures Flexi II Product Summary - Group Crisis Cover Accelerated (Oct 2024)",
    fileNameOriginal: "PRUTreasures Flexi II Product Summary - Group Crisis Cover Accelerated (Oct 2024).pdf",
    fileName: "PRUTreasures Flexi II Product Summary - Group Crisis Cover Accelerated (Oct 2024).pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    code: { PTF: [Enums.PLANS_CODE.GCCA], PF3: [Enums.PLANS_CODE.GCCA] }
  },
  {
    desc: "PRUtreasures flexi II Product Summary – Group Extended Major Medical (June 2023)",
    fileNameOriginal:
      "PRUTreasures Flexi II Product Summary - Group Extended Major Medical (June 2023) v1.1 (Clean).pdf",
    fileName: "PruTreasures Flexi II - Group Extended Major Medical - (June 2023).pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    code: { PTF: [Enums.PLANS_CODE.GEMM], PF3: [Enums.PLANS_CODE.GEMM] }
  },
  {
    desc: "PRUTreasures Flexi II Product Summary - Group Term Life (Jun 2024)",
    fileNameOriginal: "PRUTreasures Flexi II Product Summary - Group Term Life (Jun 2024).pdf",
    fileName: "PRUTreasures Flexi II Product Summary - Group Term Life (Jun 2024).pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    code: { PTF: [Enums.PLANS_CODE.GTLB], PF3: [Enums.PLANS_CODE.GTLB] }
  },
  {
    desc: "PRUTreasures Flexi II Product Summary – Group Outpatient General Practitioner (Jan 2024)",
    fileNameOriginal:
      "PRUTreasures Flexi II Product Summary - Group Outpatient General Practitioner (Jan 2024) v1.3 (Final).pdf",
    fileName: "PruTreasures Flexi II - Group GP - (June 2024).pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    code: { PF3: [Enums.PLANS_CODE.GPRU, Enums.PLANS_CODE.PGIH] }
  },
  {
    desc: "PRUtreasures flexi II Product Summary – Group Outpatient General Practitioner (June 2023)",
    fileNameOriginal:
      "PRUTreasures Flexi II Product Summary - Group Outpatient General Practitioner (June 2023) v1.2 (Final).pdf",
    fileName: "PruTreasures Flexi II - Group GP - (June 2023).pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    code: { PTF: [Enums.PLANS_CODE.GPRU, Enums.PLANS_CODE.PGIH] }
  },
  {
    desc: "PRUtreasures flexi II Product Summary – Group Dental (June 2023)",
    fileNameOriginal: "PRUTreasures Flexi II Product Summary - Group Dental (June 2023) v1.2 (Final).pdf",
    fileName: "PruTreasures Flexi II - Dental - (June 2023).pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    code: { PTF: [Enums.PLANS_CODE.GDEN], PF3: [Enums.PLANS_CODE.GDEN] }
  },
  {
    desc: "PRUtreasures flexi II Product Summary – Group Hospital and Surgical (June 2023)",
    fileNameOriginal: "PRUTreasures Flexi II Product Summary - Group Hospital & Surgical (June 2023) v1.1 (Clean).pdf",
    fileName: "PruTreasures Flexi II - Group Hospital and Surgical - (June 2023).pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    code: { PTF: [Enums.PLANS_CODE.GHSB], PF3: [Enums.PLANS_CODE.GHSB] }
  },
  {
    desc: "PRUtreasures flexi II Product Summary – Group Accidental Death and Dismemberment (June 2023)",
    fileNameOriginal:
      "PRUTreasures Flexi II Product Summary - Group Accidental Death & Dismemberment (June 2023) v1.2 (Final).pdf",
    fileName: "PruTreasures Flexi II - Group Accidental Death and Dismemberment - (June 2023).pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    code: { PTF: [Enums.PLANS_CODE.GADD, Enums.PLANS_CODE.GAMR], PF3: [Enums.PLANS_CODE.GADD, Enums.PLANS_CODE.GAMR] }
  },
  //New Rider Group Pre Crisis Guard (GPCG)
  {
    desc: "PRUtreasures flexi II Product Summary – Group Pre Crisis Guard (June 2023)",
    fileNameOriginal: "PRUTreasures Flexi II Product Summary - Group Pre-Crisis Guard (June 2023) v1.1 (Clean).pdf",
    fileName: "PruTreasures Flexi II - Group Pre Crisis Guard - (June 2023).pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    code: { PF3: [Enums.PLANS_CODE.GPCG] }
  },
  //New Plan Group Panel Dental (GPPD)
  {
    desc: "PRUtreasures flexi II Product Summary – Group Panel Dental (June 2023)",
    fileNameOriginal: "PRUTreasures Flexi II Product Summary - Group Panel Dental (June 2023) v1.3 (Final).pdf",
    fileName: "PruTreasures Flexi II - Group Panel Dental - (June 2023).pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    code: { PF3: [Enums.PLANS_CODE.GPPD] }
  },
  {
    desc: "PRUTreasures Flexi II Product Summary - Group Foreign Worker Medical Insurance (Jun 2024)",
    fileNameOriginal: "PRUTreasures Flexi II Product Summary - Group Foreign Worker Medical Insurance_(Jun 2024).pdf",
    fileName: "PRUTreasures Flexi II Product Summary - Group Foreign Worker Medical Insurance_(Jun 2024).pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    code: { PF3: [Enums.PLANS_CODE.GFWM] }
  },
  {
    desc: "Sample Employee Template",
    fileNameOriginal: "PTRF_Employee_Template.xlsx",
    fileName: "Sample_Employee_Template.xlsx",
    type: Enums.P_CODE.PRU_AGENCY
  },
  {
    desc: "Health Declaration Form",
    fileNameOriginal: "Form - Group Health Declaration (Jun 2018) PEP.pdf",
    fileName: "Form - Group Health Declaration (Jun 2018) PEP.pdf",
    type: Enums.P_CODE.UOB_ASSISTED,
    code: { PEP: [Enums.PLANS_CODE.HDF] }
  },
  {
    desc: "PRUenterprise protect - GROUP OUTPATIENT GENERAL PRACTITIONER",
    fileNameOriginal: "PRUenterprise protect without dependent- Group GP cleaned.pdf",
    fileName: "PRUenterprise protect without dependent- Group GP.pdf",
    type: Enums.P_CODE.UOB_ASSISTED,
    code: { PEP: [Enums.PLANS_CODE.GPRU] }
  },
  {
    desc: "PRUenterprise protect - GROUP HOSPITAL and SURGICAL",
    fileNameOriginal: "PRUenterprise protect without dependent- Group Hospital & Surgical cleaned.pdf",
    fileName: "PRUenterprise protect without dependent- Group Hospital & Surgical.pdf",
    type: Enums.P_CODE.UOB_ASSISTED,
    code: { PEP: [Enums.PLANS_CODE.GHSB] }
  },
  {
    desc: "PRUenterprise protect - GROUP TERM LIFE",
    fileNameOriginal: "PRUenterprise protect without dependent- Group Term Life cleaned.pdf",
    fileName: "PRUenterprise protect without dependent- Group Term Life.pdf",
    type: Enums.P_CODE.UOB_ASSISTED,
    code: { PEP: [Enums.PLANS_CODE.GTLB] }
  },
  {
    desc: "Group Hospital & Surgical Claim Form",
    fileNameOriginal: "Form - Group GHS Claim. V03.2018.pdf",
    fileName: "Form - Group GHS Claim. V03.2018.pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    code: { PTF: [Enums.PLANS_CODE.GHSB], PF3: [Enums.PLANS_CODE.HDF] }
  },
  {
    desc: "IHP Mobile Guide (If applicable)",
    fileNameOriginal: "PRU - Mobile Application Guide 2019 revised.pdf",
    fileName: "PRU - Mobile Application Guide 2019 revised.pdf",
    type: Enums.P_CODE.PRU_AGENCY
  },
  {
    desc: "HREasily Benefits Brochure",
    fileNameOriginal: "PruWorks_HREasily_Brochure.pdf",
    fileName: "PruWorks_HREasily_Brochure.pdf"
  },
  {
    desc: "Declaration of Entitlement to Claim Input Tax on Insurance Policy",
    fileNameOriginal: "Declaration of Entitlement to Claim Input Tax on Insurance Policy.pdf",
    fileName: "Declaration of Entitlement to Claim Input Tax on Insurance Policy.pdf",
    type: Enums.P_CODE.PRU_AGENCY
  },
  {
    desc: "Group Hospital & Surgical Claim Form",
    fileNameOriginal: "Form - Group GHS Claim. V03.2018.pdf",
    fileName: "Form - Group GHS Claim. V03.2018.pdf",
    type: Enums.P_CODE.PRU_CE
  },
  {
    desc: "PRUworks HR User Guide",
    fileNameOriginal: "Pruworks-HR-PCE-User-Guide.pdf",
    fileName: "PRUworks HR User Guide.pdf",
    type: Enums.P_CODE.PRU_CE
  }
];

export default doc;
