import Enums from "../../../../../utils/Enums";

const GDBenefits = {
  headers: [
    {
      title: ``,
      mandatory: true,
      attributes: {
        style: {
          width: 10
        }
      }
    },
    {
      title: `Benefits / Procedures`,
      mandatory: true,
      attributes: {
        style: {
          width: "40%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>1</strong>`
        },
        {
          data: `<p style="margin-left: -13px"><strong>X-RAY</strong></p>`
        },
        {
          data: `Reimbursement subject to annual limit`,
          attributes: {
            rowspan: 8
          }
        },
        {
          data: `-`,
          attributes: {
            rowspan: 8
          }
        },
        {
          data: `-`,
          attributes: {
            rowspan: 8
          }
        },
        {
          data: `-`,
          attributes: {
            rowspan: 8
          }
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>2</strong>`
        },
        {
          data: `<strong>Prophylaxis Treatment</strong>
                <br>(General Scaling & Polishing)
                `
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>3</strong>`
        },
        {
          data: `<strong>Fillings</strong>`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>4</strong>`
        },
        {
          data: `<strong>Simple and Surgical Extraction</strong>`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>5</strong>`
        },
        {
          data: `<strong>Root Canal Treatment</strong>`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>6</strong>`
        },
        {
          data: `<strong>Gum Treatment</strong>`,
          info: `<em>(including Curettage)</em>`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>7</strong>`
        },
        {
          data: `<strong>Periodontal Surgery</strong>`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>8</strong>`
        },
        {
          data: `<strong>Repairs of Dentures, Crowns and Bridges Due to Accident</strong>`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>9</strong>`
        },
        {
          data: `<p style="margin-left: -13px"><strong>Annual Limit</strong></p>`
        },
        {
          data: `<strong>$500</strong>`
        },
        {
          data: `-`
        },
        {
          data: `-`
        },
        {
          data: `-`
        }
      ]
    }
  ],
  footnotes: []
};

let GDPremium = {
  headers: [
    {
      title: `Plan Type`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Annual Premium</strong>`
        },
        {
          data: `S$ 285.00`
        }
      ]
    }
  ],
  footnotes: []
};

let Occupational = {
  title: `Occupational`,
  headers: [
    {
      title: `Class`,
      mandatory: true
    },
    {
      title: `Occupations`,
      mandatory: true
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Class 1</strong>`
        },
        {
          data: `Clerical, administrative or other similar non-hazardous occupations`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>Class 2</strong>`
        },
        {
          data: `Occupations where some degree of risk is involved, e.g. supervision of manual workers, totally administrative job in an industrial environment`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>Class 3</strong>`
        },
        {
          data: `Occupations involving regular light to medium manual work but no substantial hazard which may increase the risk of sickness or accident`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>Class 4</strong>`
        },
        {
          data: `<strong>Not covered</strong>`
        }
      ]
    }
  ],
  footnotes: []
};

//   ----------------------------------------------------------------------
const GP = {
  id: 1,
  code: "GDEN",
  label: `Group Dental (GDEN)`,
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GDBenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium (inclusive of 7% GST)",
      data: [GDPremium, Occupational]
    }
  ]
};

export default GP;
