import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import FormHelperText from "@mui/material/FormHelperText";
import classNames from "classnames";
import Grid from "@mui/material/Grid";
import React from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Util from "../../../../../../utils/Util";
import RefreshIcon from "@mui/icons-material/Refresh";

const renderTextField = props => {
  const {
    input,
    label,
    children,
    isSelect,
    meta: { error, warning, touched },
    ...custom
  } = props;

  return (
    <div>
      <TextField
        margin="normal"
        fullWidth
        select={isSelect || false}
        error={touched && error !== undefined}
        label={label}
        {...input}
        {...custom}
      >
        {children}
      </TextField>
      {touched &&
        ((error && (
          <FormHelperText error={true} className={classNames({ "m-b-10": error })}>
            {error}
          </FormHelperText>
        )) ||
          (warning && (
            <FormHelperText error={true} className={classNames({ "m-b-10": warning })}>
              {warning}
            </FormHelperText>
          )))}
    </div>
  );
};

const emailValidation = value => (value && !Util.isEmail(value) ? "The email address provided is invalid." : undefined);
const required = value => (value ? undefined : "Required");

const SendDocumentForm = props => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Typography variant="caption">Company Name</Typography>
      <div>
        <Typography variant="subheading" gutterBottom>
          {props.companyName}
        </Typography>
      </div>
      <Grid container spacing={"24px"}>
        <Grid item sm={12} md={6}>
          <Typography variant="caption">Plan Activation Date</Typography>
          <div>
            <Typography variant="subheading" gutterBottom>
              {props.activationDate}
            </Typography>
          </div>
        </Grid>
        <Grid item sm={12} md={6}>
          <Typography variant="caption">Actual Annual Premium</Typography>
          <div>
            <Typography variant="subheading" gutterBottom>
              {props.actualAmount}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <div>
        <Grid container>
          <Grid item xs={2} sm={2} md={2}>
            <img
              src={`data:image/jpeg;base64,${props.captcha.captcha_image !== null && props.captcha.captcha_image}`}
            />
          </Grid>
          <Grid item xs={2} sm={2} md={2}>
            <RefreshIcon
              onClick={() => props.refreshCaptcha()}
              style={{
                fontSize: "40px",
                cursor: "pointer",
                paddingTop: "0px",
                paddingLeft: "4px",
                color: "#a9a9a9",
                border: "1px solid #a9a9a9",
                marginLeft: "45px"
              }}
            />
          </Grid>
        </Grid>
        <TextField id="captcha" fullWidth={true} onChange={props.captchaEntered} label={"Enter captcha"} />
        {props.captchaErrorMessage !== "" && (
          <Typography variant="caption" style={{ color: "red", whiteSpace: "pre-line" }}>
            {props.captchaErrorMessage}
          </Typography>
        )}
      </div>
      <Field
        name="email"
        component={renderTextField}
        autoComplete="[off]"
        label="Email Address To Send Document Pack To"
        type="email"
        validate={[required, emailValidation]}
      />
    </form>
  );
};

function mapStateToProps(state, prop) {
  return {
    initialValues: { email: prop.email }
  };
}
export default connect(mapStateToProps)(
  reduxForm({
    form: "send-document"
  })(SendDocumentForm)
);
