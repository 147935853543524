import Enums from "../../../../utils/Enums";

const GEMMBenefits = {
  headers: [
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "30%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Daily Room and Board<strong>`,
          info: `<em>(including ICU & HDW)</em>`
        },
        {
          data: `(As per Basis Group Hospital & Surgical Plan)`
        },
        {
          data: `(As per Basis Group Hospital & Surgical Plan)`
        },
        {
          data: `(As per Basis Group Hospital & Surgical Plan)`
        },
        {
          data: `(As per Basis Group Hospital & Surgical Plan)`
        },
        {
          data: `(As per Basis Group Hospital & Surgical Plan)`
        }
      ]
    },
    {
      id: 1,
      columns: [
        {
          data: `<strong>Provides reimbursement of eligible expenses in excess of Basic GHS</strong>`,
          info: `if:<br><ol type="a">
          <li><em>Hospitalisation is at least 20 days; or</em></li>
          <li><em>Surgical Percentage is at least 75% per incision</em></li>
          </ol>`
        },
        {
          data: `S$ 80,000 <br> per disability`,
          attributes: {
            style: {
              width: "100%"
            }
          }
        },
        {
          data: `S$ 60,000 <br> per disability`,
          attributes: {
            style: {
              width: "100%"
            }
          }
        },
        {
          data: `S$ 40,000 <br> per disability`,
          attributes: {
            style: {
              width: "100%"
            }
          }
        },
        {
          data: `S$ 60,000 <br> per disability`,
          attributes: {
            style: {
              width: "100%"
            }
          }
        },
        {
          data: `S$ 20,000 <br> per disability`,
          attributes: {
            style: {
              width: "100%"
            }
          }
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>Daily Home Nursing Benefit</strong>`,
          info: `<em>(max. per day, up to 30 days per disability)</em>`
        },
        {
          data: `S$ 80 per day for all plans`,
          info: `(subject to respective benefit limit)`
        },
        {
          data: `S$ 80 per day for all plans`,
          info: `(subject to respective benefit limit)`
        },
        {
          data: `S$ 80 per day for all plans`,
          info: `(subject to respective benefit limit)`
        },
        {
          data: `S$ 80 per day for all plans`,
          info: `(subject to respective benefit limit)`
        },
        {
          data: `S$ 80 per day for all plans`,
          info: `(subject to respective benefit limit)`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>HIV Due to Blood Transfusion and Occupationally Acquired HIV</strong>`
        },
        {
          data: `S$ 5,000 per policy year`,
          info: `(subject to respective benefit limit)`
        },
        {
          data: `S$ 5,000 per policy year`,
          info: `(subject to respective benefit limit)`
        },
        {
          data: `S$ 5,000 per policy year`,
          info: `(subject to respective benefit limit)`
        },
        {
          data: `S$ 5,000 per policy year`,
          info: `(subject to respective benefit limit)`
        },
        {
          data: `S$ 5,000 per policy year`,
          info: `(subject to respective benefit limit)`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>Parent Accommodation</strong>`,
          info: `<em>(up to 60 days for accompanying child age 12 and below)</em>`
        },
        {
          data: `S$ 100 per day for all plans`,
          info: `(subject to respective benefit limit)`
        },
        {
          data: `S$ 100 per day for all plans`,
          info: `(subject to respective benefit limit)`
        },
        {
          data: `S$ 100 per day for all plans`,
          info: `(subject to respective benefit limit)`
        },
        {
          data: `S$ 100 per day for all plans`,
          info: `(subject to respective benefit limit)`
        },
        {
          data: `S$ 100 per day for all plans`,
          info: `(subject to respective benefit limit)`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>Deductible</strong>`
        },
        {
          data: `As per Basic GHS`
        },
        {
          data: `As per Basic GHS`
        },
        {
          data: `As per Basic GHS`
        },
        {
          data: `As per Basic GHS`
        },
        {
          data: `As per Basic GHS`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>Co-Insurance</strong>`
        },
        {
          data: `20%`
        },
        {
          data: `20%`
        },
        {
          data: `20%`
        },
        {
          data: `20%`
        },
        {
          data: `20%`
        }
      ]
    }
  ],
  footnotes: []
};

let GEMMPremiumEmployee = {
  title: `<strong>* For Employee Only <i>(inclusive of 8% GST)</i></strong>`,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 33.31`
        },
        {
          data: `S$ 26.24`
        },
        {
          data: `S$ 17.16`
        },
        {
          data: `S$ 12.12`
        },
        {
          data: `S$ 9.08`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 33.31`
        },
        {
          data: `S$ 26.24`
        },
        {
          data: `S$ 17.16`
        },
        {
          data: `S$ 12.12`
        },
        {
          data: `S$ 9.08`
        }
      ]
    }
  ],
  footnotes: []
};

let GEMMPremiumSpouseOrChildren = {
  title: `<br><strong>* For Employee and Spouse or Children Coverage Only <i>(inclusive of 8% GST)</i></strong>`,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 74.94,`
        },
        {
          data: `S$ 59.04`
        },
        {
          data: `S$ 38.61`
        },
        {
          data: `S$ 27.25`
        },
        {
          data: `S$ 20.44`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 74.94`
        },
        {
          data: `S$ 59.04`
        },
        {
          data: `S$ 38.61`
        },
        {
          data: `S$ 27.25`
        },
        {
          data: `S$ 20.44`
        }
      ]
    }
  ],
  footnotes: []
};

let GEMMPremiumSpouseAndChildren = {
  title: `<br><strong>* For Employees and Spouse and Children Coverage <i>(inclusive of 8% GST)</i></strong>`,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 116.58`
        },
        {
          data: `S$ 91.85`
        },
        {
          data: `S$ 60.06`
        },
        {
          data: `S$ 42.39`
        },
        {
          data: `S$ 31.80`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 116.58`
        },
        {
          data: `S$ 91.85`
        },
        {
          data: `S$ 60.06`
        },
        {
          data: `S$ 42.39`
        },
        {
          data: `S$ 31.80`
        }
      ]
    }
  ],
  footnotes: []
};
//   ----------------------------------------------------------------------
const GEMM = {
  id: 12,
  code: "GEMM",
  label: "Group Extended Major Medical (GEMM)",
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GEMMBenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium (inclusive of 7% GST)",
      data: [GEMMPremiumEmployee, GEMMPremiumSpouseOrChildren, GEMMPremiumSpouseAndChildren]
    }
  ]
};

export default GEMM;
