export default class ExternalScript {
  static load(window, url, callback) {
    let document = window.document;

    let script = document.createElement("script");
    script.type = "text/javascript";
    if (script.readyState) {
      //IE
      script.onreadystatechange = function() {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      //Others
      script.onload = function() {
        callback();
      };
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  }

  static inject(window, data, callback) {
    let document = window.document;
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.textContent = data;
    document.getElementsByTagName("head")[0].appendChild(script);
    callback();
  }
}
