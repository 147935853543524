import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box, Grid, Typography, Radio } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";

const emails = ["username@gmail.com", "user02@gmail.com"];

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = value => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>View all documemts</DialogTitle>
      <Box
        sx={{
          overflow: "hidden",
          backgroundColor: "white",
          padding: "30px 30px 35px 30px",
          boxShadow: "-2px -2px 20px 0 rgba(0,0,0,0.1)",
          marginTop: "20px",
          marginBottom: "80px"
        }}
      >
        <Grid sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography sx={{ padding: "0px 0px 20px 0px", fontSize: "18px", fontWeight: 500 }}>
            Sunmitted documents
          </Typography>
          <Button sx={{ fontWeight: 500, fontSize: "16px" }}>Download all</Button>
        </Grid>

        <div>
          <div
            item
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px 0px",
              borderBottom: "1px solid #ccc"
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: 500, textDecoration: "underline" }}>
              Proposal Form
            </Typography>
            <DownloadIcon />
          </div>
          <div
            item
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px 0px",
              borderBottom: "1px solid #ccc"
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: 500, textDecoration: "underline" }}>
              Declaration Form of Entitlement to Claim Input Tax on Insurance Policy
            </Typography>
            <DownloadIcon />
          </div>
        </div>
      </Box>
      <Button sx={{ fontWeight: 500, fontSize: "16px" }}>Close</Button>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired
};

export default function SimpleDialogDemo() {
  const policyRenew = useSelector(state => state.policyRenew);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Click me
      </Button>
      <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
    </div>
  );
}
