import Enums from "../../../../../utils/Enums";

const GLTBenefits = {
  headers: [
    {
      title: `Benefits / Plan Type`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1,
      info: `Health declaration required.`
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    }
    // {
    //   title: `Plan 4`,
    //   tier: 4
    // },
    // {
    //   title: `Plan 5`,
    //   tier: 5
    // }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Group Term Life<br></strong>`,
          info: `<em>
              <ul>
              <li>Death</li>
              <li>Total and Permanent Disability (TPD)</li>
              </ul>`
        },
        {
          data: `S$ 150,000`
        },
        {
          data: `S$ 80,000`
        },
        {
          data: `S$ 50,000`
        }
      ]
    }
    // {
    //   id: 2,
    //   columns: [
    //     {
    //       data: `<strong>Repatriation of Mortal Remains</strong>`,
    //       info: `<em>(per member)</em>`
    //     },
    //     {
    //       data: `S$ 50,000`
    //     },
    //     {
    //       data: `S$ 50,000`
    //     },
    //     {
    //       data: `S$ 50,000`
    //     },
    //     {
    //       data: `S$ 50,000`
    //     },
    //     {
    //       data: `S$ 50,000`
    //     }
    //   ]
    // }
  ],
  footnotes: []
};

let GLTPremium = {
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    }
    // {
    //   title: `Plan 4`,
    //   tier: 4
    // },
    // {
    //   title: `Plan 5`,
    //   tier: 5
    // }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 19</strong>`
        },
        {
          data: `S$ 118.00`
        },
        {
          data: `S$ 69.00`
        },
        {
          data: `S$ 40.00`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>20 - 24</strong>`
        },
        {
          data: `S$ 112.00`
        },
        {
          data: `S$ 66.00`
        },
        {
          data: `S$ 40.00`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>25 - 29</strong>`
        },
        {
          data: `S$ 110.00`
        },
        {
          data: `S$ 62.00`
        },
        {
          data: `S$ 37.00`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>30 - 34</strong>`
        },
        {
          data: `S$ 110.00`
        },
        {
          data: `S$ 68.00`
        },
        {
          data: `S$ 37.00`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>35 - 39</strong>`
        },
        {
          data: `S$ 129.00`
        },
        {
          data: `S$ 76.00`
        },
        {
          data: `S$ 43.00`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>40 - 44</strong>`
        },
        {
          data: `S$ 210.00`
        },
        {
          data: `S$ 110.00`
        },
        {
          data: `S$ 70.00`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>45 - 49</strong>`
        },
        {
          data: `S$ 300.00`
        },
        {
          data: `S$ 178.00`
        },
        {
          data: `S$ 100.00`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>50 - 54</strong>`
        },
        {
          data: `S$ 510.00`
        },
        {
          data: `S$ 301.00`
        },
        {
          data: `S$ 170.00`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>55 - 59</strong>`
        },
        {
          data: `S$ 962.00`
        },
        {
          data: `S$ 510.00`
        },
        {
          data: `S$ 321.00`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>60 - 64</strong>`
        },
        {
          data: `S$ 1,580.00`
        },
        {
          data: `S$ 850.00`
        },
        {
          data: `S$ 516.00`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>65 - 69</strong>`
        },
        {
          data: `S$ 2,420.00`
        },
        {
          data: `S$ 1,425.00`
        },
        {
          data: `S$ 804.00`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `<strong>70</strong>`
        },
        {
          data: `S$ 3,500.00`
        },
        {
          data: `S$ 1,980.00`
        },
        {
          data: `S$ 1,180.00`
        }
      ]
    }
  ],
  footnotes: []
};

let GLTGroupDiscount = {
  headers: [
    {
      title: `Group Size <i>Insured Employee<i>`,
      mandatory: true
    },
    {
      title: `Discount`,
      mandatory: true
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>11 - 15</strong>`
        },
        {
          data: `5%`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>16 and above</strong>`
        },
        {
          data: `10%`
        }
      ]
    }
  ],
  footnotes: []
};

//   ----------------------------------------------------------------------

const GLT = {
  id: 1,
  code: "GTLB",
  label: "Group Term Life (GTL)",
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GLTBenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium",
      data: [GLTPremium]
    }
    /*{
      id: 3,
      order: 3,
      type: Enums.PLANS_TYPE.GROUP_DISCOUNT,
      label: "Group Size Discount",
      data: [GLTGroupDiscount]
    }*/
  ]
};

export default GLT;
