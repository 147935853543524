import React, { Component } from "react";
import { withStyles } from "@mui/styles";

import Popover from "@mui/material/Popover";
import { findDOMNode } from "react-dom";
import Typography from "@mui/material/Typography";

class InfoPopover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      element: this.getDomNode(this.props.element)
    };
  }

  componentWillReceiveProps(nextProps) {
    //some case it throw exception when element is unmounted ( dialog)
    let element = this.getDomNode(nextProps.element);
    this.setState({ element });
  }

  getDomNode(el) {
    //some case it throw exception when element is unmounted ( dialog)
    let element = null;
    try {
      element = findDOMNode(el);
    } catch (e) {}
    return element;
  }

  render() {
    const { classes, open, data, handleClickInfoButton, anchor } = this.props;
    const { element } = this.state;

    // let anchorReference = "anchorPosition"

    // let anchorPosition = { top: 670, left: 1000 }

    let anchorOrigin = {
      vertical: "top",
      horizontal: "right"
    };
    let transformOrigin = {
      vertical: "bottom",
      horizontal: "center"
    };

    return (
      <Popover
        className={classes.container}
        open={open}
        onClose={handleClickInfoButton()}
        anchorEl={element}
        // anchorPosition={anchorPosition}
        // anchorReference={anchorReference}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={{ style: { width: "78%" } }}
      >
        <Typography className={classes.content}>
          <span
            dangerouslySetInnerHTML={{
              __html: data
            }}
          />
        </Typography>
      </Popover>
    );
  }
}

const styles = theme => ({
  root: {},
  container: {},
  content: {
    padding: 5,
    paddingLeft: 15,
    fontSize: ".75em",
    backgroundColor: "#fdf1ef"
  }
});

export default withStyles(styles, { withTheme: true })(InfoPopover);
