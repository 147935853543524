import Enums from "../../../../../utils/Enums";

const GADDBenefits = {
  headers: [
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "30%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Sum Assured</strong>`
        },
        {
          data: `S$ 500,000`
        },
        {
          data: `S$ 200,000`
        },
        {
          data: `S$ 150,000`
        },
        {
          data: `S$ 80,000`
        },
        {
          data: `S$ 50,000`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>Optional Benefit : Accidental Medical Reimbursement</strong>`
        },
        {
          data: `S$ 5,000`,
          attributes: {
            colspan: 5,
            style: {
              width: "100%",
              textAlign: "center"
            }
          }
        }
      ]
    }
  ],
  footnotes: []
};

let GADDPremium = {
  title: `Annual Premium Rate (inclusive of 9% GST) – Group Accidental Death & Dismemberment`,
  headers: [
    {
      title: `Occupational Class`,
      mandatory: true
    },
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Class 1</strong>`,
          attributes: {
            rowspan: 2
          }
        },
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 156.88`
        },
        {
          data: `S$ 67.23`
        },
        {
          data: `S$ 50.94`
        },
        {
          data: `S$ 27.50`
        },
        {
          data: `S$ 17.32`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 642.79`
        },
        {
          data: `S$ 279.13`
        },
        {
          data: `S$ 208.83`
        },
        {
          data: `S$ 112.05`
        },
        {
          data: `S$ 70.29`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>Class 2</strong>`,
          attributes: {
            rowspan: 2
          }
        },
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 186.42`
        },
        {
          data: `S$ 81.50`
        },
        {
          data: `S$ 61.13`
        },
        {
          data: `S$ 32.60`
        },
        {
          data: `S$ 20.37`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 766.05`
        },
        {
          data: `S$ 338.21`
        },
        {
          data: `S$ 253.65`
        },
        {
          data: `S$ 135.49`
        },
        {
          data: `S$ 84.55`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>Class 3</strong>`,
          attributes: {
            rowspan: 2
          }
        },
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 245.50`
        },
        {
          data: `S$ 109.00`
        },
        {
          data: `S$ 82.51`
        },
        {
          data: `S$ 43.81`
        },
        {
          data: `S$ 27.50`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 1,011.56`
        },
        {
          data: `S$ 455.36`
        },
        {
          data: `S$ 341.26`
        },
        {
          data: `S$ 182.35`
        },
        {
          data: `S$ 114.09`
        }
      ]
    }
  ],
  footnotes: []
};

let GAMRPremium2 = {
  title: `Annual Premium Rate (inclusive of 9% GST) – Group Accidental Medical Reimbursement`,
  headers: [
    {
      title: `Occupational Class`,
      mandatory: true
    },
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Class 1</strong>`,
          attributes: {
            rowspan: 2
          }
        },
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 37.69`
        },
        {
          data: `S$ 37.69`
        },
        {
          data: `S$ 37.69`
        },
        {
          data: `S$ 37.69`
        },
        {
          data: `S$ 37.69`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 157.90`
        },
        {
          data: `S$ 157.90`
        },
        {
          data: `S$ 157.90`
        },
        {
          data: `S$ 157.90`
        },
        {
          data: `S$ 157.90`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>Class 2</strong>`,
          attributes: {
            rowspan: 2
          }
        },
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 46.86`
        },
        {
          data: `S$ 46.86`
        },
        {
          data: `S$ 46.86`
        },
        {
          data: `S$ 46.86`
        },
        {
          data: `S$ 46.86`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 196.60`
        },
        {
          data: `S$ 196.60`
        },
        {
          data: `S$ 196.60`
        },
        {
          data: `S$ 196.60`
        },
        {
          data: `S$ 196.60`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>Class 3</strong>`,
          attributes: {
            rowspan: 2
          }
        },
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 65.19`
        },
        {
          data: `S$ 65.19`
        },
        {
          data: `S$ 65.19`
        },
        {
          data: `S$ 65.19`
        },
        {
          data: `S$ 65.19`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 275.05`
        },
        {
          data: `S$ 275.05`
        },
        {
          data: `S$ 275.05`
        },
        {
          data: `S$ 275.05`
        },
        {
          data: `S$ 275.05`
        }
      ]
    }
  ],
  footnotes: []
};
//   ----------------------------------------------------------------------
const GADD = {
  id: 6,
  code: "GADD",
  label: "Group Accidental Death & Dismemberment (GADD) (for Employee only)",
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GADDBenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium (inclusive of 9% GST)",
      data: [GADDPremium, GAMRPremium2]
    }
  ]
};

export default GADD;
