import React from "react";
import Typography from "@mui/material/Typography";
import UploadedFile from "./UploadedFile";
import RenewalUploadedFile from "./RenewalUploadedFile";

const RenderFileOrUploader = ({ title, files, Uploader, disabled, type, onRemoveFile }) => {
  if (files.length > 0) {
    const filesContent = files.map(file =>
      type == "renewal" ? (
        <RenewalUploadedFile key={file.encrypted_id} file={file} disabled={disabled} onRemoveFile={onRemoveFile} />
      ) : (
        <UploadedFile key={file.encrypted_id} file={file} disabled={disabled} />
      )
    );

    return (
      <div>
        <Typography variant="subheading" gutterBottom>
          {title}
        </Typography>
        {filesContent}
      </div>
    );
  } else {
    return Uploader;
  }
};

export default RenderFileOrUploader;
