import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Description as DescriptionIcon } from "@mui/icons-material";
import { documentActions, quoteActions } from "../../../../../../actions";
import SimpleConfirmDialog from "../../../../../../components/SimpleConfirmDialog";
import ENUMS from "../../../../../../utils/Enums";

class RenewalUploadedFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openConfirmDialog: false
    };
  }

  openConfirmDialog = () => {
    this.setState({ openConfirmDialog: true });
  };

  closeConfirmDialog = () => {
    this.setState({ openConfirmDialog: false });
  };

  responseConfirmDialog = () => {
    this.removeProposal();
  };

  removeProposal = () => {
    const { policyRenew, file, deleteRenewalFile, setActiveStep, save, onRemoveFile } = this.props;
    deleteRenewalFile(policyRenew.id, file.encrypted_id)
      .then(res => {
        onRemoveFile();
      })
      .catch(error => {});
  };

  render() {
    const { classes, file, disabled } = this.props;
    // let isProposal = file.file_type === ENUMS.FILE_TYPE.ACCEPTANCE_FILE_TYPE;
    return (
      <div className={classes.fileWrapper}>
        {/* <DescriptionIcon className={quote.readOnly || disabled ? classes.disabled : null} /> */}
        <Typography variant="caption" className={classes.fileName}>
          {file.name}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          disabled={disabled}
          //   disabled={quote.readOnly || disabled}
          className={classes.button}
          onClick={() => {
            // if (isProposal) this.openConfirmDialog();
            // else {
            this.removeProposal();
            // }
          }}
        >
          Remove file
        </Button>

        {/* <SimpleConfirmDialog
          name="proposal-remove-confirm-dialog"
          title="Remove Document"
          description="Please note that removing the proposal form upload will unlock steps 1 - 3 for editing and require you to send the document pack to the client again."
          closeButtonText="Cancel"
          okButtonText="OK"
          closeHandler={this.closeConfirmDialog}
          okHandler={this.responseConfirmDialog.bind(this)}
          open={this.state.openConfirmDialog}
        /> */}
      </div>
    );
  }
}

const styles = theme => ({
  fileWrapper: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0"
  },
  fileName: {
    padding: "0 20px"
  },
  disabled: {
    color: theme.colors.blackScale.black70
  }
});

function mapStateToProps(state, prop) {
  return {
    policyRenew: state.policyRenew
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteRenewalFile: (...payload) => dispatch(documentActions.deleteRenewalFile(...payload)),
    save: (...payload) => dispatch(quoteActions.save(...payload))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(RenewalUploadedFile));
