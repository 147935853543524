import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import BoxItem from "./boxItem";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import { getAvaliableProductBenefits } from "../../../../../selectors/CommonSelectors";
import SimpleConfirmDialog from "../../../../../components/SimpleConfirmDialog";
import Enums from "../../../../../utils/Enums";
import BenefitService from "../../../../../services/HR/benefit.service";

class Main extends Component {
  constructor(props) {
    super(props);

    const agentType = props.agentType || "AD";
    this.state = {
      listBenefits: getAvaliableProductBenefits(agentType),
      redirectDialog: false,
      redirectUrl: "",
      partnerKey: ""
    };
    this.route = this.props.route;
    this.showListBenefits = this.showListBenefits.bind(this);
  }

  openRedirectDialog = (redirectUrl, partnerKey) => {
    this.setState({
      redirectDialog: true,
      redirectUrl: redirectUrl,
      partnerKey: partnerKey
    });
  };

  closeRedirectDialog = () => {
    this.setState({
      redirectDialog: false,
      redirectUrl: "",
      partnerKey: ""
    });
  };

  responseRedirectDialog = () => {
    window.open(this.state.redirectUrl, "_blank", "noopener noreferrer");
    this.callPartnerTransactionDetailsAPI(Enums.STASH.CODE);
    this.setState({
      redirectDialog: false,
      redirectUrl: "",
      partnerKey: ""
    });
  };

  callPartnerTransactionDetailsAPI(value) {
    BenefitService.sendPartnerTransactionDetails(value, this.state.partnerKey);
  }

  showListBenefits(classes) {
    let listComponents = this.state.listBenefits.map((data, index) => {
      return data.key === "more_services" ? (
        <Grid key={index} item className={classes.wrapper}>
          <BoxItem data={data} />
        </Grid>
      ) : data.instantRedirect === true ? (
        <Grid key={index} item className={classes.wrapper}>
          <a onClick={() => this.openRedirectDialog(data.redirectUrl, data.key)}>
            <BoxItem data={data} />
          </a>
        </Grid>
      ) : (
        <Grid key={index} item className={classes.wrapper}>
          <Link to={this.route.path + "/details/" + data.key} className={classes.menuItem}>
            <BoxItem data={data} />
          </Link>
        </Grid>
      );
    });
    return listComponents;
  }

  render() {
    const { classes } = this.props;

    const showProductBenefits = this.state.listBenefits.length > 0;

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Typography className={classes.heading} variant="title" color="inherit">
            Look out for our new and exciting partners soon!
          </Typography>
          <br />
          <Typography className={classes.subHeading} variant="title" color="inherit"></Typography>
          <br />

          <Grid className={classes.listBenefits} container spacing={"8px"}>
            {showProductBenefits && this.showListBenefits(classes)}
          </Grid>
        </div>
        <SimpleConfirmDialog
          name="leave-confirm-dialog"
          title="You’re leaving the HR Portal."
          description="We will redirect you to our partner’s website. Click ok to continue."
          closeButtonText="Cancel"
          okButtonText="OK"
          closeHandler={this.closeRedirectDialog}
          okHandler={this.responseRedirectDialog}
          open={this.state.redirectDialog}
        />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    height: "100%"
  },
  menuItem: {
    textDecoration: "none"
  },
  heading: {
    paddingTop: "30px",
    fontSize: "28px",
    color: "#333333"
  },
  subHeading: {
    paddingBottom: "30px",
    fontSize: "22px",
    color: "#848689"
  },
  content: {
    padding: "10px 40px"
  },
  listBenefits: {
    flexGrow: 1
  },
  wrapper: {
    padding: 5
  }
});

function mapStateToProps(state) {
  return { agentType: state.user.auth.pcode };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Main));
