import Login from "./containers/Login/Login";
import ResetPassword from "./containers/Login/ResetPassword";
import Enums from "./utils/Enums";

//Authenticated
import Auth from "./containers/Auth";
import HomePage from "./containers/Auth/HomePage";
import CurrentQuote from "./containers/Auth/CurrentQuote";
import SavedQuotes from "./containers/Auth/SavedQuotes";
import ProductInfo from "./containers/Auth/ProductInfo";
import SalesDocuments from "./containers/Auth/MyDocuments";

//Renewal Submission
import Renewal from "./containers/Auth/Renewal";

//Client & Policy Listing
import PolicyInformation from "./containers/Auth/PolicyInformation";
import PolicyDashboard from "./containers/Auth/PolicyInformation/PolicyDashboard";
import PolicyMyEmployees from "./containers/Auth/PolicyInformation/PolicyMyEmployees";
import PolicyInsuranceCoverage from "./containers/Auth/PolicyInformation/InsuranceCoverage";
import PolicyDocuments from "./containers/Auth/PolicyInformation/PolicyDocuments";
import PolicyClaimExpenses from "./containers/Auth/PolicyInformation/PolicyClaimExpenses";

//TODO: to move pages out of _Placeholder as we build the actual
import Tutorial from "./containers/Auth/_Placeholder/Tutorial";
import NotFound from "./containers/Auth/_Placeholder/NotFound";

// HR ============================================================
import HRInsuranceCoverage from "./containers/Auth/_HR/InsuranceCoverage";
import HRInsuranceCoverageListCompanyHR from "./containers/Auth/_HR/InsuranceCoverage/InsuranceCoverageListCompanyHR";
import HRCoverageDisplay from "./containers/Auth/_HR/InsuranceCoverage/CoverageDisplay";
import HRMyEmployeesListCompanyHR from "./containers/Auth/_HR/MyEmployees/MyEmployeesListCompanyHR";
import HRMyEmployees from "./containers/Auth/_HR/MyEmployees";
import HRMyEmployeesList from "./containers/Auth/_HR/MyEmployees/MyEmployee";
import EmployeeBenefit from "./containers/Auth/_HR/EmployeeBenefit";
import EmployeeBenefitMain from "./containers/Auth/_HR/EmployeeBenefit/Main";
import BenefitDetail from "./containers/Auth/_HR/EmployeeBenefit/BenefitDetail";
import HRDocuments from "./containers/Auth/_HR/MyDocuments";
import HRDocumentsListCompanyHR from "./containers/Auth/_HR/MyDocuments/MyDocumentListCompanyHR";
import HRDocumentsDisplay from "./containers/Auth/_HR/MyDocuments/MyDocuments";
import TncPage from "./containers/TncPage";
import MaintenancePage from "./containers/MaintenancePage";
import HRClaimExpenses from "./containers/Auth/_HR/ClaimExpenses";
import HRClaimExpensesListCompanyHR from "./containers/Auth/_HR/ClaimExpenses/ClaimExpensesListCompanyHR";
import HRClaimExpensesInfo from "./containers/Auth/_HR/ClaimExpenses/ClaimExpenses";
import HRClaimAndPolicyListing from "./containers/Auth/_HR/ClientAndPolicyListing";
import HRClaimAndPolicyListingListCompanyHR from "./containers/Auth/_HR/ClientAndPolicyListing/ClientAndPolicyListingListCompanyHR";
import HRClaimAndPolicyListingDashboard from "./containers/Auth/_HR/ClientAndPolicyListing/dashboard";

// RESOUCE
import assets from "./assets";
import IconInsuranceCoverage from "./assets/svg/InsuranceCoverage";
import IconDashboard from "./assets/svg/Dashboard";
import IconMyEmployees from "./assets/svg/MyEmployees";
import IconClaimExpenses from "./assets/svg/ClaimExpenses";
import IconDocument from "./assets/svg/Document";
import IconBenefit from "./assets/svg/Benefit";
import {
  AccountBoxOutlined,
  AddchartOutlined,
  AutorenewOutlined,
  HelpOutlined,
  HomeOutlined
} from "@mui/icons-material";

const SalesRoutes = [
  {
    path: "/",
    exact: true,
    component: Login
  },
  {
    path: "/resetPassword",
    component: ResetPassword
  },
  {
    path: "/maintenance",
    component: MaintenancePage
  },
  {
    path: "/auth",
    component: Auth,
    routes: [
      {
        path: "/auth/dashboard",
        name: "Dashboard",
        component: HomePage,
        menu: {
          show: true,
          icon: HomeOutlined
        }
      },
      {
        path: "/auth/sp",
        name: "Sales Portal",
        exact: true,
        component: ProductInfo,
        menu: {
          show: true,
          icon: AddchartOutlined
        }
      },
      {
        path: "/auth/sp/productInfo",
        name: "Information",
        exact: true,
        component: ProductInfo,
        access: Enums.SCOPE_TYPE.PRODUCT_INFO_R,
        menu: {
          show: true,
          isNested: true,
          parentName: "Sales Portal"
        }
      },
      {
        path: "/auth/sp/newQuote",
        name: "New Quote",
        exact: true,
        customComponent: "NewQuote",
        access: Enums.SCOPE_TYPE.QUOTE_W,
        menu: {
          show: true,
          isNested: true,
          parentName: "Sales Portal"
        }
      },
      {
        path: "/auth/sp/currentQuote",
        name: "Current Quote",
        exact: true,
        component: CurrentQuote,
        access: Enums.SCOPE_TYPE.QUOTE_W,
        menu: {
          show: true,
          isNested: true,
          parentName: "Sales Portal"
        }
      },
      {
        path: "/auth/sp/savedQuotes",
        name: "Saved Quotes",
        exact: true,
        access: Enums.SCOPE_TYPE.QUOTE_R,
        component: SavedQuotes,
        menu: {
          show: true,
          isNested: true,
          parentName: "Sales Portal"
        }
      },
      {
        path: "/auth/sp/Documents",
        name: "Documents",
        exact: true,
        component: SalesDocuments,
        access: Enums.SCOPE_TYPE.DOCUMENT_SALE_R,
        menu: {
          show: true,
          isNested: true,
          parentName: "Sales Portal"
        }
      },
      {
        path: "/auth/sp/policyRenewal",
        name: "Policy Renewal",
        exact: true,
        component: Renewal,
        access: Enums.SCOPE_TYPE.QUOTE_W,
        menu: {
          show: true,
          isNested: true,
          parentName: "Sales Portal"
        }
      },
      {
        path: "/auth/sp/tutorial",
        name: "Tutorial",
        exact: true,
        component: Tutorial,
        access: Enums.SCOPE_TYPE.TUTORIAL_R,
        menu: {
          show: false,
          icon: HelpOutlined,
          isNested: true,
          parentName: "Sales Portal"
        }
      },
      {
        path: "/auth/pi",
        name: "Client & Policy Information",
        exact: true,
        component: PolicyInformation,
        menu: {
          show: true,
          icon: AccountBoxOutlined
        }
      },
      {
        path: "/auth/pi/dashboard",
        name: "Dashboard",
        exact: true,
        component: PolicyDashboard,
        menu: {
          show: false
        }
      },
      {
        path: "/auth/pi/memberListing",
        name: "My Employees",
        component: PolicyMyEmployees,
        menu: {
          show: false
        }
      },
      {
        path: "/auth/pi/insuranceCoverage",
        name: "Insurance Coverage",
        component: PolicyInsuranceCoverage,
        menu: {
          show: false
        }
      },
      {
        path: "/auth/pi/documents",
        name: "Documents",
        component: PolicyDocuments,
        menu: {
          show: false
        }
      },
      {
        path: "/auth/pi/claimExpenses",
        name: "Claim Expenses",
        component: PolicyClaimExpenses,
        menu: {
          show: false
        }
      }
    ]
  },
  {
    component: NotFound
  }
];

const HRRoutes = [
  {
    path: "/",
    exact: true,
    component: Login
  },
  {
    path: "/TermsAndConditions",
    exact: true,
    component: TncPage
  },
  {
    path: "/maintenance",
    component: MaintenancePage
  },
  {
    path: "/resetPassword",
    component: ResetPassword
  },
  {
    path: "/auth",
    component: Auth,
    routes: [
      {
        path: "/auth/claimAndPolicyListing",
        name: "Client and Policy Listing",
        component: HRClaimAndPolicyListing,
        access: Enums.SCOPE_TYPE.DASHBOARD_HR_R,
        menu: {
          show: true,
          svg: IconDashboard
        },
        routes: [
          {
            path: "/auth/claimAndPolicyListing",
            name: "Client and Policy Listing",
            component: HRClaimAndPolicyListingListCompanyHR,
            access: Enums.SCOPE_TYPE.DASHBOARD_HR_R,
            exact: true
          },
          {
            path: "/auth/claimAndPolicyListing/detail",
            name: "Dashboard",
            component: HRClaimAndPolicyListingDashboard,
            access: Enums.SCOPE_TYPE.DASHBOARD_HR_R
          }
        ]
      },
      {
        path: "/auth/insuranceCoverage",
        name: "Insurance Coverage",
        component: HRInsuranceCoverage,
        access: Enums.SCOPE_TYPE.INS_COV_HR_R,
        menu: {
          show: true,
          svg: IconInsuranceCoverage
        },
        routes: [
          {
            path: "/auth/insuranceCoverage",
            component: HRInsuranceCoverageListCompanyHR,
            name: "Insurance Coverage",
            access: Enums.SCOPE_TYPE.INS_COV_HR_R,
            exact: true
          },
          {
            path: "/auth/insuranceCoverage/detail",
            name: "Insurance Coverage Detail",
            component: HRCoverageDisplay,
            access: Enums.SCOPE_TYPE.INS_COV_HR_R
          }
        ]
      },
      {
        path: "/auth/myEmployees",
        name: "My Employees",
        component: HRMyEmployees,
        access: Enums.SCOPE_TYPE.EMP_HR_R,
        menu: {
          show: true,
          svg: IconMyEmployees
        },
        routes: [
          {
            path: "/auth/myEmployees",
            component: HRMyEmployeesListCompanyHR,
            name: "My Employees",
            access: Enums.SCOPE_TYPE.EMP_HR_R,
            exact: true
          },
          {
            path: "/auth/myEmployees/detail",
            component: HRMyEmployeesList,
            name: "My Employees List",
            access: Enums.SCOPE_TYPE.EMP_HR_R
          }
        ]
      },
      {
        path: "/auth/claimExpenses",
        name: "Claim Expenses",
        component: HRClaimExpenses,
        access: Enums.SCOPE_TYPE.CLAIM_EXP_HR_R,
        menu: {
          show: true,
          svg: IconClaimExpenses
        },
        routes: [
          {
            path: "/auth/claimExpenses",
            name: "Claim Expenses",
            component: HRClaimExpensesListCompanyHR,
            access: Enums.SCOPE_TYPE.CLAIM_EXP_HR_R,
            exact: true
          },
          {
            path: "/auth/claimExpenses/detail",
            name: "Claim Expenses Detail",
            component: HRClaimExpensesInfo,
            access: Enums.SCOPE_TYPE.CLAIM_EXP_HR_R
          }
        ]
      },
      {
        path: "/auth/employeeBenefit",
        name: "Benefits",
        component: EmployeeBenefit,
        access: Enums.SCOPE_TYPE.EMP_BENEFIT_HR_R,
        menu: {
          show: true,
          validate: {
            employeeBenefit: true
          },
          svg: IconBenefit
        },
        routes: [
          {
            path: "/auth/employeeBenefit",
            name: "Benefits",
            exact: true,
            component: EmployeeBenefitMain,
            access: Enums.SCOPE_TYPE.EMP_BENEFIT_HR_R
          },
          {
            path: "/auth/employeeBenefit/details/:type",
            name: "Benefit Detail",
            component: BenefitDetail,
            access: Enums.SCOPE_TYPE.EMP_BENEFIT_HR_R
          }
        ]
      },
      {
        path: "/auth/documents",
        name: "Documents",
        component: HRDocuments,
        access: Enums.SCOPE_TYPE.DOCUMENT_HR_R,
        menu: {
          show: true,
          svg: IconDocument
        },
        routes: [
          {
            path: "/auth/documents",
            name: "Documents",
            exact: true,
            component: HRDocumentsListCompanyHR,
            access: Enums.SCOPE_TYPE.DOCUMENT_HR_R
          },
          {
            path: "/auth/documents/detail",
            name: "Documents Detail",
            component: HRDocumentsDisplay,
            access: Enums.SCOPE_TYPE.DOCUMENT_HR_R
          }
        ]
      }
    ]
  },
  {
    component: NotFound
  }
];

export default { SalesRoutes, HRRoutes };
