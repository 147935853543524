import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Grid } from "@mui/material";
import UseFullDocument from "./UseFullDocument";
import UploadHistory from "./UploadHistory";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { coverageActions } from "../../../../actions";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";

function MyDocuments(props) {
  const [value, setValue] = useState("1");
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();
  const { policyNumber, companyId, productCode } = props.location.state || {};

  const url = window.location.pathname;
  useEffect(() => {
    if (url.substring(url.lastIndexOf("/") + 1) === "uploadHistory") {
      setValue("2");
    } else {
      setValue("1");
    }
  }, []);

  useEffect(() => {
    if ((policyNumber, companyId)) {
      dispatch(coverageActions.getPolicy(policyNumber, companyId));
    } else {
      dispatch(coverageActions.getPolicy());
    }
  }, [policyNumber, companyId]);

  const isProductCodeValid = () => {
    if (productCode) {
      if (productCode === "GCP" || productCode === "PCE") return true;
      return false;
    }
    if (props.HR.clientsAndPolicyListing) {
      const product = (props.HR.clientsAndPolicyListing.clients || []).find(
        client => client.product_code === "GCP" || client.product_code === "PCE"
      );
      if ((product && product.product_code === "GCP") || (product && product.product_code === "PCE")) {
        return true;
      }
    }
    return false;
  };

  return (
    <main>
      <div className={classes.root}>
        <Typography variant="h5" fontWeight={700} mb={3}>
          Documents
        </Typography>
        <Grid spacing={2} style={{ backgroundColor: "#fff" }}>
          <Grid item xs={12}>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="Useful Documents" value="1" sx={{ fontWeight: value === "1" ? "900" : "500" }} />
                    {isProductCodeValid() && (
                      <Tab label="Upload History" value="2" sx={{ fontWeight: value === "2" ? "900" : "500" }} />
                    )}
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <UseFullDocument />
                </TabPanel>
                {isProductCodeValid() && (
                  <TabPanel value="2">
                    <UploadHistory />
                  </TabPanel>
                )}
              </TabContext>
            </Box>
          </Grid>
        </Grid>
      </div>
    </main>
  );
}

const useStyles = makeStyles({
  root: {
    height: "100%",
    paddingTop: "40px",
    paddingLeft: "40px",
    paddingRight: "40px"
  }
});

export default withRouter(MyDocuments);
