import Enums from "./Enums.js";

const PolicyCompDisabledConfig = {
  PROD: {
    "00104427": [Enums.COMPONENT.MINI_LEFT_DRAWER_BENEFITS]
  },
  UAT: {
    "00104301": [Enums.COMPONENT.MINI_LEFT_DRAWER_BENEFITS]
  },
  SIT: {
    "00104301": [Enums.COMPONENT.MINI_LEFT_DRAWER_BENEFITS]
  },
  LOCAL: {
    "00104301": [Enums.COMPONENT.MINI_LEFT_DRAWER_BENEFITS]
  }
};

export default PolicyCompDisabledConfig;
