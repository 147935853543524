import Enums from "../../../../../utils/Enums";

const GOGPBenefits = {
  headers: [
    {
      title: ``,
      mandatory: true,
      attributes: {
        style: {
          width: 10
        }
      }
    },
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "40%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>1</strong>`
        },
        {
          data: `<strong>Panel General Practitioner</strong>
                <br>Benefit
                <br>Number of visits per year
                `,
          info: `via E-Card`
        },
        {
          data: `Cashless
                <br>Unlimited
                <br>Unlimited
                `
        },
        {
          data: `Cashless
                <br>Unlimited
                <br>Unlimited
                `
        },
        {
          data: `Cashless
                <br>Unlimited
                <br>Unlimited
                `
        },
        {
          data: `Cashless
                <br>Unlimited
                <br>Unlimited
                `
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>2</strong>`
        },
        {
          data: `<strong>Non-Panel General Practitioner & Paediatricians</strong>
                <br><i>(Covers treatment within Singapore only)</i>
                `,
          info: `On reimbursement basis`
        },
        {
          data: `Up to $50 per visit`
        },
        {
          data: `Up to $50 per visit`
        },
        {
          data: `Up to $80 per visit`
        },
        {
          data: `Up to $50 per visit`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>3</strong>`
        },
        {
          data: `<strong>Government Polyclinics</strong>
                <br>Benefit
                <br>Number of visits per year
                `,
          info: `On reimbursement basis`
        },
        {
          data: `
                <br>Unlimited 
                <br>Unlimited
                `
        },
        {
          data: `
                <br>Unlimited 
                <br>Unlimited
                `
        },
        {
          data: `
                <br>Unlimited 
                <br>Unlimited
                `
        },
        {
          data: `
                <br>Unlimited 
                <br>Unlimited
                `
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>4</strong>`
        },
        {
          data: `<strong>Emergency Coverage</strong>
                <br>Visits to Hospitals A&E
                `,
          info: `On reimbursement basis`
        },
        {
          data: `Up to $150 per visit`
        },
        {
          data: `Up to $150 per visit`
        },
        {
          data: `Up to $150 per visit`
        },
        {
          data: `Up to $150 per visit`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>5</strong>`
        },
        {
          data: `<strong>Overseas Outpatient Treatment</strong>`,
          info: `On reimbursement basis`
        },
        {
          data: `Up to $150 per visit`
        },
        {
          data: `Up to $150 per visit`
        },
        {
          data: `Up to $150 per visit`
        },
        {
          data: `Up to $150 per visit`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>6</strong>`
        },
        {
          data: `<strong>Visits to Traditional Chinese Medicine Practitioner</strong>
                <br><i>(capped at 10 visits per policy year)</i>
                `,
          info: `On reimbursement basis`
        },
        {
          data: `Up to $50 per visit`
        },
        {
          data: `Up to $50 per visit`
        },
        {
          data: `Up to $80 per visit`
        },
        {
          data: `Up to $50 per visit`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>7</strong>`
        },
        {
          data: `<strong>Co-Insurance</strong>`
        },
        {
          data: `NIL`
        },
        {
          data: `$5 per visit`
        },
        {
          data: `NIL`
        },
        {
          data: `$5 per visit`
        }
      ]
    }
  ],
  footnotes: []
};

let GOGPPremium = {
  headers: [
    {
      title: `Plan Type`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    },
    {
      title: `Plan 6`,
      tier: 6
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Annual Premium</strong>`
        },
        {
          data: `S$ 304.00`
        },
        {
          data: `S$ 267.00`
        },
        {
          data: `S$ 234.00`
        },
        {
          data: `S$ 256.00`
        },
        {
          data: `S$ 219.00`
        },
        {
          data: `S$ 186.00`
        }
      ]
    }
  ],
  footnotes: []
};

//   ----------------------------------------------------------------------
const GP = {
  id: 1,
  code: "GPRU",
  label: "Group Outpatient General Practitioner (GPRU)",
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GOGPBenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium (inclusive of 7% GST)",
      data: [GOGPPremium]
    }
  ]
};

export default GP;
