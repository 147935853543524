const CIAM_RESPONSE_CODE = {
  CIAM_KEYWORD: "_FR_CIAM_CODE_",
  NOT_AUTHENTICATED: "Not authenticated",
  UNAUTHORIZED: "unauthorized",
  GENERAL_MESSAGE: `Username or password incorrect. For a forgotten password, click "Forgot Password" to reset. If you use the common username for HR, Employee, or enrollment accounts, the password is same for all.`,
  GENERAL_MESSAGE_SALES: `The username or password you have entered is incorrect. If you have forgotten your password, please reset your password at <a target="_blank" href="https://pse.prudential.com.sg">https://pse.prudential.com.sg</a>`,

  INVALID_OTP: {
    code: "FR309",
    description: "Invalid OTP. Please enter the correct OTP."
  },
  MAX_OTP_FAILED: {
    code: "FR100",
    description:
      "Your account has been locked for 15mins due to incorrect OTP" +
      "\n" +
      "after 10 attempts. Please try to log in again later.",
    description_reset_password:
      "Your account has been locked for 15mins due to incorrect OTP after 10 attempts. Please try to submit your request again later."
  },
  OTP_EXPIRED: {
    code: "FR313",
    code_old: "FR105",
    description: "The OTP you have entered has expired." + "\n" + "Please log in again.",
    description_reset_password:
      "The OTP you have entered has expired." + "\n " + "Please submit your request again for new OTP."
  },
  RESETPW_OTP_EXPIRED: {
    code: "PR105",
    description: "The OTP you have entered has expired." + "\n" + "Please cancel and reset again "
  },
  INCORRECT_PW: {
    code: "FR302",
    description: "Incorrect password. Please enter the correct Password."
  },
  ACCOUNT_BLOCK: {
    code: "FR109",
    description:
      "Your account has been locked for 15mins as you have exceeded the maximum attempts. Please try to log in again later.",
    description_reset_password:
      "Your account has been locked for 15mins as you have exceeded the maximum attempts. Please try to submit your request again later."
  },
  FORGEROCK_ERROR: {
    code: "FR999",
    description: "Unauthorized try to log in or reset password",
    description_otp: "OTP verification failed. Please log in again" + "\n" + "to request a new OTP.",
    description_reset_password: "OTP verification failed. Please submit your request again for new OTP."
  },
  A_SUSPEND_INACTIVE: {
    pw_expired: "FR312",
    in_active_code: "FR110",
    suspend_code: "HSM03",
    description: `The password you entered has expired. Please click on “Forgot password” below to set a new password.`,
    description_sale: `The password you entered has expired. please reset your password at <a target="_blank" href="https://pse.prudential.com.sg">https://pse.prudential.com.sg</a>`
  },
  NOT_MATCH_PASSWORD_POLICY: {
    code: "FR102",
    description_sales_login: `Your password does not meet the password policy. Please reset your password at <a target="_blank" href="https://pse.prudential.com.sg">https://pse.prudential.com.sg</a>`,
    description_nsales_login: `Your password does not meet the password policy. Please click on "Forgot Password" below to set a new password`,
    description:
      "Unable to update the password as your password does not meet our password policy. Please follow the below criteria to set your new password."
  },
  ACCOUNT_LOCKED_FOR_WRONG_OTP_RESET_PWD: {
    code: "PR109",
    description: "Your account has been blocked for 15 minutes due to incorrect OTP. Please try again later."
  },
  EXCEEDED_OTP_REGENERATION: {
    code: "FR106",
    description: "You have reached maximum OTP resend, please retry again."
  }
};

export default Object.freeze(CIAM_RESPONSE_CODE);
