import * as actionTypes from "./types";

import renewalService from "../services/renewal.service";

export const renewalActions = {
  getRenewPolicyList,
  getRenewSubmissionList,
  getRenewPolicies,
  putRenewalPolicy,
  createRenewal,
  setRenewal,
  setRenewalType,
  setHasHrDecChanged,
  setRenewalId,
  setRenewalStatus,
  updateIsRenewalNotificationOpen
};

function getRenewPolicyList() {
  return dispatch => {
    return dispatch({
      type: actionTypes.RENEW_GET_LIST,
      payload: renewalService.getRenewPolicyList()
    });
  };
}

function getRenewSubmissionList() {
  return dispatch => {
    return dispatch({
      type: actionTypes.RENEW_GET_SUBMISSION_LIST,
      payload: renewalService.getRenewSubmissionList()
    });
  };
}

function getRenewPolicies() {
  return dispatch => {
    return dispatch({
      type: actionTypes.GET_RENEW_POLICIES,
      payload: renewalService.getRenewPolicies()
    });
  };
}

function putRenewalPolicy(policyId, details) {
  return {
    type: actionTypes.SAVE_RENEW_POLICY,
    payload: renewalService.putRenewalPolicy(policyId, details)
  };
}

function createRenewal(details) {
  return {
    type: actionTypes.CREATE_RENEW_POLICY,

    payload: renewalService.postCreateRenewal(details)
  };
}

function setRenewal(details) {
  return {
    type: actionTypes.SET_RENEW_POLICY,
    payload: details
  };
}

function setRenewalType(data) {
  return {
    type: actionTypes.SET_RENEW_POLICY_TYPE,
    payload: data
  };
}

function setHasHrDecChanged(data) {
  return {
    type: actionTypes.SET_RENEW_HAS_HR_DEC_CHANGED,
    payload: data
  };
}

function setRenewalId(data) {
  return {
    type: actionTypes.SET_RENEW_ID,
    payload: data
  };
}

function setRenewalStatus(data) {
  return {
    type: actionTypes.SET_RENEW_STATUS,
    payload: data
  };
}

function updateIsRenewalNotificationOpen(data) {
  return {
    type: actionTypes.UPDATE_IS_RENEWAL_NOTIFICATION_OPEN
  };
}
