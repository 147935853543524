import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import moment from "moment";

class PolicyDetails extends Component {
  formatDate = date => {
    return moment(date).format("DD MMM YYYY");
  };

  render() {
    const { classes, policy } = this.props;

    const policyDetails = [
      { label: "Company Name", value: policy.companyName ? policy.companyName : "-" },
      { label: "Policy Number", value: policy.policyNo ? policy.policyNo : "-" },
      {
        label: "Policy Period",
        value: `${
          policy.policyStartDate && policy.policyEndDate
            ? `${this.formatDate(policy.policyStartDate)} to ${this.formatDate(policy.policyEndDate)}`
            : "-"
        }`
      },
      { label: "Policy Status", value: policy.policyStatus ? policy.policyStatus.toLowerCase() : "-" },
      {
        label: "Policy Coverage",
        value: policy.policyCoverage && policy.policyCoverage.length > 0 ? policy.policyCoverage.join(", ") : "-"
      }
    ];

    return (
      <Grid container spacing={0} className={classes.insuranceOverView}>
        <Grid item xs={12}>
          <div className={classes.container}>
            <div className={classes.contentWrapper}>
              <Typography component="p" className={classes.description}>
                Browse the sections below to find more information on {policy.companyName}
              </Typography>
              {policyDetails.map((item, index) => (
                <div key={index} className={classes.rowWrapper}>
                  <Typography component="p" className={classes.label}>
                    {item.label}
                  </Typography>
                  <Typography component="p">
                    <span className={item.label === "Policy Coverage" ? classes.labelInfo : classes.labelInfoStatus}>
                      {item.value}
                    </span>
                    {item.label === "Policy Coverage" && (
                      <Typography
                        onClick={() => this.props.goToPage("insuranceCoverage")}
                        component="span"
                        className={classes.viewCoverage}
                      >
                        View Coverage
                      </Typography>
                    )}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  container: {
    backgroundColor: "white",
    marginLeft: "10px",
    marginBottom: "5px"
  },
  insuranceOverView: {
    marginTop: 20
  },
  description: {
    fontWeight: "bold",
    paddingBottom: "25px"
  },
  contentWrapper: {
    padding: "20px",
    display: "flex",
    flexDirection: "column"
  },
  rowWrapper: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "15px"
  },
  label: {
    fontWeight: "bold",
    width: "30%",
    display: "flex"
  },
  "@media (max-width: 600px)": {
    label: {
      width: "50%"
    }
  },
  labelInfo: {
    marginLeft: "0px"
  },
  labelInfoStatus: {
    marginLeft: "0px",
    textTransform: "capitalize"
  },
  viewCoverage: {
    color: "red",
    fontWeight: "bold",
    paddingLeft: "20px",
    cursor: "pointer"
  }
});

export default withStyles(styles, { withTheme: true })(PolicyDetails);
