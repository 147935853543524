import { Backdrop, Breadcrumbs, Button, CircularProgress, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import DownloadIcon from "@mui/icons-material/Download";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link } from "react-router-dom";
import classNames from "classnames";
import SnackBar from "./SnackBar";
import { uploadDocumentActions } from "../../../../actions/HR/uploaddocument.action";
import { useDispatch, useSelector } from "react-redux";
import { UploadDocumentService } from "../../../../services/HR/uploadDocument.service";
import { makeStyles } from "@mui/styles";
import ConfirmMessageDialog from "../../../../components/ConfirmMessageDialog";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useTheme } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import AlertTopBar from "../../../../components/AlertTopBar";
import en from "../../../../locales/en.json";

function UploadEmployee(props) {
  const { showEmployeeTable, navDrawerOpen, renewable_status } = props;
  const classes = useStyles();
  const steps = ["Add Employees", "Submit"];
  const [activeStep, setActiveStep] = useState(0);
  const [fileAttached, setFileAttached] = useState({ attached: false, fileName: "", filePath: "", file: [] });
  const [showMessage, setShowMessage] = useState({ status: false, message: "", type: "success" });
  const [showLoader, setShowLoader] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const selector = useSelector(state => state);
  const dispatch = useDispatch();

  const onDropAccepted = files => {
    setDisableSubmit(true);
    setShowConfirmDialog(true);

    files.forEach(file => {
      setFileAttached({
        attached: true,
        fileName: file.name,
        filePath: file.path,
        file: file
      });
    });
  };

  const onDropRejected = files => {
    showMessageInAlert();
  };

  const showAlert = (message, type) => {
    setShowLoader(false);
    setShowMessage({ status: true, message: message, type: type });
    setTimeout(() => {
      setShowMessage({ status: false, message: "", type: "success" });
    }, 5000);
  };
  const saveEmployees = () => {
    setShowLoader(true);
    const { policyNumber, companyId } = props.location.state || {};
    UploadDocumentService.uploadEmployeeDocument(policyNumber, companyId, fileAttached.file)
      .then(response => {
        showAlert("Uploaded Sucessfully", "success");
        setActiveStep(1);
      })
      .catch(error => {
        showAlert(error.message, "error");
      });
  };

  const cancelUpload = () => {
    setDisableSubmit(true);
    setFileAttached({ attached: false, fileName: "", filePath: "", file: [] });
  };

  const downloadTemplate = () => {
    uploadDocumentActions.downloadTemplate();
  };

  const theme = useTheme();

  return (
    <div>
      <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={showLoader}>
        <CircularProgress color="inherit" style={{ marginRight: "10px" }} /> Uploading
      </Backdrop>

      <Stepper activeStep={activeStep} alternativeLabel nonLinear className={classes.stepper}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>
              <div
                style={{
                  color: activeStep === index ? "#ED1C24" : "inherit",
                  fontWeight: activeStep === index ? "bold" : "inherit"
                }}
              >
                {label}
              </div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <div className={classes.breadCrumbs}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="4px" />} marginRight="0px" aria-label="breadcrumb">
          <Link
            className={classes.noTextDecorationLink}
            underline="hover"
            color="inherit"
            to={{ pathname: "/auth/claimAndPolicyListing" }}
          >
            PRUworks
          </Link>
          <div onClick={showEmployeeTable} underline="hover" color="inherit" className={classes.noTextDecorationLink}>
            My Employees
          </div>
          <Typography color="#000" fontWeight="400" fontSize="13px">
            {activeStep === 0 ? "Add Employees" : "Submit"}
          </Typography>
        </Breadcrumbs>
      </div>
      <div className={classes.addEmployeeTitle}>
        <Typography fontWeight="600" fontSize="20px">
          {activeStep === 0 ? "Add Employees" : "File was successfully submitted"}
        </Typography>
      </div>

      {renewable_status && (
        <div className={classes.boxHeader}>
          <AlertTopBar type="tip" title={"Note:"} listDescription={[en["hr.myemployee.tips.warning.basic4"]]} />
        </div>
      )}

      {showMessage.status && <SnackBar state="true" message={showMessage.message} type={showMessage.type} />}
      <Box sx={{ width: "100%" }}>
        <div className={classes.root}>
          {activeStep === 0 && (
            <div>
              <Typography className={classes.fileUploadText}>File Upload</Typography>
              <Dropzone
                disabled={renewable_status}
                onDropAccepted={onDropAccepted}
                onDropRejected={onDropRejected}
                multiple={false}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              >
                {({ getRootProps, getInputProps }) => (
                  <div className={classes.dropZone} {...getRootProps()}>
                    <input {...getInputProps()} />

                    <Typography style={{ fontSize: "12px" }}>
                      Drag or upload your <span style={{ fontWeight: "bold" }}>.XLS or XLSX</span> document here. You
                      may only upload 1 file at a time.
                    </Typography>

                    <div style={{ marginTop: "20px", marginBottom: "30px" }}>
                      {fileAttached.attached && (
                        <span className={classes.contentCenter}>
                          {" "}
                          <CheckCircleIcon style={{ color: "green" }} />
                          <Typography sx={{ paddingRight: "8px", paddingLeft: "8px" }}>
                            Successfully Uploaded:{" "}
                          </Typography>
                          <Typography fontWeight={"bold"} style={{ textDecoration: "underline" }}>
                            {fileAttached.fileName}
                          </Typography>
                        </span>
                      )}
                      <Button className={classes.uploadButton} disabled={renewable_status}>
                        Upload
                      </Button>
                      {/* {!fileAttached.attached && <Button className={classes.uploadButton}>Upload</Button>} */}
                    </div>
                  </div>
                )}
              </Dropzone>

              <div className={classes.downloadContainer}>
                <div>
                  <div> Download Template</div>
                  <div className={classes.templateDownloadLink}>
                    {" "}
                    <a onClick={downloadTemplate} download>
                      GCP Member Movement Template.xls
                    </a>
                  </div>
                </div>
                <div>
                  <div> Download </div>
                  <div>
                    {" "}
                    <a onClick={downloadTemplate} download>
                      <DownloadIcon className={classes.templateDownloadIcon} />
                    </a>
                  </div>
                </div>
              </div>

              <div className={classNames(classes.footer, navDrawerOpen && classes.menuShow)}>
                <div className={classes.footerContent}>
                  <Button
                    disabled={!fileAttached.attached}
                    onClick={cancelUpload}
                    className={classes.footerCancelButton}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={disableSubmit}
                    variant="contained"
                    color="primary"
                    className={classes.saveEmployeeButton}
                    onClick={saveEmployees}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          )}

          {activeStep === 1 && (
            <div>
              <div style={{ fontWeight: 600 }}>
                Thank you for your submission. {fileAttached.fileName} has been uploaded sucessfully.
                <br />
                You may view your Upload History under Documents. Please print a copy of this page for future reference.
              </div>
              <br /> You may visit the following pages:
              <ul>
                <li>
                  {" "}
                  <Link className={classes.redFont} to={{ pathname: "/auth/Documents/usefullDocuments" }}>
                    Documents
                  </Link>
                </li>
                <li>
                  <Link className={classes.redFont} to={{ pathname: "/auth/Documents/uploadHistory" }}>
                    Upload History
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </div>
      </Box>
      {activeStep == 0 && (
        <div>
          <p>Note: To ensure prompt processing, please ensure the following information are provided in your file.</p>
          <ol>
            <li>Name of employee / dependant</li>
            <li>NRIC / Passport No of employee / dependant</li>
            <li>Gender of employee / dependant</li>
            <li>Marital status of employee / dependant</li>
            <li>Date of Birth</li>
            <li>Plan No</li>
            <li>Date of employment</li>
            <li>Effective / Termination Date</li>
            <li>Email address</li>
          </ol>
        </div>
      )}
      <ConfirmMessageDialog
        name="confirm-employee"
        title="Submit Changes"
        description="By submitting the information of the insured members and/or their dependents to Prudential herein I hereby acknowledge and confirms that the information is true, complete and accurate, and shall form the basis of my/our contract with Prudential. I/We further acknowledge and agree that Prudential can request supporting documents in relation to the information provided above.~Further I acknowledge and confirm that I have obtained the necessary consent from the insured members and/or their dependents to collect, use, disclose and process such information to Prudential. I/We agree to indemnify Prudential against any losses, damages, costs or liabilities arising from any false, incomplete or inaccurate information, or any breach of the consent requirement."
        open={showConfirmDialog}
        closeHandler={() => {
          setShowConfirmDialog(false);
          cancelUpload();
        }}
        confirmHandler={() => {
          setShowConfirmDialog(false);
          setDisableSubmit(false);
        }}
        confirmButtonText="Submit"
        closeButtonText="Cancel"
        isCheckbox={true}
        checkboxLabel="I confirm that the information provided is accurate and that I have obtained necessary authorization from the insured."
      />
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  redFont: { color: "#ED1C24" },
  noTextDecorationLink: { textDecoration: "none", color: "#838383", fontSize: "13px", cursor: "pointer" },
  // noTextDecorationLink: { textDecoration: "none", color: "#ed1c24", fontSize: '13px' },
  stepper: { borderBottom: "1px solid #d3d3d3", paddingBottom: "15px" },
  breadCrumbs: { marginBottom: "10px", marginTop: "20px" },
  root: { backgroundColor: "#fff", padding: "10px", borderRadius: "5px" },
  addEmployeeTitle: { paddingTop: "15px", paddingBottom: "20px" },
  dropZone: {
    padding: "50px",
    textAlign: "center",
    border: "5px dotted",
    padding: "10px",
    borderStyle: "dashed",
    borderWidth: "2px",
    borderRadius: "5px",
    borderColor: "#d3d3d3"
  },
  fileUploadText: { fontWeight: "bold", paddingBottom: "20px", paddingTop: "15px", fontSize: "17px" },
  contentCenter: { display: "flex", justifyContent: "center", paddingBottom: "15px" },
  downloadContainer: { display: "flex", justifyContent: "space-between", fontSize: "12px", marginTop: "20px" },
  templateDownloadLink: { fontWeight: 600, color: "#838383", textDecoration: "underline", cursor: "pointer" },
  templateDownloadIcon: { color: "#838383", cursor: "pointer" },
  uploadButton: { minWidth: 150, border: `1px solid #ED1C24` },
  footer: {
    height: 60,
    backgroundColor: "white",
    position: "fixed",
    right: 0,
    bottom: 0,
    left: theme.miniLeftDrawer.widthClosed,
    borderTop: "1px solid rgba(0, 0, 0, 0.12)"
  },
  menuShow: {
    left: theme.miniLeftDrawer.width
  },
  footerContent: { display: "flex", justifyContent: "space-between", padding: "0px 5px 0px 5px" },
  footerCancelButton: { minWidth: 150, marginTop: "10px", border: `1px solid #ED1C24` },
  saveEmployeeButton: { minWidth: 150, marginTop: "10px" },
  boxHeader: {
    marginBottom: "20px"
  }
}));

export default withRouter(UploadEmployee);
