import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withStyles } from "@mui/styles";
import { injectIntl } from "react-intl";
import Typography from "@mui/material/Typography";
import TableCompanyHR from "../../../../components/hr/company/TableCompanyHR";
import { coverageActions } from "../../../../actions";
import { bindActionCreators } from "redux";

class MyDocumentsListCompanyHR extends Component {
  constructor(props) {
    super(props);
  }

  goToPage = (company, policyNumber, companyId, productCode) => {
    this.props.history.push(`/auth/documents/detail`, { company, policyNumber, companyId, productCode });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography variant="h5" fontWeight={700}>
          Documents
        </Typography>
        <Typography mt={3}>
          View all your clients policy information via the <span className={classes.subText}>Policy Number</span> or{" "}
          <span className={classes.subText}>Company Name</span>
        </Typography>
        <TableCompanyHR goToPage={this.goToPage} clients={this.props.clients} />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    height: "100%",
    paddingTop: "40px",
    paddingLeft: "40px",
    paddingRight: "40px"
  },
  subText: {
    fontWeight: "bold"
  }
});

function mapStateToProps(state) {
  return {
    clients: state.HR.clientsAndPolicyListing.clients,
    products: state.products
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(coverageActions, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectIntl(withStyles(styles, { withTheme: true })(MyDocumentsListCompanyHR)))
);
