import * as actionTypes from "./types";

import quoteService from "../services/quote.service";

export const quotesActions = {
  list
};

function list() {
  return dispatch => {
    return dispatch({
      type: actionTypes.QUOTES_GET_LIST,
      payload: quoteService.list()
    });
  };
}
