import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withStyles } from "@mui/styles";
import { Button, Grid, Typography } from "@mui/material";
import SelectInput from "../../_HR/ClaimExpenses/common/SelectInput";
import ClaimDateInput from "./common/ClaimDateInput";
import Enums from "../../../../utils/Enums";

const MEMBER_TYPE_OPTIONS = [
  { label: "All", value: "ALL" },
  { label: "Employee", value: "EMPLOYEE" },
  { label: "Dependent", value: "DEPENDENT" }
];

const CLAIM_TYPE_OPTIONS = [
  { label: "All", value: "ALL" },
  { label: "Inpatient", value: Enums.CLAIM_TYPE.INPATIENT },
  { label: "Outpatient - General Practitioners (GP)", value: Enums.CLAIM_TYPE.OUTPATIENT_GP },
  { label: "Outpatient - Specialist", value: Enums.CLAIM_TYPE.OUTPATIENT_SP },
  { label: "Outpatient - Dental", value: Enums.CLAIM_TYPE.OUTPATIENT_DENTAL },
  { label: "Maternity", value: Enums.CLAIM_TYPE.MATERNITY },
  { label: "Outpatient", value: Enums.CLAIM_TYPE.OUTPATIENT }
];

const DATE_TYPE_OPTION = [
  { label: "Consultation Date", value: Enums.DATE_FILTER.CONSULTATION_DATE },
  { label: "Processed Date", value: Enums.DATE_FILTER.PROCESSED_DATE }
];
class PolicyClaimExpensePanel extends Component {
  render() {
    const {
      classes,
      handleChange,
      handleDateChange,
      handleSubmit,
      isProcessing,
      filter: { memberType, claimType, fromDate, toDate, dateType },
      handleDateError
    } = this.props;
    const memberTypeOptionList = MEMBER_TYPE_OPTIONS;
    const claimTypeAllList = CLAIM_TYPE_OPTIONS;
    const dateTypeList = DATE_TYPE_OPTION;

    return (
      <div className={classes.container}>
        <Typography component="p" className={classes.title}>
          Employee Utilization
        </Typography>
        <div className={classes.contentWrapper}>
          <form onSubmit={handleSubmit}>
            <Grid container className={classes.row} alignItems="center">
              <Grid item md={3}>
                <Typography className={classes.label}>Employee / Dependent</Typography>
              </Grid>
              <Grid item md={6}>
                <SelectInput
                  name={"memberType"}
                  value={memberType}
                  onChange={handleChange}
                  list={memberTypeOptionList}
                  className={classes.dropdown}
                />
              </Grid>
            </Grid>

            <Grid container className={classes.row} alignItems="center">
              <Grid item md={3}>
                <Typography className={classes.label}>Claim Type</Typography>
              </Grid>
              <Grid item md={6}>
                <SelectInput
                  name={"claimType"}
                  value={claimType}
                  onChange={handleChange}
                  list={claimTypeAllList}
                  className={classes.dropdown}
                />
              </Grid>
            </Grid>

            <Grid container className={classes.row} alignItems="center">
              <Grid item md={3} style={{ paddingRight: 100 }}>
                <SelectInput
                  name={"dateType"}
                  value={dateType}
                  onChange={handleChange}
                  list={dateTypeList}
                  className={classes.dropdowntitle}
                />
              </Grid>
              <Grid item md={6} style={{ display: "flex", alignItems: "center" }}>
                <span className={classes.subLabel}>From</span>
                <ClaimDateInput
                  name={"fromDate"}
                  value={fromDate}
                  onChange={handleDateChange("fromDate")}
                  onError={handleDateError("fromDate", "Consultation 'FromDate' is invalid")}
                  maxDateMessage="Consultation Date cannot be in the future"
                  className={classes.dateInput}
                />
                <span className={classes.subLabel}>To</span>
                <ClaimDateInput
                  name={"toDate"}
                  value={toDate}
                  onChange={handleDateChange("toDate")}
                  onError={handleDateError("toDate", "Consultation 'ToDate' is invalid")}
                  maxDateMessage="Consultation Date cannot be in the future"
                  className={classes.dateInput}
                />
              </Grid>
            </Grid>

            <Grid container className={classes.row} alignItems="center">
              <Grid item md={4} />
              <Grid item md={6}>
                <span className={classes.subTip}>*Leave date field(s) as blank for search with no date boundaries</span>
              </Grid>
            </Grid>

            <Grid container className={classes.row} alignItems="center">
              <Grid item md={11} />
              <Grid item md={1}>
                <Button variant="contained" color="primary" type="submit" disabled={isProcessing}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    backgroundColor: "white",
    marginLeft: "10px",
    marginBottom: "5px",
    marginTop: "25px",
    borderRadius: "5px"
  },
  title: {
    backgroundColor: "#ED1B24",
    color: "white",
    fontSize: 18,
    padding: 10,
    marginBottom: 10,
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px"
  },
  rowWrapper: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "15px"
  },
  row: {
    marginBottom: "25px",
    paddingLeft: 5
  },
  contentWrapper: {
    padding: "20px",
    display: "flex",
    flexDirection: "column"
  },
  label: {
    fontWeight: "bold",
    display: "flex"
  },
  "@media (max-width: 600px)": {
    label: {
      width: "50%"
    }
  },
  dropdown: {
    paddingLeft: 3
  },
  subLabel: {
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 15,
    color: "#333333",
    fontWeight: "bold"
  },
  dateInput: {
    width: "100%"
  },
  dropdowntitle: {
    paddingLeft: 3,
    fontWeight: "bold"
  }
});

function mapStateToProps(state) {
  return {
    policy: state.policyDetails.policy
  };
}

export default connect(mapStateToProps)(injectIntl(withStyles(styles, { withTheme: true })(PolicyClaimExpensePanel)));
