import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import moment from "moment";

import { withStyles } from "@mui/styles";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import { DateRange as DateRangeIcon } from "@mui/icons-material";
import { quoteActions, employeeActions } from "../../../../../../actions";
import Util from "../../../../../../utils/Util";
import InfoPopover from "../../../../../../components/InfoPopover";
import waitingPeriods from "../../../../../../assets/data/waitingPeriods";
import InfoButton from "../../../../../../components/InfoButton";

class TableToolBarHeader extends Component {
  constructor(props) {
    super(props);

    this.waitingPeriods = _.range(waitingPeriods + 1).map(i => ({
      value: i,
      label: props.intl.formatMessage({ id: "policy.month" }, { monthCount: i })
    }));

    this.state = {
      waiting_period: null,
      infoPopover: {
        open: false,
        element: null,
        data: ""
      },
      waitingPeriodNotice: false,
      noticePeriodSelectionError: false
    };
  }

  componentDidUpdate(prevProps) {
    const allForeignWorkersY = Util.foreignWorkerValidation(prevProps).allForeignWorkersY;
    if (this.state.waitingPeriodNotice != allForeignWorkersY) {
      this.setState({
        waitingPeriodNotice: allForeignWorkersY,
        noticePeriodSelectionError: Util.foreignWorkerValidation(prevProps).isInvalid
      });
    }
  }

  handleDateChange = date => {
    let activationDate = date ? date.format("DD/MM/YYYY") : null;
    this.props.setActivationDate(activationDate);
    this.props.validateEmployeeData();
  };

  renderActivationDate = () => {
    const activationDate = this.props.quote.req_effective_date;
    return activationDate ? moment(activationDate, "DD/MM/YYYY") : null;
  };

  handleWaitingPeriodChange = event => {
    let target = event.target;
    let value = target.value;
    this.props.setWaitingPeriod(value);
    this.setState({
      noticePeriodSelectionError: this.state.waitingPeriodNotice && value != 0
    });
  };

  handleClickInfoButton = (data, id) => event => {
    let infoPopover = { ...this.state.infoPopover };
    infoPopover.open = !infoPopover.open;
    if (infoPopover.open) {
      infoPopover.element = this.refs[id];
      infoPopover.data = data;
    }
    this.setState({ infoPopover });
  };

  renderInfoPopover = () => {
    const { infoPopover } = this.state;

    return (
      <InfoPopover
        open={infoPopover.open}
        data={infoPopover.data}
        element={infoPopover.element}
        handleClickInfoButton={this.handleClickInfoButton}
      />
    );
  };

  isActivationDateInPass = () => {
    let activationDate = this.props.quote.req_effective_date
      ? moment(this.props.quote.req_effective_date, "DD/MM/YYYY").toDate()
      : null;
    let today = new Date().setHours(0, 0, 0, 0);
    return activationDate < today;
  };

  render() {
    const { classes, employeeLength, readOnly, intl, quote, document } = this.props;

    let waiting_period = Util.isEmpty(quote.waiting_period) ? "" : quote.waiting_period;
    let contentInfoButton = (
      <InfoButton
        ref={`btnInfoWaitingPeriod`}
        id={`btnInfoWaitingPeriod`}
        data={"Period during which no insurance coverage shall be provided"}
        handleClickInfoButton={this.handleClickInfoButton}
      />
    );
    let contentInfoPopover = this.renderInfoPopover();

    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          {
            <Typography className={classes.employeeListInfo} variant="caption">
              {`${employeeLength} Employee${employeeLength > 1 ? "s" : ""}`}
            </Typography>
          }
        </div>

        <div className={classes.wrapper}>
          <div className={classes.waitingPeriodContainer}>
            {this.state.noticePeriodSelectionError && !document.hasSignedProposal ? (
              <FormHelperText error={true} className={classes.waitingPeriodHint}>
                Error can be resolved by either introducing employee with foreign worker with indicator = NO or correct
                the waiting period selection
              </FormHelperText>
            ) : (
              !Util.isEmpty(quote.waiting_period) && (
                <Typography className={classes.waitingPeriodHint} variant="caption">
                  {intl.formatMessage({ id: "employee.waitingPeriod.hint" })}
                </Typography>
              )
            )}
            <FormControl className={classes.waitingPeriodControl} disabled={readOnly || document.hasSignedProposal}>
              <InputLabel htmlFor="waiting-period" shrink={true} style={{ left: "-15px" }}>
                Set Waiting Period {contentInfoButton}
              </InputLabel>
              <Select
                name="waiting-period"
                value={waiting_period}
                variant="standard"
                onChange={this.handleWaitingPeriodChange}
                style={{ width: "100%" }}
                error={this.state.noticePeriodSelectionError}
              >
                {this.waitingPeriods.map((p, idx) => {
                  return (
                    <MenuItem key={idx} value={p.value}>
                      {p.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>

          <div className={classes.datepickerContainer} style={{ marginLeft: "5px" }}>
            <DatePicker
              key={"coverageDate"}
              className={classes.datepicker}
              minDateMessage={""}
              variant="standard"
              disablePast
              clearable
              disabled={readOnly || (document.hasSignedProposal && !this.isActivationDateInPass())}
              value={this.renderActivationDate()}
              label="Select Coverage Start Date"
              onChange={this.handleDateChange}
              format="DD-MMM-YYYY"
              animateYearScrolling={true}
              InputProps={{
                endAdornment: <DateRangeIcon className={classes.DateRangeIcon} />
              }}
            />
          </div>
        </div>
        {contentInfoPopover}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end"
  },
  wrapper: {
    display: "flex"
  },
  employeeListInfo: {
    fontWeight: "bold"
  },
  waitingPeriodContainer: {
    display: "flex",
    alignSelf: "flex-end",
    paddingRight: theme.spacing.unit
  },
  waitingPeriodHint: {
    maxWidth: "350px",
    alignSelf: "flex-end"
  },
  waitingPeriodControl: {
    width: "160px",
    alignSelf: "flex-end"
  },
  datepickerContainer: {
    alignSelf: "flex-end",
    minWidth: "200px"
  },
  datepicker: {
    width: "100%"
  },
  DateRangeIcon: {
    height: `${theme.spacing.unit * 2.5}px`,
    width: `${theme.spacing.unit * 2.5}px`,
    marginBottom: `${theme.spacing.unit * 0.5}px`
  }
});

function mapStateToProps(state, prop) {
  return {
    quote: state.quote,
    readOnly: state.quote.readOnly,
    document: state.document
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...quoteActions, ...employeeActions }, dispatch);
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(TableToolBarHeader))
);
