import Enums from "../../../../../utils/Enums";

// GHS ----------------------
let GHSBenefits = {
  headers: [
    {
      title: ``,
      mandatory: true,
      attributes: {
        style: {
          width: "5%"
        }
      }
    },
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "20%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    },
    {
      title: `Plan 6`,
      tier: 6
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `1`
        },
        {
          data: `<strong> Daily Room and Board Benefit</strong>`,
          info: `<em>(Per day, up to 120 days per disability)</em>`
        },
        {
          data: `1-Bed Ward Private`
        },
        {
          data: `2-Bed Ward Private`
        },
        {
          data: `4-Bed Ward Private`
        },
        {
          data: `1-Bed Ward Government Restructured`
        },
        {
          data: `4-Bed Ward Government Restructured`
        },
        {
          data: `1-Bed Ward Private `
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `2`
        },
        {
          data: `<strong>Intensive Care Unit (ICU)</strong>`,
          info: `<em>(Max. per day, up to 30 days per disability)</em>`
        },
        {
          data: `3 x 1-Bedded`
        },
        {
          data: `3 x 2-Bedded	`
        },
        {
          data: `3 x 4-Bedded`
        },
        {
          data: `S$20,000`,
          info: `per disability limit for items 2 to 8`,
          attributes: {
            rowspan: 7
          }
        },
        {
          data: `S$15,000`,
          info: `per disability limit for items 2 to 8`,
          attributes: {
            rowspan: 7
          }
        },
        {
          data: `As Charged up to S$250,000 per policy year`,
          attributes: {
            rowspan: 8
          }
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `3`
        },
        {
          data: `<strong>High Dependency Ward (HDW)</strong>`,
          info: `<em>(Max. per day, up to 30 days per disability)</em>`
        },
        {
          data: `2 x 1-Bedded`
        },
        {
          data: `2 x 2-Bedded	`
        },
        {
          data: `2 x 4-Bedded`,
          attributes: {
            notLast: true
          }
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `4`
        },
        {
          data: `<strong>Other Hospital Services Benefits</strong>`,
          info: `<em>(Max. per disability)</em>`
        },
        {
          data: `S$25,000`,
          info: `per disability<br> limit for items<br> 4 to 8`,
          attributes: {
            rowspan: "5"
          }
        },
        {
          data: `S$20,000`,
          info: `per disability<br> limit for items<br> 4 to 8`,
          attributes: {
            rowspan: "5"
          }
        },
        {
          data: `S$15,000`,
          info: `per disability<br> limit for items<br> 4 to 8`,
          attributes: {
            rowspan: "5"
          }
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `5`
        },
        {
          data: `<strong>Surgical Fees (< S$ 1,500 will not be subject to Surgical Schedule)</strong>`,
          info: `<em>(subject to surgical schedule)</em><br>
          i. Waiver of surgical schedule if insured member is admitted to government restructured hospital.<br>
          ii. Surgical fee more than S$1,500 is subject to surgical schedule if insured member is admitted to a private hospital.`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `6`
        },
        {
          data: `<strong>In-Hospital Doctor's Consultation Benefit</strong>`,
          info: `<em>(Max. 120 days)</em>`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `7`
        },
        {
          data: `<strong>Pre (90 days) & Post (90 days) Hospitalisation/ Surgery,  Specialist Consultation, Diagnostic X-Ray and Lab Test, Traditional Chinese Medicine</strong>`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `8`
        },
        {
          data: `<strong>Emergency Accidental Outpatient<br> Treatment Benefit</strong>`,
          info: `<em>(Including Accidental Dental Treatment)</em>`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `9`
        },
        {
          data: `<strong>Miscarriage Benefit</strong>`
        },
        {
          data: `Covered under benefits (items 1 to 8) , as per the respective benefit limits`
        },
        {
          data: `Covered under benefits (items 1 to 8) , as per the respective benefit limits`
        },
        {
          data: `Covered under benefits (items 1 to 8) , as per the respective benefit limits`
        },
        {
          data: `Covered under benefits (items 1 to 8) , as per the respective benefit limits`
        },
        {
          data: `Covered under benefits (items 1 to 8) , as per the respective benefit limits`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `10`
        },
        {
          data: `<strong>Outpatient Cancer Treatment</strong>`,
          info: `<em>(Max. per policy year)</em>`
        },
        {
          data: `S$ 12,000`
        },
        {
          data: `S$ 12,000`
        },
        {
          data: `S$ 12,000`
        },
        {
          data: `S$ 6,000`
        },
        {
          data: `S$ 6,000`
        },
        {
          data: `S$25,000 <br>
          per policy year`,
          info: `(<em>part of policy year limit</em>)`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `11`
        },
        {
          data: `<strong>Outpatient Kidney Dialysis</strong>`,
          info: `<em>(Max. per policy year)</em>`
        },
        {
          data: `S$ 12,000`
        },
        {
          data: `S$ 12,000`
        },
        {
          data: `S$ 12,000`
        },
        {
          data: `S$ 6,000`
        },
        {
          data: `S$ 6,000`
        },
        {
          data: `S$25,000 <br>
          per policy year`,
          info: `(<em>part of policy year limit</em>)`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `12`
        },
        {
          data: `<strong>Overseas Hospitalisation for Accident Benefit</strong>`
        },
        {
          data: `150% of GHS benefit (<em>for item 1 to 7</em>)`
        },
        {
          data: `150% of GHS benefit (<em>for item 1 to 7</em>)`
        },
        {
          data: `150% of GHS benefit (<em>for item 1 to 7</em>)`
        },
        {
          data: `150% of GHS benefit (<em>for item 1 to 7</em>)`
        },
        {
          data: `150% of GHS benefit (<em>for item 1 to 7</em>)`
        },
        {
          data: `150% of GHS benefit (<em>for item 1 to 7</em>)`
        }
      ]
    },
    {
      id: 13,
      columns: [
        {
          data: `13`
        },
        {
          data: `<strong>Rehabilitation Benefit</strong>`,
          info: `<em>(Max. per disability, up to 31 days)</em>`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$10,000 <br> per policy year `,
          info: `(part of policy year limit)`
        }
      ]
    },
    {
      id: 14,
      columns: [
        {
          data: `14`
        },
        {
          data: `<strong>Hospital Cash Benefit</strong>`,
          info: `<em>(Max. per day, up to 90 days per disability)</em>`
        },
        {
          data: ``,
          attributes: {
            colspan: "6"
          }
        }
      ]
    },
    {
      id: "",
      columns: [
        {
          data: ``
        },
        {
          data: `<strong>- Singapore Government Restructured B1 Ward</strong>`
        },
        {
          data: `S$ 50`
        },
        {
          data: `S$ 40`
        },
        {
          data: `Nil`
        },
        {
          data: `Nil`
        },
        {
          data: `Nil`
        },
        {
          data: `S$ 80`
        }
      ]
    },
    {
      id: "",
      columns: [
        {
          data: ``
        },
        {
          data: `<strong>- Singapore Government Restructured B2 Ward</strong>`
        },
        {
          data: `S$ 100`
        },
        {
          data: `S$ 80`
        },
        {
          data: `S$ 40`
        },
        {
          data: `Nil`
        },
        {
          data: `Nil`
        },
        {
          data: `S$ 150`
        }
      ]
    },
    {
      id: "",
      columns: [
        {
          data: ``
        },
        {
          data: `<strong>- Singapore Government Restructured C Ward</strong>`
        },
        {
          data: `S$ 150`
        },
        {
          data: `S$ 120`
        },
        {
          data: `S$ 80`
        },
        {
          data: `Nil`
        },
        {
          data: `Nil`
        },
        {
          data: `S$ 200`
        }
      ]
    },
    {
      id: 18,
      columns: [
        {
          data: `15`
        },
        {
          data: `<strong>In-Hospital Psychiatric Treatment</strong>`,
          info: `<em>(Max. per policy year)</em>`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 2,500`
        },
        {
          data: `S$ 2,500`
        },
        {
          data: `S$10,000 <br> per policy year `,
          info: `(part of policy year limit)`
        }
      ]
    },
    {
      id: 19,
      columns: [
        {
          data: `16`
        },
        {
          data: `<strong>Death Benefit</strong>`,
          info: `<em>(Double Death Benefits payable in the event of death due to accidental causes)</em>`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        }
      ]
    },
    {
      id: 20,
      columns: [
        {
          data: `17`
        },
        {
          data: `<strong>Pro-ration factor for:<br> 
                  Plan 2 &amp; 3<br>
                  Plan 4 &amp; 5</strong>`,
          info: `<strong>Plan 2 &amp; 3</strong><br> <em>Applicable to items 4 – 7</em></p>
              <p><strong>Plan 4 &amp; 5</strong><br> <em>Applicable to items 2 – 7</em></p>`
        },
        {
          data: `Nil`
        },
        {
          data: `75%`,
          info: `applies if Insured Member stays in 1 Bedded ward (Private or Government Restructured)`
        },
        {
          data: `75%`,
          info: `applies if Insured Member stays in 2 Bedded or higher ward (Private or Government Restructured)`
        },
        {
          data: `75%`,
          info: `applies if Insured Member stays in 1 Bedded ward (Private)`
        },
        {
          data: `75% `,
          info: `applies if Insured Member stays in 4 Bedded or higher ward (Private) or 2 Bedded or higher ward (Government Restructured)`
        },
        {
          data: `Nil`
        }
      ]
    },
    {
      id: 21,
      columns: [
        {
          data: `18`
        },
        {
          data: `<strong>Co-insurance</strong>`
        },
        {
          data: `Not Applicable`
        },
        {
          data: `Not Applicable`
        },
        {
          data: `Not Applicable`
        },
        {
          data: `Not Applicable`
        },
        {
          data: `Not Applicable`
        },
        {
          data: `20%`,
          info: `(Private Hospital)`
        }
      ]
    }
  ],
  footnotes: []
};

let GHSPremiumEmployee = {
  title: `<strong>* For Employee Only <i>(inclusive of 9% GST)</i></strong> `,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    },
    {
      title: `Plan 6`,
      tier: 6
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 19</strong>`
        },
        {
          data: `S$ 394.87`
        },
        {
          data: `S$ 269.58`
        },
        {
          data: `S$ 217.62`
        },
        {
          data: `S$ 219.49`
        },
        {
          data: `S$ 161.43`
        },
        {
          data: `S$ 799.52`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>20 - 24</strong>`
        },
        {
          data: `S$ 394.87`
        },
        {
          data: `S$ 269.58`
        },
        {
          data: `S$ 217.62`
        },
        {
          data: `S$ 223.57`
        },
        {
          data: `S$ 166.53`
        },
        {
          data: `S$ 799.52`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>25 - 29</strong>`
        },
        {
          data: `S$ 394.87`
        },
        {
          data: `S$ 274.67`
        },
        {
          data: `S$ 220.68`
        },
        {
          data: `S$ 230.7`
        },
        {
          data: `S$ 171.62`
        },
        {
          data: `S$ 799.52`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>30 - 34</strong>`
        },
        {
          data: `S$ 425.44`
        },
        {
          data: `S$ 307.27`
        },
        {
          data: `S$ 248.18`
        },
        {
          data: `S$ 243.94`
        },
        {
          data: `S$ 181.8`
        },
        {
          data: `S$ 1,032.11`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>35 - 39</strong>`
        },
        {
          data: `S$ 425.44`
        },
        {
          data: `S$ 314.4`
        },
        {
          data: `S$ 253.27`
        },
        {
          data: `S$ 276.54`
        },
        {
          data: `S$ 205.24`
        },
        {
          data: `S$ 1,032.11`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>40 - 44</strong>`
        },
        {
          data: `S$ 457.02`
        },
        {
          data: `S$ 379.59`
        },
        {
          data: `S$ 301.16`
        },
        {
          data: `S$ 350.91`
        },
        {
          data: `S$ 257.19`
        },
        {
          data: `S$ 1,156.10`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>45 - 49</strong>`
        },
        {
          data: `S$ 654.64`
        },
        {
          data: `S$ 488.59`
        },
        {
          data: `S$ 405.07`
        },
        {
          data: `S$ 422.22`
        },
        {
          data: `S$ 299.98`
        },
        {
          data: `S$ 1,442.51`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>50 - 54</strong>`
        },
        {
          data: `S$ 790.13`
        },
        {
          data: `S$ 625.1`
        },
        {
          data: `S$ 529.35`
        },
        {
          data: `S$ 564.84`
        },
        {
          data: `S$ 411.02`
        },
        {
          data: `S$ 1,749.1`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>55 - 59</strong>`
        },
        {
          data: `S$ 966.36`
        },
        {
          data: `S$ 858.39`
        },
        {
          data: `S$ 685.21`
        },
        {
          data: `S$ 730.88`
        },
        {
          data: `S$ 529.18`
        },
        {
          data: `S$ 2,261.37`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>60 - 64</strong>`
        },
        {
          data: `S$ 1,361.62`
        },
        {
          data: `S$ 1,071.28`
        },
        {
          data: `S$ 945.99`
        },
        {
          data: `S$ 961.11`
        },
        {
          data: `S$ 694.21`
        },
        {
          data: `S$ 3,079.28`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>65 - 69</strong>`
        },
        {
          data: `S$ 1,863.83`
        },
        {
          data: `S$ 1,517.48`
        },
        {
          data: `S$ 1,250.58`
        },
        {
          data: `S$ 1,254.49`
        },
        {
          data: `S$ 904.06`
        },
        {
          data: `S$ 4,641.19`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only.</em>`
        },
        {
          data: `S$ 3,473.36`
        },
        {
          data: `S$ 2,546.35`
        },
        {
          data: `S$ 2,052.28`
        },
        {
          data: `S$ 1,701.8`
        },
        {
          data: `S$ 1,228.0`
        },
        {
          data: `S$ 5,637.77`
        }
      ]
    }
  ],
  footnotes: []
};

let GHSPremiumSpouseOrChild = {
  title: `<br><strong>* For Employee and Spouse or Children Coverage Only <i>(inclusive of 9% GST)</i></strong>`,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    },
    {
      title: `Plan 6`,
      tier: 6
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 19</strong>`
        },
        {
          data: `S$ 888.46`
        },
        {
          data: `S$ 606.56`
        },
        {
          data: `S$ 489.64`
        },
        {
          data: `S$ 493.86`
        },
        {
          data: `S$ 363.22`
        },
        {
          data: `S$ 1,798.91`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>20 - 24</strong>`
        },
        {
          data: `S$ 888.46`
        },
        {
          data: `S$ 606.56`
        },
        {
          data: `S$ 489.64`
        },
        {
          data: `S$ 503.04`
        },
        {
          data: `S$ 374.69`
        },
        {
          data: `S$ 1,798.91`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>25 - 29</strong>`
        },
        {
          data: `S$ 888.46`
        },
        {
          data: `S$ 618.01`
        },
        {
          data: `S$ 496.55`
        },
        {
          data: `S$ 519.07`
        },
        {
          data: `S$ 386.15`
        },
        {
          data: `S$ 1,798.91`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>30 - 34</strong>`
        },
        {
          data: `S$ 957.23`
        },
        {
          data: `S$ 691.35`
        },
        {
          data: `S$ 558.42`
        },
        {
          data: `S$ 548.87`
        },
        {
          data: `S$ 409.04`
        },
        {
          data: `S$ 2,322.25`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>35 - 39</strong>`
        },
        {
          data: `S$ 957.23`
        },
        {
          data: `S$ 707.41`
        },
        {
          data: `S$ 569.86`
        },
        {
          data: `S$ 622.23`
        },
        {
          data: `S$ 461.78`
        },
        {
          data: `S$ 2,322.25`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>40 - 44</strong>`
        },
        {
          data: `S$ 1,028.28`
        },
        {
          data: `S$ 854.08`
        },
        {
          data: `S$ 677.60`
        },
        {
          data: `S$ 789.57`
        },
        {
          data: `S$ 578.68`
        },
        {
          data: `S$ 2,601.22`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>45 - 49</strong>`
        },
        {
          data: `S$ 1,472.95`
        },
        {
          data: `S$ 1,099.33`
        },
        {
          data: `S$ 911.40`
        },
        {
          data: `S$ 950.00`
        },
        {
          data: `S$ 674.96`
        },
        {
          data: `S$ 3,245.64`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>50 - 54</strong>`
        },
        {
          data: `S$ 1,777.79`
        },
        {
          data: `S$ 1,406.48`
        },
        {
          data: `S$ 1,191.03`
        },
        {
          data: `S$ 1,270.90`
        },
        {
          data: `S$ 924.80`
        },
        {
          data: `S$ 3,935.47`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>55 - 59</strong>`
        },
        {
          data: `S$ 2,174.33`
        },
        {
          data: `S$ 1,931.37`
        },
        {
          data: `S$ 1,541.72`
        },
        {
          data: `S$ 1,644.47`
        },
        {
          data: `S$ 1,190.66`
        },
        {
          data: `S$ 5,088.08`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>60 - 64</strong>`
        },
        {
          data: `S$ 3,063.65`
        },
        {
          data: `S$ 2,410.40`
        },
        {
          data: `S$ 2,128.48`
        },
        {
          data: `S$ 2,162.49`
        },
        {
          data: `S$ 1,561.97`
        },
        {
          data: `S$ 6,928.38`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>65 - 69</strong>`
        },
        {
          data: `S$ 4,193.64`
        },
        {
          data: `S$ 3,414.32`
        },
        {
          data: `S$ 2,813.81`
        },
        {
          data: `S$ 2,822.61`
        },
        {
          data: `S$ 2,034.13`
        },
        {
          data: `S$ 10,381.93`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 7,815.08`
        },
        {
          data: `S$ 5,729.29`
        },
        {
          data: `S$ 4,617.65`
        },
        {
          data: `S$ 3,842.54`
        },
        {
          data: `S$ 2,763.02`
        },
        {
          data: `S$ 12,684.98`
        }
      ]
    }
  ],
  footnotes: []
};

let GHSPremiumSpouseAndChild = {
  title: `<br><strong>* For Employee and Spouse and Children Coverage Only <i>(inclusive of 9% GST)</i></strong>`,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    },
    {
      title: `Plan 6`,
      tier: 6
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 19</strong>`
        },
        {
          data: `S$ 1,382.05`
        },
        {
          data: `S$ 943.55`
        },
        {
          data: `S$ 761.66`
        },
        {
          data: `S$ 768.23`
        },
        {
          data: `S$ 565.01`
        },
        {
          data: `S$ 2,798.30`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>20 - 24</strong>`
        },
        {
          data: `S$ 1,382.05`
        },
        {
          data: `S$ 943.55`
        },
        {
          data: `S$ 761.66`
        },
        {
          data: `S$ 782.50`
        },
        {
          data: `S$ 582.84`
        },
        {
          data: `S$ 2,798.30`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>25 - 29</strong>`
        },
        {
          data: `S$ 1,382.05`
        },
        {
          data: `S$ 961.35`
        },
        {
          data: `S$ 772.40`
        },
        {
          data: `S$ 807.44`
        },
        {
          data: `S$ 600.69`
        },
        {
          data: `S$ 2,798.30`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>30 - 34</strong>`
        },
        {
          data: `S$ 1,489.02`
        },
        {
          data: `S$ 1,075.45`
        },
        {
          data: `S$ 868.66`
        },
        {
          data: `S$ 853.79`
        },
        {
          data: `S$ 636.30`
        },
        {
          data: `S$ 3,612.38`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>35 - 39</strong>`
        },
        {
          data: `S$ 1,489.02`
        },
        {
          data: `S$ 1,100.42`
        },
        {
          data: `S$ 886.46`
        },
        {
          data: `S$ 967.92`
        },
        {
          data: `S$ 718.32`
        },
        {
          data: `S$ 3,612.38`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>40 - 44</strong>`
        },
        {
          data: `S$ 1,599.54`
        },
        {
          data: `S$ 1,328.58`
        },
        {
          data: `S$ 1,054.04`
        },
        {
          data: `S$ 1,228.22`
        },
        {
          data: `S$ 900.17`
        },
        {
          data: `S$ 4,046.34`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>45 - 49</strong>`
        },
        {
          data: `S$ 2,291.26`
        },
        {
          data: `S$ 1,710.08`
        },
        {
          data: `S$ 1,417.74`
        },
        {
          data: `S$ 1,477.79`
        },
        {
          data: `S$ 1,049.94`
        },
        {
          data: `S$ 5,048.77`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>50 - 54</strong>`
        },
        {
          data: `S$ 2,765.45`
        },
        {
          data: `S$ 2,187.87`
        },
        {
          data: `S$ 1,852.72`
        },
        {
          data: `S$ 1,976.94`
        },
        {
          data: `S$ 1,438.58`
        },
        {
          data: `S$ 6,121.84`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>55 - 59</strong>`
        },
        {
          data: `S$ 3,382.28`
        },
        {
          data: `S$ 3,004.36`
        },
        {
          data: `S$ 2,398.22`
        },
        {
          data: `S$ 2,558.07`
        },
        {
          data: `S$ 1,852.15`
        },
        {
          data: `S$ 7,914.78`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>60 - 64</strong>`
        },
        {
          data: `S$ 4,765.69`
        },
        {
          data: `S$ 3,749.51`
        },
        {
          data: `S$ 3,310.96`
        },
        {
          data: `S$ 3,363.88`
        },
        {
          data: `S$ 2,429.73`
        },
        {
          data: `S$ 10,777.48`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>65 - 69</strong>`
        },
        {
          data: `S$ 6,523.44`
        },
        {
          data: `S$ 5,311.16`
        },
        {
          data: `S$ 4,377.05`
        },
        {
          data: `S$ 4,390.72`
        },
        {
          data: `S$ 3,164.19`
        },
        {
          data: `S$ 16,149.67`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 12,156.78`
        },
        {
          data: `S$ 8,912.23`
        },
        {
          data: `S$ 7,183.01`
        },
        {
          data: `S$ 5,977.30`
        },
        {
          data: `S$ 4,298.02`
        },
        {
          data: `S$ 19,732.20`
        }
      ]
    }
  ],
  footnotes: []
};

/*let GHSGroupDiscount = {
  headers: [
    {
      title: `Group Size <i>Insured Employee<i>`
    },
    {
      title: `Discount`
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>11 - 15</strong>`
        },
        {
          data: `5%`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>16 and above</strong>`
        },
        {
          data: `10%`
        }
      ]
    }
  ],
  footnotes: []
};*/

//   ----------------------------------------------------------------------

const GHS = {
  id: 4,
  code: "GHSB",
  label: "Group Hospital & Surgical (GHS)",
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GHSBenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium",
      data: [GHSPremiumEmployee, GHSPremiumSpouseOrChild, GHSPremiumSpouseAndChild]
    }
    /*{
      id: 4,
      order: 4,
      type: Enums.PLANS_TYPE.GROUP_DISCOUNT,
      label: "Group Size Discount",
      data: [GHSGroupDiscount]
    }*/
  ]
};

export default GHS;
