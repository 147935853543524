import Enums from "../../../../../utils/Enums";

const GOSBenefits = {
  headers: [
    {
      title: ``,
      mandatory: true,
      attributes: {
        style: {
          width: 10
        }
      }
    },
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "30%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>a</strong>`
        },
        {
          data: `<strong>Specialist Consultation at Singapore Govt Restructured Hospital (GRH)</strong>`,
          info: `<em>(recommended by a Registered Medical Practitioner)</em>`
        },
        {
          data: `S$ 2,000 per policy year`
        },
        {
          data: `S$ 1,500 per policy year`
        },
        {
          data: `S$ 1,000 per policy year`
        },
        {
          data: `Overall policy year limit of S$ 500`,
          attributes: {
            rowspan: 3
          }
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>b</strong>`
        },
        {
          data: `<strong>Specialist Consultation at Private Hospital (PTE)</strong>`,
          info: `<em>(recommended by a Registered Medical Practitioner)</em>`
        },
        {
          data: `S$ 800 per policy year`
        },
        {
          data: `S$ 400 per policy year`
        },
        {
          data: `S$ 200 per policy year`,
          attributes: {
            notLast: true
          }
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>c</strong>`
        },
        {
          data: `<strong>All other Diagnostic X-Ray and Lab Test</strong>`,
          info: `<em>(GP or SP referral required)</em>`
        },
        {
          data: `S$ 800 per policy year`
        },
        {
          data: `S$ 400 per policy year`
        },
        {
          data: `S$ 200 per policy year`,
          attributes: {
            notLast: true
          }
        }
      ]
    }
  ],
  footnotes: []
};

let GOSPremium = {
  headers: [
    {
      title: `Plan Type`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Annual Premium</strong>`
        },
        {
          data: `S$ 216.00`
        },
        {
          data: `S$ 178.65`
        },
        {
          data: `S$ 161.49`
        },
        {
          data: `S$ 123.14`
        }
      ]
    }
  ],
  footnotes: []
};

//   ----------------------------------------------------------------------
const GP = {
  id: 1,
  code: "PSIH",
  label: `Group Outpatient Specialist (GOSP)`,
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GOSBenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium (inclusive of 8% GST)",
      data: [GOSPremium]
    }
  ]
};

export default GP;
