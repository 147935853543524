import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import ClaimExpensePanel from "./ClaimExpensePanel";
import ClaimExpenseTable from "./ClaimExpenseTable";
import LoadingPopup from "../../../../components/LoadingPopup";
import SnackBar from "../../../../components/SnackBar";
import moment from "moment";
import { claimExpenseActions, coverageActions } from "../../../../actions";
import Typography from "@mui/material/Typography";
import PolicyCard from "../../../../components/PolicyCard";

const FORMAT_DATE = "DD/MM/YYYY";

class ClaimExpenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memberType: "ALL",
      claimType: "ALL",
      fromDate: null,
      toDate: null,
      dateFilter: "CONSULTATION_DATE",
      isProcessing: false,
      errors: {},
      currentPageNo: 0
    };

    const { productCode } = this.props.location.state;

    this.isPCEClient = productCode === "PCE" || productCode === "GCP";
    this.isPTFClient = productCode === "PF3" || productCode === "PTF" || productCode === "PEP";

    this.handleChange = event => {
      this.setState({
        [event.target.name]: event.target.value
      });
    };

    this.convertDate = datePickerValue => {
      return (datePickerValue && moment(datePickerValue, FORMAT_DATE)) || null;
    };

    this.formatDate = datePickerValue => {
      const date = this.convertDate(datePickerValue);
      const formatedDate = moment(date).format(FORMAT_DATE);
      return formatedDate === "Invalid date" ? "" : formatedDate;
    };

    this.handleDateChange = name => date => {
      this.setState(prevState => ({
        [name]: date,
        errors: {
          ...prevState.errors,
          [name]: ""
        }
      }));
    };

    this.handleDateError = (name, error) => errorDateValue => {
      this.setState(prevState => ({
        [name]: errorDateValue,
        errors: {
          ...prevState.errors,
          [name]: error
        }
      }));
    };

    this.validateForm = () => {
      const errors = { ...this.state.errors };
      if (!this.state.memberType) {
        errors.memberType = "Please select member type.";
      }
      if (!this.state.claimType) {
        errors.claimType = "Please select claim type.";
      }
      const fromDate = this.convertDate(this.state.fromDate);
      const toDate = this.convertDate(this.state.toDate);
      if (fromDate && toDate && fromDate > toDate) {
        errors.consultationDate = "Consultation 'From Date' must be prior to 'To Date'.";
      }
      return Object.values(errors).filter(e => !!e)[0];
    };

    this.setProcessingStatus = flag => {
      this.setState({
        isProcessing: flag
      });
    };

    this.resetCurrentPageNo = () => {
      this.setState({
        currentPageNo: 0
      });
    };

    this.handleSubmit = event => {
      event.preventDefault();
      const result = this.validateForm();
      console.log("debug result", result);
      if (result) {
        this.props.errorPopup(result);
        this.resetCurrentPageNo();
        return;
      }

      this.setProcessingStatus(true);
      const filter = {
        memberType: this.state.memberType,
        claimType: this.state.claimType,
        fromDate: this.formatDate(this.state.fromDate),
        toDate: this.formatDate(this.state.toDate),
        dateFilter: this.state.dateFilter
      };
      console.log(
        "debug this.isPCEClient",
        this.isPCEClient,
        "debug this.isPTFClient",
        this.isPTFClient,
        "this.props.location.state.productCode",
        this.props.location.state.productCode
      );
      if (this.isPCEClient || this.isPTFClient) {
        const { policyNumber, companyId } = this.props.location.state;
        this.props.getClaimExpenses(policyNumber, companyId, filter).then(data => {
          this.setProcessingStatus(false);
          this.resetCurrentPageNo();
        });
      }
    };

    this.handlePageChange = (event, pageIndex) => {
      this.setState({
        currentPageNo: pageIndex
      });
    };

    this.handleExport = () => {
      this.setProcessingStatus(true);
      const { filter } = this.props.claimexpense;
      const { policyNumber, companyId } = this.props.location.state;
      if (this.isPCEClient || this.isPTFClient) {
        this.props.exportClaimExpenses(policyNumber, companyId, filter).then(data => {
          this.setProcessingStatus(false);
        });
      }
    };
  }

  componentDidMount() {
    const { claimTypeList } = this.props.claimexpense;
    const { policyNumber } = this.props.location.state;

    if (!claimTypeList || claimTypeList.length === 0) {
      if (this.isPCEClient || this.isPTFClient) {
        this.props.getClaimTypes(policyNumber);
      }
    }

    if (policyNumber) {
      this.props.getGaPolicyInfo(policyNumber);
    }
  }

  componentWillUnmount() {
    this.props.clearOnExit();
  }

  render() {
    const { classes, claimexpense, closePopup, coverage } = this.props;
    const filter = {
      memberType: this.state.memberType,
      claimType: this.state.claimType,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      dateFilter: this.state.dateFilter
    };

    return (
      <div className={classes.root}>
        <Box mb={3}>
          <Typography variant="h5" fontWeight={700} mb={2}>
            {this.props.location.state.company}'s Claims
          </Typography>
          <PolicyCard gaInfo={coverage.gaInfo} />
        </Box>
        <Card className={classes.content}>
          <CardContent>
            <ClaimExpensePanel
              filter={filter}
              isProcessing={this.state.isProcessing}
              handleChange={this.handleChange}
              handleDateChange={this.handleDateChange}
              handleDateError={this.handleDateError}
              handleSubmit={this.handleSubmit}
              claimTypeList={claimexpense.claimTypeList}
            />
            <ClaimExpenseTable
              isProcessing={this.state.isProcessing}
              currentPageNo={this.state.currentPageNo}
              claimExpenseList={claimexpense.claimExpenseList}
              handlePageChange={this.handlePageChange}
              handleExport={this.handleExport}
            />
          </CardContent>
        </Card>
        <LoadingPopup name="claim-expense-api-loading" open={claimexpense.notification.showLoading} />
        <SnackBar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          autoHideDuration={claimexpense.notification.popupType === "success" ? 4000 : 12000}
          type={claimexpense.notification.popupType}
          open={claimexpense.notification.showPopup}
          onClose={closePopup}
          message={claimexpense.notification.popupMessage}
        />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    height: "100%",
    paddingTop: "40px",
    paddingLeft: "40px",
    paddingRight: "40px"
  },
  content: {
    width: "100%"
  }
});

const mapStateToProps = state => {
  return {
    claimexpense: state.HR.claimexpense,
    loggedUser: state.user,
    coverage: state.HR.coverage
  };
};

const mapDispatchToProps = dispatch => {
  return { ...bindActionCreators(claimExpenseActions, dispatch), ...bindActionCreators(coverageActions, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(ClaimExpenses));
