import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import { withStyles } from "@mui/styles";
import Coverage from "./Coverage";
import CoveragePF3 from "./CoveragePF3";
import Typography from "@mui/material/Typography";
import assets from "../../../../assets";
import PolicyCard from "../../../../components/PolicyCard";
import { bindActionCreators } from "redux";
import { coverageActions } from "../../../../actions";

import Util from "../../../../utils/Util";

class CoverageDisplay extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { policyNumber, companyId } = this.props.location.state;
    this.props.getPolicy(policyNumber, companyId);
    this.props.getGaPolicyInfo(policyNumber);
  }

  render() {
    const { classes, coverage } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Typography variant="h5" fontWeight={700} mb={2}>
            {this.props.location.state.company}'s Dashboard
          </Typography>
          <PolicyCard gaInfo={coverage.gaInfo} />
        </div>
        {coverage.policy &&
          (Util.isProductPF3(this.props.location.state.productCode) === true ? <CoveragePF3 /> : <Coverage />)}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    height: "100%",
    paddingTop: "40px",
    paddingLeft: "40px",
    paddingRight: "40px"
  },
  content: {
    backgroundImage: "url(" + assets.dashBoardImages.background + ")",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    padding: "20px 20px 20px"
  }
});

function mapStateToProps(state) {
  return {
    coverage: state.HR.coverage,
    quote: state.quote,
    gaInfo: state.gaInfo,
    products: state.products
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(coverageActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(CoverageDisplay)));
