import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import assets from "../../../../assets";
class Banner extends Component {
  render() {
    const { classes } = this.props;
    const { introImages } = assets;
    return (
      <div className={classes.root}>
        {/**<div className={classes.topBar}>
          <Button variant="contained" color="secondary" className={classes.topBarButton}>
            View Commercial Insurance Solutions
          </Button>
        </div>**/}
        <div className={classes.bannerHolder} style={{ background: "url(" + introImages.background + ")" }}>
          <p className={classes.bannerHeading}>Welcome to PRUworks.</p>
          <p className={classes.bannerDesc}>
            Our goal is to support you – our SME customers – on your path to success by providing a seamless, integrated
            experience and an extensive suite of services that go way beyond insurance.
            <br />
            <br />
            PRUworks is your home for business.
            <br />
            <img className={classes.bannerImg} alt="banner" src={introImages.bannerImg} />
            <br />
            <br />
            <br />
          </p>
          {/**<WatchVideo />**/}
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    background: "white"
  },
  topBar: {
    height: "75px",
    background: "#F5F5F5"
  },
  topBarButton: {
    minWidth: 150,
    backgroundColor: "transparent",
    border: "1px solid " + `${theme.colors.primary.red}`,
    color: `${theme.colors.primary.red}`,
    boxShadow: "none",
    margin: "20px",
    fontSize: "18px",
    float: "right"
  },
  bannerImg: {
    width: "100%"
  },
  bannerHolder: {
    height: "auto",
    paddingBottom: "100px",
    backgroundPosition: "center top!important",
    backgroundSize: "cover!important"
  },
  bannerHeading: {
    fontSize: "35px",
    paddingTop: "80px",
    marginTop: 0,
    textAlign: "center"
  },
  bannerDesc: {
    fontSize: "20px",
    [theme.breakpoints.up("md")]: {
      margin: "12px 300px 0px 300px"
    },
    [theme.breakpoints.down("md")]: {
      margin: "12px 50px 0px 50px"
    },
    textAlign: "justify",
    textAlignLast: "center"
  }
});

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Banner));
