import React, { Component } from "react";
import { injectIntl } from "react-intl";
import classNames from "classnames";

import { quoteActions } from "../../../../../../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CustomStep from "../../../../../../../components/CustomStep";
import PaymentInstruction from "./PaymentInstruction";
import Enums from "../../../../../../../utils/Enums";

class PaymentMethods extends Component {
  constructor(props) {
    super(props);

    const { product, user } = this.props;

    this.bankAccounts = {
      UOB_ASSISTED: {
        recipientName: "Prudential Assurance Co. Singapore (Pte) Ltd",
        recipientBank: "United Overseas Bank (UOB)",
        recipientAccountNumber: "451-309-645-8",
        swiftCode: "UOVBSGSGXXX",
        bankShortName: "UOB"
      },
      SCB_ASSISTED: {
        recipientName: "Prudential Assurance Co. Singapore (Pte) Ltd",
        recipientBank: "Standard Chartered Bank (Singapore) Limited",
        recipientAccountNumber: "010519241-4",
        bankCode: "9496",
        branchCode: "001",
        bankShortName: "SCB"
      },
      PRU_AGENCY: {
        recipientName: "Prudential Assurance Co. Singapore (Pte) Ltd",
        recipientBank: "Standard Chartered Bank (Singapore) Limited",
        recipientAccountNumber: "010519241-4",
        bankCode: "9496",
        branchCode: "001",
        bankShortName: "SCB"
      },
      PFA: {
        recipientName: "Prudential Assurance Co. Singapore (Pte) Ltd",
        recipientBank: "Standard Chartered Bank (Singapore) Limited",
        recipientAccountNumber: "010519241-4",
        bankCode: "9496",
        branchCode: "001",
        bankShortName: "SCB"
      },
      BRO: {
        recipientName: "Prudential Assurance Co. Singapore (Pte) Ltd",
        recipientBank: "Standard Chartered Bank (Singapore) Limited",
        recipientAccountNumber: "010519241-4",
        bankCode: "9496",
        branchCode: "001",
        bankShortName: "SCB"
      }
    };

    const accountDetails = this.bankAccounts[user.auth.pcode] || {};

    this.paymentModes = [
      {
        mode: Enums.PAYMENT_METHOD.BANK_TRANSFER,
        icon: "swap_horiz",
        name: "Bank Transfer",
        renderContent: isDisabled => {
          return this.hasBankSwiftCode() ? (
            <PaymentInstruction
              readOnly={isDisabled}
              content={`<ul>
                  <li>You can make a FAST Transfer or Fund Transfer to Prudential's ${accountDetails.bankShortName} Account.</li>
                  <li>The following are the key information for you to enter:</li>
                </ul>
                <table><tr><td>Recipient Name:</td><td>${accountDetails.recipientName}</td></tr><tr><td>Recipient's Bank:</td><td>${accountDetails.recipientBank}</td></tr><tr><td>Recipient's Account Number:</td><td>${accountDetails.recipientAccountNumber}</td></tr><tr><td>SWIFT Code:</td><td>${accountDetails.swiftCode}</td></tr><tr><td>Comments / Reference Field For Recipient:</td><td>[policy number], Enterprise Business, Premium due for ${product.name} followed by your Contact Number.</td></tr></table>
                <br>
                <span>* Your policy number can be found in email you received upon policy submission or can be provided to you by your Financial Consultant</span>`}
              method={Enums.PAYMENT_METHOD.BANK_TRANSFER}
            />
          ) : (
            <PaymentInstruction
              readOnly={isDisabled}
              content={`<ul>
                  <li>You can make a FAST Transfer or Fund Transfer to Prudential's ${accountDetails.bankShortName} Account.</li>
                  <li>The following are the key information for you to enter:</li>
                </ul>
                <table><tr><td>Recipient Name:</td><td>${accountDetails.recipientName}</td></tr><tr><td>Recipient's Bank:</td><td>${accountDetails.recipientBank}</td></tr><tr><td>Recipient’s Bank Code:</td><td>${accountDetails.bankCode}</td></tr><tr><td>Recipient’s Branch Code:</td><td>${accountDetails.branchCode}</td></tr><tr><td>Recipient's Account Number:</td><td>${accountDetails.recipientAccountNumber}</td></tr><tr><td>Comments / Reference Field For Recipient:</td><td>[policy number], Enterprise Business, Premium due for ${product.name} followed by your Contact Number.</td></tr></table>
                <br>
                <span>* Your policy number can be found in email you received upon policy submission or can be provided to you by your Financial Consultant</span>`}
              method={Enums.PAYMENT_METHOD.BANK_TRANSFER}
            />
          );
        }
      },
      {
        mode: Enums.PAYMENT_METHOD.CHEQUE,
        icon: "border_color",
        name: "Cheque",
        renderContent: isDisabled => {
          return (
            <PaymentInstruction
              readOnly={isDisabled}
              content={`<ul>
                <li>You can mail us a cheque at the following address or contact your Financial Consultant:<br>
                  Prudential Assurance Co. Singapore (Pte) Ltd<br>
                  Enterprise Business Solutions, Operations Team<br>
                  7 <a href="https://www.google.com/maps/place/Marina+One+East+Tower/@1.2779623,103.8505501,17z/data=!3m1!4b1!4m5!3m4!1s0x31da1911bab00319:0x4b8b51b42eb849be!8m2!3d1.2779623!4d103.8527388" target="_blank">Straits View, #06-01</a> Marina One East Tower, Singapore 018936
                </li>
                <li>Cheques should be crossed and made payable to Prudential Assurance Company (S) P/L.</b></li>
                <li>At the back of the Cheque please write : [policy number], Enterprise Business Solutions, Operations Team, Premium due for ${product.name} followed by your Contact Number.</i>.
                </li>
              </ul>
              * Your policy number can be found in email you received upon policy submission or can be provided to you by your Financial Consultant
							`}
              method={Enums.PAYMENT_METHOD.CHEQUE}
            />
          );
        }
      }
    ];

    this.state = {
      selectedMode: null,
      status: null
    };
  }

  componentDidMount() {
    const { quote } = this.props;
    this.setPaymentMode(quote.payment_method);
  }

  setPaymentMode = mode => {
    this.setState({ selectedMode: mode });
  };

  handlePaymentModeClick = mode => {
    this.setPaymentMode(mode);
  };

  hasBankSwiftCode = () => {
    const brand = this.props.user.auth.pcode;
    const accountDetails = this.bankAccounts[brand] || {};
    return accountDetails["swiftCode"] && typeof accountDetails["swiftCode"] !== "undefined";
  };

  render() {
    const { classes, intl, document, quote, readOnly, disabled } = this.props;
    const { selectedMode } = this.state;

    const ACCEPTANCE_FILE_TYPE = Enums.FILE_TYPE.ACCEPTANCE_FILE_TYPE;
    const ACRA_FILE_TYPE = Enums.FILE_TYPE.ACRA_FILE_TYPE;
    const MAS314_FILE_TYPE = Enums.FILE_TYPE.MAS314_FILE_TYPE;
    const CORP_BANK_FILE_TYPE = Enums.FILE_TYPE.CORP_BANK_DETAILS;
    const NRIC_IND_FILE_TYPE = Enums.FILE_TYPE.NRIC_IND_FILE_TYPE;
    const CLAIM_DECLARATION_FILE_TYPE = Enums.FILE_TYPE.DECLARATION_CLAIM_ENTITLEMENT_FILE_TYPE;
    const acceptanceLetterFiles = document.files.filter(file => file.file_type === ACCEPTANCE_FILE_TYPE) || [];
    const acraFiles = document.files.filter(file => file.file_type === ACRA_FILE_TYPE) || [];
    const mas314Files = document.files.filter(file => file.file_type === MAS314_FILE_TYPE) || [];
    const corpBankFiles = document.files.filter(file => file.file_type === CORP_BANK_FILE_TYPE) || [];
    const NRIC_INDFiles = document.files.filter(file => file.file_type === NRIC_IND_FILE_TYPE) || [];
    const claimDeclarationFiles = document.files.filter(file => file.file_type === CLAIM_DECLARATION_FILE_TYPE) || [];

    const isDisabled =
      readOnly ||
      acceptanceLetterFiles.length === 0 ||
      acraFiles.length === 0 ||
      mas314Files.length === 0 ||
      corpBankFiles.length === 0 ||
      NRIC_INDFiles.length === 0 ||
      claimDeclarationFiles.length == 0 ||
      (quote.payment_request && quote.payment_request.isProcessing) ||
      quote.payment_status == Enums.PAYMENT_STATUS.DONE;

    return (
      <Grid container>
        <Grid item md={1}>
          <CustomStep label="3" last disabled={isDisabled} />
        </Grid>
        <Grid item md={11}>
          <Paper className={classNames(classes.root)} style={{ padding: "10px", marginTop: "10px" }}>
            <div>
              <Typography variant="h5" gutterBottom className={classNames(classes.container, classes.heading)}>
                {intl.formatMessage({ id: "payment.method.title" })}
                {/* <NoticeGST /> */}
              </Typography>
              <div>
                For all policies with policy start date or renewal date before 1 Jan 2024, the new GST rate of 9% will
                apply for premium payments made on or after 1 Jan 2024. For all policies with policy start date or
                renewal date on or after 1 Jan 2024, the new GST rate of 9% will apply regardless of payment date. This
                is with exception for New Business packaged policies, for which the old GST rate of 8% will continue to
                apply for premium payments made before 1 Jan 2024, regardless of whether the policy start date is
                before, on or after 1 Jan 2024.
              </div>
              <div className={classes.content}>
                <Typography variant="subheading" className={classes.subheading} gutterBottom>
                  {intl.formatMessage({ id: "payment.method.subheading" })}
                </Typography>
                {/* Payment mode selections */}
                {this.paymentModes.map((m, idx) => {
                  return (
                    <FormControl disabled={isDisabled} key={idx} className={classes.formControl}>
                      <div
                        disabled={isDisabled}
                        className={classes.formControlLabel}
                        onClick={() => !isDisabled && this.handlePaymentModeClick(m.mode)}
                      >
                        <Radio color="primary" checked={m.mode === selectedMode} name="payment_type" value={m.mode} />
                        <Icon className={classes.paymentIcon}>{m.icon}</Icon>
                        <Typography className={classes.paymentLabel}>{m.name}</Typography>
                      </div>
                      {/* Payment content */}
                      {m.mode === selectedMode && (
                        <Grid container className={classNames(classes.paymentContent, classes.formControl)} key={idx}>
                          <Grid item md={12}>
                            {m.renderContent(isDisabled)}
                          </Grid>
                        </Grid>
                      )}
                    </FormControl>
                  );
                })}
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%"
  },
  container: {
    padding: `20px ${theme.spacing.unit * 3}px`
  },
  content: {
    padding: theme.spacing.unit * 3,
    paddingTop: 5
  },
  heading: {
    borderBottom: `1px solid ${theme.colors.blackScale.black70}`
  },
  subheading: {
    fontWeight: "bold"
  },
  formControl: {
    margin: theme.spacing.unit,
    width: "100%"
  },
  formControlLabel: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    cursor: "pointer"
  },
  paymentLabel: {
    paddingLeft: theme.spacing.unit
  },
  paymentIcon: {
    fontSize: "40px"
  },
  paymentContent: {
    width: "100%"
  },
  selected: {
    "&::after": {
      width: "100%"
    }
  },
  unselected: {
    opacity: "0.4",
    "&:hover": {
      opacity: 1
    }
  },
  hide: {
    display: "none"
  }
});

function mapStateToProps(state, prop) {
  let product = state.products[state.quote.product_code];
  return {
    quote: state.quote,
    readOnly: state.quote.readOnly,
    product: product && product.config,
    document: state.document,
    user: state.user,
    app: state.app
  };
}

function mapDispatchToProps(dispatch) {
  return { ...bindActionCreators(quoteActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(PaymentMethods)));
