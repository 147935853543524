//Display for Product Naming

export default [
  {
    value: "GTLB",
    label: "Group Term Life",
    sCode: "(GTL)"
  },
  {
    value: "GHSB",
    label: "Group Hospital & Surgical",
    sCode: "(GHS)"
  },
  {
    value: "GFWM",
    label: "Group Foreign Worker Medical Insurance",
    sCode: "(GFWM) : 4-Bed Ward SGRH"
  },
  {
    value: "GCCA",
    label: "Group Crisis Cover Accelerated",
    sCode: "(GCCA)"
  },
  {
    value: "GEMM",
    label: "Group Extended Major Medical",
    sCode: "(GEMM)"
  },
  {
    value: "GPRU",
    label: "Group Outpatient General Pracitioner",
    sCode: "(GPRU)"
  },
  {
    value: "PGIH",
    label: "Group Outpatient General Pracitioner",
    sCode: "(GPRU)"
  },
  {
    value: "GOSP",
    label: "Group Outpatient Specialist",
    sCode: "(GOSP)"
  },
  {
    value: "PSIH",
    label: "Group Outpatient Specialist",
    sCode: "(GOSP)"
  },
  {
    value: "GADD",
    label: "Group Accidental Death & Dismemberment",
    sCode: "(GADD)"
  },
  {
    value: "GAMR",
    label: "Group Accidental Medical Reimbursement",
    sCode: "(GAMR)"
  },
  {
    value: "GDEN",
    label: "Group Dental",
    sCode: "(GDEN)"
  },
  {
    value: "GPCG",
    label: "Group Pre-Crisis Guard",
    sCode: "(GPCG)"
  },
  {
    value: "GEAC",
    label: "Group Pre-Crisis Guard",
    sCode: "(GPCG)"
  },
  {
    value: "GPPD",
    label: "Group Panel Dental",
    sCode: "(GPPD)"
  },
  {
    value: "PDIH",
    label: "Group Panel Dental",
    sCode: "(GPPD)"
  },
  {
    value: "GSMM",
    label: "GSMM",
    sCode: "(GSMM)"
  },
  {
    value: "PSMH",
    label: "PSMH",
    sCode: "(PSMH)"
  }
];
