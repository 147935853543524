import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import moment from "moment";

import { withStyles } from "@mui/styles";

import EmployeeTable from "./EmployeeTable";
import { employeeActions, quoteActions, validationsActions } from "../../../../../../actions";
import InfoPopover from "../../../../../../components/InfoPopover";
import Util from "../../../../../../utils/Util";
import * as _ from "lodash";
import Enums from "../../../../../../utils/Enums";

class EmployeeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoPopover: {
        open: false,
        element: null,
        data: ""
      },
      errors: null
    };
  }

  componentDidMount() {
    this.checkAndSetErrors();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.quote !== nextProps.quote || (!this.props.errors && this.props.errors !== nextProps.errors)) {
      if (this.props.employee !== nextProps.employee && this.props.employee.errors !== nextProps.employee.errors) {
        return;
      }
      this.checkAndSetErrors(nextProps);
    }
  }

  async checkAndSetErrors(props = this.props) {
    const { intl, errors, quote, employee, document } = props;

    if (props.quote.readOnly || document.hasSignedProposal) {
      return [];
    }
    let _errors = [];
    if (employee.dataIsInvalid) {
      _errors.push({
        title: "Employee details errors:",
        error: intl.formatMessage({ id: "error.msg.employee.list" })
      });
    }

    let persons = quote.data.persons;

    let errorSet = new Set();

    persons.map(person => {
      if (person.validations) {
        let error1211 = person.validations.find(e => e.statusCode == "1211");
        let error1212 = person.validations.find(e => e.statusCode == "1212");
        let error1314 = person.validations.find(e => e.statusCode == "1314");
        //let error1975 = person.validations.find(e => e.statusCode == "1975");
        //let error1976 = person.validations.find(e => e.statusCode == "1976");

        if (error1211) {
          errorSet.add(error1211.msg);
        } else if (error1314) {
          errorSet.add(error1314.msg);
        } else if (error1212) {
          errorSet.add(error1212.msg);
        }
        /*
        if (!document.hasSignedProposal) {
          if (error1975) {
            errorSet.add(error1975.msg);
          }
          if (error1976) {
            errorSet.add(error1976.msg);
          }
        }
          */
      }
    });

    if (errorSet.size > 0) {
      errorSet.forEach(e => {
        _errors.push({
          title: "GFWM Error",
          error: e
        });
      });
    }

    // Check duplicate NRIC
    let listNRICBools = persons.map(person => {
      let id_no = person.id_no;
      if (Util.isEmpty(id_no)) return false;
      let count = _.filter(persons, x => x.id_no === id_no);
      return count.length > 1 ? true : false;
    });
    let isDuplicateNRIC = _.includes(listNRICBools, true);
    if (isDuplicateNRIC) {
      _errors.push({
        title: "Records with duplicate NRIC",
        error: intl.formatMessage({ id: "error.msg.employee.duplicateNRICs" })
      });
    }

    // Check duplicate Email
    let listEmailBools = persons.map(person => {
      let email = person.email;
      if (Util.isEmpty(email)) return false;
      let count = _.filter(persons, x => x.email === email);
      return count.length > 1 ? true : false;
    });
    let isDuplicateEmail = _.includes(listEmailBools, true);
    if (isDuplicateEmail) {
      _errors.push({
        title: "Records with duplicate email",
        error: intl.formatMessage({ id: "error.msg.employee.duplicateEmails" })
      });
    }

    // Check invalid email
    let listResultValidateEmails = persons
      .filter(person => person.type === "E")
      .map(person => {
        return Util.isEmail(person.email);
      });
    let isEmailInvalid = _.includes(listResultValidateEmails, false);
    if (isEmailInvalid) {
      _errors.push({
        title: "Invalid email",
        error: intl.formatMessage({ id: "error.msg.employee.invalidEmail" })
      });
    }

    const listResultValidateEmailsForbiddenDomainPatterns = persons
      .filter(person => person.type === "E")
      .map(person => Util.isEmailForbiddenDomainPatterns(person.email));
    const isEmailForbiddenDomainPatterns = _.includes(listResultValidateEmailsForbiddenDomainPatterns, true);
    if (isEmailForbiddenDomainPatterns) {
      _errors.push({
        title: "Invalid email",
        error: intl.formatMessage({ id: "error.msg.employee.invalidEmailDomain" })
      });
    }

    const listResultValidateEmailsForbiddenUsernamePatterns = persons
      .filter(person => person.type === "E")
      .map(person => Util.isEmailForbiddenUsernamePatterns(person.email));
    const isEmailForbiddenUsernamePatterns = _.includes(listResultValidateEmailsForbiddenUsernamePatterns, true);
    if (isEmailForbiddenUsernamePatterns) {
      _errors.push({
        title: "Invalid email",
        error: intl.formatMessage({ id: "error.msg.employee.invalidEmailUsername" })
      });
    }

    let activationDate = quote.req_effective_date ? moment(quote.req_effective_date, "DD/MM/YYYY").toDate() : null;
    let today = new Date().setHours(0, 0, 0, 0);
    if (activationDate === null) {
      _errors.push({
        title: "Coverage Start Date errors",
        error: intl.formatMessage({ id: "error.msg.activation.date" })
      });
    } else if (activationDate < today) {
      _errors.push({
        title: "Coverage Start Date errors",
        error: intl.formatMessage({
          id: "error.msg.earlier.today.coverage.date"
        })
      });
    }

    if (Util.isEmpty(quote.waiting_period)) {
      _errors.push({
        title: "Please specify a waiting period for your coverage.",
        error: intl.formatMessage({ id: "error.msg.waiting.period" })
      });
    }

    //Merge the errors into a single list for display on error summary
    if (errors !== null) _errors = _errors.concat(!Util.isEmpty(errors) ? errors.slice(0) : []);
    props.setErrors(_errors);

    //Check and set the stage validation status if there are employee errors
    props.setStageValidation(Enums.STAGES.EMPLOYEE, _errors.length === 0);

    if (props.quote.employeeRevalidateFlag) {
      props.employeeFinishRevalidate();
      props.handleEdits();
    }
  }

  render() {
    const { classes, employee, employeeData, handlerRemoveEmployee, emailErrors } = this.props;
    let contentInfoPopover = this.renderInfoPopover();
    return (
      <div className={classes.root}>
        <div className={classes.employeeListingWrapper}>
          <EmployeeTable
            employeeData={employeeData}
            handlerRemoveEmployee={handlerRemoveEmployee}
            errors={employee.errors}
          />
        </div>
        {contentInfoPopover}
      </div>
    );
  }

  handleClickInfoButton = (data, id) => event => {
    let infoPopover = { ...this.state.infoPopover };
    infoPopover.open = !infoPopover.open;
    if (infoPopover.open) {
      infoPopover.element = this.refs[id];
      infoPopover.data = data;
    }
    this.setState({ infoPopover });
  };

  renderInfoPopover = () => {
    const { infoPopover } = this.state;
    return (
      <InfoPopover
        open={infoPopover.open}
        data={infoPopover.data}
        element={infoPopover.element}
        handleClickInfoButton={this.handleClickInfoButton}
      />
    );
  };
}

const styles = theme => ({
  root: {},
  tableToolbarWrapper: {
    display: "flex"
  },
  employeeListInfo: {
    flex: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end"
  },
  downloadWrapperContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  downloadWrapper: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing.unit,
    borderWidth: "2px",
    borderColor: "rgb(150, 150, 150)",
    borderStyle: "dashed",
    borderRadius: "5px"
  },
  downloadIcon: {
    margin: "0 10px"
  },
  employeeListingWrapper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3
  },
  labelActivationDate: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.75rem"
  }
});

function mapStateToProps(state, prop) {
  return {
    user: state.user.user,
    employee: state.quote.employee,
    quote: state.quote,
    document: state.document
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(quoteActions, dispatch),
    ...bindActionCreators(employeeActions, dispatch),
    ...bindActionCreators(validationsActions, dispatch)
  };
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(EmployeeList))
);
