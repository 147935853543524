import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import { withStyles } from "@mui/styles";
import { quoteActions } from "../../../../actions";
import quoteService from "../../../../services/quote.service";
import moment from "moment";
import Enums from "../../../../utils/Enums";
import Util from "../../../../utils/Util";
import Icon from "@mui/material/Icon";

class QuotesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    this.renderPDF(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.id !== nextProps.quote.id) {
      this.renderPDF(nextProps);
    }
  }

  renderPDF = props => {
    const { quote } = props;
    if (quote.has_proposal_file && quote.stages !== Enums.STAGES.ESTIMATE && quote.stages !== Enums.STAGES.EMPLOYEE) {
      this.setState({ pdf: null });
    } else {
      this.setState({ pdf: "-" });
    }
    this.setState({ id: quote.id, name: quote.name });
  };

  /**
   * Depends on statges will convert to status
   * Draft [ESTIMATE , EMPLOYEE]
   * Proposed [PROPOSAL, SUBMISSION]
   * Submitted [SUBMITTED]
   * In force [INFORCE, PROCESSED]
   */
  mappingStatus = stages => {
    let getStatus = this.props.statuses.filter(status => {
      return status.mapTo.indexOf(stages) > -1;
    });
    return getStatus[0].status;
  };

  handleDownloadClick(quote) {
    return e => {
      e.stopPropagation();
      quoteService.getPdf(this.state.id).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }));
        this.setState({ pdf: url });
        this.props.onPdfClick(url, this.state.id, `${this.state.name}_Proposal.pdf`);
      });
    };
  }

  render() {
    const { classes, intl, quote } = this.props;
    let hasProposal =
      quote.has_proposal_file && quote.stages !== Enums.STAGES.ESTIMATE && quote.stages !== Enums.STAGES.EMPLOYEE;

    return (
      <div>
        {hasProposal ? (
          <div onClick={this.handleDownloadClick().bind(this)} className={this.props.classes.downloadLink}>
            <span className={this.props.classes.downloadLinkText}>{this.state.name}_Proposal.pdf</span>
            <Icon className={classes.dlIcon}>file_download</Icon>
          </div>
        ) : (
          "-"
        )}
      </div>
    );
  }
}

const styles = theme => ({
  downloadLink: {
    display: "flex",
    alignItems: "center"
  },
  downloadLinkText: {
    marginRight: theme.spacing.unit
  }
});

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(quoteActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(QuotesTable)));
