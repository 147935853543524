import * as actionTypes from "../actions/types";

const initialState = {
  emailErrors: null
};

export const validations = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EMAIL_VALIDATION:
      return {
        ...state,
        emailErrors: action.payload
      };
    default:
      return state;
  }
};
