import * as actionTypes from "../actions/types";
import config from "../config/config";

const initialState = {
  type: config.app.type,
  build: config.app.build,
  scrollToTop: false,
  showFirstPageDialog: true,
  showRenewalNotification: true,
  showLoadingPopup: false,
  message: ["Please be reminded to renew the policy below, as it will be expiring soon"]
};

export const app = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APP_SET_LOCALE:
      return {
        ...state,
        locale: action.payload
      };
    case actionTypes.APP_SET_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case actionTypes.APP_500_ERROR:
      return {
        ...state,
        error500: action.payload
      };
    case actionTypes.APP_SET_TYPE:
      return {
        ...state,
        type: action.payload
      };
    case actionTypes.APP_SCROLL:
      return {
        ...state,
        scrollToTop: true
      };
    case actionTypes.APP_SCROLL_FIN:
      return {
        ...state,
        scrollToTop: false
      };
    case actionTypes.APP_FIRSTPAGEDIALOG_SET_VISIBILITY:
      return {
        ...state,
        showFirstPageDialog: action.payload
      };
    case actionTypes.APP_RENEWALNOTIFICATION_SET_VISIBILITY:
      return {
        ...state,
        showRenewalNotification: action.payload
      };
    case actionTypes.APP_SET_LOADING_STATE:
      return {
        ...state,
        showLoadingPopup: action.payload
      };
    default: {
      return state;
    }
  }
};
