import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import _ from "lodash";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InfoButton from "../../../../components/InfoButton";
import InfoPopover from "../../../../components/InfoPopover";
import codeplan from "../../../../assets/data/codeplan";

class CategoryPlansTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      infoPopover: {
        open: false,
        element: null,
        data: ""
      }
    };
  }

  handleClickInfoButton = (data, id) => event => {
    let infoPopover = {
      ...this.state.infoPopover
    };
    infoPopover.open = !infoPopover.open;
    if (infoPopover.open) {
      infoPopover.element = this.refs[id];
      infoPopover.data = data;
    }
    this.setState({ infoPopover });
  };

  handleCloseDialog() {
    let infoPopover = {
      ...this.state.infoPopover,
      open: false
    };
    this.setState({ infoPopover });
  }

  renderInfoPopover = () => {
    const { infoPopover } = this.state;

    return (
      <InfoPopover
        open={infoPopover.open}
        data={infoPopover.data}
        element={infoPopover.element}
        handleClickInfoButton={this.handleClickInfoButton}
      />
    );
  };

  getPlansConfiguration() {
    const { data } = this.props;
    let allConfiguration = [];

    //form array with all configuration
    data.forEach(d => {
      allConfiguration.push(d.plansConfiguration);
    });

    //return uniq plan configuration
    return _.uniqBy(_.flatten(allConfiguration), "code");
  }

  getHelpText = code => {
    if (code === "GTL") {
      let data =
        "<ul>" +
        "<li>Employee (16 to 64 age last birthday) selecting Plan 1 of Group Term Life will require underwriting. The employee will be covered and invoiced at Plan 2 sum assured while pending for fulfillment of underwriting.</li>" +
        "<br/><li>Employee (from 65 to 69 age last birthday) selecting Group Term Life will require underwriting. No coverage shall be extended until underwriting has been approved.</li>" +
        "<br/>The status of the coverage will be confirmed via an official letter from Prudential. Please contact HR for more information." +
        "</ul>";
      return data;
    } else if (code === "GCCA") {
      let data =
        "<ul>" +
        "<li>Employee (16 to 64 age last birthday) selecting Plan 1 of Group Term Life will require underwriting. The employee will be covered and invoiced at Plan 2 sum assured while pending for fulfillment of underwriting.</li>" +
        "<br/>The status of the coverage will be confirmed via an official letter from Prudential. Please contact HR for more information." +
        "</ul>";
      return data;
    }
  };

  render() {
    const { classes, data, config } = this.props;
    const allConfigurationCode = this.getPlansConfiguration();

    if (!data) {
      return (
        <div className={classes.root}>
          <div className={classes.content}>
            <Typography color="inherit">No insurance coverage available.</Typography>
          </div>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        {/*Employee Listing*/}
        <Paper className={classes.root}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Category Name</TableCell>
                <TableCell>No. Of Person</TableCell>
                {codeplan[config.code].map((p, idx) => {
                  if (_.find(allConfigurationCode, ["code", p.value]))
                    // render only if exist in planConfiguration
                    return (
                      <TableCell
                        key={idx}
                        className={classNames((p.label === "GTL" || p.label === "GCCA") && classes.cellInfo)}
                      >
                        <div>
                          {p.label === "PGIH" ? "GPRU" : p.label === "PSIH" ? "GOSP" : p.label}
                          {(p.label === "GTL" || p.label === "GCCA") && config.code !== "GCP" && (
                            <InfoButton
                              ref={`btnInfoHeader${idx}`}
                              id={`btnInfoHeader${idx}`}
                              data={this.getHelpText(p.label)}
                              handleClickInfoButton={this.handleClickInfoButton}
                            />
                          )}
                        </div>
                      </TableCell>
                    );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((d, id) => {
                let noOfDependents = d.noOfDependents || 0;
                let noOfEmployees = d.noOfEmployees || 0;
                let empRow = (
                  <TableRow key={id}>
                    <TableCell>{d.name}</TableCell>
                    <TableCell>{d.noOfEmployees}</TableCell>

                    {codeplan[config.code].map((p, idx) => {
                      let plan = d.plansConfiguration.find(_p => _p.code === p.value);

                      let selectedTier = plan && plan.tier ? `Plan ${plan.tier}` : "-";

                      if (plan) {
                        return <TableCell key={idx}>{selectedTier}</TableCell>;
                      } else {
                        if (_.find(allConfigurationCode, ["code", p.value])) {
                          return <TableCell key={idx}> - </TableCell>;
                        }
                      }
                    })}
                  </TableRow>
                );

                let dependantRow = (
                  <TableRow
                    key={"dep" + id}
                    className={classNames(noOfDependents === 0 && classes.trCellDataDim, {
                      hidden: !config.enableDependent
                    })}
                  >
                    <TableCell>Dependants ({d.name})</TableCell>
                    <TableCell>{noOfDependents}</TableCell>

                    {codeplan[config.code].map((p, idx) => {
                      let plan = d.plansConfiguration.find(_p => _p.code === p.value);
                      let selectedTier =
                        plan && plan.enableDependent && plan.tier && noOfDependents !== 0 ? `Plan ${plan.tier}` : "-";
                      if (plan) {
                        return <TableCell key={idx}>{selectedTier}</TableCell>;
                      } else {
                        if (_.find(allConfigurationCode, ["code", p.value])) {
                          return <TableCell key={idx}> - </TableCell>;
                        }
                      }
                    })}
                  </TableRow>
                );

                let grabDependantRow = (
                  <TableRow
                    key={"dep" + id}
                    className={classNames(noOfDependents === 0 && classes.trCellDataDim, {
                      hidden: !config.enableDependent
                    })}
                  >
                    <TableCell>{d.name}</TableCell>
                    <TableCell>{noOfDependents}</TableCell>

                    {codeplan[config.code].map((p, idx) => {
                      let plan = d.plansConfiguration.find(_p => _p.code === p.value);
                      let selectedTier =
                        plan && plan.enableDependent && plan.tier && noOfDependents !== 0 ? `Plan ${plan.tier}` : "-";
                      if (plan) {
                        return <TableCell key={idx}>{selectedTier}</TableCell>;
                      } else {
                        if (_.find(allConfigurationCode, ["code", p.value])) {
                          return <TableCell key={idx}> - </TableCell>;
                        }
                      }
                    })}
                  </TableRow>
                );

                if (config && config.code && config.code === "GCP") {
                  if (noOfEmployees != 0 && noOfDependents != 0) {
                    return [empRow, grabDependantRow];
                  } else if (noOfDependents == 0) {
                    return empRow;
                  } else if (noOfEmployees == 0) {
                    return grabDependantRow;
                  }
                } else {
                  return [empRow, dependantRow];
                }
              })}
            </TableBody>
          </Table>
          {this.renderInfoPopover()}
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto"
  },
  trCellDataDim: {
    opacity: 0.5
  },
  cellInfo: {
    minWidth: 65
  }
});

function mapStateToProps(state) {
  return {
    hr_coverage: state.HR.coverage,
    products: state.products
  };
}

function mapDispatchToProps(dispatch) {
  return {
    //...bindActionCreators(quotesActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(CategoryPlansTable)));
