export default [
  {
    value: "GTLB",
    label: "Group Term Life (GTL)",
    sCode: "GTL",
    type: "Core"
  },
  {
    value: "GHSB",
    label: "Group Hospital & Surgical (GHS)",
    sCode: "GHS",
    type: "Core"
  },
  {
    value: "GFWM",
    label: "Group Foreign Worker Medical Insurance (GFWM)",
    sCode: "GFWM",
    type: "Core"
  },
  {
    value: "GCCA",
    label: "Group Crisis Cover Accelerated (GCCA)",
    sCode: "GCCA",
    type: "Supplementary"
  },
  {
    value: "GEMM",
    label: "Group Extended Major Medical (GEMM)",
    sCode: "GEMM",
    type: "Supplementary"
  },
  {
    value: "GPRU",
    label: "Group Outpatient General Practitioner (GPRU)",
    sCode: "GPRU",
    type: "Supplementary"
  },
  {
    value: "PGIH",
    label: "Group Outpatient General Practitioner (GPRU)",
    sCode: "GPRU",
    type: "Supplementary"
  },
  {
    value: "GOSP",
    label: "Group Outpatient Specialist (GOSP)",
    sCode: "GOSP",
    type: "Supplementary"
  },
  {
    value: "PSIH",
    label: "Group Outpatient Specialist (GOSP)",
    sCode: "GOSP",
    type: "Supplementary"
  },
  {
    value: "GADD",
    label: "Group Accidental Death & Dismemberment (GADD)",
    sCode: "GADD",
    type: "Supplementary"
  },
  {
    value: "GAMR",
    label: "Group Accidental Medical Reimbursement (GAMR)",
    sCode: "GAMR",
    type: "Supplementary"
  },
  {
    value: "GDEN",
    label: "Group Dental (GDEN)",
    sCode: "GDEN",
    type: "Supplementary"
  },
  {
    value: "GSMM",
    label: "Group Extended Major Medical (GEMM)",
    sCode: "GEMM",
    type: "Supplementary"
  },
  {
    value: "PSMH",
    label: "PSMH3",
    sCode: "PSMH3",
    type: "Supplementary"
  },
  {
    value: "GEAC",
    label: "Group Pre Crisis Guard (GPCG)",
    sCode: "GPCG",
    type: "Supplementary"
  },
  {
    value: "GPPD",
    label: "Group Panel Dental (GPPD)",
    sCode: "GPPD",
    type: "Supplementary"
  },
  {
    value: "PDIH",
    label: "Group Panel Dental (GPPD)",
    sCode: "GPPD",
    type: "Supplementary"
  }
];
