import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { withStyles } from "@mui/styles";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import { employeeActions, quoteActions } from "../../../../../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import FormHelperText from "@mui/material/FormHelperText";

class EmployeeDropZone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: []
    };
  }

  onDropAccepted(files) {
    files.forEach(file => {
      this.fileUpload(file);

      //Remove filename checks
      // const errors = Util.invalidFileName([], file);
      // if (errors.length > 0) {
      //   this.props.employeeActions.uploadFailed({
      //     message: errors[0].dialogDescription,
      //     code: 1
      //   });
      // } else {
      //   this.fileUpload(file);
      // }
    });
  }

  fileUpload(file) {
    this.props.employeeActions.upload(this.props.quote.id, file);
  }

  onDropRejected(files) {
    this.props.employeeActions.uploadFailed({
      message: "Only xls or csv files are allowed",
      code: 1
    });
  }

  render() {
    const { classes, quote, errors } = this.props;
    const { disabledClass, root } = classes;
    return (
      <div className={classNames(root, !quote.pdpa_consent && disabledClass)}>
        <div className={classes.errorWrapper}>
          {errors && (
            <FormHelperText id="name-error-text" error className={classes.error}>
              {errors.msg}
            </FormHelperText>
          )}
        </div>

        <Dropzone
          onDropAccepted={this.onDropAccepted.bind(this)}
          onDropRejected={this.onDropRejected.bind(this)}
          className={classes.dropZone}
          disabled={!quote.pdpa_consent}
          multiple={false}
          disabledClassName="disabledClass"
        >
          {({ getRootProps, getInputProps }) => (
            <div
              className={classNames(classes.dropZoneInfoWrapper, !quote.pdpa_consent ? "disabledClass" : "activeClass")}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <CloudUploadIcon
                style={{
                  fontSize: 80
                }}
                className={classes.uploadIcon}
              />
              <Typography className={classes.uploadInfo}>Drop or upload your .xls or csv file here</Typography>
              {this.props.employee.uploading && <LinearProgress />}
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    padding: "30px"
  },
  error: {
    fontSize: "0.75rem"
  },
  errorWrapper: {
    margin: " 0 20%",
    paddingBottom: 10
  },

  disabledClass: {
    color: `${theme.palette.text.disabled} !important`,
    borderColor: `${theme.palette.text.disabled} !important`,
    cursor: "not-allowed"
  },
  activeClass: {
    cursor: "pointer",
    borderColor: "#333333"
  },
  dropZoneInfoWrapper: {
    padding: "50px",
    textAlign: "center",
    border: "5px dotted",
    padding: "10px",
    borderStyle: "dashed",
    borderWidth: "2px",
    borderRadius: "5px"
  },
  uploadIcon: {
    height: "50px",
    width: "50px"
  },
  button: {},
  uploadInfo: {}
});

function mapStateToProps(state, prop) {
  return { employee: state.quote.employee, quote: state.quote };
}

function mapDispatchToProps(dispatch) {
  return {
    employeeActions: bindActionCreators(employeeActions, dispatch),
    quoteActions: bindActionCreators(quoteActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(EmployeeDropZone));
