import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withStyles } from "@mui/styles";
import Enums from "../../../../utils/Enums";
import Plans from "../../../../containers/Auth/components/Plans/indexPF3";
import CategoryPlansTablePF3 from "./CategoryPlansTablePF3";
import InfoPopover from "../../../../components/InfoPopover";
import moment from "moment";
import { bindActionCreators } from "redux";
import { coverageActions } from "../../../../actions";
import { withRouter } from "react-router";

class CoveragePF3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      plans: {
        selectedTypes: [Enums.PLANS_TYPE.BENEFITS]
      },
      categories: null,
      selectedTiers: null,
      extendedBenefits: null,
      infoPopover: {
        open: false,
        element: null,
        data: ""
      }
    };
  }

  componentWillMount() {
    const { coverage } = this.props;
    let categories = coverage.policy.data && coverage.policy.data.categories;
    let selectedTiers = this.getSelectedTiers(categories);
    let extendedBenefits = coverage.policy.data && coverage.policy.data.extendedBenefit;
    this.setState({ categories: categories, selectedTiers: selectedTiers, extendedBenefits: extendedBenefits });
  }

  getSelectedTiers = categories => {
    let selectedTiers = {};
    if (categories) {
      categories.forEach(c => {
        if (c.plansConfiguration) {
          c.plansConfiguration.forEach(p => {
            if (!selectedTiers[p.code]) selectedTiers[p.code] = [];
            selectedTiers[p.code].push(p.tier);
          });
        }
      });
    }
    return selectedTiers;
  };

  getPolicyEffectiveDate = gaInfo => {
    let policyEffectiveDate = "";
    gaInfo.forEach(gaInfo => {
      if (moment().isBetween(gaInfo.cc_date, gaInfo.cr_date)) {
        policyEffectiveDate = gaInfo.cc_date;
      }
    });
    return policyEffectiveDate;
  };

  getPolicyEndDate = gaInfo => {
    let policyEndDate = "";
    gaInfo.forEach(gaInfo => {
      if (moment().isBetween(gaInfo.cc_date, gaInfo.cr_date)) {
        policyEndDate = gaInfo.cr_date;
      }
    });
    return policyEndDate;
  };

  handleClickInfoButton = (data, id) => event => {
    let infoPopover = { ...this.state.infoPopover };
    infoPopover.open = !infoPopover.open;
    if (infoPopover.open) {
      infoPopover.element = this.refs[id];
      infoPopover.data = data;
    }
    this.setState({ infoPopover });
  };

  renderInfoPopover = () => {
    const { infoPopover } = this.state;
    return (
      <InfoPopover
        open={infoPopover.open}
        data={infoPopover.data}
        element={infoPopover.element}
        handleClickInfoButton={this.handleClickInfoButton}
      />
    );
  };

  render() {
    const { classes, coverage, quote, products } = this.props;
    const { plans, categories, selectedTiers, extendedBenefits } = this.state;
    const policyEffectiveDate =
      coverage.gaInfo == undefined || null ? "" : this.getPolicyEffectiveDate(coverage.gaInfo);
    let contentInfoPopover = this.renderInfoPopover();
    console.log("debug this.props.location.state", this.props.location.state);
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.root2}>
            <CategoryPlansTablePF3
              data={categories}
              config={products[this.props.location.state.productCode].config}
              extendedBenefits={extendedBenefits}
            />
          </div>

          <br />
          <Plans
            types={plans.selectedTypes}
            tiers={selectedTiers}
            selectionDisplayMode={"HIGHLIGHT"}
            portalType={"HR"}
            displayAll={true}
            resetTime={quote.resetTime}
            productCode={this.props.location.state.productCode}
            policyEffectiveDate={policyEffectiveDate}
            productVersion={Number(this.props.location.state.productVersion)}
            // policyNo={coverage.policy.policy_no}
          />
        </div>
        {contentInfoPopover}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    marginBottom: theme.actionFooter.height,
    fontSize: "0.75em"
  },
  content: {
    padding: theme.spacing.unit * 2.5
  },
  font: {
    fontWeight: "bold",
    color: "black"
  },
  fontTitle: {
    color: "red"
  },
  root2: {
    backgroundColor: "white",
    padding: theme.spacing.unit * 2.5,
    paddingTop: 10
  },
  fontTopTitle: {
    color: "#6A6B6D"
  }
});

function mapStateToProps(state) {
  return {
    coverage: state.HR.coverage,
    quote: state.quote,
    gaInfo: state.gaInfo,
    products: state.products
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(coverageActions, dispatch)
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles, { withTheme: true })(CoveragePF3)))
);
