import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import classNames from "classnames";
import { withStyles } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import EmployeeEditForm from "./EmployeeEditForm";
import DependentEditContainer from "./DependentEditContainer";
import { getCategories } from "../../../../../../selectors/CommonSelectors";
import AlertTopBar from "../../../../../../components/AlertTopBar";
import ErrorSummary from "../../../../../../components/ErrorSummary";
import Enums from "../../../../../../utils/Enums";
import { employeeActions, quoteActions, validationsActions } from "../../../../../../actions";
import Util from "../../../../../../utils/Util";
import moment from "moment";

const EMPLOYEE_FORMAT_DATE = "DD/MM/YYYY";

const MODE = {
  ADD: "ADD",
  EDIT: "EDIT"
};

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir}>
      {children}
    </Typography>
  );
}

function TabLabelIcon({ children, label, icon, theme }) {
  return (
    <span style={{ display: "flex", alignItems: "center" }}>
      <span>{label}</span>
      {icon && <Icon style={{ paddingLeft: "5px", color: "#CC0000" }}>{icon}</Icon>}
    </span>
  );
}

class EmployeeEditDialog extends Component {
  constructor(props) {
    super(props);

    this.isHRApp = props.app.type === Enums.APP_TYPE.HR;
    this.isSalesApp = props.app.type === Enums.APP_TYPE.SALES;

    this.formsSubmitted = null;

    this.employeeForm = null;
    this.dependentForm = null;

    this.validationRules = this.getValidationRules();

    this.state = {
      value: 0, // first tab : Employee Detail
      employeeData: null,
      dependentData: [],
      employeeErrors: false,
      dependentErrors: false,
      isFormDirty: false
    };
  }

  componentWillMount() {
    let dependents = this.props.getDependentRecords(this.props.selectedRecord);
    this.setState({ dependentData: dependents });
    this.setState({ employeeData: this.props.selectedRecord });
  }

  getValidationRules = () => {
    return {
      isDuplicatedIdNo: (type, value, data, index = null) => {
        //Complicated piece of validation against a list of employee and dependents which are different structured between Sales and HR
        if (Util.isEmpty(value)) return true;

        let isDuplicated = false;
        if (this.isSalesApp) {
          const _persons = this.props.quote.data.persons;
          if (type === "employee") {
            isDuplicated = !!_persons.find(p => {
              return p.type === "E"
                ? _persons.indexOf(p) !== this.props.selectedIndex && Util.caseInsensitiveEqual(p.id_no, value)
                : p.dependency_id_no !== data.dependency_id_no && Util.caseInsensitiveEqual(p.id_no, value);
            });
            if (isDuplicated) return false;
          }

          if (type === "dependent") {
            isDuplicated = !!_persons.find(p => {
              !(
                Util.caseInsensitiveEqual(p.id_no, data.dependency_id_no) ||
                Util.caseInsensitiveEqual(p.dependency_id_no, data.dependency_id_no)
              )
                ? Util.caseInsensitiveEqual(p.id_no, value)
                : false;
            });
            if (isDuplicated) return false;
          }
        } else if (this.isHRApp) {
          const _employee = this.props.myEmployee.data;
          isDuplicated = !!_employee.find(p => {
            let exists = false;
            if (_employee.indexOf(p) !== this.props.selectedIndex) {
              //Check against the rest of the employees and dependents
              exists = Util.caseInsensitiveEqual(p.id_no, value);
              if (exists) return exists;

              //Check against the rest of the employees and dependents
              exists = !!p.dependents.find(d => {
                return Util.caseInsensitiveEqual(d.id_no, value);
              });
              if (exists) return exists;
            }
            return exists;
          });
          if (isDuplicated) return false;
        }

        //If no duplicates are found from the employee and dependents list, check against the current form state for employee and dependents
        if (type === "dependent") {
          //Check against the employee state
          if (this.employeeForm.state) {
            isDuplicated = Util.caseInsensitiveEqual(this.employeeForm.state.id_no, value);
          }
          if (isDuplicated) return false;
        }

        //Check against the updated dependents within the state
        if (this.dependentForm?.state.dependents) {
          isDuplicated = !!this.dependentForm.state.dependents.find((d, idx) => {
            return type === "dependent"
              ? idx !== index
                ? Util.caseInsensitiveEqual(d.id_no, value)
                : false
              : Util.caseInsensitiveEqual(d.id_no, value);
          });
          if (isDuplicated) return false;
        }
        return true;
      },

      isDependentsCoverageDateValid: () => {
        if (this.isHRApp) {
          if (this.employeeForm !== null) {
            let employeeStartDate = this.employeeForm.state.date_of_employment;
            if (employeeStartDate === null) {
              return true;
            }
            let dependentsArray = this.dependentForm.state.dependents;
            let anyAdult = dependentsArray.some(
              dependent =>
                dependent.coverage_start_date != null &&
                moment(employeeStartDate, EMPLOYEE_FORMAT_DATE)
                  .toDate()
                  .valueOf() >
                  moment(dependent.coverage_start_date, EMPLOYEE_FORMAT_DATE)
                    .toDate()
                    .valueOf()
            );
            return !anyAdult;
          } else {
            return true;
          }
        }
        return true;
      },

      isNRICValid: (value, index) => {
        if (this.isHRApp) {
          if (this.dependentForm !== null) {
            let validatingDependant = this.dependentForm.state.dependents[index];
            if (validatingDependant.nationality === "SGP") {
              if (Util.isNRICValid(value)) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          }
        }
        return true;
      },

      isDobTally: (value, index) => {
        if (this.isHRApp) {
          if (this.dependentForm !== null) {
            let validatingDependant = this.dependentForm.state.dependents[index];
            if (validatingDependant.nationality === "SGP" && validatingDependant.id_no != "") {
              if (value !== null) {
                let dob = moment(value).format(EMPLOYEE_FORMAT_DATE);
                if (dob.slice(6, 10) >= 1968) {
                  if (validatingDependant.id_no.slice(0, 1) == "S") {
                    var slicedId = validatingDependant.id_no.slice(1, 3);
                    var slicedDob = dob.slice(6, 10);
                    var combinedDob = "19".concat(slicedId);
                    if (slicedDob == combinedDob) {
                      return true;
                    }
                  } else if (validatingDependant.id_no.slice(0, 1) == "T") {
                    var slicedId = validatingDependant.id_no.slice(1, 3);
                    var slicedDob = dob.slice(6, 10);
                    var combinedDob = "20".concat(slicedId);
                    if (slicedDob == combinedDob) {
                      return true;
                    }
                  }
                } else {
                  // rule doesnt apply if the person is born before 1968
                  return true;
                }
              }
              return false;
            }
          }
        }
        return true;
      },

      isDependantMaxAgeCoverValid: (value, index) => {
        if (this.isHRApp) {
          let dob = moment(value, EMPLOYEE_FORMAT_DATE);

          if (this.dependentForm !== null) {
            let validatingDependant = this.dependentForm.state.dependents[index];
            let coverageStartDate = moment(validatingDependant.coverage_start_date, EMPLOYEE_FORMAT_DATE);
            if (validatingDependant.type == "S") {
              // substracting 30 minutes as Singapore was GMT+0730 before 31st December 1982
              // while the timezone now is GMT+0800
              if (
                moment
                  .duration(coverageStartDate.diff(dob))
                  .subtract(30, "minutes")
                  .asYears() < 70
              ) {
                return { validated: true, errorMessage: "" };
              } else {
                return {
                  validated: false,
                  errorMessage: this.props.intl.formatMessage({ id: "validator.exceedMaxACoverageAge.spouse" })
                };
              }
            } else if (validatingDependant.type == "C") {
              if (moment.duration(coverageStartDate.diff(dob)).asYears() < 26) {
                return { validated: true, errorMessage: "" };
              } else {
                return {
                  validated: false,
                  errorMessage: this.props.intl.formatMessage({ id: "validator.exceedMaxACoverageAge.child" })
                };
              }
            }
          }
        }
        return { validated: true, errorMessage: "" };
      },

      // complex piece on validating dependant coverage date extended business rules
      isDependentsCoverageDateValidRefined: (type, value, index) => {
        if (this.isHRApp) {
          let policyStartDate = moment(this.props.coverage.policy.activation_date, EMPLOYEE_FORMAT_DATE);
          let coverageStartDate = moment(value, EMPLOYEE_FORMAT_DATE);
          let dateHrSendInstruction = moment();

          if (this.dependentForm !== null && this.employeeForm !== null) {
            let validatingDependant = this.dependentForm.state.dependents[index];
            let employeeCoverageDate = this.employeeForm.state.coverage_start_date;
            let childDob = validatingDependant.dob;

            if (validatingDependant.type == "S") {
              if (this.props.mode == MODE.EDIT) {
                if (coverageStartDate.isSameOrAfter(policyStartDate)) {
                  if (moment.duration(dateHrSendInstruction.diff(coverageStartDate)).asDays() <= 31) {
                    return { validated: true, errorMessage: "" };
                  } else {
                    // it can be in the future date but it could not be more than 1 month before
                    if (coverageStartDate.isSameOrAfter(dateHrSendInstruction, "day")) {
                      return { validated: true, errorMessage: "" };
                    } else {
                      return {
                        validated: false,
                        errorMessage: this.props.intl.formatMessage(
                          { id: "validator.invalidCoverageDate.lateNotification.spouse" },
                          { currentDate: moment(dateHrSendInstruction).format(EMPLOYEE_FORMAT_DATE) }
                        )
                      };
                    }
                  }
                } else {
                  return {
                    validated: false,
                    errorMessage: this.props.intl.formatMessage({
                      id: "validator.invalidCoverageDate.earlierThanPolicyStartDate"
                    })
                  };
                }
              } else if (this.props.mode == MODE.ADD) {
                if (coverageStartDate.isSameOrAfter(policyStartDate)) {
                  if (employeeCoverageDate !== null) {
                    //second validation
                    if (coverageStartDate.isSame(moment(employeeCoverageDate, EMPLOYEE_FORMAT_DATE))) {
                      return { validated: true, errorMessage: "" };
                    } else {
                      return {
                        validated: false,
                        errorMessage: this.props.intl.formatMessage({
                          id: "validator.invalidCoverageDate.sameAsEmployee.spouse"
                        })
                      };
                    }
                  }
                } else {
                  return {
                    validated: false,
                    errorMessage: this.props.intl.formatMessage({
                      id: "validator.invalidCoverageDate.earlierThanPolicyStartDate"
                    })
                  };
                }
              }
              return true;
            } else if (validatingDependant.type == "C") {
              if (this.props.mode == MODE.EDIT) {
                if (coverageStartDate.isSameOrAfter(policyStartDate)) {
                  if (childDob !== null) {
                    let momentDob = moment(childDob, EMPLOYEE_FORMAT_DATE);
                    let diffInDays = moment
                      .duration(moment(dateHrSendInstruction, EMPLOYEE_FORMAT_DATE).diff(momentDob))
                      .asDays();
                    let dobIncremented = momentDob.add(14, "days");
                    // within 31 days + 15 days old (14) first day born is day 0
                    if (diffInDays <= 45) {
                      if (coverageStartDate.isSame(dobIncremented)) {
                        return { validated: true, errorMessage: "" };
                      } else {
                        return {
                          validated: false,
                          errorMessage: this.props.intl.formatMessage(
                            { id: "validator.invalidCoverageDate.intendedDate.child" },
                            { incrementedDob: dobIncremented.format(EMPLOYEE_FORMAT_DATE) }
                          )
                        };
                      }
                    } else {
                      if (coverageStartDate.isSameOrAfter(dateHrSendInstruction, "day")) {
                        return { validated: true, errorMessage: "" };
                      } else {
                        return {
                          validated: false,
                          errorMessage: this.props.intl.formatMessage(
                            { id: "validator.invalidCoverageDate.lateNotification.child" },
                            { coverageStartDate: moment().format(EMPLOYEE_FORMAT_DATE) }
                          )
                        };
                      }
                    }
                  }
                } else {
                  return {
                    validated: false,
                    errorMessage: this.props.intl.formatMessage({
                      id: "validator.invalidCoverageDate.earlierThanPolicyStartDate"
                    })
                  };
                }
              } else if (this.props.mode == MODE.ADD) {
                // initial validation
                if (coverageStartDate.isSameOrAfter(policyStartDate)) {
                  if (employeeCoverageDate !== null && childDob !== null) {
                    // second validation
                    let incrementedDob = moment(childDob, EMPLOYEE_FORMAT_DATE).add(14, "days");
                    if (moment(employeeCoverageDate, EMPLOYEE_FORMAT_DATE).isSameOrBefore(incrementedDob)) {
                      if (coverageStartDate.isSame(incrementedDob)) {
                        return { validated: true, errorMessage: "" };
                      } else {
                        return {
                          validated: false,
                          errorMessage: this.props.intl.formatMessage(
                            { id: "validator.invalidCoverageDate.intendedDate.child" },
                            { incrementedDob: incrementedDob.format(EMPLOYEE_FORMAT_DATE) }
                          )
                        };
                      }
                    } else {
                      // if the child is born after the employee coverage date the child coverage date needs to be the same
                      if (coverageStartDate.isSame(moment(employeeCoverageDate, EMPLOYEE_FORMAT_DATE))) {
                        return { validated: true, errorMessage: "" };
                      } else {
                        return {
                          validated: false,
                          errorMessage: this.props.intl.formatMessage({
                            id: "validator.invalidCoverageDate.sameAsEmployee.child"
                          })
                        };
                      }
                    }
                  }
                } else {
                  return {
                    validated: false,
                    errorMessage: this.props.intl.formatMessage({
                      id: "validator.invalidCoverageDate.earlierThanPolicyStartDate"
                    })
                  };
                }
              }
              return { validated: true, errorMessage: "" };
            }
          }
        }
        return { validated: true, errorMessage: "" };
      },

      isTerminationDateValid: (value, index) => {
        if (this.dependentForm !== null) {
          let validatingDependant = this.dependentForm.state.dependents[index];
          let coverageEndDate = moment(value, EMPLOYEE_FORMAT_DATE);
          let dateHrSendInstruction = moment(moment(), EMPLOYEE_FORMAT_DATE);
          let coverageStartDate = moment(validatingDependant.coverage_start_date, EMPLOYEE_FORMAT_DATE);
          let diffInDays = moment.duration(dateHrSendInstruction.diff(coverageEndDate)).asDays();
          if (coverageEndDate.isSameOrAfter(coverageStartDate, "day")) {
            if (diffInDays <= 31) {
              return { validated: true, errorMessage: "" };
            } else {
              if (coverageEndDate.isSameOrAfter(dateHrSendInstruction, "day")) {
                return true;
              } else {
                let errorMessage =
                  "Late notification of coverage end date. Thus, coverage end date should be on " +
                  dateHrSendInstruction.format(EMPLOYEE_FORMAT_DATE) +
                  " or later";
                return { validated: false, errorMessage: errorMessage };
              }
            }
          } else {
            return { validated: false, errorMessage: "Coverage end date cannot be earlier than coverage start date" };
          }
        }
        return false;
      }
    };
  };

  handleEmployeeDataChange = (name, value) => {
    const { handleEmployeeDataChange } = this.props;
    if (name === "category") {
      if (handleEmployeeDataChange) {
        this.props.handleEmployeeDataChange(name, value);
      }
      this.setState({
        employeeData: { ...this.state.employeeData, category: value }
      });
    }
  };

  handleTabChange = (event, value) => {
    console.log(value);
    this.setState({ value });
  };

  handleTabChangeIndex = index => {
    this.setState({ value: index });
  };

  handleClickSave = () => {
    this.formsSubmitted = null;
    this.setState({ dependentErrors: false, employeeErrors: false }, () => {
      this.employeeForm.validateAndSubmit();
      this.dependentForm.validateAndSubmit();
    });
  };

  handleFormErrors = (type, errors) => {
    let hasErrors = false;
    if (type === "dependent" && this.dependentForm) {
      hasErrors =
        this.dependentForm.refs.form.errors.length > 0 || this.dependentForm.state.validationErrors.length > 0;
    } else if (type === "employee" && this.employeeForm) {
      hasErrors = this.employeeForm.refs.form.errors.length > 0 || this.employeeForm.state.validationErrors.length > 0;
    }
    this.setState({ [`${type}Errors`]: hasErrors });
  };

  handleSubmitCompleted = (type, data) => {
    const { mode, app } = this.props;
    this.formsSubmitted = this.formsSubmitted || {};
    this.formsSubmitted[type] = data;

    let employee = this.formsSubmitted.employee;
    let dependents = this.formsSubmitted.dependents;

    let hasErrors = this.state.dependentErrors || this.state.employeeErrors;

    if (employee && dependents && !hasErrors) {
      this.props.handleSubmitCompleted(mode, employee, dependents);
    }
  };

  handleDirtyForm = hasFormChanged => {
    this.setState({ isFormDirty: !hasFormChanged });
  };

  closeDialog = e => {
    e.preventDefault();
    this.props.handleClose();
  };

  triggerEmailValidationAPI = () => {
    const { quote } = this.props;
    const emails = quote.data.persons.map(person => person.email).filter(email => email !== "");
    this.props.emailValidations(emails);
  };

  render() {
    const {
      mode,
      classes,
      theme,
      readOnly,
      tips,
      selectedIndex,
      categories,
      systemEmailExist,
      handleSubmitCompleted,
      handleDependentTermination,
      intl,
      products,
      quote,
      isRenewalSubmitted
    } = this.props;
    const { value, employeeData, dependentData, employeeErrors, dependentErrors } = this.state;
    // const noOfDependents = (this.dependentForm && this.dependentForm.state.dependents.length) || 0;
    const noOfDependents = (employeeData && employeeData.no_of_dependent) || 0;
    const systemEmailExistError = { title: "Email Duplicated Error:", msg: "This email has already been used." };
    if (this.dependentForm && this.props.systemEmailExist) {
      if (this.dependentForm.state.validationErrors.length > 0) {
        for (var i = 0; i < this.dependentForm.state.validationErrors.length; i++) {
          if (this.dependentForm.state.validationErrors[i].msg == "This email has already been used.") {
            break;
          } else {
            if (i == this.dependentForm.state.validationErrors.length - 1) {
              this.dependentForm.state.validationErrors.push(systemEmailExistError);
              break;
            }
          }
        }
      } else {
        this.dependentForm.state.validationErrors.push(systemEmailExistError);
      }
    }
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
        fullScreen
        disableBackdropClick
        disableEscapeKeyDown
      >
        <AppBar className={classes.bar}>
          <Toolbar classes={{ root: classes.toolbar }}>
            <div className={classes.toolbarContent}>
              <IconButton
                color="inherit"
                onClick={() => {
                  this.triggerEmailValidationAPI();
                  this.props.handleClose();
                }}
                aria-label="Close"
              >
                <i className="material-icons">close</i>
              </IconButton>
              <Typography variant="title" color="inherit" className={classes.flex}>
                {mode === "ADD" ? "Add Employee" : "Edit Employee"}
              </Typography>
            </div>
            <Button
              className={classes.saveBtn}
              color="inherit"
              onClick={this.handleClickSave}
              disabled={readOnly || !this.state.isFormDirty || isRenewalSubmitted}
            >
              Save
            </Button>
          </Toolbar>
        </AppBar>

        {!Util.isEmpty(tips) && (
          <div className={classes.boxHeader}>
            {tips.map((tip, id) => {
              if (tip.multiTip) {
                return <AlertTopBar key={id} type="tip" title={tip.title} listDescription={tip.content} />;
              } else {
                return <AlertTopBar key={id} type="tip" title={tip.title} description={tip.content} />;
              }
            })}
          </div>
        )}
        {this.dependentForm && (
          <div className={classes.boxHeader}>
            <ErrorSummary errors={this.dependentForm.state.validationErrors} />
          </div>
        )}
        <TabContext value={value}>
          <DialogTitle id="alert-dialog-title">
            <TabList
              // value={this.state.value}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab icon={<TabLabelIcon label="Employee Details" icon={employeeErrors ? "error" : null} />} />
              <Tab
                className={classNames({
                  hidden: !products[quote.product_code].config.enableDependent
                })}
                icon={<TabLabelIcon label={`Dependants (${noOfDependents})`} icon={dependentErrors ? "error" : null} />}
              />
            </TabList>
          </DialogTitle>
          <DialogContent classes={{ root: classes.dialogContent }}>
            <DialogContentText />
            <div style={{ display: value === 0 ? "block" : "none" }}>
              <TabContainer dir={theme.direction}>
                <EmployeeEditForm
                  innerRef={ref => (this.employeeForm = ref)}
                  readOnly={readOnly}
                  onSubmitCompleted={this.handleSubmitCompleted}
                  handleFormErrors={this.handleFormErrors.bind(this)}
                  onChange={this.handleEmployeeDataChange}
                  validationRules={this.validationRules}
                  employeeData={employeeData}
                  selectedIndex={selectedIndex}
                  categories={categories}
                  mode={mode}
                  systemEmailExist={systemEmailExist}
                  isFormDirty={this.state.isFormDirty}
                  handleDirtyForm={this.handleDirtyForm}
                />
              </TabContainer>
            </div>
            <div style={{ display: value === 1 ? "block" : "none" }}>
              <TabContainer dir={theme.direction}>
                <DependentEditContainer
                  innerRef={ref => (this.dependentForm = ref)}
                  readOnly={readOnly}
                  onSubmitCompleted={this.handleSubmitCompleted}
                  handleFormErrors={this.handleFormErrors.bind(this)}
                  handleDependentTermination={handleDependentTermination}
                  validationRules={this.validationRules}
                  dependentData={dependentData}
                  employeeData={employeeData}
                  categories={categories}
                  mode={mode}
                  show={value === 1 ? true : false}
                  isFormDirty={this.state.isFormDirty}
                  handleDirtyForm={this.handleDirtyForm}
                />
              </TabContainer>
            </div>
          </DialogContent>
        </TabContext>
      </Dialog>
    );
  }
}

const styles = theme => ({
  dialog: {
    width: "100%"
  },
  bar: {
    position: "relative"
  },
  boxHeader: {
    marginTop: 10,
    marginLeft: 20,
    marginRight: 20,
    padding: 10,
    paddingBottom: 0
  },
  toolbar: {
    justifyContent: "space-between"
  },
  toolbarContent: {
    display: "flex",
    alignItems: "center"
  },
  dialogContent: {
    padding: "30px 30px !important"
  },
  saveBtn: {
    right: 20,
    fontSize: 20
  },
  link: {
    color: theme.colors.secondary.blue50
  }
});

function mapStateToProps(state, prop) {
  if (state.app.type === Enums.APP_TYPE.SALES) {
    return {
      app: state.app,
      quote: state.quote,
      products: state.products
    };
  } else if (state.app.type === Enums.APP_TYPE.HR) {
    return {
      app: state.app,
      quote: state.quote,
      products: state.products,
      myEmployee: state.HR.myemployee,
      coverage: state.HR.coverage
    };
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(employeeActions, dispatch),
    ...bindActionCreators(quoteActions, dispatch),
    ...bindActionCreators(validationsActions, dispatch)
  };
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles, { withTheme: true })(EmployeeEditDialog)))
);
