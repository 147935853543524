import * as actionTypes from "./types";

import CompanyService from "../services/company.service";

export const companyActions = {
  setCompanyFormDetails,
  getCompanyDetail,
  putCompanyDetail,
  postCompanyDetail,
  setCompanyDetailsFromCache
};

function getCompanyDetail(quoteId) {
  return (dispatch, getState) => {
    return dispatch({
      type: actionTypes.COMPANY_DETAIL_GET,
      payload: CompanyService.getCompanyDetail(quoteId)
    });
  };
}

function putCompanyDetail(payload) {
  return {
    type: actionTypes.COMPANY_DETAIL_PUT,
    payload: CompanyService.putCompanyDetail(payload)
  };
}

function postCompanyDetail(payload) {
  return {
    type: actionTypes.COMPANY_DETAIL_POST,
    payload: CompanyService.postCompanyDetail(payload)
  };
}

function setCompanyFormDetails(payload) {
  return dispatch => {
    dispatch({ type: actionTypes.COMPANY_SET_FORM_DETAILS, payload: payload });
  };
}

function setCompanyDetailsFromCache(payload) {
  return dispatch => {
    dispatch({ type: actionTypes.SET_CACHE_TO_COMPANY_DETAIL, payload: payload });
  };
}
