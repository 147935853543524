import * as actionTypes from "./types";

import otpService from "../services/otp.service";

export const otpActions = {
  getNotificationMethods,
  setOtpSelectedMethod,
  setPreviousOtpMethod,
  sendOtp
};

function getNotificationMethods() {
  return dispatch => {
    return otpService.getNotificationMethods().then(
      response => {
        if (response.success) {
          dispatch({
            type: actionTypes.GET_OTP_NOTIFICATION_METHODS_SUCCESS,
            payload: response.data
          });
        }
        return response;
      },
      error => {
        dispatch({
          type: actionTypes.GET_OTP_NOTIFICATION_METHODS_FAIL,
          payload: error
        });
        return null;
      }
    );
  };
}

function sendOtp(data, txId, regenerateOtp) {
  console.log("otpActions received regenerateOtp:", regenerateOtp);
  return dispatch => {
    return otpService.sendOtp(data, txId, regenerateOtp).then(
      response => {
        if (response.success) {
          dispatch({
            type: actionTypes.SEND_OTP_SUCCESS,
            payload: data
          });
        }
        return response; // Resolve the promise with the response
      },
      error => {
        console.error("Error occurred:", error); // Log the error

        if (data === "EMAIL") {
          dispatch({
            type: actionTypes.SEND_OTP_FAIL_EMAIL,
            payload:
              "An error occurred while sending the OTP to your email address. Please try again or select another verification method by clicking <Try Another Way>."
          });
        } else if (data === "SMS") {
          dispatch({
            type: actionTypes.SEND_OTP_FAIL_SMS,
            payload:
              "An error occurred while sending the OTP to your mobile phone. Please try again or select another verification method by clicking <Try Another Way>."
          });
        } else {
          dispatch({
            type: actionTypes.SEND_OTP_FAIL,
            payload: error?.message || "An unknown error occurred"
          });
        }

        return null; // Resolve with null or an appropriate fallback value
      }
    );
  };
}

function setOtpSelectedMethod(otpMethod) {
  return dispatch => {
    dispatch({
      type: actionTypes.SELECT_OTP_METHOD,
      payload: otpMethod
    });
  };
}

function setPreviousOtpMethod(otpMethod) {
  return dispatch => {
    dispatch({
      type: actionTypes.SELECT_PREVIOUS_OTP_METHOD,
      payload: otpMethod
    });
  };
}
