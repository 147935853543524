import * as actionTypes from "../types";
import CoverageService from "../../services/HR/coverage.service";

export const coverageActions = {
  getPolicy,
  getGaPolicyInfo
};

function getPolicy(policyNumber, companyId) {
  return dispatch => {
    return CoverageService.getPolicy(policyNumber, companyId).then(
      resp => {
        if (resp.success) {
          dispatch({ type: actionTypes.HR_GET_POLICY_SUCCESS, payload: resp.data });
          return Promise.resolve(resp);
        }
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function getGaPolicyInfo(policyNo) {
  return dispatch => {
    return CoverageService.getGaPolicyInfo(policyNo).then(
      response => {
        if (response.success) {
          dispatch({
            type: actionTypes.HR_GET_GA_POLICY_FULFILLED,
            payload: response.data
          });
        }
        return Promise.resolve(response);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}
