import * as actionTypes from "../actions/types";

const initialState = [];

export const quotes = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.QUOTES_GET_LIST_FULFILLED:
      let response = action.payload;
      return response.data;
    default:
      return state;
  }
};
