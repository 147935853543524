import * as actionTypes from "../../actions/types";

const initialState = {
  policy: null
};

export const coverage = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.HR_GET_POLICY_SUCCESS:
      return {
        ...state,
        policy: action.payload
      };
    case actionTypes.HR_GET_GA_POLICY_FULFILLED:
      return {
        ...state,
        gaInfo: action.payload
      };
    default: {
      return state;
    }
  }
};
