import * as actionTypes from "../actions/types";

const initialState = {
  currentPolicyNo: null,
  policy: {},
  relatedDocuments: []
};

export const policyDetails = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.POLICY_DETAILS_SET_CURRENT_POLICY_NO:
      let policyNo = action.payload;
      return {
        ...state,
        currentPolicyNo: policyNo
      };
    case actionTypes.POLICY_DETAILS_GET_POLICY:
      return {
        ...state,
        policy: action.payload
      };
    case actionTypes.POLICY_GET_ALL_RELATED_DOCUMENTS:
      return {
        ...state,
        relatedDocuments: action.payload
      };
    case actionTypes.POLICY_DETAILS_REMOVE_POLICY:
      return initialState;
    default: {
      return state;
    }
  }
};
