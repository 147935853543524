import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { UploadDocumentService } from "../../../../services/HR/uploadDocument.service";
import { uploadDocumentActions } from "../../../../actions/HR/uploaddocument.action";
import DownloadIcon from "@mui/icons-material/Download";
import { makeStyles } from "@mui/styles";

const downloadFile = (policyNo, docID, fileName) => {
  uploadDocumentActions.downloadFile(policyNo, docID, fileName);
};

export default function UploadHistory() {
  const classes = useStyles();
  const selector = useSelector(state => state);
  const dispatch = useDispatch();
  const [data, setData] = React.useState({
    total_records: 0,
    current_page: 0,
    items: []
  });
  const customHeader = () => null;
  const columns = [
    { field: "email", headerName: "Uploaded By", minWidth: 300 },
    { field: "company_name", headerName: "Company Name", minWidth: 300 },
    { field: "policy_no", headerName: "Reference/Policy No.", minWidth: 200 },
    { field: "upload_date", headerName: "Date and Time", minWidth: 200 },
    {
      field: "data_file_name",
      minWidth: 500,
      headerName: "Uploaded Document"
      // renderCell: params => {
      //   const url = `${params.row.policy_no}/${params.row.data_file_id}`;
      //   return (
      //     <a
      //       onClick={() => downloadFile(params.row.policy_no, params.row.data_file_id, params.row.data_file_name)}
      //       className={classes.downloadLink}
      //     >
      //       {params.value} <DownloadIcon className={classes.downloadIcon} />
      //     </a>
      //   );
      // }
    },
    {
      headerName: "",
      minWidth: 200,
      sortable: false,
      disableColumnMenu: true,
      headerComponent: customHeader,
      renderCell: params => {
        const url = `${params.row.policy_no}/${params.row.data_file_id}`;
        return (
          <a
            onClick={() => downloadFile(params.row.policy_no, params.row.data_file_id, params.row.data_file_name)}
            className={classes.downloadLink}
          >
            {params.value} <DownloadIcon className={classes.downloadIcon} />
          </a>
        );
      }
    }
  ];

  React.useEffect(() => {
    const fetchData = async () => {
      const policyNos = selector.HR.clientsAndPolicyListing.clients
        .map(client => client.policy_no)
        .reduce((acc, currVal) => {
          // remove duplicate policy number
          if (!acc.includes(currVal)) {
            acc.push(currVal);
          }
          return acc;
        }, [])
        .join(",");
      let result = await UploadDocumentService.getDocuments(policyNos);
      setData(result.data.items);
    };

    fetchData();
  }, []);
  return (
    <div className={classes.dataGridContainer}>
      <DataGrid
        rows={data}
        columns={columns}
        getRowId={row => row.data_file_id}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 }
          }
        }}
        pageSizeOptions={[5, 10]}
      />
    </div>
  );
}

const useStyles = makeStyles({
  dataGridContainer: { height: 600, width: "100%" },
  downloadLink: { cursor: "pointer", display: "flex", alignItems: "center", height: "100%" },
  downloadIcon: { color: "#b3b3b3", marginLeft: "80px" }
});
