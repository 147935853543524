import React, { Component } from "react";
import { Box, Typography } from "@mui/material";
import { withRouter } from "react-router";
import { withStyles } from "@mui/styles";
import moment from "moment";

class PolicyCard extends Component {
  goTo = () => {
    const { company, policyNumber, companyId, productCode } = this.props.location.state;
    this.props.history.push("/auth/insuranceCoverage/detail", { company, policyNumber, companyId, productCode });
  };

  getPolicyEffectiveDate = gaInfo => {
    let policyEffectiveDate = moment(gaInfo[0].cc_date, "YYYYMMDD");

    const ccDate = moment(gaInfo[0].cc_date, "YYYYMMDD");
    const crDate = moment(gaInfo[0].cr_date, "YYYYMMDD");

    if (moment().isBetween(ccDate, crDate)) {
      policyEffectiveDate = ccDate; // Set to current matching period's start date
    } else if (ccDate.isAfter(moment(policyEffectiveDate, "D MMM YYYY"))) {
      policyEffectiveDate = ccDate; // Update to latest start date if current date is not any within period
    }

    return policyEffectiveDate.format("D MMM YYYY");
  };

  getPolicyEndDate = gaInfo => {
    let policyEndDate = moment(gaInfo[0].cr_date, "YYYYMMDD");

    const ccDate = moment(gaInfo[0].cc_date, "YYYYMMDD");
    const crDate = moment(gaInfo[0].cr_date, "YYYYMMDD");

    if (moment().isBetween(ccDate, crDate)) {
      policyEndDate = crDate; // Set to current matching period's end date
    } else if (crDate.isAfter(moment(policyEndDate, "D MMM YYYY"))) {
      policyEndDate = crDate; // Update to latest end date if current date is not any within period
    }

    return policyEndDate.format("D MMM YYYY");
  };

  render() {
    const { classes, isShowViewCoverageLink = false, gaInfo } = this.props;
    const policyEffectiveDate = gaInfo == undefined || null ? "" : this.getPolicyEffectiveDate(gaInfo);
    const policyEndDate = gaInfo == undefined || null ? "" : this.getPolicyEndDate(gaInfo);
    const { company, policyNumber } = this.props.location.state;

    return (
      <Box className={classes.root}>
        <Typography variant="body1" component="div" display="flex">
          <strong className={classes.text}>Company Name</strong>
          <div className={classes.subText}>{company}</div>
        </Typography>
        <Typography variant="body1" component="div" mt={2} display="flex">
          <strong className={classes.text}>Policy Number</strong>
          <div className={classes.subText}>{policyNumber}</div>
        </Typography>
        <Typography variant="body1" component="div" mt={2} mb={2} display="flex">
          <strong className={classes.text}>Policy Period</strong>
          <div className={classes.subText}>
            {policyEffectiveDate} to {policyEndDate}
          </div>
        </Typography>
        {isShowViewCoverageLink && (
          <div className={classes.viewCoverage} onClick={this.goTo}>
            View Coverage
          </div>
        )}
      </Box>
    );
  }
}

const styles = theme => ({
  root: {
    border: "1px solid #e0e0e0",
    padding: "16px",
    backgroundColor: "white",
    width: "60%"
  },
  text: {
    flex: 1
  },
  subText: {
    flex: 2
  },
  viewCoverage: {
    cursor: "pointer",
    color: "red"
  }
});

export default withRouter(withStyles(styles, { withTheme: true })(PolicyCard));
