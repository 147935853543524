import * as actionTypes from "../../actions/types";
import Enums from "../../utils/Enums";

const initialState = {
  data: [],
  empDependants: [],
  totalEmployees: 0,
  selectedIds: [],
  defaultSortField: "full_name",
  showInviteSuccess: false,
  showInviteError: false,
  showEmployeeSuccess: false,
  showLoading: false,
  categories: []
};

export const myemployee = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.HR_MY_EMPLOYEE_LOAD:
      //sort default
      let data = action.payload && action.payload.items ? action.payload.items : [];
      //assign activation_date to coverage_start_date
      data.forEach(employee => {
        if (!employee["coverage_start_date"]) {
          employee["coverage_start_date"] = employee["activation_date"];
        }
      });

      //re filter dependence
      let employeeList = data.filter(d => d.member_type === "MEMBER");
      let dependentList = data.filter(d => d.member_type !== "MEMBER");

      //assign dependent to employee
      employeeList.forEach(employee => {
        const idNo = employee.id_no;
        const myDependent = dependentList.filter(dp => dp.dependent_id_no === idNo);
        employee["dependents"] = myDependent;
      });
      return {
        ...state,
        data: employeeList,
        totalEmployees: action.payload.total_records
      };
    case actionTypes.HR_MY_EMPLOYEE_DEPENDANT_LOAD:
      let empDependants = action.payload;
      return {
        ...state,
        empDependants: empDependants
      };
    case actionTypes.HR_MY_EMPLOYEE_SORT:
      //sort default
      let order = action.payload.order;
      let orderBy = action.payload.orderBy;
      let cloneData = state.data.slice(0);
      if (order === Enums.SORT.ASC) {
        cloneData = cloneData.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
      } else {
        cloneData = cloneData.sort((a, b) => (a[orderBy] > b[orderBy] ? -1 : 1));
      }
      return {
        ...state,
        data: cloneData
      };
    case actionTypes.HR_MY_EMPLOYEE_SET_SELECTED_IDS:
      return {
        ...state,
        selectedIds: action.payload
      };
    case actionTypes.HR_MY_EMPLOYEE_SEND_INVITE_SUCCESS:
      return {
        ...state,
        showInviteSuccess: true
      };
    case actionTypes.HR_MY_EMPLOYEE_SEND_INVITE_ERROR:
      return {
        ...state,
        showInviteError: true
      };
    case actionTypes.HR_MY_EMPLOYEE_ADD_SUCCESS:
      return {
        ...state,
        showEmployeeSuccess: true
      };
    case actionTypes.HR_MY_EMPLOYEE_CLOSE_POPUP:
      return {
        ...state,
        showInviteSuccess: false,
        showInviteError: false,
        showEmployeeSuccess: false,
        showLoading: false
      };
    case actionTypes.HR_MY_EMPLOYEE_LOAD_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: action.payload
      };
    case actionTypes.HR_MY_EMPLOYEE_SHOW_LOADING:
      return {
        ...state,
        showLoading: true
      };
    case actionTypes.HR_MY_EMPLOYEE_CLOSE_LOADING:
      return {
        ...state,
        showLoading: false
      };
    case actionTypes.HR_MY_EMPLOYEE_DEPENDANT_SHOW_LOADING:
      return {
        ...state,
        showLoading: true
      };
    case actionTypes.HR_MY_EMPLOYEE_DEPENDANT_CLOSE_LOADING:
      return {
        ...state,
        showLoading: false
      };
    default: {
      return state;
    }
  }
};
