import Enums from "../../../../utils/Enums";

const GCCABenefits = {
  headers: [
    {
      title: `Benefits / Plan Type`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      info: `Health declaration required.`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Group Crisis Cover Accelerated</strong>`
        },
        {
          data: `S$ 250,000`
        },
        {
          data: `S$ 100,000`
        },
        {
          data: `S$ 75,000`
        },
        {
          data: `S$ 40,000`
        },
        {
          data: `S$ 25,000`
        }
      ]
    }
  ],
  footnotes: []
};

let GCCAPremium = {
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 19</strong>`
        },
        {
          data: `S$ 211.00`
        },
        {
          data: `S$ 94.00`
        },
        {
          data: `S$ 72.00`
        },
        {
          data: `S$ 39.00`
        },
        {
          data: `S$ 24.00`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>20 - 24</strong>`
        },
        {
          data: `S$ 212.00`
        },
        {
          data: `S$ 95.00`
        },
        {
          data: `S$ 74.00`
        },
        {
          data: `S$ 40.00`
        },
        {
          data: `S$ 25.00`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>25 - 29</strong>`
        },
        {
          data: `S$ 291.00`
        },
        {
          data: `S$ 127.00`
        },
        {
          data: `S$ 98.00`
        },
        {
          data: `S$ 52.00`
        },
        {
          data: `S$ 33.00`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>30 - 34</strong>`
        },
        {
          data: `S$ 366.00`
        },
        {
          data: `S$ 157.00`
        },
        {
          data: `S$ 122.00`
        },
        {
          data: `S$ 65.00`
        },
        {
          data: `S$ 41.00`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>35 - 39</strong>`
        },
        {
          data: `S$ 551.00`
        },
        {
          data: `S$ 238.00`
        },
        {
          data: `S$ 184.00`
        },
        {
          data: `S$ 99.00`
        },
        {
          data: `S$ 62.00`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>40 - 44</strong>`
        },
        {
          data: `S$ 954.00`
        },
        {
          data: `S$ 421.00`
        },
        {
          data: `S$ 326.00`
        },
        {
          data: `S$ 174.00`
        },
        {
          data: `S$ 109.00`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>45 - 49</strong>`
        },
        {
          data: `S$ 1,378.00`
        },
        {
          data: `S$ 622.00`
        },
        {
          data: `S$ 481.00`
        },
        {
          data: `S$ 257.00`
        },
        {
          data: `S$ 161.00`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>50 - 54</strong>`
        },
        {
          data: `S$ 1,927.00`
        },
        {
          data: `S$ 873.00`
        },
        {
          data: `S$ 675.00`
        },
        {
          data: `S$ 360.00`
        },
        {
          data: `S$ 225.00`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>55 - 59</strong>`
        },
        {
          data: `S$ 3,301.00`
        },
        {
          data: `S$ 1,489.00`
        },
        {
          data: `S$ 1,151.00`
        },
        {
          data: `S$ 615.00`
        },
        {
          data: `S$ 384.00`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>60 - 64</strong>`
        },
        {
          data: `S$ 5,479.00`
        },
        {
          data: `S$ 2,476.00`
        },
        {
          data: `S$ 1,915.00`
        },
        {
          data: `S$ 1,022.00`
        },
        {
          data: `S$ 639.00`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>65 - 69</strong>`,
          info: `For renewals only`
        },
        {
          data: `S$ 7,423.00`
        },
        {
          data: `S$ 3,413.00`
        },
        {
          data: `S$ 2,639.00`
        },
        {
          data: `S$ 1,407.00`
        },
        {
          data: `S$ 880.00`
        }
      ]
    }
  ],
  footnotes: [
    {
      data: `Note: Coverage for Group Crisis Cover Accelerated benefit ceases at age 70 last birthday.`
    }
  ]
};

//   ----------------------------------------------------------------------
const GCCA = {
  id: 1,
  code: "GCCA",
  label: "Group Crisis Cover Accelerated (GCCA)",
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GCCABenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium",
      data: [GCCAPremium]
    }
  ]
};

export default GCCA;
