import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import useOtp from "../../hooks/useOtp";
import { styles } from "./emailContent.styles";

const EmailContent = ({ classes }) => {
  const { setOtpSelectedMethod } = useOtp();

  const onClick = () => {
    setOtpSelectedMethod("EMAIL");
  };

  return (
    <>
      <Divider className={classNames(classes.divider)} />
      <Box onClick={onClick} className={classes.clickableContent}>
        <Box display="flex" alignItems="center">
          <EmailIcon style={{ marginRight: 8, color: "#555" }} />
          <Typography variant="body1">Get an OTP via your email</Typography>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default withStyles(styles, { withTheme: true })(EmailContent);
