import { APIError, APIResource, APIResponse } from "../utils/APIResource";

export default class CaptchaService {
  static generateCaptcha() {
    return APIResource.post(`/v1/open/generateCaptcha`)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static refreshCaptcha(trxId) {
    let body = { trx_id: trxId, captcha_code: "", captcha_image: "" };
    return APIResource.post(`/v1/open/refreshCaptcha`, body)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }
}
