import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { companyActions } from "../../../../../../actions";
import TextField from "@mui/material/TextField";

const useStyles = makeStyles(theme => ({
  textField: {},
  textContainer: {
    padding: "10px",
    paddingBottom: "20px"
  }
}));

const UEN = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const company = useSelector(state => state.company.details);

  const [isError, setIsError] = useState({ error: false, message: "" });

  useEffect(() => {
    // Check if uen_no is undefined or empty or contains only spaces
    const isUenNoEmpty = company.uen_no === undefined || !company.uen_no.trim();

    // Memoize the regex object for alphanumeric validation
    const regex = /^[a-zA-Z0-9]+$/;

    if (isUenNoEmpty) {
      setIsError(prevState => ({
        ...prevState,
        error: true,
        message: "Required"
      }));
    } else if (!regex.test(company.uen_no)) {
      setIsError(prevState => ({
        ...prevState,
        error: true,
        message: "Invalid characters"
      }));
    } else {
      setIsError(prevState => ({
        ...prevState,
        error: false,
        message: ""
      }));
    }
  }, [company]);

  return (
    <div className={classes.textContainer}>
      <TextField
        id="uen_no"
        name="uen_no"
        label="Enter the company Unique Entity Number"
        className={classes.textField}
        value={company.uen_no}
        autoComplete="[off]"
        size="small"
        inputProps={{
          maxLength: 15
        }}
        error={isError.error}
        helperText={isError.error ? isError.message : ""}
        onChange={event => {
          const fieldConfig = { key: "uen_no", value: event.target.value?.trim() };
          dispatch(companyActions.setCompanyFormDetails(fieldConfig));
        }}
        style={{ width: "98%" }}
      />
    </div>
  );
};

export default UEN;
