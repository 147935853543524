import Enums from "../../../../../utils/Enums";

const GOGPBenefits = {
  headers: [
    {
      title: ``,
      mandatory: true,
      attributes: {
        style: {
          width: 10
        }
      }
    },
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: 200
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    }
    // {
    //   title: `Plan 4`,
    //   tier: 4
    // },
    // {
    //   title: `Plan 5`,
    //   tier: 5
    // },
    // {
    //   title: `Plan 6`,
    //   tier: 6
    // }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>1</strong>`
        },
        {
          data: `<strong>Panel Provider</strong>`
        },
        {
          data: `Cashless & As-Charged`,
          attributes: {
            colspan: 3
          }
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>2</strong>`
        },
        {
          data: `<strong>Non-Panel Provider</strong>`,
          info: `(covers treatment in Singapore only)`
        },
        {
          data: `Up to S$30 per visit`,
          attributes: {
            colspan: 3
          }
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>3</strong>`
        },
        {
          data: `<strong>Singapore Government Polyclinic</strong>`
        },
        {
          data: `Reimbursement in full`,
          attributes: {
            colspan: 3
          }
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>4</strong>`
        },
        {
          data: `<strong>Accident & Emergency
          Department in Singapore Hospitals</strong>`
        },
        {
          data: `Up to S$100 per visit; <i>(capped at 2 visits per policy year)</i>`,
          attributes: {
            colspan: 3
          }
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>5</strong>`
        },
        {
          data: `<strong>Overseas Outpatient Treatment</strong>`
        },
        {
          data: `Up to S$100 per visit`,
          attributes: {
            colspan: 3
          }
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>6</strong>`
        },
        {
          data: `<strong>Paediatrician Direct Access</strong>`
        },
        {
          data: `Up to S$30 per visit`,
          attributes: {
            colspan: 3
          }
        }
      ]
    },

    {
      id: 7,
      columns: [
        {
          data: `<strong>7</strong>`
        },
        {
          data: `<strong>Co-Payment per Visit</strong>`
        },
        {
          data: `Nil`
        },
        {
          data: `S$5`
        },
        {
          data: `S$10`
        }
      ]
    }
  ],
  footnotes: []
};

let GOGPPremium = {
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    }
    // {
    //   title: `Plan 4`,
    //   tier: 4
    // },
    // {
    //   title: `Plan 5`,
    //   tier: 5
    // },
    // {
    //   title: `Plan 6`,
    //   tier: 6
    // }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 70</strong>`
        },
        {
          data: `S$ 256.00`
        },
        {
          data: `S$ 219.00`
        },
        {
          data: `S$ 186.00`
        }
      ]
    }
  ],
  footnotes: []
};

//   ----------------------------------------------------------------------
const GP = {
  id: 1,
  code: "GPRU",
  label: "Group Outpatient General Practitioner (GPRU)",
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GOGPBenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium (inclusive of 7% GST)",
      data: [GOGPPremium]
    }
  ]
};

export default GP;
