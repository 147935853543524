import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import SmsIcon from "@mui/icons-material/Sms";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import useOtp from "../../hooks/useOtp";
import { styles } from "./smsContent.styles";

const SmsContent = ({ classes }) => {
  const { setOtpSelectedMethod } = useOtp();

  const onClick = () => {
    setOtpSelectedMethod("SMS");
  };

  return (
    <>
      <Divider className={classNames(classes.divider)} />
      <Box onClick={onClick} className={classes.clickableContent}>
        <Box display="flex" alignItems="center">
          <SmsIcon style={{ marginRight: 8, color: "#555" }} />
          <Typography variant="body1">Get an OTP via your mobile phone</Typography>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default withStyles(styles, { withTheme: true })(SmsContent);
