import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";

import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import {
  productActions,
  companyActions,
  documentActions,
  employeeActions,
  quoteActions,
  quotesActions,
  combinedActions,
  appActions
} from "../../../actions";
import PDFViewer from "../../../components/pdfViewer";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";

import QuotesTable from "./components/QuotesTable";
import DeleteQuoteDialog from "./components/DeleteQuoteDialog";
import SnackBar from "../../../components/SnackBar";
import LoadingPopup from "../../../components/LoadingPopup";
import config from "../../../config/config";
import Enums from "../../../utils/Enums";
import QuoteService from "../../../services/quote.service";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import Util from "../../../utils/Util";

class SavedQuotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pdf: undefined,
      open: false,
      openDelete: false,
      quoteId: undefined,
      showLoadingPopup: false,
      currentQuote: null,
      isOldQuoteWarningOpen: false
    };
  }

  onPdfClick = (url, quoteID, fileName) => {
    this.setState({ open: true, selectedQuote: quoteID, pdf: url, fileName });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  componentWillMount() {
    this.props.list();
  }

  onRowClick = (event, quote) => {
    if (quote.stages !== Enums.STAGES.INFORCE && quote.stages !== Enums.STAGES.SUBMITTED) {
      this.props.get(quote.id).then(response => {
        this.setState({ currentQuote: quote });
        if (config.app.type === Enums.APP_TYPE.SALES && quote?.valid_draft === false) {
          this.setState({ isOldQuoteWarningOpen: true });
        } else {
          this.props.loadConfig().then(response => {
            this.props.validateEmployeeData(quote.stages);
            this.props.getCompanyDetail(quote.id);
            this.props.getFiles(quote.id).then(response => {
              this.props.history.push("/auth/sp/currentQuote");

              const dialogNbstpDisabled = Util.isDialogNbstpEnabled(config.disableBefore, config.dialogAutoDisable);

              if (config.firstPageDialogEnabled && dialogNbstpDisabled) {
                this.props.setFirstPageDialogVisibility(true);
              }
            });
          });
        }
      });
    }
  };

  //Code by Kunal and Luis 17 Jul
  onDeleteClick = (event, quote) => {
    event.stopPropagation();
    this.setState({ openDelete: true, quoteId: quote.id });
  };

  handleCloseDeleteDialog() {
    this.setState({
      openDelete: false,
      quoteId: undefined
    });
  }

  handleDeleteQuote() {
    this.setState({ showLoadingPopup: true });
    this.props.deleteQuote(this.state.quoteId).then(
      response => {
        this.handleCloseDeleteDialog();
        this.setState({ showLoadingPopup: false });
        this.props.list();
      },
      error => {
        this.handleCloseDeleteDialog();
        this.setState({ showLoadingPopup: false });
      }
    );
  }

  handleOldQuoteWarningClose = () => {
    this.setState({ isOldQuoteWarningOpen: false });

    this.props.loadConfig().then(response => {
      const { product } = this.props;

      let categories = this.state.currentQuote.data.categories;

      // convert to pf3
      categories.forEach(category => {
        category.plansConfiguration.forEach(planConfiguration => {
          if (planConfiguration.code == "GPCG") {
            planConfiguration.code = "GEAC";
          }
          if (planConfiguration.code == "GPRU") {
            planConfiguration.code = "PGIH";
          }
          if (planConfiguration.code == "GOSP") {
            planConfiguration.code = "PSIH";
          }
          if (planConfiguration.code == "GPPD") {
            planConfiguration.code = "PDIH";
          }
        });
      });

      // add empty configuration to the existing categories selections
      product.config.plans.forEach(plan => {
        categories.forEach(category => {
          let result = category.plansConfiguration.find(planConfiguration => {
            return planConfiguration.code == plan.code;
          });
          if (result == undefined) {
            category.plansConfiguration.push({
              code: plan.code,
              tier: null,
              enableDependent: false
            });
          }
        });
      });

      // update the product id
      this.setState({ currentQuote: { ...this.state.currentQuote, product_id: product.config.productId } }, () => {
        QuoteService.update(this.state.currentQuote.id, this.state.currentQuote);
      });

      this.props.setCategories(categories);
      this.props.validateEmployeeData(this.state.currentQuote.stages);
      this.props.getCompanyDetail(this.state.currentQuote.id);
      this.props.getFiles(this.state.currentQuote.id).then(response => {
        this.props.history.push("/auth/CurrentQuote");
      });
    });
  };
  //End code 17 Jul

  render() {
    const { classes, quotes } = this.props;
    const { open, openDelete } = this.state;

    return (
      <>
        <div className={classes.breadcrumb}>
          <CustomBreadcrumb currentPage="SavedQuotes" />
        </div>
        <div className={classes.root}>
          <Modal open={this.state.isOldQuoteWarningOpen} onClose={this.handleOldQuoteWarningClose}>
            <Grid container direction="row">
              <Grid item xs={1} />
              <Grid item xs={10}>
                <div className={classes.popup}>
                  <div className={classes.containerPopup}>
                    <span style={{ fontWeight: "bold" }}>
                      The quote has expired. In view of the implementation of 9% GST for all quotes generated on and
                      after 1 Jan 2024, please proceed to generate a new quote.
                    </span>
                    <br />
                    <br />
                    <Button
                      variant="raised"
                      color="primary"
                      className={classes.button}
                      onClick={this.handleOldQuoteWarningClose}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid item xs={1} style={{ paddingTop: "2%" }}>
                <Button variant="fab" mini onClick={this.handleOldQuoteWarningClose}>
                  <Icon className={classes.leftIcon}>close</Icon>
                </Button>
              </Grid>
            </Grid>
          </Modal>
          <div className={classes.content}>
            <Typography variant="h5" color="inherit">
              Saved Quotes
            </Typography>
            <br />
            <QuotesTable
              quotes={quotes}
              onRowClick={this.onRowClick}
              onPdfClick={this.onPdfClick}
              onDeleteClick={this.onDeleteClick}
            />
          </div>
          <PDFViewer
            isPDF={true}
            open={open}
            handleClose={this.handleClose}
            fileName={this.state.fileName}
            pdf={this.state.pdf}
          />
          <DeleteQuoteDialog
            show={openDelete}
            handleClose={this.handleClose}
            closeHandler={this.handleCloseDeleteDialog.bind(this)}
            submitHandler={this.handleDeleteQuote.bind(this)}
          />
          <LoadingPopup title="delete.quote.dialog" name="delete-quote-loading" open={this.state.showLoadingPopup} />
          <SnackBar
            type="error"
            open={this.props.error500 && this.props.error500.fail}
            onClose={this.handleCloseSnackbarError}
            message="There was a problem connecting to the server. Please try again later."
          />
        </div>
      </>
    );
  }
}

const styles = theme => ({
  root: {
    marginBottom: theme.actionFooter.height
  },
  content: {
    padding: theme.spacing.unit * 2.5,
    paddingTop: 10
  },
  popupTitle: {
    display: "block",
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: "400"
  },
  popup: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    alignSelf: "center",
    padding: "12",
    backgroundColor: theme.background.color,
    // backgroundColor: theme.palette.default.main,
    color: theme.palette.default.fontColor,
    p: 4,
    overflowY: "auto",
    textAlign: "center",
    borderRadius: "3px",
    boxShadow: "4px 8px 12px 0 rgba(0,0,0,0.4)",
    animation: "show-modal .7s ease-in-out"
  },
  containerPopup: {
    marginLeft: theme.spacing.unit * 5.0,
    marginRight: theme.spacing.unit * 5.0,
    paddingTop: theme.spacing.unit * 5.0,
    paddingBottom: theme.spacing.unit * 5.0
  },
  button: {
    marginLeft: 20,
    minWidth: 150
  },
  breadcrumb: {
    margin: "10px 10px",
    paddingBottom: "20px"
  }
});

function mapStateToProps(state) {
  let product = state.products[state.quote.product_code];
  return {
    app: state.app,
    quotes: state.quotes,
    product: product
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(appActions, dispatch),
    ...bindActionCreators(productActions, dispatch),
    ...bindActionCreators(quoteActions, dispatch),
    ...bindActionCreators(employeeActions, dispatch),
    ...bindActionCreators(quotesActions, dispatch),
    ...bindActionCreators(companyActions, dispatch),
    ...bindActionCreators(documentActions, dispatch),
    ...bindActionCreators(combinedActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(SavedQuotes)));
