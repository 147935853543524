import React from "react";
import { withStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import config from "../../config/config";

const MaintenancePage = ({ classes }) => {
  return (
    <div>
      <Paper className={classes.root} elevation={4}>
        <Typography variant="headline" component="h3">
          {config.maintenanceTitle}
        </Typography>
        <Typography variant="caption" component="h6" className={classes.spacing}>
          {config.maintenanceSubtitle}
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          {config.maintenanceDetails}
        </Typography>
        <hr />
      </Paper>
    </div>
  );
};

const styles = theme => ({
  pSpacing: {
    margin: `${theme.spacing.unit}px 0`,
    whiteSpace: "pre-line"
  },
  root: {
    padding: 16,
    margin: theme.spacing.unit * 3
  },
  spacing: {
    padding: "5px 0",
    margin: `${theme.spacing.unit}px 0`
  }
});

export default withStyles(styles, { withTheme: true })(MaintenancePage);
