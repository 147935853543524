import React from "react";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#ed1b24",
    color: "white",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #ed1b24"
  },
  arrow: {
    color: "#ed1b24"
  }
}))(Tooltip);

export default CustomTooltip;
