export const styles = theme => ({
  backButton: {
    backgroundColor: theme.colors.secondary.white,
    border: `2px solid black`,
    color: theme.colors.blackScale.black100,
    fontWeight: 500
  },
  dialogTitle: {
    paddingBottom: "2px"
  },
  button: {
    minWidth: 150
  },
  dialogContent: {
    padding: "5px 24px"
  },
  divider: {
    marginTop: "1px"
  },
  clickableContent: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.colors.secondary.grey10
    },
    padding: "8px 0"
  }
});
