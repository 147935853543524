//some code from BE is not same with current
export default {
  Indonesia: "Indonesia (Jakarta Only)",
  Philippine: "Philippines (Manila Only)",
  China: "People’s Republic of China (except Xinjiang and Tibet)",
  Korea: "South Korea",
  UAEmirates: "UAE",
  Singapore: "Singapore",
  Brunei: "Brunei",
  "Hong Kong": "Hong Kong",
  Japan: "Japan",
  Malaysia: "Malaysia",
  Macau: "Macau",
  Taiwan: "Taiwan",
  Thailand: "Thailand",
  Qatar: "Qatar",
  Others: "Others"
};
