import * as actionTypes from "../actions/types";

const initialState = {
  data: [],
  categories: [],
  dependentData: []
};

export const policyMyEmployees = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.POLICY_GET_MY_EMPLOYEES:
      let response = action.payload ? action.payload : [];
      return {
        ...state,
        data: response
      };
    case actionTypes.POLICY_MY_EMPLOYEE_LOAD_CATEGORY:
      return {
        ...state,
        categories: action.payload
      };
    case actionTypes.POLICY_MY_EMPLOYEE_ALL_DEPENDENT:
      return {
        ...state,
        dependentData: action.payload
      };
    default:
      return state;
  }
};
