import React, { Component } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { withRouter } from "react-router";
import { withStyles } from "@mui/styles";

class CustomTablePagination extends Component {
  handleBackButtonClick = event => {
    const { onPageChange, page } = this.props;
    onPageChange(event, page - 1);
  };

  handleNextButtonClick = event => {
    const { onPageChange, page } = this.props;
    onPageChange(event, page + 1);
  };

  handlePageNumberClick = (event, pageNumber) => {
    const { onPageChange } = this.props;
    onPageChange(event, pageNumber);
  };

  render() {
    const { count, page, rowsPerPage, classes } = this.props;
    const totalPages = Math.ceil(count / rowsPerPage);

    return (
      <Box display="flex" justifyContent="space-between" alignItems="center" padding="10px">
        <Typography variant="body2">
          Showing{" "}
          <span className={classes.rangePage}>
            {page * rowsPerPage + 1} to {Math.min((page + 1) * rowsPerPage, count)}
          </span>{" "}
          of {count} results
        </Typography>
        <Box display="flex" alignItems="center">
          <IconButton onClick={this.handleBackButtonClick} disabled={page === 0} aria-label="previous page">
            <KeyboardArrowLeft />
          </IconButton>
          {[...Array(totalPages)].map((_, index) => (
            <Typography
              key={index}
              variant="body2"
              style={{
                margin: "0 5px",
                cursor: "pointer",
                fontWeight: page === index ? "bold" : "normal"
              }}
              onClick={event => this.handlePageNumberClick(event, index)}
            >
              {index + 1}
            </Typography>
          ))}
          <IconButton onClick={this.handleNextButtonClick} disabled={page >= totalPages - 1} aria-label="next page">
            <KeyboardArrowRight />
          </IconButton>
        </Box>
      </Box>
    );
  }
}

const styles = theme => ({
  rangePage: {
    fontWeight: "bold"
  }
});

export default withRouter(withStyles(styles, { withTheme: true })(CustomTablePagination));
