import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import moment from "moment";
import { connect } from "react-redux";
import { quoteActions, employeeActions, validationsActions } from "../../../../../../actions";
import { bindActionCreators } from "redux";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import SelectValidatorElement from "../../../../../../components/validation/SelectValidatorElement";
import DatePickerValidatorElement from "../../../../../../components/validation/DatePickerValidatorElement";
//import AutosuggestionValidatorElement from "components/validation/AutosuggestionValidatorElement";
import { injectIntl } from "react-intl";
import countries from "../../../../../../assets/data/countries";
import countryOfResidence from "../../../../../../assets/data/countryOfResidence";
import mappingBECode from "../../../../../../assets/data/countryOfResidenceMapping";
import genderValues from "../../../../../../assets/data/genderValues";
import maritalStatusValues from "../../../../../../assets/data/maritalValues";
import Enums from "../../../../../../utils/Enums";
import Util from "../../../../../../utils/Util";
import SelectValidatorElementInfo from "../../../../../../components/validation/SelectValidatorElementInfo";
import occupationsClass from "../../../../../../assets/data/occupationsClass";
import InfoPopover from "../../../../../../components/InfoPopover";
import HREmployeeAdditionalFields from "./HREmployeeAdditionalFields";

const FORMAT_DATE = "DD/MM/YYYY";

class EmployeeEditForm extends Component {
  constructor(props) {
    super(props);

    this._refs = {};

    this.isHRApp = props.app.type === Enums.APP_TYPE.HR;
    this.isSalesApp = props.app.type === Enums.APP_TYPE.SALES;
    let person = {
      full_name: "",
      category: "",
      email: "",
      date_of_employment: null,
      id_no: "",
      dob: null,
      gender: "",
      occupation_class: "",
      marital_status: "",
      nationality: "",
      country_of_residence: "",
      coverage_start_date: null,
      coverage_end_date: null,
      underwriting_status: null,
      account_status: "",
      type: Enums.PERSON_TYPE.EMPLOYEE,
      invalid_fields: [],
      isValid: null,
      foreign_worker: "",
      emailError: null
    };
    this.originalValues = {};
    this.oldValues = {};

    if (props.employeeData) {
      person = Object.assign({}, person, props.employeeData);
      //do mapping incorrect code
      let coResidenceMapping = mappingBECode[person["country_of_residence"]];
      if (!coResidenceMapping) {
        coResidenceMapping = "Others";
      }
      console.log("mapping missing code", person["country_of_residence"], coResidenceMapping);
      person["country_of_residence"] = coResidenceMapping;
    }

    this.state = {
      ...person,
      errorMessages: {
        age: props.intl.formatMessage({ id: "validator.age" }),
        coverageStartDateError: ""
      },
      dirtyField: "",
      validationOnly: false,
      validationErrors: [],
      infoPopover: {
        open: false,
        element: null,
        data: ""
      }
    };

    //Categories
    let categoriesSource = props.categories.map(cat => ({
      value: cat.name,
      label: cat.name
    }));

    //FW toggle
    this.yesNo = [
      {
        value: "Y",
        label: "Yes"
      },
      {
        value: "N",
        label: "No"
      }
    ];

    if (this.isHRApp) {
      this.state["foreign_worker_help"] = this.getForeignWorkerHelpText(this.state.foreign_worker);
    }

    this.categories = categoriesSource;
    this.countries = countries;
    this.country_of_residence = countryOfResidence.map(cat => ({
      value: cat,
      label: cat
    }));

    this.maritalStatusValues = maritalStatusValues;

    this.genderValues = genderValues;
    this.occupationClass = [];
    for (var prop in occupationsClass) {
      this.occupationClass.push({
        value: prop,
        label: occupationsClass[prop].name,
        description: occupationsClass[prop].description
      });
    }

    props.innerRef?.(this);
    this.employeeNameValidator = React.createRef();
  }

  componentWillMount() {
    this.addValidationRules();
    this.setOriginalValues(this.props.employeeData);
  }

  componentDidMount() {
    const isValidateEmail = this.triggerEmailValidation();
    if ((!this.props.readOnly && this.props.employeeData && !this.props.employeeData.isValid) || isValidateEmail) {
      setTimeout(() => {
        this.validateAndSubmit(true);
        let { errorMessages, validationOnly, validationErrors, infoPopover, ...oldValuesPerson } = this.state;
        this.oldValues = oldValuesPerson;
        // delete this.oldValues.validationOnly;
      }, 500);
    }
    if (this.isSalesApp) {
      this.validateField();
    }
  }

  triggerEmailValidation = () => {
    const _persons = this.props.quote?.data.persons || [];
    if (_persons.length) {
      const isEmailDuplicated = _persons.filter(p => p.email === this.props.employeeData?.email)?.length > 1;
      const { emailErrors } = this.props;

      if (isEmailDuplicated) return true;
      if (emailErrors.email_results[this.props.employeeData?.email]?.length) return true;
    }

    return false;
  };

  validateField = () => {
    if (
      this.employeeNameValidator &&
      this.employeeNameValidator.current &&
      this.state.full_name.length > 0 &&
      !this.props.document?.hasSignedProposal
    ) {
      this.employeeNameValidator.current.validate(this.state.full_name);
    }
  };

  setOriginalValues = data => {
    if (data) {
      this.originalValues = {
        category: data.category,
        coverage_start_date: data.coverage_start_date
      };
    }
  };

  triggerErrorApiValidationOnBlur = async value => {
    if (this.props.app.type === Enums.APP_TYPE.HR) {
      const employee = this.props.myEmployee.data;
      const isCurrentEmployee = employee.find(p => p.email === this.state.email);
      if (isCurrentEmployee) return;
    }

    const { emailValidations } = this.props;
    // check email duplication on database level
    const response = await emailValidations([value]);
    if (response.data.email_results[value].length) {
      this.setState({
        emailError: response.data.email_results[value]
      });
      this.refs.form?.isFormValid(false);
    }
    this.setState({
      emailError: null
    });
    this.refs.form?.isFormValid(true);
  };

  addValidationRules = () => {
    // custom rule will have name 'isAgeValid'
    ValidatorForm.addValidationRule("notBlank", value => {
      return !(typeof value === "string" && value && value.trim() === "");
    });

    ValidatorForm.addValidationRule("isAgeValid", value => {
      let person = this.state;
      let categories = this.props.categories;
      let result = this.props.validateEmployeeAge(categories, person, true);
      if (result.success) {
        return true;
      } else {
        if (this.state.errorMessages.age !== result.msg) {
          this.setState({
            errorMessages: { ...this.state.errorMessages, age: result.msg }
          });
        }
        return false;
      }
    });

    ValidatorForm.addValidationRule("isDuplicatedIdNo", value => {
      return this.props.validationRules["isDuplicatedIdNo"]("employee", value, this.state);
    });

    ValidatorForm.addValidationRule("isDependentsCoverageDateValid", value => {
      return this.props.validationRules["isDependentsCoverageDateValid"]();
    });

    ValidatorForm.addValidationRule("isEmail", value => {
      return Util.isEmail(value);
    });

    ValidatorForm.addValidationRule("lengthNames", value => {
      if (this.props.document?.hasSignedProposal) return true;
      return value.length <= 120;
    });

    ValidatorForm.addValidationRule("isEmploymentStartDateValid", value => {
      return Util.isEmploymentStartDateValid(this.state.date_of_employment, this.state.coverage_start_date);
    });

    ValidatorForm.addValidationRule("isDuplicatedEmail", value => {
      if (Util.isEmpty(value)) {
        return true;
      }
      let person = this.state;
      if (this.isSalesApp) {
        const _persons = this.props.quote.data.persons;
        return (
          !_persons.filter(
            p =>
              p.type === "E" &&
              !Util.caseInsensitiveEqual(p.id_no, person.id_no) &&
              _persons.indexOf(p) !== this.props.selectedIndex &&
              Util.caseInsensitiveEqual(p.email, person.email)
          ).length > 0
        );
      } else {
        const _employee = this.props.myEmployee.data;
        return (
          !_employee.filter(p => {
            return (
              p.member_type === "MEMBER" &&
              _employee.indexOf(p) !== this.props.selectedIndex &&
              Util.caseInsensitiveEqual(p.email, person.email)
            );
          }).length > 0
        );
      }
    });

    ValidatorForm.addValidationRule("isEmailValid", value => {
      if (Util.isEmailForbiddenUsernamePatterns(value)) {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule("isEmailDomainValid", value => {
      if (Util.isEmailForbiddenDomainPatterns(value)) {
        return false;
      }

      return true;
    });

    ValidatorForm.addValidationRule("isEmailValidationViaAPI", async value => {
      // makesure only call email validation via API when passing these regex

      if (
        !Util.isEmail(value) ||
        Util.isEmailForbiddenDomainPatterns(value) ||
        Util.isEmailForbiddenUsernamePatterns(value)
      )
        return true;

      if (this.props.app.type === Enums.APP_TYPE.HR) {
        const employee = this.props.myEmployee.data;
        const isCurrentEmployee = employee.find(p => p.email === this.state.email);
        if (isCurrentEmployee) return true;
      }

      const { emailValidations } = this.props;
      // check email duplication on database level
      const response = await emailValidations([value]);
      if (response.data.email_results[value].length) {
        this.setState({
          emailError: response.data.email_results[value]
        });
        return false;
      }

      this.setState({
        emailError: null
      });

      return true;
    });

    ValidatorForm.addValidationRule("isForeignWorkerValid", value => {
      if (Util.isEmpty(value)) {
        return true;
      }
      return (value === "Y" || value === "1") && this.state.nationality === "SGP" ? false : true;
    });

    ValidatorForm.addValidationRule("isResidencyValid", value => {
      //validate more than 50% singapore residency
      if (this.isHRApp) {
        if (this.props.mode == "EDIT") {
          if (this.props.isFormDirty && this.state.dirtyField == "country_of_residence") {
            if (value !== "Singapore") {
              if (this.props.myEmployee !== null) {
                let totalEmployeesCount = this.props.myEmployee.totalEmployees;
                let sgpEmployeesCount = 0;
                if (this.props.myEmployee.data !== undefined) {
                  this.props.myEmployee.data.forEach(data => {
                    if (data.country_of_residence == "Singapore") {
                      sgpEmployeesCount++;
                    }
                  });
                }
                // plus one as the one that is currently editing does not reflect
                if (sgpEmployeesCount / (totalEmployeesCount + 1) < 0.5) {
                  return false;
                } else {
                  return true;
                }
              }
            }
          }
        } else if (this.props.mode == "ADD") {
          if (value !== "Singapore") {
            if (this.props.myEmployee !== null) {
              let totalEmployeesCount = this.props.myEmployee.totalEmployees;
              let sgpEmployeesCount = 0;
              if (this.props.myEmployee.data !== undefined) {
                this.props.myEmployee.data.forEach(data => {
                  if (data.country_of_residence == "Singapore") {
                    sgpEmployeesCount++;
                  }
                });
              }
              // plus one as the one that is currently editing does not reflect
              if (sgpEmployeesCount / (totalEmployeesCount + 1) < 0.5) {
                return false;
              } else {
                return true;
              }
            }
          }
        }
      }
      return true;
    });

    ValidatorForm.addValidationRule("isNameValid", value => {
      if (this.props.document?.hasSignedProposal) return true;
      if (this.props.mode == "EDIT") {
        /*
        if (this.props.isFormDirty && this.state.dirtyField == "full_name") {
          if (Util.isNameValid(value)) {
            return true;
          } else {
            return false;
          }
        }
          */
        if (Util.isNameValid(value)) {
          return true;
        } else {
          return false;
        }
      } else if (this.props.mode == "ADD") {
        if (Util.isNameValid(value)) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    });

    ValidatorForm.addValidationRule("isNRICValid", value => {
      //validating only singaporean and to validate only on hr portal
      // might have other edge cases to check
      if (this.isHRApp) {
        if (this.props.mode == "EDIT") {
          if (this.props.isFormDirty && this.state.dirtyField == "id_no") {
            if (this.state.nationality === "SGP") {
              if (Util.isNRICValid(value)) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          }
        } else if (this.props.mode == "ADD") {
          if (this.state.nationality === "SGP") {
            if (Util.isNRICValid(value)) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        }
      }
      return true;
    });

    ValidatorForm.addValidationRule("alphanumericValid", value => {
      let meRegex = /[^\w]|_/g;
      let isInvalid;
      isInvalid = meRegex.test(value);
      if (isInvalid) {
        return false;
      } else {
        return true;
      }
    });

    ValidatorForm.addValidationRule("isDobValid", value => {
      if (this.isHRApp) {
        if (this.props.mode == "EDIT") {
          if (this.props.isFormDirty && this.state.dirtyField == "dob") {
            if (this.state.nationality === "SGP") {
              if (value !== null) {
                return this.validateDob(value);
              }
            }
          }
        } else if (this.props.mode == "ADD") {
          if (this.state.nationality === "SGP") {
            if (value !== null) {
              return this.validateDob(value);
            }
          }
        }
      }
      return true;
    });

    ValidatorForm.addValidationRule("isCoverageStartDateValid", value => {
      // validate employee coverage start date
      // only hr app, and only for new additon change viewing of it should not validate this
      if (this.isHRApp) {
        if (this.props.mode == "EDIT") {
          if (this.props.isFormDirty && this.state.dirtyField == "coverage_start_date") {
            return this.validateCoverageDate(value);
          }
        } else if (this.props.mode == "ADD") {
          return this.validateCoverageDate(value);
        }
      }
      return true;
    });
  };

  validateCoverageDate = value => {
    let waiting_period = 0;
    let policyStartDate = moment(this.props.policy.activation_date, FORMAT_DATE); // the original start date of policy
    if (this.state.date_of_employment !== null && this.state.foreign_worker !== "") {
      if (this.state.foreign_worker == "Y") {
        waiting_period = 0;
      } else if (this.state.foreign_worker == "N") {
        waiting_period = this.props.policy.waiting_period;
      }
      let dateHrSendInstruction = moment(moment(), FORMAT_DATE);
      let employmentStartDate = moment(this.state.date_of_employment, FORMAT_DATE);
      let coverageStartDate = moment(value, FORMAT_DATE);
      let employmentDateWithWaitingPeriod = employmentStartDate.add(waiting_period, "M");

      // PACSEB-225 first validation
      if (
        coverageStartDate.isSameOrAfter(policyStartDate) &&
        coverageStartDate.isSameOrAfter(employmentDateWithWaitingPeriod)
      ) {
        // PACSEB-264 second validation
        // date difference between today and employment start date
        let diffInDays = moment.duration(dateHrSendInstruction.diff(employmentDateWithWaitingPeriod)).asDays();
        if (diffInDays <= 31) {
          if (coverageStartDate.isSame(employmentDateWithWaitingPeriod, "day")) {
            return true;
          } else {
            let errorMessage = this.props.intl.formatMessage(
              { id: "validator.invalidCoverageDate.intendedDate.employee" },
              { employmentDate: employmentDateWithWaitingPeriod.format("DD/MM/YYYY") }
            );
            this.setState({
              errorMessages: { ...this.state.errorMessages, coverageStartDateError: errorMessage }
            });
            return false;
          }
        } else {
          if (coverageStartDate.isSameOrAfter(dateHrSendInstruction, "day")) {
            return true;
          } else {
            let errorMessage = this.props.intl.formatMessage(
              { id: "validator.invalidCoverageDate.lateNotification.employee" },
              { currentDate: moment().format(FORMAT_DATE) }
            );
            this.setState({
              errorMessages: { ...this.state.errorMessages, coverageStartDateError: errorMessage }
            });
            return false;
          }
        }
      } else {
        let errorMessage = this.props.intl.formatMessage(
          { id: "validator.invalidCoverageDate.earlierThanPolicyStartDate.employee" },
          { employmentDate: employmentDateWithWaitingPeriod.format("DD/MM/YYYY") }
        );
        this.setState({
          errorMessages: { ...this.state.errorMessages, coverageStartDateError: errorMessage }
        });
        return false;
      }
    } else {
      return true;
    }
  };

  validateDob = value => {
    let dob = moment(value).format(FORMAT_DATE);
    if (dob.slice(6, 10) >= 1968) {
      if (this.state.id_no !== undefined) {
        if (this.state.id_no.slice(0, 1) == "S") {
          var slicedId = this.state.id_no.slice(1, 3);
          var slicedDob = dob.slice(6, 10);
          var combinedDob = "19".concat(slicedId);
          if (slicedDob == combinedDob) {
            return true;
          }
        } else if (this.state.id_no.slice(0, 1) == "T") {
          var slicedId = this.state.id_no.slice(1, 3);
          var slicedDob = dob.slice(6, 10);
          var combinedDob = "20".concat(slicedId);
          if (slicedDob == combinedDob) {
            return true;
          }
        }
      }
    } else {
      // rule doesnt apply if the person is born before 1968
      return true;
    }
  };

  formatDate = date => {
    return (date && moment(date, FORMAT_DATE)) || null;
  };

  handleChange = name => event => {
    let value = event.target.value;
    this.setState({ [name]: value }, () => {
      if (name === "category") {
        this.props.onChange("category", value);
      }

      if (name === "nationality") {
        //Trigger validate event for linked logic with foreign worker
        this._refs.foreign_worker.validate(this.state.foreign_worker);
      }

      if (this.isHRApp) {
        if (name === "foreign_worker") {
          this.setState({
            foreign_worker_help: this.getForeignWorkerHelpText(value)
          });
        }

        if (name === "category" && this.props.mode === "EDIT") {
          let coverage_start_date =
            value === this.originalValues.category ? this.originalValues.coverage_start_date : null;
          this.setState({ coverage_start_date: coverage_start_date });
        }
      }

      this.setState({ dirtyField: name });
      let { errorMessages, validationOnly, validationErrors, infoPopover, ...currentStatePerson } = this.state;
      let currentState = currentStatePerson;
      // delete currentState.validationOnly;
      this.props.handleDirtyForm(JSON.stringify(currentState) === JSON.stringify(this.oldValues));
    });
  };

  handleSelectChange = name => value => {
    this.setState({ dirtyField: name });
    this.setState({ [name]: value });
  };

  handleResetValue = name => () => {
    this.setState({
      [name]: ""
    });
  };

  handleDateChange = name => date => {
    if (this.isHRApp && name === "coverage_start_date") {
      let activationDate = date ? date.format("DD/MM/YYYY") : null;
      this.props.setActivationDate(activationDate);
    }
    this.setState({ dirtyField: name });
    this.setState({
      [name]: (date && moment(date).format(FORMAT_DATE)) || null
    });
    this.props.handleDirtyForm();
  };

  handleSubmit = () => {
    let data = Object.assign({}, this.state);
    data.invalid_fields = [];
    data.isValid = true;

    this.props.handleFormErrors("employee");
    if (!this.state.validationOnly) {
      setTimeout(() => {
        this.props.onSubmitCompleted("employee", data);
      }, 50);
    }
  };

  validateAndSubmit = (validationOnly = false) => {
    this.setState({ validationOnly: validationOnly }, () => {
      this.refs.form.submit();
    });
  };

  setRef = (ref, name) => {
    this._refs[name] = ref;
  };

  //it save the dom el of info parent component
  handleInfoRefs = (id, el) => {
    this.refs[id] = el;
  };

  getForeignWorkerHelpText = value => {
    if (value === "1") {
      return "Waiting Period: 0 month";
    } else {
      let waitingPeriod = this.props.policy && this.props.policy.waiting_period ? this.props.policy.waiting_period : 0;
      return `Waiting Period: ${waitingPeriod} month`;
    }
  };

  handleClickInfoButton = (data, id) => e => {
    e.stopPropagation();
    e.preventDefault();
    let infoPopover = { ...this.state.infoPopover };
    infoPopover.open = !infoPopover.open;
    if (infoPopover.open) {
      infoPopover.element = this.refs[id];
      infoPopover.data = data;
    }
    this.setState({ infoPopover });
  };
  render() {
    const { classes, intl, readOnly, app, agentType, productCode } = this.props;
    const { errorMessages, infoPopover } = this.state;
    return (
      <ValidatorForm
        ref="form"
        className={classes.form}
        onSubmit={this.handleSubmit}
        onError={errors => {
          this.props.handleFormErrors("employee", errors);
        }}
      >
        <Grid container spacing={"24px"} style={{ alignItems: "center" }}>
          {this.isHRApp && (
            <HREmployeeAdditionalFields
              setRef={this.setRef}
              formatDate={this.formatDate}
              handleDateChange={this.handleDateChange}
              handleChange={this.handleChange}
              handleResetValue={this.handleResetValue}
              getAccountStatus={this.getAccountStatus}
              readOnly={readOnly}
              coverage_start_date={this.state.coverage_start_date}
              underwriting_status={this.state.underwriting_status}
              coverage_end_date={this.state.coverage_end_date}
              employee_status={this.state.employee_status}
              errorMessages={errorMessages.coverageStartDateError}
              coverage_validate_role={"Employee"}
            />
          )}

          <Grid item className={classes.gridItem} xs={12} sm={6} style={{ marginTop: "10px" }}>
            <TextValidator
              //regex validation for special character
              //ref={this.employeeNameValidator}
              autoComplete="[off]"
              disabled={readOnly}
              fullWidth
              className={classes.textField}
              label="Employee Full Name"
              onChange={this.handleChange("full_name")}
              name="full_name"
              value={this.state.full_name}
              //validators={["required", "notBlank"]}
              validators={["required", "notBlank", "isNameValid", "lengthNames"]}
              inputProps={{
                maxLength: 150
              }}
              errorMessages={[
                intl.formatMessage({ id: "validator.required" }),
                "Please enter a valid value",
                "Invalid characters in name field",
                "Input exceeded 120 characters limit."
              ]}
            />
          </Grid>

          <Grid item className={classes.gridItem} xs={12} sm={6} style={{ marginTop: "10px" }}>
            <SelectValidatorElement
              key="category"
              value={this.state.category || ""}
              disabled={readOnly}
              autoComplete="[off]"
              onChange={this.handleChange("category")}
              resetValue={this.handleResetValue("category")}
              name={"category"}
              id={"category"}
              label="Category"
              validators={["required"]}
              suggestions={this.categories}
              errorMessages={[intl.formatMessage({ id: "validator.required" })]}
              variant="standard"
            />
          </Grid>

          <Grid item className={classes.gridItem} xs={12} sm={6}>
            <SelectValidatorElement
              value={this.state.nationality}
              disabled={readOnly}
              onChange={this.handleChange("nationality")}
              id="nationality"
              name="nationality"
              label="Nationality"
              autoComplete="[off]"
              validators={["required"]}
              suggestions={this.countries}
              resetValue={this.handleResetValue("nationality")}
              errorMessages={[intl.formatMessage({ id: "validator.required" })]}
              variant="standard"
            />
          </Grid>

          <Grid item className={classes.gridItem} xs={12} sm={6}>
            <TextValidator
              fullWidth
              disabled={readOnly}
              className={classes.textField}
              autoComplete="[off]"
              label="NRIC/FIN/Passport"
              id="passport"
              onChange={this.handleChange("id_no")}
              name="id_no"
              value={this.state.id_no}
              validators={["required", "notBlank", "isDuplicatedIdNo", "isNRICValid", "alphanumericValid"]}
              errorMessages={[
                intl.formatMessage({ id: "validator.required" }),
                "Please enter a valid value",
                intl.formatMessage({ id: "validator.duplicated.idNo" }),
                "You have filled in an invalid Singaporean NRIC",
                "Please enter alphanumeric characters only for NRIC/FIN/Passport"
              ]}
            />
          </Grid>

          {this.props.business_type == Enums.BUSINESS_TYPE.CONVERSION ? (
            <Grid item className={classes.gridItem} xs={12} sm={6}>
              <SelectValidatorElement
                innerRef={r => this.setRef(r, "foreign_worker")}
                value={
                  typeof this.state.foreign_worker === "boolean"
                    ? this.state.foreign_worker
                      ? "Y"
                      : "N"
                    : this.state.foreign_worker || ""
                }
                disabled={readOnly}
                onChange={this.handleChange("foreign_worker")}
                name={"foreign_worker"}
                id={"foreign_worker"}
                label="Foreign Worker (S-pass/Work Permit)"
                autoComplete="[off]"
                validators={["required", "isForeignWorkerValid"]}
                helperText={this.state.foreign_worker_help}
                suggestions={this.yesNo}
                resetValue={this.handleResetValue("foreign_worker")}
                errorMessages={[
                  intl.formatMessage({ id: "validator.required" }),
                  intl.formatMessage({ id: "validator.invalid.foreignWorker" })
                ]}
                variant="standard"
              />
            </Grid>
          ) : (
            <Grid item className={classes.gridItem} xs={12} sm={6}>
              <SelectValidatorElement
                innerRef={r => this.setRef(r, "foreign_worker")}
                value={this.state.foreign_worker || ""}
                disabled={readOnly}
                onChange={this.handleChange("foreign_worker")}
                name={"foreign_worker"}
                id={"foreign_worker"}
                label="Foreign Worker (S-pass/Work Permit)"
                autoComplete="[off]"
                validators={["required", "isForeignWorkerValid"]}
                helperText={this.state.foreign_worker_help}
                suggestions={this.yesNo}
                resetValue={this.handleResetValue("foreign_worker")}
                errorMessages={[
                  intl.formatMessage({ id: "validator.required" }),
                  intl.formatMessage({ id: "validator.invalid.foreignWorker" })
                ]}
                variant="standard"
              />
            </Grid>
          )}

          <Grid item className={classes.gridItem} xs={12} sm={6}>
            <SelectValidatorElement
              value={this.state.gender || ""}
              disabled={readOnly}
              onChange={this.handleChange("gender")}
              name={"gender"}
              id={"gender"}
              label="Gender"
              autoComplete="[off]"
              validators={["required"]}
              suggestions={this.genderValues}
              resetValue={this.handleResetValue("gender")}
              errorMessages={[intl.formatMessage({ id: "validator.required" })]}
              variant="standard"
            />
          </Grid>

          <Grid item className={classes.gridItem} xs={12} sm={6}>
            <SelectValidatorElement
              value={this.state.country_of_residence}
              disabled={readOnly}
              onChange={this.handleChange("country_of_residence")}
              id="country_of_residence"
              name="country_of_residence"
              label="Country Of Residence"
              autoComplete="[off]"
              validators={["required", "isResidencyValid"]}
              suggestions={this.country_of_residence}
              resetValue={this.handleResetValue("country_of_residence")}
              errorMessages={[
                intl.formatMessage({ id: "validator.required" }),
                "Please note not more than 50% of employees should reside outside of Singapore"
              ]}
              variant="standard"
            />
          </Grid>

          <Grid item className={classes.gridItem} xs={12} sm={6}>
            <DatePickerValidatorElement
              innerRef={r => this.setRef(r, "age")}
              fullWidth
              variant="standard"
              disabled={readOnly}
              name={"dob"}
              autoComplete="[off]"
              clearable
              label="Date Of Birth"
              value={this.formatDate(this.state.dob)}
              onChange={this.handleDateChange("dob")}
              format="DD/MM/YYYY"
              animateYearScrolling={true}
              keyboard
              validators={["required", "isAgeValid", "isDobValid"]}
              errorMessages={[
                intl.formatMessage({ id: "validator.required" }),
                errorMessages.age,
                "Year of birth does not tally with NRIC."
              ]}
            />
          </Grid>

          <Grid item className={classes.gridItem} xs={12} sm={6}>
            <DatePickerValidatorElement
              name={"date_of_employment"}
              disabled={readOnly}
              fullWidth
              variant="standard"
              clearable
              label="Employment Start Date"
              autoComplete="[off]"
              value={this.formatDate(this.state.date_of_employment)}
              onChange={this.handleDateChange("date_of_employment")}
              format="DD/MM/YYYY"
              animateYearScrolling={true}
              keyboard
              // removing employment start date validity as it is validated
              // in the new business rules with enhancement
              // "isEmploymentStartDateValid"
              validators={["required", "isDependentsCoverageDateValid"]}
              errorMessages={[
                intl.formatMessage({ id: "validator.required" }),
                // intl.formatMessage({ id: "validator.employmentStartDateValidity" }),
                intl.formatMessage({ id: "validator.dependentsCoverageDateValidity" })
              ]}
            />
          </Grid>

          <Grid item className={classes.gridItem} xs={12} sm={6}>
            <SelectValidatorElement
              value={this.state.marital_status || ""}
              disabled={readOnly}
              onChange={this.handleChange("marital_status")}
              name={"marital_status"}
              id={"marital_status"}
              label="Marital Status"
              autoComplete="[off]"
              validators={["required"]}
              suggestions={this.maritalStatusValues}
              resetValue={this.handleResetValue("marital_status")}
              errorMessages={[intl.formatMessage({ id: "validator.required" })]}
              variant="standard"
            />
          </Grid>

          <Grid item className={classes.gridItem} xs={12} sm={6}>
            <TextValidator
              fullWidth
              className={classes.textField}
              disabled={readOnly}
              autoComplete="[off]"
              label="Company Email"
              onChange={this.handleChange("email")}
              name="email"
              value={this.state.email}
              onBlur={() => this.triggerErrorApiValidationOnBlur(this.state.email)}
              validators={[
                "required",
                "isEmail",
                "isDuplicatedEmail",
                "isEmailValid",
                "isEmailDomainValid",
                "isEmailValidationViaAPI"
              ]}
              errorMessages={[
                intl.formatMessage({ id: "validator.required" }),
                intl.formatMessage({ id: "validator.email" }),
                intl.formatMessage({ id: "validator.duplicated.email" }),
                intl.formatMessage({ id: "validator.emailValid" }),
                intl.formatMessage({ id: "validator.emailDomainValid" }),
                this.state.emailError
              ]}
            />
          </Grid>

          <Grid item className={classes.gridItem} xs={12} sm={6}>
            <SelectValidatorElementInfo
              value={this.state.occupation_class || ""}
              disabled={readOnly}
              onChange={this.handleChange("occupation_class")}
              name={"occupation_class"}
              id={"occupation_class"}
              label="Occupation Class"
              autoComplete="[off]"
              validators={["required"]}
              suggestions={this.occupationClass}
              resetValue={this.handleResetValue("occupation_class")}
              handleClickInfoButton={this.handleClickInfoButton}
              handleInfoRefs={this.handleInfoRefs}
              errorMessages={[intl.formatMessage({ id: "validator.required" })]}
              variant="standard"
            />
          </Grid>

          {this.isSalesApp && (
            <Grid container spacing={"20px"} style={{ marginLeft: "8px", marginTop: "10px" }}>
              <Grid item className={classes.gridItem} xs={12} sm={6}>
                <TextValidator
                  //regex validation for special character
                  autoComplete="[off]"
                  disabled={readOnly}
                  fullWidth
                  className={classes.textField}
                  label="Bank Account Name (Optional)"
                  name="bank_account_name"
                  id="bank_account_name"
                  value={this.state.bank_account_name}
                  onChange={this.handleChange("bank_account_name")}
                />
              </Grid>
              <Grid item className={classes.gridItem} xs={12} sm={6}>
                <TextValidator
                  //regex validation for special character
                  autoComplete="[off]"
                  disabled={readOnly}
                  fullWidth
                  className={classes.textField}
                  label="Bank Account No. (Optional)"
                  name="bank_account_number"
                  id="bank_account_number"
                  value={this.state.bank_account_number}
                  onChange={this.handleChange("bank_account_number")}
                />
              </Grid>
              <Grid item className={classes.gridItem} xs={12} sm={6}>
                <TextValidator
                  //regex validation for special character
                  autoComplete="[off]"
                  disabled={readOnly}
                  fullWidth
                  className={classes.textField}
                  label="Bank Name (Optional)"
                  name="bank_name"
                  id="bank_name"
                  value={this.state.bank_name}
                  onChange={this.handleChange("bank_name")}
                />
              </Grid>
            </Grid>
          )}
          {!this.isSalesApp && Enums.PACKAGED_PLANS.includes(productCode) && (
            <>
              <Grid item className={classes.gridItem} xs={12} sm={6}>
                <TextValidator
                  //regex validation for special character
                  autoComplete="[off]"
                  disabled={readOnly}
                  fullWidth
                  className={classes.textField}
                  label="Bank Name"
                  name="bank_name"
                  id="bank_name"
                  value={this.state.bank_name}
                  onChange={this.handleChange("bank_name")}
                  validators={[]}
                  errorMessages={[]}
                />
              </Grid>
              <Grid item className={classes.gridItem} xs={12} sm={6}>
                <TextValidator
                  //regex validation for special character
                  autoComplete="[off]"
                  disabled={readOnly}
                  fullWidth
                  className={classes.textField}
                  label="Bank Account Number"
                  name="bank_account_number"
                  id="bank_account_number"
                  value={this.state.bank_account_number}
                  onChange={this.handleChange("bank_account_number")}
                  validators={[]}
                  errorMessages={[]}
                />
              </Grid>
              <Grid item className={classes.gridItem} xs={12} sm={6}>
                <TextValidator
                  //regex validation for special character
                  autoComplete="[off]"
                  disabled={readOnly}
                  fullWidth
                  className={classes.textField}
                  label="Bank Code"
                  name="bank_code"
                  id="bank_code"
                  value={this.state.bank_code}
                  onChange={this.handleChange("bank_code")}
                  validators={[]}
                  errorMessages={[]}
                />
              </Grid>
              <Grid item className={classes.gridItem} xs={12} sm={6}>
                <TextValidator
                  //regex validation for special character
                  autoComplete="[off]"
                  disabled={readOnly}
                  fullWidth
                  className={classes.textField}
                  label="Bank Account Name"
                  name="bank_account_name"
                  id="bank_account_name"
                  onChange={this.handleChange("bank_account_name")}
                  value={this.state.bank_account_name}
                  validators={[]}
                  errorMessages={[]}
                />
              </Grid>
              <Grid item className={classes.gridItem} xs={12} sm={6}>
                <TextValidator
                  //regex validation for special character
                  autoComplete="[off]"
                  disabled={readOnly}
                  fullWidth
                  className={classes.textField}
                  label="Branch Code"
                  name="branch_code"
                  id="branch_code"
                  onChange={this.handleChange("branch_code")}
                  value={this.state.branch_code}
                  validators={[]}
                  errorMessages={[]}
                />
              </Grid>
            </>
          )}
        </Grid>

        {/* Info Popover */}
        <InfoPopover
          open={infoPopover.open}
          data={infoPopover.data}
          element={infoPopover.element}
          handleClickInfoButton={this.handleClickInfoButton}
        />
      </ValidatorForm>
    );
  }
}

const styles = theme => ({
  textField: {},
  gridItem: {
    paddingBottom: "7px !important",
    paddingTop: "7px !important"
  },
  form: {
    display: "flex"
  }
});

function mapStateToProps(state, prop) {
  if (state.app.type === Enums.APP_TYPE.SALES) {
    return {
      app: state.app,
      employee: state.quote.employee,
      quote: state.quote,
      business_type: state.quote.business_type,
      document: state.document,
      emailErrors: state.validations.emailErrors
    };
  } else if (state.app.type === Enums.APP_TYPE.HR) {
    return {
      app: state.app,
      myEmployee: state.HR.myemployee,
      policy: state.HR.coverage.policy,
      emailErrors: state.validations.emailErrors,
      productCode: state.HR.coverage.policy.product_code
    };
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...quoteActions, ...employeeActions, ...validationsActions }, dispatch);
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(EmployeeEditForm))
);
