import { reduxForm } from "redux-form";
import React from "react";
import SimpleControlDialog from "../../../../components/SimpleControlDialog";

class DeleteQuoteDialog extends React.Component {
  /*componentWillReceiveProps(nextProps) {
    if (this.props.show !== nextProps.show) this.props.reset();
  }*/
  submit = () => {
    this.props.submitHandler();
  };
  render() {
    return (
      <SimpleControlDialog
        show={this.props.show}
        title="Confirm Delete Quotation"
        description="Are you sure you want to delete your Draft Quotation?"
        onClose={this.props.closeHandler}
        closeHandler={this.props.closeHandler}
        valid={true}
        onSubmit={this.props.handleSubmit(this.submit)}
        okButtonText="Yes"
        closeButtonText="No"
      />
    );
  }
}
export default reduxForm({
  form: "delete-quote-form"
})(DeleteQuoteDialog);
