import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import { withStyles } from "@mui/styles";
import { quoteActions } from "../../../../actions";
import quoteService from "../../../../services/quote.service";
import ProposalLink from "./proposalLink";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Enums from "../../../../utils/Enums";

class QuotesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quotes: this.sort(props.quotes)
    };

    this.Statuses = [
      {
        status: this.props.intl.formatMessage({ id: "policy.status.Draft" }),
        mapTo: ["ESTIMATE", "EMPLOYEE"]
      },
      {
        status: this.props.intl.formatMessage({ id: "policy.status.Proposed" }),
        mapTo: ["PROPOSAL", "SUBMISSION"]
      },
      {
        status: this.props.intl.formatMessage({
          id: "policy.status.Submitted"
        }),
        mapTo: ["SUBMITTED"]
      },
      {
        status: this.props.intl.formatMessage({ id: "policy.status.InForce" }),
        mapTo: ["INFORCE", "PROCESSED"]
      }
    ];
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ quotes: this.sort(nextProps.quotes) });
  }

  /**
   * Depends on statges will convert to status
   * Draft [ESTIMATE , EMPLOYEE]
   * Proposed [PROPOSAL, SUBMISSION]
   * Submitted [SUBMITTED]
   * In force [INFORCE, PROCESSED]
   */
  mappingStatus = stages => {
    let getStatus = this.Statuses.filter(status => {
      return status.mapTo.indexOf(stages) > -1;
    });
    return getStatus[0].status;
  };

  sort = quotes => {
    let sorted = quotes.sort(function(a, b) {
      return a.updated < b.updated ? 1 : -1;
    });
    return sorted;
  };

  getDisableStyling(quote, classes) {
    let disableStyling = "";
    if (quote.stages == Enums.STAGES.INFORCE || quote.stages == Enums.STAGES.SUBMITTED) {
      disableStyling = classes.disabledCompleted;
    }
    return disableStyling;
  }

  render() {
    const { classes, intl } = this.props;
    const { quotes } = this.state;
    return (
      <div>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell>Company Name</CustomTableCell>
                <CustomTableCell>Status</CustomTableCell>
                <CustomTableCell>Latest Updated</CustomTableCell>
                <CustomTableCell>{intl.formatMessage({ id: "quote.proposalDocument" })}</CustomTableCell>
                <CustomTableCell>{intl.formatMessage({ id: "quote.referenceNumber" })}</CustomTableCell>
                <CustomTableCell>Delete</CustomTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {quotes.map((quote, idx) => {
                const disableStyling = this.getDisableStyling(quote, classes);
                return (
                  <TableRow
                    className={classes.tableRow}
                    key={idx}
                    onClick={event => this.props.onRowClick(event, quote)}
                  >
                    <CustomTableCell className={disableStyling}>{quote.name}</CustomTableCell>
                    <CustomTableCell className={disableStyling}>{this.mappingStatus(quote.stages)}</CustomTableCell>
                    <CustomTableCell className={disableStyling}>
                      {moment(quote.updated).format("MM/DD/YYYY HH:mm:ss")}
                    </CustomTableCell>
                    <CustomTableCell style={{ cursor: "pointer" }}>
                      <ProposalLink statuses={this.Statuses} quote={quote} onPdfClick={this.props.onPdfClick} />
                    </CustomTableCell>
                    <CustomTableCell className={disableStyling}>{quote.policy_no || "-"}</CustomTableCell>
                    <CustomTableCell className={disableStyling}>
                      {this.mappingStatus(quote.stages) === "Draft" ? (
                        <DeleteIcon key={idx} onClick={event => this.props.onDeleteClick(event, quote)} />
                      ) : (
                        ""
                      )}
                    </CustomTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

const CustomTableCell = withStyles(theme => ({
  typeHead: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 14
  },
  typeBody: {
    fontSize: 14
  }
}))(TableCell);

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto"
  },
  downloadLink: {
    display: "flex",
    alignItems: "center"
  },
  downloadLinkText: {
    marginRight: theme.spacing.unit
  },
  tableRow: {
    cursor: "pointer",
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  disabledCompleted: {
    color: "#cccccc",
    cursor: "default"
  }
});

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(quoteActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(QuotesTable)));
