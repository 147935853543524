import Enums from "../../../../utils/Enums";

const GAMRBenefits = {
  headers: [
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "30%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Accidental Medical Reimbursement</strong>`,
          info: `<i>(Optional Benefit to GADD)</i>`
        },
        {
          data: `S$ 5,000`,
          attributes: {
            colspan: 5,
            style: {
              width: "46%",
              textAlign: "right"
            }
          }
        }
      ]
    }
  ],
  footnotes: []
};

let GAMRPremium = {
  title: `Annual Premium Rate (inclusive of 8% GST) – Group Accidental Medical Reimbursement`,
  headers: [
    {
      title: `Occupational Class`,
      mandatory: true
    },
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Class 1</strong>`,
          attributes: {
            rowspan: 2
          }
        },
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 37.35`
        },
        {
          data: `S$ 37.35`
        },
        {
          data: `S$ 37.35`
        },
        {
          data: `S$ 37.35`
        },
        {
          data: `S$ 37.35`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 156.45`
        },
        {
          data: `S$ 156.45`
        },
        {
          data: `S$ 156.45`
        },
        {
          data: `S$ 156.45`
        },
        {
          data: `S$ 156.45`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>Class 2</strong>`,
          attributes: {
            rowspan: 2
          }
        },
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 46.43`
        },
        {
          data: `S$ 46.43`
        },
        {
          data: `S$ 46.43`
        },
        {
          data: `S$ 46.43`
        },
        {
          data: `S$ 46.43`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 194.80`
        },
        {
          data: `S$ 194.80`
        },
        {
          data: `S$ 194.80`
        },
        {
          data: `S$ 194.80`
        },
        {
          data: `S$ 194.80`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>Class 3</strong>`,
          attributes: {
            rowspan: 2
          }
        },
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 64.59`
        },
        {
          data: `S$ 64.59`
        },
        {
          data: `S$ 64.59`
        },
        {
          data: `S$ 64.59`
        },
        {
          data: `S$ 64.59`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 272.53`
        },
        {
          data: `S$ 272.53`
        },
        {
          data: `S$ 272.53`
        },
        {
          data: `S$ 272.53`
        },
        {
          data: `S$ 272.53`
        }
      ]
    }
  ],
  footnotes: []
};

//   ----------------------------------------------------------------------
const GAMR = {
  id: 7,
  code: "GAMR",
  label: "Group Accidental Medical Reimbursement (GAMR) (for Employee only)",
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GAMRBenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium",
      data: [GAMRPremium]
    }
  ]
};

export default GAMR;
