import React from "react";
import { ValidatorComponent } from "react-material-ui-form-validator";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import Icon from "@mui/material/Icon";

class SelectValidatorElementInfo extends ValidatorComponent {
  constructor(props) {
    super(props);
    //validate if uploaded data (select box) is correct or not
    //so we reset it value to empty, otherwise the validator can not valid
    if (this.props.value !== "" && this.props.suggestions && this.props.resetValue) {
      if (this.props.suggestions.filter(c => c.value === this.props.value) <= 0) {
        //reset this value
        this.props.resetValue();
      }
    }
  }

  renderValidatorComponent() {
    const {
      classes,
      errorMessages,
      validators,
      requiredError,
      errorText,
      validatorListener,
      resetValue,
      handleInfoRefs,
      handleClickInfoButton,
      id,
      label,
      suggestions,
      ...rest
    } = this.props;
    const { isValid } = this.state;
    return (
      <FormControl fullWidth margin="none" error={!isValid}>
        <InputLabel style={{ left: "-15px" }} htmlFor={id} className={classNames(!isValid && classes.labelError)}>
          {label}
        </InputLabel>
        <Select
          {...rest}
          renderValue={selected => {
            let selectRecords = suggestions.filter(v => {
              return v.value === selected;
            });
            return selectRecords.length > 0 ? selectRecords[0].label : "";
          }}
        >
          {suggestions.map(option => (
            <MenuItem key={option.value} value={option.value} disableRipple>
              <div className={classes.rootInfo}>
                <Icon
                  style={{ color: "#c0c0c0", width: 24, height: 24 }}
                  id={`btnInfo_${option.value}`}
                  ref={el => handleInfoRefs(`btnInfo_${option.value}`, el)}
                  onClick={handleClickInfoButton(option.description, `btnInfo_${option.value}`)}
                >
                  information
                </Icon>
              </div>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText style={{ marginLeft: "0px" }} className={classNames(!isValid && classes.error)}>
          {this.errorText()}
        </FormHelperText>
      </FormControl>
    );
  }

  errorText() {
    const { isValid } = this.state;
    if (isValid) {
      return null;
    }

    return <span>{this.getErrorMessage()}</span>;
  }
}

const styles = theme => ({
  error: {
    color: "#f44336"
  },
  labelError: {
    color: "#f44336"
  },
  rootInfo: {
    width: 30,
    height: 24
  }
});
export default withStyles(styles, { withTheme: true })(SelectValidatorElementInfo);
