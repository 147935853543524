import FormHelperText from "@mui/material/FormHelperText";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import React, { PureComponent } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";

class DatePickerWithValidator extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedDate: null
    };
  }

  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };
  render() {
    const {
      classes,
      input,
      label,
      errorMessages,
      validators,
      requiredError,
      errorText,
      validatorListener,
      helperText,
      onChange,
      ...rest
    } = this.props;
    const { isValid } = this.state;
    const { error, warning } = this.props.meta;
    return (
      <div>
        <DatePicker
          {...rest}
          format="DD/MM/YYYY"
          slotProps={{
            textField: {
              margin: "normal",
              label: label,
              error: error !== undefined,
              fullWidth: rest.fullWidth,
              helperText: this.errorText(helperText),
              variant: rest.variant
            }
          }}
          value={rest.inputProps.value}
          onChange={input.onChange}
        />
      </div>
    );
  }
  errorText(defaultText) {
    const { isValid } = this.state;
    if (isValid) {
      return defaultText;
    }

    return defaultText;
  }
}

const styles = theme => ({
  error: {
    whiteSpace: "pre-line"
  }
});

export default withStyles(styles, { withTheme: true })(DatePickerWithValidator);
