import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import config from "../../../config/config";
import Enums from "../../../utils/Enums";
import muiTheme from "../../../themes";
import React, { Component } from "react";
import Typography from "@mui/material/Typography";

class CallHelperBottom extends Component {
  goToEmployeePage = () => {
    window.open("//" + config.employeeUrl, "_blank", "noopener noreferrer");
  };

  render() {
    const { drawerOpen, classes } = this.props;
    return (
      <div className={classNames(classes.menuBottomWrapper, !drawerOpen && classes.menuBottomWrapperClose)}>
        <div>
          {config.app.type !== Enums.APP_TYPE.SALES && (
            <div className={classes.container}>
              <div className={classes.callContent}>
                <div className={classes.callIcon}>
                  <i className="material-icons">call</i>
                </div>
                <div>
                  <Typography className={classes.callText}>Call PruCustomer Line</Typography>
                  <a href="tel:1800 835 9733" style={{ textDecoration: "none" }}>
                    <Typography href="tel:1800 835 9733" color="primary" className={classes.callNumber}>
                      1800 835 9733
                    </Typography>
                    <Typography className={classes.callText}>(Mon-Fri, 8:30am to 5:30pm)</Typography>
                  </a>
                  <a href="tel:+65 6233 3299" style={{ textDecoration: "none" }}>
                    <Typography href="tel:+65 6233 3299" color="primary" className={classes.callNumber}>
                      +65 6233 3299
                    </Typography>
                    <Typography className={classes.callText}>if you're dialling from overseas</Typography>
                  </a>
                </div>
              </div>

              <div className={classes.emailContent}>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <div>
                  <hr />
                  <Typography className={classes.emailText}>Email us at</Typography>
                  <a href="mailto:SGP.EB.Enquiry@prudential.com.sg" style={{ textDecoration: "none" }}>
                    <Typography
                      href="mailto:SGP.EB.Enquiry@prudential.com.sg"
                      color="primary"
                      className={classes.emailUs}
                    >
                      SGP.EB.Enquiry@prudential.com.sg
                    </Typography>
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  menuBottomWrapper: {
    opacity: 1,
    transition: "all 0.2s linear",
    backgroundColor: muiTheme.colors.blackScale.black50
  },
  menuBottomWrapperClose: {
    opacity: 0,
    maxHeight: 0,
    overflow: "hidden"
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(6)
  },
  callContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  callText: {
    color: "#6A6B6D",
    fontSize: "0.9rem",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    whitepspace: "nowrap",
    lineHeight: "1.2"
  },
  callNumber: {
    fontWeight: 600,
    color: "#ED1B24",
    fontSize: "1rem",
    lineHeight: "1.2"
  },
  callIcon: {
    color: muiTheme.colors.secondary.white,
    backgroundColor: muiTheme.colors.primary.red,
    borderRadius: "50%",
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(1)
  },
  tncBtn: {
    textAlign: "center",
    color: muiTheme.colors.secondary.blue70,
    "&:visited": {
      color: muiTheme.colors.secondary.blue70
    }
  },
  button: {
    marginTop: 20,
    fontWeight: 700,
    fontSize: 18,
    height: 51,
    width: "100%",
    color: muiTheme.colors.blackScale.black100,
    border: "2px solid " + muiTheme.colors.blackScale.black60
  },
  emailUs: {
    fontWeight: 600,
    color: "#ED1B24",
    fontSize: "0.8rem",
    lineHeight: "1.2"
  },
  emailText: {
    color: "#6A6B6D",
    fontSize: "0.9rem",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    whitespace: "nowrap",
    lineHeight: "1.2",
    textAlign: "center"
  },
  emailContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  }
});

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(CallHelperBottom))
);
