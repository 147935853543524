import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import BenefitInfo from "./benefit-info";
import React, { Component } from "react";
import _ from "lodash";
import assets from "../../../../../assets";

const ProductNotAvailable = ({ classes }) => {
  return <div className={classes.content}>Product not available</div>;
};

class BenefitDetail extends Component {
  render() {
    const { classes } = this.props;
    const { type } = this.props.match.params;
    const agentType = this.props.agentType || "AD";
    const availableProducts = assets.productInfo.availableProducts[agentType];
    const productAllowed = _.includes(availableProducts, type);
    return (
      <div className={classes.root}>
        {productAllowed ? <BenefitInfo type={type} /> : <ProductNotAvailable {...this.props} />}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    backgroundColor: theme.background.color,
    height: "100%"
  },
  content: {
    padding: theme.spacing.unit * 2.5,
    paddingTop: 10
  }
});

function mapStateToProps(state) {
  return { agentType: state.user.auth.pcode };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(BenefitDetail));
