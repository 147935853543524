import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const SubmitQuoteForm = props => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Typography variant="caption">Company Name</Typography>
      <Typography variant="subheading" gutterBottom>
        {props.companyName}
      </Typography>

      <Grid container spacing={"24px"}>
        <Grid item sm={12} md={6}>
          <Typography variant="caption">Plan Coverage Start Date</Typography>
          <Typography variant="subheading" gutterBottom>
            {props.activationDate}
          </Typography>
        </Grid>
        <Grid item sm={12} md={6}>
          <Typography variant="caption">Actual Annual Premium</Typography>
          <Typography variant="subheading" gutterBottom>
            {props.actualAmount}
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default SubmitQuoteForm;
