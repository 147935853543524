import Enums from "../../../../utils/Enums";

const GADDBenefits = {
  headers: [
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "30%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Sum Assured</strong>`
        },
        {
          data: `S$ 500,000`
        },
        {
          data: `S$ 200,000`
        },
        {
          data: `S$ 150,000`
        },
        {
          data: `S$ 80,000`
        },
        {
          data: `S$ 50,000`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>Optional Benefit : Accidental Medical Reimbursement</strong>`
        },
        {
          data: `S$ 5,000`,
          attributes: {
            colspan: 5,
            style: {
              width: "100%",
              textAlign: "center"
            }
          }
        }
      ]
    }
  ],
  footnotes: []
};

let GADDPremium = {
  title: `Annual Premium Rate (inclusive of 8% GST) – Group Accidental Death & Dismemberment`,
  headers: [
    {
      title: `Occupational Class`,
      mandatory: true
    },
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Class 1</strong>`,
          attributes: {
            rowspan: 2
          }
        },
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 155.44`
        },
        {
          data: `S$ 66.61`
        },
        {
          data: `S$ 50.47`
        },
        {
          data: `S$ 27.25`
        },
        {
          data: `S$ 17.16`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 636.90`
        },
        {
          data: `S$ 276.57`
        },
        {
          data: `S$ 206.92`
        },
        {
          data: `S$ 111.02`
        },
        {
          data: `S$ 69.65`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>Class 2</strong>`,
          attributes: {
            rowspan: 2
          }
        },
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 184.71`
        },
        {
          data: `S$ 80.75`
        },
        {
          data: `S$ 60.57`
        },
        {
          data: `S$ 32.30`
        },
        {
          data: `S$ 20.19`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 759.02`
        },
        {
          data: `S$ 335.10`
        },
        {
          data: `S$ 251.33`
        },
        {
          data: `S$ 134.24`
        },
        {
          data: `S$ 83.78`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>Class 3</strong>`,
          attributes: {
            rowspan: 2
          }
        },
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 243.25`
        },
        {
          data: `S$ 108.00`
        },
        {
          data: `S$ 81.76`
        },
        {
          data: `S$ 43.41`
        },
        {
          data: `S$ 27.25`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 1,002.28`
        },
        {
          data: `S$ 451.18`
        },
        {
          data: `S$ 338.13`
        },
        {
          data: `S$ 180.67`
        },
        {
          data: `S$ 113.04`
        }
      ]
    }
  ],
  footnotes: []
};

let GAMRPremium2 = {
  title: `Annual Premium Rate (inclusive of 8% GST) – Group Accidental Medical Reimbursement`,
  headers: [
    {
      title: `Occupational Class`,
      mandatory: true
    },
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Class 1</strong>`,
          attributes: {
            rowspan: 2
          }
        },
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 37.35`
        },
        {
          data: `S$ 37.35`
        },
        {
          data: `S$ 37.35`
        },
        {
          data: `S$ 37.35`
        },
        {
          data: `S$ 37.35`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 156.45`
        },
        {
          data: `S$ 156.45`
        },
        {
          data: `S$ 156.45`
        },
        {
          data: `S$ 156.45`
        },
        {
          data: `S$ 156.45`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>Class 2</strong>`,
          attributes: {
            rowspan: 2
          }
        },
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 46.43`
        },
        {
          data: `S$ 46.43`
        },
        {
          data: `S$ 46.43`
        },
        {
          data: `S$ 46.43`
        },
        {
          data: `S$ 46.43`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 194.80`
        },
        {
          data: `S$ 194.80`
        },
        {
          data: `S$ 194.80`
        },
        {
          data: `S$ 194.80`
        },
        {
          data: `S$ 194.80`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>Class 3</strong>`,
          attributes: {
            rowspan: 2
          }
        },
        {
          data: `<strong>16 - 69</strong>`
        },
        {
          data: `S$ 64.59`
        },
        {
          data: `S$ 64.59`
        },
        {
          data: `S$ 64.59`
        },
        {
          data: `S$ 64.59`
        },
        {
          data: `S$ 64.59`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 272.53`
        },
        {
          data: `S$ 272.53`
        },
        {
          data: `S$ 272.53`
        },
        {
          data: `S$ 272.53`
        },
        {
          data: `S$ 272.53`
        }
      ]
    }
  ],
  footnotes: []
};
//   ----------------------------------------------------------------------
const GADD = {
  id: 6,
  code: "GADD",
  label: "Group Accidental Death & Dismemberment (GADD) (for Employee only)",
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GADDBenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium (inclusive of 8% GST)",
      data: [GADDPremium, GAMRPremium2]
    }
  ]
};

export default GADD;
