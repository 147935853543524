import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "../../../actions";
import DOMPurify from "dompurify";
import { DialogTitle, Divider, Typography } from "@mui/material";

const RenewalNotification = ({ classes, onClose }) => {
  const [policies, setPolicies] = useState([]);
  const app = useSelector(state => state.app);
  const renewPolicies = useSelector(state => state.renewPolicies);
  const dispatch = useDispatch();
  useEffect(() => {
    setPolicies(renewPolicies.pendingList);
    const shouldDisplayNotification = true;
    if (shouldDisplayNotification) {
      // Ensure mmp.config is defined and imported correctly
      // mmp.config({
      //   displayRenewalNotification: true
      // });
    }
  }, []);

  const closeDialog = () => {
    dispatch(appActions.setRenewalNotificationVisibility(false));
    onClose();
    // Optionally, trigger the display of showRenewalNotification here if needed
    // For example, if closing firstPageDialog should automatically open showRenewalNotification
    // dispatch(appActions.setShowRenewalNotification(true));
  };

  const renderDetails = () => {
    if (!app.message) return null;

    return app.message.map((item, idx) => (
      <Typography
        key={idx}
        className={classes.typography}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(item)
        }}
      />
    ));
  };

  const renderPolicies = () => {
    return policies.map((policy, index) => (
      <>
        <tr key={index}>
          <td colSpan={3}>
            <Divider />
          </td>
        </tr>

        <tr key={index} className={classes.tableContent}>
          <td>{policy.company_name}</td>
          <td>{policy.policy_no}</td>
          <td>{policy.expiry_date}</td>
        </tr>
      </>
    ));
  };

  return (
    <Dialog
      open={app.showRenewalNotification}
      onClose={closeDialog}
      disableBackdropClick
      maxWidth={false}
      classes={{
        paper: classes.dialogPaper
      }}
    >
      <DialogTitle className={classes.dialogTitle}>Notice</DialogTitle>
      <DialogContent className={classes.dContent}>
        <>{renderDetails()}</>
        <table className={classes.renewalTable}>
          <thead>
            <tr>
              <th className={classes.tableHead}>Company Name</th>
              <th className={classes.tableHead}>Ref/Policy No.</th>
              <th className={classes.tableHead}>Expiry Date</th>
            </tr>
          </thead>
          <tbody>{renderPolicies()}</tbody>
        </table>

        <Button onClick={closeDialog} className={classes.closeButtonFab}>
          <i className="material-icons">close</i>
        </Button>

        <DialogActions
          classes={{
            root: classes.dialogActions
          }}
        >
          <Button onClick={closeDialog} className={classes.closeButton} variant="contained" color="primary">
            Close
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const styles = theme => ({
  dialogTitle: {
    textAlign: "center",
    fontWeight: 600,
    backgroundColor: "#ffffff"
  },
  dContent: {
    backgroundColor: "#eeeeee"
  },
  dialogPaper: {
    maxWidth: 700
  },
  typography: {
    fontSize: "1rem",
    fontWeight: 600,
    padding: "30px 0px 0px 0px"
  },
  closeButton: {
    minWidth: 130,
    margin: "40px 0px 30px 0px"
  },
  dialogContent: {
    padding: "1rem"
  },
  closeButtonFab: {
    position: "fixed",
    top: 20,
    right: 20,
    [theme.breakpoints.down("sm")]: {
      opacity: 0.3,
      "&:hover": {
        opacity: 1
      }
    }
  },
  dialogActions: {
    justifyContent: "center"
  },
  renewalTable: {
    width: "100%",
    marginTop: "30px"
  },
  tableHead: {
    fontWeight: 500,
    textAlign: "left",
    fontSize: "1.2rem",
    color: "#7f7f7f",
    paddingBottom: "10px"
  }
});

export default withStyles(styles, { withTheme: true })(RenewalNotification);
