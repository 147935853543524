import React from "react";
import { ValidatorComponent } from "react-material-ui-form-validator";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { withStyles } from "@mui/styles";
import classNames from "classnames";

class SelectValidatorElement extends ValidatorComponent {
  constructor(props) {
    super(props);

    //validate if uploaded data (select box) is correct or not
    //so we reset it value to empty, otherwise the validator can not valid
    if (this.props.value !== "" && this.props.suggestions && this.props.resetValue) {
      if (this.props.suggestions.filter(c => c.value === this.props.value) <= 0) {
        //reset this value
        this.props.resetValue();
      }
    }
    props.innerRef?.(this);
  }

  renderValidatorComponent() {
    const {
      classes,
      errorMessages,
      validators,
      requiredError,
      errorText,
      helperText,
      validatorListener,
      resetValue,
      id,
      label,
      suggestions,
      innerRef,
      ...rest
    } = this.props;
    const { isValid } = this.state;
    return (
      <FormControl fullWidth margin="none" error={!isValid}>
        <InputLabel style={{ left: "-15px" }} htmlFor={id} className={classNames(!isValid && classes.labelError)}>
          {label}
        </InputLabel>
        <Select {...rest}>
          {suggestions.map((option, idx) => (
            <MenuItem key={idx} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText style={{ marginLeft: "0px" }} className={classNames(!isValid && classes.error)}>
          {this.errorText(helperText)}
        </FormHelperText>
      </FormControl>
    );
  }

  errorText(helperText = "") {
    const { isValid } = this.state;
    if (isValid) {
      return helperText;
    }

    return <span>{this.getErrorMessage()}</span>;
  }
}

const styles = theme => ({
  error: {
    color: "#f44336"
  },
  labelError: {
    color: "#f44336"
  }
});

export default withStyles(styles, { withTheme: true })(SelectValidatorElement);
