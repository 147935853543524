import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import moment from "moment";
import { bindActionCreators } from "redux";

import CustomBreadcrumb from "../../../../components/CustomBreadcrumb";
import PolicyClaimExpensePanel from "./PolicyClaimExpensePanel";
import PolicyClaimExpenseTable from "./PolicyClaimExpenseTable";
import LoadingPopup from "../../../../components/LoadingPopup";
import SnackBar from "../../../../components/SnackBar";

import { policyDetailsActions } from "../../../../actions/policyDetails.action";
import { policyClaimExpenseAction } from "../../../../actions/policyClaimExpense.action";

const FORMAT_DATE = "DD/MM/YYYY";

class PolicyClaimExpenses extends Component {
  state = {
    memberType: "ALL",
    claimType: "ALL",
    fromDate: null,
    toDate: null,
    dateType: "CONSULTATION_DATE",
    isProcessing: false,
    errors: {},
    currentPageNo: 0
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleDateError = (name, error) => errorDateValue => {
    this.setState(prevState => ({
      [name]: errorDateValue,
      errors: { ...prevState.errors, [name]: error }
    }));
  };

  validateForm = () => {
    const { memberType, claimType, fromDate, toDate } = this.state;
    const errors = {};

    if (!memberType) errors.memberType = "Please select member type.";
    if (!claimType) errors.claimType = "Please select claim type.";

    const from = this.convertDate(fromDate);
    const to = this.convertDate(toDate);

    if (from && to && from > to) {
      errors.consultationDate = "Consultation 'From Date' must be prior to 'To Date'.";
    }

    this.setState({ errors });
    return Object.values(errors).some(e => !!e) ? errors : null;
  };

  setProcessingStatus = isProcessing => {
    this.setState({ isProcessing });
  };

  convertDate = datePickerValue => (datePickerValue ? moment(datePickerValue, FORMAT_DATE) : null);

  formatDate = datePickerValue => {
    const date = this.convertDate(datePickerValue);
    return date ? date.format(FORMAT_DATE) : "";
  };

  handleDateChange = name => date => {
    this.setState(prevState => ({
      [name]: date,
      errors: { ...prevState.errors, [name]: "" }
    }));
  };

  resetCurrentPageNo = () => {
    this.setState({ currentPageNo: 0 });
  };

  handleSubmit = event => {
    event.preventDefault();
    const errors = this.validateForm();
    if (errors) {
      const firstError = Object.values(errors)[0];
      this.props.errorPopup(firstError);
      this.resetCurrentPageNo();
      return;
    }

    this.setProcessingStatus(true);

    const filter = {
      memberType: this.state.memberType,
      claimType: this.state.claimType,
      fromDate: this.formatDate(this.state.fromDate),
      toDate: this.formatDate(this.state.toDate),
      dateType: this.state.dateType
    };

    this.props.getFCClaimExpenses(filter).then(() => {
      this.setProcessingStatus(false);
      this.resetCurrentPageNo();
    });
  };

  handlePageChange = (event, pageIndex) => {
    this.setState({ currentPageNo: pageIndex });
  };

  handleExport = () => {
    this.setProcessingStatus(true);
    this.props.exportFCClaimExpenses(this.props.claimExpense.filter).then(() => {
      this.setProcessingStatus(false);
    });
  };

  formatDateDisplay = date => moment(date).format("DD MMM YYYY");

  componentWillUnmount() {
    this.props.clearOnExit();
  }

  render() {
    const { classes, policy, claimExpense, closePopup } = this.props;
    const { memberType, claimType, fromDate, toDate, dateType, isProcessing, currentPageNo } = this.state;

    const filter = { memberType, claimType, fromDate, toDate, dateType };

    const policyDetails = [
      { label: "Company Name", value: policy.companyName || "-" },
      { label: "Policy Number", value: policy.policyNo || "-" },
      {
        label: "Policy Period",
        value:
          policy.policyStartDate && policy.policyEndDate
            ? `${this.formatDateDisplay(policy.policyStartDate)} to ${this.formatDateDisplay(policy.policyEndDate)}`
            : "-"
      }
    ];

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.breadcrumb}>
            <CustomBreadcrumb currentPage="claimExpenses" companyName={policy.companyName} />
          </div>
          <Typography className={classes.title}>{policy.companyName && `${policy.companyName}'s`} Claims</Typography>

          <Grid item xs={12}>
            <div className={classes.container}>
              <div className={classes.contentWrapper}>
                {policyDetails.map((item, index) => (
                  <div key={index} className={classes.rowWrapper}>
                    <Typography component="p" className={classes.label}>
                      {item.label}
                    </Typography>
                    <Typography component="p">
                      <span className={classes.labelInfoStatus}>{item.value}</span>
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
          </Grid>

          <PolicyClaimExpensePanel
            filter={filter}
            isProcessing={isProcessing}
            handleChange={this.handleChange}
            handleDateChange={this.handleDateChange}
            handleDateError={this.handleDateError}
            handleSubmit={this.handleSubmit}
          />

          <PolicyClaimExpenseTable
            isProcessing={isProcessing}
            currentPageNo={this.state.currentPageNo}
            claimExpense={claimExpense.claimExpenseList}
            handlePageChange={this.handlePageChange}
            handleExport={this.handleExport}
          />
        </div>

        <LoadingPopup name="claim-expense-api-loading" open={claimExpense.notification.showLoading} />
        <SnackBar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={claimExpense.notification.popupType === "success" ? 4000 : 12000}
          type={claimExpense.notification.popupType}
          open={claimExpense.notification.showPopup}
          onClose={closePopup}
          message={claimExpense.notification.popupMessage}
        />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    marginBottom: theme.actionFooter.height,
    fontSize: "0.75em"
  },
  breadcrumb: {
    margin: "10px 10px",
    paddingBottom: "20px"
  },
  content: {
    padding: theme.spacing(2.5)
  },
  title: {
    fontSize: "20px",
    fontWeight: 600,
    paddingBottom: "5px",
    marginLeft: "10px",
    marginBottom: "20px"
  },
  container: {
    backgroundColor: "white",
    marginLeft: "10px",
    marginBottom: "5px"
  },
  contentWrapper: {
    padding: "20px",
    display: "flex",
    flexDirection: "column"
  },
  rowWrapper: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "15px"
  },
  label: {
    fontWeight: "bold",
    width: "30%"
  },
  labelInfoStatus: {
    marginLeft: "0px",
    textTransform: "capitalize"
  }
});

const mapStateToProps = state => ({
  policy: state.policyDetails.policy,
  claimExpense: state.policyClaimExpense
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(policyDetailsActions, dispatch),
  ...bindActionCreators(policyClaimExpenseAction, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(PolicyClaimExpenses)));
