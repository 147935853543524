import useOtp from "./hooks/useOtp";
import OtpSelector from "./component/OtpSelector";
import OTP_COMPONENTS from "./types/otpComponents";
import OTP_SELECTOR_CONTENTS from "./types/otpSelectorContents";
import { useEffect } from "react";

const Otp = ({
  txId,
  show,
  description,
  closeHandler,
  valid,
  reset,
  submitHandler,
  inputHandler,
  submitError,
  submitErrorMessage
}) => {
  const {
    otpState,
    selectedMethod,
    backHandlerOtpSelector,
    previousOtpMethod,
    anotherWayHandler,
    refreshNotificationMethods
  } = useOtp();

  const availableContents = Object.fromEntries(
    Object.entries(OTP_SELECTOR_CONTENTS).filter(([key]) => otpState?.availableMethods?.includes(key))
  );

  console.log("availableContents", availableContents);

  const filteredContents = Object.fromEntries(
    Object.entries(availableContents).filter(([key]) => key !== previousOtpMethod)
  );

  console.log("filteredContents", filteredContents);

  const closeButtonDisabled =
    Object.keys(availableContents).length === 0 || Object.keys(availableContents).length === 1;

  const handleClose = () => {
    closeHandler();
    refreshNotificationMethods();
  };

  const otpProps = {
    txId,
    show,
    description,
    closeHandler: handleClose,
    valid,
    reset,
    submitHandler,
    inputHandler,
    submitError,
    submitErrorMessage,
    anotherWayHandler,
    closeButtonDisabled
  };

  useEffect(() => {
    refreshNotificationMethods(); // Call on every load
  }, []);

  const DefaultOtpComponent = OTP_COMPONENTS[selectedMethod] || OTP_COMPONENTS["EMAIL"];

  return !selectedMethod ? (
    <OtpSelector
      open={show}
      onClose={backHandlerOtpSelector}
      onBack={backHandlerOtpSelector}
      contents={filteredContents}
    />
  ) : (
    <DefaultOtpComponent {...otpProps} />
  );
};

export default Otp;
