import * as actionTypes from "../../actions/types";

const initialState = {
  notification: {
    showLoading: false,
    showPopup: false,
    popupType: "success",
    popupMessage: ""
  },
  claimTypeList: [],
  filter: {
    memberType: "ALL",
    claimType: "ALL",
    fromDate: "",
    toDate: ""
  },
  claimExpenseList: []
};

export const claimexpense = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.HR_CLAIM_EXPENSE_SHOW_LOADING:
      return { ...state, notification: { ...state.notification, showLoading: true } };
    case actionTypes.HR_CLAIM_EXPENSE_CLOSE_LOADING:
      return { ...state, notification: { ...state.notification, showLoading: false } };
    case actionTypes.HR_CLAIM_EXPENSE_SHOW_POPUP_SUCCESS:
      return {
        ...state,
        notification: {
          ...state.notification,
          showPopup: true,
          popupType: "success",
          popupMessage: action.message
        }
      };
    case actionTypes.HR_CLAIM_EXPENSE_SHOW_POPUP_ERROR:
      return {
        ...state,
        notification: {
          ...state.notification,
          showPopup: true,
          popupType: "error",
          popupMessage: action.message
        },
        filter: initialState.filter,
        claimExpenseList: initialState.claimExpenseList
      };
    case actionTypes.HR_CLAIM_EXPENSE_CLOSE_POPUP:
      return {
        ...state,
        notification: {
          ...state.notification,
          showPopup: false
        }
      };
    case actionTypes.HR_CLAIM_EXPENSE_GET_CLAIM_TYPES_SUCCESS:
      return {
        ...state,
        claimTypeList: action.data
      };
    case actionTypes.HR_CLAIM_EXPENSE_GET_CLAIM_EXPENSES_SUCCESS:
      return {
        ...state,
        filter: action.filter,
        claimExpenseList: action.data
      };
    case actionTypes.HR_CLAIM_EXPENSE_CLEAR:
      return {
        ...initialState,
        claimTypeList: state.claimTypeList
      };
    default:
      return state;
  }
};
