import { APIResource, APIError, APIResponse } from "../utils/APIResource";

export default class ESignService {
  static getKofaxSign(quoteId) {
    return APIResource.get(`/v1/quote/${quoteId}/sign`)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getCallbackStatus(packageId) {
    return APIResource.get(`/v1/quote/document/${packageId}/kofaxCallbackStatus`)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getPackageStatus(packageId) {
    return APIResource.get(`/v1/quote/document/${packageId}/packageStatus`)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getSignedDocument(quote_id, packageId) {
    return APIResource.get(`/v1/quote/${quote_id}/document/${packageId}/signedDocument`)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }
}
