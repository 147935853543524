import * as actionTypes from "./types";
import Enums from "../utils/Enums";

import productService from "../services/product.service";
import { quoteActions } from "./quote.action";

export const productActions = {
  loadConfig,
  loadConfigByCodeAndVersion,
  loadEngine,
  setConfig
};

function loadConfig() {
  return (dispatch, getState) => {
    let state = getState();
    //TODO: when we have more products, to handle accordingly, for now we just get the hard-coded values
    return productService.loadLatestProductVersion(Enums.PRODUCT_CODE_PF3).then(
      latestProductResponse => {
        if (latestProductResponse.success) {
          let latestProductResponseData = latestProductResponse.data.config;
          console.log(latestProductResponseData);
          return productService.loadConfig(latestProductResponseData.code, latestProductResponseData.version).then(
            response => {
              if (response.success) {
                let data = response.data;
                dispatch(success(data));
                dispatch(quoteActions.init(data.productId, data.code));
                dispatch(quoteActions.setProductCodeAndVersion(data.code, data.version));
                console.log(data);
                return dispatch(loadEngine(latestProductResponseData.code, latestProductResponseData.version));
              }
            },
            error => {
              dispatch(failure(error));
              return Promise.reject(error);
            }
          );
        }
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function success(payload) {
    return { type: actionTypes.PRODUCT_CONFIG_LOAD_SUCCESS, payload };
  }
  function failure(error) {
    return { type: actionTypes.PRODUCT_CONFIG_LOAD_FAILURE, error };
  }
}

function loadConfigByCodeAndVersion(code, version) {
  return (dispatch, getState) => {
    //TODO: when we have more products, to handle accordingly, for now we just get the hard-coded values
    return productService.loadConfig(code, version).then(
      response => {
        if (response.success) {
          let data = response.data;
          dispatch(success(data));
          dispatch(quoteActions.init(data.productId, data.code));
          console.log(data);
          return dispatch(loadEngine(data.code, data.version));
        }
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function success(payload) {
    return { type: actionTypes.PRODUCT_CONFIG_LOAD_SUCCESS, payload };
  }
  function failure(error) {
    return { type: actionTypes.PRODUCT_CONFIG_LOAD_FAILURE, error };
  }
}

function setConfig(payload) {
  return dispatch => {
    dispatch({ type: actionTypes.PRODUCT_CONFIG_LOAD_SUCCESS, payload });
    return Promise.resolve("success");
  };
}

function loadEngine(code, version) {
  return dispatch => {
    console.log(code);
    console.log(version);
    return productService.loadEngine(code, version).then(
      response => {
        if (response.success) {
          let data = response.data;
          let tmp = {
            code: code,
            version: version,
            ...data[code]
          };
          dispatch(success(tmp));
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );

    function success(payload) {
      return { type: actionTypes.PRODUCT_ENGINE_LOAD_SUCCESS, payload };
    }
    function failure(error) {
      return { type: actionTypes.PRODUCT_ENGINE_LOAD_FAILURE, error };
    }
  };
}
