import GCCA from "./GCCA";
import GCCA_HR_PF3 from "./GCCA_HR_PF3";
import GEAC from "./GEAC";
import GEAC_HR_PF3 from "./GEAC_HR_PF3";
import GADD from "./GADD";
import GADD_HR_PF3 from "./GADD_HR_PF3";
import GAMR from "./GAMR";
import GAMR_HR_PF3 from "./GAMR_HR_PF3";
import GEMM from "./GEMM";
import GEMM_HR_PF3 from "./GEMM_HR_PF3";
import PGIH from "./PGIH";
import PGIH_HR_PF3 from "./PGIH_HR_PF3";
import PSIH from "./PSIH";
import PSIH_HR_PF3 from "./PSIH_HR_PF3";
import GDEN from "./GDEN";
import GDEN_HR_PF3 from "./GDEN_HR_PF3";
import PDIH from "./PDIH";
import PDIH_HR_PF3 from "./PDIH_HR_PF3";

// Plans ----------------------
// Separate GADD GAMR
// Merge GAMR to GADD 7/6/2023
const plans = [
  GCCA,
  GEAC,
  GEMM,
  PGIH,
  PSIH,
  GADD,
  GDEN,
  PDIH,
  GCCA_HR_PF3,
  GEAC_HR_PF3,
  GEMM_HR_PF3,
  PGIH_HR_PF3,
  PSIH_HR_PF3,
  GADD_HR_PF3,
  GAMR_HR_PF3,
  GDEN_HR_PF3,
  PDIH_HR_PF3
];

export default plans;
