import React, { Component } from "react";
import { withStyles } from "@mui/styles";

import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import PlanTable from "./PlanTablePF3";

class PlanContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 1
    };
  }
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.renderTabs()}
        {this.renderTable()}
      </div>
    );
  }

  renderTable = () => {
    const { data } = this.props;
    const { tiers, selectionDisplayMode } = this.props;

    return data.map((type, _idxType) => {
      if (this.state.tab === type.order) {
        let tables = type.data;
        return tables.map((table, _tableIdx) => {
          return (
            <Typography key={_tableIdx} component="div">
              <Typography variant="subheading" style={{ padding: 10 }}>
                <span
                  key={_tableIdx}
                  dangerouslySetInnerHTML={{
                    __html: table.title
                  }}
                />
              </Typography>

              <PlanTable data={table} tiers={tiers} selectionDisplayMode={selectionDisplayMode} />

              {/* Footnotes */}
              {table.footnotes &&
                table.footnotes.map((note, _idxNote) => {
                  return (
                    <div
                      key={_idxNote}
                      dangerouslySetInnerHTML={{
                        __html: note.data
                      }}
                    />
                  );
                })}
            </Typography>
          );
        });
      }
      return null;
    });
  };

  renderTabs = () => {
    const { types, data } = this.props;

    if (data.length > 1 && (!types || (types && types.length > 1))) {
      return (
        <Tabs value={this.state.tab} onChange={this.tabToggle} indicatorColor="primary" textColor="primary" fullWidth>
          {data.map((type, _idxType) => {
            if (!types || types.includes(type.type)) {
              return <Tab key={_idxType} value={type.order} label={type.label} />;
            } else {
              return null;
            }
          })}
        </Tabs>
      );
    } else {
      return;
    }
  };

  tabToggle = (event, value) => {
    this.setState({ tab: value });
  };
}

const styles = theme => ({
  root: {
    width: "100%",
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
});

export default withStyles(styles, { withTheme: true })(PlanContent);
