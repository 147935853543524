var domains_1 = require("./domains");
var regexNonASCII = /[^\x20-\x7E]/;
function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}
var DomainValidator = (function() {
  // regexNonASCII:any;
  function DomainValidator() {
    this.errors = {
      overflow: "Overflow: input needs wider integers to process",
      "not-basic": "Illegal input >= 0x80 (not a basic code point)",
      "invalid-input": "Invalid input"
    };
    console.log("domain created");
    this.regexSeparators = /[\x2E\u3002\uFF0E\uFF61]/g; // RFC 3490 separators
    this.initialN = 128; // 0x80
    this.initialBias = 72;
    this.stringFromCharCode = String.fromCharCode;
    this.delimiter = "-"; // '\x2D'
    this.maxInt = 2147483647; // aka. 0x7FFFFFFF or 2^31-1
    this.floor = Math.floor;
    this.tMin = 1;
    this.base = 36;
    this.tMax = 26;
    this.damp = 700;
    this.baseMinusTMin = this.base - this.tMin;
    this.skew = 38;
    this.domainLabelRegex = "[a-zA-Z0-9](?:[a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?";
    this.topLabelRegex = "[a-zA-Z](?:[a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?";
    this.domainNameRegex = "^(?:" + this.domainLabelRegex + "\\.)*(" + this.topLabelRegex + ")\\.?$";
    this._domainRegex = new RegExp(this.domainNameRegex);
  }
  DomainValidator.prototype.digitToBasic = function(digit, flag) {
    //  0..25 map to ASCII a..z or A..Z
    // 26..35 map to ASCII 0..9
    return digit + 22 + 75 * (digit < 26) - ((flag != 0) << 5);
  };
  DomainValidator.prototype.error = function(type) {
    throw new RangeError(this.errors[type]);
  };
  DomainValidator.prototype.adapt = function(delta, numPoints, firstTime) {
    var k = 0;
    delta = firstTime ? this.floor(delta / this.damp) : delta >> 1;
    delta += this.floor(delta / numPoints);
    for (; delta > (this.baseMinusTMin * this.tMax) >> 1; k += this.base) {
      delta = this.floor(delta / this.baseMinusTMin);
    }
    return this.floor(k + ((this.baseMinusTMin + 1) * delta) / (delta + this.skew));
  };
  DomainValidator.prototype.basicToDigit = function(codePoint) {
    if (codePoint - 0x30 < 0x0a) {
      return codePoint - 0x16;
    }
    if (codePoint - 0x41 < 0x1a) {
      return codePoint - 0x41;
    }
    if (codePoint - 0x61 < 0x1a) {
      return codePoint - 0x61;
    }
    return this.base;
  };
  DomainValidator.prototype.ucs2decode = function(string) {
    var output = [];
    var counter = 0;
    var length = string.length;
    while (counter < length) {
      var value = string.charCodeAt(counter++);
      if (value >= 0xd800 && value <= 0xdbff && counter < length) {
        // It's a high surrogate, and there is a next character.
        var extra = string.charCodeAt(counter++);
        if ((extra & 0xfc00) == 0xdc00) {
          output.push(((value & 0x3ff) << 10) + (extra & 0x3ff) + 0x10000);
        } else {
          // It's an unmatched surrogate; only append this code unit, in case the
          // next code unit is the high surrogate of a surrogate pair.
          output.push(value);
          counter--;
        }
      } else {
        output.push(value);
      }
    }
    return output;
  };
  DomainValidator.prototype.contains = function(a, obj) {
    var i = a.length;
    while (i--) {
      if (a[i] === obj) {
        return true;
      }
    }
    return false;
  };
  DomainValidator.prototype.encode = function(input) {
    var output = [];
    // Convert the input in UCS-2 to an array of Unicode code points.
    input = this.ucs2decode(input);
    // Cache the length.
    var inputLength = input.length;
    // Initialize the state.
    var n = this.initialN;
    var delta = 0;
    var bias = this.initialBias;
    // Handle the basic code points.
    for (var _i = 0; _i < input.length; _i++) {
      var currentValue = input[_i];
      if (currentValue < 0x80) {
        output.push(this.stringFromCharCode(currentValue));
      }
    }
    var basicLength = output.length;
    var handledCPCount = basicLength;
    // `handledCPCount` is the number of code points that have been handled;
    // `basicLength` is the number of basic code points.
    // Finish the basic string with a delimiter unless it's empty.
    if (basicLength) {
      output.push(this.delimiter);
    }
    // Main encoding loop:
    while (handledCPCount < inputLength) {
      // All non-basic code points < n have been handled already. Find the next
      // larger one:
      var m = this.maxInt;
      for (var _a = 0; _a < input.length; _a++) {
        var currentValue = input[_a];
        if (currentValue >= n && currentValue < m) {
          m = currentValue;
        }
      }
      // Increase `delta` enough to advance the decoder's <n,i> state to <m,0>,
      // but guard against overflow.
      var handledCPCountPlusOne = handledCPCount + 1;
      if (m - n > this.floor((this.maxInt - delta) / handledCPCountPlusOne)) {
        this.error("overflow");
      }
      delta += (m - n) * handledCPCountPlusOne;
      n = m;
      for (var _b = 0; _b < input.length; _b++) {
        var currentValue = input[_b];
        if (currentValue < n && ++delta > this.maxInt) {
          this.error("overflow");
        }
        if (currentValue == n) {
          // Represent delta as a generalized variable-length integer.
          var q = delta;
          for (var k = this.base; ; k += this.base) {
            var t = k <= bias ? this.tMin : k >= bias + this.tMax ? this.tMax : k - bias;
            if (q < t) {
              break;
            }
            var qMinusT = q - t;
            var baseMinusT = this.base - t;
            output.push(this.stringFromCharCode(this.digitToBasic(t + (qMinusT % baseMinusT), 0)));
            q = this.floor(qMinusT / baseMinusT);
          }
          output.push(this.stringFromCharCode(this.digitToBasic(q, 0)));
          bias = this.adapt(delta, handledCPCountPlusOne, handledCPCount == basicLength);
          delta = 0;
          ++handledCPCount;
        }
      }
      ++delta;
      ++n;
    }
    return output.join("");
  };
  DomainValidator.prototype.map = function(array, fn) {
    var result = [];
    var length = array.length;
    while (length--) {
      result[length] = fn(array[length]);
    }
    return result;
  };
  DomainValidator.prototype.mapDomain = function(string, fn) {
    var parts = string.split("@");
    var result = "";
    if (parts.length > 1) {
      // In email addresses, only the domain name should be punycoded. Leave
      // the local part (i.e. everything up to `@`) intact.
      result = parts[0] + "@";
      string = parts[1];
    }
    // Avoid `split(regex)` for IE8 compatibility. See #17.
    string = string.replace(this.regexSeparators, "\x2E");
    var labels = string.split(".");
    var encoded = this.map(labels, fn).join(".");
    return result + encoded;
  };
  /**
   * @param allowLocal   Should local addresses be considered valid?
   */
  DomainValidator.prototype.DomainValidator = function() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var _ref$allowLocal = _ref.allowLocal;
    var allowLocal = _ref$allowLocal === undefined ? false : _ref$allowLocal;
    _classCallCheck(this, DomainValidator);
    var domainLabelRegex = "[a-zA-Z0-9](?:[a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?";
    var topLabelRegex = "[a-zA-Z](?:[a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?";
    var domainNameRegex = "^(?:" + domainLabelRegex + "\\.)*(" + topLabelRegex + ")\\.?$";
    this._domainRegex = new RegExp(domainNameRegex);
  };
  DomainValidator.prototype._chompLeadingDot = function(str) {
    if (str[0] === ".") {
      return str.substring(1);
    }
    return str;
  };
  DomainValidator.prototype._unicodeToASCII = function(input) {
    return this.mapDomain(input, function(string) {
      return regexNonASCII.test(string) ? "xn--" + this.encode(string) : string;
    });
  };
  DomainValidator.prototype._arrayContains = function(sortedArray, key) {
    // TODO: use binary search
    return this.contains(sortedArray, key);
  };
  DomainValidator.prototype.isValidCountryCodeTld = function(ccTld) {
    var key = this._chompLeadingDot(this._unicodeToASCII(ccTld).toLowerCase());
    return this._arrayContains(domains_1.countryCodeTlds, key);
  };
  DomainValidator.prototype.isValidGenericTld = function(gTld) {
    var key = this._chompLeadingDot(this._unicodeToASCII(gTld).toLowerCase());
    return this._arrayContains(domains_1.genericTlds, key);
  };
  DomainValidator.prototype.isValidInfrastructureTld = function(iTld) {
    var key = this._chompLeadingDot(this._unicodeToASCII(iTld).toLowerCase());
    return this._arrayContains(domains_1.infrastructureTlds, key);
  };
  DomainValidator.prototype.isValidTld = function(tld) {
    tld = this._unicodeToASCII(tld);
    return this.isValidInfrastructureTld(tld) || this.isValidGenericTld(tld) || this.isValidCountryCodeTld(tld);
  };
  DomainValidator.prototype.extractTld = function(domain) {
    if (!domain) {
      console.log("in extract tld  !domain");
      return false;
    }
    domain = this._unicodeToASCII(domain);
    if (domain.length > 253) {
      return false;
    }
    var groups = domain.match(this._domainRegex);
    if (groups) {
      console.log("group");
      return groups[1];
    }
    return null;
  };
  DomainValidator.prototype.isValid = function(domain) {
    if (!domain) {
      return false;
    }
    domain = this._unicodeToASCII(domain);
    if (domain.length > 253) {
      console.log("too long");
      return false;
    }
    var groups = domain.match(this._domainRegex);
    if (groups) {
    }
    if (groups && groups.length > 1) {
      return this.isValidTld(groups[1]) && groups[0] !== groups[1];
    }
    return false;
  };
  return DomainValidator;
})();
export const DomainValidate = new DomainValidator();
