import { APIError, APIResource, APIResponse } from "../utils/APIResource";
import config from "../config/config";

export default class UserService {
  static forgotPassword(params) {
    return APIResource.post(
      `/v1/open/pwd`,
      {
        username: params.username,
        app: params.app
      },
      {}
    )
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static login(params) {
    let grant_type = "password";
    return APIResource.post(
      `oauth/token`,
      `grant_type=${grant_type}&username=${params.username}&password=${params.password}`,
      {
        headers: {
          Authorization: `Basic ${config.api.authBearer}`,
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    )
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static logout() {
    return APIResource.post("/v1/logout")
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }
  static refreshToken(token) {
    let grant_type = "refresh_token";

    return APIResource.post(`/oauth/token`, `grant_type=${grant_type}&refresh_token=${token}`, {
      headers: {
        Authorization: `Basic ${config.api.authBearer}`,
        "x-auth-app": config.app.type
      }
    })
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static CIAMLogin(params) {
    let grant_type = "password";
    console.log("oath/token login: " + params);
    return APIResource.post(
      `oauth/token`,
      `salt=${params.salt}&iv=${params.iv}&ciphertext=${params.ciphertext}&key=${params.key}&grant_type=${grant_type}&username=${params.username}&password=${params.password}&captchaTxn=${params.captchaTxn}&captchaCode=${params.captchaCode}`,

      {
        headers: {
          Authorization: `Basic ${config.api.authBearer}`,
          "x-auth-type": "CIAM",
          "x-auth-random-key": params.randomKey,
          "x-auth-tx-id": params.txnId,
          "x-auth-app": config.app.type,
          "x-auth-cnonce": params.cnonce,
          "x-auth-cnonce-hash": params.chash
        }
      }
    )
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        console.log("an error occured: " + error);
        return Promise.reject(new APIError(error));
      });
  }

  static getRSA(params) {
    return APIResource.get("/v1/open/auth/rsa", {
      headers: {
        "x-auth-app": config.app.type
      }
    })
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getOtp(params) {
    return APIResource.get("/v1/auth/otp", {
      headers: {
        "x-auth-type": "CIAM",
        "x-auth-random-key": params.randomKey,
        "x-auth-tx-id": params.txnId,
        "x-auth-app": config.app.type
      }
    })
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getResetPasswordOTP(code) {
    return APIResource.post("/v1/open/pwd/otp", {
      code: code
    })
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getResetLinkValidity(code) {
    return APIResource.get(`/v1/open/pwd/${code}/status`)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.resolve(new APIError(error));
      });
  }

  static putResetPasswordOTP(params) {
    return APIResource.put(
      "/v1/open/pwd",
      {
        code: params.code,
        otp: params.otp,
        new_password: params.new_password,
        confirm_password: params.confirm_password
      },
      {
        headers: {
          Authorization: `Basic ${config.api.authBearer}`,
          "x-auth-type": "CIAM",
          "x-auth-random-key": params.randomKey,
          "x-auth-tx-id": params.txnId,
          "x-auth-app": config.app.type
        }
      }
    )
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static postOTP(params) {
    return APIResource.post(
      "/v1/auth/otp/",
      {
        otp: params.otp
      },
      {
        headers: {
          "x-auth-type": "CIAM",
          "x-auth-random-key": params.randomKey,
          "x-auth-tx-id": params.txnId,
          "x-auth-app": config.app.type
        }
      }
    )
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static agreeTnc(params) {
    return APIResource.post("/v1/auth/tc", {
      marketing_consent: params.marketingConsent,
      tnc: params.tnc
    })
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getHRInfo(quote_id) {
    if (quote_id) {
      return APIResource.get(`/v1/quote/${quote_id}/company`)
        .then(resp => {
          return Promise.resolve(new APIResponse(resp));
        })
        .catch(error => {
          return Promise.reject(new APIError(error));
        });
    } else {
      return APIResource.get(`/v1/quote/company`)
        .then(resp => {
          return Promise.resolve(new APIResponse(resp));
        })
        .catch(error => {
          return Promise.reject(new APIError(error));
        });
    }
  }

  static getHRLoggedInfo(person_id) {
    return APIResource.get(`/v1/person/${person_id}`)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getBannerList(personId) {
    return APIResource.get(`/v1/employee/${personId}/banners`).then(
      response => {
        return Promise.resolve(new APIResponse(response));
      },
      error => {
        return Promise.reject(new APIError(error));
      }
    );
  }
}
