export default [
  {
    logo: require("../img/dashboard-sales-portal-img.png"),
    id: "salesPortal",
    title: "Sales Portal",
    description: "Recommend and select plans for your clients",
    module: "SALES_PORTAL"
  },
  {
    logo: require("..//img/dashboard-client-policy-list-img.png"),
    id: "clientPolicyList",
    title: "Client & Policy Information",
    description: "View existing client’s policy information",
    module: "POLICY_INFORMATION"
  }
  // {
  //   logo: require("..//img/dashboard-client-policy-list-img.png").default,
  //   id: "renewal",
  //   title: "Renewal Submission",
  //   description: "Renew your client’s policy with ease",
  //   module: "RENEWAL"
  // }
];
