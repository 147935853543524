import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import _ from "lodash";
import { withStyles } from "@mui/styles";
import { Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper } from "@mui/material";
import InfoButton from "../../../../../components/InfoButton";
import InfoPopover from "../../../../../components/InfoPopover";

class CategoryCustomisedTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      infoPopover: {
        open: false,
        element: null,
        data: ""
      }
    };
  }

  handleClickInfoButton = (data, id) => event => {
    let infoPopover = {
      ...this.state.infoPopover
    };
    infoPopover.open = !infoPopover.open;
    if (infoPopover.open) {
      infoPopover.element = this.refs[id];
      infoPopover.data = data;
    }
    this.setState({ infoPopover });
  };

  handleCloseDialog() {
    let infoPopover = {
      ...this.state.infoPopover,
      open: false
    };
    this.setState({ infoPopover });
  }

  renderInfoPopover = () => {
    const { infoPopover } = this.state;

    return (
      <InfoPopover
        open={infoPopover.open}
        data={infoPopover.data}
        element={infoPopover.element}
        handleClickInfoButton={this.handleClickInfoButton}
      />
    );
  };

  transposedPlansData = data => {
    const transposedData = [];
    let maxLength = 0;
    data.map(product => {
      if (product.plans.length > maxLength) {
        maxLength = product.plans.length;
      }
    });

    for (let i = 0; i < maxLength; i++) {
      const newRow = [];
      data.forEach(row => {
        newRow.push(row.plans[i] !== undefined ? row.plans[i] : null);
      });
      transposedData.push(newRow);
    }
    return transposedData;
  };

  render() {
    const { classes, policy } = this.props;
    const data = policy.customisedPlans;
    if (!data || !data.length) {
      return (
        <div className={classes.root}>
          <div className={classes.content}>
            <Typography color="inherit">No insurance coverage available.</Typography>
          </div>
        </div>
      );
    }

    const transposedData = this.transposedPlansData(data);

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Table>
            <TableHead>
              <TableRow>
                {data.map((product, index) => {
                  return (
                    <TableCell key={index} className={classes.tableHeaderCell}>
                      {product.productCode}
                      <InfoButton
                        className={classes.infoButton}
                        variant="outlined"
                        ref={`btnInfoBody${index}`}
                        id={`btnInfoBody${index}`}
                        data={product.description}
                        handleClickInfoButton={this.handleClickInfoButton}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {transposedData.map((plans, index) => {
                return (
                  <TableRow key={index}>
                    {plans.map((plan, planIndex) => {
                      return (
                        <TableCell key={index + planIndex} className={classes.tableBodyCell}>
                          {plan ? (
                            <div>
                              <Typography>{plan.planCode}</Typography>
                              <Typography>{plan.employeeCategory}</Typography>
                            </div>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {this.renderInfoPopover()}
        </Paper>
      </div>
    );
  }
  renderInfoDialog = () => {
    const { infoPopover } = this.state;

    return (
      <SimpleMessageDialog
        type="none"
        name="info"
        title="Info"
        description={infoPopover.data}
        alignCenter={false}
        isHtml={true}
        open={infoPopover.open}
        closeHandler={this.handleCloseDialog.bind(this)}
      />
    );
  };
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto"
  },
  tableHeaderCell: {
    color: "black",
    fontWeight: "bold",
    backgroundColor: "#f2f2f2",
    border: "0.1rem solid #e1e1e1",
    padding: "20px"
  },
  tableBodyCell: {
    color: "black",
    border: "0.1rem solid #e1e1e1",
    padding: "20px"
  },
  infoButton: {
    color: "black"
  }
});

function mapStateToProps(state) {
  return {
    policy: state.policyDetails.policy
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(CategoryCustomisedTable)));
