import { APIResource, APIError, APIResponse } from "../utils/APIResource";

let regenerateOtp = false;

export default class otpService {
  static getNotificationMethods() {
    regenerateOtp = false;
    return APIResource.get(`/v1/otp/notification-methods`)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static sendOtp(data, txId) {
    return APIResource.post(
      `/v1/otp/notify`,
      {
        notificationMethod: data,
        regenerateOtp: regenerateOtp
      },
      {
        headers: {
          "x-auth-type": "CIAM",
          "x-auth-tx-id": txId
        }
      }
    )
      .then(resp => {
        regenerateOtp = true;
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        console.error("Error sending OTP:", error);
        return Promise.reject(new APIError(error));
      });
  }
}
