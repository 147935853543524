import { APIResource, APIError, APIResponse } from "../../utils/APIResource";

export const UploadDocumentService = {
  uploadEmployeeDocument,
  getDocuments,
  downloadFile,
  downloadTemplate
};

function uploadEmployeeDocument(policyNo, companyId, file) {
  const formData = new FormData();
  formData.append("multipartFile", file);
  const config = {
    headers: {
      "content-type": "multipart/form-data"
    }
  };
  return APIResource.post(`/v1/employee/member-update/upload/${policyNo}/${companyId}`, formData, config)
    .then(resp => {
      return Promise.resolve(new APIResponse(resp));
    })
    .catch(error => {
      return Promise.reject(new APIError(error));
    });
}

function getDocuments(policyNos) {
  return APIResource.get(`/v1/employee/member-update/document?policyNos=${policyNos}`)
    .then(resp => {
      return Promise.resolve(new APIResponse(resp));
    })
    .catch(error => {
      return Promise.reject(new APIError(error));
    });
}

function downloadFile(policyNo, docId) {
  return APIResource.get(`/v1/employee/member-update/document/${policyNo}/${docId}`, { responseType: "blob" })
    .then(resp => {
      const response = new APIResponse(resp);
      return Promise.resolve({ ...response, filename: resp.headers.filename });
    })
    .catch(error => {
      return Promise.reject(new APIError(error));
    });
}

function downloadTemplate(docName) {
  return APIResource.get(`/v1/document/${docName}`)
    .then(resp => {
      const response = new APIResponse(resp);
      return response;
    })
    .catch(error => {
      return Promise.reject(new APIError(error));
    });
}
