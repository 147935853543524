import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import PolicyDetailsBoxItem from "../../../../components/PolicyDetailsBoxItem";
import BoxItemValues from "../../../../assets/data/boxItemValues";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import PolicyDetails from "./PolicyDetails";
import CustomBreadcrumb from "../../../../components/CustomBreadcrumb";
import LoadingPopup from "../../../../components/LoadingPopup";

class PolicyDashboard extends Component {
  constructor(props) {
    super(props);
  }

  renderBoxItemBottom = () => {
    let boxItems = BoxItemValues;

    this.listBoxItemComponents = boxItems.map((value, index) => {
      return (
        <Grid xs={12} sm={6} md={12 / boxItems.length} key={index} item>
          <PolicyDetailsBoxItem data={value} onClick={this.goToPage} />
        </Grid>
      );
    });
    return this.listBoxItemComponents;
  };

  goToPage = id => {
    const { history } = this.props;
    switch (id) {
      case "insuranceCoverage":
        history.push(`/auth/pi/insuranceCoverage`);
        return;
      case "myEmployees":
        return history.push(`/auth/pi/memberListing`);
      case "claimExpenses":
        return history.push(`/auth/pi/claimExpenses`);
      case "documents":
        return history.push(`/auth/pi/documents`);
      default:
        return;
    }
  };

  render() {
    const { classes, policy, showLoadingPopup } = this.props;
    return (
      <div className={classes.root}>
        <LoadingPopup title="popup.loading.default.title" name="load-policy-listing" open={showLoadingPopup} />
        <div className={classes.content}>
          <div className={classes.breadcrumb}>
            <CustomBreadcrumb currentPage="PolicyDashboard" companyName={policy.companyName} />
          </div>
          <Typography className={classes.title}>{policy.companyName && `${policy.companyName}'s`} Dashboard</Typography>
          <PolicyDetails policy={policy} goToPage={this.goToPage} />
        </div>
        <Grid className={classes.listBoxItem} container spacing={"8px"}>
          {this.renderBoxItemBottom()}
        </Grid>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    height: "100%"
  },
  breadcrumb: {
    margin: "10px 10px",
    paddingBottom: "20px"
  },
  content: {
    padding: "20px 0px"
  },
  title: {
    fontSize: "20px",
    fontWeight: "600 !important",
    paddingBottom: "5px",
    marginLeft: "10px"
  },
  "@media (max-width: 600px)": {
    label: {
      width: "50%"
    }
  },
  listBoxItem: {
    width: "100%",
    margin: 0,
    display: "flex",
    justifyContent: "space-between"
  }
});

function mapStateToProps(state) {
  return {
    currentPolicyNo: state.policyDetails.currentPolicyNo,
    policy: state.policyDetails.policy,
    showLoadingPopup: state.app.showLoadingPopup
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(PolicyDashboard)));
