import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import { Typography, Breadcrumbs, Link } from "@mui/material";
import { generateNavigation } from "../utils/Navigations";

class CustomBreadcrumb extends Component {
  render() {
    const { classes, currentPage, companyName } = this.props;
    const navigation = generateNavigation(currentPage, companyName);

    if (navigation && navigation.length > 0) {
      return (
        <Breadcrumbs separator=">" aria-label="breadcrumb">
          {navigation.map((nav, index) => {
            const isLast = index === navigation.length - 1;
            if (isLast) {
              return (
                <Typography key={index} color="text.primary" className={classes.font}>
                  {nav.text}
                </Typography>
              );
            } else {
              return (
                <Link
                  underline="hover"
                  key={index}
                  color="inherit"
                  href={nav.link}
                  className={classes.font2}
                  style={{ cursor: nav.link ? "pointer" : "default" }}
                >
                  {nav.text}
                </Link>
              );
            }
          })}
        </Breadcrumbs>
      );
    } else {
      return null;
    }
  }
}

const styles = theme => ({
  root: {},
  font: {
    fontWeight: "bold",
    fontSize: "12px",
    color: "#6D6D6D",
    cursor: "default"
  },
  font2: {
    fontSize: "12px",
    color: "#6D6D6D"
  }
});

export default withStyles(styles, { withTheme: true })(CustomBreadcrumb);
