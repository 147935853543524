import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function IconLogout(props) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#a)">
        <path d="M10.09 15.59 11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59ZM19 3H5a2 2 0 0 0-2 2v4h2V5h14v14H5v-4H3v4a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M0 0h24v24H0V0" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
