import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function IconMyEmployees(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M3.22 14.887a4.167 4.167 0 0 1 2.947-1.22h6.666A4.167 4.167 0 0 1 17 17.833V19.5a.833.833 0 0 1-1.667 0v-1.667a2.5 2.5 0 0 0-2.5-2.5H6.167a2.5 2.5 0 0 0-2.5 2.5V19.5A.833.833 0 0 1 2 19.5v-1.667c0-1.105.439-2.164 1.22-2.946ZM9.5 5.333a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm-4.167 2.5a4.167 4.167 0 1 1 8.334 0 4.167 4.167 0 0 1-8.334 0ZM17.86 14.4a.833.833 0 0 1 1.015-.598A4.167 4.167 0 0 1 22 17.832V19.5a.833.833 0 0 1-1.667 0v-1.666a2.5 2.5 0 0 0-1.875-2.419.833.833 0 0 1-.598-1.015Zm-3.334-9.998a.833.833 0 0 1 1.014-.6 4.167 4.167 0 0 1 0 8.072.833.833 0 1 1-.413-1.615 2.5 2.5 0 0 0 0-4.843.833.833 0 0 1-.601-1.014Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
}
