import { APIResource, APIError, APIResponse } from "../../utils/APIResource";

export default class CoverageService {
  static getPolicy(policyNumber, companyId) {
    const isNewURI = policyNumber && companyId;
    return APIResource.get(isNewURI ? `/v1/policy/${policyNumber}/${companyId}` : "/v1/person/policy")
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static getGaPolicyInfo(policy_no) {
    return APIResource.get(`/v1/policy/gaPolicy/${policy_no}`)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }
}
