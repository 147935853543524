import * as actionTypes from "../../actions/types";

const initialState = {
  clients: null
};

export const clientsAndPolicyListing = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLIENT_AND_POLICY_LISTING:
      return {
        ...state,
        clients: action.payload
      };
    default: {
      return state;
    }
  }
};
