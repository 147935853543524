import React, { Component } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Field, reduxForm } from "redux-form";
import FormHelperText from "@mui/material/FormHelperText";
import classNames from "classnames";
import { withStyles } from "@mui/styles";
import SimpleMessageDialog from "../../components/SimpleMessageDialog";
import SimpleControlDialog from "../../components/SimpleControlDialog";
import Util from "../../utils/Util";

const renderTextField = props => {
  const {
    input,
    label,
    children,
    isSelect,
    meta: { error, warning, touched },
    ...custom
  } = props;

  return (
    <div>
      <TextField
        margin="normal"
        fullWidth
        select={isSelect || false}
        error={touched && error !== undefined}
        label={label}
        {...input}
        {...custom}
      >
        {children}
      </TextField>
      {touched &&
        ((error && (
          <FormHelperText error={true} className={classNames({ "m-b-10": error })}>
            {error}
          </FormHelperText>
        )) ||
          (warning && (
            <FormHelperText error={true} className={classNames({ "m-b-10": warning })}>
              {warning}
            </FormHelperText>
          )))}
    </div>
  );
};

const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = "Required";
  }

  return errors;
};

class ForgotPassworDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      success: false,
      errorMessage: null
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.show !== nextProps.show) {
      nextProps.reset();
      this.setState({ success: false, errorMessage: null });
    }
  }

  handleCloseSuccess() {
    this.setState({ success: [] }, () => {
      this.props.closeHandler();
    });
  }

  submit = values => {
    const errors = validate(values);

    if (Util.isEmpty(errors)) {
      this.props.forgotPassword(values.email.trim().toLowerCase(), this.props.app).then(
        resp => {
          console.log(resp);
          if (resp.success) this.setState({ success: true, email: values.email });
          else this.setState({ errorMessage: resp.message });
        },
        error => {
          this.setState({
            errorMessage: error.customMessage || error.message
          });
        }
      );
    }
  };

  handleInputChange = event => {
    const target = event.target;
    this.setState({
      [target.name]: target.value
    });
  };

  handleClose() {
    this.setState({ success: false }, () => {
      this.props.closeHandler();
    });
  }

  render() {
    const { email, success, errorMessage } = this.state;
    const { classes, show, valid } = this.props;
    return (
      <div>
        {!success && (
          <SimpleControlDialog
            show={show}
            title="Forgot Your Password?"
            description="Enter your email address to receive a password reset
          email:"
            onClose={this.props.closeHandler}
            closeHandler={this.handleClose.bind(this)}
            valid={valid}
            onSubmit={this.props.handleSubmit(this.submit)}
            okButtonText="Submit"
            closeButtonText="Cancel"
          >
            <div className={classes.resetPasswordForm}>
              <Field
                name="email"
                autoFocus
                margin="dense"
                component={renderTextField}
                autoComplete="[off]"
                label="Enter email address"
                type="text"
                onChange={this.handleInputChange}
              />
            </div>
            <p className={classes.errorMessage}>{errorMessage}</p>
          </SimpleControlDialog>
        )}
        <SimpleMessageDialog
          type="success"
          name="reset-password-successfully"
          title="Forgot Your Password?"
          description={`We've sent a password reset link to your email. If you didn't receive it, please contact your HR or call PruCustomer Line at 1800 835 9733 or +65 6233 3299 (for overseas).`}
          closeButtonText="OK"
          closeHandler={this.handleCloseSuccess.bind(this)}
          open={success}
        />
      </div>
    );
  }
}

const styles = theme => ({
  successIcon: {
    fontSize: 50,
    marginBottom: 20,
    color: "green"
  },
  successContent: {
    textAlign: "center"
  },
  resetPasswordForm: {
    textAlign: "center"
  },
  errorMessage: {
    marginTop: theme.spacing.unit * 1,
    color: theme.palette.error.main,
    fontFamily: "OpenSans"
  }
});

export default reduxForm({
  form: "forgot-password-form",
  validate
})(withStyles(styles, { withTheme: true })(ForgotPassworDialog));