import React, { useState } from "react";
import { Box, Button, Grid, Typography, Radio } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import SimpleDialog from "./RenewalSubmittedDocumentsDialogue";
import DialogActions from "@mui/material/DialogActions";

const RenewalSubmittedDocuments = ({}) => {
  const policyRenew = useSelector(state => state.policyRenew);
  const dispatch = useDispatch();

  return (
    <>
      <Box
        sx={{
          overflow: "hidden",
          backgroundColor: "white",
          padding: "30px 30px 35px 30px",
          boxShadow: "-2px -2px 20px 0 rgba(0,0,0,0.1)"
        }}
      >
        <Grid
          sx={{ padding: "0px 0px 20px 0px", display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>Policy chosen for renewal</Typography>
        </Grid>

        <Grid container rowSpacing={1} columnSpacing={3} xs={5}>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>Company:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>{policyRenew.company_name}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>Reference / Policy No.:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>{policyRenew.policy_no}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>Policy Expiry Date:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>{policyRenew.expiry_date}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>Status:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>{policyRenew.expiry_date}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          overflow: "hidden",
          backgroundColor: "white",
          padding: "30px 30px 35px 30px",
          boxShadow: "-2px -2px 20px 0 rgba(0,0,0,0.1)",
          marginTop: "20px",
          marginBottom: "80px"
        }}
      >
        <Grid sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography sx={{ padding: "0px 0px 20px 0px", fontSize: "18px", fontWeight: 500 }}>
            Sunmitted documents
          </Typography>
          <Button sx={{ fontWeight: 500, fontSize: "16px" }}>Download all</Button>
        </Grid>

        <div>
          <div
            item
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px 0px",
              borderBottom: "1px solid #ccc"
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: 500, textDecoration: "underline" }}>
              Proposal Form
            </Typography>
            <DownloadIcon />
          </div>
          <div
            item
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px 0px",
              borderBottom: "1px solid #ccc"
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: 500, textDecoration: "underline" }}>
              Declaration Form of Entitlement to Claim Input Tax on Insurance Policy
            </Typography>
            <DownloadIcon />
          </div>
        </div>
      </Box>
      <SimpleDialog data={"ello"} />
    </>
  );
};

export default RenewalSubmittedDocuments;
