import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import Icon from "@mui/material/Icon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PDFViewerBase64 from "../../../../components/pdfViewerBase64";
import assets from "../../../../assets";
import Enums from "../../../../utils/Enums";
import Util from "../../../../utils/Util";
import DocumentService from "../../../../services/document.service";

class UseFullDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pdf: undefined,
      pdfContentBase64: "",
      fileName: undefined,
      open: false,
      isPDF: false
    };
  }

  handleClick = async (fileNameOriginal, fileName) => {
    let result = await DocumentService.getDocumentByName(fileNameOriginal);

    let isPDF = result.data.file_mime_type === "application/pdf" ? true : false;

    let pdfContentBase64 = result.data.file_content;
    let file = `data:${result.data.file_mime_type};base64,${result.data.file_content}`;

    this.setState({ pdf: file, pdfContentBase64, fileName: fileName, open: true, isPDF: isPDF });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  renderDoc = (doc, prodCode, appType, selectedPlans, pcode, classes) => {
    return doc.map((doc, index) => {
      const { desc, fileName, fileNameOriginal, type, code } = doc;
      let exist = false;
      if (!Util.isEmpty(code && code[prodCode])) {
        let codeList = code[prodCode];
        codeList.forEach(c => {
          if (selectedPlans.includes(c)) {
            exist = true;
          }
        });
      }
      if (
        (appType === Enums.APP_TYPE.SALES && type === Enums.P_CODE.PRU_AGENCY) ||
        (appType === Enums.APP_TYPE.HR && (exist || (code && code[prodCode] && code[prodCode].includes("HDF")))) ||
        (appType === Enums.APP_TYPE.HR && type === Enums.P_CODE.PRU_CE && pcode === Enums.P_CODE.PRU_CE)
      ) {
        return (
          <TableRow
            key={index}
            className={[classes.tableRowDoc, classes.dlIcon]}
            onClick={() => {
              this.handleClick(fileNameOriginal, fileName);
            }}
          >
            <TableCell className={classes.tableCellDoc}>
              <span>{doc.desc}</span>
            </TableCell>
            <TableCell className={classes.dlCell}>
              <p className={classes.dlHolder}>
                <Icon
                  onClick={() => {
                    this.handleClick(fileNameOriginal, fileName);
                  }}
                  className={classes.dlIcon}
                >
                  file_download
                </Icon>
              </p>
            </TableCell>
          </TableRow>
        );
      }
    });
  };

  renderUserGuid = (userGuide, prodCode, appType, selectedPlans, pcode, classes) => {
    return userGuide.map((doc, index) => {
      const { fileName, fileNameOriginal, type, code } = doc;
      if (
        appType === Enums.APP_TYPE.HR &&
        (type.includes(pcode) || (type === Enums.P_CODE.CUSTOMIZED && pcode === Enums.P_CODE.PRU_AGENCY)) &&
        code &&
        code[prodCode]
      ) {
        return (
          <TableRow
            key={index}
            className={[classes.tableRowDoc, classes.dlIcon]}
            onClick={() => {
              this.handleClick(fileNameOriginal, fileName);
            }}
          >
            <TableCell className={classes.tableCellDoc}>{doc.desc}</TableCell>
            <TableCell className={classes.dlCell}>
              <p className={classes.dlHolder}>
                <Icon
                  onClick={() => {
                    this.handleClick(fileNameOriginal, fileName);
                  }}
                  className={classes.dlIcon}
                >
                  file_download
                </Icon>
              </p>
            </TableCell>
          </TableRow>
        );
      }
    });
  };

  render() {
    const { classes, coverage, appType, pcode } = this.props;

    let prodCode = coverage.policy && coverage.policy.product_code;

    let categories = coverage.policy && coverage.policy.data && coverage.policy.data.categories;
    let selectedPlans = [];
    categories &&
      categories.forEach(c => {
        if (c.plansConfiguration) {
          c.plansConfiguration.forEach(p => {
            if (p.tier && !selectedPlans.includes(p.code)) selectedPlans.push(p.code);
          });
        }
      });

    return (
      <div className={classes.root}>
        <main>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderCellFilename}>File name</TableCell>
                <TableCell className={classes.tableHeaderCellDownload}>Download</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.renderDoc(assets.doc, prodCode, appType, selectedPlans, pcode, classes)}
              {this.renderUserGuid(assets.userGuide, prodCode, appType, selectedPlans, pcode, classes)}
            </TableBody>
          </Table>
          <PDFViewerBase64
            open={this.state.open}
            handleClose={this.handleClose}
            pdf={this.state.pdf}
            pdfContentBase64={this.state.pdfContentBase64}
            isPDF={this.state.isPDF}
            fileName={this.state.fileName}
          />
        </main>
      </div>
    );
  }
}

const styles = theme => ({
  root: {},
  maxWidth: {
    width: "100%"
  },
  maxHeight: {
    height: "100%"
  },
  tableRowDoc: {
    color: "#818181",
    backgroundColor: "white",
    height: "30px"
  },
  tableCellDoc: {
    padding: 0,
    paddingLeft: 25,
    textDecoration: "underline"
  },
  clearCellStyle: {
    padding: 0,
    paddingRight: "0!important",
    border: 0
  },
  pdfViewModal: {
    overflowY: "scroll"
  },
  dlHolder: {
    textAlign: "right"
  },
  dlIcon: {
    "&:hover": {
      color: "#919191",
      cursor: "pointer"
    },
    color: "#b1b1b1"
  },
  dlCell: {
    padding: 0
  },
  pdfViewer: {
    height: "80%"
  },
  pdfViewHolder: {
    position: "relative",
    paddingTop: "50px",
    paddingBottom: "50px"
  },
  pdfActionsHolder: {
    position: "fixed",
    bottom: "20px"
  },
  prevAction: {
    float: "right",
    opacity: 0.3,
    "&:hover": {
      opacity: 1
    }
  },
  nextAction: {
    float: "left",
    opacity: 0.3,
    "&:hover": {
      opacity: 1
    }
  },
  tableHeaderCellFilename: {
    color: "#808080 !important",
    padding: "5px 10px 0 5px",
    backgroundColor: "#fff"
  },
  tableHeaderCellDownload: {
    color: "#808080 !important",
    padding: "5px 0px 0 5px",
    backgroundColor: "#fff",
    textAlign: "right"
  },
  dlAction: {
    textDecoration: "none",
    position: "fixed",
    top: "20px",
    right: "20px",
    [theme.breakpoints.down("sm")]: {
      opacity: 0.3,
      "&:hover": {
        opacity: 1
      }
    }
  },
  pdfZoomHolder: {
    position: "fixed",
    bottom: "20px",
    right: 0,
    maxWidth: "100px"
  },
  zoomIn: {
    float: "right",
    marginRight: "20px"
  },
  zoomOut: {
    float: "right",
    marginRight: "20px"
  }
});

function mapStateToProps(state) {
  return {
    coverage: state.HR.coverage,
    appType: state.app.type,
    pcode: state.user.auth.pcode,
    clients: state.HR.clientsAndPolicyListing.clients
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(UseFullDocument));
