import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import moment from "moment";

import { withStyles } from "@mui/styles";

import { Accordion as ExpansionPanel } from "@mui/material";
import { AccordionSummary as ExpansionPanelSummary } from "@mui/material";
import { AccordionDetails as ExpansionPanelDetails } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import SelectValidatorElement from "../../../../../../components/validation/SelectValidatorElement";
import DatePickerValidatorElement from "../../../../../../components/validation/DatePickerValidatorElement";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
//import AutosuggestionValidatorElement from "components/validation/AutosuggestionValidatorElement";
import Grid from "@mui/material/Grid";
import { DateRange as DateRangeIcon } from "@mui/icons-material";
import countries from "../../../../../../assets/data/countries";
import { employeeActions } from "../../../../../../actions";
import Enums from "../../../../../../utils/Enums";
import Util from "../../../../../../utils/Util";

const FORMAT_DATE = "DD/MM/YYYY";

const genderValues = [
  {
    value: "F",
    label: "Female"
  },
  {
    value: "M",
    label: "Male"
  }
];

const personTypeValues = [
  {
    value: "S",
    label: "Spouse"
  },
  {
    value: "C",
    label: "Child"
  }
];

class DependentEditForm extends Component {
  constructor(props) {
    super(props);

    this.isHRApp = props.app.type === Enums.APP_TYPE.HR;
    this.isSalesApp = props.app.type === Enums.APP_TYPE.SALES;

    this._refs = {};

    this.state = {
      editTerminationDate: false,
      errorMessages: {
        age: props.intl.formatMessage({ id: "validator.age" }),
        coverageStartDateError: ""
      }
    };
  }

  formatDate = date => {
    return (date && moment(date, FORMAT_DATE)) || null;
  };

  handleDelete = (idx, allowTerminate) => {
    if (allowTerminate) {
      if (Util.isEmpty(this.props.data.coverage_end_date)) {
        this.setState({ editTerminationDate: true }, () => {
          this.props.handleTerminate(idx);
          this._refs["coverage_end_date"].validate();
        });
      }
    } else {
      this.props.handleDelete(idx);
    }
  };

  componentWillMount() {
    const { index, data, readOnly, document } = this.props;

    ValidatorForm.addValidationRule("notBlank", value => {
      return !(typeof value === "string" && value && value.trim() === "");
    });
    ValidatorForm.addValidationRule("lengthName", value => {
      if (readOnly || document?.hasSignedProposal) return true;
      return value.length <= 120;
    });
    // custom rule will have name 'isAgeValid'
    ValidatorForm.addValidationRule(`isAgeValid[${index}]`, value => {
      if (readOnly || document.hasSignedProposal) return true;

      let person = this.props.data;
      let result = this.props.validateEmployeeAge(this.props.categories, person, true);

      if (result.success) {
        return true;
      } else {
        if (this.state.errorMessages.age !== result.msg) {
          this.setState({
            errorMessages: { ...this.state.errorMessages, age: result.msg }
          });
        }
        return false;
      }
    });

    ValidatorForm.addValidationRule(`isTerminationDateRequired`, value => {
      return value ? true : false;
    });

    ValidatorForm.addValidationRule(`isDuplicatedIdNo[${index}]`, value => {
      return this.props.validationRules["isDuplicatedIdNo"]("dependent", value, data, index);
    });

    ValidatorForm.addValidationRule(`isCoverageDateValid[${index}]`, value => {
      if (
        (this.props.dirtyField == "coverage_start_date" + index || this.props.dirtyField == "dob" + index) &&
        this.props.isFormDirty
      ) {
        let result = this.props.validationRules["isDependentsCoverageDateValidRefined"]("dependant", value, index);
        if (result !== undefined) {
          if (result.validated) {
            return true;
          } else {
            if (result.errorMessage !== null && result.errorMessage !== "") {
              this.props.errorMessages.coverageStartDateError[index] = result.errorMessage;
            }
            // this.props.errorMessages = result.errorMessages;
            return false;
          }
        }
      }
      return true;
    });

    ValidatorForm.addValidationRule(`isDependantMaxAgeCoverValid[${index}]`, value => {
      let result = this.props.validationRules["isDependantMaxAgeCoverValid"](value, index);
      if (result !== undefined) {
        if (result.validated) {
          return true;
        } else {
          if (result.errorMessage !== null && result.errorMessage !== "") {
            this.props.errorMessages.maxAgeCoverError[index] = result.errorMessage;
          }
          // this.props.errorMessages = result.errorMessages;
          return false;
        }
      }
      return true;
    });

    ValidatorForm.addValidationRule("isNameValid", value => {
      if (readOnly || document.hasSignedProposal) return true;
      if (Util.isNameValid(value)) {
        return true;
      }
    });

    ValidatorForm.addValidationRule(`isNRICValid[${index}]`, value => {
      return this.props.validationRules["isNRICValid"](value, index);
    });

    ValidatorForm.addValidationRule(`isDobTally[${index}]`, value => {
      return this.props.validationRules["isDobTally"](value, index);
    });

    ValidatorForm.addValidationRule(`isTerminationDateValid[${index}]`, value => {
      let result = this.props.validationRules["isTerminationDateValid"](value, index);
      if (result !== undefined) {
        if (result.validated) {
          return true;
        } else {
          if (result.errorMessage !== null && result.errorMessage !== "") {
            this.props.errorMessages.coverageEndDateError[index] = result.errorMessage;
          }
          // this.props.errorMessages = result.errorMessages;
          return false;
        }
      }
    });

    ValidatorForm.addValidationRule("alphanumericValid", value => {
      let meRegex = /[^\w]|_/g;
      let isInvalid;
      isInvalid = meRegex.test(value);
      if (isInvalid) {
        return false;
      } else {
        return true;
      }
    });
  }

  setRef = (ref, name) => {
    this._refs[name] = ref;
  };

  render() {
    const {
      intl,
      classes,
      index,
      document,
      data,
      handleChange,
      handleDateChange,
      handleResetValue,
      errorMessages,
      readOnly
    } = this.props;
    const { editTerminationDate } = this.state;

    const allowTerminate = this.isHRApp && !data.isNewRecord;

    let disabled = readOnly || (document && document.hasSignedProposal);

    return (
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Dependant {index + 1}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={"24px"}>
            {this.isHRApp && [
              <Grid
                item
                xs={12}
                sm={6}
                key={`g1${index}`}
                style={{
                  borderTop: "1px solid gray",
                  borderLeft: "1px solid gray",
                  borderBottom: "1px solid gray",
                  marginTop: 5
                }}
              >
                <DatePickerValidatorElement
                  key={`coverage_start_date[${index}]`}
                  name={`coverage_start_date[${index}]`}
                  fullWidth
                  clearable
                  disabled={disabled}
                  variant="standard"
                  label="Coverage Start Date"
                  autoComplete="[off]"
                  value={this.formatDate(data.coverage_start_date)}
                  onChange={handleDateChange(index, `coverage_start_date`)}
                  format="DD/MM/YYYY"
                  animateYearScrolling={true}
                  keyboard
                  validators={["required", `isCoverageDateValid[${index}]`]}
                  errorMessages={[
                    intl.formatMessage({ id: "validator.required" }),
                    errorMessages.coverageStartDateError[index] !== null
                      ? errorMessages.coverageStartDateError[index]
                      : ""
                  ]}
                />
              </Grid>,

              <Grid
                item
                xs={12}
                sm={6}
                key={`g2${index}`}
                style={{
                  borderTop: "1px solid gray",
                  borderBottom: "1px solid gray",
                  borderRight: "1px solid gray",
                  marginTop: 5
                }}
              >
                {editTerminationDate && (
                  <DatePickerValidatorElement
                    innerRef={r => this.setRef(r, "coverage_end_date")}
                    key={`coverage_end_date[${index}]`}
                    name={`coverage_end_date[${index}]`}
                    fullWidth
                    clearable
                    disabled={disabled}
                    variant="standard"
                    label="Coverage End Date"
                    autoComplete="[off]"
                    value={this.formatDate(data.coverage_end_date)}
                    onChange={handleDateChange(index, `coverage_end_date`)}
                    format="DD/MM/YYYY"
                    animateYearScrolling={true}
                    InputProps={{
                      endAdornment: <DateRangeIcon />
                    }}
                    validators={["isTerminationDateRequired", `isTerminationDateValid[${index}]`]}
                    errorMessages={[
                      intl.formatMessage({ id: "validator.required" }),
                      errorMessages.coverageEndDateError[index] !== null
                        ? errorMessages.coverageEndDateError[index]
                        : ""
                    ]}
                  />
                )}

                {!editTerminationDate && (
                  <FormControl disabled fullWidth>
                    <InputLabel htmlFor="coverage_end_date">Coverage End Date</InputLabel>
                    <Input
                      key={`coverage_end_date[${index}]`}
                      name={`coverage_end_date[${index}]`}
                      id={`coverage_end_date[${index}]`}
                      autoComplete="[off]"
                      value={data.coverage_end_date || "-"}
                    />
                    <FormHelperText />
                  </FormControl>
                )}
              </Grid>
            ]}

            <Grid item xs={12} sm={6} key={`g3${index}`}>
              <TextValidator
                disabled={disabled}
                autoComplete="[off]"
                fullWidth
                label="Dependant Full Name"
                onChange={e => {
                  handleChange(index, e);
                }}
                key={`full_name[${index}]`}
                name={`full_name[${index}]`}
                value={data.full_name}
                validators={["required", "notBlank", "isNameValid", "lengthName"]}
                errorMessages={[
                  intl.formatMessage({ id: "validator.required" }),
                  "Please enter a valid value",
                  "Invalid characters in name field",
                  "Input exceeded 120 characters limit."
                ]}
              />
            </Grid>

            <Grid item xs={12} sm={6} key={`g5${index}`}>
              <SelectValidatorElement
                disabled={disabled}
                autoComplete="[off]"
                label="Relationship To Employee"
                onChange={e => {
                  handleChange(index, e);
                }}
                key={`type[${index}]`}
                name={`type[${index}]`}
                value={data.type || ""}
                validators={["required"]}
                resetValue={handleResetValue(index, "occupation_class")}
                suggestions={personTypeValues}
                errorMessages={[intl.formatMessage({ id: "validator.required" })]}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} sm={6} key={`g6${index}`}>
              <SelectValidatorElement
                key={`gender[${index}]`}
                disabled={disabled}
                value={data.gender || ""}
                onChange={e => {
                  handleChange(index, e);
                }}
                name={`gender[${index}]`}
                label="Gender"
                autoComplete="[off]"
                validators={["required"]}
                resetValue={handleResetValue(index, "gender")}
                suggestions={genderValues}
                errorMessages={[intl.formatMessage({ id: "validator.required" })]}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} sm={6} key={`g7${index}`}>
              <TextValidator
                disabled={disabled}
                autoComplete="[off]"
                fullWidth
                label="NRIC/FIN/Passport"
                onChange={e => {
                  handleChange(index, e);
                }}
                name={`id_no[${index}]`}
                value={data.id_no}
                validators={[
                  "required",
                  "notBlank",
                  `isDuplicatedIdNo[${index}]`,
                  `isNRICValid[${index}]`,
                  `alphanumericValid`
                ]}
                errorMessages={[
                  intl.formatMessage({ id: "validator.required" }),
                  "Please enter a valid value",
                  intl.formatMessage({ id: "validator.duplicated.idNo" }),
                  "You have filled in an invalid Singaporean NRIC",
                  "Please enter alphanumeric characters only for NRIC/FIN/Passport"
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6} key={`g8${index}`}>
              <DatePickerValidatorElement
                innerRef={r => this.setRef(r, "dob")}
                fullWidth
                disabled={disabled}
                variant="standard"
                name={`dob[${index}]`}
                autoComplete="[off]"
                clearable
                label="Date Of Birth"
                value={this.formatDate(data.dob)}
                onChange={handleDateChange(index, `dob`)}
                format="DD/MM/YYYY"
                animateYearScrolling={true}
                keyboard
                validators={[
                  "required",
                  `isAgeValid[${index}]`,
                  `isDependantMaxAgeCoverValid[${index}]`,
                  `isDobTally[${index}]`
                ]}
                errorMessages={[
                  intl.formatMessage({ id: "validator.required" }),
                  this.state.errorMessages.age,
                  errorMessages.maxAgeCoverError[index] !== null ? errorMessages.maxAgeCoverError[index] : "",
                  "Year of birth does not tally with NRIC."
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6} key={`g9${index}`}>
              <SelectValidatorElement
                disabled={disabled}
                id="nationality"
                name={`nationality[${index}]`}
                label="Nationality"
                autoComplete="[off]"
                value={data.nationality}
                resetValue={handleResetValue(index, "nationality")}
                suggestions={countries}
                onChange={e => {
                  handleChange(index, e);
                }}
                validators={["required"]}
                errorMessages={[intl.formatMessage({ id: "validator.required" })]}
                variant="standard"
              />
            </Grid>

            <Grid item xs={12} sm={6} key={`g10${index}`} className={classes.btnDelete}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  this.handleDelete(index, allowTerminate);
                }}
                disabled={disabled}
              >
                {allowTerminate ? "Remove Dependant from Coverage" : "Remove Dependant"}
              </Button>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

const styles = theme => ({
  btnDelete: {
    textAlign: "right",
    alignSelf: "center",
    marginTop: "-8px"
  }
});

function mapStateToProps(state, prop) {
  return {
    app: state.app,
    document: state.document
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(employeeActions, dispatch);
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(DependentEditForm))
);
