import React, { useState } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Button, Toolbar } from "@mui/material";
import classNames from "classnames";

const PolicyRenewalSteps = ({
  activeStep,
  navDrawerOpen,
  isRenewalBtnDiabled,
  handleNext,
  handleSave,
  isSubmitBtnDiabled,
  handleSubmit
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <div className={classNames(classes.root, navDrawerOpen && classes.menuShow)}>
      <Toolbar className={classes.container}>
        <div className={classes.leftActions}>
          {activeStep != 1 && (
            <Button variant="raised" className={classes.saveButton} onClick={handleSave}>
              Back
            </Button>
          )}
        </div>
        <div style={{ flexGrow: 1 }} />
        <div className={classes.actions}>
          {activeStep != 3 && (
            <Button
              disabled={isRenewalBtnDiabled}
              variant="raised"
              color="primary"
              className={isRenewalBtnDiabled ? classes.disabledButton : classes.button}
              onClick={handleNext}
            >
              Next
            </Button>
          )}
          {activeStep === 3 && (
            <Button
              variant="raised"
              color="primary"
              className={isSubmitBtnDiabled ? classes.disabledButton : classes.button}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          )}
        </div>
      </Toolbar>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: `calc(100% - ${theme.miniLeftDrawer.widthClosed}px)`,
    backgroundColor: "#ffffff",
    position: "fixed",
    bottom: 0,
    right: 0,
    zIndex: 1,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuShow: {
    width: `calc(100% - ${theme.miniLeftDrawer.width}px)`
  },
  container: {
    justifyContent: "flex-start",
    borderTop: "solid 1px #ccc",
    ...theme.extended.minHeight(80)
  },
  button: {
    marginLeft: 20,
    fontWeight: 500,
    padding: "10px 80px",
    fontSize: "18px",
    borderRadius: "50px"
  },
  disabledButton: {
    marginLeft: 20,
    fontWeight: 500,
    padding: "10px 80px",
    fontSize: "18px",
    borderRadius: "50px",
    background: "#dddddd"
  },
  saveButton: {
    marginLeft: 20,
    fontWeight: 500,
    padding: "10px 80px",
    fontSize: "18px",
    borderRadius: "50px",
    border: "solid 1px #ccc",
    color: "#7f7f7f",
    backgroundColor: "transparent"
  },
  actions: {
    display: "flex",
    flexDirection: "row"
  },
  leftActions: {
    display: "flex",
    flexDirection: "row",
    marginLeft: -20
  }
}));

export default PolicyRenewalSteps;
