import * as actionTypes from "./types";
import employeeService from "../services/employee.service";
import productEngineService from "../services/product.engine.service";
import { getCategories, getCategoryName } from "../selectors/CommonSelectors";
import Util from "../utils/Util";
import Enums from "../utils/Enums";
import ProductEngineService from "../services/product.engine.service";
import { quoteActions } from "./quote.action";

export const employeeActions = {
  upload,
  saveEmployee,
  addEmployee,
  deleteEmployee,
  moveCategory,
  setSelectedEmployees,
  validateEmployeeData,
  gotoListEmployees,
  setEmployeeDependents,
  uploadFailed,
  setErrors,
  validateEmployeeAge
};

function validateEmployee(state, persons) {
  const categories = getCategories(state.quote);
  const categoryNames = getCategoryName(state.quote);
  //validate category
  let validatedData = persons.map((d, idx) => {
    // if (!d.invalid_fields) {
    //   d.invalid_fields = [];
    // }
    // d.invalid_fields = [];

    if (!Util.isAlphaNumeric(d.id_no)) {
      d.invalid_fields.push("id_no");
    } else {
      Util.removeArrayValue(d.invalid_fields, "id_no");
    }

    //only need validate for employee
    if (d.type !== "E") return d;
    if (categoryNames && categoryNames.length > 0) {
      Util.removeArrayValue(d.invalid_fields, "category");
      if (!categoryNames.includes(d.category)) {
        d.invalid_fields.push("category");
      }
    } else {
      Util.removeArrayValue(d.invalid_fields, "category");
      d.invalid_fields.push("category");
    }

    //check if email is invalid
    if (!Util.isEmail(d.email)) {
      d.invalid_fields.push("invalid_email");
    } else {
      Util.removeArrayValue(d.invalid_fields, "invalid_email");
    }

    //re-validate dup email
    // if (d.invalid_fields.includes("email") && !Util.isEmpty(d.email)) {
    //   let notDupEmail =
    //     persons.filter(
    //       p =>
    //         p.type === "E" &&
    //         !Util.caseInsensitiveEqual(p.id_no, d.id_no) &&
    //         Util.caseInsensitiveEqual(p.email, d.email)
    //     ).length === 0;
    //   if (notDupEmail) {
    //     Util.removeArrayValue(d.invalid_fields, "email");
    //   }
    // }

    if (d.invalid_fields.includes("dob") && !Util.isEmpty(d.original_dob)) {
      d.dob = d.original_dob;
    }

    if (d.invalid_fields.includes("date_of_employment") && !Util.isEmpty(d.original_date_of_employement)) {
      d.date_of_employment = d.original_date_of_employement;
    }
    return d;
  });

  let _opts = _mapOpts(state.quote);

  //validate age for all type (E, S, C, F)
  return productEngineService
    .validatePersons(categories, persons, _opts)
    .then(results => {
      if (results.success && results.personResults && results.personResults.length > 0) {
        validatedData = validatedData.map((d, idx) => {
          const validateRs = results.personResults[idx];
          //TODO: nasty way of doing for now, to refactor to handle dynamically
          let fields = ["age", "dob", "enableDependent", "multipleSpouse"];
          d.invalid_fields = d.invalid_fields.filter(value => !fields.includes(value));

          if (validateRs && !validateRs.valid) {
            d.invalid_fields = d.invalid_fields.concat(validateRs.invalid_fields);
            d.validations = validateRs.validations;
          } else if (validateRs && validateRs.valid) {
            d.validations = validateRs.validations;
          }
          //we need validate date format dob
          if (!Util.isValidDateFormat(d.dob)) {
            Util.putUnique(d.invalid_fields, "dob");
          }

          //mark it invalid or not
          d.isValid = d.invalid_fields.length === 0;
          return d;
        });
      }
      return Promise.resolve(validatedData);
    })
    .then(validatedDataResult => {
      //validate dependants of employee
      let employeesWithoutDependents = validatedDataResult.filter((person, idx) => person.type === "E");
      let dependents = validatedDataResult.filter((person, idx) => person.type !== "E");

      employeesWithoutDependents.map((d, idx) => {
        if (d.no_of_dependent > 0) {
          let dependentsOfemployees = dependents.filter((person, idx) => person.dependency_id_no === d.id_no);
          let isInvalid = dependentsOfemployees.filter((person, idx) => !person.isValid).length > 0;
          Util.removeArrayValue(d.invalid_fields, "dependent_error");
          if (isInvalid) {
            d.invalid_fields.push("dependent_error");
            d.invalid_fields.push("no_of_dependent");
          }
          d.isValid = d.invalid_fields.length === 0;
        }
        return d;
      });

      return Promise.resolve(validatedData);
    });
}

function uploadFailed(error) {
  return dispatch => {
    return dispatch({ type: actionTypes.EMPLOYEE_UPLOAD_ERROR, error });
  };
}

function upload(quoteId, file) {
  return (dispatch, getState) => {
    dispatch(request());
    const state = getState();
    employeeService.upload(quoteId, file).then(
      response => {
        if (response.success) {
          validateEmployee(state, response.data).then(validatedData => {
            dispatch(success({ data: validatedData, fileName: file.name }));
          });
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return { type: actionTypes.EMPLOYEE_UPLOAD_REQUEST };
  }

  function success(payload) {
    return { type: actionTypes.EMPLOYEE_UPLOAD_SUCCESS, payload };
  }

  function failure(error) {
    return { type: actionTypes.EMPLOYEE_UPLOAD_ERROR, error };
  }
}

function validateEmployeeData(stage) {
  return (dispatch, getState) => {
    const state = getState();
    const persons = state.quote.data.persons;
    dispatch(quoteActions.employeeRevalidate());
    validateEmployee(state, persons).then(validatedData => {
      let employeeValidationStatus = false;
      const dataIsInvalid = validatedData.filter(d => d.isValid === false).length > 0;
      if (dataIsInvalid || !quoteActions.validateStep2(state)) {
        dispatch(quoteActions.resetActualPremium());
        employeeValidationStatus = false;
        dispatch({
          type: actionTypes.QUOTE_SET_STAGE_VALIDATION,
          payload: {
            stage: Enums.STAGES.EMPLOYEE,
            status: employeeValidationStatus
          }
        });
        dispatch({
          type: actionTypes.EMPLOYEE_VALIDATE_DATA,
          payload: { persons: validatedData, dataIsInvalid: dataIsInvalid }
        });
      } else {
        dispatch(quoteActions.computeActualPremium()).then(
          () => {
            dispatch({
              type: actionTypes.QUOTE_SET_STAGE_VALIDATION,
              payload: {
                stage: Enums.STAGES.EMPLOYEE,
                status: true
              }
            });
            dispatch({
              type: actionTypes.EMPLOYEE_VALIDATE_DATA,
              payload: { persons: validatedData, dataIsInvalid: dataIsInvalid }
            });
          },
          () => {
            dispatch({
              type: actionTypes.QUOTE_SET_STAGE_VALIDATION,
              payload: {
                stage: Enums.STAGES.EMPLOYEE,
                status: false
              }
            });
            dispatch({
              type: actionTypes.EMPLOYEE_VALIDATE_DATA,
              payload: { persons: validatedData, dataIsInvalid: dataIsInvalid }
            });
          }
        );
      }
    });
  };
}

function saveEmployee(payload) {
  return dispatch => {
    dispatch({ type: actionTypes.EMPLOYEE_SAVE_REQUEST, payload: payload });
  };
}

function addEmployee(data) {
  return dispatch => {
    dispatch({ type: actionTypes.EMPLOYEE_ADD_REQUEST, payload: data });
  };
}

function deleteEmployee(ids) {
  return (dispatch, getState) => {
    const state = getState();
    let persons = state.quote.data.persons;
    let dependencyIds = [];

    //sort index in order
    let _ids = Util.sortAsc(ids);
    _ids.forEach(idx => {
      let person = persons[idx];
      //Check the dependencies for this employee
      if (person.no_of_dependent > 0) {
        persons.forEach((p, idx) => {
          if (p.dependency_id_no === person.id_no) {
            dependencyIds.push(idx);
          }
        });
      }
    });
    _ids = _ids.concat(dependencyIds);

    dispatch({
      type: actionTypes.EMPLOYEE_DELETE_REQUEST,
      payload: { ids: _ids }
    });
    dispatch(validateEmployeeData());
  };
}

function moveCategory(data) {
  return (dispatch, getState) => {
    const state = getState();
    let newCategory = data.category;
    let persons = state.quote.data.persons.slice(0);

    data.ids.forEach(id => {
      let employee = persons[id];
      employee.category = newCategory;

      persons.forEach(p => {
        if (employee.id_no === p.dependency_id_no) {
          p.category = newCategory;
        }
      });
    });

    dispatch({ type: actionTypes.EMPLOYEE_MOVE_REQUEST, payload: persons });
    dispatch(validateEmployeeData());
  };
}

function setSelectedEmployees(payload) {
  return { type: actionTypes.EMPLOYEES_SET_SELECTED, payload: payload };
}

function gotoListEmployees(payload) {
  return dispatch => {
    dispatch({ type: actionTypes.EMPLOYEE_LIST_SCREEN, payload: payload });
  };
}

function setEmployeeDependents(payload) {
  return (dispatch, getState) => {
    const state = getState();
    let dependencies = payload.records || [];
    let persons = state.quote.data.persons;
    let employeeIdNo = payload.id_no;
    let dependencyIdNo =
      !Util.isEmpty(payload.previous_id_no) && payload.previous_id_no === payload.id_no
        ? payload.id_no
        : payload.previous_id_no;

    //Remove all existing dependencies under this id_no
    let employeesWithoutDependents = persons.filter((person, idx) => {
      return (
        person.type === Enums.PERSON_TYPE.EMPLOYEE ||
        (person.type !== Enums.PERSON_TYPE.EMPLOYEE && person.dependency_id_no !== dependencyIdNo)
      );
    });

    let idx = employeesWithoutDependents.findIndex(x => x.id_no === employeeIdNo);
    let employee = employeesWithoutDependents[idx];

    //Set number of dependents
    employee.no_of_dependent = dependencies.length || 0;

    //Map employee.id_no to the dependency_id_no and category
    dependencies = dependencies.map((dependent, idx) => {
      dependent.dependency_id_no = employee.id_no;
      dependent.category = employee.category;
      return dependent;
    });

    //Set dependency type for employee based on dependencies
    let types = {};
    dependencies.forEach(d => {
      types[d.type] = true;
    });
    if (types[Enums.PERSON_TYPE.SPOUSE] && types[Enums.PERSON_TYPE.CHILD]) {
      employee.dependency_type = Enums.DEPENDENCY_TYPE.FAMILY;
    } else if (types[Enums.PERSON_TYPE.SPOUSE] && !types[Enums.PERSON_TYPE.CHILD]) {
      employee.dependency_type = Enums.DEPENDENCY_TYPE.SPOUSE;
    } else if (!types[Enums.PERSON_TYPE.SPOUSE] && types[Enums.PERSON_TYPE.CHILD]) {
      employee.dependency_type = Enums.DEPENDENCY_TYPE.CHILDREN;
    } else {
      employee.dependency_type = Enums.DEPENDENCY_TYPE.NONE;
    }
    // employeesWithoutDependents[idx] = employee;

    let _persons = [...employeesWithoutDependents, ...dependencies];
    dispatch({ type: actionTypes.EMPLOYEE_SET_DEPENDENTS, payload: _persons });
    dispatch(validateEmployeeData());
  };
}

function setErrors(payload) {
  return dispatch => {
    dispatch({ type: actionTypes.EMPLOYEE_SET_ERRORS, payload: payload });
  };
}

function validateEmployeeAge(categories, person, ignoreEngine = false) {
  return (dispatch, getState) => {
    const state = getState();
    let _opts = _mapOpts(state.quote);
    let persons = person && [_mapPerson(person)];
    let result = {
      success: false,
      msg: null
    };

    let validatedDataResult = ProductEngineService.validateAge(categories, persons, _opts, ignoreEngine);
    if (validatedDataResult.success && validatedDataResult.personResults) {
      let personResult = validatedDataResult.personResults[0];
      if (personResult.valid || (!personResult.valid && personResult.statusCode === "1302")) {
        result.success = true;
      } else {
        result.success = false;
        result.msg = Util.mapPlansCodeLabel(personResult.msg);
      }
    } else {
      result = { ...result, msg: validatedDataResult.msg };
    }
    return result;
  };
}

function _mapOpts(quote) {
  return {
    activateDate: quote.req_effective_date
  };
}

function _mapPerson(person) {
  //Populate person values
  return {
    id: person.id,
    id_no: person.id_no,
    full_name: person.full_name,
    gender: Util.isEmpty(person.gender) ? null : person.gender,
    dob: Util.isEmpty(person.dob) ? null : person.dob,
    marital_status: Util.isEmpty(person.marital_status) ? null : person.marital_status,
    occupation_class: person.occupation_class,
    bank_account_name: person.bank_account_name,
    bank_account_number: person.bank_account_number,
    bank_name: person.bank_name,
    no_of_dependent: person.no_of_dependent,
    nationality: person.nationality,
    country_of_residence: person.country_of_residence,
    date_of_employment: Util.isEmpty(person.date_of_employment) ? null : person.date_of_employment,
    email: person.email,
    category: person.category,
    type: Util.isEmpty(person.type) ? null : person.type,
    dependency_type: Util.isEmpty(person.dependency_type) ? null : person.dependency_type,
    dependency_id_no: person.dependency_id_no,
    invalid_fields: person.invalid_fields,
    isValid: person.isValid,
    foreign_worker: person.foreign_worker
  };
}
