import Enums from "../../../../../utils/Enums";

const GTLSumAssuredEmployee = {
  title: `<br><strong>Employee<strong>`,
  headers: [
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "30%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Group Term Life<br></strong>`,
          info: `<em>(Double Death Benefits payable in the event of </em><em>death due to accident in a public land conveyance in Singapore)</em><br><ul>
              <li>Death</li>
              <li>Total and Permanent Disability (TPD)</li>
              <li>Terminal Illness (TI)</li>
              <li>Double Indemnity</li>
              </ul>`
        },
        {
          data: `S$ 500,000`
        },
        {
          data: `S$ 200,000`
        },
        {
          data: `S$ 150,000`
        },
        {
          data: `S$ 80,000`
        },
        {
          data: `S$ 50,000`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>Repatriation of Mortal Remains</strong>`,
          info: `<em>(per member)</em>`
        },
        {
          data: `S$ 75,000`
        },
        {
          data: `S$ 75,000`
        },
        {
          data: `S$ 75,000`
        },
        {
          data: `S$ 75,000`
        },
        {
          data: `S$ 75,000`
        }
      ]
    }
  ],
  footnotes: []
};

const GTLSumAssuredDependant = {
  title: `<br><strong>Dependant<strong>`,
  headers: [
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "30%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Group Term Life<br></strong>`,
          info: `<em>(Double Death Benefits payable in the event of </em><em>death due to accident in a public land conveyance)</em>`
        },
        {
          data: `S$ 250,000`
        },
        {
          data: `S$ 100,000`
        },
        {
          data: `S$ 75,000`
        },
        {
          data: `S$ 40,000`
        },
        {
          data: `S$ 25,000`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>Repatriation of Mortal Remains</strong>`,
          info: `<em>(per member)</em>`
        },
        {
          data: `S$ 75,000`
        },
        {
          data: `S$ 75,000`
        },
        {
          data: `S$ 75,000`
        },
        {
          data: `S$ 75,000`
        },
        {
          data: `S$ 75,000`
        }
      ]
    }
  ],
  footnotes: []
};

let GLTPremiumEmployee = {
  title: `<strong>* For Employee Only </strong>`,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 19</strong>`
        },
        {
          data: `S$ 388.00`
        },
        {
          data: `S$ 156.00`
        },
        {
          data: `S$ 117.00`
        },
        {
          data: `S$ 73.00`
        },
        {
          data: `S$ 39.00`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>20 - 24</strong>`
        },
        {
          data: `S$ 379.00`
        },
        {
          data: `S$ 156.00`
        },
        {
          data: `S$ 117.00`
        },
        {
          data: `S$ 69.00`
        },
        {
          data: `S$ 39.00`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>25 - 29</strong>`
        },
        {
          data: `S$ 333.00`
        },
        {
          data: `S$ 156.00`
        },
        {
          data: `S$ 117.00`
        },
        {
          data: `S$ 61.00`
        },
        {
          data: `S$ 39.00`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>30 - 34</strong>`
        },
        {
          data: `S$ 372.00`
        },
        {
          data: `S$ 156.00`
        },
        {
          data: `S$ 117.00`
        },
        {
          data: `S$ 68.00`
        },
        {
          data: `S$ 39.00`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>35 - 39</strong>`
        },
        {
          data: `S$ 417.00`
        },
        {
          data: `S$ 185.00`
        },
        {
          data: `S$ 139.00`
        },
        {
          data: `S$ 77.00`
        },
        {
          data: `S$ 47.00`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>40 - 44</strong>`
        },
        {
          data: `S$ 574.00`
        },
        {
          data: `S$ 277.00`
        },
        {
          data: `S$ 215.00`
        },
        {
          data: `S$ 108.00`
        },
        {
          data: `S$ 73.00`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>45 - 49</strong>`
        },
        {
          data: `S$ 944.00`
        },
        {
          data: `S$ 431.00`
        },
        {
          data: `S$ 324.00`
        },
        {
          data: `S$ 174.00`
        },
        {
          data: `S$ 108.00`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>50 - 54</strong>`
        },
        {
          data: `S$ 1,611.00`
        },
        {
          data: `S$ 730.00`
        },
        {
          data: `S$ 548.00`
        },
        {
          data: `S$ 295.00`
        },
        {
          data: `S$ 183.00`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>55 - 59</strong>`
        },
        {
          data: `S$ 2,582.00`
        },
        {
          data: `S$ 1,246.00`
        },
        {
          data: `S$ 964.00`
        },
        {
          data: `S$ 479.00`
        },
        {
          data: `S$ 323.00`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>60 - 64</strong>`
        },
        {
          data: `S$ 4,320.00`
        },
        {
          data: `S$ 2,084.00`
        },
        {
          data: `S$ 1,612.00`
        },
        {
          data: `S$ 801.00`
        },
        {
          data: `S$ 539.00`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>65 - 69</strong>`
        },
        {
          data: `S$ 7,207.00`
        },
        {
          data: `S$ 3,480.00`
        },
        {
          data: `S$ 2,692.00`
        },
        {
          data: `S$ 1,337.00`
        },
        {
          data: `S$ 899.00`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only.</em>`
        },
        {
          data: `S$ 13,856.00`
        },
        {
          data: `S$ 6,688.00`
        },
        {
          data: `S$ 5,171.00`
        },
        {
          data: `S$ 2,566.00`
        },
        {
          data: `S$ 1,726.00`
        }
      ]
    }
  ],
  footnotes: []
};

let GLTPremiumDependent = {
  title: `<br><strong> * For Dependant Only <strong>`,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>0 - 15</strong>`
        },
        {
          data: `S$ 206.00`
        },
        {
          data: `S$ 83.00`
        },
        {
          data: `S$ 62.00`
        },
        {
          data: `S$ 39.00`
        },
        {
          data: `S$ 21.00`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>16 - 19</strong>`
        },
        {
          data: `S$ 206.00`
        },
        {
          data: `S$ 83.00`
        },
        {
          data: `S$ 62.00`
        },
        {
          data: `S$ 39.00`
        },
        {
          data: `S$ 21.00`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>20 - 24</strong>`
        },
        {
          data: `S$ 201.00`
        },
        {
          data: `S$ 83.00`
        },
        {
          data: `S$ 62.00`
        },
        {
          data: `S$ 37.00`
        },
        {
          data: `S$ 21.00`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>25 - 29</strong>`
        },
        {
          data: `S$ 176.00`
        },
        {
          data: `S$ 83.00`
        },
        {
          data: `S$ 62.00`
        },
        {
          data: `S$ 32.00`
        },
        {
          data: `S$ 21.00`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>30 - 34</strong>`
        },
        {
          data: `S$ 197.00`
        },
        {
          data: `S$ 83.00`
        },
        {
          data: `S$ 62.00`
        },
        {
          data: `S$ 36.00`
        },
        {
          data: `S$ 21.00`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>35 - 39</strong>`
        },
        {
          data: `S$ 221.00`
        },
        {
          data: `S$ 98.00`
        },
        {
          data: `S$ 74.00`
        },
        {
          data: `S$ 41.00`
        },
        {
          data: `S$ 25.00`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>40 - 44</strong>`
        },
        {
          data: `S$ 304.00`
        },
        {
          data: `S$ 147.00`
        },
        {
          data: `S$ 114.00`
        },
        {
          data: `S$ 57.00`
        },
        {
          data: `S$ 39.00`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>45 - 49</strong>`
        },
        {
          data: `S$ 500.00`
        },
        {
          data: `S$ 228.00`
        },
        {
          data: `S$ 172.00`
        },
        {
          data: `S$ 92.00`
        },
        {
          data: `S$ 57.00`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>50 - 54</strong>`
        },
        {
          data: `S$ 854.00`
        },
        {
          data: `S$ 387.00`
        },
        {
          data: `S$ 290.00`
        },
        {
          data: `S$ 156.00`
        },
        {
          data: `S$ 97.00`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>55 - 59</strong>`
        },
        {
          data: `S$ 1,368.00`
        },
        {
          data: `S$ 660.00`
        },
        {
          data: `S$ 511.00`
        },
        {
          data: `S$ 254.00`
        },
        {
          data: `S$ 171.00`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>60 - 64</strong>`
        },
        {
          data: `S$ 2,290.00`
        },
        {
          data: `S$ 1,105.00`
        },
        {
          data: `S$ 854.00`
        },
        {
          data: `S$ 425.00`
        },
        {
          data: `S$ 286.00`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `<strong>65 - 69</strong>`
        },
        {
          data: `S$ 3,820.00`
        },
        {
          data: `S$ 1,844.00`
        },
        {
          data: `S$ 1,427.00`
        },
        {
          data: `S$ 709.00`
        },
        {
          data: `S$ 476.00`
        }
      ]
    },
    {
      id: 13,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only.</em>`
        },
        {
          data: `S$ 7,344.00`
        },
        {
          data: `S$ 3,545.00`
        },
        {
          data: `S$ 2,741.00`
        },
        {
          data: `S$ 1,360.00`
        },
        {
          data: `S$ 915.00`
        }
      ]
    }
  ],
  footnotes: []
};

let GLTGroupDiscount = {
  headers: [
    {
      title: `Group Size <i>Insured Employee<i>`,
      mandatory: true
    },
    {
      title: `Discount`,
      mandatory: true
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>11 - 15</strong>`
        },
        {
          data: `5%`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>16 and above</strong>`
        },
        {
          data: `10%`
        }
      ]
    }
  ],
  footnotes: []
};

//   ----------------------------------------------------------------------

const GTL = {
  id: 1,
  code: "GTLB",
  label: "Group Term Life (GTL)",
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GTLSumAssuredEmployee, GTLSumAssuredDependant]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium",
      data: [GLTPremiumEmployee, GLTPremiumDependent]
    }
    /*{
      id: 3,
      order: 3,
      type: Enums.PLANS_TYPE.GROUP_DISCOUNT,
      label: "Group Size Discount",
      data: [GLTGroupDiscount]
    }*/
  ]
};

export default GTL;
