import * as actionTypes from "../actions/types";

const initialState = {
  pendingList: [],
  submmitedList: [],
  isRenewalNotificationOpened: false,
  isLoading: true
};

export const renewPolicies = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_RENEW_POLICIES_PENDING:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.GET_RENEW_POLICIES_FULFILLED:
      return {
        ...state,
        isLoading: false,
        pendingList: action.payload.data.data.pendingList,
        submmitedList: action.payload.data.data.submmitedList
      };

    case actionTypes.UPDATE_IS_RENEWAL_NOTIFICATION_OPEN:
      return {
        ...state,
        isRenewalNotificationOpened: true
      };

    default:
      return state;
  }
};
