import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import { injectIntl } from "react-intl";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableFooter,
  TablePagination,
  TableSortLabel,
  TableContainer,
  FormControl,
  InputAdornment,
  Input
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { policyDetailsActions } from "../../../actions";
import TablePaginationActions from "../_HR/MyEmployees/TablePaginationActions";
import Enums from "../../../utils/Enums";

const DISABLED_POLICY_STATUSES = ["DC", "PN", "SU", "XN"];
class PolicyListingTable extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        label: "Company Name",
        field: "companyName",
        filter: this.getTextFilter("companyName")
      },
      {
        label: "Policy Number",
        field: "policyNo",
        filter: this.getTextFilter("policyNo")
      },
      {
        label: "Policy Period",
        field: "policyStartDate"
      },
      {
        label: "Plan Type",
        field: "planType"
      },
      {
        label: "Status",
        field: "policyStatus"
      }
    ];

    this.state = {
      order: Enums.SORT.ASC,
      orderBy: "policyStartDate",
      processedData: null,
      filterValues: {
        companyName: "",
        policyNo: ""
      }
    };
  }

  getTextFilter = name => classes => {
    return (
      <FormControl className={classes.margin}>
        <Input
          value={this.state.filterValues[name]}
          onChange={this.handleFilterChange(name)}
          classes={{
            root: classes.textRoot,
            underline: classes.cssUnderline
          }}
          onKeyPress={event => {
            if (event.key === "Enter") {
              this.handleSubmitFilter();
              event.preventDefault();
            }
          }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon className={classes.filterIcon} />
            </InputAdornment>
          }
        />
      </FormControl>
    );
  };

  handleFilterChange = name => event => {
    this.state.filterValues[name] = event.target.value;
    this.setState({ filterValues: this.state.filterValues });
  };

  validateInput = (columnName, inputValue, hasError) => {
    if (hasError) {
      return hasError;
    }
    const minValue = columnName === "policyNo" ? 4 : 3;
    const maxValue = columnName === "policyNo" ? 8 : 100;
    const searchColumn = columnName === "policyNo" ? "Policy Number" : "Company Name";

    const inputPattern = /^[a-zA-Z0-9 ()&.@/-]*$/;
    const isValidInput = columnName === "policyNo" ? !isNaN(+inputValue) : inputPattern.test(inputValue);

    if (!isValidInput) {
      return "Invalid characters found. Please enter the allowed characters only.";
    } else if (inputValue.length < minValue) {
      return `Minimum ${minValue} characters required for ${searchColumn}.`;
    } else if (inputValue.length > maxValue) {
      return `Maximum ${maxValue} characters allowed for ${searchColumn}.`;
    }
  };

  // filter function
  handleSubmitFilter = () => {
    let filterValues = this.state.filterValues;
    let hasError = "";

    // to show all the policy data
    if (!filterValues.companyName && !filterValues.policyNo) {
      this.setState({ processedData: this.props.policyList });
      this.sortHandler(this.state.orderBy);
      return;
    }

    // validate input
    Object.entries(filterValues).forEach(([key, value]) => {
      if (value !== "") {
        hasError = this.validateInput(key, value, hasError);
      }
    });

    if (hasError) {
      this.props.handleErrorInput(hasError);
      return;
    }

    const filteredData = this.props.policyList.filter(policy => {
      let filterCompanyName = policy["companyName"].toLowerCase().includes(filterValues["companyName"].toLowerCase());
      let filterPolicyNumber = policy["policyNo"].includes(filterValues["policyNo"]);

      if (filterCompanyName && filterPolicyNumber) {
        return policy;
      }
    });
    this.setState({ processedData: filteredData });
    this.sortHandler();
    this.props.handleResetPage();
  };

  // sort function
  sortHandler = columnName => () => {
    const order = this.state.order === Enums.SORT.DESC ? Enums.SORT.ASC : Enums.SORT.DESC;

    const selectedTable = this.state.processedData ? this.state.processedData : this.props.policyList;
    let sortedTable;
    if (columnName === "policyStartDate") {
      sortedTable = [...selectedTable].sort((a, b) => {
        return order === Enums.SORT.ASC
          ? new Date(a[columnName]) - new Date(b[columnName])
          : new Date(b[columnName]) - new Date(a[columnName]);
      });
    } else if (columnName === "policyNo") {
      sortedTable = [...selectedTable].sort((a, b) => {
        return order === Enums.SORT.ASC ? a[columnName] - b[columnName] : b[columnName] - a[columnName];
      });
    } else {
      sortedTable = [...selectedTable].sort((a, b) => {
        return order === Enums.SORT.ASC
          ? a[columnName].localeCompare(b[columnName])
          : b[columnName].localeCompare(a[columnName]);
      });
    }

    this.setState({ processedData: sortedTable, order, orderBy: columnName });
    this.props.handleResetPage();
  };

  componentDidUpdate(prevProps) {
    if (this.props.policyList !== prevProps.policyList) {
      this.sortHandler(this.state.orderBy)();
    }
  }

  handleRowClick = policy => {
    this.props.setCurrentPolicyNo(policy.policyNo);
    this.props.getPolicyDetails(policy.policyNo);
    this.props.history.push(`/auth/pi/dashboard`);
  };

  disablePolicyStatus(policy, classes) {
    return DISABLED_POLICY_STATUSES.includes(policy.policyStatusCode) ? classes.isDisable : "";
  }

  isClickable(policy) {
    return DISABLED_POLICY_STATUSES.includes(policy.policyStatusCode)
      ? ""
      : { onClick: () => this.handleRowClick(policy) };
  }

  render() {
    const { classes, currentPageNo, policyList, handlePageChange } = this.props;
    const tableRowPerPage = 10;
    const totalPageList = this.state.processedData ? this.state.processedData : policyList;
    const offsetStartIdx = Math.floor(currentPageNo * tableRowPerPage);
    const offsetEndIdx = Math.floor(offsetStartIdx + tableRowPerPage - 1);
    const currentPageList = (totalPageList || []).filter((data, idx) => offsetStartIdx <= idx && idx <= offsetEndIdx);

    return (
      <div className={classes.content}>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead className={classes.tableHeaderRow}>
              <TableRow>
                {this.columns.map((column, idx) => (
                  <TableCell key={idx} className={classes.tableHeaderCell}>
                    <TableSortLabel
                      className={classes.sortLabel}
                      active={this.state.orderBy === column.field}
                      direction={this.state.order}
                      onClick={this.sortHandler(column.field)}
                    >
                      {column.label}
                    </TableSortLabel>
                    <br />
                    {column.filter ? column.filter(classes) : ""}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {totalPageList.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan="5">No record found</TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {currentPageList.map((policy, row) => (
                  <TableRow className={classes.tableRow} key={row} {...this.isClickable(policy)}>
                    {this.columns.map((column, idx) => (
                      <TableCell
                        key={idx}
                        className={`${classes.tableBodyCell} ${this.disablePolicyStatus(policy, classes)}`}
                      >
                        {column.field === "policyStartDate"
                          ? `${moment(policy[column.field]).format("DD MMM YYYY")} - ${moment(
                              policy["policyEndDate"]
                            ).format("DD MMM YYYY")}`
                          : column.field === "policyStatus"
                          ? policy[column.field].charAt(0).toUpperCase() + policy[column.field].slice(1).toLowerCase()
                          : policy[column.field]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            )}
            {totalPageList.length !== 0 && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={11}
                    count={(totalPageList && totalPageList.length) || 0}
                    rowsPerPage={tableRowPerPage}
                    page={currentPageNo}
                    onPageChange={handlePageChange}
                    ActionsComponent={TablePaginationActions}
                    rowsPerPageOptions={[]}
                    labelDisplayedRows={({ from, to, count }) => {
                      return `Showing ${from} to ${to} of ${count} results`;
                    }}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </div>
    );
  }
}

const styles = theme => ({
  content: {
    width: "100%",
    overflowX: "auto"
  },
  table: {
    backgroundColor: theme.palette.background.paper
  },
  tableHeaderRow: {
    backgroundColor: "black",
    border: "0.1rem solid #cccccc"
  },
  tableHeaderCell: {
    fontSize: "14px",
    color: "white",
    fontWeight: "bold",
    border: "0.1rem solid #cccccc",
    paddingLeft: "15px"
  },
  tableBodyCell: {
    color: "black",
    border: "0.1rem solid #cccccc",
    paddingLeft: "15px"
  },
  tableRow: {
    cursor: "pointer"
  },
  sortLabel: {
    "&.Mui-active": {
      color: "white"
    },
    "& .MuiTableSortLabel-icon": {
      color: "inherit !important"
    }
  },
  cssUnderline: {
    "&:after": {
      backgroundColor: "white"
    }
  },
  textRoot: {
    fontSize: "0.75rem",
    fontWeight: 400,
    color: "black",
    backgroundColor: "white"
  },
  filterIcon: {
    paddingLeft: "5px",
    color: "black"
  },
  isDisable: {
    color: "#cccccc",
    cursor: "default"
  }
});

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    setCurrentPolicyNo: policyNo => dispatch(policyDetailsActions.setCurrentPolicyNo(policyNo)),
    getPolicyDetails: policyNo => dispatch(policyDetailsActions.getPolicyDetails(policyNo))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles, { withTheme: true })(PolicyListingTable)))
);
