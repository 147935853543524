import pi from "../../productInfo";

const { DOCDOC, VALIDUS, WELLTEQ, HREASILY, SMEHEALTH, STASH } = pi.productKeys;

export default {
  validus: {
    logo: [require("../../../img/validus-logo.png")],
    key: VALIDUS,
    name: "Validus",
    background: require("../../../img/benefit-banner-background.png"),
    header: "Financing Made Easy.",
    subHeader: "Easy access to business financing and consulting to drive  a successful business.",
    button: {
      first: "Get started",
      second: "Request a Callback"
    },
    subInformation: {
      title: "Receive $500 off your first facility disbursement fee. *",
      desc: "* Brought to you by Validus. T&C’s apply"
    },
    content: {
      header: "About Validus:",
      description:
        "Having the right financing in place goes a long way in helping to manage cash flow, purchase inventory, or to simply fund your business growth. Validus’ online peer-to-peer lending platform gives you access to invoice financing in a fast, affordable and reliable way, making it easier and quicker for businesses to obtain low-interest, zero collateral financing at a lower cost.\n\n" +
        "Access up to $500,000, invoice, PO and working capital financing within 48 hours of approval."
    },
    highlights: {
      service:
        "<ul><li>Financing up to $500.000</li><li>Invoice financing</li><li>PO financing</li><li>Working capital financing</li></ul>",
      benefits:
        "<ul><li>Easy application, approval within 48 hours</li><li>Competitive, low interest rates</li><li>Zero-collateral</li><li>Simple and flexible terms, no hidden costs</li></ul>",
      results:
        "<ul><li>Save on financing costs</li><li>Flexible - Access cash as you need</li><li>Increase or maintain stable cash flow</li><li>Position your company for growth</li><li>Fund business expansion</li></ul>"
    }
  },
  // hreasily: {
  //   logo: [require("../../../img/HRE_Logo.png")],
  //   key: HREASILY,
  //   name: "HReasily",
  //   background: require("../../../img/benefit-banner-background.png"),
  //   header: "HReasily",
  //   subHeader: "An easier way to run your everyday HR processes.",
  //   button: {
  //     first: "Learn More",
  //     second: "Free Trial",
  //     third: "Download Brochure"
  //   },
  //   subInformation: {
  //     title: "All Pruworks customers get 20% off published pricing.",
  //     desc: ""
  //   },
  //   content: {
  //     header: "About HReasily:",
  //     description:
  //       "HReasily is a fully integrated digital HR management solution available in 7 countries across Southeast Asia and is the fastest growing HR-tech firm in the region. Our vision is to simplify traditional HR functions by automating your workflows, saving you time and effort."
  //   },
  //   highlights: {
  //     service:
  //       "<ul><li>Automate your <b>PAYROLL</b>.</li><li>Digitize <b>LEAVE</b> and <b>CLAIM</b> applications.</li><li>Track <b>TIME ATTENDANCE</b> via <b>FACIAL RECOGNITION</b>.</li><li>Online <b>STAFF</b> Database</li></ul>",
  //     benefits:
  //       "<ul><li>Easily access information on a single platform through multiple devices.</li><li>Digitally file employee data via the cloud.</li><li>Free mobile app on iOS and Android.</li></ul>",
  //     results:
  //       "<ul><li>Manage HR tasks more efficiently and with the ability to do it remotely.</li><li>Increase productivity and accomplish more in less time.</li><li>Reduce human error by automating workflows and calculations.</li></ul>"
  //   }
  // },
  smehealth: {
    logo: [require("../../../img/SME_Health+_Logo.png")],
    key: SMEHEALTH,
    name: "SMEHealth",
    background: require("../../../img/benefit-banner-background.png"),
    header: "SME Health+",
    subHeader: "A scheme that aims to support Small and Medium Enterprises (SMEs) in their health promotion journey ",
    subHeaderExplain: "* Programme will need to be conducted by 31 Dec 2019 in order to be eligible for funding",
    button: {
      first: "Request a Callback"
    },
    subInformation: {
      title: "Eligibility Criteria",
      desc:
        "- SMEs with less than 200 employees or annual sales revenue of less than SGD100 million. \n" +
        "- Company with a Unique Entity Number (UEN)."
    },
    content: {
      header: "About SME Health+",
      description:
        "SME Health+ is a scheme that aims to support Small and Medium Enterprises (SMEs) in their health promotion journey. Under this new scheme, SMEs will have easy access to cost-effective and impactful health programmes co-funded by HPB for employees." +
        ' Kindly <a href="https://www.hpb.gov.sg/workplace/workplaceprogrammes/sme-workplace-health-package"/>click here</a> for more information on the scheme.'
    },
    highlights: {
      service:
        "<ul><li>Chronic Disease Management</li><li>Health Eating</li><li>Active Living</li><li>Mental Welling</li></ul>",
      benefits:
        "<ul><li>Creating healthy environments</li><li>Enumerates a healthier workforce and decreased absenteeism</li></ul>",
      results:
        "<ul><li>Cost-effective and impactful health programmes</li><li>Higher productivity</li><li>Reduced health care costs</li></ul>"
    }
  },
  docdoc: {
    logo: [require("../../../img/docdoc-logo.png")],
    key: DOCDOC,
    name: "DocDoc",
    background: require("../../../img/benefit-banner-background.png"),
    header: "Easy Access to Medical Specialists",
    youTubeID: "6ktJS-A9F9M",
    subHeader:
      "Call <span class='red'>+65-6871-4111</span> for a free discovery consultation on Monday - Friday between 8am - 6.30pm and Saturday between 9am - 2pm. *",
    subHeaderExplain:
      "* Your phone call will be made to a third party service provider for a free discovery consultation",
    button: {
      first: "Request a Callback"
    },
    subInformation: {
      title: "We invite you to try the service for free *",
      desc: "* Brought to you by DocDoc. T&C’s apply"
    },
    content: {
      header: "About Docdoc:",
      description:
        "DocDoc’s discovery system brings you in contact with a doctor who will provide you with a personalised set of recommended specialists based on your unique medical preferences and needs.\n\nOnce you have chosen your specialist, DocDoc will secure an appointment and support you throughout the healthcare journey."
    },
    highlights: {
      service:
        "<ul><li>Access to high quality, patient care</li><li>Personalised set of recommended specialists</li><li>Support throughout health care journey</li></ul>",
      benefits:
        "<ul><li>Large provider network in Asia</li><li>Verified, high quality, certified doctors</li><li>A company that cares</li></ul>",
      results: "<ul><li>Peace of mind</li><li>Personalised care</li><li>Informed healthcare decisions</li></ul>"
    }
  },
  wellteq: {
    logo: [require("../../../img/wellteq-logo.png")],
    key: WELLTEQ,
    name: "Wellteq",
    background: require("../../../img/benefit-banner-background.png"),
    header: "Healthy Employees In Body & Mind",
    button: {
      first: "Request a Callback"
    },
    subInformation: {
      title: "We invite you to try the service for free *",
      desc: "* Brought to you by WellteQ. T&C’s apply"
    },
    content: {
      header: "About Wellteq:",
      description:
        "Employee Wellness is key to productivity. WellteQ’s digital health solutions provides employees access to a suite of wellness & engagement programmes; helping employees improve their health and well-being via a gamified platform offering powerful activity challenges, mental and financial programmes, rewards and virtual coaching. It’s a great way to motivate employees, challenging them to stay fit.\n\n" +
        "In addition, via real time engagement and HR risk analytics, WellteQ enables you to understand your organisation’s health risks, culture and engagement statistics, improving your company’s productivity and efficiency."
    },
    highlights: {
      service:
        "<ul><li>Team activity challenges</li>" +
        "<li>Mental and financial wellbeing programmes</li>" +
        "<li>3D Health Avatar</li><li>HR data analytics</li>" +
        "<li>Communication platform</li></ul>",
      benefits:
        "<ul><li>Identify & reduce health risks</li><li>Reduce costs - absenteeism, insurance, turnover</li><li>Improve health & productivity</li><li>Data intelligence - HR strategy</li><li>Real time communication</li></ul>",
      results:
        "<ul><li>Healthier, motivated employees</li><li>Improved productivity</li><li>Stronger workforce relationships</li><li>Employees understanding of their own well-being</li></ul>"
    }
  }
  // stash: {
  //   logo: [require("../../../img/stash-background.png")],
  //   key: STASH,
  //   name: "stash",
  //   background: require("../../../img/benefit-banner-background.png"),
  //   header: "STASH NextGen Employee Benefits",
  //   subHeader:
  //     "Enabling flexibility. Motivating employees. </br> </br> With STASH NextGen's Employee Benefits, employees will enjoy flexibility of choosing benefits that they value the most from our exclusive list of merchants. In addition, STASH NextGen's platform will enable you to reward employees in a fuss-free, seamless manner through the Employee Benefits Portal. </br> </br> By taking care of your employees, they will take care of your business.",
  //   button: {
  //     first: "Get started"
  //   },
  //   subInformation: {
  //     title: "",
  //     desc: ""
  //   },
  //   content: {
  //     header: "STASH NextGen",
  //     description:
  //       "Why STASH NextGen?\n\n" +
  //       "With thousands of digital partners, we’re a proven first-mover in mobile-first borderless payments, rewards and real-time data intelligence.\n\n" +
  //       "This goes beyond seamlessly integrating with our next-gen payment engine. We empower you to brand and mobilise every transaction to deliver a richer, more rewarding experience. It’s a powerful combination that provides instant fulfilment and secure global connectivity.\n\n" +
  //       "This enables us to partner with global brands and reward employees with flexible payment options to spend on health, online shopping and in-store purchases.\n\n" +
  //       "Let’s get started. Choose the benefit package that fits your business and employees best."
  //   },
  //   highlights: {
  //     service:
  //       "<del>S$1.60/Employee/Month</del></br>" +
  //       "S$1.28/Employee/Month </br>" +
  //       "<p class='red' >(Introductory offer: 20% off until 31/03/2022)</p>" +
  //       "Reward your employees with an assigned amount of money for them to redeem their preferred digital gift cards/ vouchers.</br></br>" +
  //       "Features:</br>" +
  //       "<ul><li>Access to leading brands across multiple categories</li><li>Instant access to digital benefits</li><li>Shop online or in-store</li></ul>",
  //     benefits:
  //       "<del>S$2.20/Employee/Month</del></br>" +
  //       "S$1.76/Employee/Month </br>" +
  //       "<p class='red'>(Introductory offer: 20% off until 31/03/2022)</p>" +
  //       "Your enhanced employee benefits package includes FlexBen Advantage features and FlexPay - a digital Mastercard for your employees to shop for any product online." +
  //       "</br></br>Features:</br>" +
  //       "<ul><li>Access to leading brands across multiple categories</li><li>Instant access to digital benefits</li><li>Shop online or in-store (for digital gift cards/vouchers)</li><li>Ability to allocate part of all your employee benefits to your FlexPay card</li><li>Use your FlexPay Mastercard for online purchases</li></ul>",
  //     results:
  //       "<del>S$2.80/Employee/Month</del></br>" +
  //       "S$2.24/Employee/Month </br>" +
  //       "<p class='red'>(Introductory offer: 20% off until 31/03/2022)</p>" +
  //       "Your enhanced employee benefits package includes FlexBen Premium features and Offers - exclusive discounts to your favourite brands. " +
  //       "</br></br>Features:</br>" +
  //       "<ul><li>Access to leading brands across multiple categories</li><li>Instant access to digital benefits</li><li>Shop online or in-store (for digital gift cards/vouchers)</li><li>Ability to allocate part of all your employee benefits to your FlexPay card</li><li>Use your FlexPay Mastercard for online purchases</li><li>Exclusive access to merchant offers and discounts</li></ul>"
  //   }
  // }
};
