import * as actionTypes from "../actions/types";
import Enums from "../utils/Enums";
import config from "../config/config";
import Util from "../utils/Util";

const defaultProduct = config.products.config[config.products.defaultCode];

import { initialState as employeeInitialState, employee } from "./employee.reducer";

export const initialState = {
  premiums: {
    estimate: {},
    actual: {}
  },
  data: {
    amlClauseAction: null,
    categories: [],
    estimates: [],
    persons: [],
    specificIndividuals: []
  },
  name: null,
  id: null,
  planValid: false,
  product_code: defaultProduct.code,
  product_version: defaultProduct.version,
  product_id: null,
  req_effective_date: null,
  isSaving: false,
  isLoading: false,
  isLoaded: false,
  isResetting: false,
  employeeRevalidateFlag: false,
  dependantSnackbarFlag: false,
  activeStep: 0,
  pdpa_consent: false,
  employee: employeeInitialState,
  confirmActivationDate: null,
  resetTime: new Date().valueOf().toString(),
  showSuccessDialog: false,
  showSnackBarError: false,
  showSnackBarSuccess: false,
  submittedErrorMsg: null,
  isGenerateQuoteLoading: false,
  quoteGenerated: false,
  readOnly: false,
  status: "DRAFT",
  waiting_period: null,
  stageValidation: {
    ESTIMATE: false,
    EMPLOYEE: false,
    PROPOSAL: false,
    SUBMISSION: false,
    SUBMITTED: false
  },
  payment_method: null,
  payment_status: null,
  payment_request: {
    isProcessing: false,
    isProcessed: false,
    error: null
  },
  business_type: Enums.BUSINESS_TYPE.NEW,
  existing_policy_no: null
};

export const quote = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.QUOTE_SET_PRODUCT_CODE_VERSION:
      return {
        ...state,
        product_code: action.payload.code,
        product_version: action.payload.version
      };
    case actionTypes.QUOTE_RESET_TRIGGERED:
      return {
        ...state,
        isResetting: true
      };
    case actionTypes.QUOTE_RESET:
      return {
        ...initialState,
        product_code: state.product_code,
        product_version: state.product_version,
        product_id: state.product_id,
        isResetting: false,
        resetTime: new Date().valueOf().toString()
      };
    case actionTypes.QUOTE_INIT:
      return {
        ...state,
        product_code: action.payload.code,
        product_id: action.payload.id
      };
    case actionTypes.QUOTE_SET_ID:
      return {
        ...state,
        id: action.payload.id
      };
    case actionTypes.QUOTE_SET_ACTIVATION_DATE:
      return {
        ...state,
        req_effective_date: action.payload
      };
    case actionTypes.QUOTE_SET_CONFIRM_ACTIVATION_DATE:
      return {
        ...state,
        confirmActivationDate: action.payload
      };
    case actionTypes.QUOTE_SET_PLANS:
      return {
        ...state,
        plans: action.payload
      };
    case actionTypes.QUOTE_SET_ESTIMATED_PREMIUM:
      return {
        ...state,
        premiums: {
          ...state.premiums,
          estimate: action.payload
        }
      };
    case actionTypes.QUOTE_SET_ACTUAL_PREMIUM:
      return {
        ...state,
        premiums: {
          ...state.premiums,
          actual: action.payload
        }
      };
    case actionTypes.QUOTE_SET_CATEGORIES:
      return {
        ...state,
        data: {
          ...state.data,
          categories: action.payload
        }
      };
    case actionTypes.QUOTE_SET_SPECIFIC_INDIVIDUALS:
      return {
        ...state,
        data: {
          ...state.data,
          specificIndividuals: action.payload
        }
      };
    case actionTypes.QUOTE_SET_AML_CLAUSE_ACTION:
      return {
        ...state,
        data: {
          ...state.data,
          amlClauseAction: action.payload
        }
      };
    case actionTypes.QUOTE_SET_ESTIMATES:
      return {
        ...state,
        data: {
          ...state.data,
          estimates: action.payload
        }
      };
    case actionTypes.QUOTE_SET_PERSONS:
      return {
        ...state,
        data: {
          ...state.data,
          persons: Util.cloneOrigin(action.payload)
        }
      };
    case actionTypes.QUOTE_SET_NAME:
      return {
        ...state,
        name: action.payload
      };
    case actionTypes.QUOTE_SET_EXISTING_POLICY_NO:
      return {
        ...state,
        existing_policy_no: action.payload
      };
    case actionTypes.QUOTE_SET_BUSINESS_TYPE:
      return {
        ...state,
        business_type: action.payload
      };
    case actionTypes.QUOTE_SET_PDPA:
      return {
        ...state,
        pdpa_consent: action.payload
      };
    case actionTypes.QUOTE_SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload,
        employee: {
          ...state.employee,
          showEmployeeList: false,
          shouldShowPopup: false,
          uploadErrorCode: -1
        }
      };
    case actionTypes.QUOTE_SAVE:
      return {
        ...state,
        isSaving: true
      };
    case actionTypes.QUOTE_SAVE_FULFILLED: {
      let response = action.payload;
      let data = response.data;
      return {
        ...state,
        ...data,
        isSaving: false,
        savedTime: new Date()
      };
    }
    case actionTypes.QUOTE_SAVE_REJECTED:
      return {
        ...state,
        isSaving: false,
        failedTime: new Date()
      };
    case actionTypes.QUOTE_GET_PENDING:
      return {
        ...state,
        payment_request: { ...initialState.payment_request },
        isSaving: false,
        isLoaded: false,
        isLoading: true
      };
    case actionTypes.QUOTE_GET_FULFILLED: {
      let response = action.payload;
      let _quote = response.data;
      let data = _quote.data;
      data.persons = Util.cloneOrigin(data.persons) || [];
      data.categories = data.categories || [];
      data.estimates = data.estimates || [];
      data.specificIndividuals = data.specificIndividuals || [];
      data.amlClauseAction = data.amlClauseAction;
      return {
        ...state,
        ..._quote,
        isSaving: false,
        isLoading: false,
        isLoaded: true,
        readOnly: _quote.stages === Enums.STAGES.SUBMITTED || _quote.stages === Enums.STAGES.INFORCE,
        savedTime: new Date(),
        quoteGenerated: _quote.stages === Enums.STAGES.PROPOSAL && _quote.has_proposal_file
      };
    }
    case actionTypes.QUOTE_GET_REJECTED:
      return {
        ...state,
        isSaving: false,
        isLoading: true,
        isLoaded: true,
        failedTime: new Date()
      };
    case actionTypes.QUOTE_SUBMIT_POLICY:
      return {
        ...state,
        readOnly: true
      };
    case actionTypes.QUOTE_SUBMIT_POLICY_SUCCESS:
      return {
        ...state,
        showSuccessDialog: true,
        readOnly: true,
        stages: Enums.STAGES.SUBMITTED
      };
    case actionTypes.QUOTE_SUBMIT_POLICY_ERROR:
      return {
        ...state,
        showSnackBarError: true,
        readOnly: false,
        submittedErrorMsg: action.payload
      };
    case actionTypes.QUOTE_SUBMIT_POLICY_CLOSE_SUCCESS_POPUP:
      return {
        ...state,
        showSuccessDialog: false
      };
    case actionTypes.QUOTE_SUBMIT_POLICY_CLOSE_SNACKBAR:
      return {
        ...state,
        showSnackBarError: false,
        showSnackBarSuccess: false,
        submittedErrorMsg: null
      };
    case actionTypes.QUOTE_SUBMIT_QUOTE_SUCCESS:
      return {
        ...state,
        showSnackBarSuccess: true,
        isGenerateQuoteLoading: false,
        quoteGenerated: true
      };
    case actionTypes.QUOTE_SUBMIT_QUOTE:
      return {
        ...state,
        showSnackBarSuccess: false,
        showSnackBarError: false,
        isGenerateQuoteLoading: true
      };
    case actionTypes.QUOTE_SUBMIT_QUOTE_ERROR:
      return {
        ...state,
        showSnackBarError: true,
        submittedErrorMsg: action.payload,
        isGenerateQuoteLoading: false,
        quoteGenerated: false
      };
    case actionTypes.QUOTE_SET_WAITING_PERIOD:
      return {
        ...state,
        waiting_period: action.payload
      };
    case actionTypes.QUOTE_SET_STAGE_VALIDATION:
      let stageValidation = state.stageValidation;
      stageValidation[action.payload.stage] = action.payload.status;
      return {
        ...state,
        stageValidation: {
          ...stageValidation
        }
      };
    case actionTypes.QUOTE_SET_REVALIDATE_FLAG:
      return {
        ...state,
        employeeRevalidateFlag: true
      };
    case actionTypes.QUOTE_UNSET_REVALIDATE_FLAG:
      return {
        ...state,
        employeeRevalidateFlag: false
      };
    case actionTypes.QUOTE_SET_DEPENDANT_SNACKBAR_FLAG:
      return {
        ...state,
        dependantSnackbarFlag: action.payload
      };
    case actionTypes.QUOTE_SET_PAYMENT_METHOD: {
      return {
        ...state,
        payment_method: action.payload
      };
    }
    case actionTypes.QUOTE_MAKE_PAYMENT_PENDING: {
      return {
        ...state,
        payment_request: {
          ...state.payment_request,
          isProcessing: true,
          isProcessed: false,
          error: null
        }
      };
    }
    case actionTypes.QUOTE_MAKE_PAYMENT_FULFILLED: {
      return {
        ...state,
        payment_status: Enums.PAYMENT_STATUS.DONE,
        payment_request: {
          ...state.payment_request,
          isProcessing: false,
          isProcessed: true,
          error: null
        }
      };
    }
    case actionTypes.QUOTE_MAKE_PAYMENT_REJECTED: {
      return {
        ...state,
        payment_request: {
          ...state.payment_request,
          isProcessing: false,
          isProcessed: false,
          error: action.payload
        }
      };
    }

    case actionTypes.QUOTE_EXISTING_POLICY_MEMBER_DETAILS_FULFILLED:
      return {
        ...state,
        ...action.payload,
        data: {
          ...state.data,
          persons: action.payload.data
        }
      };
    default:
      return employee(state, action);
  }
};
