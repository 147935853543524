import React, { Component } from "react";
import { injectIntl } from "react-intl";
import classNames from "classnames";
import MobileDetect from "mobile-detect";

import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { documentActions, quoteActions } from "../../../../../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import config from "../../../../../../config/config";
import RenderFileOrUploader from "./RenderFileOrUploader";
import RenderMultipleFileUploader from "./RenderMultipleFileUploader";
import SimpleMessageDialog from "../../../../../../components/SimpleMessageDialog";
import CustomStep from "../../../../../../components/CustomStep";
import * as _ from "lodash";
import ENUMS from "../../../../../../utils/Enums";
import Util from "../../../../../../utils/Util";
import DropZone from "./DropZone";
import Esign from "./Esign";
import UEN from "./UEN";
import {
  DOCUMENT_ACCEPTANCE_LETTER_UPLOAD,
  DOCUMENT_ACRA_UPLOAD,
  DOCUMENT_MAS314_UPLOAD,
  DOCUMENT_CORP_BANK_UPLOAD,
  DOCUMENT_DECLARATION_CLAIM_ENTITLEMENT_UPLOAD,
  DOCUMENT_NRIC_IND_UPLOAD
} from "../../../../../../actions/types";
import { data } from "browserslist";
import { stubFalse } from "lodash";

const mobileDetect = new MobileDetect(window.navigator.userAgent);
const isDesktop = Util.isEmpty(mobileDetect.phone() || mobileDetect.tablet());
const isChrome = window.navigator.userAgent.includes("Chrome");
const isFirefox = window.navigator.userAgent.includes("Firefox");
//const eSignUnsupp = isDesktop && (isChrome || isFirefox);
const eSignUnsupp = false;

class SubmitDoc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      errors: {},
      showDialogError: false,
      dialogTitle: "",
      dialogDescription: ""
    };
  }

  componentWillMount() {
    const { quote } = this.props;
    if (quote.id) this.props.documentActions.getFiles(quote.id);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.document.files !== nextProps.document.files) {
      this.setState({ files: nextProps.document.files });
    }
  }

  handleClose = () => {
    this.setState({ showDialogError: false });
  };

  /**
   * Check file extension is valid or not
   * @return False if file is invalid. True if valid
   */
  invalidExtension = file => {
    let getExtension = file.name.split(".").pop();
    let arrayFileExtension = config.fileUploadOptions.accept.split(",").map(extension => {
      return extension.substr(1);
    });
    return arrayFileExtension.indexOf(getExtension) > 0;
  };

  getError = file => {
    const { intl } = this.props;

    if (file.size > config.fileUploadOptions.maxSize) {
      return {
        inValid: true,
        title: intl.formatMessage({ id: "popup.file.sizeExceeds.title" }),
        description: intl.formatMessage({
          id: "popup.file.sizeExceeds.description"
        })
      };
    }
    // else if (!this.invalidExtension(file)) {
    //   return {
    //     inValid: true,
    //     title: intl.formatMessage({ id: "popup.file.invalidExtension.title" }),
    //     description: intl.formatMessage({
    //       id: "popup.file.invalidExtension.description"
    //     })
    //   };
    // }
  };

  onDropRejected = (files, type) => {
    let firstFile = _.first(files);
    let errorData = this.getError(firstFile);
    if (errorData && errorData.inValid === true) {
      this.setState({
        errors: {
          [type]: {
            invalid: errorData.inValid,
            dialogTitle: errorData.title,
            dialogDescription: errorData.description
          }
        }
      });
    }
  };

  handleUploadFile = (uploadAction, fileType, files) => {
    const { errors } = this.state;
    Util.forEachAsync(files, file => {
      console.log("fileType", errors, fileType);
      this.props.documentActions.uploadFile(this.props.quote.id, uploadAction, fileType, file).then(
        () => {
          this.setState({
            errors: {
              ...errors,
              [fileType]: null
            }
          });
        },
        error => {
          if (DOCUMENT_ACCEPTANCE_LETTER_UPLOAD == uploadAction) {
            error.message =
              "Invalid file format due to encrypted file. To unencrypt your file, you may refer to user guide under the Documents tab.";
          }

          this.setState({
            errors: {
              ...errors,
              [fileType]: {
                invalid: false,
                dialogTitle: "Upload error",
                dialogDescription: error.message
              }
            }
          });
        }
      );
    });
  };

  render() {
    const { classes, document, intl, quote, proposalDisabled } = this.props;
    const { files } = this.state;
    const ACCEPTANCE_FILE_TYPE = ENUMS.FILE_TYPE.ACCEPTANCE_FILE_TYPE;
    const ACRA_FILE_TYPE = ENUMS.FILE_TYPE.ACRA_FILE_TYPE;
    const MAS314_FILE_TYPE = ENUMS.FILE_TYPE.MAS314_FILE_TYPE;
    const CORP_BANK_FILE_TYPE = ENUMS.FILE_TYPE.CORP_BANK_DETAILS;
    const NRIC_IND_FILE_TYPE = ENUMS.FILE_TYPE.NRIC_IND_FILE_TYPE;
    const DECLARATION_CLAIM_ENTITLEMENT_FILE_TYPE = ENUMS.FILE_TYPE.DECLARATION_CLAIM_ENTITLEMENT_FILE_TYPE;
    const acceptanceLetterFiles = files.filter(file => file.file_type === ACCEPTANCE_FILE_TYPE) || [];
    const acraFiles = files.filter(file => file.file_type === ACRA_FILE_TYPE) || [];
    const mas314Files = files.filter(file => file.file_type === MAS314_FILE_TYPE) || [];
    const corpBankFiles = files.filter(file => file.file_type === CORP_BANK_FILE_TYPE) || [];
    const claimDeclarationFile = files.filter(file => file.file_type === DECLARATION_CLAIM_ENTITLEMENT_FILE_TYPE) || [];
    const NRIC_INDFiles = files.filter(file => file.file_type === NRIC_IND_FILE_TYPE) || [];

    let isPaid =
      quote.payment_method === ENUMS.PAYMENT_METHOD.CREDIT_CARD && quote.payment_status === ENUMS.PAYMENT_STATUS.DONE;
    let disabled = proposalDisabled || isPaid;

    return (
      <Grid container>
        <Grid item md={1} align="center">
          <CustomStep label="1" disabled={disabled} />
        </Grid>
        <Grid item md={11}>
          <Paper className={classNames(classes.root)} style={{ padding: "10px" }}>
            <div>
              <Typography variant="h5" gutterBottom className={classNames(classes.container, classes.heading)}>
                {intl.formatMessage({
                  id: "upload.signedProposalForm.subheading"
                })}
              </Typography>
              <div className={classNames(classes.content)}>
                <Grid item md={12}>
                  <Typography className={classNames(classes.infoHeading)}>
                    {intl.formatMessage({
                      id: "upload.signedProposalForm.infoheading.before"
                    })}
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <Grid container className={classNames(classes.center)}>
                    <Grid item md={3} className={classNames(acceptanceLetterFiles.length > 0 ? classes.hidden : null)}>
                      <Esign disabled={disabled} eSignUnsupp={eSignUnsupp} />
                    </Grid>
                    <Grid
                      item
                      md={1}
                      align="center"
                      variant="dispay2"
                      className={classNames(acceptanceLetterFiles.length > 0 ? classes.hidden : null)}
                    >
                      <Typography>OR</Typography>
                    </Grid>
                    <Grid item md={8}>
                      <RenderFileOrUploader
                        disabled={isPaid}
                        files={acceptanceLetterFiles}
                        Uploader={
                          <DropZone
                            disabled={disabled}
                            // accept={config.fileUploadOptions.accept}
                            maxSize={config.fileUploadOptions.maxSize}
                            descr={intl.formatMessage({
                              id: "upload.signedProposalForm.description"
                            })}
                            errorMessage={this.state.errors[ACCEPTANCE_FILE_TYPE]}
                            onDropRejected={files => {
                              this.onDropRejected(files, ACCEPTANCE_FILE_TYPE);
                            }}
                            onDropHandler={files => {
                              this.handleUploadFile(DOCUMENT_ACCEPTANCE_LETTER_UPLOAD, ACCEPTANCE_FILE_TYPE, files);
                            }}
                            isUploading={this.props.document.acceptanceLetterIsUploading}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item md={1}>
          <CustomStep label="2" disabled={quote.readOnly || isPaid} />
        </Grid>
        <Grid item md={11}>
          <Paper className={classNames(classes.root)} style={{ padding: "10px", marginTop: "10px" }}>
            <div>
              <Typography variant="h5" gutterBottom className={classNames(classes.container, classes.heading)}>
                {intl.formatMessage({ id: "upload.documents.subheading" })}
              </Typography>
              <div className={classNames(classes.content)}>
                <Grid item md={12}>
                  <Typography className={classNames(classes.infoHeading)}>
                    {intl.formatMessage({ id: "validator.file.rules" })}
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  {/*Upload ACRA Document Zone*/}
                  <RenderFileOrUploader
                    disabled={isPaid}
                    title={intl.formatMessage({ id: "upload.ACRA.title" })}
                    files={acraFiles}
                    Uploader={
                      <DropZone
                        // accept={config.fileUploadOptions.accept}
                        maxSize={config.fileUploadOptions.maxSize}
                        label={intl.formatMessage({ id: "upload.ACRA.title" })}
                        descr={intl.formatMessage({
                          id: "upload.ACRA.description"
                        })}
                        onDropRejected={files => {
                          this.onDropRejected(files, ACRA_FILE_TYPE);
                        }}
                        errorMessage={this.state.errors[ACRA_FILE_TYPE]}
                        onDropHandler={files => {
                          this.handleUploadFile(DOCUMENT_ACRA_UPLOAD, ACRA_FILE_TYPE, files);
                        }}
                        isUploading={this.props.document.acraIsUploading}
                      />
                    }
                  />
                </Grid>

                <Grid item md={6}>
                  <UEN />
                </Grid>

                <Grid item md={12}>
                  {/*Upload MAS314 Zone*/}
                  <RenderFileOrUploader
                    disabled={isPaid}
                    title={intl.formatMessage({ id: "upload.MAS314.title" })}
                    files={mas314Files}
                    Uploader={
                      <DropZone
                        // accept={config.fileUploadOptions.accept}
                        maxSize={config.fileUploadOptions.maxSize}
                        label={intl.formatMessage({
                          id: "upload.MAS314.title"
                        })}
                        descr={intl.formatMessage({
                          id: "upload.MAS314.description"
                        })}
                        onDropRejected={files => {
                          this.onDropRejected(files, MAS314_FILE_TYPE);
                        }}
                        errorMessage={this.state.errors[MAS314_FILE_TYPE]}
                        onDropHandler={files => {
                          this.handleUploadFile(DOCUMENT_MAS314_UPLOAD, MAS314_FILE_TYPE, files);
                        }}
                        isUploading={this.props.document.mas314IsUploading}
                      />
                    }
                  />
                </Grid>

                <Grid item md={12}>
                  {/*Upload Declaration of Entitlement to Claim Input Tax on Insurance Policy*/}
                  <RenderFileOrUploader
                    disabled={isPaid}
                    title={intl.formatMessage({ id: "upload.claim.tax.declaration.title" })}
                    files={claimDeclarationFile}
                    Uploader={
                      <DropZone
                        // accept={config.fileUploadOptions.accept}
                        disabled={quote.readOnly}
                        maxSize={config.fileUploadOptions.maxSize}
                        label={intl.formatMessage({
                          id: "upload.claim.tax.declaration.title"
                        })}
                        descr={intl.formatMessage({
                          id: "upload.claim.tax.declaration.description"
                        })}
                        onDropRejected={files => {
                          this.onDropRejected(files, DECLARATION_CLAIM_ENTITLEMENT_FILE_TYPE);
                        }}
                        errorMessage={this.state.errors[DECLARATION_CLAIM_ENTITLEMENT_FILE_TYPE]}
                        onDropHandler={files => {
                          this.handleUploadFile(
                            DOCUMENT_DECLARATION_CLAIM_ENTITLEMENT_UPLOAD,
                            DECLARATION_CLAIM_ENTITLEMENT_FILE_TYPE,
                            files
                          );
                        }}
                        isUploading={this.props.document.claimDeclarationIsUploading}
                      />
                    }
                  />
                </Grid>

                <Grid item md={12}>
                  {/*Upload NRIC_IND Zone*/}
                  <RenderMultipleFileUploader
                    disabled={quote.readOnly || isPaid}
                    title={intl.formatMessage({ id: "upload.NRIC_IND.title" })}
                    files={NRIC_INDFiles}
                    Uploader={
                      <DropZone
                        // accept={config.fileUploadOptions.accept}
                        maxSize={config.fileUploadOptions.maxSize}
                        descr={intl.formatMessage({
                          id: "upload.NRIC_IND.description"
                        })}
                        onDropRejected={files => {
                          this.onDropRejected(files, NRIC_IND_FILE_TYPE);
                        }}
                        errorMessage={this.state.errors[NRIC_IND_FILE_TYPE]}
                        onDropHandler={files => {
                          this.handleUploadFile(DOCUMENT_NRIC_IND_UPLOAD, NRIC_IND_FILE_TYPE, files);
                        }}
                        isUploading={this.props.document.NRIC_INDIsUploading}
                        multiple={true}
                      />
                    }
                  />
                </Grid>

                <Grid item md={12}>
                  {/*Uploadcorp bank details*/}
                  <RenderFileOrUploader
                    disabled={isPaid}
                    title={intl.formatMessage({ id: "upload.corpBank.title" })}
                    files={corpBankFiles}
                    Uploader={
                      <DropZone
                        // accept={config.fileUploadOptions.accept}
                        maxSize={config.fileUploadOptions.maxSize}
                        label={intl.formatMessage({
                          id: "upload.corpBank.title"
                        })}
                        descr={intl.formatMessage({
                          id: "upload.corpBank.description"
                        })}
                        onDropRejected={files => {
                          this.onDropRejected(files, CORP_BANK_FILE_TYPE);
                        }}
                        errorMessage={this.state.errors[CORP_BANK_FILE_TYPE]}
                        onDropHandler={files => {
                          this.handleUploadFile(DOCUMENT_CORP_BANK_UPLOAD, CORP_BANK_FILE_TYPE, files);
                        }}
                        isUploading={this.props.document.corpBankIsUploading}
                      />
                    }
                  />
                </Grid>
              </div>

              <SimpleMessageDialog
                type="error"
                name="error-file-uploading"
                title={this.state.dialogTitle}
                description={this.state.dialogDescription}
                open={this.state.showDialogError}
                closeHandler={this.handleClose}
                closeButtonText={intl.formatMessage({ id: "popup.button.ok" })}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%"
  },
  container: {
    padding: `20px ${theme.spacing.unit * 3}px`
  },
  content: {
    padding: theme.spacing.unit * 3,
    paddingTop: "5px"
  },
  heading: {
    borderBottom: `1px solid ${theme.colors.blackScale.black70}`
  },
  infoHeading: {
    color: `${theme.colors.blackScale.black80}`,
    paddingBottom: 20,
    fontSize: `${theme.infoHeading.fontSize}`
  },
  center: {
    display: "flex",
    alignItems: "center"
  },
  hidden: {
    display: "none"
  }
});

function mapStateToProps(state, prop) {
  return { document: state.document, quote: state.quote };
}

function mapDispatchToProps(dispatch) {
  return {
    documentActions: bindActionCreators(documentActions, dispatch),
    quoteActions: bindActionCreators(quoteActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(SubmitDoc)));
