import React, { Component } from "react";

import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import SelectInput from "./common/SelectInput";
import DateInput from "./common/DateInput";

import Enums from "../../../../utils/Enums";

const MEMBER_TYPE_OPTIONS = [
  {
    label: "All",
    value: "ALL"
  },
  {
    label: "Employee",
    value: "EMPLOYEE"
  },
  {
    label: "Dependent",
    value: "DEPENDENT"
  }
];
const CLAIM_TYPE_OPTIONS = [
  {
    label: "All",
    value: "ALL"
  },
  {
    label: "Inpatient",
    value: Enums.CLAIM_TYPE.INPATIENT
  },
  {
    label: "Outpatient - General Practitioners (GP)",
    value: Enums.CLAIM_TYPE.OUTPATIENT_GP
  },
  {
    label: "Outpatient - Specialist",
    value: Enums.CLAIM_TYPE.OUTPATIENT_SP
  },
  {
    label: "Outpatient - Dental",
    value: Enums.CLAIM_TYPE.OUTPATIENT_DENTAL
  },
  {
    label: "Outpatient - Health-Screen/Vaccinations",
    value: Enums.CLAIM_TYPE.OUTPATIENT_HS_VAC
  },
  {
    label: "Maternity",
    value: Enums.CLAIM_TYPE.MATERNITY
  },
  {
    label: "Outpatient",
    value: Enums.CLAIM_TYPE.OUTPATIENT
  }
];

const DATE_FILTER_OPTION = [
  {
    label: "Consultation Date",
    value: Enums.DATE_FILTER.CONSULTATION_DATE
  },
  {
    label: "Processed Date",
    value: Enums.DATE_FILTER.PROCESSED_DATE
  }
];

class ClaimExpensePanel extends Component {
  render() {
    const {
      classes,
      filter: { memberType, claimType, fromDate, toDate, dateFilter },
      isProcessing,
      handleChange,
      handleDateChange,
      handleDateError,
      handleSubmit,
      claimTypeList
    } = this.props;

    const memberTypeOptionList = MEMBER_TYPE_OPTIONS;
    const claimTypeAllList = ["ALL", ...claimTypeList];
    const claimTypeOptionList = CLAIM_TYPE_OPTIONS.filter(({ label, value }) => claimTypeAllList.includes(value));
    const dateFilterList = DATE_FILTER_OPTION;

    return (
      <div className={classes.content}>
        <Typography className={classes.title}>Employee Utilization</Typography>
        <form onSubmit={handleSubmit}>
          <Grid container className={classes.row}>
            <Grid item md={3}>
              <Typography className={classes.label}>Employee / Dependent</Typography>
            </Grid>
            <Grid item md={4}>
              <SelectInput
                name={"memberType"}
                value={memberType}
                onChange={handleChange}
                list={memberTypeOptionList}
                className={classes.dropdown}
              />
            </Grid>
          </Grid>

          <Grid container className={classes.row}>
            <Grid item md={3}>
              <Typography className={classes.label}>Claim Type</Typography>
            </Grid>
            <Grid item md={4}>
              <SelectInput
                name={"claimType"}
                value={claimType}
                onChange={handleChange}
                list={claimTypeOptionList}
                className={classes.dropdown}
              />
            </Grid>
          </Grid>

          <Grid container className={classes.row}>
            <Grid item md={3}>
              <SelectInput
                name={"dateFilter"}
                value={dateFilter}
                onChange={handleChange}
                list={dateFilterList}
                className={classes.dropdowntitle}
              />
            </Grid>
            <Grid item md={9}>
              <span className={classes.subLabel}>From</span>
              <DateInput
                name={"fromDate"}
                value={fromDate}
                onChange={handleDateChange("fromDate")}
                onError={handleDateError("fromDate", "Consultation 'FromDate' is invalid")}
                maxDateMessage="Consultation Date cannot be in the future"
                className={""}
              />
              <span className={classes.subLabel}>To</span>
              <DateInput
                name={"toDate"}
                value={toDate}
                onChange={handleDateChange("toDate")}
                onError={handleDateError("toDate", "Consultation 'ToDate' is invalid")}
                maxDateMessage="Consultation Date cannot be in the future"
                className={""}
              />
              <span className={classes.subLabel}>(DD/MM/YYYY)</span>
            </Grid>
          </Grid>

          <Grid container className={classes.row}>
            <Grid item md={3} />
            <Grid item md={6}>
              <span className={classes.subTip}>*Leave date field(s) as blank for search with no date boundaries</span>
            </Grid>
          </Grid>

          <Grid container className={classes.row}>
            <Grid item md={3} />
            <Grid item md={3}>
              <Button variant="contained" color="primary" type="submit" disabled={isProcessing}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

const styles = theme => ({
  content: {
    border: "1px solid #cccccc",
    padding: "2px 2px 0px 2px",
    width: "100%"
  },
  title: {
    backgroundColor: "#ED1B24",
    color: "white",
    fontSize: 20,
    padding: 10,
    marginBottom: 10
  },
  row: {
    marginBottom: 5,
    paddingLeft: 5
  },
  label: {
    paddingTop: 6,
    fontSize: 16,
    color: "#333333",
    fontWeight: "bold"
  },
  subLabel: {
    paddingLeft: 2,
    paddingRight: 10,
    fontSize: 15,
    color: "#333333",
    fontWeight: "bold"
  },
  subTip: {
    paddingLeft: 2,
    fontSize: 12,
    color: "#333333",
    fontStyle: "italic"
  },
  dropdown: {
    paddingLeft: 3
  },
  dropdowntitle: {
    paddingLeft: 3,
    fontWeight: "bold"
  }
});

export default withStyles(styles, { withTheme: true })(ClaimExpensePanel);
