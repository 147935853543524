import Enums from "../../../../../utils/Enums";

const GPPDBenefits = {
  headers: [
    {
      title: ``,
      mandatory: true,
      attributes: {
        style: {
          width: 10
        }
      }
    },
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "30%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: ``
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong></strong>`
        },
        {
          data: `<strong></strong>`
        },
        {
          data: `<strong>Panel (Cashless)</strong>`,
          attributes: {
            style: {
              width: "60%",
              textAlign: "right"
            }
          }
        },
        {
          data: `<strong>Non Panel <br>
                  (Reimbursement, Max per visit)</strong>`,
          attributes: {
            style: {
              width: "90%",
              textAlign: "center"
            }
          }
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>1</strong>`
        },
        {
          data: `<strong>Consultation</strong>`
        },
        {
          data: `As Charged `,
          attributes: {
            rowspan: 15,
            style: {
              width: "60%",
              textAlign: "right"
            }
          }
        },
        {
          data: `S$ 15.00`,
          attributes: {
            style: {
              width: "60%",
              textAlign: "right"
            }
          }
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>2</strong>`
        },
        {
          data: `<strong>Medicine & Miscellaneous Treatment</strong> <br>
          <i>Analgesics, antibiotics, sterilization and disposables</i>`
        },
        {
          data: `S$ 15.00`,
          attributes: {
            style: {
              width: "60%",
              textAlign: "right"
            }
          }
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>3</strong>`
        },
        {
          data: `<strong>X-ray</strong><br>
                 Intraoral<br>
                 Bitewing<br>
                 Panorex<br>`
        },
        {
          data: `<br>
                 S$ 12.00<br>
                 S$ 12.00<br>
                 S$ 32.00`,
          attributes: {
            style: {
              width: "60%",
              textAlign: "right"
            }
          }
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>4</strong>`
        },
        {
          data: `<strong>Test & Laboratory</strong> <br> <i>Biopsy and examination of tissue</i>`
        },
        {
          data: `S$ 48.00`,
          attributes: {
            style: {
              width: "60%",
              textAlign: "right"
            }
          }
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>5</strong>`
        },
        {
          data: `<strong>Prophylaxis</strong><br>
                 Routine (Scaling & Polishing)<br>
                 Complex (Scaling & Polishing & Fluoride)<br>`
        },
        {
          data: `<br>
                 S$ 40.00<br>
                 S$ 60.00<br>`,
          attributes: {
            style: {
              width: "60%",
              textAlign: "right"
            }
          }
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>6</strong>`
        },
        {
          data: `<strong>Filling (Tooth- Coloured Material or Amalgam) for Posterior Teeth Only</strong><br>
                 One surface<br>
                 Two surfaces<br>
                 Three or more surfaces<br>
                 Reinforced Pin<br>`
        },
        {
          data: `<br>
                 S$ 16.00<br>
                 S$ 24.00<br>
                 S$ 32.00<br>
                 S$ 9.00<br>`,
          attributes: {
            style: {
              width: "60%",
              textAlign: "right"
            }
          }
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>7</strong>`
        },
        {
          data: `<strong>Filling (Tooth- Coloured Material) for Anterior Teeth And Buccal (one surface) fulling of Premolars only</strong><br>
                 One surface<br>
                 Two surfaces<br>
                 Three or more surfaces<br>`
        },
        {
          data: `<br>
                 S$ 30.00<br>
                 S$ 40.00<br>
                 S$ 50.00<br>`,
          attributes: {
            style: {
              width: "60%",
              textAlign: "right"
            }
          }
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>8</strong>`
        },
        {
          data: `<strong>Pulpotomy</strong><br>
                 Pulpotomy<br>
                 Pulp Cap<br>`
        },
        {
          data: `<br>
                 S$ 40.00<br>
                 S$ 20.00<br>`,
          attributes: {
            style: {
              width: "60%",
              textAlign: "right"
            }
          }
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>9</strong>`
        },
        {
          data: `<strong>Root Canal Treatment</strong> <br>
                 Single root canal filling<br>
                 Double root canal filling<br>
                 Three or more canals<br>`,
          info: `<i>(X-ray of the tooth involved with the diagnostic wire or wires in place must accompany
                     claim for payment)</i>`
        },
        {
          data: `<br>
                 S$ 150.00<br>
                 S$ 220.00<br>
                 S$ 350.00<br>`,
          attributes: {
            style: {
              width: "60%",
              textAlign: "right"
            }
          }
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>10</strong>`
        },
        {
          data: `<strong>Extrations</strong><br>
                 Routine<br>`,
          info: `<i>(Simple) - each tooth </i>`
        },
        {
          data: `<br>
                 S$ 30.00<br>`,
          attributes: {
            style: {
              width: "60%",
              textAlign: "right"
            }
          }
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `<strong>11</strong>`
        },
        {
          data: `<strong>Surgival Extractions</strong><br>
                 Erupted tooth or root<br>
                 Soft tissue impaction<br>
                 Part bony impaction<br>
                 Completely bony impaction<br>`
        },
        {
          data: `<br>
                 S$ 120.00<br>
                 S$ 160.00<br>
                 S$ 250.00<br>
                 S$ 320.00<br>`,
          attributes: {
            style: {
              width: "60%",
              textAlign: "right"
            }
          }
        }
      ]
    },
    {
      id: 13,
      columns: [
        {
          data: `<strong>12</strong>`
        },
        {
          data: `<strong>Alveoplasty</strong><br>
                 Per quadrant, in connection with extractions<br>
                 Per quadrant, not in connection with extractions<br>
                 For a complete Alveoplasty involving more than one quadrant<br>`
        },
        {
          data: `<br>
                 S$ 30.00<br>
                 S$ 42.00<br>
                 S$ 160.00<br>`,
          attributes: {
            style: {
              width: "60%",
              textAlign: "right"
            }
          }
        }
      ]
    },
    {
      id: 14,
      columns: [
        {
          data: `<strong>13</strong>`
        },
        {
          data: `<strong>Excision of tumour</strong><br>
                 Excision of tumour<br>`
        },
        {
          data: `<br>
                 S$ 76.00<br>`,
          attributes: {
            style: {
              width: "60%",
              textAlign: "right"
            }
          }
        }
      ]
    },
    {
      id: 15,
      columns: [
        {
          data: `<strong>14</strong>`
        },
        {
          data: `<strong>Repair of Prosthetic Appliance</strong><br>
                 Repair of broken complete or partial denture<br>
                 Repair of denture and replace broken tooth<br>
                 Adding tooth to partial denture to replace extracted tooth<br>
                 Add tooth to partial denture to plus clasp<br>`
        },
        {
          data: `<br>
                 S$ 20.00<br>
                 S$ 40.00<br>
                 S$ 27.00<br>
                 S$ 54.00<br>`,
          attributes: {
            style: {
              width: "60%",
              textAlign: "right"
            }
          }
        }
      ]
    },
    {
      id: 16,
      columns: [
        {
          data: `<strong>15</strong>`
        },
        {
          data: `<strong>Space Maintainers</strong><br>
                 Fixed band type (uni or bilateral)<br>
                 Removal in Acrylic (uni or bilateral)<br>`
        },
        {
          data: `<br>
                 S$ 135.00<br>
                 S$ 67.00<br>`,
          attributes: {
            style: {
              width: "60%",
              textAlign: "right"
            }
          }
        }
      ]
    },
    {
      id: 17,
      columns: [
        {
          data: `<strong>16</strong>`
        },
        {
          data: `<strong>Fracture of jaw</strong><br>
                Simple<br/>Compound`,
          info: `<i>(X-ray of the fracture must accompany claim for payment)</i>`
        },
        {
          data: `N.A`,
          attributes: {
            style: {
              width: "100%",
              textAlign: "center"
            }
          }
        },
        {
          data: `<br>
                 S$ 500.00<br>
                 S$ 600.00<br>`,
          attributes: {
            style: {
              width: "60%",
              textAlign: "right"
            }
          }
        }
      ]
    },
    {
      id: 18,
      columns: [
        {
          data: `<strong>17</strong>`
        },
        {
          data: `<strong>Overall Dental Limit</strong><br>
                 (per policy year)`
        },
        {
          data: `S$ 2,000`,
          attributes: {
            colspan: 2,
            style: {
              width: "100%",
              textAlign: "center"
            }
          }
        }
      ]
    }
  ],
  footnotes: []
};

let GPPDPremium = {
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 74</strong>`
        },
        {
          data: `S$ 391.59`
        }
      ]
    }
  ],
  footnotes: []
};

let Occupational = {
  title: `Occupational`,
  headers: [
    {
      title: `Class`,
      mandatory: true
    },
    {
      title: `Occupations`,
      mandatory: true
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Class 1</strong>`
        },
        {
          data: `Clerical, administrative or other similar non-hazardous occupations`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>Class 2</strong>`
        },
        {
          data: `Occupations where some degree of risk is involved, e.g. supervision of manual workers, totally administrative job in an industrial environment`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>Class 3</strong>`
        },
        {
          data: `Occupations involving regular light to medium manual work but no substantial hazard which may increase the risk of sickness or accident`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>Class 4</strong>`
        },
        {
          data: `<strong>Not covered</strong>`
        }
      ]
    }
  ],
  footnotes: []
};

//   ----------------------------------------------------------------------
const PDIH = {
  id: 9,
  code: "PDIH",
  label: `Group Panel Dental (GPPD)`,
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GPPDBenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium (inclusive of 9% GST)",
      data: [GPPDPremium]
    }
  ]
};

export default PDIH;
