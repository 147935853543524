import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PDFViewerBase64 from "../../../components/pdfViewerBase64";
import assets from "../../../assets";
import Enums from "../../../utils/Enums";
import Util from "../../../utils/Util";
import DocumentService from "../../../services/document.service";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";

class MyDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pdf: undefined,
      pdfContentBase64: "",
      fileName: undefined,
      open: false,
      isPDF: false
    };
  }

  handleClick = async (fileNameOriginal, fileName) => {
    let result = await DocumentService.getDocumentByName(fileNameOriginal);

    let isPDF = result.data.file_mime_type === "application/pdf" ? true : false;

    let pdfContentBase64 = result.data.file_content;
    let file = `data:${result.data.file_mime_type};base64,${result.data.file_content}`;

    this.setState({ pdf: file, pdfContentBase64, fileName: fileName, open: true, isPDF: isPDF });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  renderDownloadable = (index, fileNameOriginal, fileName, classes, doc) => {
    return (
      <TableRow
        key={index}
        className={[classes.tableRowDoc, classes.dlIcon]}
        onClick={() => {
          this.handleClick(fileNameOriginal, fileName);
        }}
      >
        <TableCell className={classes.tableCellDoc}>{doc.desc}</TableCell>
        <TableCell className={classes.dlCell}>
          <p className={classes.dlHolder}>
            <Icon
              onClick={() => {
                this.handleClick(fileNameOriginal, fileName);
              }}
              className={classes.dlIcon}
            >
              file_download
            </Icon>
          </p>
        </TableCell>
      </TableRow>
    );
  };

  renderDoc = (doc, prodCode, appType, selectedPlans, classes) => {
    return doc.map((doc, index) => {
      const { fileName, fileNameOriginal, type, code } = doc;

      let exist = false;
      if (!Util.isEmpty(code && code[prodCode])) {
        let codeList = code[prodCode];
        codeList.forEach(c => {
          if (selectedPlans.includes(c)) {
            exist = true;
          }
        });
      }

      if (
        (appType === Enums.APP_TYPE.SALES && type === Enums.P_CODE.PRU_AGENCY) ||
        (appType === Enums.APP_TYPE.HR && (exist || (code && code[prodCode] && code[prodCode].includes("HDF"))))
      ) {
        return (
          <TableRow
            key={index}
            className={[classes.tableRowDoc, classes.dlIcon]}
            onClick={() => {
              this.handleClick(fileNameOriginal, fileName);
            }}
          >
            <TableCell className={classes.tableCellDoc}>{doc.desc}</TableCell>
            <TableCell className={classes.dlCell}>
              <p className={classes.dlHolder}>
                <Icon
                  onClick={() => {
                    this.handleClick(fileNameOriginal, fileName);
                  }}
                  className={classes.dlIcon}
                >
                  file_download
                </Icon>
              </p>
            </TableCell>
          </TableRow>
        );
      }
    });
  };

  renderUserGuid = (userGuide, prodCode, appType, selectedPlans, brand, classes) => {
    return userGuide.map((doc, index) => {
      const { fileName, fileNameOriginal, type, code, version } = doc;

      let exist = false;
      if (!Util.isEmpty(code && code[prodCode])) {
        let codeList = code[prodCode];
        codeList.forEach(c => {
          if (selectedPlans.includes(c)) {
            exist = true;
          }
        });
      }

      if (version) {
        if (this.props.product_engine.version == version) {
          console.log(version);
        }
      }

      if (
        (appType === Enums.APP_TYPE.SALES && type.includes(brand)) ||
        (appType === Enums.APP_TYPE.HR && (exist || (code && code[prodCode] && code[prodCode].includes("HDF"))))
      ) {
        if (version) {
          if (this.props.product_engine.version == version) {
            return this.renderDownloadable(index, fileNameOriginal, fileName, classes, doc);
          }
        } else {
          return this.renderDownloadable(index, fileNameOriginal, fileName, classes, doc);
        }
      }
    });
  };

  render() {
    const { classes, coverage, appType, pcode } = this.props;

    let prodCode = coverage.policy && coverage.policy.product_code;

    let categories = coverage.policy && coverage.policy.data && coverage.policy.data.categories;
    let selectedPlans = [];
    categories &&
      categories.forEach(c => {
        if (c.plansConfiguration) {
          c.plansConfiguration.forEach(p => {
            if (p.tier && !selectedPlans.includes(p.code)) selectedPlans.push(p.code);
          });
        }
      });

    return (
      <>
        <div className={classes.breadcrumb}>
          <CustomBreadcrumb currentPage="PolicyRenewal" />
        </div>

        <div className={classes.root}>
          <main>
            <div className={classes.content}>
              <Typography className={classes.heading} variant="title" color="inherit">
                Documents
              </Typography>
              <br />
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHead}>Filename</TableCell>
                    <TableCell className={classes.tableHead} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.renderDoc(assets.doc, prodCode, appType, selectedPlans, classes)}
                  {this.renderUserGuid(assets.userGuide, prodCode, appType, selectedPlans, pcode, classes)}
                </TableBody>
              </Table>
            </div>
            <PDFViewerBase64
              open={this.state.open}
              handleClose={this.handleClose}
              pdf={this.state.pdf}
              pdfContentBase64={this.state.pdfContentBase64}
              isPDF={this.state.isPDF}
              fileName={this.state.fileName}
            />
          </main>
        </div>
      </>
    );
  }
}

const styles = theme => ({
  root: {},
  maxWidth: {
    width: "100%"
  },
  maxHeight: {
    height: "100%"
  },
  heading: {
    paddingTop: "30px",
    fontSize: "28px",
    color: "#333333"
  },
  content: {
    padding: "10px 40px"
  },
  tableHead: {
    backgroundColor: "#333",
    fontSize: "14px",
    padding: "20px",
    color: theme.palette.common.white
  },
  tableRowDoc: {
    color: "#818181",
    backgroundColor: "white",
    height: "30px"
  },
  tableCellDoc: {
    padding: 0,
    paddingLeft: 25
  },
  clearCellStyle: {
    padding: 0,
    paddingRight: "0!important",
    border: 0
  },
  pdfViewModal: {
    overflowY: "scroll"
  },
  dlHolder: {
    textAlign: "right"
  },
  dlIcon: {
    "&:hover": {
      color: "#919191",
      cursor: "pointer"
    },
    color: "#b1b1b1"
  },
  dlCell: {
    padding: 0
  },
  pdfViewer: {
    height: "80%"
  },
  pdfViewHolder: {
    position: "relative",
    paddingTop: "50px",
    paddingBottom: "50px"
  },
  pdfActionsHolder: {
    position: "fixed",
    bottom: "20px"
  },
  prevAction: {
    float: "right",
    opacity: 0.3,
    "&:hover": {
      opacity: 1
    }
  },
  nextAction: {
    float: "left",
    opacity: 0.3,
    "&:hover": {
      opacity: 1
    }
  },
  dlAction: {
    textDecoration: "none",
    position: "fixed",
    top: "20px",
    right: "20px",
    [theme.breakpoints.down("sm")]: {
      opacity: 0.3,
      "&:hover": {
        opacity: 1
      }
    }
  },
  pdfZoomHolder: {
    position: "fixed",
    bottom: "20px",
    right: 0,
    maxWidth: "100px"
  },
  zoomIn: {
    float: "right",
    marginRight: "20px"
  },
  zoomOut: {
    float: "right",
    marginRight: "20px"
  },
  breadcrumb: {
    margin: "10px 10px",
    paddingBottom: "20px"
  }
});

function mapStateToProps(state) {
  return {
    coverage: state.HR.coverage,
    appType: state.app.type,
    pcode: state.user.auth.pcode,
    product_engine: state.products.engine
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(MyDocuments));
