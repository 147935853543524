import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import Popover from "@mui/material/Popover";
import { findDOMNode } from "react-dom";
import Typography from "@mui/material/Typography";

class InfoPopover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      element: this.getDomNode(this.props.element)
    };
  }

  componentWillReceiveProps(nextProps) {
    //some case it throw exception when element is unmounted ( dialog)
    let element = this.getDomNode(nextProps.element);
    this.setState({ element });
  }

  getDomNode(el) {
    //some case it throw exception when element is unmounted ( dialog)
    let element = null;
    try {
      element = findDOMNode(el);
    } catch (e) {}
    return element;
  }

  render() {
    const { classes, open, data, handleClickInfoButton, anchor } = this.props;
    const { element } = this.state;

    let anchorOrigin = {
      vertical: "center",
      horizontal: "right"
    };
    let transformOrigin = {
      vertical: "center",
      horizontal: "left"
    };
    if (anchor === "top") {
      anchorOrigin = {
        vertical: "top",
        horizontal: "center"
      };

      transformOrigin = {
        vertical: "bottom",
        horizontal: "center"
      };
    }

    return (
      <Popover
        className={classes.container}
        open={open}
        onClose={handleClickInfoButton()}
        anchorEl={element}
        anchorReference="anchorEl"
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={{ style: { maxWidth: "20%" } }}
      >
        <Typography className={classes.content}>
          <span
            dangerouslySetInnerHTML={{
              __html: data
            }}
          />
        </Typography>
      </Popover>
    );
  }
}

const styles = theme => ({
  root: {},
  container: {
    // maxWidth: "60%"
  },
  content: {
    // maxWidth: "60%",
    padding: 10,
    fontSize: ".75em"
  }
});

export default withStyles(styles, { withTheme: true })(InfoPopover);
