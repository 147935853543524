import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function IconClaimExpenses(props) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#a)">
        <path d="M11 15h2v2h-2v-2Zm0-8h2v6h-2V7Zm8-4h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04a2.008 2.008 0 0 0-1.44 1.19c-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2Zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75ZM19 19H5V5h14v14Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M0 0h24v24H0V0" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
