import React, { Component } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withStyles } from "@mui/styles";
import { injectIntl } from "react-intl";
import CustomTablePagination from "./CustomTablePagination";

class TableCompanyHR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10
    };
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  render() {
    const { page, rowsPerPage } = this.state;
    const { classes, goToPage, clients } = this.props;
    return (
      <div className={classes.container}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" className={classes.tableHeaderCell}>
                  Policy Number
                </TableCell>
                <TableCell align="left" className={classes.tableHeaderCell}>
                  Company Name
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clients &&
                clients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                  const isDisabled =
                    this.props.location.pathname === "/auth/insuranceCoverage" &&
                    row.product_code !== "PF3" &&
                    row.product_code !== "PTF" &&
                    row.product_code !== "PEP";
                  const cellStyled = isDisabled ? { color: "gray", cursor: "not-allowed" } : {};
                  return (
                    <TableRow
                      key={row.policy_no}
                      onClick={() =>
                        !isDisabled &&
                        goToPage(row.company_name, row.policy_no, row.company_id, row.product_code, row.product_version)
                      }
                      sx={{
                        cursor: isDisabled ? "not-allowed" : "pointer"
                      }}
                    >
                      <TableCell align="left" className={classes.tableCellContent} style={cellStyled}>
                        {row.policy_no}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCellContent} style={cellStyled}>
                        {row.company_name}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {clients && (
            <CustomTablePagination
              count={clients.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={this.handleChangePage}
            />
          )}
        </TableContainer>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    marginTop: "20px"
  },
  tableHeaderCell: {
    backgroundColor: "black",
    color: "white !important"
  },
  tableCellContent: {
    fontWeight: "bold"
  },
  tableRow: {
    cursor: "pointer"
  }
});

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles, { withTheme: true })(TableCompanyHR)))
);
