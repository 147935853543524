import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function IconDocument(props) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#a)">
        <path d="M8 16h8v2H8v-2Zm0-4h8v2H8v-2Zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6Zm4 18H6V4h7v5h5v11Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M0 0h24v24H0V0" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
