import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Banner from "./components/banner";
import Offer from "./components/offer";
import assets from "../../../assets";
import Util from "../../../utils/Util";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";

const ProductBenefits = props => {
  const { classes, product, productInfo, productCategory, singleCategory, handleChangeCategory } = props;

  return (
    <Fragment>
      <div className={classes.beyondHolder}>
        <p className={classes.beyondHeading}>A New World of Services</p>
        <p className={classes.beyondSubHeading}>
          PRUworks opens the door to an extensive and tailored mix of products and services.
        </p>
      </div>
      <Grid className={classes.navBar} container justify="center" spacing={"40px"}>
        {productInfo.productCategories.map((category, index) => {
          const active = category === productCategory && !singleCategory;
          const { menuTitle } = productInfo.categoryData[category];
          return (
            <Grid
              key={"product" + index}
              onClick={() => {
                props.handleChangeCategory(index);
              }}
              className={active ? classes.navBarContentActive + " " + classes.navBarContent : classes.navBarContent}
              item
              sm={6}
              md={3}
            >
              <p>{menuTitle}</p>
            </Grid>
          );
        })}
      </Grid>
      <Offer productCategory={productCategory} productInfo={productInfo} />
    </Fragment>
  );
};

class ProductInfo extends Component {
  constructor(props) {
    super(props);

    const productInfo = assets.productInfo.getProductInfo(props.agentType || "AD");

    this.state = {
      productCategory: productInfo.productCategories[0],
      productInfo
    };
  }

  handleChangeCategory = productCategoryIndex => {
    this.setState({
      productCategory: this.state.productInfo.productCategories[productCategoryIndex]
    });
  };

  render() {
    const { classes, product } = this.props;
    const { productInfo, productCategory } = this.state;

    const showProductBenefits = !Util.isEmpty(productInfo.productCategories);

    const singleCategory = productInfo.productCategories.length < 2;
    const { introImages } = assets;
    return (
      <div>
        <div className={classes.breadcrumb}>
          <CustomBreadcrumb currentPage="Information" />
        </div>

        <div className={classes.root}>
          <Banner />

          <div className={classes.chooseUsHolder} style={{ background: "url(" + introImages.csBackground + ")" }}>
            <Link className={classes.linkUnstyle} to={"/auth/sp/currentQuote"}>
              <Button variant="contained" color="primary" className={classes.button}>
                Back to Quote
              </Button>
            </Link>
            <br />
            <i className={classes.qnIcon + " material-icons"}>keyboard_arrow_down</i>
            <p className={classes.chooseUsHeading}>Why Choose Us</p>
            <p className={classes.chooseUsSubHeading}>
              PRUworks lets you choose your group protection plan based on your organisation&#39;s unique needs by
              selecting the amount of coverage, medical protection and benefits.
            </p>
            <div className={classes.chooseUsInfoHolder}>
              <Grid container spacing={"16px"}>
                <Grid className={classes.infoHolder} item sm={6}>
                  <Grid container spacing={"0px"}>
                    <Grid item xs={2}>
                      <img className={classes.img} alt="Small Group Size icon" src={introImages.demoIcon} />
                    </Grid>
                    <Grid item xs={10} className={classes.infoContent}>
                      <p className={classes.infoContentHeading}>Small Group Size</p>
                      <p className={classes.infoContentSubHeading}>Coverage for SMEs with as little as 3 employees.</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={classes.infoHolder} item sm={6}>
                  <Grid container spacing={"0px"}>
                    <Grid item xs={2}>
                      <img className={classes.img} alt="Flexibility icon" src={introImages.listIcon} />
                    </Grid>
                    <Grid item xs={10} className={classes.infoContent}>
                      <p className={classes.infoContentHeading}>Flexibility</p>
                      <p className={classes.infoContentSubHeading}>Choose a plan according to your budget and needs.</p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={"16px"}>
                <Grid className={classes.infoHolder} item sm={6}>
                  <Grid container spacing={"0px"}>
                    <Grid item xs={2}>
                      <img className={classes.img} alt="Guaranteed Coverage icon" src={introImages.gcIcon} />
                    </Grid>
                    <Grid item xs={10} className={classes.infoContent}>
                      <p className={classes.infoContentHeading}>Guaranteed Coverage</p>
                      <p className={classes.infoContentSubHeading}>
                        Eligible employees enjoy coverage up to $200,000 for Group Term Life and up to $100,000 for
                        Group accident and dismemberment.
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={classes.infoHolder} item sm={6}>
                  <Grid container spacing={"0px"}>
                    <Grid item xs={2}>
                      <img className={classes.img} alt="Comprehensive Solutions icon" src={introImages.csIcon} />
                    </Grid>
                    <Grid item xs={10} className={classes.infoContent}>
                      <p className={classes.infoContentHeading}>Comprehensive Solutions</p>
                      <p className={classes.infoContentSubHeading}>
                        We offer Life, Medical and Surgical coverage with a suite of supplementary plans.
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={"16px"}>
                <Grid className={classes.infoHolder} item sm={6}>
                  <Grid container spacing={"0px"}>
                    <Grid item xs={2}>
                      <img className={classes.img} alt="Extended Coverage icon" src={introImages.ecIcon} />
                    </Grid>
                    <Grid item xs={10} className={classes.infoContent}>
                      <p className={classes.infoContentHeading}>Extended Coverage</p>
                      <p className={classes.infoContentSubHeading}>
                        Dependant’s coverage for medical products and coverage for employees residing out of Singapore.
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={classes.infoHolder} item sm={6}>
                  <Grid container spacing={"0px"}>
                    <Grid item xs={2}>
                      <img
                        className={classes.img}
                        alt="Complimentary Health Screening icon"
                        src={introImages.cshIcon}
                      />
                    </Grid>
                    <Grid item xs={10} className={classes.infoContent}>
                      <p className={classes.infoContentHeading}>Complimentary Health Screening</p>
                      <p className={classes.infoContentSubHeading}>
                        Receive complimentary health screening when signing up more than 10 employees.
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={"16px"}>
                {/**<Grid className={classes.infoHolder} item sm={6}>
                <Grid container spacing={"0px"}>
                  <Grid item xs={2}>
                    <img
                      className={classes.img}
                      alt="Group Size Discount icon"
                      src={introImages.gsdIcon}
                    />
                  </Grid>
                  <Grid item xs={10} className={classes.infoContent}>
                    <p className={classes.infoContentHeading}>
                      Group Size Discount
                    </p>
                    <p className={classes.infoContentSubHeading}>
                      Receive discounts on services when signing up 10 employees
                      or more.
                    </p>
                  </Grid>
                </Grid>
              </Grid>**/}

                {/* Product Benefits tile */}
                {showProductBenefits && (
                  <Grid className={classes.infoHolder} item sm={6}>
                    <Grid container spacing={"0px"}>
                      <Grid className={classes.img} item xs={2}>
                        <img className={classes.img} alt="Value Added Services icon" src={introImages.vasIcon} />
                      </Grid>
                      <Grid item xs={10} className={classes.infoContent}>
                        <p className={classes.infoContentHeading}>Value Added Services</p>
                        <p className={classes.infoContentSubHeading}>
                          PruWorks also provides access to value added services for your business and lifestyle needs.
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </div>
            <Link className={classes.linkUnstyle} to={"/auth/sp/currentQuote"}>
              <Button variant="contained" color="primary" className={classes.button}>
                Back to Quote
              </Button>
            </Link>
          </div>

          {/* Product Benefits */}
          {showProductBenefits && (
            <ProductBenefits
              classes={classes}
              productInfo={productInfo}
              product={product}
              productCategory={productCategory}
              singleCategory={singleCategory}
              handleChangeCategory={this.handleChangeCategory}
            />
          )}
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    backgroundColor: "white",
    color: "#262626",
    overflowX: "hidden"
  },
  linkUnstyle: {
    textDecoration: "none"
  },
  button: {
    boxShadow: "none",
    backgroundColor: `${theme.colors.primary.red}`,
    fontSize: 12,
    height: 44,
    paddingLeft: "90px",
    paddingRight: "90px"
  },
  chooseUsHolder: {
    textAlign: "center",
    padding: "50px 0px",
    backgroundSize: "cover!important",
    backgroundPosition: "center bottom!important",
    backgroundRepeat: "no-repeat!important"
  },
  img: {
    width: "100%"
  },
  qnIcon: {
    fontSize: "60px",
    color: `${theme.colors.primary.red}`,
    padding: "50px 0px"
  },
  chooseUsHeading: {
    fontSize: "35px",
    marginTop: 0
  },
  chooseUsSubHeading: {
    [theme.breakpoints.up("md")]: {
      margin: "0px 300px 50px 300px"
    },
    [theme.breakpoints.down("md")]: {
      margin: "0px 50px 50px 50px"
    },
    fontSize: "20px"
  },
  chooseUsInfoHolder: {
    [theme.breakpoints.up("md")]: {
      padding: "50px 130px"
    },
    [theme.breakpoints.down("md")]: {
      padding: "50px 50px"
    }
  },
  infoHolder: {
    textAlign: "left",
    minHeight: 200
  },
  infoContent: {
    float: "left",
    paddingLeft: "30px"
  },
  infoContentHeading: {
    fontSize: "24px",
    marginBottom: "10px"
  },
  infoContentSubHeading: {
    fontSize: "16px",
    marginTop: 0
  },
  beyondHolder: {
    [theme.breakpoints.up("md")]: {
      padding: "70px 300px"
    },
    [theme.breakpoints.down("md")]: {
      padding: "70px 50px"
    },
    textAlign: "center"
  },
  beyondHeading: {
    fontSize: "35px"
  },
  beyondSubHeading: {
    fontSize: "16px"
  },
  navBar: {
    border: "1px solid #e7e7e7",
    boxShadow: "0 4px 2px -2px #e7e7e7",
    marginBottom: "5px",
    width: "calc(100% + 20px)"
  },
  navBarContent: {
    fontSize: "22px",
    textAlign: "center",
    cursor: "pointer"
  },
  navBarContentActive: {
    borderBottom: "4px solid " + `${theme.colors.primary.red}`
  },
  breadcrumb: {
    margin: "10px 10px",
    paddingBottom: "20px"
  }
});

function mapStateToProps(state) {
  let product = state.products[state.quote.product_code];
  return {
    agentType: state.user.auth.pcode,
    product: product && product.config
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(ProductInfo));
