import Enums from "../../../../utils/Enums";

// TODO: To be updated with correct data after confirmation with user
let doc = [
  {
    desc: "Health Declaration Form",
    fileNameOriginal: "Form - Group Health Declaration (Jun 2019) PTRFII.pdf",
    fileName: "Health Declaration Form.pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    code: { PTF: [Enums.PLANS_CODE.HDF], PF3: [Enums.PLANS_CODE.HDF] }
  },
  {
    desc: "Group Hospital & Surgical Claim Form",
    fileNameOriginal: "Form - Group GHS Claim. V03.2018.pdf",
    fileName: "Form - Group GHS Claim. V03.2018.pdf",
    type: Enums.P_CODE.PRU_AGENCY,
    code: { PTF: [Enums.PLANS_CODE.GHSB], PF3: [Enums.PLANS_CODE.HDF] }
  },
  {
    desc: "IHP Mobile Guide (If applicable)",
    fileNameOriginal: "PRU - Mobile Application Guide 2019 revised.pdf",
    fileName: "PRU - Mobile Application Guide 2019 revised.pdf",
    type: Enums.P_CODE.PRU_AGENCY
  },
  {
    desc: "HREasily Benefits Brochure",
    fileNameOriginal: "PruWorks_HREasily_Brochure.pdf",
    fileName: "PruWorks_HREasily_Brochure.pdf"
  },
  {
    desc: "PRUworks HR User Guide",
    fileNameOriginal: "Pruworks-HR-PCE-User-Guide.pdf",
    fileName: "PRUworks HR User Guide.pdf",
    type: Enums.P_CODE.PRU_CE
  }
];

export default doc;
