import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import { Box } from "@mui/material";
import classNames from "classnames";
import { CloseRounded } from "@mui/icons-material";

class SimpleControlDialog extends Component {
  render() {
    const {
      closeButtonText,
      closeHandler,
      description,
      okButtonText,
      onClose,
      onSubmit,
      show,
      valid,
      title,
      classes,
      showCloseButton = false,
      closeButtonDisabled = false
    } = this.props;

    return (
      <Dialog open={show} onClose={onClose} aria-labelledby="control-dialog-title" disableBackdropClick>
        <form onSubmit={onSubmit}>
          {showCloseButton && (
            <>
              <Box display="flex" flexDirection="row-reverse">
                <Button
                  style={{
                    backgroundColor: "#B8B8B8",
                    color: "white",
                    borderRadius: "50%",
                    minWidth: "5px", // Adjusts the width and height for roundness
                    height: "25px",
                    width: "25px",
                    marginTop: "0.50rem",
                    marginRight: "0.50rem"
                  }}
                  onClick={onClose}
                >
                  <CloseRounded fontSize="small" />
                </Button>
              </Box>
              <DialogTitle id="control-dialog-title" style={{ paddingTop: "1px" }}>
                {title}
              </DialogTitle>
            </>
          )}
          {!showCloseButton && <DialogTitle id="control-dialog-title}">{title}</DialogTitle>}
          <DialogContent classes={{ root: classes.dialogContent }}>
            <DialogContentText>{description}</DialogContentText>
            {this.props.children}
          </DialogContent>
          <DialogActions classes={{ root: classes.dialogActions }}>
            <Button
              onClick={closeHandler}
              variant="raised"
              disabled={closeButtonDisabled}
              className={classNames(classes.cancelButton)}
            >
              {closeButtonText}
            </Button>
            <Button
              type="submit"
              disabled={!valid}
              variant="contained"
              color="primary"
              className={classNames(classes.button, classes.okButton)}
            >
              {okButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

const styles = theme => ({
  dialogContent: {
    minWidth: 320,
    overflow: "hidden"
  },
  dialogActions: {
    paddingBottom: 15,
    paddingRight: 15
  },
  cancelButton: {
    backgroundColor: theme.colors.secondary.white,
    border: `2px solid ${theme.colors.blackScale.black50}`,
    color: theme.colors.blackScale.black100,
    fontWeight: 500
  },
  button: {
    minWidth: 150
  }
});

export default withStyles(styles, { withTheme: true })(SimpleControlDialog);
