import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";

import { getCategories } from "../../../../../../selectors/CommonSelectors";
import LoadingPopup from "../../../../../../components/LoadingPopup";
import SnackBar from "../../../../../../components/SnackBar";

import Enums from "../../../../../../utils/Enums";
import { employeeActions, quoteActions } from "../../../../../../actions";
import Util from "../../../../../../utils/Util";

import EmployeeEditDialog from "../../components/EmployeeDialogForm/EmployeeEditDialog";

class EmployeeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0, // first tab : Employee Detail
      showLoadingPopup: false
    };
  }

  getDependentRecords = employeeData => {
    const { quote } = this.props;
    let dependentRecords = [];
    if (employeeData && employeeData.type === Enums.PERSON_TYPE.EMPLOYEE) {
      const persons = Util.clone(quote.data.persons);
      dependentRecords = persons.filter((person, idx) => {
        return person.dependency_id_no === employeeData.id_no && person.type !== Enums.PERSON_TYPE.EMPLOYEE;
      });

      //Set category from selected employee
      dependentRecords.forEach((record, idx) => {
        record.category = employeeData.category;
      });
    }
    return dependentRecords;
  };

  handleSubmitCompleted = (mode, employee, dependents) => {
    const {
      addEmployee,
      saveEmployee,
      setEmployeeDependents,
      selectedRecord,
      handleClose,
      successHandler
    } = this.props;

    //let delete this. cause out of memory
    delete employee["infoPopover"];
    if (mode === "ADD") {
      addEmployee({
        record: employee
      });
    } else if (mode === "EDIT") {
      saveEmployee({
        index: this.props.selectedIndex,
        record: employee
      });
    }

    if (Util.isEmpty(employee.id_no)) {
      console.error("Uable to save dependants as employee id is empty.");
    } else {
      let id_no = employee.id_no;
      let previous_id_no = selectedRecord && selectedRecord.id_no;
      setEmployeeDependents({ id_no, previous_id_no, records: dependents });
    }
    successHandler();
    handleClose();
  };

  render() {
    const { mode, open, readOnly, handleClose, selectedIndex, selectedRecord, document, classes } = this.props;

    return (
      <div>
        <EmployeeEditDialog
          mode={mode}
          open={open}
          handleClose={handleClose}
          selectedIndex={selectedIndex}
          selectedRecord={selectedRecord}
          getDependentRecords={this.getDependentRecords.bind(this)}
          handleSubmitCompleted={this.handleSubmitCompleted.bind(this)}
          categories={getCategories(this.props.quote)}
          readOnly={readOnly || document.hasSignedProposal}
        />
        <LoadingPopup
          title="popup.loading.submitEmployee.title"
          name="send-employee-loading"
          open={this.state.showLoadingPopup}
        />
        <SnackBar
          type="error"
          open={this.state.snackBarSubmissionFail}
          onClose={() => this.setState({ snackBarSubmissionFail: false })}
          message={
            <div>
              <span>Failed to submit changes, please try again later.</span>&nbsp;&nbsp;&nbsp;
              <a className={classes.link} onClick={this.closeDialog} href="">
                Return to Employee list
              </a>
            </div>
          }
        />
      </div>
    );
  }
}

const styles = theme => ({});

function mapStateToProps(state, prop) {
  return {
    quote: state.quote,
    document: state.document
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(employeeActions, dispatch),
    ...bindActionCreators(quoteActions, dispatch)
  };
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(EmployeeDialog))
);
