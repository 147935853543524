export default [
  {
    value: "GTLB",
    label: "GTL"
  },
  {
    value: "GHSB",
    label: "GHS"
  },
  {
    value: "GCCA",
    label: "GCCA"
  },
  {
    value: "GEMM",
    label: "GEMM"
  },
  {
    value: "GPRU",
    label: "GPRU"
  },
  {
    value: "GOSP",
    label: "GOSP"
  },
  {
    value: "GADD",
    label: "GADD"
  },
  {
    value: "GAMR",
    label: "GAMR"
  },
  {
    value: "GDEN",
    label: "GDEN"
  },
  {
    value: "GSMM",
    label: "GSMM"
  },
  {
    value: "PSMH",
    label: "PSMH"
  }
];
