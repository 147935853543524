import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { companyActions, quoteActions } from "../../../../../../actions";
import classNames from "classnames";
import { bindActionCreators } from "redux";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import SendContainer from "./SendContainer";
import CompanyDetailForm from "./CompanyDetailForm";
import CustomStep from "../../../../../../components/CustomStep";
import * as _ from "lodash";
import ENUMS from "../../../../../../utils/Enums";
import Util from "../../../../../../utils/Util";
import NoticeGST from "../../../../../../components/NoticeGST";
import DeclarationOfAmlClauseForm from "./DeclarationOfAmlClauseForm";

const disabledButtonsInStages = [ENUMS.STAGES.SUBMITTED, ENUMS.STAGES.INFORCE];

class CompanyDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadData: true,
      amlClauseRadio: false
    };
  }

  changeAMlClauseToggle = () => {
    this.setState({ amlClauseRadio: true });
    // this.props.amlClauseActions.setAmlClauseAction(true);
  };

  componentWillReceiveProps(nextProps) {
    if (this.state.loadData && this.props.quote.id != null) {
      this.setState({ loadData: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.quote.product_id != this.props.quote.product_id) {
      this.props.quoteActions.computeActualPremium();
    }
  }

  /**
   * Check if company details were filled
   * @return true if company details weren't filled
   * @return false if company details were filled
   */
  hasFilledCompanyDetails = () => {
    let result = false;
    let fieldsDontNeedToCheck = [
      "id",
      "notes",
      "uen_no",
      "encrypted_password",
      "referral_id_1",
      "referral_id_2",
      "address2",
      "address3",
      "address4",
      "address5"
    ];

    if (this.props.agentType === "SCB_ASSISTED") {
      fieldsDontNeedToCheck = ["id", "notes", "uen_no", "encrypted_password", "agent_email"];
    }

    for (var field in this.props.company.details) {
      /**
       * There are some fields we don't need to check
       */
      if (fieldsDontNeedToCheck.includes(field)) {
        continue;
      } else {
        if (Util.isEmpty(this.props.company.details[field]) || this.props.company.details[field].trim().length <= 0) {
          result = true;
          break;
        }
      }
    }

    return result;
  };

  hadFilledAMLClauseDetials() {
    let empty = false;
    if (this.props.quote?.data?.hasOwnProperty("specificIndividuals")) {
      if (this.props.quote?.data?.specificIndividuals.length > 0) {
        this.props.quote?.data?.specificIndividuals.forEach(element => {
          for (var field in element) {
            if (Util.isEmpty(element[field])) {
              empty = true;
              break;
            }
          }
        });
      } else {
        empty = true;
      }
    }

    return empty;
  }

  render() {
    const { classes, intl, quote, form, document } = this.props;
    let validatePersonNames;
    let readOnly = quote && !quote.readOnly && document.hasSignedProposal ? true : quote.readOnly;
    let companyDetailForm = form["company-detail"];
    let disabledButton = this.hasFilledCompanyDetails();
    if (this.props.quote.data.amlClauseAction === null || this.props.quote.data.amlClauseAction === undefined) {
      disabledButton = true;
    }

    if (disabledButton === false && this.props.quote.data.amlClauseAction === true) {
      disabledButton = this.hadFilledAMLClauseDetials();
    }

    //edit for doc pack validations

    if (quote && quote.data && quote.persons) {
      validatePersonNames = this.props.quote.data.persons.some(person => person.full_name.length > 120);
    }

    let disabled =
      companyDetailForm &&
      (disabledButton ||
        _.includes(disabledButtonsInStages, quote.stages) ||
        Util.isEmpty(companyDetailForm.values.encrypted_password) ||
        (!Util.isEmpty(companyDetailForm.syncErrors) &&
          !Util.isEmpty(companyDetailForm.syncErrors.encrypted_password)));
    let disabledSendDocPack =
      disabled ||
      Util.foreignWorkerValidation(this.props).isInvalid ||
      validatePersonNames ||
      quote.stageValidation[ENUMS.STAGES.EMPLOYEE] === false ||
      quote.stageValidation[ENUMS.STAGES.ESTIMATE] === false ||
      Util.isEmpty(quote.req_effective_date) ||
      readOnly ||
      (this.props.form &&
        this.props.form["company-detail"] &&
        this.props.form["company-detail"].syncErrors !== undefined) ||
      !Util.isPlanValid;

    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item md={12}>
            <Typography variant="h5" gutterBottom className={classes.header}>
              {intl.formatMessage({ id: "company.details.heading" })}
            </Typography>
          </Grid>
          <Grid item sm={1}>
            <CustomStep label="1" disabled={readOnly} />
          </Grid>
          <Grid item sm={11}>
            <Paper style={{ padding: "10px" }}>
              <Typography variant="h5" gutterBottom className={classNames(classes.container, classes.heading)}>
                {intl.formatMessage({ id: "company.details.subheading" })}
              </Typography>
              <div className={classes.content}>
                <CompanyDetailForm handleEdits={this.props.handleHasEdits} />
              </div>
            </Paper>
          </Grid>
          <Grid item sm={1}>
            <CustomStep label="2" disabled={readOnly} />
          </Grid>
          <Grid item sm={11}>
            <Paper style={{ padding: "10px", marginTop: "10px" }}>
              <Typography variant="h5" gutterBottom className={classNames(classes.container, classes.heading)}>
                {intl.formatMessage({ id: "company.aml.subheading" })}
              </Typography>
              <div className={classes.content}>
                <DeclarationOfAmlClauseForm
                  handleEdits={this.props.handleHasEdits}
                  changeAMlClauseToggle={this.changeAMlClauseToggle}
                />
              </div>
            </Paper>
          </Grid>
          <Grid item sm={1}>
            <CustomStep label="3" last disabled={disabledSendDocPack} />
          </Grid>
          <Grid item sm={11}>
            <Paper style={{ padding: "10px", marginTop: "10px" }}>
              <Typography variant="h5" gutterBottom className={classNames(classes.container, classes.heading)}>
                {intl.formatMessage({ id: "company.docpack.subheading" })}
                {/* <NoticeGST/> */}
              </Typography>
              <div>
                For all policies with policy start date or renewal date before 1 Jan 2024, the new GST rate of 9% will
                apply for premium payments made on or after 1 Jan 2024. For all policies with policy start date or
                renewal date on or after 1 Jan 2024, the new GST rate of 9% will apply regardless of payment date. This
                is with exception for New Business packaged policies, for which the old GST rate of 8% will continue to
                apply for premium payments made before 1 Jan 2024, regardless of whether the policy start date is
                before, on or after 1 Jan 2024.
              </div>
              <div className={classes.content}>
                <SendContainer disabled={disabledSendDocPack} handleResetEdits={this.props.handleResetEdits} />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    paddingRight: 30,
    paddingTop: 20
  },
  container: {
    padding: `20px ${theme.spacing.unit * 3}px`
  },
  header: {
    paddingLeft: 20
  },
  content: {
    padding: theme.spacing.unit * 3,
    paddingTop: "5px"
  },
  heading: {
    borderBottom: `1px solid ${theme.colors.blackScale.black70}`
  }
});

function mapStateToProps(state, prop) {
  return {
    form: state.form,
    company: state.company,
    quote: state.quote,
    agentType: state.user.auth.pcode,
    document: state.document
  };
}

function mapDispatchToProps(dispatch) {
  return {
    companyActions: bindActionCreators(companyActions, dispatch),
    quoteActions: bindActionCreators(quoteActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(CompanyDetail)));
