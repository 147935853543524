import { APIResource, APIError, APIResponse } from "../utils/APIResource";

export default class EmployeeService {
  static upload(quoteId, file) {
    const formData = new FormData();
    formData.append("multipartFile", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };
    return APIResource.post(`/v1/quote/${quoteId}/employee/upload`, formData, config)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }
}
