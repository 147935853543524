import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import { withStyles } from "@mui/styles";

import Enums from "../../../../utils/Enums";
import Plans from "../../../../containers/Auth/components/Plans";
import CategoryPlansTable from "./CategoryPlansTable";
import InfoPopover from "../../../../components/InfoPopover";
import { withRouter } from "react-router";
import moment from "moment";

class Coverage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      plans: {
        selectedTypes: [Enums.PLANS_TYPE.BENEFITS]
      },
      categories: null,
      selectedTiers: null,
      infoPopover: {
        open: false,
        element: null,
        data: ""
      }
    };
  }

  componentWillMount() {
    const { coverage } = this.props;
    let categories = coverage.policy.data && coverage.policy.data.categories;
    let selectedTiers = this.getSelectedTiers(categories);
    this.setState({ categories: categories, selectedTiers: selectedTiers });
  }

  getSelectedTiers = categories => {
    let selectedTiers = {};
    if (categories) {
      categories.forEach(c => {
        if (c.plansConfiguration) {
          c.plansConfiguration.forEach(p => {
            if (!selectedTiers[p.code]) selectedTiers[p.code] = [];
            selectedTiers[p.code].push(p.tier);
          });
        }
      });
    }
    return selectedTiers;
  };

  getPolicyEffectiveDate = gaInfo => {
    let policyEffectiveDate = "";
    gaInfo.forEach(gaInfo => {
      if (moment().isBetween(gaInfo.cc_date, gaInfo.cr_date)) {
        policyEffectiveDate = gaInfo.cc_date;
      }
    });
    return policyEffectiveDate;
  };

  handleClickInfoButton = (data, id) => event => {
    let infoPopover = { ...this.state.infoPopover };
    infoPopover.open = !infoPopover.open;
    if (infoPopover.open) {
      infoPopover.element = this.refs[id];
      infoPopover.data = data;
    }
    this.setState({ infoPopover });
  };

  renderInfoPopover = () => {
    const { infoPopover } = this.state;
    return (
      <InfoPopover
        open={infoPopover.open}
        data={infoPopover.data}
        element={infoPopover.element}
        handleClickInfoButton={this.handleClickInfoButton}
      />
    );
  };

  render() {
    const { classes, coverage, intl, quote, products } = this.props;
    const { plans, categories, selectedTiers } = this.state;
    const policyEffectiveDate =
      coverage.gaInfo == undefined || null ? "" : this.getPolicyEffectiveDate(coverage.gaInfo);
    // let waiting_period = coverage.policy.waiting_period || null;
    // let contentInfoButton = (
    //   <InfoButton
    //     ref={`btnInfoWaitingPeriod`}
    //     id={`btnInfoWaitingPeriod`}
    //     data={"Period during which no insurance coverage shall be provided"}
    //     handleClickInfoButton={this.handleClickInfoButton}
    //   />
    // );
    let contentInfoPopover = this.renderInfoPopover();
    const { productCode, productVersion } = this.props.location.state;

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.root2}>
            <CategoryPlansTable data={categories} config={products[productCode].config} />
          </div>
          <br />
          <Plans
            types={plans.selectedTypes}
            tiers={selectedTiers}
            selectionDisplayMode={"HIGHLIGHT"}
            portalType={"HR"}
            displayAll={true}
            resetTime={quote.resetTime}
            productCode={productCode}
            productVersion={Number(productVersion)}
            policyEffectiveDate={policyEffectiveDate}
            // policyNo={coverage.policy.policy_no}
          />
        </div>
        {contentInfoPopover}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    backgroundColor: theme.background.color,
    marginBottom: theme.actionFooter.height,
    fontSize: "0.75em"
  },
  content: {
    padding: theme.spacing.unit * 2.5,
    paddingTop: 10
  },
  root2: {
    backgroundColor: "white",
    padding: theme.spacing.unit * 2.5,
    paddingTop: 10
  }
  // waitingPeriodWrapper: {
  //   padding: theme.spacing.unit * 2.5,
  //   marginTop: 10
  // },
  // waitingPeriodContent: {
  //   display: "flex",
  //   justifyContent: "space-between"
  // }
});

function mapStateToProps(state) {
  return {
    coverage: state.HR.coverage,
    quote: state.quote,
    gaInfo: state.gaInfo,
    products: state.products
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles, { withTheme: true })(Coverage)))
);
