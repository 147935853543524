import { APIResource, APIError, APIResponse } from "../../utils/APIResource";

export default class ClientAndPolicyListing {
  static getClientAndPolicy() {
    return APIResource.get("v1/person/policy/client")
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }
}
