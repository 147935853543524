import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import InfoPopover from "../../../../../components/InfoPopoverPremiumAmount";
import InfoButton from "../../../../../components/InfoButtonPremiumAmount";
import NoticeGST from "../../../../../components/NoticeGST";

import { injectIntl } from "react-intl";

class PremiumAmount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoPopover: {
        open: false,
        element: null,
        data: ""
      }
    };
  }

  handleClickInfoButton = (data, id) => event => {
    let infoPopover = { ...this.state.infoPopover };
    infoPopover.open = !infoPopover.open;
    if (infoPopover.open) {
      infoPopover.element = this.refs[id];
      infoPopover.data = data;
    }
    this.setState({ infoPopover });
  };

  render() {
    const { classes, showEstimate, intl, estimate, actual } = this.props;
    let content = null;

    let contentInfoButton = null;
    let contentInfoPopover = null;
    contentInfoButton = [
      <span key="btnInfo" className={!showEstimate ? classes.hide : null}>
        <InfoButton
          ref={`btnInfoPremium`}
          id={`btnInfoPremium`}
          data={
            `<b> The estimated premium includes plan activation on the first day of the next calendar month. It excludes coverage for dependants.
            <br> </b>`
            // +
            // `<ul>` +
            // `<li>` +
            // intl.formatMessage({ id: "notice.gst.2023.firstline"}) +
            // `</li>`+

            // `<li>` +
            // intl.formatMessage({ id: "notice.gst.2023.secondline" }) +
            // `</li>`+

            // `<li>` +
            // intl.formatMessage({ id: "notice.gst.2023.thirdline" }) +
            // `</li>`+
            // `</ul>`
          }
          handleClickInfoButton={this.handleClickInfoButton}
        />
      </span>
    ];

    contentInfoPopover = this.renderInfoPopover();

    let currencySymbol = "S$";
    /*let discount = showEstimate
      ? estimateAmounts.discount || 0
      : actualAmounts.discount || 0;
    discount = intl.formatNumber(discount);*/

    if (estimate || actual)
      content = (
        <div>
          {/**<div
            className={classNames(classes.flexWrapper, classes.discountWrapper)}
          >
            <span>{intl.formatMessage({ id: "premiums.label.discount" })}</span>
            <span className={classes.premiumText2}>
              {currencySymbol} {discount}
            </span>
          </div>**/}
          <div className={classNames(classes.flexWrapper, classes.premiumAmountWrapper)}>
            <span>
              {showEstimate
                ? intl.formatMessage({ id: "premiums.label.estimate" })
                : intl.formatMessage({ id: "premiums.label.actual" })}
              &nbsp;{contentInfoButton}&nbsp;
            </span>
            <span className={classes.premiumAmount}>
              {currencySymbol}{" "}
              {intl.formatNumber(
                showEstimate ? (estimate && estimate.final) || 0 : (actual && actual.total) || 0,

                { style: "decimal", minimumFractionDigits: 2 }
              ) + " / year"}
            </span>
          </div>
          {/* <NoticeGST />     */}
        </div>
      );

    return (
      <div className={classes.root}>
        {content}
        {contentInfoPopover}
      </div>
    );
  }

  renderInfoPopover = () => {
    const { infoPopover } = this.state;

    return (
      <InfoPopover
        open={infoPopover.open}
        data={infoPopover.data}
        element={infoPopover.element}
        handleClickInfoButton={this.handleClickInfoButton}
      />
    );
  };
}

const styles = theme => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeight,
    display: "flex",
    flexDirection: "column",
    paddingLeft: 0,
    paddingRight: 15
    // padding: 24,
    // [theme.breakpoints.down("md")]: {
    //   paddingBottom: 18
    // }
  },
  flexWrapper: {
    // display: "flex",
    // justifyContent: "space-between"
  },
  premiumAmountWrapper: {
    // paddingTop: "10px"
  },
  discountWrapper: {},
  premiumAmount: {
    fontSize: "1.3em",
    fontWeight: "bold"
  },
  hide: {
    display: "none"
  }
});

function mapStateToProps(state) {
  return {
    actual: state.quote.premiums && state.quote.premiums.actual,
    estimate: state.quote.premiums && state.quote.premiums.estimate
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(PremiumAmount))
);
