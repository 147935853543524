import Enums from "../../../../../utils/Enums";

// GHS ----------------------
let GHSBenefits = {
  headers: [
    {
      title: ``,
      mandatory: true,
      attributes: {
        style: {
          width: "8%"
        }
      }
    },
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "30%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `1`
        },
        {
          data: `<strong>Daily Room and Board Benefit</strong>`,
          info: `<em>(Per day, up to 120 days per disability)</em>`
        },
        {
          data: `1-Bed Ward Private`
        },
        {
          data: `2-Bed Ward Private`
        },
        {
          data: `4-Bed Ward Private`
        },
        {
          data: `1-Bed Ward Government Restructured`
        },
        {
          data: `4-Bed Ward Government Restructured`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `2`
        },
        {
          data: `<strong>Intensive Care Unit (ICU)</strong>`,
          info: `<em>(Max. per day, up to 30 days per disability)</em>`
        },
        {
          data: `3 x 1-Bedded`
        },
        {
          data: `3 x 2-Bedded	`
        },
        {
          data: `3 x 4-Bedded`
        },
        {
          data: `S$20,000`,
          info: `per disability limit for items 2 to 8`,
          attributes: {
            rowspan: 7
          }
        },
        {
          data: `S$15,000`,
          info: `per disability limit for items 2 to 8`,
          attributes: {
            rowspan: 7
          }
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `3`
        },
        {
          data: `<strong>High Dependency Ward (HDW)</strong>`,
          info: `<em>(Max. per day, up to 30 days per disability)</em>`
        },
        {
          data: `2 x 1-Bedded`
        },
        {
          data: `2 x 2-Bedded	`
        },
        {
          data: `2 x 4-Bedded`,
          attributes: {
            notLast: true
          }
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `4`
        },
        {
          data: `<strong>Other Hospital Services Benefits – Including implants</strong>`,
          info: `<em>(Max. per disability)</em>`
        },
        {
          data: `S$25,000`,
          info: `per disability<br> limit for items<br> 4 to 8`,
          attributes: {
            rowspan: "5"
          }
        },
        {
          data: `S$20,000`,
          info: `per disability<br> limit for items<br> 4 to 8`,
          attributes: {
            rowspan: "5"
          }
        },
        {
          data: `S$15,000`,
          info: `per disability<br> limit for items<br> 4 to 8`,
          attributes: {
            rowspan: "5"
          }
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `5`
        },
        {
          data: `<strong>Surgical Fees</strong>`,
          info: `<em>(subject to surgical schedule)</em><br>
          i. Waiver of surgical schedule if insured member is admitted to government restructured hospital.<br>
          ii. Surgical fee more than S$1,500 is subject to surgical schedule if insured member is admitted to a private hospital.`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `6`
        },
        {
          data: `<strong>In-Hospital Doctor's Consultation Benefit</strong>`,
          info: `<em>(Max. 120 days)</em>`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `7`
        },
        {
          data: `<strong>Pre (90 days) & Post (90 days) Hospitalisation/ Surgery,  Specialist Consultation, Diagnostic X-Ray and Lab Test, Traditional Chinese Medicine</strong>`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `8`
        },
        {
          data: `<strong>Emergency Accidental Outpatient<br> Treatment Benefit</strong>`,
          info: `<em>(Including Accidental Dental Treatment)</em>`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `9`
        },
        {
          data: `<strong>Miscarriage Benefit</strong>`
        },
        {
          data: `Covered under benefits (items 1 to 8) ,<br> as per the respective benefit limits`,
          attributes: {
            colspan: "5",
            style: {
              width: "100%",
              textAlign: "center"
            }
          }
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `10`
        },
        {
          data: `<strong>Outpatient Cancer Treatment</strong>`,
          info: `<em>(Max. per policy year)</em>`
        },
        {
          data: `S$ 10,000`
        },
        {
          data: `S$ 10,000`
        },
        {
          data: `S$ 10,000`
        },
        {
          data: `N.A`
        },
        {
          data: `N.A`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `11`
        },
        {
          data: `<strong>Outpatient Kidney Dialysis</strong>`,
          info: `<em>(Max. per policy year)</em>`
        },
        {
          data: `S$ 10,000`
        },
        {
          data: `S$ 10,000`
        },
        {
          data: `S$ 10,000`
        },
        {
          data: `N.A`
        },
        {
          data: `N.A`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `12`
        },
        {
          data: `<strong>Overseas Hospitalisation for Accident Benefit</strong>`
        },
        {
          data: `150% of GHS benefit (<em>for item 1 to 7</em>)`,
          attributes: {
            colspan: "5",
            style: {
              width: "100%",
              textAlign: "center"
            }
          }
        }
      ]
    },
    {
      id: 13,
      columns: [
        {
          data: `13`
        },
        {
          data: `<strong>Rehabilitation Benefit</strong>`,
          info: `<em>(Max. per disability, up to 31 days)</em>`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        }
      ]
    },
    {
      id: 14,
      columns: [
        {
          data: `14`
        },
        {
          data: `<strong>Hospital Cash Benefit</strong>`,
          info: `<em>(Max. per day, up to 90 days per disability)</em>`
        },
        {
          data: ``,
          attributes: {
            colspan: "5"
          }
        }
      ]
    },
    {
      id: "",
      columns: [
        {
          data: ``
        },
        {
          data: `<strong>- Singapore Government Restructured B1 Ward</strong>`
        },
        {
          data: `S$ 50`
        },
        {
          data: `S$ 40`
        },
        {
          data: `Nil`
        },
        {
          data: `Nil`
        },
        {
          data: `Nil`
        }
      ]
    },
    {
      id: "",
      columns: [
        {
          data: ``
        },
        {
          data: `<strong>- Singapore Government Restructured B2 Ward</strong>`
        },
        {
          data: `S$ 100`
        },
        {
          data: `S$ 80`
        },
        {
          data: `S$ 40`
        },
        {
          data: `Nil`
        },
        {
          data: `Nil`
        }
      ]
    },
    {
      id: "",
      columns: [
        {
          data: ``
        },
        {
          data: `<strong>- Singapore Government Restructured C Ward</strong>`
        },
        {
          data: `S$ 150`
        },
        {
          data: `S$ 120`
        },
        {
          data: `S$ 80`
        },
        {
          data: `Nil`
        },
        {
          data: `Nil`
        }
      ]
    },
    {
      id: 18,
      columns: [
        {
          data: `15`
        },
        {
          data: `<strong>In-Hospital Psychiatric Treatment</strong>`,
          info: `<em>(Max. per policy year, applicable<br> to Singapore GRH only)</em>`
        },
        {
          data: `S$ 1,000`
        },
        {
          data: `S$ 1,000`
        },
        {
          data: `S$ 1,000`
        },
        {
          data: `S$ 1,000`
        },
        {
          data: `S$ 1,000`
        }
      ]
    },
    {
      id: 19,
      columns: [
        {
          data: `16`
        },
        {
          data: `<strong>Death Benefit</strong>`,
          info: `<em>(Double Death Benefits payable in the event of death due to accidental causes)</em>`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        }
      ]
    },
    {
      id: 20,
      columns: [
        {
          data: `17`
        },
        {
          data: `<p><strong>Pro-ration factor for:<br> Plan 2 &amp; 3</strong></p>
              <p><strong>Plan 4 &amp; 5</strong></p>`,
          info: `<strong>Plan 2 &amp; 3</strong><br> <em>Applicable to items 4 – 7</em></p>
              <p><strong>Plan 4 &amp; 5</strong><br> <em>Applicable to items 2 – 7</em></p>`
        },
        {
          data: `Nil`
        },
        {
          data: `75%`,
          info: `applies if Insured Member stays in 1 Bedded ward (Private or Government Restructured)`
        },
        {
          data: `75%`,
          info: `applies if Insured Member stays in 2 Bedded or higher ward (Private or Government Restructured)`
        },
        {
          data: `75%`,
          info: `applies if Insured Member stays in 1 Bedded ward (Private)`
        },
        {
          data: `75% `,
          info: `applies if Insured Member stays in 4 Bedded or higher ward (Private) or 2 Bedded or higher ward (Government Restructured)`
        }
      ]
    },
    {
      id: 21,
      columns: [
        {
          data: `18`
        },
        {
          data: `<strong>Complimentary Health Screening</strong>`,
          info: `<em>(for employee only)</em>`
        },
        {
          data: `Applicable for group size &gt; 3 eligible employees, yearly renewable and subject to availability`,
          attributes: {
            colspan: "5",
            style: {
              width: "100%",
              textAlign: "center"
            }
          }
        }
      ]
    }
  ],
  footnotes: []
};

let GHSPremiumEmployee = {
  title: `For Employee Only (inclusive of 8% GST)`,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 19</strong>`
        },
        {
          data: `S$ 381.53`
        },
        {
          data: `S$ 257.39`
        },
        {
          data: `S$ 205.90`
        },
        {
          data: `S$ 194.80`
        },
        {
          data: `S$ 137.27`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>20 - 24</strong>`
        },
        {
          data: `S$ 381.53`
        },
        {
          data: `S$ 257.39`
        },
        {
          data: `S$ 205.90`
        },
        {
          data: `S$ 198.84`
        },
        {
          data: `S$ 142.32`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>25 - 29</strong>`
        },
        {
          data: `S$ 381.53`
        },
        {
          data: `S$ 262.43`
        },
        {
          data: `S$ 208.94`
        },
        {
          data: `S$ 205.90`
        },
        {
          data: `S$ 147.37`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>30 - 34</strong>`
        },
        {
          data: `S$ 411.81`
        },
        {
          data: `S$ 294.73`
        },
        {
          data: `S$ 236.19`
        },
        {
          data: `S$ 219.02`
        },
        {
          data: `S$ 157.45`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>35 - 39</strong>`
        },
        {
          data: `S$ 411.81`
        },
        {
          data: `S$ 301.80`
        },
        {
          data: `S$ 241.23`
        },
        {
          data: `S$ 251.33`
        },
        {
          data: `S$ 180.67`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>40 - 44</strong>`
        },
        {
          data: `S$ 443.10`
        },
        {
          data: `S$ 366.39`
        },
        {
          data: `S$ 288.67`
        },
        {
          data: `S$ 325.02`
        },
        {
          data: `S$ 232.15`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>45 - 49</strong>`
        },
        {
          data: `S$ 638.92`
        },
        {
          data: `S$ 474.39`
        },
        {
          data: `S$ 391.63`
        },
        {
          data: `S$ 395.67`
        },
        {
          data: `S$ 274.55`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>50 - 54</strong>`
        },
        {
          data: `S$ 773.16`
        },
        {
          data: `S$ 609.65`
        },
        {
          data: `S$ 514.77`
        },
        {
          data: `S$ 536.98`
        },
        {
          data: `S$ 384.57`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>55 - 59</strong>`
        },
        {
          data: `S$ 947.78`
        },
        {
          data: `S$ 840.79`
        },
        {
          data: `S$ 669.20`
        },
        {
          data: `S$ 701.49`
        },
        {
          data: `S$ 501.65`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>60 - 64</strong>`
        },
        {
          data: `S$ 1,339.41`
        },
        {
          data: `S$ 1,051.74`
        },
        {
          data: `S$ 927.59`
        },
        {
          data: `S$ 929.61`
        },
        {
          data: `S$ 665.16`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>65 - 69</strong>`
        },
        {
          data: `S$ 1,837.02`
        },
        {
          data: `S$ 1,493.83`
        },
        {
          data: `S$ 1,229.39`
        },
        {
          data: `S$ 1,220.30`
        },
        {
          data: `S$ 873.08`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only.</em>`
        },
        {
          data: `S$ 3,431.78`
        },
        {
          data: `S$ 2,513.27`
        },
        {
          data: `S$ 2,023.74`
        },
        {
          data: `S$ 1,669.45`
        },
        {
          data: `S$ 1,194.06`
        }
      ]
    }
  ],
  footnotes: []
};

let GHSPremiumSpouseOrChild = {
  title: `For Employee and Spouse or Children Coverage Only (inclusive of 8% GST)`,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 19</strong>`
        },
        {
          data: `S$ 858.45`
        },
        {
          data: `S$ 579.12`
        },
        {
          data: `S$ 463.29`
        },
        {
          data: `S$ 438.31`
        },
        {
          data: `S$ 308.86`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>20 - 24</strong>`
        },
        {
          data: `S$ 858.45`
        },
        {
          data: `S$ 579.12`
        },
        {
          data: `S$ 463.29`
        },
        {
          data: `S$ 447.39`
        },
        {
          data: `S$ 320.22`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>25 - 29</strong>`
        },
        {
          data: `S$ 858.45`
        },
        {
          data: `S$ 590.47`
        },
        {
          data: `S$ 470.10`
        },
        {
          data: `S$ 463.29`
        },
        {
          data: `S$ 331.57`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>30 - 34</strong>`
        },
        {
          data: `S$ 926.58`
        },
        {
          data: `S$ 663.14`
        },
        {
          data: `S$ 531.42`
        },
        {
          data: `S$ 492.81`
        },
        {
          data: `S$ 354.28`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>35 - 39</strong>`
        },
        {
          data: `S$ 926.58`
        },
        {
          data: `S$ 679.04`
        },
        {
          data: `S$ 542.78`
        },
        {
          data: `S$ 565.49`
        },
        {
          data: `S$ 406.51`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>40 - 44</strong>`
        },
        {
          data: `S$ 996.98`
        },
        {
          data: `S$ 824.39`
        },
        {
          data: `S$ 649.51`
        },
        {
          data: `S$ 731.27`
        },
        {
          data: `S$ 522.34`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>45 - 49</strong>`
        },
        {
          data: `S$ 1,437.57`
        },
        {
          data: `S$ 1,067.39`
        },
        {
          data: `S$ 881.16`
        },
        {
          data: `S$ 890.24`
        },
        {
          data: `S$ 617.72`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>50 - 54</strong>`
        },
        {
          data: `S$ 1,739.61`
        },
        {
          data: `S$ 1,371.70`
        },
        {
          data: `S$ 1,158.22`
        },
        {
          data: `S$ 1,208.19`
        },
        {
          data: `S$ 865.26`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>55 - 59</strong>`
        },
        {
          data: `S$ 2,132.49`
        },
        {
          data: `S$ 1,891.77`
        },
        {
          data: `S$ 1,505.69`
        },
        {
          data: `S$ 1,578.37`
        },
        {
          data: `S$ 1,128.70`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>60 - 64</strong>`
        },
        {
          data: `S$ 3,013.65`
        },
        {
          data: `S$ 2,366.41`
        },
        {
          data: `S$ 2,087.08`
        },
        {
          data: `S$ 2,091.61`
        },
        {
          data: `S$ 1,496.61`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>65 - 69</strong>`
        },
        {
          data: `S$ 4,133.27`
        },
        {
          data: `S$ 3,361.12`
        },
        {
          data: `S$ 2,766.12`
        },
        {
          data: `S$ 2,745.67`
        },
        {
          data: `S$ 1,964.44`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 7,721.49`
        },
        {
          data: `S$ 5,654.86`
        },
        {
          data: `S$ 4,553.41`
        },
        {
          data: `S$ 3,756.28`
        },
        {
          data: `S$ 2,686.63`
        }
      ]
    }
  ],
  footnotes: []
};

let GHSPremiumSpouseAndChild = {
  title: `For Employee and Spouse and Children Coverage Only (inclusive of 8% GST)`,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 19</strong>`
        },
        {
          data: `S$ 1,335.37`
        },
        {
          data: `S$ 900.84`
        },
        {
          data: `S$ 720.67`
        },
        {
          data: `S$ 681.81`
        },
        {
          data: `S$ 480.45`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>20 - 24</strong>`
        },
        {
          data: `S$ 1,335.37`
        },
        {
          data: `S$ 900.84`
        },
        {
          data: `S$ 720.67`
        },
        {
          data: `S$ 695.94`
        },
        {
          data: `S$ 498.12`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>25 - 29</strong>`
        },
        {
          data: `S$ 1,335.37`
        },
        {
          data: `S$ 918.51`
        },
        {
          data: `S$ 731.27`
        },
        {
          data: `S$ 720.67`
        },
        {
          data: `S$ 515.78`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>30 - 34</strong>`
        },
        {
          data: `S$ 1,441.35`
        },
        {
          data: `S$ 1,031.55`
        },
        {
          data: `S$ 826.65`
        },
        {
          data: `S$ 766.59`
        },
        {
          data: `S$ 551.10`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>35 - 39</strong>`
        },
        {
          data: `S$ 1,441.35`
        },
        {
          data: `S$ 1,056.28`
        },
        {
          data: `S$ 844.32`
        },
        {
          data: `S$ 879.65`
        },
        {
          data: `S$ 632.35`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>40 - 44</strong>`
        },
        {
          data: `S$ 1,550.86`
        },
        {
          data: `S$ 1,282.37`
        },
        {
          data: `S$ 1,010.35`
        },
        {
          data: `S$ 1,137.53`
        },
        {
          data: `S$ 812.53`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>45 - 49</strong>`
        },
        {
          data: `S$ 2,236.20`
        },
        {
          data: `S$ 1,660.37`
        },
        {
          data: `S$ 1,370.69`
        },
        {
          data: `S$ 1,384.82`
        },
        {
          data: `S$ 960.90`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>50 - 54</strong>`
        },
        {
          data: `S$ 2,706.06`
        },
        {
          data: `S$ 2,133.76`
        },
        {
          data: `S$ 1,801.68`
        },
        {
          data: `S$ 1,879.41`
        },
        {
          data: `S$ 1,345.96`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>55 - 59</strong>`
        },
        {
          data: `S$ 3,317.22`
        },
        {
          data: `S$ 2,942.75`
        },
        {
          data: `S$ 2,342.19`
        },
        {
          data: `S$ 2,455.23`
        },
        {
          data: `S$ 1,755.76`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>60 - 64</strong>`
        },
        {
          data: `S$ 4,687.90`
        },
        {
          data: `S$ 3,681.08`
        },
        {
          data: `S$ 3,246.57`
        },
        {
          data: `S$ 3,253.63`
        },
        {
          data: `S$ 2,328.06`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>65 - 69</strong>`
        },
        {
          data: `S$ 6,429.53`
        },
        {
          data: `S$ 5,228.41`
        },
        {
          data: `S$ 4,302.84`
        },
        {
          data: `S$ 4,271.04`
        },
        {
          data: `S$ 3,055.80`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 12,011.22`
        },
        {
          data: `S$ 8,796.45`
        },
        {
          data: `S$ 7,083.08`
        },
        {
          data: `S$ 5,843.10`
        },
        {
          data: `S$ 4,179.20`
        }
      ]
    }
  ],
  footnotes: []
};

/*let GHSGroupDiscount = {
  headers: [
    {
      title: `Group Size <i>Insured Employee<i>`
    },
    {
      title: `Discount`
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>11 - 15</strong>`
        },
        {
          data: `5%`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>16 and above</strong>`
        },
        {
          data: `10%`
        }
      ]
    }
  ],
  footnotes: []
};*/

//   ----------------------------------------------------------------------

const GHS = {
  id: 2,
  code: "GHSB",
  label: "Group Hospital & Surgical (GHS)",
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GHSBenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium",
      data: [GHSPremiumEmployee, GHSPremiumSpouseOrChild, GHSPremiumSpouseAndChild]
    }
    /*{
      id: 4,
      order: 4,
      type: Enums.PLANS_TYPE.GROUP_DISCOUNT,
      label: "Group Size Discount",
      data: [GHSGroupDiscount]
    }*/
  ]
};

export default GHS;
