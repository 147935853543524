import { APIResource, APIError, APIResponse } from "../../utils/APIResource";

export default class BenefitService {
  static sendEmailRequestCallBack(data, partnerKey) {
    return APIResource.post(`/v1/partner/${partnerKey}/callback`, data, {})
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }

  static sendPartnerTransactionDetails(data, partnerKey) {
    const config = {
      headers: {
        "content-type": "application/json"
      }
    };
    return APIResource.post(`/v1/partner/${partnerKey}/transaction`, data, config)
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }
}
