import Enums from "../../../../utils/Enums";

let doc = [
  {
    desc: "PRUworks HR User Guide",
    fileNameOriginal: "PRUworks HR Portal Guide 2023.pdf",
    fileName: "PRUworks HR User Guide.pdf",
    type: [Enums.P_CODE.PRU_AGENCY, Enums.P_CODE.SCB_ASSISTED, Enums.P_CODE.PFA, Enums.P_CODE.BRO],
    code: { PTF: [Enums.PLANS_CODE.HDF], GCP: [Enums.PLANS_CODE.HDF], PF3: [Enums.PLANS_CODE.HDF] }
  },
  {
    desc: "PRUworks Employee User Guide",
    fileNameOriginal: "PRUworks Employee Portal Guide 2023.pdf",
    fileName: "PRUworks Employee User Guide.pdf",
    type: [Enums.P_CODE.PRU_AGENCY, Enums.P_CODE.SCB_ASSISTED, Enums.P_CODE.PFA, Enums.P_CODE.BRO],
    code: { PTF: [Enums.PLANS_CODE.HDF], GCP: [Enums.PLANS_CODE.HDF], PF3: [Enums.PLANS_CODE.HDF] }
  },
  {
    desc: "PRUworks HR User Guide",
    fileNameOriginal: "PRUworks HR Portal Guide 2023.pdf",
    fileName: "PRUworks HR User Guide.pdf",
    type: [Enums.P_CODE.UOB_ASSISTED, Enums.P_CODE.UOB_TEAM_2],
    code: { PEP: [Enums.PLANS_CODE.GTLB], PTF: [Enums.PLANS_CODE.HDF], PF3: [Enums.PLANS_CODE.HDF] }
  },
  {
    desc: "PRUworks Employee User Guide",
    fileNameOriginal: "PRUworks Employee Portal Guide 2023.pdf",
    fileName: "PRUworks Employee User Guide.pdf",
    type: [Enums.P_CODE.UOB_ASSISTED, Enums.P_CODE.UOB_TEAM_2],
    code: { PEP: [Enums.PLANS_CODE.GTLB], PTF: [Enums.PLANS_CODE.HDF], PF3: [Enums.PLANS_CODE.HDF] }
  },
  {
    desc: "PRUworks Sales User Guide",
    fileNameOriginal: "PRUworks FC Portal Guide 2023.pdf",
    fileName: "PRUworks User Guide.pdf",
    type: [Enums.P_CODE.PRU_AGENCY, Enums.P_CODE.PFA, Enums.P_CODE.BRO]
  },
  {
    desc: "PRUtreasures flexi II Calculator",
    fileNameOriginal: "PRUtreasures flexi II Calculator_v4.xlsm",
    fileName: "PRUtreasures flexi II Calculator_v4.xlsm",
    type: [
      Enums.P_CODE.PRU_AGENCY,
      Enums.P_CODE.PFA,
      Enums.P_CODE.UOB_ASSISTED,
      Enums.P_CODE.UOB_TEAM_2,
      Enums.P_CODE.SCB_ASSISTED,
      Enums.P_CODE.BRO
    ]
  },
  {
    desc: "MHC Guide",
    fileNameOriginal: "GCP_MHC_Guide.pdf",
    fileName: "MHC Guide.pdf",
    type: [Enums.P_CODE.CUSTOMIZED],
    code: { GCP: [Enums.PLANS_CODE.GHSB] }
  },
  {
    desc: "Panel GP Listing",
    fileNameOriginal: "GCP_Panel_GP_Listing.pdf",
    fileName: "Panel GP Listing.pdf",
    type: [Enums.P_CODE.CUSTOMIZED],
    code: { GCP: [Enums.PLANS_CODE.GHSB] }
  },
  {
    desc: "Panel SP Listing",
    fileNameOriginal: "GCP_Panel_SP_Listing.pdf",
    fileName: "Panel SP Listing.pdf",
    type: [Enums.P_CODE.CUSTOMIZED],
    code: { GCP: [Enums.PLANS_CODE.GHSB] }
  },
  {
    desc: "Hospital Ward Charges",
    fileNameOriginal: "GCP_Hospital_Ward_Charges.pdf",
    fileName: "Hospital Ward Charges.pdf",
    type: [Enums.P_CODE.CUSTOMIZED],
    code: { GCP: [Enums.PLANS_CODE.GHSB] }
  },
  {
    desc: "PRUtreasures flexi II Product Brochure",
    fileNameOriginal: "PTRF2 Brochure 25 July 2024 - Agency (New).pdf",
    fileName: "Prudential-PRUtreasures-Flexi-II_072024.pdf",
    type: [Enums.P_CODE.PRU_AGENCY, Enums.P_CODE.PFA, Enums.P_CODE.BRO]
  },
  {
    desc: "PRUtreasures flexi II Product Brochure (Chinese translated version)",
    fileNameOriginal: "PTRF2 Brochure 14 Sep 24 - Agency (Chinese) V2.3.pdf",
    fileName: "Prudential-PRUtreasures-Flexi-II-CN_092024.pdf",
    type: [Enums.P_CODE.PRU_AGENCY, Enums.P_CODE.PFA, Enums.P_CODE.BRO]
  },
  {
    desc: "PRUtreasures flexi II Product Brochure",
    fileNameOriginal: "PTRF2 Brochure 25 July 2024 - UOB (New).pdf",
    fileName: "Prudential-PRUtreasures-Flexi-II-UOB_072024.pdf",
    type: Enums.P_CODE.UOB_ASSISTED
  },
  {
    desc: "PRUtreasures flexi II Product Brochure",
    fileNameOriginal: "PTRF2 Brochure 25 July 2024 - SCB (New).pdf",
    fileName: "Prudential-PRUtreasures-Flexi-II-SCB_072024.pdf",
    type: Enums.P_CODE.SCB_ASSISTED
  },
  {
    desc: "User Guide to Unencrypt encrypted file",
    fileNameOriginal: "User-Guide-to-Unencrypt-encrypted-file.pdf",
    fileName: "user guide to unencrypt encrypted file .pdf",
    type: [
      Enums.P_CODE.PRU_AGENCY,
      Enums.P_CODE.SCB_ASSISTED,
      Enums.P_CODE.UOB_ASSISTED,
      Enums.P_CODE.PFA,
      Enums.P_CODE.BRO
    ]
  }
];

export default doc;
