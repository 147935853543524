import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import MobileDetect from "mobile-detect";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InfoButton from "../../../../components/InfoButton";
import InfoPopover from "../../../../components/InfoPopover";
import SimpleMessageDialog from "../../../../components/SimpleMessageDialog";
import Util from "../../../../utils/Util";
import "./style.css";

const mobileDetect = new MobileDetect(window.navigator.userAgent);

const DISPLAYMODE = {
  SHOW: "SHOW",
  HIGHLIGHT: "HIGHLIGHT"
};

class PlanTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoPopover: {
        open: false,
        element: null,
        data: ""
      }
    };
  }
  /**
   * Check if the current col belongs to active tier
   * @param tiers The columns are activated
   * @return true if it belongs to active tier
   * @return false if it doesn't belong to active tier
   */
  valueBelongsToActiveColumn = (tiers, col) => {
    const { data } = this.props;
    const { colspan } = col.attributes;
    this.maxTiger = Math.max(...tiers);
    /**
     * If colSpan is lesser maxTiger
     * Then check if colspan is inside tiers
     */
    if (colspan <= this.maxTiger) {
      return tiers.indexOf(col.attributes.colspan) >= -1;
    } else if (colspan > this.maxTiger) {
      /**
       * If colSpan is greater than maxTiger.
       * It convered all columns already
       */
      return true;
    }
  };

  /**
   * Check if header is selected
   */
  checkSelected = header => {
    const { tiers } = this.props;
    return header.mandatory || (tiers && tiers.includes(header.tier));
  };

  checkColSpan = col => {
    return col && col.attributes && col.attributes.colspan > 1;
  };

  /**
   * Get data for header
   * @isSelected Has header selected
   * @classHighlight Class hightlight for header
   */
  getHeaderData = header => {
    const { classes, tiers, selectionDisplayMode } = this.props;
    if (!Util.isEmpty(tiers) && !Util.isEmpty(selectionDisplayMode)) {
      this.isSelected = this.checkSelected(header);
      switch (selectionDisplayMode) {
        case DISPLAYMODE.HIGHLIGHT:
          this.classHighlight = !this.isSelected ? classes.thCellDataDim : classes.thCellDataHighlight;
          break;
        default:
          this.classHighlight = {};
      }
    } else {
      this.isSelected = false;
      switch (selectionDisplayMode) {
        case DISPLAYMODE.HIGHLIGHT:
          this.classHighlight = !this.isSelected ? classes.thCellDataDim : classes.thCellDataHighlight;
          break;
        default:
          this.classHighlight = {};
      }
    }
    return {
      classHighlight: this.classHighlight,
      isSelected: this.isSelected
    };
  };

  /**
   * Get data for body
   * @col The col data after finalized
   * @isSelected Has header selected
   * @classHighlight Class hightlight for header
   */
  getBodyData = (col, header) => {
    const { classes, tiers, selectionDisplayMode } = this.props;
    if (!Util.isEmpty(tiers) && !Util.isEmpty(selectionDisplayMode)) {
      this._col = Util.clone(col);
      this.isSelected = this.checkSelected(header);
      if (Util.isEmpty(col) && selectionDisplayMode === DISPLAYMODE.SHOW) {
        //Assume and takes the previous cell's saved colspan data
        col = this.isSelected ? _col || null : null;
        if (col && col.attributes) {
          col.attributes.colspan = 1;
        }
      }
      // Class hightlight for row
      switch (selectionDisplayMode) {
        case DISPLAYMODE.HIGHLIGHT:
          this.hasColSpan = this.checkColSpan(col);
          let overwrite = false;
          if (this.hasColSpan) {
            if (col.attributes.overwrite) {
              overwrite = true;
            }
          }
          this.classHighlight =
            this.isSelected || (this.hasColSpan && this.valueBelongsToActiveColumn(tiers, col)) || overwrite
              ? //if overwrite is true cell highlight
                classes.thCellDataHighlight
              : classes.thCellDataDim;
          break;
        default:
          this.classHighlight = {};
      }
    }
    return {
      col: col,
      isSelected: this.isSelected,
      classHighlight: this.classHighlight
    };
  };

  getTableCellColSpanForHeader = header => {
    return (header.attributes && header.attributes.colspan) || 1;
  };

  getTableCellColSpanForBody = col => {
    return (col.attributes && col.attributes.colspan) || 1;
  };

  getTableCellRowSpanForBody = col => {
    return (col.attributes && col.attributes.rowspan) || 1;
  };

  render() {
    const { classes, tiers, selectionDisplayMode } = this.props;
    const { data } = this.props;
    return (
      <div className={classes.root}>
        <Table className={classes.plans}>
          <TableHead>
            <TableRow>
              {data.headers.map((header, _idxHeader) => {
                const { isSelected, classHighlight } = this.getHeaderData(header);
                if (!isSelected && selectionDisplayMode === DISPLAYMODE.SHOW) {
                  return null;
                } else {
                  return (
                    <TableCell
                      key={_idxHeader}
                      colSpan={this.getTableCellColSpanForHeader(header)}
                      style={header.attributes && header.attributes.style}
                    >
                      <div className={classNames(classes.thCellData, classHighlight)}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: header.title
                          }}
                        />
                        {/* Render additional info (optional) */}
                        {header.info && (
                          <InfoButton
                            ref={`btnInfoHeader${_idxHeader}`}
                            id={`btnInfoHeader${_idxHeader}`}
                            data={header.info}
                            handleClickInfoButton={this.handleClickInfoButton}
                          />
                        )}
                      </div>
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.rows.map((row, _idxRow) => {
              return (
                <TableRow key={_idxRow}>
                  {data.headers.map((header, _idx) => {
                    const { col, isSelected, classHighlight } = this.getBodyData(row.columns[_idx], header);
                    if ((!isSelected && selectionDisplayMode === DISPLAYMODE.SHOW) || Util.isEmpty(col)) {
                      return null;
                    } else {
                      return (
                        <TableCell
                          key={_idx}
                          rowSpan={this.getTableCellRowSpanForBody(col)}
                          colSpan={this.getTableCellColSpanForBody(col)}
                          classes={{
                            root: classes.tbCellLast
                          }}
                        >
                          <div className={classNames(classes.tbCellData, classHighlight)}>
                            <span
                              style={col.attributes && col.attributes.style}
                              dangerouslySetInnerHTML={{
                                __html: col.data
                              }}
                            />
                            {/* Render additional info (optional) */}
                            {col.info && (
                              <InfoButton
                                ref={`btnInfoBody${_idxRow}-${_idx}`}
                                id={`btnInfoBody${_idxRow}-${_idx}`}
                                data={col.info}
                                handleClickInfoButton={this.handleClickInfoButton}
                              />
                            )}
                          </div>
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {/* Info Popover || Dialog*/}
        {mobileDetect.phone() === null ? this.renderInfoPopover() : this.renderInfoDialog()}
      </div>
    );
  }

  handleClickInfoButton = (data, id) => event => {
    let infoPopover = {
      ...this.state.infoPopover
    };
    infoPopover.open = !infoPopover.open;
    if (infoPopover.open) {
      infoPopover.element = this.refs[id];
      infoPopover.data = data;
    }
    this.setState({ infoPopover });
  };

  handleCloseDialog() {
    let infoPopover = {
      ...this.state.infoPopover,
      open: false
    };
    this.setState({ infoPopover });
  }

  renderInfoPopover = () => {
    const { infoPopover } = this.state;

    return (
      <InfoPopover
        open={infoPopover.open}
        data={infoPopover.data}
        element={infoPopover.element}
        handleClickInfoButton={this.handleClickInfoButton}
      />
    );
  };

  renderInfoDialog = () => {
    const { infoPopover } = this.state;

    return (
      <SimpleMessageDialog
        type="none"
        name="info"
        title="Info"
        description={infoPopover.data}
        alignCenter={false}
        isHtml={true}
        open={infoPopover.open}
        closeHandler={this.handleCloseDialog.bind(this)}
      />
    );
  };
}

const styles = theme => ({
  root: {
    width: "100%"
  },
  thCellData: {
    display: "flex",
    alignItems: "left",
    justifyContent: "left"
  },
  tbCellData: {
    display: "flex",
    alignItems: "left",
    justifyContent: "left"
  },
  thCellDataHighlight: {
    opacity: 1
  },
  thCellDataDim: {
    opacity: 0.5
  },
  tbCellLast: {
    border: `1px solid ${theme.colors.blackScale.black70}`
    // "&:last-child": {
    //   padding: "4px 10px 4px 10px"
    // }
  },
  plans: {
    tableLayout: "fixed",
    overflow: "auto"
    // border: `1px solid ${theme.colors.blackScale.black70}`
  }
});

export default withStyles(styles, { withTheme: true })(PlanTable);
