import React from "react";
import { ValidatorComponent } from "react-material-ui-form-validator";
import { withStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers";

class DatePickerValidatorElement extends ValidatorComponent {
  constructor(props) {
    super(props);
    props.innerRef?.(this);
  }

  renderValidatorComponent() {
    const {
      classes,
      errorMessages,
      validators,
      requiredError,
      errorText,
      validatorListener,
      helperText,
      ...rest
    } = this.props;
    const { isValid } = this.state;
    return (
      <DatePicker
        {...rest}
        slotProps={{
          textField: {
            error: !isValid,
            fullWidth: rest.fullWidth,
            helperText: this.errorText(helperText),
            variant: rest.variant
          }
        }}
      />
    );
  }

  errorText(defaultText) {
    const { isValid } = this.state;
    if (isValid) {
      return defaultText;
    }

    return <span>{this.getErrorMessage()}</span>;
  }
}

const styles = theme => ({
  error: {
    color: theme.colors.primary.red
  },
  labelError: {
    color: theme.colors.primary.red
  }
});

export default withStyles(styles, { withTheme: true })(DatePickerValidatorElement);
