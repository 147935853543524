import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import classNames from "classnames";

const styles = theme => ({
  backButton: {
    backgroundColor: theme.colors.secondary.white,
    border: `2px solid black`,
    color: theme.colors.blackScale.black100,
    fontWeight: 500
  },
  dialogTitle: {
    paddingBottom: "2px"
  },
  button: {
    minWidth: 150
  },
  dialogContent: {
    padding: "5px 24px"
  },
  divider: {
    marginTop: "1px"
  }
});

const OtpSelector = ({ open, onClose, onBack, classes, contents }) => {
  console.log("contents", contents);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="verification-dialog-title"
      maxWidth={false}
      sx={{
        "& .MuiPaper-root": {
          minWidth: 360,
          maxWidth: 600,
          width: "100%" // Allows it to scale based on screen size
        }
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={classNames(classes.dialogTitle)}
      >
        <DialogTitle id="verification-dialog-title" style={{ paddingRight: "2rem" }}>
          Choose another way for login verification:
        </DialogTitle>
      </Box>

      {contents &&
        Object.entries(contents).map(([method, Component]) => (
          <DialogContent className={classNames(classes.dialogContent)}>
            <Component />
          </DialogContent>
        ))}

      <DialogActions style={{ justifyContent: "flex-start", paddingLeft: 16 }}>
        <Button onClick={onBack} variant="raised" className={classNames(classes.button, classes.backButton)}>
          Back
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles, { withTheme: true })(OtpSelector);
