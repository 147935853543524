import PF3_PlansCode from "./PF3/plansCode";
import PTF_PlansCode from "./PTF/plansCode";
import PEP_PlansCode from "./PEP/plansCode";
import GCP_PlansCode from "./GCP/plansCode";

const planscode = {
  PF3: PF3_PlansCode,
  PTF: PTF_PlansCode,
  PEP: PEP_PlansCode,
  GCP: GCP_PlansCode
};

export default planscode;
