/**
 * Inject Polyfill
 * For fixing issues on IE
 * Some functions from Javascript didn't have on IE11
 * For example: .includes and etc...
 */
import "core-js";
import "core-js/stable";
import "regenerator-runtime/runtime";

import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import App from "./containers/App/App";
import React from "react";
import ReactDOM from "react-dom";
import registerServiceWorker from "./registerServiceWorker";
import "./index.css";
import config from "./config/config";
import "./ie.css";

console.info(`${config.app.version} build ${config.app.build}`);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
