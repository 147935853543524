import * as actionTypes from "../types";
import ClientAndPolicyListingService from "../../services/HR/clientandpolicylisting.service";

export const clientAndPolicyListingActions = {
  getClientAndPolicyListing
};

function getClientAndPolicyListing() {
  return dispatch => {
    return ClientAndPolicyListingService.getClientAndPolicy().then(
      resp => {
        if (resp.success) {
          dispatch({ type: actionTypes.CLIENT_AND_POLICY_LISTING, payload: resp.data });
          return Promise.resolve(resp);
        }
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}
