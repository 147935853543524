import doc from "./data/hr/documents";
import fcDoc from "./data/fc/documents";
import pcDoc from "./data/policy/documents";
import userGuide from "./data/hr/documents/user-guide";
import productInfo from "./data/productInfo";

const images = {
  pru_logo: require("./img/prulogo.png"),
  pru_menu_logo: require("./img/pru_menu_logo.jpg"),
  pru_work_logo: require("./img/pruworks_main logo.png"),
  avatar: require("./img/avatar.jpg"),
  stepper: require("./img/stepper.gif"),
  brand: {
    UOB: require("./img/brand/UOB.jpg")
  },
  lord: require("./img/stepper.gif")
};

const benefitImages = {
  benefitsImg: require("./img/oval-benefits-img.png"),
  resultsImg: require("./img/oval-results-img.png"),
  serviceImg: require("./img/oval-service-img.png"),
  left_red_chevron: require("./img/red-chevron-left.png")
};

const template = {
  TestEmployee: require("./template/Employee_Template_Final.xlsx")
};

const benefits = {
  details: require("./data/hr/benefit/benefitDetails.js"),
  list: require("./data/hr/benefit/yourBenefits.js")
};

const dashBoardImages = {
  background: require("./img/dashboard-background.png"),
  socialMedia: require("./img/dashboard-social-media-img.png"),
  employees: require("./img/dashboard-employees-img.png"),
  document: require("./img/dashboard-document-img.png"),
  benefits: require("./img/dashboard-benefits-img.png"),
  red_arrow: require("./img/red-arrow.png")
};

const introImages = {
  background: require("./img/intro-banner-background.png"),
  csBackground: require("./img/intro-chooseus-background.png"),
  bannerImg: require("./img/intro-banner-img.png"),
  videoIcon: require("./img/intro-video-icon.png"),
  questionIcon: require("./img/question-icon.png"),
  csIcon: require("./img/intro-cs-icon.png"),
  cshIcon: require("./img/chs_icon.png"),
  gsdIcon: require("./img/intro-gsd-icon.png"),
  listIcon: require("./img/intro-list-icon.png"),
  vasIcon: require("./img/intro-vas-icon.png"),
  demoIcon: require("./img/intro-demographics-icon.png"),
  gcIcon: require("./img/intro-gc-icon.png"),
  ecIcon: require("./img/ec_icons.png"),
  medIconActive: require("./img/intro-med-active-icon.png"),
  medIcon: require("./img/intro-med-icon.png"),
  healthIconActive: require("./img/intro-health-active-icon.png"),
  healthIcon: require("./img/intro-health-icon.png"),
  loanIconActive: require("./img/intro-loan-active-icon.png"),
  medImg: require("./img/intro-med-img.png"),
  healthImg: require("./img/intro-health-img.png"),
  loanImg: require("./img/intro-loan-img.png"),
  moreIcon: require("./img/doublearrowright_icon.png")
};

const hrMenuIcons = {
  dashboard: require("./img/hr/dashboard.png"),
  insuranceCoverage: require("./img/hr/insurance-coverage.png"),
  myEmployees: require("./img/hr/my-employees.png"),
  employeeBenefits: require("./img/hr/benefits.png"),
  documents: require("./img/hr/documents.png"),
  claimExpenses: require("./img/hr/claim-expenses.png")
};

const global = {
  background: require("./img/background.png")
};

const alert = {
  tip: require("./img/alert/tip.png")
};

export default {
  images,
  alert,
  benefitImages,
  benefits,
  template,
  doc,
  fcDoc,
  pcDoc,
  global,
  dashBoardImages,
  productInfo,
  introImages,
  hrMenuIcons,
  userGuide
};
