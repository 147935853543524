import { withStyles } from "@mui/styles";
import React, { Component } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

class PolicyDetailsBoxItem extends Component {
  render() {
    const { classes, index } = this.props;
    return (
      <div
        className={classes.boxItem}
        onClick={() => {
          this.props.onClick(this.props.data.id);
        }}
      >
        <div className={classes.boxInfo}>
          <img src={this.props.data.logo} alt={this.props.data.title} className={classes.boxIcon} />
          <div className={classes.textContainer}>
            <span className={classes.boxTitle}>{this.props.data.title}</span>
            <span className={classes.boxDescription}>{this.props.data.description}</span>
          </div>
        </div>
        <KeyboardArrowRightIcon style={{ color: "red" }} />
      </div>
    );
  }
}

const styles = theme => ({
  root: {},
  boxItem: {
    cursor: "pointer",
    alignItems: "center",
    background: "#FFF",
    borderRadius: 3,
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 10px"
  },
  boxIcon: {
    height: "40px",
    width: "40px"
  },
  boxInfo: {
    display: "flex",
    alignItems: "center"
  },
  boxTitle: {
    paddingLeft: 10,
    fontSize: 14
  },
  boxArrow: {
    right: 15
  },
  boxDescription: {
    paddingLeft: 10,
    fontSize: 12
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 15
  }
});

export default withStyles(styles, { withTheme: true })(PolicyDetailsBoxItem);
