import Enums from "../../../../../utils/Enums";

const GDBenefits = {
  headers: [
    {
      title: ``,
      mandatory: true,
      attributes: {
        style: {
          width: 10
        }
      }
    },
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "40%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>1</strong>`
        },
        {
          data: `<strong>Specialist (Government Hospital)</strong>
                <br>Benefit
                <br><i>(includes Standard Diagnostic X-ray & Lab Test)</i>
                <br>Number of visits per year
                <br>Co-Payment/Co-Insurance                
                `
        },
        {
          data: `<br>
                <br>Unlimited
                <br>
                <br>Unlimited
                <br>NIL              
                `
        },
        {
          data: `<br>
                <br>Unlimited
                <br>
                <br>Unlimited
                <br>20%              
                `
        },
        {
          data: `<br>
                <br>Unlimited
                <br>
                <br>Unlimited
                <br>NIL              
                `
        },
        {
          data: `<br>
                <br>Unlimited
                <br>
                <br>Unlimited
                <br>20%              
                `
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>2</strong>`
        },
        {
          data: `<strong>Panel Specialist (Private)</strong>
                <br>Benefit
                <br><i>(includes Standard Diagnostic X-ray & Lab Test)</i>
                <br>Co-Payment/Co-Insurance                
                `
        },
        {
          data: `<br>
                <br>Unlimited
                <br>
                <br>NIL              
                `
        },
        {
          data: `<br>
                <br>Unlimited
                <br>
                <br>20%              
                `
        },
        {
          data: `<br>
                <br>Unlimited
                <br>
                <br>NIL              
                `
        },
        {
          data: `<br>
                <br>Unlimited
                <br>
                <br>20%              
                `
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>3</strong>`
        },
        {
          data: `<strong>Non-Panel Specialist</strong>
                <br>Benefit
                <br><i>(includes Standard Diagnostic X-ray & Lab Test)</i>
                <br>Co-Payment/Co-Insurance                
                `
        },
        {
          data: `<br>
                <br>Up to $1,000 per policy year
                <br>
                <br>NIL              
                `
        },
        {
          data: `<br>
                <br>Up to $1,000 per policy year
                <br>
                <br>20%            
                `
        },
        {
          data: `<br>
                <br>Up to $5,000 per policy year
                <br>
                <br>NIL              
                `
        },
        {
          data: `<br>
                <br>Up to $1,000 per policy year
                <br>
                <br>20%            
                `
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>4</strong>`
        },
        {
          data: `<strong>Other Diagnostic Scan (MRI, CT Scan PET)</strong>
                <br>Benefit
                <br>Co-Payment/Co-Insurance                
                `
        },
        {
          data: `<br>
                <br>Up to $1,000 per policy year
                <br>NIL              
                `
        },
        {
          data: `<br>
                <br>Up to $1,000 per policy year
                <br>20%         
                `
        },
        {
          data: `<br>
                <br>Up to $2,000 per policy year
                <br>NIL              
                `
        },
        {
          data: `<br>
                <br>Up to $1,000 per policy year
                <br>20%         
                `
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>5</strong>`
        },
        {
          data: `<strong>Outpatient Physiotheraphy / Chirapactor Treatment</strong>
                <br>Benefit
                <br>Co-Payment/Co-Insurance                
                `
        },
        {
          data: `<br>
                <br>Up to $1,000 per policy year
                <br>NIL              
                `
        },
        {
          data: `<br>
                <br>Up to $1,000 per policy year
                <br>20%         
                `
        },
        {
          data: `<br>
                <br>Up to $2,000 per policy year
                <br>NIL              
                `
        },
        {
          data: `<br>
                <br>Up to $1,000 per policy year
                <br>20%         
                `
        }
      ]
    }
  ],
  footnotes: []
};

let GDPremium = {
  headers: [
    {
      title: `Plan Type`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Annual Premium</strong>`
        },
        {
          data: `S$ 285.00`
        }
      ]
    }
  ],
  footnotes: []
};

let Occupational = {
  title: `Occupational`,
  headers: [
    {
      title: `Class`,
      mandatory: true
    },
    {
      title: `Occupations`,
      mandatory: true
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Class 1</strong>`
        },
        {
          data: `Clerical, administrative or other similar non-hazardous occupations`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>Class 2</strong>`
        },
        {
          data: `Occupations where some degree of risk is involved, e.g. supervision of manual workers, totally administrative job in an industrial environment`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>Class 3</strong>`
        },
        {
          data: `Occupations involving regular light to medium manual work but no substantial hazard which may increase the risk of sickness or accident`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>Class 4</strong>`
        },
        {
          data: `<strong>Not covered</strong>`
        }
      ]
    }
  ],
  footnotes: []
};

//   ----------------------------------------------------------------------
const GP = {
  id: 1,
  code: "PSMH",
  label: `Group Outpatient Specialist (GOSP)`,
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GDBenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium (inclusive of 7% GST)",
      data: [GDPremium, Occupational]
    }
  ]
};

export default GP;
