import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Typography, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import moment from "moment";
import CategoryCustomisedTable from "./CategoryCustomisedTable";
class CoverageCustomised extends Component {
  constructor(props) {
    super(props);
  }

  formatDate = date => {
    return moment(date).format("DD MMM YYYY");
  };

  render() {
    const { classes, policy } = this.props;

    return (
      <div className={classes.root}>
        <div>
          <Typography className={classes.title}>
            {policy.companyName && `${policy.companyName}'s`} Insurance Coverage
          </Typography>
        </div>

        <div className={classes.content}>
          <div className={classes.root2}>
            <Grid container spacing={"0px"} className={classes.insuranceOverView}>
              <Grid item xs={12}>
                <div className={classes.contentWrapper}>
                  <div className={classes.rowWrapper}>
                    <Typography component="p" className={classes.label}>
                      Company Name
                    </Typography>
                    <Typography component="p">
                      <span className={classes.labelInfo}>{policy.companyName || "-"}</span>
                    </Typography>
                  </div>
                  <div className={classes.rowWrapper}>
                    <Typography component="p" className={classes.label}>
                      Policy Number
                    </Typography>
                    <Typography component="p">
                      <span className={classes.labelInfo}>{policy.policyNo || "-"}</span>
                    </Typography>
                  </div>
                  <div className={classes.rowWrapper}>
                    <Typography component="p" className={classes.label}>
                      Policy Period
                    </Typography>
                    <Typography component="p">
                      <span className={classes.labelInfo}>
                        {policy.policyStartDate && policy.policyEndDate
                          ? `${this.formatDate(policy.policyStartDate)} to ${this.formatDate(policy.policyEndDate)}`
                          : "-"}
                      </span>
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <CategoryCustomisedTable />
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    marginBottom: theme.actionFooter.height,
    paddingLeft: "10px"
  },
  title: {
    fontSize: "20px",
    fontWeight: "600 !important",
    padding: "0px 0px 25px 0px"
  },
  content: {
    padding: theme.spacing.unit * 2.5
  },
  root2: {
    backgroundColor: "white",
    padding: theme.spacing.unit * 2.5,
    paddingTop: 10,
    padding: "1.5%"
  },
  insuranceOverView: {
    marginTop: 20
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column"
  },
  rowWrapper: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "15px"
  },
  label: {
    fontWeight: "bold",
    width: "30%",
    display: "flex"
  },
  "@media (max-width: 600px)": {
    label: {
      width: "50%"
    }
  },
  labelInfo: {
    marginLeft: "0px"
  }
});

function mapStateToProps(state) {
  return {
    policy: state.policyDetails.policy
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(CoverageCustomised)));
