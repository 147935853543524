import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { bindActionCreators } from "redux";

import SimpleMessageDialog from "../../../components/SimpleMessageDialog";
import PolicyListingTable from "./PolicyListingTable";
import { policyListActions, policyDetailsActions } from "../../../actions";

import { withStyles } from "@mui/styles";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { Typography } from "@mui/material";
import LoadingPopup from "../../../components/LoadingPopup";

class PolicyInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMessageDialog: false,
      errorMessage: "",
      searchInputValue: "",
      currentPageNo: 0
    };
  }

  componentDidMount() {
    this.props.removePolicyDetails();
    this.props.getPolicyList();
  }

  handleErrorInput = errorMessage => {
    this.setState({
      errorMessage: errorMessage,
      openMessageDialog: true
    });
  };

  handleCloseMessageDialog = () => {
    this.setState({ errorMessage: "", openMessageDialog: false });
  };

  handlePageChange = (event, pageIndex) => {
    this.setState({
      currentPageNo: pageIndex
    });
  };

  handleResetPage = () => {
    this.setState({
      currentPageNo: 0
    });
  };

  render() {
    const { classes, showLoadingPopup } = this.props;
    return (
      <div className={classes.root}>
        <LoadingPopup title="popup.loading.default.title" name="load-policy-listing" open={showLoadingPopup} />
        <div className={classes.breadcrumb}>
          <CustomBreadcrumb currentPage="PolicyInformation" />
        </div>
        <Typography className={classes.title}>Client & Policy Information</Typography>
        <Typography className={classes.description}>
          View all clients / policies or search using <span className={classes.font}>&lt;Company Name&gt;</span> or{" "}
          <span className={classes.font}>&lt;Policy Number&gt;</span>
        </Typography>
        <Typography className={classes.description}>
          <span className={classes.font}>Confirmation of Policy & Commencement of Cover</span>
          <br />
          Please note that the final confirmation and issuance of your policy is subject to the clearance of all checks
          required in accordance with the internal requirements of Prudential Assurance Company Singapore (Pte) Limited
          (“Prudential”) and applicable laws and/or regulations. As such, the policy will only commence when the checks
          are complete, accepted by Prudential and the official letter indicating the commencement date of cover has
          been issued.
        </Typography>
        <SimpleMessageDialog
          type="error"
          name="errorMessageDialog"
          title="Error Input"
          description={this.state.errorMessage}
          closeButtonText="OK"
          closeHandler={this.handleCloseMessageDialog}
          open={this.state.openMessageDialog}
        />
        <PolicyListingTable
          currentPageNo={this.state.currentPageNo}
          handlePageChange={this.handlePageChange}
          handleResetPage={this.handleResetPage}
          handleErrorInput={this.handleErrorInput}
          policyList={this.props.policyList.data}
        />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    overflowX: "auto",
    paddingLeft: "10px"
  },
  breadcrumb: {
    padding: "30px 0px 30px 0px"
  },
  title: {
    fontSize: "20px",
    fontWeight: "600 !important",
    padding: "0px 0px 20px 0px"
  },
  description: {
    fontSize: "16px",
    padding: "0px 0px 20px 0px"
  },
  font: {
    fontWeight: "600 !important"
  },
  searchButton: {
    height: "2.7rem",
    width: "8rem",
    backgroundColor: theme.colors.primary.red,
    color: theme.palette.common.white
  },
  searchInput: {
    width: "100%"
  }
});

function mapStateToProps(state) {
  return {
    policyList: state.policyList,
    showLoadingPopup: state.app.showLoadingPopup
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(policyListActions, dispatch),
    ...bindActionCreators(policyDetailsActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(PolicyInformation)));
