import { AddCircle as AddCircleIcon } from "@mui/icons-material";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { quoteActions, employeeActions } from "../../../../../actions";
import PremiumAmount from "../components/PremiumAmount";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import classNames from "classnames";
import EmployeeDialog from "../containers/EnrollEmployee/EmployeeDialog";
import ENUMS from "../../../../../utils/Enums";
import React, { Component } from "react";
import SnackBar from "../../../../../components/SnackBar";
import TableToolBar from "../containers/EnrollEmployee/TableToolBar";
import Toolbar from "@mui/material/Toolbar";
import SimpleMessageDialog from "../../../../../components/SimpleMessageDialog";
import Util from "../../../../../utils/Util";

class ActionToolbarStep1 extends Component {
  constructor(props) {
    super(props);

    this.mode = {
      ADD: "ADD",
      EDIT: "EDIT",
      DELETE: "DELETE"
    };

    this.state = {
      deletedEmployees: false,
      snackBarErrorShow: false,
      snackBarEmployeeSuccess: false,
      selectedRecord: null,
      showDialog: false,
      mode: this.mode.ADD
    };
  }

  handleRemove = () => {
    const { selectedIds } = this.props.employee;
    if (selectedIds.length > 0) {
      this.props.deleteEmployee(selectedIds);
      this.setState({ deletedEmployees: true });
    }
  };

  handeCloseDeletedEmployees = () => {
    this.setState({ deletedEmployees: false });
  };

  handleEdit = () => {
    const { employee, quote } = this.props;

    if (employee.selectedIds.length === 1) {
      const selectedId = employee.selectedIds[0];
      let selectRecords = quote.data.persons.filter((n, idx) => selectedId === idx);
      this.setState({
        showDialog: true,
        mode: this.mode.EDIT,
        selectedRecord: selectRecords[0]
      });
    } else {
      this.setState({ showDialog: true, mode: this.mode.EDIT });
    }
  };

  handleAdd = () => {
    this.setState({
      showDialog: true,
      selectedRecord: null,
      mode: this.mode.ADD
    });
  };

  handleCloseDialog = () => {
    this.setState({ showDialog: false });
    //reset
    this.props.gotoListEmployees({
      showEmployeeList: false,
      shouldShowPopup: false
    });
  };

  handleClickSave = () => {
    this.props.save(ENUMS.STAGES.EMPLOYEE).then(response => {
      this.setState({ snackBarErrorShow: true });
    });
  };

  moveCategoryHandler = value => {
    const { selectedIds } = this.props.employee;
    if (selectedIds.length > 0) {
      this.props.moveCategory({
        category: value,
        ids: selectedIds
      });
    }
  };

  successHandler = () => {
    this.setState({ snackBarEmployeeSuccess: true });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.employee.shouldShowPopup) {
      this.setState({
        showDialog: true,
        selectedRecord: null,
        mode: this.mode.ADD
      });
      //reset
      this.props.gotoListEmployees({
        showEmployeeList: true,
        shouldShowPopup: false
      });
    }
  }

  render() {
    const { classes, quote, employee, document, persons, handlerNextStep, intl } = this.props;

    let readonly = this.props.quote.readOnly;
    let categoriesSource = [];
    if (quote && quote.data.categories) {
      categoriesSource = this.props.quote.data.categories.filter(c => !Util.isEmpty(c.name));
    }
    //to do get from source
    categoriesSource = categoriesSource.map(cat => ({
      value: cat.name,
      label: cat.name
    }));

    return (
      <Toolbar className={classes.container}>
        <div className={classes.actions}>
          {((persons && persons.length > 0) || employee.showEmployeeList) && (
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              disabled={readonly || document.hasSignedProposal}
              onClick={this.handleAdd}
            >
              <AddCircleIcon className={classes.leftIcon} />
              Add Employee
            </Button>
          )}
          {this.state.showDialog && (
            <EmployeeDialog
              open={this.state.showDialog}
              mode={this.state.mode}
              readOnly={readonly || document.hasSignedProposal}
              selectedRecord={this.state.selectedRecord}
              selectedIndex={employee.selectedIds.length === 1 ? employee.selectedIds[0] : -1}
              successHandler={this.successHandler}
              handleClose={this.handleCloseDialog}
            />
          )}
          {/*ToolBar*/}
          <TableToolBar
            disabled={readonly || document.hasSignedProposal}
            moveCategoryHandler={this.moveCategoryHandler}
            categoriesSource={categoriesSource}
            employeeSelected={employee.selectedIds}
            editEmployeeHandler={this.handleEdit}
            removeEmployeeHandler={this.handleRemove}
          />
        </div>
        <div className={classes.leftActions}>
          <Button
            variant="raised"
            color="inherit"
            onClick={this.handleClickSave}
            disabled={readonly || document.hasSignedProposal}
            className={classNames(classes.button, classes.buttonSave)}
          >
            {intl.formatMessage({ id: "actiontoolbar.button.save" })}
          </Button>
        </div>
        <div style={{ flexGrow: 1 }} />
        <div className={classes.actions}>
          <PremiumAmount showEstimate={false} />
          <Button variant="contained" color="primary" className={classes.button} onClick={handlerNextStep(2)}>
            {intl.formatMessage({ id: "actiontoolbar.button.continue" })}
          </Button>
        </div>
        <SnackBar
          type="success"
          open={this.state.snackBarErrorShow}
          onClose={() => this.setState({ snackBarErrorShow: false })}
          message="The quote has been saved"
        />
        <SnackBar
          type="error"
          open={this.state.snackBarEmployeeSuccess}
          onClose={() => this.setState({ snackBarEmployeeSuccess: false })}
          message="Changes will not be saved until Save Current Quote button is clicked"
        />
        <SimpleMessageDialog
          type="success"
          name="delete-employees"
          title="Success"
          description="The selected employee(s) have been removed"
          closeButtonText="OK"
          closeHandler={this.handeCloseDeletedEmployees}
          open={this.state.deletedEmployees}
        />
      </Toolbar>
    );
  }
}

function mapStateToProps(state, prop) {
  return {
    employee: state.quote.employee,
    persons: state.quote.data.persons,
    quote: state.quote,
    document: state.document
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(quoteActions, dispatch),
    ...bindActionCreators(employeeActions, dispatch)
  };
}

const styles = theme => ({
  container: {
    justifyContent: "flex-start",
    borderTop: "solid 1px #ccc",
    ...theme.extended.minHeight(55)
  },
  actions: {
    display: "flex",
    flexDirection: "row"
  },
  leftActions: {
    display: "flex",
    flexDirection: "row",
    marginLeft: -20
  },
  button: {
    marginLeft: 20,
    minWidth: 150
  },
  buttonSave: {
    backgroundColor: "#fff"
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(ActionToolbarStep1)));
