import Enums from "../../../../utils/Enums";

// GHS ----------------------
let GHSBenefits = {
  headers: [
    {
      title: ``,
      mandatory: true,
      attributes: {
        style: {
          width: "8%"
        }
      }
    },
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "30%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `1`
        },
        {
          data: `<strong>Daily Room and Board Benefit</strong>`,
          info: `<em>(Per day, up to 120 days per disability)</em>`
        },
        {
          data: `1-Bed Ward Private`
        },
        {
          data: `2-Bed Ward Private`
        },
        {
          data: `4-Bed Ward Private`
        },
        {
          data: `1-Bed Ward Government Restructured`
        },
        {
          data: `4-Bed Ward Government Restructured`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `2`
        },
        {
          data: `<strong>Intensive Care Unit (ICU)</strong>`,
          info: `<em>(Max. per day, up to 30 days per disability)</em>`
        },
        {
          data: `3 x 1-Bedded`
        },
        {
          data: `3 x 2-Bedded	`
        },
        {
          data: `3 x 4-Bedded`
        },
        {
          data: `S$20,000`,
          info: `per disability limit for items 2 to 8`,
          attributes: {
            rowspan: 7
          }
        },
        {
          data: `S$15,000`,
          info: `per disability limit for items 2 to 8`,
          attributes: {
            rowspan: 7
          }
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `3`
        },
        {
          data: `<strong>High Dependency Ward (HDW)</strong>`,
          info: `<em>(Max. per day, up to 30 days per disability)</em>`
        },
        {
          data: `2 x 1-Bedded`
        },
        {
          data: `2 x 2-Bedded	`
        },
        {
          data: `2 x 4-Bedded`,
          attributes: {
            notLast: true
          }
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `4`
        },
        {
          data: `<strong>Other Hospital Services Benefits – Including implants</strong>`,
          info: `<em>(Max. per disability)</em>`
        },
        {
          data: `S$25,000`,
          info: `per disability<br> limit for items<br> 4 to 8`,
          attributes: {
            rowspan: "5"
          }
        },
        {
          data: `S$20,000`,
          info: `per disability<br> limit for items<br> 4 to 8`,
          attributes: {
            rowspan: "5"
          }
        },
        {
          data: `S$15,000`,
          info: `per disability<br> limit for items<br> 4 to 8`,
          attributes: {
            rowspan: "5"
          }
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `5`
        },
        {
          data: `<strong>Surgical Fees</strong>`,
          info: `<em>(subject to surgical schedule)</em><br>
          i. Waiver of surgical schedule if insured member is admitted to government restructured hospital.<br>
          ii. Surgical fee more than S$1,500 is subject to surgical schedule if insured member is admitted to a private hospital.`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `6`
        },
        {
          data: `<strong>In-Hospital Doctor's Consultation Benefit</strong>`,
          info: `<em>(Max. 120 days)</em>`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `7`
        },
        {
          data: `<strong>Pre (90 days) & Post (90 days) Hospitalisation/ Surgery,  Specialist Consultation, Diagnostic X-Ray and Lab Test, Traditional Chinese Medicine</strong>`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `8`
        },
        {
          data: `<strong>Emergency Accidental Outpatient<br> Treatment Benefit</strong>`,
          info: `<em>(Including Accidental Dental Treatment)</em>`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `9`
        },
        {
          data: `<strong>Miscarriage Benefit</strong>`
        },
        {
          data: `Covered under benefits (items 1 to 8) ,<br> as per the respective benefit limits`,
          attributes: {
            colspan: "5",
            style: {
              width: "100%",
              textAlign: "center"
            }
          }
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `10`
        },
        {
          data: `<strong>Outpatient Cancer Treatment</strong>`,
          info: `<em>(Max. per policy year)</em>`
        },
        {
          data: `S$ 10,000`
        },
        {
          data: `S$ 10,000`
        },
        {
          data: `S$ 10,000`
        },
        {
          data: `N.A`
        },
        {
          data: `N.A`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `11`
        },
        {
          data: `<strong>Outpatient Kidney Dialysis</strong>`,
          info: `<em>(Max. per policy year)</em>`
        },
        {
          data: `S$ 10,000`
        },
        {
          data: `S$ 10,000`
        },
        {
          data: `S$ 10,000`
        },
        {
          data: `N.A`
        },
        {
          data: `N.A`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `12`
        },
        {
          data: `<strong>Overseas Hospitalisation for Accident Benefit</strong>`
        },
        {
          data: `150% of GHS benefit (<em>for item 1 to 7</em>)`,
          attributes: {
            colspan: "5",
            style: {
              width: "100%",
              textAlign: "center"
            }
          }
        }
      ]
    },
    {
      id: 13,
      columns: [
        {
          data: `13`
        },
        {
          data: `<strong>Rehabilitation Benefit</strong>`,
          info: `<em>(Max. per disability, up to 31 days)</em>`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        }
      ]
    },
    {
      id: 14,
      columns: [
        {
          data: `14`
        },
        {
          data: `<strong>Hospital Cash Benefit</strong>`,
          info: `<em>(Max. per day, up to 90 days per disability)</em>`
        },
        {
          data: ``,
          attributes: {
            colspan: "5"
          }
        }
      ]
    },
    {
      id: "",
      columns: [
        {
          data: ``
        },
        {
          data: `<strong>- Singapore Government Restructured B1 Ward</strong>`
        },
        {
          data: `S$ 50`
        },
        {
          data: `S$ 40`
        },
        {
          data: `Nil`
        },
        {
          data: `Nil`
        },
        {
          data: `Nil`
        }
      ]
    },
    {
      id: "",
      columns: [
        {
          data: ``
        },
        {
          data: `<strong>- Singapore Government Restructured B2 Ward</strong>`
        },
        {
          data: `S$ 100`
        },
        {
          data: `S$ 80`
        },
        {
          data: `S$ 40`
        },
        {
          data: `Nil`
        },
        {
          data: `Nil`
        }
      ]
    },
    {
      id: "",
      columns: [
        {
          data: ``
        },
        {
          data: `<strong>- Singapore Government Restructured C Ward</strong>`
        },
        {
          data: `S$ 150`
        },
        {
          data: `S$ 120`
        },
        {
          data: `S$ 80`
        },
        {
          data: `Nil`
        },
        {
          data: `Nil`
        }
      ]
    },
    {
      id: 18,
      columns: [
        {
          data: `15`
        },
        {
          data: `<strong>In-Hospital Psychiatric Treatment</strong>`,
          info: `<em>(Max. per policy year, applicable<br> to Singapore GRH only)</em>`
        },
        {
          data: `S$ 1,000`
        },
        {
          data: `S$ 1,000`
        },
        {
          data: `S$ 1,000`
        },
        {
          data: `S$ 1,000`
        },
        {
          data: `S$ 1,000`
        }
      ]
    },
    {
      id: 19,
      columns: [
        {
          data: `16`
        },
        {
          data: `<strong>Death Benefit</strong>`,
          info: `<em>(Double Death Benefits payable in the event of death due to accidental causes)</em>`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 5,000`
        }
      ]
    },
    {
      id: 20,
      columns: [
        {
          data: `17`
        },
        {
          data: `<p><strong>Pro-ration factor for:<br> Plan 2 &amp; 3</strong></p>
              <p><strong>Plan 4 &amp; 5</strong></p>`,
          info: `<strong>Plan 2 &amp; 3</strong><br> <em>Applicable to items 4 – 7</em></p>
              <p><strong>Plan 4 &amp; 5</strong><br> <em>Applicable to items 2 – 7</em></p>`
        },
        {
          data: `Nil`
        },
        {
          data: `75%`,
          info: `applies if Insured Member stays in 1 Bedded ward (Private or Government Restructured)`
        },
        {
          data: `75%`,
          info: `applies if Insured Member stays in 2 Bedded or higher ward (Private or Government Restructured)`
        },
        {
          data: `75%`,
          info: `applies if Insured Member stays in 1 Bedded ward (Private)`
        },
        {
          data: `75% `,
          info: `applies if Insured Member stays in 4 Bedded or higher ward (Private) or 2 Bedded or higher ward (Government Restructured)`
        }
      ]
    },
    {
      id: 21,
      columns: [
        {
          data: `18`
        },
        {
          data: `<strong>Complimentary Health Screening</strong>`,
          info: `<em>(for employee only)</em>`
        },
        {
          data: `Applicable for group size &gt; 3 eligible employees, yearly renewable and subject to availability`,
          attributes: {
            colspan: "5",
            style: {
              width: "100%",
              textAlign: "center"
            }
          }
        }
      ]
    }
  ],
  footnotes: []
};

let GHSPremiumEmployee = {
  title: `For Employee Only (inclusive of 7% GST)`,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 19</strong>`
        },
        {
          data: `S$ 378.00`
        },
        {
          data: `S$ 255.00`
        },
        {
          data: `S$ 204.00`
        },
        {
          data: `S$ 193.00`
        },
        {
          data: `S$ 136.00`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>20 - 24</strong>`
        },
        {
          data: `S$ 378.00`
        },
        {
          data: `S$ 255.00`
        },
        {
          data: `S$ 204.00`
        },
        {
          data: `S$ 197.00`
        },
        {
          data: `S$ 141.00`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>25 - 29</strong>`
        },
        {
          data: `S$ 378.00`
        },
        {
          data: `S$ 260.00`
        },
        {
          data: `S$ 207.00`
        },
        {
          data: `S$ 204.00`
        },
        {
          data: `S$ 146.00`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>30 - 34</strong>`
        },
        {
          data: `S$ 408.00`
        },
        {
          data: `S$ 292.00`
        },
        {
          data: `S$ 234.00`
        },
        {
          data: `S$ 217.00`
        },
        {
          data: `S$ 156.00`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>35 - 39</strong>`
        },
        {
          data: `S$ 408.00`
        },
        {
          data: `S$ 299.00`
        },
        {
          data: `S$ 239.00`
        },
        {
          data: `S$ 249.00`
        },
        {
          data: `S$ 179.00`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>40 - 44</strong>`
        },
        {
          data: `S$ 439.00`
        },
        {
          data: `S$ 363.00`
        },
        {
          data: `S$ 286.00`
        },
        {
          data: `S$ 322.00`
        },
        {
          data: `S$ 230.00`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>45 - 49</strong>`
        },
        {
          data: `S$ 633.00`
        },
        {
          data: `S$ 470.00`
        },
        {
          data: `S$ 388.00`
        },
        {
          data: `S$ 392.00`
        },
        {
          data: `S$ 272.00`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>50 - 54</strong>`
        },
        {
          data: `S$ 766.00`
        },
        {
          data: `S$ 604.00`
        },
        {
          data: `S$ 510.00`
        },
        {
          data: `S$ 532.00`
        },
        {
          data: `S$ 381.00`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>55 - 59</strong>`
        },
        {
          data: `S$ 939.00`
        },
        {
          data: `S$ 833.00`
        },
        {
          data: `S$ 663.00`
        },
        {
          data: `S$ 695.00`
        },
        {
          data: `S$ 497.00`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>60 - 64</strong>`
        },
        {
          data: `S$ 1,327.00`
        },
        {
          data: `S$ 1,042.00`
        },
        {
          data: `S$ 919.00`
        },
        {
          data: `S$ 921.00`
        },
        {
          data: `S$ 659.00`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>65 - 69</strong>`
        },
        {
          data: `S$ 1,820.00`
        },
        {
          data: `S$ 1,480.00`
        },
        {
          data: `S$ 1,218.00`
        },
        {
          data: `S$ 1,209.00`
        },
        {
          data: `S$ 865.00`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only.</em>`
        },
        {
          data: `S$ 3,400.00`
        },
        {
          data: `S$ 2,490.00`
        },
        {
          data: `S$ 2,005.00`
        },
        {
          data: `S$ 1,654.00`
        },
        {
          data: `S$ 1,183.00`
        }
      ]
    }
  ],
  footnotes: []
};

let GHSPremiumSpouseOrChild = {
  title: `For Employee and Spouse or Children Coverage Only (inclusive of 7% GST)`,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 19</strong>`
        },
        {
          data: `S$ 850.50`
        },
        {
          data: `S$ 573.75`
        },
        {
          data: `S$ 459.00`
        },
        {
          data: `S$ 434.25`
        },
        {
          data: `S$ 306.00`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>20 - 24</strong>`
        },
        {
          data: `S$ 850.50`
        },
        {
          data: `S$ 573.75`
        },
        {
          data: `S$ 459.00`
        },
        {
          data: `S$ 443.25`
        },
        {
          data: `S$ 317.25`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>25 - 29</strong>`
        },
        {
          data: `S$ 850.50`
        },
        {
          data: `S$ 585.00`
        },
        {
          data: `S$ 465.75`
        },
        {
          data: `S$ 459.00`
        },
        {
          data: `S$ 328.50`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>30 - 34</strong>`
        },
        {
          data: `S$ 918.00`
        },
        {
          data: `S$ 657.00`
        },
        {
          data: `S$ 526.50`
        },
        {
          data: `S$ 488.25`
        },
        {
          data: `S$ 351.00`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>35 - 39</strong>`
        },
        {
          data: `S$ 918.00`
        },
        {
          data: `S$ 672.75`
        },
        {
          data: `S$ 537.75`
        },
        {
          data: `S$ 560.25`
        },
        {
          data: `S$ 402.75`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>40 - 44</strong>`
        },
        {
          data: `S$ 987.75`
        },
        {
          data: `S$ 816.75`
        },
        {
          data: `S$ 643.50`
        },
        {
          data: `S$ 724.50`
        },
        {
          data: `S$ 517.50`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>45 - 49</strong>`
        },
        {
          data: `S$ 1,424.25`
        },
        {
          data: `S$ 1,057.50`
        },
        {
          data: `S$ 873.00`
        },
        {
          data: `S$ 882.00`
        },
        {
          data: `S$ 612.00`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>50 - 54</strong>`
        },
        {
          data: `S$ 1,723.50`
        },
        {
          data: `S$ 1,359.00`
        },
        {
          data: `S$ 1,147.50`
        },
        {
          data: `S$ 1,197.00`
        },
        {
          data: `S$ 857.25`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>55 - 59</strong>`
        },
        {
          data: `S$ 2,112.75`
        },
        {
          data: `S$ 1,874.25`
        },
        {
          data: `S$ 1,491.75`
        },
        {
          data: `S$ 1,563.75`
        },
        {
          data: `S$ 1,118.25`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>60 - 64</strong>`
        },
        {
          data: `S$ 2,985.75`
        },
        {
          data: `S$ 2,344.50`
        },
        {
          data: `S$ 2,067.75`
        },
        {
          data: `S$ 2,072.25`
        },
        {
          data: `S$ 1,482.75`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>65 - 69</strong>`
        },
        {
          data: `S$ 4,095.00`
        },
        {
          data: `S$ 3,330.00`
        },
        {
          data: `S$ 2,740.50`
        },
        {
          data: `S$ 2,720.25`
        },
        {
          data: `S$ 1,946.25`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 7,650.00`
        },
        {
          data: `S$ 5,602.50`
        },
        {
          data: `S$ 4,511.25`
        },
        {
          data: `S$ 3,721.50`
        },
        {
          data: `S$ 2,661.75`
        }
      ]
    }
  ],
  footnotes: []
};

let GHSPremiumSpouseAndChild = {
  title: `For Employee and Spouse and Children Coverage Only (inclusive of 7% GST)`,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 19</strong>`
        },
        {
          data: `S$ 1,323.00`
        },
        {
          data: `S$ 892.50`
        },
        {
          data: `S$ 714.00`
        },
        {
          data: `S$ 675.50`
        },
        {
          data: `S$ 476.00`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>20 - 24</strong>`
        },
        {
          data: `S$ 1,323.00`
        },
        {
          data: `S$ 892.50`
        },
        {
          data: `S$ 714.00`
        },
        {
          data: `S$ 689.50`
        },
        {
          data: `S$ 493.50`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>25 - 29</strong>`
        },
        {
          data: `S$ 1,323.00`
        },
        {
          data: `S$ 910.00`
        },
        {
          data: `S$ 724.50`
        },
        {
          data: `S$ 714.00`
        },
        {
          data: `S$ 511.00`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>30 - 34</strong>`
        },
        {
          data: `S$ 1,428.00`
        },
        {
          data: `S$ 1,022.00`
        },
        {
          data: `S$ 819.00`
        },
        {
          data: `S$ 759.50`
        },
        {
          data: `S$ 546.00`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>35 - 39</strong>`
        },
        {
          data: `S$ 1,428.00`
        },
        {
          data: `S$ 1,046.50`
        },
        {
          data: `S$ 836.50`
        },
        {
          data: `S$ 871.50`
        },
        {
          data: `S$ 626.50`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>40 - 44</strong>`
        },
        {
          data: `S$ 1,536.50`
        },
        {
          data: `S$ 1,270.50`
        },
        {
          data: `S$ 1,001.00`
        },
        {
          data: `S$ 1,127.00`
        },
        {
          data: `S$ 805.00`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>45 - 49</strong>`
        },
        {
          data: `S$ 2,215.50`
        },
        {
          data: `S$ 1,645.00`
        },
        {
          data: `S$ 1,358.00`
        },
        {
          data: `S$ 1,372.00`
        },
        {
          data: `S$ 952.00`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>50 - 54</strong>`
        },
        {
          data: `S$ 2,681.00`
        },
        {
          data: `S$ 2,114.00`
        },
        {
          data: `S$ 1,785.00`
        },
        {
          data: `S$ 1,862.00`
        },
        {
          data: `S$ 1,333.50`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>55 - 59</strong>`
        },
        {
          data: `S$ 3,286.50`
        },
        {
          data: `S$ 2,915.50`
        },
        {
          data: `S$ 2,320.50`
        },
        {
          data: `S$ 2,432.50`
        },
        {
          data: `S$ 1,739.50`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>60 - 64</strong>`
        },
        {
          data: `S$ 4,644.50`
        },
        {
          data: `S$ 3,647.00`
        },
        {
          data: `S$ 3,216.50`
        },
        {
          data: `S$ 3,223.50`
        },
        {
          data: `S$ 2,306.50`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>65 - 69</strong>`
        },
        {
          data: `S$ 6,370.00`
        },
        {
          data: `S$ 5,180.00`
        },
        {
          data: `S$ 4,263.00`
        },
        {
          data: `S$ 4,231.50`
        },
        {
          data: `S$ 3,027.50`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `<strong>70 - 74</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 11,900.00`
        },
        {
          data: `S$ 8,715.00`
        },
        {
          data: `S$ 7,017.50`
        },
        {
          data: `S$ 5,789.00`
        },
        {
          data: `S$ 4,140.50`
        }
      ]
    }
  ],
  footnotes: []
};

/*let GHSGroupDiscount = {
  headers: [
    {
      title: `Group Size <i>Insured Employee<i>`
    },
    {
      title: `Discount`
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>11 - 15</strong>`
        },
        {
          data: `5%`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>16 and above</strong>`
        },
        {
          data: `10%`
        }
      ]
    }
  ],
  footnotes: []
};*/

//   ----------------------------------------------------------------------

const GHS = {
  id: 2,
  code: "GHSB",
  label: "Group Hospital & Surgical (GHS)",
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GHSBenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium",
      data: [GHSPremiumEmployee, GHSPremiumSpouseOrChild, GHSPremiumSpouseAndChild]
    }
    /*{
      id: 4,
      order: 4,
      type: Enums.PLANS_TYPE.GROUP_DISCOUNT,
      label: "Group Size Discount",
      data: [GHSGroupDiscount]
    }*/
  ]
};

export default GHS;
