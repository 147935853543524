import { Component } from "react";
import React from "react";

import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { Box } from "@mui/material";

class CustomTabPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { children, value, index } = this.props;

    return (
      <div role="tabpanel" hidden={value !== index} id={`custom-tab-panel-${index}`}>
        {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
      </div>
    );
  }
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const styles = theme => ({});

export default withStyles(styles, { withTheme: true })(CustomTabPanel);
