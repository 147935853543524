import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
//plans display typography not in backend but in json
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Accordion as ExpansionPanel } from "@mui/material";
import { AccordionSummary as ExpansionPanelSummary } from "@mui/material";
import { AccordionDetails as ExpansionPanelDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import { productActions } from "../../../../actions";
import PlanContent from "./PlanContentPF3";
import pruPlans from "../../../../assets/data/plans/index";
import pruPlansV2 from "../../../../assets/data/plans/Version2/index";
import { bindActionCreators } from "redux";
import config from "../../../../config/config";
import moment from "moment";
import { cloneDeep } from "lodash";

class Plans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coreTabValue: 0,
      supplyMentaryTabValue: 0,
      filteredCorePlans: [],
      filteredSupplementaryPlans: []
    };
  }

  getPlans() {
    const { tiers = null, productCode = quote.product_code } = this.props;
    const enrolledPlans = Object.keys(tiers);

    const formattedPlan = [];
    pruPlans[productCode].forEach(type => {
      const formattedType = type.plans.map(plan => {
        if (enrolledPlans.includes(plan.code)) return plan;
        return null;
      });

      if (formattedType && !formattedType.every(p => p === null))
        formattedPlan.push({ title: type.title, plans: formattedType });
    });
    return formattedPlan || pruPlans[productCode];
  }

  getPlanTitle = (plan, selectedTiers) => {
    return (
      plan.types.find(t => t.type === this.props.types[0]).data[0].headers.find(h => h.tier === selectedTiers[0])
        .title || "Unknown Plan"
    );
  };

  getPlansShow(portalType, resetTime, basePlansClone) {
    let basePlans = basePlansClone;
    if (portalType == "HR" || portalType == "PI") {
      basePlans = this.getHRPlans(basePlans);
    } else {
      basePlans = this.getSalesPlans(basePlans, resetTime);
    }
    return basePlans;
  }

  getSalesPlans(basePlans, resetTime) {
    let creationTime = resetTime;
    if (this.props.quote.created !== undefined) {
      if (moment(this.props.quote.created).isBefore(resetTime)) {
        creationTime = this.props.quote.created;
      }
    }
    // removing the GHS and GTL Plans Rates which belongs to HR
    // id 5 signifies GTL_HR, id 6 signifies GHS_HR, id 7 signifies GTL_HR_PF3, id 8 signifies GHS_HR_PF3, id 20 ingnifies GFWM_HR_PF3

    //Core Plan
    let hrPlanIdx = basePlans[0].plans.findIndex(x => x.id === 5);
    if (hrPlanIdx !== -1) {
      basePlans[0].plans.splice(hrPlanIdx, 1);
    }

    let hrPlanIdx2 = basePlans[0].plans.findIndex(x => x.id === 6);
    if (hrPlanIdx2 !== -1) {
      basePlans[0].plans.splice(hrPlanIdx2, 1);
    }

    let hrPlanIdx4 = basePlans[0].plans.findIndex(x => x.id === 7);
    if (hrPlanIdx4 !== -1) {
      basePlans[0].plans.splice(hrPlanIdx4, 1);
    }

    let hrPlanIdx3 = basePlans[0].plans.findIndex(x => x.id === 8);
    if (hrPlanIdx3 !== -1) {
      basePlans[0].plans.splice(hrPlanIdx3, 1);
    }

    let hrPlanIdx5 = basePlans[0].plans.findIndex(x => x.id === 20);
    if (hrPlanIdx5 !== -1) {
      basePlans[0].plans.splice(hrPlanIdx5, 1);
    }

    let hrPlanIdx6 = basePlans[0].plans.findIndex(x => x.id === 9);
    if (hrPlanIdx6 !== -1) {
      basePlans[0].plans.splice(hrPlanIdx6, 1);
    }

    //SupplyMentary Plan
    //GCCA
    let supPlanIdx1 = basePlans[1].plans.findIndex(x => x.id === 10);
    if (supPlanIdx1 !== -1) {
      basePlans[1].plans.splice(supPlanIdx1, 1);
    }

    //GPCG
    let supPlanIdx2 = basePlans[1].plans.findIndex(x => x.id === 11);
    if (supPlanIdx2 !== -1) {
      basePlans[1].plans.splice(supPlanIdx2, 1);
    }

    //GEMM
    let supPlanIdx3 = basePlans[1].plans.findIndex(x => x.id === 12);
    if (supPlanIdx3 !== -1) {
      basePlans[1].plans.splice(supPlanIdx3, 1);
    }

    //PGIH
    let supPlanIdx4 = basePlans[1].plans.findIndex(x => x.id === 13);
    if (supPlanIdx4 !== -1) {
      basePlans[1].plans.splice(supPlanIdx4, 1);
    }

    //PSIH
    let supPlanIdx5 = basePlans[1].plans.findIndex(x => x.id === 14);
    if (supPlanIdx5 !== -1) {
      basePlans[1].plans.splice(supPlanIdx5, 1);
    }

    //GADD
    let supPlanIdx6 = basePlans[1].plans.findIndex(x => x.id === 15);
    if (supPlanIdx6 !== -1) {
      basePlans[1].plans.splice(supPlanIdx6, 1);
    }

    //GAMR
    let supPlanIdx7 = basePlans[1].plans.findIndex(x => x.id === 16);
    if (supPlanIdx7 !== -1) {
      basePlans[1].plans.splice(supPlanIdx7, 1);
    }

    //GDEN
    let supPlanIdx8 = basePlans[1].plans.findIndex(x => x.id === 17);
    if (supPlanIdx8 !== -1) {
      basePlans[1].plans.splice(supPlanIdx8, 1);
    }

    //PDIH
    let supPlanIdx9 = basePlans[1].plans.findIndex(x => x.id === 18);
    if (supPlanIdx9 !== -1) {
      basePlans[1].plans.splice(supPlanIdx9, 1);
    }

    if (moment(creationTime).isSameOrAfter(config.cut_off_date)) {
      // removing the old rates of GHS and GTL
      // id 1 signifies GTL old rates and id 2 signifies GHS old rates
      let oldPlanIdx1 = basePlans[0].plans.findIndex(x => x.id === 1);
      if (oldPlanIdx1 !== -1) {
        basePlans[0].plans.splice(oldPlanIdx1, 1);
      }

      let oldPlanIdx2 = basePlans[0].plans.findIndex(x => x.id === 2);
      if (oldPlanIdx2 !== -1) {
        basePlans[0].plans.splice(oldPlanIdx2, 1);
      }
    } else {
      // original rates
      // removing the new rates of GHS and GTL
      // id 3 signifies GTL new rates and id 4 signifies GHS new rates
      let newPlanIdx1 = basePlans[0].plans.findIndex(x => x.id === 3);
      if (newPlanIdx1 !== -1) {
        basePlans[0].plans.splice(newPlanIdx1, 1);
      }

      let newPlanIdx2 = basePlans[0].plans.findIndex(x => x.id === 4);
      if (newPlanIdx2 !== -1) {
        basePlans[0].plans.splice(newPlanIdx2, 1);
      }
    }
    return basePlans;
  }

  getHRPlans(basePlans) {
    // getting policy effective date
    let policyEffectiveDate = this.props.policyEffectiveDate;
    // policyEffectiveDate = this.props.quote.req_effective_date;

    // removing the old rates of GHS and GTL
    // id 1 signifies GTL old rates and id 2 signifies GHS old rates
    let oldPlanIdx1 = basePlans[0].plans.findIndex(x => x.id === 1);
    if (oldPlanIdx1 !== -1) {
      basePlans[0].plans.splice(oldPlanIdx1, 1);
    }

    let oldPlanIdx2 = basePlans[0].plans.findIndex(x => x.id === 2);
    if (oldPlanIdx2 !== -1) {
      basePlans[0].plans.splice(oldPlanIdx2, 1);
    }

    // removing previous GTL_HR which id 5
    let hrPlanIdx2 = basePlans[0].plans.findIndex(x => x.id === 5);
    if (hrPlanIdx2 !== -1) {
      basePlans[0].plans.splice(hrPlanIdx2, 1);
    }

    // removing previous GHS_HR which id 6
    let hrPlanIdx1 = basePlans[0].plans.findIndex(x => x.id === 6);
    if (hrPlanIdx1 !== -1) {
      basePlans[0].plans.splice(hrPlanIdx1, 1);
    }

    let hrPlanIdx3 = basePlans[0].plans.findIndex(x => x.id === 19);
    if (hrPlanIdx3 !== -1) {
      basePlans[0].plans.splice(hrPlanIdx3, 1);
    }

    let hrPlanIdx4 = basePlans[0].plans.findIndex(x => x.id === 4);
    if (hrPlanIdx4 !== -1) {
      basePlans[0].plans.splice(hrPlanIdx4, 1);
    }

    //SupplyMentary Plan
    //GCCA
    let supPlanIdx1 = basePlans[1].plans.findIndex(x => x.id === 1);
    if (supPlanIdx1 !== -1) {
      basePlans[1].plans.splice(supPlanIdx1, 1);
    }

    //GPCG
    let supPlanIdx2 = basePlans[1].plans.findIndex(x => x.id === 2);
    if (supPlanIdx2 !== -1) {
      basePlans[1].plans.splice(supPlanIdx2, 1);
    }

    //GEMM
    let supPlanIdx3 = basePlans[1].plans.findIndex(x => x.id === 3);
    if (supPlanIdx3 !== -1) {
      basePlans[1].plans.splice(supPlanIdx3, 1);
    }

    //PGIH
    let supPlanIdx4 = basePlans[1].plans.findIndex(x => x.id === 4);
    if (supPlanIdx4 !== -1) {
      basePlans[1].plans.splice(supPlanIdx4, 1);
    }

    //PSIH
    let supPlanIdx5 = basePlans[1].plans.findIndex(x => x.id === 5);
    if (supPlanIdx5 !== -1) {
      basePlans[1].plans.splice(supPlanIdx5, 1);
    }

    //GADD
    let supPlanIdx6 = basePlans[1].plans.findIndex(x => x.id === 6);
    if (supPlanIdx6 !== -1) {
      basePlans[1].plans.splice(supPlanIdx6, 1);
    }

    //GAMR
    let supPlanIdx7 = basePlans[1].plans.findIndex(x => x.id === 7);
    if (supPlanIdx7 !== -1) {
      basePlans[1].plans.splice(supPlanIdx7, 1);
    }

    //GDEN
    let supPlanIdx8 = basePlans[1].plans.findIndex(x => x.id === 8);
    if (supPlanIdx8 !== -1) {
      basePlans[1].plans.splice(supPlanIdx8, 1);
    }

    //GPPD
    let supPlanIdx9 = basePlans[1].plans.findIndex(x => x.id === 9);
    if (supPlanIdx9 !== -1) {
      basePlans[1].plans.splice(supPlanIdx9, 1);
    }

    if (moment(policyEffectiveDate).isSameOrAfter(config.cut_off_date)) {
      // removing the GHS and GTL Old Plans Rates with info notes
      // id 7 signifies GTL_HR_PF3 and id 8 signifies GHS_HR_PF3
      let hrPlanIdx3 = basePlans[0].plans.findIndex(x => x.id === 6);
      if (hrPlanIdx3 !== -1) {
        basePlans[0].plans.splice(hrPlanIdx3, 1);
      }

      let hrPlanIdx = basePlans[0].plans.findIndex(x => x.id === 7);
      if (hrPlanIdx !== -1) {
        basePlans[0].plans.splice(hrPlanIdx, 1);
      }

      let hrPlanIdx2 = basePlans[0].plans.findIndex(x => x.id === 8);
      if (hrPlanIdx2 !== -1) {
        basePlans[0].plans.splice(hrPlanIdx2, 1);
      }
    } else {
      // original rates with information notes
      // removing the new rates of GHS and GTL
      // id 3 signifies GTL new rates and id 4 signifies GHS new rates
      let newPlanIdx1 = basePlans[0].plans.findIndex(x => x.id === 3);
      if (newPlanIdx1 !== -1) {
        basePlans[0].plans.splice(newPlanIdx1, 1);
      }

      let newPlanIdx2 = basePlans[0].plans.findIndex(x => x.id === 9);
      if (newPlanIdx2 !== -1) {
        basePlans[0].plans.splice(newPlanIdx2, 1);
      }
    }
    return basePlans;
  }

  handleCodePlanTabChange = (event, coreTabValue) => {
    this.setState({ coreTabValue });
  };

  handleSupplyMentaryPlanTabChange = (event, supplyMentaryTabValue) => {
    this.setState({ supplyMentaryTabValue });
  };

  filterCorePlans(corePlans, enrolledPlans) {
    let filteredCorePlans = [];
    corePlans.plans.map(plan => {
      if (enrolledPlans.includes(plan.code)) {
        //--MANIPULATE
        if (plan.code === "GFWM") {
          const extendedBenefits = this.props?.hr_coverage?.policy?.data?.extendedBenefit;

          if (typeof extendedBenefits !== "undefined") {
            extendedBenefits.map(benefit => {
              if (benefit.benefitCode === "B") {
                this.modifyGFWMdata(benefit, plan, 13);
              } else if (benefit.benefitCode === "C") {
                this.modifyGFWMdata(benefit, plan, 14);
              } else if (benefit.benefitCode === "D") {
                this.modifyGFWMdata(benefit, plan, 15);
              }
            });
          }
        }
        //--MANIPULATE
        filteredCorePlans.push(plan);
      }
    });
    this.setState({ filteredCorePlans }, () => {});
  }

  modifyGFWMdata(benefit, plan, rowNum) {
    for (var i = 1; i < plan.types[0].data[0].rows[rowNum].columns.length; i++) {
      if (benefit.tier === i) {
        plan.types[0].data[0].rows[rowNum].columns[i].data = "✔";
      }
    }
  }

  filterSupplementaryPlans(supplementaryPlans, enrolledPlans) {
    let filteredSupplementaryPlans = [];
    supplementaryPlans.plans.map(plan => {
      if (enrolledPlans.includes(plan.code) && plan.code !== "GAMR") {
        filteredSupplementaryPlans.push(plan);
        this.setState({ filteredSupplementaryPlans });
      }
    });
  }

  componentWillMount() {
    var planVersions = [];
    planVersions.push(pruPlans);
    planVersions.push(pruPlansV2);
    const productVersion = this.props.productVersion || this.props.quote.product_version;
    var planCurrentVersion = planVersions.find(plan => plan.version === productVersion);

    const basePlansDefault = this.props.displayAll ? planCurrentVersion[this.props.productCode] : this.getPlans();

    const basePlansClone = cloneDeep(basePlansDefault);
    let basePlans = this.getPlansShow(this.props.portalType, this.props.resetTime, basePlansClone);
    let corePlans = basePlans[0];
    let supplementaryPlans = basePlans[1];
    const enrolledPlans = Object.keys(this.props.tiers);

    this.filterCorePlans(corePlans, enrolledPlans);
    this.filterSupplementaryPlans(supplementaryPlans, enrolledPlans);
  }

  render() {
    const {
      classes,
      types,
      tiers,
      selectionDisplayMode,
      displayAll = true,
      productCode = quote.product_code,
      resetTime = quote.resetTime,
      portalType
    } = this.props;

    const { coreTabValue, supplyMentaryTabValue, filteredCorePlans, filteredSupplementaryPlans } = this.state;

    var planVersions = [];
    planVersions.push(pruPlans);
    planVersions.push(pruPlansV2);

    //get version for screen plan display
    const productVersion = this.props.productVersion || this.props.quote.product_version;
    var planCurrentVersion = planVersions.find(plan => plan.version === productVersion);

    const basePlansDefault = displayAll ? planCurrentVersion[productCode] : this.getPlans();
    const basePlansClone = cloneDeep(basePlansDefault);

    let basePlans = this.getPlansShow(portalType, resetTime, basePlansClone);

    let corePlans = basePlans[0];
    let supplementaryPlans = basePlans[1];

    return (
      <div className={classes.root}>
        <Paper>
          <Typography className={classes.coverageBenefit}>Benefits & Coverage</Typography>
          {this.state.filteredCorePlans.length !== 0 ? (
            <ExpansionPanel>
              <ExpansionPanelSummary className={classes.expansionPanelSummary} expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.expansionPanelSummaryLabelCoverageBenefit}>{corePlans.title}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div color="default" className={classes.appBar}>
                  <Tabs
                    value={coreTabValue}
                    onChange={this.handleCodePlanTabChange}
                    indicatorColor="primary"
                    variant="fullWidth"
                    scrollable
                    scrollButtons="off"
                  >
                    {filteredCorePlans.map((plan, idx) => {
                      return <Tab key={idx} label={plan.label} />;
                    })}
                  </Tabs>

                  <PlanContent
                    data={filteredCorePlans[coreTabValue].types}
                    types={types}
                    tiers={tiers[filteredCorePlans[coreTabValue].code]}
                    selectionDisplayMode={selectionDisplayMode}
                  />
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ) : (
            ""
          )}
          {this.state.filteredSupplementaryPlans.length !== 0 ? (
            <ExpansionPanel>
              <ExpansionPanelSummary className={classes.expansionPanelSummary} expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.expansionPanelSummaryLabelCoverageBenefit}>
                  {supplementaryPlans.title}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div color="default" className={classes.appBar}>
                  <Tabs
                    value={supplyMentaryTabValue}
                    onChange={this.handleSupplyMentaryPlanTabChange}
                    indicatorColor="primary"
                    variant="fullWidth"
                    scrollable
                    scrollButtons="off"
                  >
                    {filteredSupplementaryPlans.map((plan, idx) => {
                      return plan.code !== "GAMR" && <Tab key={idx} label={plan.label} />;
                    })}
                  </Tabs>

                  <PlanContent
                    data={filteredSupplementaryPlans[supplyMentaryTabValue].types}
                    types={types}
                    tiers={tiers[filteredSupplementaryPlans[supplyMentaryTabValue].code]}
                    selectionDisplayMode={selectionDisplayMode}
                  />
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ) : (
            ""
          )}
        </Paper>
      </div>
    );
  }
}

Plans.defaultProps = {
  tiers: null,
  selectionDisplayMode: null
};

const styles = theme => ({
  root: {
    width: "100%"
  },
  flex: {
    flex: 1
  },
  coverageBenefit: {
    color: "red",
    width: "100%",
    fontWeight: 700,
    paddingLeft: "24px",
    paddingTop: "24px",
    paddingBottom: "4px",
    minHeight: "40px"
  },
  expansionPanel: {
    margin: "16px 0"
  },
  expansionPanelSummary: {
    backgroundColor: theme.palette.default.main,
    minHeight: "70px"
  },
  expansionPanelSummaryLabel: {
    color: theme.palette.default.fontColor,
    width: "100%"
  },
  expansionPanelSummaryLabelCoverageBenefit: {
    width: "100%",
    fontWeight: 550
  },
  appBar: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    width: "100%"
  },
  headline: {
    fontSize: "1em",
    fontWeight: 700
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  titleLabel: {
    width: "80%"
  },
  titleSelected: {
    flex: 1,
    fontWeight: "bold",
    color: "#C4184A"
  }
});

function mapStateToProps(state) {
  return {
    quote: state.quote,
    hr_coverage: state.HR.coverage
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(productActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Plans));
